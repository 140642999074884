@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&family=BIZ+UDPGothic:wght@400;700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ultra&display=swap");

$base-font-url: 'https://static.lenskart.com/owndays/fonts/SFCompactDisplay';

@font-face {
  font-family: "SFCompactDisplay";
  src: url("#{$base-font-url}/SFCompactDisplay-Regular.woff2")
      format("woff2"),
    url("#{$base-font-url}/SFCompactDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFCompactDisplay";
  src: url("#{$base-font-url}/SFCompactDisplay-Bold.woff2")
      format("woff2"),
    url("#{$base-font-url}/SFCompactDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$font-biz: "BIZ UDPGothic", serif;

$black-29: #292929;
$gray-53: #535353;
$yellow-b4: #B47E00;
$yellow-87: #875D07;

.base-huawei-2-titanium {
  overflow: hidden;

  &__container {
    padding-left: 23px;
    padding-right: 23px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1220px;

    @media (min-width: 1240px) {
      padding: 0;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 45px;
    border-radius: 10px;
    color: white;
    background-color: $black-29;
    font-family: $font-biz;
    font-weight: 700 !important;
    letter-spacing: 0.14px;
    line-height: 1;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
      line-height: 28px;
      font-size: 14px;
    }

    @media (min-width: 1024px) {
      cursor: pointer;
    }

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    &--sm {
      background: white;
      color: $gray-53;
      border: 1px solid $gray-53;
      border-radius: 10px;
      height: 40px;
      width: 100%;
      max-width: 280px;
      padding: 0 72px;
      margin-inline: auto;
      font-size: 12px;
      line-height: 1;
      font-weight: 700 !important;
      letter-spacing: 0.12px;

      &:hover, &:active, &:visited, &:focus {
        color: $gray-53;
      }

      @media (min-width: 1024px) {
        &:hover {
          color: white;
  
          &::after {
            transform: scaleX(1);
          }
        }
  
        &::after {
          background: $gray-53;
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 100% !important;
          height: 100%;
          transform: scaleX(0);
          transform-origin: left top;
          transition: .2s cubic-bezier(0.45, 0, 0.55, 1);
          z-index: -1;
          will-change: transform;
        }
      }
    }

    &--gold {
      background-color: $yellow-b4;
      color: white;

      &:hover, &:active, &:visited, &:focus {
        color: white;
      }

      @media (min-width: 1024px) {
        &::after {
          background-color: $yellow-87;
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 140px;
          transform: skewY(-10deg) scaleY(0);
          transform-origin: left top;
          transition: .2s cubic-bezier(.45,0,.55,1);
          z-index: -1;
          will-change: transform;
        }

        &:hover {
          transition: 0.5s;
          background: $yellow-87;

          &::after {
            transform: skewY(-10deg) scale(1);
          }
        }
      }
    }

    @media (min-width: 768px) {
      &--sm{
        margin-left: 0;
      }
    }

    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 30.8px;

      &--lg {
        font-size: 14px;
        line-height: 22.4px;
        letter-spacing: 0.28px;
        height: 70px;
      }

      &--sm {
        max-width: 260px;
        height: 54px;
        font-size: 14px;
        letter-spacing: 0.14px;
      }
    }
  }
}
