.services__lens-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.services__lens-filter-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 0 30px;
}

.services__lens-filter-list li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 15px;
  line-height: 2;
  color: #666;
  padding: 0 12px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.services__lens-filter-list li.active {
  color: #007eaa;
}

.services__lens-filter-list li:hover {
  color: #007eaa;
}

.services__lens-filter-content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}

.services__lens-filter-content ul li {
  display: grid;
  grid-template-columns: 55px 1fr;
  border: 1px solid #f4f4f4;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
}

.services__lens-filter-content ul li::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25px;
  background: #f4f4f4 url("https://static.lenskart.com/media/owndays/img/services-lens/arrow-b-black.png") no-repeat center center/4px auto;
}

.services__lens-filter-icon {
  display: block;
  max-width: 30px;
  margin: 0 auto;
}

.services__lens-filter-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-right: 45px;
}

.services__lens-filter-details span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70px;
  height: 17px;
  font-size: 11px;
  line-height: 1;
  text-align: center;
  color: #fff;
  margin-bottom: 3px;
}

.services__lens-filter-details span.standard {
  background-color: #0094c8;
}

.services__lens-filter-details span.option {
  background-color: #c7002e;
}

.services__lens-filter-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  row-gap: 8px;
}

.services__lens-filter-title h4,
.services__lens-filter-title p {
  margin-top: 0;
  margin-bottom: 0;
}

.services__lens-filter-title h4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  font-size: 14px;
  font-weight: 600 !important;
  color: #000;
}

.services__lens-filter-title h4 img {
  width: auto;
  height: 27px;
  -o-object-fit: contain;
     object-fit: contain;
}

.services__lens-filter-title p {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #c7002e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  row-gap: 4px;
}

.services__lens-filter-title p small {
  font-weight: 400;
}

@media (min-width: 768px) {
  .services__lens-filter .services__lens-filter-content ul li {
    grid-template-columns: 68px 1fr;
  }
  .services__lens-filter .services__lens-filter-content ul li::before {
    width: 50px;
  }
  .services__lens-filter .services__lens-filter-icon {
    max-width: 36px;
  }
  .services__lens-filter .services__lens-filter-details {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 70px;
  }
  .services__lens-filter .services__lens-filter-details span {
    margin-bottom: 0;
    height: 30px;
  }
  .services__lens-filter .services__lens-filter-title {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
  .services__lens-filter .services__lens-filter-title h4, .services__lens-filter .services__lens-filter-title p {
    font-size: 16px;
  }
}
