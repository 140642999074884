$baseImgUrl: 'https://static.lenskart.com/media/owndays/img/news';

.header-news {
  opacity: 0;
  transform: translate(0, 16px);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1),
  transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--active {
    opacity: 1;
    transform: translate(0, 0);

    .header-news__title-text {
      opacity: 1;

      &:before {
        width: 100%;
      }

      span {
        opacity: 1;
      }
    }
  }

  &__title-text {
    display: inline-block;
    position: relative;
    padding: 3px 26px;
    line-height: 1;
    color: #fff;
    opacity: 0;
    transition: opacity 1s cubic-bezier(1, 0, 0, 1) .25s;
    margin: 0;
    background-color: #000;
    letter-spacing: 1px;
    font-size: 40px;
    font-weight: 300 !important;

    @media (max-width: 767px) {
      padding: 3px 13px;
    }

    &--active {
      animation: slide-in-left-to-right 0.75s ease-out forwards;
    }
  }

  &__line-curve {
    display: block;
    position: absolute;
    top: 10px;
    right: -105px;
    width: 274px;
    height: 140px;
    background: 0 0 no-repeat;
    background-image: url("#{$baseImgUrl}/line-curve.png");
    background-size: 274px 140px;

    @media (max-width: 767px) {
      width: 116px;
      height: 70px;
      top: 5px;
      right: auto;
      left: 60%;
      background-size: 137px 70px;
    }
  }

  @keyframes slide-in-left-to-right {
    0% { clip-path: inset(0 100% 0 0); }
    100% { clip-path: inset(0); }
  }
}
