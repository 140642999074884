$font-founders: 'Founders Grotesk', sans-serif;

$gray-66: #666;
$gray-e3: #e3e3e3;
$gray-99: #999;

$base-img-url: 'https://static.lenskart.com/owndays/img/contact-us';

.header-contact-us {
  &__container {
    width: 100%;
    height: auto;
    padding: 0 5%;
    max-width: 764px;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 80%;
      padding: 0;
    }
  }

  &__main {
    &__title {
      font-family: $font-founders;
      font-size: 20px;
      font-weight: normal !important;
      line-height: 1.5;
      margin: 0;
      color: black;
      padding-top: 34px;
      text-align: center;
      letter-spacing: 1px;

      span {
        display: block;
        font-family: $font-founders;
        font-weight: normal;
        font-size: 16px;
        text-align: left;
        line-height: 1.6;
        margin-top: 15px;
        color: $gray-66;
        letter-spacing: 1px;
      }
    }

    nav {
      padding: 30px 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-content: center;

      a {
        border: 1px solid $gray-e3;
        width: 100%;
        height: 115px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:nth-child(2) {
          border-left: 0;
          border-right: 0;
        }

        span {
          display: flex;
          justify-content: center;
          height: 50px;

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }

        p {
          font-family: $font-founders;
          font-size: 11px;
          font-weight: normal !important;
          line-height: 1.4;
          padding-top: 2px;
          margin: 0;
          color: black;
          letter-spacing: 1px;
        }
      }
    }

    &__tel {
      border: 6px solid $gray-e3;
      text-align: center;
      padding: 25px 10px;

      &__title {
        font-family: $font-founders;
        font-size: 15px;
        font-weight: normal !important;
        line-height: 1.6;
        color: black;
        margin: 0;
        letter-spacing: 1px;
      }

      &--number {
        color: black;
        font-family: $font-founders;
        font-size: 34px;
        font-weight: normal;
        line-height: 1;
        letter-spacing: 1px;
        margin: 0;

        &::before {
          content: '';
          display: inline-block;
          width: 30px;
          height: 38px;
          background-image: url("#{$base-img-url}/tel.svg");
          background-size: contain;
          background-position: 0 5px;
          background-repeat: no-repeat;
          width: 22px;
          height: 31px;
          margin-right: 3px;
        }
      }

      &--time {
        width: fit-content;
        height: auto;
        margin: 7px auto 0 auto;
        padding: 0 10px;
        background-color: $gray-e3;

        span {
          font-family: $font-founders;
          font-size: 12px;
          font-weight: normal !important;
          line-height: 2;
          letter-spacing: 1px;
          color: black;
        }
      }
    }

    &__list {
      padding: 0 0 40px 0;
      margin: 15px 0 0 0;
      list-style-type: none;

      li {
        color: $gray-99;
        font-family: $font-founders;
        font-size: 11px;
        font-weight: normal;
        line-height: 1.7;
        position: relative;
        padding-left: 12px;
        letter-spacing: 1px;

        &::before {
          content: "*";
          font-size: 14px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    @media (min-width: 768px) {
      &__title {
        font-size: 38px;
        padding-top: 70px;

        span {
          text-align: center;
        }
      }

      nav {
        padding: 55px 0 50px 0;
        grid-template-columns: 32% 32% 32%;
        column-gap: 2%;

        a {
          height: 235px;
          transition: 0.5s;
  
          &:nth-child(2) {
            border: 1px solid $gray-e3;
          }
  
          span {
            height: 80px;
  
            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }
  
          p {
            font-size: 15px;
            padding-top: 7px;
          }

          &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
          }
        }
      }

      &__tel {
        padding: 20px;

        > div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          margin-top: 5px;
          width: fit-content;
          margin: 5px auto 0 auto;
        }
  
        &--number {
          font-size: 44px;
          padding-right: 20px;

          &::before {
            margin-right: 5px;
          }
        }
  
        &--time {
          margin-top: 0;

          span {
            font-size: 16px;
          }
        }
      }

      &__list {
        padding: 0 0 70px 0;
      }
    }

    @media (min-width: 1024px) {
      nav a:hover {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}
