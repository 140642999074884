.body-kimetsu__sec {
  position: relative;
  padding-top: 23px;
  padding-bottom: 45px;
}

.body-kimetsu__sec::before, .body-kimetsu__sec::after {
  content: '';
  position: absolute;
}

.body-kimetsu__sec::before {
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.body-kimetsu__sec::after {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 358px;
}

.body-kimetsu__sec > .base-kimetsu__container > *:not(.body-kimetsu__sec-img):not(.body-kimetsu__sec-subtitle) {
  z-index: 2;
}

.body-kimetsu__sec-img {
  position: absolute;
  top: -40px;
  max-width: 764px;
  z-index: 1;
}

@media (max-width: 767px) {
  .body-kimetsu__sec-img {
    max-width: 360px;
  }
}

.body-kimetsu__sec-number {
  font-family: "Noto Serif JP", serif;
  font-size: 131px;
  font-weight: 300 !important;
  line-height: 188px;
}

.body-kimetsu__sec-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.body-kimetsu__sec-title img {
  width: auto;
  height: 82.5px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec-title img {
    height: 70px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec-title img {
    height: 55px;
  }
}

.body-kimetsu__sec-subtitle {
  position: relative;
  font-family: "Noto Serif JP", serif;
  font-size: 50px;
  font-weight: 500;
  color: #D6D6D6;
  letter-spacing: 2.5px;
  line-height: 56px;
  margin-bottom: 32px;
}

.body-kimetsu__sec-badge {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 148px;
  height: 148px;
  text-align: center;
  line-height: 1;
  color: white !important;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-owndays.svg") no-repeat center center/contain;
}

.body-kimetsu__sec-badge-header {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 11px;
  font-weight: 800 !important;
  margin-top: 12px;
  margin-bottom: 4px;
}

.body-kimetsu__sec-badge-body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 8px;
  font-weight: 700 !important;
  letter-spacing: 0.8px;
  margin-bottom: 4px;
}

.body-kimetsu__sec-badge-body span {
  display: block;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 10px;
  font-weight: 700 !important;
}

.body-kimetsu__sec-badge-body span strong {
  font-family: "NeuzeitGroBold";
  font-size: 20px;
  font-weight: normal !important;
  color: white !important;
}

.body-kimetsu__sec-badge-body span small {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 60%;
  line-height: 1;
}

.body-kimetsu__sec-badge-footer {
  font-size: 6px;
}

@media (min-width: 1024px) {
  .body-kimetsu__sec-badge {
    width: 206px;
    height: 206px;
  }
  .body-kimetsu__sec-badge-header, .body-kimetsu__sec-badge-body {
    line-height: 1.1;
  }
  .body-kimetsu__sec-badge-header {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 2px;
  }
  .body-kimetsu__sec-badge-body {
    font-size: 14px;
    margin-bottom: 0;
  }
  .body-kimetsu__sec-badge-body span {
    font-size: 12px;
    margin-top: 5px;
  }
  .body-kimetsu__sec-badge-body span strong {
    font-size: 18px;
  }
  .body-kimetsu__sec-badge-body span small {
    font-size: 8px;
  }
}

@media (min-width: 1024px) and (max-width: 1023px) {
  .body-kimetsu__sec-badge-body span small {
    display: none;
  }
}

@media (min-width: 1024px) {
  .body-kimetsu__sec-badge-footer {
    font-size: 8px;
  }
}

.body-kimetsu__sec-slider {
  margin-bottom: 131px !important;
  padding-left: 15px;
  padding-right: 15px;
}

.body-kimetsu__sec-slider > .slick-arrow {
  position: absolute;
  top: auto;
  bottom: -51px;
  width: 14px;
  height: 27px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.body-kimetsu__sec-slider > .slick-arrow::before {
  display: none;
}

.body-kimetsu__sec-slider > .slick-prev, .body-kimetsu__sec-slider > .slick-next {
  background-color: transparent;
  color: transparent;
  border: 0;
}

.body-kimetsu__sec-slider > .slick-prev {
  left: 0;
  background-image: url("https://static.lenskart.com/media/owndays/img/kimetsu/arrow-left-black.svg");
}

.body-kimetsu__sec-slider > .slick-next {
  right: 0;
  background-image: url("https://static.lenskart.com/media/owndays/img/kimetsu/arrow-right-black.svg");
}

.body-kimetsu__sec-slider > .slick-dots {
  position: absolute;
  left: 33px;
  right: 33px;
  bottom: -39.5px;
  width: auto;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.body-kimetsu__sec-slider > .slick-dots li {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0;
  height: 4px;
}

.body-kimetsu__sec-slider > .slick-dots li.slick-active button {
  opacity: 1;
}

.body-kimetsu__sec-slider > .slick-dots li button {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.1;
  color: transparent;
  border: 0;
}

.body-kimetsu__sec-slider > .slick-dots li button::before {
  display: none;
}

.body-kimetsu__sec-des {
  margin-bottom: 126px;
  letter-spacing: 1px;
  line-height: 2;
  color: black;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec-des {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec-des {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 13px;
    margin-bottom: 50px;
  }
}

.body-kimetsu__sec-details {
  position: relative;
  z-index: 2;
}

.body-kimetsu__sec-details-price {
  margin-bottom: 15px;
  color: black;
}

.body-kimetsu__sec-details-price h5, .body-kimetsu__sec-details-price p {
  margin: 0;
}

.body-kimetsu__sec-details-price h5 {
  font-family: "Noto Serif JP", serif;
  font-size: 24px;
  font-weight: 700 !important;
}

.body-kimetsu__sec-details-price h5 small {
  font-size: 14px;
}

.body-kimetsu__sec-details-price p {
  font-size: 10px;
}

.body-kimetsu__sec-details-btn {
  font-family: "Noto Serif JP", serif;
  font-size: 13px;
  font-weight: 700 !important;
  padding: 16px;
  display: block;
  text-align: center;
  color: white;
  margin-bottom: 28px;
  position: relative;
}

.body-kimetsu__sec-details-btn::before, .body-kimetsu__sec-details-btn::after {
  content: '';
  position: absolute;
}

.body-kimetsu__sec-details-btn::before {
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border: 1px solid rgba(255, 255, 255, .5);
}

.body-kimetsu__sec-details-btn::after {
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 8px;
  height: 14px;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/arrow-right.svg") no-repeat center center/contain;
}

.body-kimetsu__sec-details-btn-wrap[disabled] {
  pointer-events: none;
  position: relative;
}

.body-kimetsu__sec-details-btn-wrap[disabled]::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: rgba(128, 128, 128, .9);
}

.body-kimetsu__sec-details dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  color: #646464;
  letter-spacing: 1px;
  margin: 0;
}

.body-kimetsu__sec-details dl dt, .body-kimetsu__sec-details dl dd {
  line-height: 2 !important;
}

.body-kimetsu__sec-details dl dt {
  min-width: 85px;
  font-weight: 400 !important;
}

.body-kimetsu__sec-details dl dd {
  margin: 0;
}

@media (max-width: 767px) {
  .body-kimetsu__sec-details dl dt, .body-kimetsu__sec-details dl dd {
    line-height: 1.7 !important;
  }
}

@media (min-width: 1024px) {
  .body-kimetsu__sec-details-btn {
    margin-bottom: 0 !important;
  }
  .body-kimetsu__sec-details-right {
    padding-left: 15px;
  }
}

@media (min-width: 1024px) {
  .body-kimetsu__sec-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .body-kimetsu__sec-details-left {
    width: 540px;
  }
  .body-kimetsu__sec-slider, .body-kimetsu__sec-slider-nav, .body-kimetsu__sec-des {
    max-width: 540px;
    padding-left: 0;
    padding-right: 0;
  }
  .body-kimetsu__sec-subtitle {
    white-space: nowrap;
    position: absolute;
    top: 11.7%;
  }
}

@media (min-width: 1024px) {
  .body-kimetsu__sec-img {
    top: auto;
    bottom: 50px;
  }
}

@media (min-width: 768px) {
  .body-kimetsu__sec {
    padding-top: 80px;
    padding-bottom: 55px;
  }
  .body-kimetsu__sec::after {
    height: 272px;
  }
  .body-kimetsu__sec-number {
    font-size: 225px;
    line-height: 280px;
  }
  .body-kimetsu__sec-img {
    top: -300px;
  }
  .body-kimetsu__sec-slider {
    margin-bottom: 182px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .body-kimetsu__sec-slider .slick-arrow {
    bottom: -90px;
    width: 26px;
    height: 50px;
  }
  .body-kimetsu__sec-slider .slick-dots {
    left: 58px;
    right: 58px;
    bottom: -68px;
  }
  .body-kimetsu__sec-slider .slick-dots li button {
    height: 6px;
  }
  .body-kimetsu__sec-subtitle {
    font-size: 103px;
    line-height: 1.1;
    letter-spacing: 12px;
  }
  .body-kimetsu__sec-des {
    font-size: 14px;
    min-height: 145px;
  }
  .body-kimetsu__sec-details-price h5 {
    font-size: 31px;
    line-height: 1.2;
  }
  .body-kimetsu__sec-details-price h5 small {
    font-size: 12px;
  }
  .body-kimetsu__sec-details-btn {
    margin-bottom: 0;
    font-size: 16px;
    padding: 26px 16px;
    margin-bottom: 28px;
  }
  .body-kimetsu__sec-details-btn::before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
  .body-kimetsu__sec-details-btn:after {
    width: 15px;
    height: 28px;
    right: 20px;
  }
  .body-kimetsu__sec2-slider, .body-kimetsu__sec2-slider-nav, .body-kimetsu__sec2-des, .body-kimetsu__sec4-slider, .body-kimetsu__sec4-slider-nav, .body-kimetsu__sec4-des, .body-kimetsu__sec6-slider, .body-kimetsu__sec6-slider-nav, .body-kimetsu__sec6-des {
    margin-left: auto;
  }
  .body-kimetsu__sec2 .body-kimetsu__sec-details-left, .body-kimetsu__sec4 .body-kimetsu__sec-details-left, .body-kimetsu__sec6 .body-kimetsu__sec-details-left {
    text-align: right;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .body-kimetsu__sec2 .body-kimetsu__sec-details-right, .body-kimetsu__sec4 .body-kimetsu__sec-details-right, .body-kimetsu__sec6 .body-kimetsu__sec-details-right {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec-subtitle {
    margin-top: -19px;
  }
}

.body-kimetsu__sec1::before {
  top: 20%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-green-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec1::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55.svg") no-repeat center top/cover;
}

.body-kimetsu__sec1-img {
  right: -120px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec1-img {
    right: -180px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec1-img {
    right: -60px;
  }
}

.body-kimetsu__sec1-title {
  margin-left: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec1-title {
    margin-left: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec1-title {
    margin-left: -20px;
  }
}

.body-kimetsu__sec1-number {
  color: #2B7C62;
  margin-right: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec1-number {
    margin-right: 10px;
  }
}

.body-kimetsu__sec1-subtitle {
  left: 9%;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec1-subtitle {
    left: -6%;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec1-subtitle {
    margin-left: 4%;
  }
}

.body-kimetsu__sec1-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#1C5240));
  background-image: linear-gradient(#000000, #1C5240);
}

@media (min-width: 1024px) {
  .body-kimetsu__sec1 {
    z-index: 3;
  }
  .body-kimetsu__sec1::before {
    top: 28%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-green-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec1::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55@2x.svg") no-repeat center top/cover;
  }
}

.body-kimetsu__sec2::before {
  top: 20%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-pink-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec2::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55-2.svg") no-repeat center top/cover;
}

.body-kimetsu__sec2-img {
  left: -120px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec2-img {
    left: -180px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec2-img {
    left: -60px;
  }
}

.body-kimetsu__sec2-title {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-right: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec2-title {
    margin-right: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec2-title {
    margin-right: -20px;
  }
}

.body-kimetsu__sec2-subtitle {
  right: 9%;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec2-subtitle {
    right: -6%;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec2-subtitle {
    margin-right: 4%;
  }
}

.body-kimetsu__sec2-number {
  color: #A0334A;
  margin-left: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec2-number {
    margin-left: 10px;
  }
}

.body-kimetsu__sec2-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#561A2B));
  background-image: linear-gradient(#000000, #561A2B);
}

@media (min-width: 768px) {
  .body-kimetsu__sec2 {
    z-index: 2;
  }
  .body-kimetsu__sec2::before {
    top: 26%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-pink-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec2::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55-2@2x.svg") no-repeat center top/cover;
  }
}

.body-kimetsu__sec3::before {
  top: 21%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-orange-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec3::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55.svg") no-repeat center top/cover;
}

.body-kimetsu__sec3-img {
  right: -120px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec3-img {
    right: -180px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec3-img {
    right: -60px;
  }
}

.body-kimetsu__sec3-title {
  margin-left: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec3-title {
    margin-left: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec3-title {
    margin-left: -20px;
  }
}

.body-kimetsu__sec3-subtitle {
  left: 11.5%;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec3-subtitle {
    text-align: left;
    left: -6%;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec3-subtitle {
    margin-left: 4%;
  }
}

.body-kimetsu__sec3-number {
  color: #CE5623;
  margin-right: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec3-number {
    margin-right: 10px;
  }
}

.body-kimetsu__sec3-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#813515));
  background-image: linear-gradient(#000000, #813515);
}

@media (min-width: 768px) {
  .body-kimetsu__sec3 {
    z-index: 1;
  }
  .body-kimetsu__sec3::before {
    top: 27%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-orange-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec3::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55@2x.svg") no-repeat center top/cover;
  }
}

.body-kimetsu__sec4::before {
  top: 20.5%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-sky-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec4::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55-2.svg") no-repeat center top/cover;
}

.body-kimetsu__sec4-img {
  left: -120px;
}

@media (max-width: 767px) {
  .body-kimetsu__sec4-img {
    left: -8%;
  }
}

.body-kimetsu__sec4-title {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-right: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec4-title {
    margin-right: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec4-title {
    margin-right: -20px;
  }
}

.body-kimetsu__sec4-subtitle {
  right: 9%;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec4-subtitle {
    text-align: right;
    right: -6%;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec4-subtitle {
    margin-right: 4%;
  }
}

.body-kimetsu__sec4-number {
  color: #5585A4;
  margin-left: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec4-number {
    margin-left: 10px;
  }
}

.body-kimetsu__sec4-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#013C6F));
  background-image: linear-gradient(#000000, #013C6F);
}

@media (min-width: 768px) {
  .body-kimetsu__sec4 {
    z-index: 0;
  }
  .body-kimetsu__sec4::before {
    top: 27%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-sky-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec4::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55-2@2x.svg") no-repeat center top/cover;
  }
}

.body-kimetsu__sec5::before {
  top: 21%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-blue-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec5::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55.svg") no-repeat center top/cover;
}

.body-kimetsu__sec5-img {
  right: -120px;
  bottom: 40px;
  top: -160px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec5-img {
    top: -170px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec5-img {
    max-width: 350px;
    top: 37px;
    right: -60px;
  }
}

.body-kimetsu__sec5-title {
  margin-left: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec5-title {
    margin-left: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec5-title {
    margin-left: -20px;
  }
}

.body-kimetsu__sec5-subtitle {
  left: 9%;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec5-subtitle {
    text-align: left;
    left: -6%;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec5-subtitle {
    margin-left: 4%;
  }
}

.body-kimetsu__sec5-number {
  color: #283D86;
  margin-right: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec5-number {
    margin-right: 10px;
  }
}

.body-kimetsu__sec5-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#22336F));
  background-image: linear-gradient(#000000, #22336F);
}

@media (min-width: 768px) {
  .body-kimetsu__sec5::before {
    top: 28%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-blue-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec5::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55@2x.svg") no-repeat center top/cover;
  }
}

.body-kimetsu__sec6::before {
  top: 20.5%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-purple-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec6::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55-2.svg") no-repeat center top/cover;
}

.body-kimetsu__sec6-img {
  left: -120px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec6-img {
    left: -180px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec6-img {
    left: -16%;
  }
}

.body-kimetsu__sec6-title {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-right: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec6-title {
    margin-right: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec6-title {
    margin-right: -20px;
  }
}

.body-kimetsu__sec6-subtitle {
  right: 9%;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec6-subtitle {
    text-align: right;
    right: -6%;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec6-subtitle {
    margin-right: 4%;
  }
}

.body-kimetsu__sec6-number {
  color: #452D60;
  margin-left: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec6-number {
    margin-left: 10px;
  }
}

.body-kimetsu__sec6-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#452D60));
  background-image: linear-gradient(#000000, #452D60);
}

@media (min-width: 768px) {
  .body-kimetsu__sec6::before {
    top: 27%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-purple-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec6::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55-2@2x.svg") no-repeat center top/cover;
  }
}

.body-kimetsu__sec7::before {
  top: 21%;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-red-50.svg") no-repeat center top/100% auto;
}

.body-kimetsu__sec7::after {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55.svg") no-repeat center top/cover;
}

.body-kimetsu__sec7-img {
  right: -30%;
  top: -18%;
}

@media (max-width: 767px) {
  .body-kimetsu__sec7-img {
    max-width: 350px;
    top: 0px;
    right: -90px;
  }
}

@media (min-width: 1024px) {
  .body-kimetsu__sec7-img {
    top: -22rem;
    right: -10rem;
  }
}

.body-kimetsu__sec7-title {
  margin-left: -25px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec7-title {
    margin-left: -60px;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec7-title {
    margin-left: -20px;
  }
}

.body-kimetsu__sec7-subtitle {
  left: 9%;
  margin-bottom: 75px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec7-subtitle {
    text-align: left;
    left: -6%;
  }
}

@media (max-width: 767px) {
  .body-kimetsu__sec7-subtitle {
    margin-left: 4%;
  }
}

.body-kimetsu__sec7-badge {
  top: 16%;
  left: 5%;
}

@media (min-width: 1024px) {
  .body-kimetsu__sec7-badge {
    top: -5rem;
    left: 30rem;
  }
}

@media (min-width: 1296px) {
  .body-kimetsu__sec7-badge {
    top: 1rem;
    left: 41rem;
  }
}

.body-kimetsu__sec7-number {
  color: #C72838;
  margin-right: 22px;
}

@media (max-width: 1023px) {
  .body-kimetsu__sec7-number {
    margin-right: 10px;
  }
}

.body-kimetsu__sec7-details-btn {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#711422));
  background-image: linear-gradient(#000000, #711422);
}

@media (min-width: 768px) {
  .body-kimetsu__sec7::before {
    top: 28%;
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-red-50@2x.svg") no-repeat center top/100% auto;
  }
  .body-kimetsu__sec7::after {
    background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-white-55@2x.svg") no-repeat center top/cover;
  }
}
