
$base-img-url: 'https://static.lenskart.com/media/owndays/img/freaks-store';

$font-noto: "Noto Sans JP", sans-serif;
$font-din: din-condensed, sans-serif;

$green-55: #555E2B;
$white-f5: #F5F4EF;
$white-f7: #F7F7F7;

.header-freaks-store {
  &__main {
    position: relative;

    &__bg {
      img {
        aspect-ratio: 9 / 13;
      }
    }

    &__logo-container {
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
      padding: 0;
    }

    &__logo {
      margin: 0;
      background-color: rgba(255, 255, 255, 0.84);
      padding: 31px 0;

      img {
        width: 47%;
        height: auto;
        display: block;
        margin: 0 auto;
        aspect-ratio: 8 / 3;
      }
    }

    @media (min-width: 768px) {
      &__bg {
        img {
          aspect-ratio: 144 / 65;
        }
      }

      &__logo-container {
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 12vw;
      }

      &__logo {
        background: none;
        padding: 0;
        
        img {
          width: 22vw;
        }
      }
    }
  }

  &__concept {
    background-color: $green-55;
    padding: 74px 0 0;

    &__animate-typing {
      transition: 0s !important;
  
      &__text-split {
        font: inherit;
        display: inline;
        transition-duration: 0.1s;
        transition-property: top, opacity;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        position: relative;
        top: 0;
  
        &--empty {
          width: 0.5em;
        }
      }
  
      &:not(.aos-animate) {
        opacity: 0;
  
        .header-freaks-store__concept__animate-typing__text-split {
          transition-duration: 0s !important;
          transition-delay: 0s !important;
          top: 20px;
          opacity: 0;
        }
      }
    }

    &__title {
      font: 900 24px / 37px $font-noto;
      text-align: center;
      margin-bottom: 30px;
      letter-spacing: 1.5px;
      color: white;

      span {
        font-weight: inherit !important;
      }

      p {
        font: 400 14px / 26px $font-noto;
      }
    }

    &__vdo {
      display: block;
      position: relative;
      min-width: 100%;
      transition: none;
      margin-top: 81px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 11.1vw;
        max-width: 110px;
        background: url("#{$base-img-url}/play-icon.svg") center / contain no-repeat;
        z-index: 2;
      }

      picture {
        display: block;
        opacity: 1;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: black;
          pointer-events: none;
          z-index: -1;
        }
      }
    }

    p {
      font: 400 14px / 26px $font-noto;
      margin-bottom: 0;
      color: white;
    }

    @media (min-width: 768px) {
      .base-freaks-store__btn {
        margin-top: 39px;
        max-width: 380px;
      }

      .base-freaks-store__container {
        display: grid;
        grid-template-columns: 49% auto;
        align-items: center;
        gap: 20px;
        max-width: calc(1026px + 15px* 2);
      }

      &__title {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: 1.5px;
        white-space: nowrap;
        margin: 0;
      }

      &__vdo {
        width: 5.6vw;
      }
    }

    @media (min-width: 1024px) {
      padding: 80px 0 0;

      .base-freaks-store__container {
        grid-template-columns: auto 527px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  &__menu {
    padding: 37px 0 50px;
    background-color: $white-f7;

    .base-freaks-store__container {
      padding-top: 23px;
    }

    ul {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      padding: 0;
      margin: 0;

      li {
        position: relative;
      }
    }

    &__item {
      &--lineup {
        .header-freaks-store__menu__icon img {
          width: 30px;
          top: 18px;
        }
      }

      &--original {
        .header-freaks-store__menu__icon img {
          width: 22px;
          top: 10px;
        }
      }
    }

    &__icon {
      display: block;
      width: 60px;
      height: 60px;
      background: linear-gradient(0deg, rgba(85, 94, 43, 0) 36px, $green-55 0);
      border-radius: 50%;
      position: absolute;
      top: calc(23px * -1);
      left: 0;
      right: 0;
      margin: auto;
      isolation: isolate;
      z-index: 2;

      img {
        display: block;
        width: 30px;
        height: auto;
        top: 18px;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        opacity: 1 !important;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background-color: $white-f5;
        clip-path: polygon(0 0, 100% 0, 100% calc(23px + 1px), 0 calc(23px + 1px));
        border-radius: 50%;
        isolation: isolate;
      }

      &-hover {
        display: none !important;
      }
    }

    &__content {
      display: block;
      text-align: center;
      padding: 38px 10px 12px;
      background-color: $white-f5;
      border: 2px solid $green-55;
      position: relative;
      height: 100%;

      &-inner {
        color: $green-55;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 4;

        &::after {
          content: "";
          display: block;
          margin: auto auto 0;
          width: 22px;
          height: 11px;
          background-color: currentColor;
          clip-path: polygon(50% 100%, 0 0, 100% 0);
        }
      }

      &-title {
        font: 700 24px / 1 $font-din;
        margin: 0;
      }

      &-desc {
        display: block;
        font: 700 12px / 1 $font-noto;
        margin: 11px 0 15px;
        color: black;
      }
    }

    @media (min-width: 768px) {
      padding: 119px 0 130px;

      .base-freaks-store__container {
        padding-top: 51px;
      }

      ul {
        gap: 20px;
      }

      &__item {
        &--lineup {
          .header-freaks-store__menu__icon img {
            width: 60px;
            top: 38px;
          }
        }
  
        &--goods {
          .header-freaks-store__menu__icon img {
            width: 47px;
            top: 18px;
          }
        }

        a {
          color: transparent;
          &:hover, &:active, &:visited, &:focus {
            text-decoration: none !important;
          }
        }
      }

      &__icon {
        width: 140px;
        height: 140px;
        background: linear-gradient(0deg, rgba(85, 94, 43, 0) 87px, $green-55 0);
        top: calc(51px * -1);
  
        img {
          display: block;
          width: 30px;
          top: 18px;
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          opacity: 1 !important;
          z-index: 1;
        }
  
        &::after {
          clip-path: polygon(0 0, 100% 0, 100% calc(51px + 1px), 0 calc(51px + 1px));
        }
      }

      &__content {
        padding: 45px 10px 15px;

        &-inner {
          &::after {
            width: 38px;
            height: 20px;
          }
        }

        &-title {
          font-size: 42px;
        }

        &-desc {
          font-size: 14px;
          margin: 6px 0 20px;
        }
      }
    }

    @media (min-width: 1024px) {
      &__icon {
        img {
          &.header-freaks-store__menu__icon-hover {
            display: block;
            opacity: 0 !important;
          }
        }

        &-hover {
          display: block !important;
        }
      }

      &__item {
        cursor: pointer;
        
        &:hover {
          .header-freaks-store__menu__icon {
            img {
              &:not(.header-freaks-store__menu__icon-hover) {
                opacity: 0 !important;
              }
              &.header-freaks-store__menu__icon-hover {
                opacity: 1 !important;
              }
            }
          }

          .header-freaks-store__menu__content-inner, .header-freaks-store__menu__content-desc {
            transition: 0.5s;
            color: white;
          }

          .header-freaks-store__menu__content {
            transition: 0.5s;
            border: 2px solid $green-55;
          }

          .header-freaks-store__menu__icon::after, .header-freaks-store__menu__content {
            transition: 0.5s;
            background-color: $green-55;
          }
        }
      }
    }
  }
}
