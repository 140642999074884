.body-gift-ticket__concept__btn {
  width: 100%;
  max-width: 460px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  margin: 0 auto;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  cursor: pointer;
}

.body-gift-ticket__concept__btn:hover, .body-gift-ticket__concept__btn:active, .body-gift-ticket__concept__btn:visited, .body-gift-ticket__concept__btn:focus {
  text-decoration: none;
}

.body-gift-ticket__concept__btn--gray {
  font-weight: bold !important;
  background-color: #516075;
  color: #FFF;
}

.body-gift-ticket__concept__btn--white {
  background-color: #FFF;
  color: #666;
  font-weight: 500 !important;
  border: 1px solid #666;
}

@media (min-width: 768px) {
  .body-gift-ticket__concept__btn--gray:hover {
    background-color: #6b788a;
    color: #FFF;
  }
  .body-gift-ticket__concept__btn--white:hover {
    background-color: #666;
    color: #FFF;
  }
}

.body-gift-ticket__concept__header {
  width: 100%;
  height: auto;
  padding: 50px 5% 60px;
}

.body-gift-ticket__concept__header__title, .body-gift-ticket__concept__header__desc {
  font-family: "Founders Grotesk", sans-serif;
  margin-bottom: 30px;
}

.body-gift-ticket__concept__header__title {
  font-size: 18px;
  font-weight: 400 !important;
  text-align: center;
  line-height: 1.5;
}

.body-gift-ticket__concept__header__desc {
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
}

@media (min-width: 768px) {
  .body-gift-ticket__concept__header {
    padding: 80px 5% 130px;
    margin: 0 auto;
  }
  .body-gift-ticket__concept__header__title {
    font-size: 24px;
  }
  .body-gift-ticket__concept__header__desc {
    font-size: 16px;
    max-width: 614.39px;
    margin: 0 auto 50px;
  }
}

@media (min-width: 1024px) {
  .body-gift-ticket__concept__header__desc {
    max-width: 720px;
  }
}

.body-gift-ticket__concept__body-bg {
  padding: 0 5% 60px;
  position: relative;
}

.body-gift-ticket__concept__body-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F6F6F6;
  z-index: -1;
}

@media (min-width: 768px) {
  .body-gift-ticket__concept__body-bg {
    padding-bottom: 0;
  }
  .body-gift-ticket__concept__body-bg::before {
    top: 44px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.body-gift-ticket__concept__body {
  width: 100%;
  height: auto;
  position: relative;
}

.body-gift-ticket__concept__body__img {
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 210px;
  overflow-y: hidden;
}

.body-gift-ticket__concept__body__img img {
  display: block;
  width: 145px;
  height: auto;
  position: absolute;
  top: 16px;
  left: 50%;
  -webkit-transform: rotate(-8deg) translateX(-50%);
          transform: rotate(-8deg) translateX(-50%);
}

.body-gift-ticket__concept__body__desc, .body-gift-ticket__concept__body__remark {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  margin-bottom: 0;
}

.body-gift-ticket__concept__body__desc {
  font-size: 15px;
  line-height: 2;
}

.body-gift-ticket__concept__body__remark {
  font-size: 11px !important;
  line-height: 1.7;
  margin-top: 5px;
  position: relative;
  padding-left: 12px;
  color: #999;
}

.body-gift-ticket__concept__body__remark::before {
  content: '*';
  position: absolute;
  left: 0;
}

@media (min-width: 768px) {
  .body-gift-ticket__concept__body {
    display: grid;
    grid-template-columns: 247.5px 1fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 924px;
    margin: 0 auto;
  }
  .body-gift-ticket__concept__body__img {
    -webkit-transform: none;
            transform: none;
    height: 229px;
  }
  .body-gift-ticket__concept__body__img img {
    width: 100%;
    max-width: 167px;
  }
}

@media (min-width: 1024px) {
  .body-gift-ticket__concept__body {
    grid-template-columns: 495px 1fr;
  }
  .body-gift-ticket__concept__body__img {
    height: 458px;
  }
  .body-gift-ticket__concept__body__img img {
    width: 100%;
    max-width: 334px;
  }
  .body-gift-ticket__concept__body__desc {
    font-size: 16px;
  }
}

.body-gift-ticket__concept__footer {
  background-color: #FFF;
  padding: 60px 0 80px;
}

.body-gift-ticket__concept__footer__container {
  width: 100%;
  padding: 0 5%;
  max-width: 924px;
  margin: 0 auto;
}

.body-gift-ticket__concept__footer__title h2, .body-gift-ticket__concept__footer__title p {
  font-family: "Founders Grotesk", sans-serif;
  margin-bottom: 33px;
}

.body-gift-ticket__concept__footer__title h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1.5;
}

.body-gift-ticket__concept__footer__title p {
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
}

.body-gift-ticket__concept__footer__title p br {
  display: none;
}

.body-gift-ticket__concept__footer__list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 90px;
}

.body-gift-ticket__concept__footer__btn {
  width: 100%;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 1024px) {
  .body-gift-ticket__concept__footer {
    padding: 120px 0 140px;
  }
  .body-gift-ticket__concept__footer__container {
    display: grid;
    grid-template-columns: 1fr 450px;
    gap: 15px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 0 170px 0;
    max-width: 924px;
  }
  .body-gift-ticket__concept__footer__title h2 {
    text-align: left;
    font-size: 24px;
    margin-bottom: 24px;
  }
  .body-gift-ticket__concept__footer__title p {
    font-size: 16px;
    font-weight: normal;
    line-height: 2;
  }
  .body-gift-ticket__concept__footer__title p br {
    display: block;
  }
  .body-gift-ticket__concept__footer__list {
    margin-bottom: 0;
  }
}

.body-gift-ticket__how-to {
  background-color: #EDF8F6;
}

.body-gift-ticket__how-to__title {
  padding: 0 0 35px 0;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-weight: normal !important;
  line-height: 1;
  text-align: center;
  margin-bottom: 0;
}

.body-gift-ticket__how-to__container {
  padding-top: 60px;
}

.body-gift-ticket__how-to__container:last-child {
  position: relative;
}

.body-gift-ticket__how-to__container:last-child::before {
  content: '';
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  height: 1px;
  background-color: #e3e3e3;
}

.body-gift-ticket__how-to__list {
  list-style-type: none;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 227px 227px 227px;
  gap: 20px;
  overflow-x: scroll;
}

.body-gift-ticket__how-to__item {
  padding-bottom: 50px;
}

.body-gift-ticket__how-to__item img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.body-gift-ticket__how-to__item h5, .body-gift-ticket__how-to__item p {
  font-family: "Founders Grotesk", sans-serif;
}

.body-gift-ticket__how-to__item h5 {
  font-size: 16px;
  font-weight: bold !important;
  line-height: 1;
  margin-bottom: 8px;
  text-align: center;
}

.body-gift-ticket__how-to__item p {
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .body-gift-ticket__how-to__title {
    font-size: 24px;
    padding: 0 0 55px 0;
  }
  .body-gift-ticket__how-to__container {
    padding: 100px 5% 0 5%;
    max-width: 924px;
    margin: 0 auto;
  }
  .body-gift-ticket__how-to__list {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    padding: 0;
    margin-bottom: 0;
  }
  .body-gift-ticket__how-to__item {
    padding-bottom: 80px;
  }
  .body-gift-ticket__how-to__item img {
    margin-bottom: 24px;
  }
  .body-gift-ticket__how-to__item h5 {
    margin-bottom: 15px;
  }
}
