$clear-contactlens: #59b2e7;
$color-contactlens: #71475e;

$daily-blue: #26558d;
$natural: #b28247;
$horoscope: #324675;
$petal: #9aced6;
$velvet: #7198cf;

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}
.contact-category {
  &--clear-contactlens {
    h2::before {
      background-color: $clear-contactlens;
    }
    .contact-type__header-img span,
    .contact-type__features ul li h3 strong {
      color: $clear-contactlens;
    }
  }
  &--color-contactlens {
    h2::before {
      background-color: $color-contactlens;
    }
    .contact-type__header {
      background-repeat: no-repeat;
      background-size: cover;
      svg{
        display: none;
      }
    }
    .contact-type__header-img span,
    .contact-type__features ul li h3 strong {
      color: $color-contactlens;
    }
    .contact-type__header-img img{
      max-width: 440px;
      max-height: 335px;
    }
  }
  @media (max-width: 767px) {
    &--color-contactlens {
      .contact-type__header {
        &-logo {
          h1 {
            img {
              max-width: 150px;
            }
          }
        }
        &-img {
          order: -1;
          img {
            max-width: 300px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    &--clear-contactlens{
      .contact-type__header{
        &-grid{
          column-gap: 20px;
        }
        &-logo,&-img{
          flex: 1 0 0;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &--color-contactlens {
      .contact-type__header {
        padding-block: 224px;
      }
    }
  }
}
.contact-type {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.8px;
  width: 100%;
  overflow-x: hidden;
  img {
    display: block;
    width: 100%;
  }
  h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    line-height: 1;
    letter-spacing: 0.01rem;
    position: relative;
    margin: 0 0 60px;
    padding-bottom: 60px;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
    }
  }
  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1152px + 40px);
    &--lg {
      max-width: calc(1300px + 40px);
    }
    &--sm {
      max-width: calc(900px + 40px);
    }
  }
  &__header {
    position: relative;
    padding-block: 64px;
    background: url('https://static1.lenskart.com/media/owndays/img/contacts/bg-header.webp') repeat
      center/auto;
    svg {
      scale: -1;
      min-height: 50px;
      position: absolute;
      bottom: 0;
    }
    &-grid {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-logo {
      text-align: center;
      h1 {
        font: 0/0 a;
        margin: 0;
        img {
          max-width: 220px;
          margin-inline: auto;
        }
      }
      h3 {
        font-size: inherit;
        line-height: inherit;
        margin: 15px 0 0;
      }
      p {
        margin: 20px auto 0;
        background-color: #dadcd9;
        font-size: 13px;
        line-height: 1;
        padding: 12px 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 3px;
          left: 3px;
          width: 100%;
          height: 100%;
          border: 1px solid;
        }
      }
    }
    &-img {
      position: relative;
      img {
        margin-inline: auto;
        object-fit: contain;
      }
      > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 48px;
        right: 48px;
        width: 74px;
        height: 74px;
        background-color: white;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        z-index: 1;
        animation: pulse 4s infinite both;
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 74px;
          height: 74px;
          background-color: white;
          border-radius: 4px;
          z-index: -1;
        }
        &::before {
          rotate: 65deg;
        }
        &::after {
          rotate: -65deg;
        }
        > span {
          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 74px;
            height: 74px;
            background-color: white;
            border-radius: 4px;
            z-index: -1;
          }
          &::before {
            rotate: 25deg;
          }
          &::after {
            rotate: 45deg;
          }
        }
      }
    }
  }
  &__features {
    margin-block: 80px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px 48px;
      li {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 332px;
        img {
          max-width: 240px;
          margin-inline: auto;
          opacity: 0.3;
        }
        h3 {
          display: flex;
          flex-direction: column;
          font-size: 28px;
          font-weight: 300;
          letter-spacing: 2px;
          margin: 0 0 8px;
          strong {
            opacity: 0.5;
            font-family: inherit;
            font-size: 72px;
            font-weight: 600;
          }
        }
      }
    }
  }
  &__lineup{
    margin-block: 80px;
    &-list{
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px 15px;
      li{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 160px;
        .lens{
          height: 80px;
          object-fit: contain;
          filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3));
        }
        .name{
          line-height: 1.5;
          margin: 10px 0 20px;
        }
        .image{
          margin-top: auto;
          position: relative;
          span{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 10rem;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
            color: white;
            text-transform: uppercase;
            position: absolute;
            top: 0;
            right: -8px;
            transform: translateY(-50%);
            z-index: 1;
          }
        }
      }
    }
  }
  &__spec {
    background-color: #f4f4f4;
    padding-block: 80px;
    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;
      li {
        display: grid;
        padding-block: 20px;
        border-bottom: 1px solid black;
        &:first-child {
          border-top: 1px solid black;
        }
        h3,
        p {
          margin: 0;
        }
        h3 {
          font-weight: 700;
          font-size: 16px;
          line-height: inherit;
        }
      }
    }
    &-remark {
      list-style: none;
      padding: 0;
      margin: 10px 0 0;
      color: #999;
      font-size: 11px;
    }
  }
  &__shops {
    margin-block: 80px;
    &-list {
      list-style: none;
      padding: 30px 0;
      margin: 0;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      display: grid;
      column-gap: 20px;
      li {
        position: relative;
        padding-left: 1.5em;
        &::before {
          content: '';
          position: absolute;
          top: 12px;
          left: 8px;
          width: 4px;
          height: 4px;
          background-color: black;
          border-radius: 1rem;
        }
        a {
          color: black;
          text-decoration: none;
          transition: opacity 0.3s ease;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &--daily-blue {
    h2::before {
      background-color: $daily-blue;
    }
    .contact-type__header-img span,
    .contact-type__features ul {
      
      li h3 strong {
        color: $daily-blue;
      }
    }
    .contact-type__features ul {
      max-width: 1000px;
      margin-inline: auto;
    }
  }
  &--natural{
    h2::before,.contact-type__lineup-list li .image span {
      background-color: $natural;
    }
    .contact-type__header-img span,
    .contact-type__features ul {
      li h3 strong {
        color: $natural;
      }
    }
    .contact-type__header-logo p::before{
      border-color: $natural;
    }
  }
  &--horoscope{
    h2::before,.contact-type__lineup-list li .image span {
      background-color: $horoscope;
    }
    .contact-type__header-img span,
    .contact-type__features ul {
      li h3 strong {
        color: $horoscope;
      }
    }
    .contact-type__header-logo h3{
      color: white;
    }
    .contact-type__header-logo p::before{
      border-color: #999;
    }
  }
  &--petal{
    h2::before,.contact-type__lineup-list li .image span {
      background-color: $petal;
    }
    .contact-type__header-img span,
    .contact-type__features ul {
      li h3 strong {
        color: $petal;
      }
    }
    .contact-type__header-logo p::before{
      border-color: $petal;
    }
  }
  &--velvet{
    h2::before,.contact-type__lineup-list li .image span {
      background-color: $velvet;
    }
    .contact-type__header-img span,
    .contact-type__features ul {
      li h3 strong {
        color: $velvet;
      }
    }
    .contact-type__header-logo p::before{
      border-color: $velvet;
    }
  }
  @media (min-width: 768px) {
    &__header{
      &-grid{
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 80px;
      }
      &-img {
        > span {
          top: 25%;
          left: -24px;
          right: auto;
        }
      }
    }
    &__lineup{
      &-list{
        li{
          max-width: 200px;
        }
      }
    }
    &__spec {
      &-list {
        li {
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          padding-inline: 20px;
          justify-content: center;
        }
      }
    }
    &__shops {
      &-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  @media (min-width: 1024px) {
    h2{
      font-size: 50px;
    }
    &__header {
      &-img {
        > span {
          left: -48px;
          width: 90px;
          height: 90px;
          &::before,
          &::after {
            width: 90px;
            height: 90px;
          }
          > span {
            &::before,
            &::after {
              width: 90px;
              height: 90px;
            }
          }
        }
      }
    }
    &__features,&__lineup,&__shops{
      margin-block: 120px;
    }
    &__lineup{
      &-list{
        column-gap: 16px;
        li{
          max-width: 276px;
          .name{
            font-size: 24px;
          }
          .image{
            span{
              width: 70px;
              height: 70px;
              font-size: 15px;
            }
          }
        }
      }
    }
    &__spec {
      padding-block: 120px;
      &-list {
        li {
          grid-template-columns: 270px 300px;
        }
      }
    }
  }
}
