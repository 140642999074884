.od-gender-menu .menu-line-section {
  position: relative;
}

.od-gender-menu .nav-head {
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display: block;
  margin-bottom: 40px;
}

.od-gender-menu .nav-head-category {
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display: block;
  margin: -40px 0 40px 0;
  padding-left: 20px;
}

.od-gender-menu .nav-head-category-submenu {
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display: block;
  margin-bottom: 40px;
}

.od-gender-menu .od-gender__info, .od-gender-menu .od-gender__category-menu_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 25px 10px 20px;
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  max-width: 250px;
}

.od-gender-menu .od-gender__info:hover, .od-gender-menu .od-gender__category-menu_list:hover {
  background: #FAFAFA;
}

.od-gender-menu .od-gender__info span, .od-gender-menu .od-gender__category-menu_list span {
  width: 50%;
  margin-left: 20px;
  display: inline-block;
}

.od-gender-menu .od-gender__info .fa-angle-right, .od-gender-menu .od-gender__category-menu_list .fa-angle-right {
  padding-left: 30px;
  color: #000042;
}

.od-gender-menu .od-gender__info__img, .od-gender-menu .od-gender__category-menu_list__img {
  width: 50px;
  height: 50px;
}

.od-gender-menu .od-gender:not(:last-child) {
  margin-bottom: 20px;
}

.od-gender-menu .od-gender:hover .od-gender__category {
  display: block;
  position: absolute;
  left: 100%;
  top: -20px;
  width: 100%;
}

.od-gender-menu .od-gender-menu-section > .od-gender.active .od-gender__info {
  background-color: #FAFAFA;
}

.od-gender-menu .od-gender-menu-section > .od-gender.active .od-gender__info + .od-gender__category {
  position: absolute;
  left: 100%;
  width: 250px;
  display: block;
  background-color: #fff;
  top: -20px;
}

.od-gender-menu .od-gender-menu-section > .od-gender.active .od-gender__info + .od-gender__category.submenu {
  width: auto;
  min-height: 425px;
}

.od-gender-menu .od-gender-menu-section > .od-gender.active .od-gender__info + .od-gender__category .category-menu .category-menu_data.active {
  background-color: #FAFAFA;
  border-radius: 5px;
}

.od-gender-menu .od-gender-menu-section > .od-gender.active .od-gender__info + .od-gender__category .category-menu .category-menu_data.active .select-submenu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  min-height: 400px;
}

.od-gender-menu .od-gender-menu-section > .od-gender.active .od-gender__info + .od-gender__category .category-menu .category-menu_data.active .select-submenu .nav-subcategory-level {
  margin-top: 0;
}

.od-gender-menu .category-condition {
  background-color: #FAFAFA;
}
