$font-founders: "Founders Grotesk", sans-serif;

.header-eye-camp {
  position: relative;

  &__title {
    width: 100%;
    max-width: 762px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: transparent;
    padding: 0 10%;
    text-align: center;
    color: white;
    z-index: 2;

    h1 {
      font-family: $font-founders;
      font-size: 40px;
      font-weight: 400 !important;
      line-height: 1;
      margin: 0;

      span {
        display: block;
        font-size: 13px;
        margin-top: 5px;
        letter-spacing: 1px;
      }
    }

    @media (min-width: 768px) {
      width: 80%;
      max-width: 1152px;
      margin: 0 auto;

      h1 {
        span {
          margin-top: 20px;
        }
      }
    }
  }
}
