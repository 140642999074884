$base-img-url: 'https://static.lenskart.com/media/owndays/img/kuromi-collection';

$font-co: "Corporate Logo Rounded";
$font-he: "heisei-maru-gothic-std", sans-serif;

$purple-a6 :#a69cca;
$purple-bc: #bcadd3;
$blue-b8: #b8d5e7;
$pink-f9: #f99bb8;
$pink-fc: #fcbbc2;
$black-2b: #2b2045;

.header-kuromi-collection {
  &__checkered-pattern {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -50vw;
      bottom: -50vw;
      left: -50vw;
      right: -50vw;
      background: url("#{$base-img-url}/checkered-pattern.svg") center / 43px;
      transform: rotate(-11deg);
      z-index: 1;
      pointer-events: none;
    }
  }

  &__main {
    padding-top: 67px;
    padding-bottom: 30%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(308deg, $purple-a6, $blue-b8, $pink-f9);
      background-size: 600% 600%;
      animation: kuromiBackgroundAnimation 60s ease infinite;
      z-index: -1;

      @media (min-width: 768px) {
        background-image: url("#{$base-img-url}/main-bg-lg.webp");
        width: 100vw;
      }

      @media (min-width: 1224px) {
        left: calc((1224px - 100vw) / 2);
      }
    }

    h1 {
      font-size: 40px;
      line-height: 1;
      grid-area: h1;
      margin: 0;

      img {
        width: 66.3%;
        max-width: 280px;
        margin: 0 auto;
      }

      @media (min-width: 768px) {
        align-self: end;

        img {
          width: 100%;
          max-width: none;
        }
      }

      @media (min-width: 1024px) {
        img {
          width: 346px;
        }
      }
    }

    figure {
      grid-area: img;

      img {
        width: 87.5%;
        height: auto;
        display: block;
        max-width: 450px;
        margin: 0 auto;

        @media (min-width: 768px) {
          width: 100%;
          max-width: none;
        }
      }
    }

    &__content {
      text-align: center;
      display: flex;
      flex-direction: column;
      row-gap: 55px;
      position: relative;
      z-index: 2;

      @media (min-width: 768px) {
        display: grid;
        gap: 0 60px;
        grid-template-columns: 32% auto;
        grid-template-areas: "h1 img" "date img";
      }

      @media (min-width: 1024px) {
        grid-template-columns: auto 559px;
        justify-content: space-between;
        gap: 0 60px;
      }
    }

    &__release-date {
      margin: 0 auto;
      grid-area: date;
      text-align: center;

      &::before, &::after {
        content: '';
        width: 18px;
        height: 24px;
        background: url("#{$base-img-url}/star.svg") center / contain no-repeat;
      }

      h3 {
        display: inline-flex;
        align-items: center;
        column-gap: 12px;
        font: 600 29px / 1 $font-co;
        margin: 0;
      }

      p {
        font-size: 14px;
        line-height: 2.2em;
        letter-spacing: 0.07em;
        margin: 0;
      }

      @media (min-width: 768px) {
        margin-top: 30px;
      }

      @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        align-self: start;
        column-gap: 3px;
        margin-top: 39px;

        &::before, &::after {
          width: 13px;
          height: 18px;
        }

        h3 {
          font-size: 16px;
          line-height: 1;
        }

        p {
          font-size: 15px;
          line-height: 1;
          letter-spacing: 0.04em;
        }
      }
    }

    &__hashtag {
      position: absolute;
      top: 50%;
      right: -13px;
      min-width: 14px;
      width: 4.3%;
      height: auto;
      max-width: 25px;
      transform: translateY(calc(-50% + 13px));

      @media (min-width: 768px) {
        width: 25px;
        max-width: none;
      }

      @media (min-width: 1296px) {
        right: -110px;
      }
    }

    &__scroll {
      &-layout {
        text-align: center;
      }

      &-btn {
        font-size: 14px;
        padding: 8px 30px 8px 13px;
        display: inline-flex;
        align-items: center;
        background-color: $purple-bc;
        color: white;
        border-radius: 3em;
        margin-top: 55px;
        position: relative;
        z-index: 2;
        text-decoration: none;
        transition: .5s;
        cursor: pointer;

        &:hover {
          color: white;
        }

        &::before {
          content: '';
          width: 29px;
          height: 29px;
          background: url("#{$base-img-url}/arrow-down.svg") center / contain no-repeat;
          margin-right: 11px;
        }

        @media (min-width: 768px) {
          &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
          }
        }

        @media (min-width: 1024px) {
          font-size: 17px;
          padding: 10px 21px 9px 14px;
          margin-top: 41px;
        }
      }
    }

    @media (min-width: 768px) {
      padding-top: 120px;
      padding-bottom: 75px;
    }
  }

  &__intro {
    background-color: $purple-bc;
    padding-top: 60px;
    padding-bottom: 300px;
    position: relative;
    color: black;

    &::before {
      content: '';
      position: absolute;
      bottom: calc(100% - 2px);
      left: 0;
      right: 0;
      height: 22.6vw;
      background: url("#{$base-img-url}/intro-top-shape.svg") top center / cover no-repeat;
      pointer-events: none;
    }

    &__content-1 {
      position: relative;
      padding: 0 0 10px 7px;
      z-index: 4;

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        bottom: 3px;
        left: 0;
        right: 7px;
        background-color: $pink-fc;
        border: 3px solid black;
        border-radius: 3px;
      }

      &-inner {
        background-color: white;
        padding: 64px 30px 45px 22px;
        border: 3px solid $black-2b;
        border-radius: 3px;
        position: relative;
        z-index: 2;
      }

      &-top {
        position: absolute;
        top: 0;
        left: 50%;
        width: 90%;
        max-width: 770px;
        transform: translate(-50%, -52%);
        pointer-events: none;
      }

      &-title {
        display: block;
        width: 100%;
        margin: 0 auto 34px;
      }

      p {
        font-size: 15px;
        line-height: 2.5;
        letter-spacing: 2;
        margin: 0;
      }

      &-highlight-text {
        display: inline-block;
        width: auto;
        height: 24px;
        vertical-align: middle;
        margin-top: -5px;
      }

      &-character {
        display: block;
        width: 190px;
        margin: 10px auto 0;
      }

      @media (min-width: 768px) {
        max-width: 883px;
        margin: 0 auto;
        padding: 0 0 20px 16px;

        &::before {
          top: 25px;
          right: 18px;
          border-width: 6px;
        }

        &-inner {
          padding: 112px 92px 50px 92px;
          border-width: 6px;
        }

        &-character {
          position: absolute;
        }
      }

      @media (min-width: 1024px) {
        &-inner {
          padding-bottom: 90px;
        }

        &-title {
          width: 500px;
          margin-bottom: 57px;
        }

        p {
          font-size: 22px;
        }

        &-highlight-text {
          height: 36px;
        }

        &-character {
          bottom: -7px;
        }
      }

      @media (min-width: 1296px) {
        &-character {
          width: 318px;
          right: -198px;
        }
      }
    }

    &__content-2 {
      margin-top: 105px;
      position: relative;
      z-index: 4;

      &-inner {
        background-color: white;
        padding: 50px 30px 1px;
        box-shadow: 10px 10px 0 $black-2b;
        position: relative;
      }

      &-img {
        display: block;
        width: 95px;
        margin: 0 auto 45px;
      }

      &-title {
        font: 700 23px / 1 $font-co;
        padding-bottom: 18px;
        text-align: center;
        background: url("#{$base-img-url}/intro-2-dot-sp.svg") center bottom no-repeat;
        display: inline-table;
        line-height: 1.3;
        background-image: none;
        margin: 0 0 10px 0;

        @media (min-width: 768px) {
          letter-spacing: 0.06em;
          background-repeat: repeat-x;
        }
      }

      p {
        font: 800 13px / 2.5 $font-he;
        margin: 0;
        letter-spacing: 0;
        line-height: 2.1;
      }

      &-character {
        display: block;
        width: 190px;
        margin: 15px auto -36px;
      }

      @media (min-width: 768px) {

      }

      @media (min-width: 1024px) {
        margin-top: 200px;

        &-inner {
          padding: 53px 16px 53px 89px;
          box-shadow: 14px 14px 0 $black-2b;
          display: grid;
          grid-template-columns: 108px auto;
          column-gap: 70px;
          align-items: center;
        }

        &-img {
          width: 100%;
          margin: 0;
        }

        &-detail {
          position: relative;
        }

        &-title {
          font-size: 24px;
        }

        p {
          font-size: 14px;
          max-width: 545px;
        }

        &-character {
          width: 200px;
          margin: 0;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      @media (min-width: 1296px) {
        p {
          width: 100%;
        }
      }
    }

    @media (min-width: 768px) {
      padding-top: 310px;
      padding-bottom: 370px;

      .base-kuromi-collection__container {
        padding-top: 0;
        padding-bottom: 0;
      }

      &::before {
        height: 54px;
        background: url("#{$base-img-url}/intro-top-shape-pc.webp") center bottom / 100% 100% no-repeat;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url("#{$base-img-url}/intro-bg-pc.webp") top 100px center / 140% auto no-repeat;
        z-index: 2;
        pointer-events: none;
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: 400px;

      &::after {
        background-size: 1478px auto;
      }
    }

    @media (min-width: 1400px) {
      padding-bottom: 500px;
    }
  }
}

@-webkit-keyframes kuromiBackgroundAnimation {
  0%{background-position:0% 9%}
  50%{background-position:100% 92%}
  100%{background-position:0% 9%}
}

@-moz-keyframes kuromiBackgroundAnimation {
  0%{background-position:0% 9%}
  50%{background-position:100% 92%}
  100%{background-position:0% 9%}
}

@keyframes kuromiBackgroundAnimation {
  0%{background-position:0% 9%}
  50%{background-position:100% 92%}
  100%{background-position:0% 9%}
}
