$font-founders: 'Founders Grotesk', sans-serif;

$gray-e3: #e3e3e3;
$gray-99: #999;
$gray-f4: #f4f4f4;
$gray-6c: #6c757d;
$red-c7: #c7002e;
$blue-00: #0094c8;

$base-img: 'https://static.lenskart.com/owndays/img/gift-cards';

.body-gift-cards-products {
  &__form {
    section {
      margin-bottom: 50px;
    }

    &-title {
      color: black;
      font-family: $font-founders;
      font-size: 14px;
      font-weight: bold !important;
      line-height: 1;
      margin-bottom: 20px;
      text-align: left;
      letter-spacing: 1px;
    }

    &-remain-message {
      color: $gray-99;
      font-family: $font-founders;
      font-size: 11px;
      font-weight: normal !important;
      letter-spacing: 1px;
      line-height: 1.7;
      margin-bottom: 10px;
      text-align: right;
    }

    &-error-message {
      color: $red-c7;
      font-family: $font-founders;
      font-size: 15px;
      font-weight: normal;
      line-height: 2;
      margin: 0;
    }

    &-card-design {
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: -4px;
        overflow-y: scroll;
        max-height: 80vw;

        li {
          width: calc(100% / 3);
          height: fit-content;
          padding: 4px;

          label {
            display: block;
            margin: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 31 / 23;
            }
          }
        }
      }

      &__selected {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 3px solid $blue-00;
          border-radius: 4px;
        }
      }
    }

    &-card-denomination {
      
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: -4px;

        li {
          width: calc(100% / 3);
          height: auto;
          padding: 4px;

          &:last-child {
            width: calc((100% / 3)* 2);
          }

          label {
            display: block;
            border: 1px solid $gray-e3;
            border-radius: 4px;
            text-align: center;
            font-family: $font-founders;
            font-size: 17px;
            font-weight: normal !important;
            letter-spacing: 1px;
            padding: 16px 20px 17px;
            line-height: 1;
            color: black;
            margin: 0;
          }
          
          &:last-child {
            label {
              padding: 6px 10px 5px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      &__selected {
        border: 2px solid $blue-00 !important;
      }

      input[type="number"] {
        -webkit-appearance: auto !important;
        appearance: auto !important;
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: auto !important;
      }

      &__custom-price {
        font-family: $font-founders;
        cursor: pointer;
        background-color: $gray-f4;
        border-color: $gray-f4;
        border-style: solid;
        font-size: 14px;
        padding: 9px 10px 8px;
        width: 100%;
        margin-left: 5px;
      }

      &__remark {
        font-family: $font-founders;
        font-size: 11px;
        font-weight: normal !important;
        letter-spacing: 1px;
        position: relative;
        padding-left: 1em;
        color: $gray-99;
        line-height: 1.7;
        margin: 10px 0 0 0;

        &::before {
          content: '*';
          position: absolute;
          left: 0;
        }
      }
    }

    &-message {
      .body-gift-cards-products__form-title {
        margin-bottom: 0;
      }

      textarea {
        width: 100%;
        min-height: 190px;
        height: auto;
        border-width: 0;
        padding: 20px 15px;
        background-color: $gray-f4;
        cursor: pointer;
        font-family: $font-founders;
        font-size: 15px;
        letter-spacing: 1px;
        resize: vertical;
        outline: none;

        &::placeholder {
          color: $gray-6c;
        }
      }
    }
    
    &-name {
      .body-gift-cards-products__form-title {
        margin-bottom: 0;
      }

      input {
        width: 100%;
        height: auto;
        border-width: 0;
        padding: 15px 15px 16px;
        background-color: $gray-f4;
        cursor: pointer;
        font-family: $font-founders;
        font-size: 15px;
        letter-spacing: 1px;

        &::placeholder {
          color: $gray-6c;
        }
      }
    }

    @media (min-width: 768px) {
      &-title {
        font-size: 16px;
      }

      &-card-design {
        ul {
          margin: -5px;
          max-height: 53vw;
  
          li {
            width: calc(100% / 4);
            padding: 5px;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      &-card-design {
        ul {
          max-height: 325px;

          li {
            label {
              cursor: pointer;
            }
          }
        }
      }
      
      &-card-denomination {
        ul {
          li {
            width: auto;
            flex: 1 1 auto;

            &:last-child {
              width: auto;
            }

            label {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
