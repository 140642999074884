.yt-modal__overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(30, 30, 30, .9);
  padding: 5%;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.yt-modal__content {
  position: relative;
  aspect-ratio: 16/9;
}

.yt-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(30, 30, 30, .6);
  color: #ccc;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
}

.yt-modal__iframe {
  width: 100%;
  height: 100%;
  border: none;
}
