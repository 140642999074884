.body-owndays-pc__about__top, .body-owndays-pc__about__middle {
  padding: 60px 0;
}

.body-owndays-pc__about__top {
  padding-top: 50px;
}

.body-owndays-pc__about__middle {
  background-color: #f9f9f9;
  margin-bottom: 60px;
}

.body-owndays-pc__about__middle ul {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  max-width: 820px;
  margin: 0 auto;
  padding-left: 0;
  list-style-type: none;
}

.body-owndays-pc__about__middle ul li h5 {
  font-family: "NeuzeitGroReg";
  font-weight: 400 !important;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 1.3;
  margin: 0;
}

.body-owndays-pc__about__middle ul li p {
  font-family: "Space Mono", monospace;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 1.4;
  min-height: 25.19px;
  margin: 0;
}

@media (min-width: 768px) {
  .body-owndays-pc__about__middle ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .body-owndays-pc__about__top {
    padding-top: 90px;
    padding-bottom: 100px;
  }
  .body-owndays-pc__about__top .base-owndays-pc__btn {
    margin-left: 0;
  }
  .body-owndays-pc__about__middle {
    margin-bottom: 100px;
  }
  .body-owndays-pc__about__middle ul li h5 {
    font-size: 14px;
  }
  .body-owndays-pc__about__middle ul li p {
    font-size: 20px;
    min-height: 28px;
  }
  .body-owndays-pc__about__middle ul li:nth-child(1) p {
    min-width: 294px;
  }
  .body-owndays-pc__about__middle ul li:nth-child(2) p {
    min-width: 111px;
  }
}

.body-owndays-pc__points {
  padding: 100px 0;
}

.body-owndays-pc__points__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  max-width: 240px;
  margin: 0 auto 60px;
  list-style-type: none;
  padding-left: 0;
}

.body-owndays-pc__points__list li img {
  margin-bottom: 20px;
}

.body-owndays-pc__points__list li h5 {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 15px;
}

.body-owndays-pc__points__list li p {
  font-family: "NeuzeitGroReg";
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin: 0;
}

@media (min-width: 768px) {
  .body-owndays-pc__points__list {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1050px;
    margin-bottom: 100px;
  }
}

@media (min-width: 1024px) {
  .body-owndays-pc__points {
    padding-top: 180px;
  }
  .body-owndays-pc__points__list {
    grid-column-gap: 120px;
  }
  .body-owndays-pc__points__list li h5, .body-owndays-pc__points__list li p {
    font-size: 16px;
  }
}

.body-owndays-pc__what__top {
  padding: 0 0 20px 0;
}

.body-owndays-pc__what__blue-light {
  margin: 0 auto;
  max-width: 800px;
}

.body-owndays-pc__what__slider {
  margin: 60px 0 100px;
}

.body-owndays-pc__what__slider .slick-list {
  padding: 0 50% !important;
}

@media (min-width: 1024px) {
  .body-owndays-pc__what__top {
    padding: 80px 0 60px 0;
  }
  .body-owndays-pc__what__slider {
    margin: 100px 0 186px 0;
  }
  .body-owndays-pc__what__slider .slick-list {
    padding: 0 !important;
  }
}
