@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$base-img-url: 'https://static.lenskart.com/media/owndays/img/kimetsu';

$font-se-jp: "Noto Serif JP", serif;
$font-sa-noto: "Noto Sans JP", sans-serif;

html {
  font-size: inherit;
}

.base-kimetsu {
  background: url("#{$base-img-url}/bg_ptn.jpg") repeat top / contain;
  background-attachment: fixed;
  font-family: $font-sa-noto;
  font-weight: 400 !important;
  letter-spacing: 1px;
  overflow-x: hidden;

  &__container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 5%;

    @media (min-width: 768px) {
      padding: 0 8%;
    }

    @media (min-width: 1024px) {
      padding: 0 25px;
    }
  }

  img {
    backdrop-filter: blur(0);
  }

  a {
    &:hover {
      @media (max-width: 767px) {
        opacity: 1;
        color: white;
      }
    }
  }

  &__show {
    &--xl {
      display: none !important;
    }

    @media (min-width: 1296px) {
      &--xl {
        display: block !important;
      }
    }
  }

  &__hide {
    &--pc {
      display: block !important;

      @media (min-width: 768px) {
        display: none !important;
      }
    }

    &--tb {
      display: none !important;

      @media (min-width: 1024px) {
        display: block !important;
      }
    }

    &--xl {
      display: block !important;

      @media (min-width: 1200px) {
        display: none !important;
      }
    }

    &--sp {
      display: none !important;

      @media (min-width: 768px) {
        display: block !important;
      }
    }
  }
}
