.od-cart-bill-details {
  width: 387px;
  margin-bottom: 24px;

  &__title {
    color: #444;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.48px;
    margin-bottom: 24px;
  }

  &__rows {
    display: grid;
    padding: 16px 16px 0 16px;
    grid-template-columns: auto min-content;
    align-items: center;
    row-gap: 8px;
    column-gap: 32px;
		white-space: nowrap;
    background: #fff;
  }

  &__item {
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  &__item-price {
    color: #444;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;

    &--free {
      color: #4D9855;
    }
  }

  &__total-wrapper {
    padding-bottom: 16px;
  }

  &__total {
    color: #444;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  &__total-price {
    text-align: right;
    color: #444;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
  }

  &__button-proceed {
    display: flex;
    width: 100%;
    padding: 16px 24px;
    border: 1px solid #546073;
    border-radius: 50px;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 21px;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    background-color: #546073;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  &__unlocked-discount {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    margin-top: 17px;
    color: #4D9855;
    font-size: 16px;
    img {
      margin-top: -4px;
    }
  }

  &__unlocked-discount--exclude {
    color: #000;
    margin-top: 0px;
  }

  &__button-icon {
    font-size: 24px;
  }

  &__coupon-gift-btns {
    display: flex;
    width: 100%;
    margin-top: 17px;
    gap: 16px;
  }

  &__features {
    display: flex;
    padding: 16px;
    margin-top: 24px;
    justify-content: center;
    align-items: stretch;
    gap: 26px;
  }

  &__delivery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;

    &__title {
      color: #444;
      font-size: 24px;
      letter-spacing: -0.48px;
    }

    &__change {
      color: #546073;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.32px;
      text-decoration-line: underline;
    }
  }

  &__feature {
    display: flex;
    max-width: 60px;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    max-width: 24px;
    max-height: 24px;
  }

  &__feature-title {
    margin-top: 8px;
    text-align: center;
    color: #444;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-transform: capitalize;
  }
}

.od-cart-bill-details-membership {
  padding: 16px;
  background-color: #fff;

  &__checkbox-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }

  &__checkbox {
    display: flex;
    width: 24px;
    height: 24px;
    position: relative;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      border: 2px solid #546073;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    &--checked {
      background: url(/assets/svg/od-checkbox-grey-icon.svg);

      &::after {
        display: none;
      }
    }
  }

  &__checkbox-label {
    color: #546073;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  &__discount {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    align-items: center;
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
}
