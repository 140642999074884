.main-lineup-freak-store__main-slide {
  max-width: 650px;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 53px;
  position: relative;
}

.main-lineup-freak-store__main-slide > .slick-dots {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  margin: 12px 0 0 0;
}

.main-lineup-freak-store__main-slide > .slick-dots > li, .main-lineup-freak-store__main-slide > .slick-dots > li > button {
  width: 40px;
  height: 5px;
}

.main-lineup-freak-store__main-slide > .slick-dots > li > button {
  border: 0;
  border-radius: 0;
  opacity: 1;
  margin: 0;
  background-color: #ccc;
  color: transparent;
}

.main-lineup-freak-store__main-slide > .slick-dots .slick-active > button {
  background-color: black;
}

.main-lineup-freak-store__content-slider__title {
  margin: 0 -15px;
  padding-top: 28px;
  padding-bottom: 28px;
  border-top: 1px solid #555E2B;
  border-bottom: 1px solid #555E2B;
}

.main-lineup-freak-store__content-slider__title h2, .main-lineup-freak-store__content-slider__title p {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  margin: 0;
  font-weight: normal;
}

.main-lineup-freak-store__content-slider__title p {
  font-size: 15px;
  line-height: 2;
}

.main-lineup-freak-store__content-slider__title p span {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  font-size: 14px;
}

.main-lineup-freak-store__content-slider__title h2 {
  font-size: 32px;
  font-weight: bold !important;
  line-height: 38px;
}

.main-lineup-freak-store__content-slider__title--highlight {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  color: #9D674F;
  letter-spacing: 0.87px;
}

.main-lineup-freak-store__content-slider__sub-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin: 20px -15px;
  font-size: 27px;
  font-family: din-condensed, sans-serif;
  letter-spacing: 0.81px;
  font-weight: bold !important;
  line-height: 1.5;
}

.main-lineup-freak-store__content-slider__message {
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  margin: 0;
}

@media (min-width: 768px) {
  .main-lineup-freak-store__main-slide {
    margin-top: 0px;
    padding-bottom: 0;
  }
  .main-lineup-freak-store__main-slide > .slick-dots {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    gap: 10px;
    margin: 12px 0 0 0;
  }
  .main-lineup-freak-store__main-slide > .slick-dots > li, .main-lineup-freak-store__main-slide > .slick-dots > li > button {
    width: 50px;
    height: 5px;
  }
  .main-lineup-freak-store__detail-2way {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .main-lineup-freak-store__detail-2way--row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .main-lineup-freak-store-slider {
    width: 50%;
  }
  .main-lineup-freak-store__content-slider {
    width: 50%;
    margin-top: -45px;
  }
  .main-lineup-freak-store__content-slider__title {
    margin: auto;
    margin-right: -15px;
    padding: 28px;
  }
  .main-lineup-freak-store__content-slider__title h2, .main-lineup-freak-store__content-slider__title p {
    text-align: left;
  }
  .main-lineup-freak-store__content-slider__title p span {
    font-size: 18px;
  }
  .main-lineup-freak-store__content-slider__title--reverse {
    margin-right: inherit !important;
    margin-left: -100vw !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .main-lineup-freak-store__content-slider__title--reverse h2, .main-lineup-freak-store__content-slider__title--reverse p {
    max-width: 540px !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 1123px) {
  .main-lineup-freak-store__content-slider__title--reverse h2, .main-lineup-freak-store__content-slider__title--reverse p {
    max-width: 32.6% !important;
  }
}

@media (min-width: 768px) {
  .main-lineup-freak-store__content-slider__detail {
    padding: 0 30px;
  }
  .main-lineup-freak-store__content-slider__detail--reverse {
    padding: 0 73px 0 0px !important;
  }
  .main-lineup-freak-store__content-slider__sub-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 40px 0 15px 0;
  }
}

@media (min-width: 1024px) {
  .main-lineup-freak-store {
    padding-bottom: 120px;
  }
  .main-lineup-freak-store__frame-section {
    margin-top: 80px;
  }
  .main-lineup-freak-store__main-slide {
    margin-top: 0px;
  }
  .main-lineup-freak-store__content-slider__title {
    margin-right: -100vw;
    padding: 35px 73px 30px 73px;
  }
  .main-lineup-freak-store__content-slider__title h2 span {
    font-size: 32px;
    line-height: 60px;
  }
  .main-lineup-freak-store__content-slider__detail {
    padding: 0 0px 0 73px;
  }
  .main-lineup-freak-store__content-slider__sub-title {
    font-size: 32px;
  }
  .main-lineup-freak-store__content-slider__message {
    font-size: 16px;
  }
}
