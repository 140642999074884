.upload-prescription-sidebar {
  overflow: hidden;
}

.upload-prescription-sidebar__product {
  width: 100%;
  padding: 24px 32px 16px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.upload-prescription-sidebar__product__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
  color: #000042;
  line-height: 20px;
}

.upload-prescription-sidebar__product__info__order-id {
  font-size: 14px;
  font-family: 'Founders Grotesk', sans-serif;
}

.upload-prescription-sidebar__product__info__title {
  font-size: 14px;
  font-family: 'NeuzeitGroReg', sans-serif;
}

.upload-prescription-sidebar__product__info__description {
  font-size: 12px;
  font-family: 'NeuzeitGroReg', sans-serif;
}

.upload-prescription-sidebar__product .od-pc-image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.upload-prescription-sidebar__product .od-pc-image-container .od-pc-image {
  height: 128px;
}

.upload-prescription-sidebar__cross {
  display: none;
}

.upload-prescription-sidebar__order-id {
  padding: 0 34px 24px 34px;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.28px;
  color: rgba(68, 68, 68, 0.69804);
}

.upload-prescription-sidebar__title {
  padding: 0 34px 24px 34px;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.48px;
  color: #444;
}

.upload-prescription-sidebar__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 64px 34px 16px 34px;
}

.upload-prescription-sidebar__heading__back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.upload-prescription-sidebar__heading__back-btn img {
  height: 24px;
  opacity: 0.7;
}

.upload-prescription-sidebar__heading__back-btn__text {
  font-size: 16px;
  color: rgba(68, 68, 68, 0.69804);
  font-family: 'NeuzeitGroReg', sans-serif;
}

.upload-prescription-sidebar__heading__close {
  cursor: pointer;
}

.upload-prescription-sidebar__heading__close img {
  height: 24px;
  opacity: 0.7;
}

.upload-prescription-sidebar__heading__title {
  font-size: 24px;
  line-height: 36px;
  color: #000042;
  font-family: 'Founders Grotesk', sans-serif;
}

.upload-prescription-sidebar__content {
  padding: 28px 34px;
  height: calc(100vh - 108px);
  overflow: scroll;
  padding-bottom: 200px;
}

.upload-prescription-sidebar__content .upload-prescription-sidebar__collapaseMenu {
  background: #fff;
  border-radius: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 32px;
  border: 1px solid rgba(84, 96, 115, .1);
  padding: 28px 16px;
}

.upload-prescription-sidebar__content .upload-prescription-sidebar__collapaseMenu-title {
  font-family: 'Founders Grotesk', sans-serif;
  color: #444;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
}

.upload-prescription-sidebar__content .upload-prescription-sidebar__collapaseMenu-title > div:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.upload-prescription-sidebar__content .upload-prescription-sidebar__collapaseMenu-body-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 24px 16px;
  color: #444;
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.4px;
  cursor: pointer;
  background-color: #fafafa;
  margin-top: 16px;
  border-radius: 10px;
}

.upload-prescription-sidebar__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 24px 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fafafa;
}

.upload-prescription-sidebar__footer__price {
  color: #444;
  line-height: 24px;
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 24px;
}

.upload-prescription-sidebar__footer__price span {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 32px;
}

.upload-prescription-sidebar__footer button {
  cursor: pointer;
}

.saved-prescription-sidebar-panel {
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.saved-prescription-sidebar-panel__img {
  margin-top: 24px;
}

.saved-prescription-sidebar-panel__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px 32px 32px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  background-color: #fafafa;
}

.saved-prescription-sidebar-panel__footer button {
  cursor: pointer;
}

.saved-prescription-sidebar-panel .saved-prescription-radio {
  background-color: #fafafa;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;
}

.saved-prescription-sidebar-panel .saved-prescription-radio__left_name {
  color: #444;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.24px;
}

.saved-prescription-sidebar-panel .saved-prescription-radio__left_date {
  color: rgba(68, 68, 68, .7);
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.24px;
}

.saved-prescription-sidebar-panel .saved-prescription-radio__right_check_uncheck {
  width: 18px;
}

.upload-prescription-sidebar-panel__heading-footer {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  list-style: disc;
  list-style-type: disc;
  margin-bottom: 8px;
  color: #333368;
}

.upload-prescription-sidebar-panel__list {
  padding-left: 12px;
}

.upload-prescription-sidebar-panel__list_item {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  list-style: disc;
  list-style-type: disc;
  padding-left: 16px;
  margin-bottom: 20px;
  color: #333368;
}

.upload-prescription-sidebar-panel__uploadBtn button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000042;
  background-color: #fafafa;
  border: none;
  cursor: pointer;
  margin: 16px 0 24px 0;
}

.upload-prescription-sidebar-panel__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px 32px 32px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fafafa;
}

.upload-prescription-sidebar-panel__footer button {
  cursor: pointer;
}
