.od-payment-page {
  display: flex;
  padding: 30px 90px 115px;
  flex-direction: column;
  justify-content: space-between;
  gap: 170px;
  background-color: #f7f7f7;
  min-height: calc(100vh - 83px);

  &__content {
    display: grid;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    grid-template-columns: 3.5fr 1fr;
    gap: 40px;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__add-items {
    width: 100%;
    padding-top: 28px;
    justify-content: space-between;

    span {
      color: #444;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
    }
  }
}

.od-payment-page__success {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: calc(100vh - 83px);
  .od-payment-page-d-success-image {
    margin-top: -100px;
  }
}

