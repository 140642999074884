.articles {
  padding-block: 50px;
}

.articles__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1060px;
}

.articles__title {
  text-align: center;
  font: 400 20px/1.5 "Founders Grotesk", sans-serif;
  margin-block: 0 40px;
}

.articles__list {
  display: grid;
  gap: 30px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.articles__list li a {
  display: grid;
  gap: 15px;
  text-decoration: none;
  color: #000;
}

.articles__list li img {
  aspect-ratio: 7/4;
}

.articles__list li h3 {
  font: 400 13px/1.5 "Founders Grotesk", sans-serif;
  margin: 0;
}

@media (max-width: 767px) {
  .articles__list li a {
    grid-template-columns: 3fr 7fr;
  }
}

@media (min-width: 768px) {
  .articles__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .articles {
    padding-block: 90px;
  }
  .articles__title {
    margin-bottom: 80px;
    font-size: 30px;
  }
  .articles__list {
    gap: 40px 20px;
  }
  .articles__list li a {
    gap: 12px;
  }
  .articles__list li a:hover img {
    opacity: .6;
  }
  .articles__list li img {
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
  }
  .articles__list li h3 {
    font-size: 14px;
  }
}
