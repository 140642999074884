.service_l {
  margin: 0 auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}

@media (max-width: 1023px) {
  .service_l {
    margin: 0 auto 60px;
  }
}

.service_l__section {
  position: relative;
  overflow: hidden;
  display: block;
}

.service_l__container {
  width: 90%;
  max-width: 1296px;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container {
    width: 100%;
    padding: 0 5%;
  }
}

.service_l__container-retail {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container-retail {
    width: 100%;
    padding: 0;
  }
}

.service_l__container-warranty {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1296px;
}

@media (max-width: 767px) {
  .service_l__container-warranty {
    width: 100%;
    padding: 0 5%;
  }
}

.staff-section__content {
  position: relative;
  margin-top: 80px;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .staff-section__content {
    margin-top: 25px;
    padding: 0;
  }
}

.staff-section__content-inner {
  width: 80%;
  max-width: 1152px;
  margin: 0 auto;
  border: 1px solid #e3e3e3;
}

@media (max-width: 767px) {
  .staff-section__content-inner {
    width: 100%;
  }
}

.staff-section__content-title {
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  padding: 18px 0 13px;
  font-family: "Founders Grotesk", sans-serif;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1;
  margin: 0;
}

@media (max-width: 767px) {
  .staff-section__content-title {
    padding: 15px 20px;
    line-height: 1.3;
    font-weight: 400;
  }
}

@media (max-width: 1023px) {
  .staff-section__content-title {
    font-weight: 400;
  }
}

.staff-section__content-description {
  padding: 20px;
  margin: 0;
  font-size: 15px;
  font-family: "Founders Grotesk", sans-serif;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 2;
}

.staff-section__content-table {
  padding: 0 20px 20px;
}

.staff-section__content-table table {
  border: 1px solid #e3e3e3;
  width: 100%;
  border-collapse: collapse;
}

.staff-section__content-table table th {
  border-bottom: 1px solid #e3e3e3;
  vertical-align: middle;
  width: 100px;
  text-align: center;
}

.staff-section__content-table table th img {
  width: 45px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .staff-section__content-table table th {
    border-bottom: none;
    width: 100%;
    display: block;
    margin-top: 20px;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@media (max-width: 1023px) {
  .staff-section__content-table table th.-last {
    border-bottom: 0;
  }
}

.staff-section__content-table table td {
  border-left: 1px dashed #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}

.staff-section__content-table table td h3 {
  font-size: 16px;
  padding: 20px 20px 0;
  font-weight: 600;
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 2;
}

@media (max-width: 767px) {
  .staff-section__content-table table td h3 {
    padding: 10px 0 0;
    text-align: center;
  }
}

.staff-section__content-table table td p {
  font-size: 15px;
  padding: 10px 20px 20px;
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  color: #000;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 2;
}

@media (max-width: 767px) {
  .staff-section__content-table table td {
    border-left: 0;
    display: block;
    width: 100%;
  }
  .staff-section__content-table table td p {
    letter-spacing: 0.8px;
  }
}

@media (max-width: 1023px) {
  .staff-section__content-table table td.-last {
    display: block;
    width: 100%;
    border-bottom: 0;
    padding: 0;
    margin: 0;
  }
  .staff-section__content-table table td.-last p {
    letter-spacing: 0.8px;
  }
}

@media (max-width: 1023px) {
  .staff-section__content-table table {
    margin: 0;
  }
}
