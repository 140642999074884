.od-login-page {
  position: fixed;
  inset: 0;
  z-index: 9999;
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.od-login-page input[type='date'] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 50px;
}

.od-login-page .od-login-page-number-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.od-login-page .od-login-page-number-wrapper_code {
  width: 60px;
  padding-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.od-login-page .od-login-page-number-wrapper_code::-webkit-input-placeholder {
  color: #000;
}

.od-login-page .od-login-page-number-wrapper_code::-moz-placeholder {
  color: #000;
}

.od-login-page .od-login-page-number-wrapper_code::-ms-input-placeholder {
  color: #000;
}

.od-login-page .od-login-page-number-wrapper_code::placeholder {
  color: #000;
}

.od-login-page .od-login-page-number-wrapper_code--disabled {
  opacity: 0.5;
}

.od-login-page .od-login-page-number-wrapper_number {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.od-login-page .od-login-page-number-wrapper_number--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.od-login-page-left {
  padding: 48px;
  background: url("https://static1.lenskart.com/media/owndays/img/customer/account/auth-bg_pc.webp") no-repeat left top/cover;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-weight: 400;
  line-height: normal;
}

.od-login-page-left__back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 10rem;
  margin-bottom: 24px;
  font: 0/0 a;
}

.od-login-page-left__heading {
  font: 0/0 a;
  margin: 0 0 80px;
}

.od-login-page-left__heading img {
  display: block;
  width: 100%;
  max-width: 342px;
}

.od-login-page-left__benefits {
  list-style: none;
  padding: 0;
  margin: 0 0 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  max-width: 624px;
}

.od-login-page-left__benefits li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background: rgba(76, 76, 76, .4);
  backdrop-filter: blur(4px);
  padding: 16px;
}

.od-login-page-left__benefits li p {
  font-size: 20px;
  margin: 0;
}

.od-login-page-left__copyright {
  margin-block: auto 0;
  font-size: 18px;
  text-align: center;
}

.od-login-page-right {
  position: relative;
  background-color: #fafafa;
  z-index: 9999999999;
  padding-block: 46px;
  overflow-y: auto;
}

.od-login-page-right-h1 {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 24px;
  text-align: center;
  margin: 0 0 24px 0;
  padding: 0px 30px;
  letter-spacing: -2%;
}

@media (min-width: 900px) {
  .od-login-page-right-h1 {
    margin: 0 0 50px 0;
  }
}

.od-login-page-right-flex {
  display: -webkit-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.od-login-page-right-form {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
  padding: 24px;
  border-radius: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 60%;
}

@media (max-width: 1024px) {
  .od-login-page-right-form {
    width: 80%;
  }
}

.od-login-page-right-form h4 {
  font-family: 'Founders Grotesk', sans-serif;
  line-height: 26px;
  font-size: 24px;
}

.od-login-page-right-form input,
.od-login-page-right-form select {
  display: block;
  padding: 12px 16px;
  background-color: #fafafa;
  border: none;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  width: 100%;
}

.od-login-page-right-form-fp {
  text-align: right;
  font-family: 'Founders Grotesk', sans-serif;
  color: #444444;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 24px;
}

.od-login-page-right-form-sb {
  width: 100%;
  background-color: #546073;
  padding: 12px 20px;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 20px;
  margin-top: 24px;
  -webkit-transition: opacity 0.3s ease, background-color 0.3s ease;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.od-login-page-right-form-sb:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #8090a3;
}

.od-login-page-right-form-sb:not(:disabled):hover {
  background-color: #3a4350;
}

.od-login-page .od-login-page-input-error {
  color: #a94442;
  margin-bottom: 16px;
  margin-top: 16px;
}

.od-login-page .od-login-page-phone-error {
  color: #a94442;
  margin-bottom: 16px;
}

.od-login-page .divider-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.od-login-page .divider-line-left {
  border: 1px solid rgba(0, 0, 0, 0.06275);
  margin-right: 20px;
  width: 100%;
  height: 1px;
}

.od-login-page .divider-line-text {
  font-family: 'Founders Grotesk', sans-serif;
}

.od-login-page .divider-line-right {
  border: 1px solid rgba(0, 0, 0, 0.06275);
  margin-left: 20px;
  width: 100%;
  height: 1px;
}

.od-login-page .od-login-page-number-h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}

.od-login-page .od-login-page-number-h .od-login-page-right-form-fp {
  margin-bottom: 0px;
}

.od-login-page .od-login-page-number-h .od-login-page-right-form-fp__disabled {
  color: #444444;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
