.od-applied-gift-cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  grid-column: span 2;

  &__card {
    padding: 10px 15px;
    background-color: rgba(84, 96, 115, 0.10);
    color: #546073;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}
