.information {
  font-family: 'Founders Grotesk', sans-serif;
  line-height: 1.5;
  letter-spacing: 1px;
  padding-block: 50px;
}

.information__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1060px;
}

.information__title {
  text-align: center;
  margin: 0 0 40px;
  font-size: 20px;
  font-weight: 400;
  color: black;
}

.information__genre {
  list-style: none;
  padding: 0;
  margin: 0 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.information__genre-btn {
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  line-height: 1;
  letter-spacing: 1px;
  position: relative;
  color: black;
}

.information__genre-btn::before {
  content: '';
  position: absolute;
  left: auto;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: black;
  -webkit-transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.information__genre-btn:hover::before {
  left: 0;
  width: 100%;
  right: auto;
}

.information__genre-btn.active::before {
  width: 100%;
}

.information__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 30px;
}

.information__card {
  display: grid;
  color: black;
}

.information__card-cover {
  aspect-ratio: 7/4;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.information__card-date {
  font-size: 12px;
  margin: 0 0 6px;
  color: #666;
}

.information__card-title {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.information__card:hover, .information__card:focus {
  text-decoration: none;
  color: inherit;
}

.information__pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 50px;
  gap: 5px;
}

.information__pagination-btn {
  height: 32px;
  min-width: 32px;
  padding-inline: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: black;
  border-radius: 3px;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.information__pagination-btn:hover, .information__pagination-btn:focus {
  color: black;
  background-color: transparent;
}

.information__pagination-btn.active {
  background-color: black;
  color: white;
}

@media (max-width: 767px) {
  .information__card {
    grid-template-columns: 3fr 7fr;
    gap: 15px;
  }
}

@media (min-width: 768px) {
  .information {
    padding-block: 90px;
  }
  .information__title {
    font-size: 24px;
    margin-bottom: 80px;
  }
  .information__genre {
    margin-bottom: 70px;
    gap: 25px;
  }
  .information__genre-btn {
    font-size: 14px;
  }
  .information__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 20px;
  }
  .information__card {
    gap: 15px;
  }
  .information__card-date {
    margin-bottom: 3px;
  }
  .information__card-title {
    font-size: 14px;
    line-height: 1.7;
  }
  .information__card:hover .information__card-cover {
    opacity: 0.6;
  }
  .information__card:hover .information__card-title {
    color: #999;
  }
  .information__pagination-btn:not(.active):hover {
    background-color: #e9ecef;
  }
}

@media (min-width: 1024px) {
  .information__title {
    font-size: 30px;
  }
}
