.od-header-owndays-progressive-cp {
  width: 100%;
  height: auto;
  position: relative;
}

.od-header-owndays-progressive-cp__container {
  width: 100%;
  height: auto;
  aspect-ratio: auto 1321 / 584;
}

.od-header-owndays-progressive-cp__container__desc {
  position: absolute;
  left: 120px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (max-width: 1438px) {
  .od-header-owndays-progressive-cp__container {
    -o-object-position: left 100px bottom 0px;
       object-position: left 100px bottom 0px;
  }
}

@media (max-width: 1208px) {
  .od-header-owndays-progressive-cp__container__desc {
    left: 5%;
  }
}

@media (max-width: 1024px) {
  .od-header-owndays-progressive-cp__container {
    -o-object-position: left 150px bottom 0px;
       object-position: left 150px bottom 0px;
  }
}

@media (max-width: 895px) {
  .od-header-owndays-progressive-cp__container {
    content: url("https://static.lenskart.com/media/owndays/img/progressive-cp/main-sp-10-2024.webp");
    min-height: 658px;
    max-height: 850px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: initial;
       object-position: initial;
    margin-top: 346px;
  }
  .od-header-owndays-progressive-cp__container__desc {
    position: absolute;
    left: 50%;
    top: 13%;
    -webkit-transform: translate(-50%, 0px);
            transform: translate(-50%, 0px);
  }
}

@media (max-width: 595px) {
  .od-header-owndays-progressive-cp__container {
    -o-object-fit: cover;
       object-fit: cover;
    min-height: auto;
    max-height: none;
    margin-top: 236px;
  }
  .od-header-owndays-progressive-cp__container__desc {
    top: 80px;
    width: 100%;
    max-width: 83.85%;
  }
}

@media (max-width: 430px) {
  .od-header-owndays-progressive-cp__container {
    margin-top: 247px;
  }
}

.od-header-owndays-progressive-cp__header {
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
}

.od-header-owndays-progressive-cp__header__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 5px;
  height: 63px;
}

.od-header-owndays-progressive-cp__header--black, .od-header-owndays-progressive-cp__header--red {
  font-family: "Founders Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600 !important;
}

.od-header-owndays-progressive-cp__header--black {
  color: #000;
  font-size: 30px;
  margin-top: 8px;
  line-height: 36px;
  letter-spacing: 3px;
}

.od-header-owndays-progressive-cp__header--red {
  color: #BF0000;
  font-size: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 10px;
}

@media (max-width: 595px) {
  .od-header-owndays-progressive-cp__header {
    padding-bottom: 20px;
  }
  .od-header-owndays-progressive-cp__header__main {
    gap: 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 5px;
    height: 38px;
  }
  .od-header-owndays-progressive-cp__header--black {
    font-size: 24px;
    margin: 3px 0 0 0;
    letter-spacing: 3px;
    line-height: 24px;
  }
  .od-header-owndays-progressive-cp__header--red {
    font-size: 60px;
    letter-spacing: 6px;
  }
}

@media (max-width: 430px) {
  .od-header-owndays-progressive-cp__header--black {
    font-size: 20px;
    letter-spacing: 2px;
  }
}

.od-header-owndays-progressive-cp__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2px;
  padding: 30px 0 0 0;
}

.od-header-owndays-progressive-cp__body__tag-pc, .od-header-owndays-progressive-cp__body h3 {
  font-family: "Founders Grotesk", sans-serif;
}

.od-header-owndays-progressive-cp__body__tag-pc, .od-header-owndays-progressive-cp__body__tag-sp {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px;
  background-color: #BF0000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 36px;
  letter-spacing: 1px;
  color: white;
  margin-bottom: 0;
}

.od-header-owndays-progressive-cp__body__tag-pc__price, .od-header-owndays-progressive-cp__body__tag-sp__price {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 1.5px;
  padding: 0 5px 0 10px;
}

.od-header-owndays-progressive-cp__body__tag-sp {
  display: none;
}

.od-header-owndays-progressive-cp__body h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 1.2px;
  margin: 24px 0 0 0;
}

.od-header-owndays-progressive-cp__body h3::after {
  content: '';
  display: block;
  height: 6px;
}

@media (max-width: 1274px) {
  .od-header-owndays-progressive-cp__body {
    padding: 30px 0 0 0;
  }
  .od-header-owndays-progressive-cp__body h3 {
    font-size: 18px;
    letter-spacing: 0.9px;
  }
}

@media (max-width: 1024px) {
  .od-header-owndays-progressive-cp__body::after {
    height: 4px;
  }
  .od-header-owndays-progressive-cp__body h3 {
    line-height: normal;
  }
}

@media (max-width: 1024px) and (max-width: 776px) {
  .od-header-owndays-progressive-cp__body h3 br {
    display: none;
  }
}

@media (max-width: 895px) {
  .od-header-owndays-progressive-cp__body__tag-pc {
    display: none;
  }
  .od-header-owndays-progressive-cp__body__tag-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .od-header-owndays-progressive-cp__body__tag-sp--now {
    margin: 10px 0 0 auto;
  }
}

@media (max-width: 595px) {
  .od-header-owndays-progressive-cp__body {
    padding: 20px 0 0 0;
  }
  .od-header-owndays-progressive-cp__body__tag-sp {
    font-size: 18px;
    padding: 0 11px 0 9px;
  }
  .od-header-owndays-progressive-cp__body__tag-sp__price {
    font-size: 24px;
    letter-spacing: 1.2px;
  }
  .od-header-owndays-progressive-cp__body__tag-sp--now {
    margin: 10px 0 0 auto;
  }
  .od-header-owndays-progressive-cp__body h3 {
    margin-top: 16px;
  }
}

@media (max-width: 476px) {
  .od-header-owndays-progressive-cp__custom-breadcrumbs {
    margin-top: 76px;
  }
  .od-header-owndays-progressive-cp__custom-breadcrumbs nav ol li {
    font-size: 10px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }
  .od-header-owndays-progressive-cp__custom-breadcrumbs nav ol li::before {
    padding: 0 8px;
  }
}

.od-header-owndays-progressive-cp__remark {
  position: absolute;
  right: 122px;
  bottom: 10px;
  color: #FFF;
  text-align: right;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, .35);
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal !important;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.7px;
}

@media (max-width: 895px) {
  .od-header-owndays-progressive-cp__remark {
    width: 100%;
    height: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
  }
}

@media (max-width: 595px) {
  .od-header-owndays-progressive-cp__remark {
    font-size: 12px;
    bottom: 4px;
  }
}
