.od-coupon-items-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.od-coupon-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
}

.od-coupon-items-multiple {
  color: rgba(0, 0, 0, 0.50196);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.od-coupon-items-multiple li {
  list-style-type: disc;
}

.od-coupon-items-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 40px;
}

.od-coupon-items-header__title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #000000;
}

.od-coupon-items-header__icon {
  cursor: pointer;
}

.od-coupon-items-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.od-coupon-items-add__input {
  display: block;
  width: 100%;
  height: 56px;
  padding: 16px 14px;
  border: 1px solid #e2e2ee;
  background-color: #ffffff;
  font-family: Raleway;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
}

.od-coupon-items-add__error {
  font-family: Raleway;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #a94442;
}

.od-coupon-tems-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  background-color: #ffffff;
  overflow: hidden;
}

.od-coupon-tems-item__title {
  font-family: Raleway;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.od-coupon-tems-item__description {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  text-decoration: none;
}

.od-coupon-tems-item__terms-conditions {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  text-decoration: none;
}

.od-coupon-tems-item__button {
  padding: 0;
  border: none;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
  text-transform: capitalize;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  background-color: transparent;
}

.od-coupon-tems-item__divider {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  margin: 0;
}
