.goods-freaks-store {
  background-color: white;
}

.goods-freaks-store__button .base-freaks-store__container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.goods-freaks-store__button .base-freaks-store__btn {
  margin: auto;
}

@media (min-width: 768px) {
  .goods-freaks-store__button .base-freaks-store__btn {
    width: 380px;
  }
}

.goods-freaks-store__text {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
}

.goods-freaks-store__text--brown {
  color: #9D674F;
}

.goods-freaks-store__header {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 30px;
}

.goods-freaks-store__header__title-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}

.goods-freaks-store__header__title-text, .goods-freaks-store__header__title-subtitle {
  margin: 0;
}

.goods-freaks-store__header__title-text {
  font-family: din-condensed, sans-serif;
  font-weight: bold !important;
  font-size: 42px;
  line-height: 34px;
  letter-spacing: 2.1px;
  color: #555E2B;
}

.goods-freaks-store__header__title-subtitle {
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  font-style: normal !important;
  font-weight: 700;
  line-height: 25px;
  color: black;
}

.goods-freaks-store__header__description-layout {
  padding: 40px 0 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.goods-freaks-store__header__description-title, .goods-freaks-store__header__description-subtitle {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  color: black;
}

.goods-freaks-store__header__description-title {
  font-size: 14px;
  position: relative;
  padding: 0 20px;
}

.goods-freaks-store__header__description-title::before {
  position: absolute;
  top: 2px;
  left: 0;
  content: url("https://static.lenskart.com/media/owndays/img/freaks-store/original-goods-slash-left-icon.svg");
}

.goods-freaks-store__header__description-title::after {
  position: absolute;
  top: 4px;
  right: 0;
  content: url("https://static.lenskart.com/media/owndays/img/freaks-store/original-goods-slash-right-icon.svg");
}

.goods-freaks-store__header__description-subtitle {
  font-size: 32px;
}

@media (min-width: 768px) {
  .goods-freaks-store__header {
    padding-bottom: 96px;
  }
  .goods-freaks-store__header__title-layout {
    gap: 13px;
  }
  .goods-freaks-store__header__title-text {
    font-size: 70px;
    line-height: 57px;
  }
  .goods-freaks-store__header__description-layout {
    padding: 81px 0 40px 0;
  }
  .goods-freaks-store__header__description-title {
    font-size: 20px;
  }
}

.goods-freaks-store__typing-effect {
  -webkit-clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
          clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
}

.goods-freaks-store__typing-effect[data-aos="goods-typing-animation"].aos-animate {
  -webkit-animation: typing-effect 1s ease forwards;
          animation: typing-effect 1s ease forwards;
}

.goods-freaks-store__message {
  width: 100%;
  height: auto;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 2;
  color: black;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .goods-freaks-store__message {
    width: 660px;
    font-size: 16px;
  }
}

.goods-freaks-store__how-to {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 0;
  padding-bottom: 60px;
  gap: 10px;
}

.goods-freaks-store__how-to__main-layout {
  width: 330px;
  height: 239px;
  position: relative;
  margin: 0 auto;
}

.goods-freaks-store__how-to__image {
  width: 100%;
  max-width: 352px;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.goods-freaks-store__how-to__image-step-01, .goods-freaks-store__how-to__image-step-02, .goods-freaks-store__how-to__image-step-03 {
  height: auto;
  position: absolute;
  cursor: pointer;
}

.goods-freaks-store__how-to__image-step-01 {
  width: 31px;
  top: 0;
  left: 24px;
}

.goods-freaks-store__how-to__image-step-02 {
  width: 74px;
  top: 0;
  right: 18px;
}

.goods-freaks-store__how-to__image-step-03 {
  width: 55px;
  bottom: 0;
  top: 163px;
  left: 177px;
}

.goods-freaks-store__how-to__point {
  width: 222px;
  height: auto;
  margin: 0 auto;
  position: relative;
}

.goods-freaks-store__how-to__point img {
  display: block;
  width: 165px;
  height: auto;
  margin: 0 auto;
}

.goods-freaks-store__how-to__point > .slick-dots {
  padding: 0;
  list-style-type: none;
  margin: 0;
  position: absolute;
  top: 180px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  z-index: 1;
}

.goods-freaks-store__how-to__point > .slick-dots li, .goods-freaks-store__how-to__point > .slick-dots li > button {
  width: 40px;
  height: 5px;
}

.goods-freaks-store__how-to__point > .slick-dots li > button {
  border: 0;
  opacity: 1;
  margin: 0;
  background-color: #ddd;
  color: transparent;
}

.goods-freaks-store__how-to__point > .slick-dots > .slick-active > button {
  background-color: black;
}

.goods-freaks-store__how-to__point__label {
  margin: 40px auto 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}

.goods-freaks-store__how-to__point__label--circle-bg {
  width: 30px;
  height: 30px;
  background-color: #555E2B;
  border-radius: 50%;
  position: relative;
}

.goods-freaks-store__how-to__point__label--circle-bg span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: din-condensed, sans-serif;
  font-weight: bold !important;
  font-size: 18px;
  letter-spacing: 0.54px;
  color: white;
}

.goods-freaks-store__how-to__point__label--name {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  font-size: 18px;
  line-height: 18px;
  color: #555E2B;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .goods-freaks-store__how-to {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 38px;
    padding-bottom: 132px;
  }
  .goods-freaks-store__how-to .base-freaks-store__container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0;
    margin: 0;
  }
  .goods-freaks-store__how-to__main-layout {
    margin: 0;
  }
  .goods-freaks-store__how-to__point {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .goods-freaks-store__how-to__main-layout {
    width: 624px;
    height: 413px;
    margin: 0;
  }
  .goods-freaks-store__how-to__image {
    width: 624px;
    max-width: none;
  }
  .goods-freaks-store__how-to__image-step-01, .goods-freaks-store__how-to__image-step-02, .goods-freaks-store__how-to__image-step-03 {
    height: auto;
    position: absolute;
    cursor: pointer;
  }
  .goods-freaks-store__how-to__image-step-01 {
    width: 57px;
    top: 0;
    left: 46px;
  }
  .goods-freaks-store__how-to__image-step-02 {
    width: 130px;
    top: 0;
    right: 43px;
  }
  .goods-freaks-store__how-to__image-step-03 {
    width: 103px;
    top: unset;
    bottom: 0;
    left: 334px;
  }
  .goods-freaks-store__how-to__point img {
    width: 230px;
    height: 230px;
  }
  .goods-freaks-store__how-to__point > .slick-dots {
    top: 250px;
  }
}

.goods-freaks-store__multiway-design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  padding: 0 0 30px 0;
}

.goods-freaks-store__multiway-design .goods-freaks-store__header__description-subtitle {
  font-size: 22px;
  line-height: 1.1;
}

@media (min-width: 768px) {
  .goods-freaks-store__multiway-design {
    padding: 0 0 60px 0;
  }
  .goods-freaks-store__multiway-design .goods-freaks-store__header__description-subtitle {
    font-size: 32px;
  }
}

.goods-freaks-store__design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .goods-freaks-store__design {
    padding: 0;
  }
}

.goods-freaks-store__design-slider {
  list-style-type: none;
  padding: 0 0 21px 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: thin !important;
}

.goods-freaks-store__design-slider::-webkit-scrollbar {
  display: block !important;
  height: 5px !important;
}

.goods-freaks-store__design-slider::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e5e5e5;
}

.goods-freaks-store__design-slider::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #727272;
}

.goods-freaks-store__design-slider__item {
  width: 100%;
  height: auto;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 15px;
}

.goods-freaks-store__design-slider__tag {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  background-color: #555E2B;
  padding: 7px 15px;
  border-radius: 50px;
}

.goods-freaks-store__design-slider__tag span {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 14px;
  color: white;
  white-space: nowrap;
}

.goods-freaks-store__design-slider__link-show-modal {
  outline: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.goods-freaks-store__design-slider__link-show-modal:hover, .goods-freaks-store__design-slider__link-show-modal:active, .goods-freaks-store__design-slider__link-show-modal:visited, .goods-freaks-store__design-slider__link-show-modal:focus {
  text-decoration: none;
}

.goods-freaks-store__design-slider__link-show-modal span {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 25px;
  color: #9D674F;
  white-space: nowrap;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.goods-freaks-store__design-slider__link-show-modal img {
  display: block;
  width: 12px;
  height: 12px;
  margin-top: 2px;
}

.goods-freaks-store__design-slider__image {
  display: block;
  min-width: 270px;
  height: auto;
  aspect-ratio: 350/530;
}

@media (min-width: 1024px) {
  .goods-freaks-store__design-slider {
    padding: 0;
  }
  .goods-freaks-store__design-slider::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
  }
  .goods-freaks-store__design-slider::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.goods-freaks-store__remark {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  margin: 0 auto;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  padding: 30px 0 60px 15px;
  color: black;
  position: relative;
}

.goods-freaks-store__remark::before {
  content: '*';
  position: absolute;
  left: 0;
}

.goods-freaks-store__collapse {
  padding-bottom: 100px;
}

.goods-freaks-store__collapse__button {
  width: 100%;
  height: auto;
  padding: 15px 0;
  background-color: #555E2B;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  cursor: pointer;
}

.goods-freaks-store__collapse__button h4 {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  font-size: 16px;
  color: white;
  text-align: center;
  position: relative;
  margin-left: 26px;
}

.goods-freaks-store__collapse__button h4::before {
  content: url("https://static.lenskart.com/media/owndays/img/freaks-store/original-goods-warning-icon.svg");
  position: absolute;
  top: 0;
  left: -26px;
}

.goods-freaks-store__collapse__button img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.goods-freaks-store__collapse__animation {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
}

.goods-freaks-store__collapse__content {
  padding: 30px 0;
  background-color: #F7F7F7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.goods-freaks-store__collapse__content section {
  width: calc(100% - 40px);
  max-width: 773px;
  height: auto;
}

.goods-freaks-store__collapse__content section:not(:first-child) {
  margin-top: 30px;
}

.goods-freaks-store__collapse__content section h4 {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  font-size: 11px;
  line-height: 25px;
  color: black;
  margin: 0 4px 0 0;
}

.goods-freaks-store__collapse__content section ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.goods-freaks-store__collapse__content section ul li {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400 !important;
  font-size: 11px;
  line-height: 25px;
  color: black;
  position: relative;
  margin: 0 0 0 15px;
}

.goods-freaks-store__collapse__content section ul li::before {
  content: '*';
  position: absolute;
  left: -15px;
}

@media (min-width: 768px) {
  .goods-freaks-store__collapse__content {
    padding: 60px 0;
  }
}

.goods-freaks-store__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.goods-freaks-store__modal__container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, .2);
  -webkit-animation: fadeIn 0.3s ease;
          animation: fadeIn 0.3s ease;
}

.goods-freaks-store__modal__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
  width: calc(100% - 40px);
  height: auto;
  -webkit-animation: slideIn 0.3s ease;
          animation: slideIn 0.3s ease;
}

.goods-freaks-store__modal__header {
  padding: 15px 13px 30px 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.goods-freaks-store__modal__header__close-icon {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: end;
      align-self: end;
  cursor: pointer;
}

.goods-freaks-store__modal__header-title-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.goods-freaks-store__modal__header-title, .goods-freaks-store__modal__header-subtitle {
  font-family: "NeuzeitGroBold";
  color: black;
  margin: 0;
}

.goods-freaks-store__modal__header-title {
  font-size: 14px;
}

.goods-freaks-store__modal__header-subtitle {
  font-size: 22px;
}

.goods-freaks-store__modal__body {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 51px;
  position: relative;
}

.goods-freaks-store__modal__number {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;
  margin: 30px 0 15px 0;
  position: relative;
}

.goods-freaks-store__modal__number span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: "NeuzeitGroBold";
  font-size: 24px;
  line-height: 23px;
  color: white;
}

.goods-freaks-store__modal__images, .goods-freaks-store__modal__desc-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.goods-freaks-store__modal__images {
  width: calc(100% - 40px);
  height: auto;
  max-width: 829px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 40px;
  margin: 0 auto;
}

.goods-freaks-store__modal__images li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.goods-freaks-store__modal__images li::before {
  content: '';
  position: absolute;
  top: 54px;
  right: -30px;
  width: 20px;
  height: 20px;
  background-image: url("https://static.lenskart.com/media/owndays/img/freaks-store/original-goods-step-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.goods-freaks-store__modal__images li:last-child::before {
  content: none;
}

.goods-freaks-store__modal__desc-list {
  padding: 20px 20px 0 20px;
}

.goods-freaks-store__modal__desc-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
  margin-bottom: 30px;
}

.goods-freaks-store__modal__desc-list li:last-child {
  margin-bottom: 0;
}

.goods-freaks-store__modal__desc-list li p {
  width: calc(100% - 37px);
  height: auto;
  font-family: "NeuzeitGroReg";
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin: 0;
  letter-spacing: normal;
}

.goods-freaks-store__modal__desc-list li .goods-freaks-store__modal__number {
  margin: 0;
}

@media (min-width: 768px) {
  .goods-freaks-store__modal__content {
    width: 100%;
    max-width: 1079px;
  }
  .goods-freaks-store__modal__header {
    padding: 90px 30px 60px 30px;
  }
  .goods-freaks-store__modal__header__close-icon {
    width: 27px;
    height: 27px;
  }
  .goods-freaks-store__modal__header-subtitle {
    font-size: 26px;
  }
  .goods-freaks-store__modal__body {
    padding-bottom: 114px;
  }
  .goods-freaks-store__modal__images {
    gap: 59px;
  }
  .goods-freaks-store__modal__images li img {
    width: 190px;
    height: auto;
  }
  .goods-freaks-store__modal__images li::before {
    top: 157px;
    right: -53px;
    width: 46px;
    height: 36px;
  }
  .goods-freaks-store__modal__desc-list {
    display: grid;
    grid-template-columns: 237px 237px 237px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 59px;
    padding-top: 0;
  }
  .goods-freaks-store__modal__desc-list li {
    gap: 0;
    margin-bottom: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .goods-freaks-store__modal__desc-list li p {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .goods-freaks-store__modal__desc-list li .goods-freaks-store__modal__number {
    display: none;
  }
}

@-webkit-keyframes typing-effect {
  from {
    -webkit-clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
            clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@keyframes typing-effect {
  from {
    -webkit-clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
            clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
  }
  to {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
