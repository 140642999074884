.service_l {
  margin: 0 auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}

@media (max-width: 1023px) {
  .service_l {
    margin: 0 auto 60px;
  }
}

.service_l__section {
  position: relative;
  overflow: hidden;
  display: block;
}

.service_l__container {
  width: 90%;
  max-width: 1296px;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container {
    width: 100%;
    padding: 0 5%;
  }
}

.service_l__container-retail {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container-retail {
    width: 100%;
    padding: 0;
  }
}

.service_l__container-warranty {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1296px;
}

@media (max-width: 767px) {
  .service_l__container-warranty {
    width: 100%;
    padding: 0 5%;
  }
}
