$font-founders: 'Founders Grotesk', sans-serif;

$black: #000;

.footer-gift-ticket {
  &__condition {
    padding: 60px 0 85px;

    &__container {
      width: 100%;
      max-width: 924px;
      height: auto;
      padding: 0 5%;
      margin: 0 auto;
    }

    &__title {
      font-family: $font-founders;
      font-size: 14px;
      font-weight: bold !important;
      line-height: 1;
      margin-bottom: 15px;
      text-align: left;
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    &__item {
      padding-left: 1em;
      font-family: $font-founders;
      font-weight: normal !important;
      font-size: 15px;
      text-align: left;
      color: $black;
      letter-spacing: 1px;
      line-height: 2;
      position: relative;

      &::before {
        content: "*";
        position: absolute;
        left: 0;
      }
    }

    @media (min-width: 768px) {
      padding: 80px 0 100px;

      &__title {
        font-size: 16px;
      }
    }
  }
}
