.services-page-hero {
  position: relative;
}

.services-page-hero__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 200px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.services-page-hero__logo {
  margin-bottom: 50px;
}

.services-page-hero__title {
  margin: 0;
  font-size: 72px;
  line-height: 60px;
  letter-spacing: 0em;
  color: #000000;
}

.services-page-list {
  padding: 120px 200px 60px;
}

.services-page-list__layout {
  max-width: 1200px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  margin: 0 auto;
}

.services-page-list__description {
  padding: 0 80px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0em;
  color: #000000;
}

.services-page-list__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 50px;
}

.services-page-list__item-info {
  padding: 40px;
}

.services-page-list__item-title {
  margin: 0 0 40px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, .3);
}

.services-page-list__item-subtitle {
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000000;
}

.services-page-list__item-price {
  margin-bottom: 20px;
  color: rgba(84, 96, 115, .7);
}

.services-page-list__item-description {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, .7);
}

.services-page-list__item-image {
  background-repeat: no-repeat;
  background-position: center;
}
