.lkModal {
    &.modal-title {
        font-weight: 700;
    }
    &.modal-dialog {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%) !important;
    }
}