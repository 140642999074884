.od-primer-card {
  &__two-fields-wrapper {
    display: grid;
    margin-top: 24px;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__fields-wrapper {
    margin-top: 24px;
  }

  .axtra-error-field {
    display: none;
  }
}

.od-primer-card-field {
  display: flex;
  height: 64px;
  padding: 20px 24px;
  border-radius: 5px;
  align-items: center;
  gap: 8px;
  background-color: #FAFAFA;
  overflow: hidden;

  &__label {
    margin-bottom: 0;
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    white-space: nowrap;
  }

  &__input {
    height: 24px;
    padding: 0;
    border: none;
    color: #444;
    font-family: 'NeuzeitGroReg', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: transparent;
    outline: none;

    &::placeholder {
      opacity: 0.2;
    }
  }
}
