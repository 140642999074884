.footer-air__recommended {
  position: relative;
  padding-block: 80px 100px;
}

.footer-air__recommended h2 {
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: .48px;
  line-height: 1.25;
  margin-bottom: 60px;
  color: black;
}

.footer-air__recommended ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 40px 35px;
}

.footer-air__recommended ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.footer-air__recommended ul li img {
  display: block;
  aspect-ratio: 39 / 23;
  margin-bottom: 16px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.footer-air__recommended ul li h3, .footer-air__recommended ul li p {
  font-family: "Founders Grotesk", sans-serif;
  color: black;
  margin: 0 0 10px 0;
}

.footer-air__recommended ul li h3 {
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 1.25;
  letter-spacing: .72px;
}

.footer-air__recommended ul li p, .footer-air__recommended ul li a {
  font-family: heisei-maru-gothic-std, sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
}

.footer-air__recommended ul li p {
  font-weight: 500 !important;
  letter-spacing: .28px;
  line-height: 2;
}

.footer-air__recommended ul li a {
  outline: none;
  text-decoration: none;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #0094c8;
  line-height: 1;
  letter-spacing: .56px;
}

.footer-air__recommended ul li a::before {
  content: "";
  position: absolute;
  inset: 0;
}

@media (min-width: 768px) {
  .footer-air__recommended ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .footer-air__recommended {
    padding-block: 140px;
  }
  .footer-air__recommended h2 {
    font-size: 36px;
    letter-spacing: 0.72px;
    margin-bottom: 100px;
  }
  .footer-air__recommended ul {
    grid-row-gap: 60px;
  }
  .footer-air__recommended ul li h3 {
    font-size: 24px;
    letter-spacing: 1.44px;
    line-height: 1;
  }
  .footer-air__recommended ul li:hover img {
    opacity: .6;
  }
}

.footer-air__navs-sticky {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 55px;
  z-index: 2;
}

.footer-air__navs-sticky .base-air__container {
  padding-inline: 0;
}

.footer-air__navs-sticky .base-air__btn {
  width: 160px !important;
  height: 55px;
  border-radius: 0;
  white-space: nowrap;
}

.footer-air__navs-sticky .base-air__btn--primary {
  border-top-left-radius: 15px !important;
}

.footer-air__navs-sticky__inner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
}

.footer-air__navs-sticky__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  max-width: 823px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .footer-air__navs-sticky {
    bottom: 47px;
  }
  .footer-air__navs-sticky .base-air__btn {
    width: 340px !important;
    border-radius: 10px !important;
  }
  .footer-air__navs-sticky__actions {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 40px;
    max-width: none;
  }
  .footer-air__navs-sticky__inner {
    background-color: rgba(0, 0, 0, .4);
    padding: 24px 0;
  }
}
