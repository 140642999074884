.social-media-links {
  display: flex;
  align-items: center;
  gap: 28px;
  padding: 15px 0 0 0;
}

.social-media-links-mweb {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  margin: 0;
  padding: 15px 0 15px 0;
  list-style-type: none;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
