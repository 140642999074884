.od-membership-block {
  width: 100%;
  margin-top: 20px;
}

.od-membership-block__table {
  margin-top: 40px;
}

.od-membership-block__table-item {
  width: 100%;
  padding: 0 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.od-membership-block__table-item--ref-friend {
  width: 100%;
  padding: 0 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 19px;
}

@media screen and (max-width: 768px) {
  .od-membership-block__table-item--ref-friend {
    padding: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.od-membership-block__table-item--care {
  width: 100%;
  padding: 0 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 19px;
}

.od-membership-block__table-item__content--ref-friend {
  min-height: 236px;
  width: 50%;
  padding: 40px;
  background-color: #fafafa;
}

@media screen and (max-width: 768px) {
  .od-membership-block__table-item__content--ref-friend {
    width: 100%;
  }
}

.od-membership-block__table-item__content--care {
  min-height: 253px;
  width: 50%;
  padding: 40px;
  background-color: #fafafa;
}

.od-membership-block__table-item__content--points {
  min-height: 309px;
  width: 50%;
  padding: 40px;
  background-color: #fafafa;
}

.od-membership-block__table-item__content__title {
  color: #000000;
  font-size: 20px;
  line-height: 23px;
  opacity: 0.5;
}

.od-membership-block__table-item__content__rebate {
  margin-top: 40px;
  color: #000000;
}

.od-membership-block__table-item__content__rebate .text-bold {
  font-size: 48px;
  line-height: 56px;
}

.od-membership-block__table-item__content__rebate .text-reg {
  font-size: 24px;
  line-height: 28px;
}

.od-membership-block__table-item__content__rebate .care-text-bold {
  font-size: 24px;
  line-height: 54px;
}

.od-membership-block__table-item__content__rebate .care-text-bold--secondary {
  font-size: 48px;
  line-height: 56px;
}

.od-membership-block__table-item__content__rebate .care-text-reg {
  font-size: 24px;
  line-height: 28px;
}

.od-membership-block__table-item__content__description {
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  opacity: 0.5;
  margin-top: 15px;
}

.od-membership-block__table-item__content__rebate-description {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
  margin-top: 40px;
}

.od-membership-block__table-item__img {
  width: 50%;
  min-height: 309px;
}

.od-membership-block__table-item__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .od-membership-block__table-item {
    width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .od-membership-block__table-item {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    padding: 0 20px;
  }
  .od-membership-block__table-item__content--points {
    width: 100%;
  }
  .od-membership-block__table-item__img {
    width: 100%;
    min-height: 100%;
  }
  .od-membership-block__table-item:nth-child(2n) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .od-membership-block__table-item__content__rebate .text-bold {
    font-size: 32px;
  }
  .od-membership-block__table--ref-friend, .od-membership-block__table--care, .od-membership-block__table--points {
    width: 100%;
    padding: 0 20px;
  }
  .od-membership-block__table__description-list, .od-membership-block__table__terms-conditions {
    padding: 0 20px;
  }
}

.od-membership-block__description-list {
  padding: 0 200px;
  margin-top: 40px;
}

.od-membership-block__description-list li {
  list-style: inside;
  font-size: 16px;
  line-height: 40px;
  color: #000000;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .od-membership-block__description-list {
    padding: 0 20px;
  }
  .od-membership-block__description-list li {
    font-size: 16px;
    line-height: 24px;
  }
}

.od-membership-block__terms-conditions {
  color: #000000;
  padding: 0 200px;
  margin-top: 40px;
  opacity: 0.7;
}

.od-membership-block__terms-conditions__title {
  font-size: 16px;
  line-height: 40px;
}

.od-membership-block__terms-conditions__description {
  font-size: 12px;
  line-height: 30px;
}
