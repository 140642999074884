.brands {
  padding-block: 50px 60px;
  font: 400 13px/2 'Founders Grotesk', sans-serif;
  letter-spacing: 1px;
}

@media (min-width: 1024px) {
  .brands {
    font-size: 16px;
  }
}

.brands__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1172px;
}

.brands__heading {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: black;
}

.brands__heading--long {
  color: #666;
  font-size: 14px;
  font-weight: normal !important;
  margin: 12px auto 0;
  line-height: 1.5;
  padding-bottom: 30px;
  text-align: center;
}

.brands__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.brands__card {
  position: relative;
  cursor: pointer;
}

.brands__card::before, .brands__card::after {
  content: '';
  position: absolute;
  inset: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.brands__card::before {
  background-color: rgba(0, 0, 0, .4);
}

.brands__card::after {
  background: url("https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg") no-repeat center/27px auto;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="1 0" /><feFuncG type="table" tableValues="1 0" /><feFuncB type="table" tableValues="1 0" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: invert(1);
          filter: invert(1);
}

.brands__card img {
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
}

.brands__card:hover::before, .brands__card:hover::after, .brands__card.is-open::before, .brands__card.is-open::after {
  opacity: 1;
}

.brands__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 48px 15px 35px;
  border: 1px solid #999;
  position: relative;
  grid-column: span 2;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: none;
  transition: none;
  margin-top: 0;
}

.brands__details-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.brands__details-close img {
  width: 100%;
}

.brands__details-slider {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
}

.brands__details-slider.is-visible {
  opacity: 1;
}

@media (max-width: 767px) {
  .brands__details-slider {
    height: auto !important;
    max-height: none !important;
  }
}

.brands__details-slider .slick-slide * {
  outline: none;
}

.brands__details-slider .slick-slide img {
  width: 100%;
  aspect-ratio: 3/2;
}

.brands__details-slider .slick-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 14px;
  margin-top: 10px;
}

.brands__details-slider .slick-dots li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.brands__details-slider .slick-dots li button {
  font: 0/0 a;
  padding: 0;
  margin-bottom: 20px;
  background-color: #999;
  border: none;
  border-radius: 0;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.brands__details-slider .slick-dots li.slick-active button {
  background-color: #000;
}

.brands__details-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.brands__details-logo {
  font: 0/0 a;
  margin: 0;
  text-align: left;
  padding: 0;
}

.brands__details-logo img {
  max-height: 37px;
  margin: 40px 0 20px 0;
  display: block;
}

.brands__details-logo--expanded img {
  width: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 767px) {
  .brands__details-logo--expanded img {
    width: 83%;
  }
}

.brands__details-recommended {
  border-style: solid;
  border-color: #999;
  border-width: 1px 0px 1px 0px;
  padding: 16px 0;
  margin-bottom: 20px;
  font-size: 15px;
}

.brands__details-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.brands__details-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 12px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin-bottom: 20px;
}

.brands__details-actions .button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 0;
}

.brands__details-actions .button:focus, .brands__details-actions .button:hover {
  text-decoration: none;
}

.brands__details-actions .button:focus {
  text-decoration: none !important;
}

.brands__details-title {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28.8px;
  vertical-align: cap;
  margin: 0 0 20px 0;
}

.brands__details-description {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  margin: 0;
}

.brands__details.is-visible {
  visibility: visible;
  opacity: 1;
  max-height: 800px;
  -webkit-animation: expandHeight 0.6s ease-out forwards;
          animation: expandHeight 0.6s ease-out forwards;
}

.brands__details.is-closing {
  -webkit-animation: collapseHeight 0.4s ease-out forwards;
          animation: collapseHeight 0.4s ease-out forwards;
}

@-webkit-keyframes expandHeight {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 800px;
    opacity: 1;
  }
}

@keyframes expandHeight {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 800px;
    opacity: 1;
  }
}

@-webkit-keyframes collapseHeight {
  0% {
    max-height: 800px;
    opacity: 1;
    visibility: visible;
  }
  100% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes collapseHeight {
  0% {
    max-height: 800px;
    opacity: 1;
    visibility: visible;
  }
  100% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .brands__details.is-visible {
    -webkit-animation: expandHeight 0.6s ease-out forwards;
            animation: expandHeight 0.6s ease-out forwards;
  }
  .brands__details.is-closing {
    -webkit-animation: collapseHeight 0.4s ease-out forwards;
            animation: collapseHeight 0.4s ease-out forwards;
  }
}

@media (min-width: 768px) {
  .brands__list {
    grid-template-columns: repeat(3, 1fr);
  }
  .brands__details {
    display: grid;
    grid-template-columns: 1fr 50%;
    gap: 25px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-column: span 3;
  }
  .brands__details-slider {
    overflow: hidden;
  }
  .brands__details-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .brands__details-actions {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .brands__details-title {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .brands__details-description {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .brands {
    padding-block: 110px 120px;
  }
  .brands__heading {
    font-size: 40px;
  }
  .brands__heading--long {
    margin: 17px auto 0;
    padding-bottom: 90px;
    line-height: 1.5;
  }
}

@media (min-width: 1024px) {
  .brands__list {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .brands__card::after {
    background-size: 53px auto;
  }
  .brands__details {
    gap: 50px;
    padding: 50px;
    grid-column: span 4;
  }
  .brands__details-recommended {
    font-size: 16px;
  }
}
