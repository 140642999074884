$font-itc: "itc-avant-garde-gothic-pro", sans-serif;
$font-miller: "Miller-Banner";
$font-tahoma: "Tahoma";

$brown-81: #815624;

$base-img-url: 'https://static.lenskart.com/owndays/img/anniversary-10th-collection';

.header-anniversary-10th-collection {
  &__main {
    display: grid;

    picture {
      grid-column: 1 / 2;
      grid-row: 1 / 3;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__text {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding-top: 38px;
    }

    h1 {
      font-family: $font-miller;
      font-weight: 400 !important;
      line-height: 9.55vw;
      letter-spacing: 0.84px;
      color: $brown-81 !important;
      text-align: center;

      small, &+small {
        font-family: $font-tahoma !important;
        font-size: 4.125vw;
        font-weight: 400 !important;
        letter-spacing: 0.48px;
        color: $brown-81 !important;
      }

      small {
        display: block;
        font-size: 4.125vw;
        margin-top: 11px;
        line-height: 9.55vw;
        letter-spacing: 0.48px;
      }

      &+small {
        display: block;
        text-align: center;
        margin-top: 11px;
      }

      strong { 
        font-family: inherit !important;
        color: $brown-81 !important;
      }
    }

    &__swift-up-text {
      display: block;
      font-weight: inherit;
      overflow: hidden;
      padding-bottom: 6px;
      margin-bottom: -6px;
      
      i {
        font-style: normal;
        position: relative;
        top: calc(7.2vw + 8px);
        background: transparent;

        @media (min-width: 1024px) {
          top: 0;
          transform: translateY(calc(7.2vw + 8px));
        }
      }

      &.aos-animate {
        span {
          animation: .3s header-anniversary-10th-collection-swift-up ease-in-out forwards;
          @media (min-width: 1024px) {
            animation: .3s header-anniversary-10th-collection-swift-up-pc ease-in-out forwards;
          }
        }

        i {
          animation: .4s header-anniversary-10th-collection-swift-up ease-in-out forwards;
          @media (min-width: 1024px) {
            display: inline-block;
            animation: .4s header-anniversary-10th-collection-swift-up-pc ease-in-out forwards;
          }
        }
      }
    }

    @media (min-width: 768px) {
      picture {
        img {
          min-height: 845px;
          object-fit: cover;
          object-position: bottom;
        }
      }

      &__text {
        padding-top: 80px;
      }
  
      h1 {
        font-size: 46px;
        letter-spacing: 1.38px;
        line-height: 1;

        small {
          font-size: 20px;
          letter-spacing: 0.6px;
          line-height: 1;
          margin-top: 23px;
        }

        &+ small {
          font-size: 20px;
          letter-spacing: 0.6px;
          line-height: 1;
          margin-top: 23px;
        }
      }
    }
  }

  &__concept {
    font-family: $font-itc;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.42px;
    color: $brown-81;
    padding-top: 63px;
    padding-bottom: 70px;

    p {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: normal;
      line-height: 2;
    }

    a {
      margin-top: 64px;
      background-color: black;

      .base-anniversary-10th-collection__frame {
        position: relative;
      }

      .base-anniversary-10th-collection__frame::after {
        content: "";
        position: absolute;
        width: 124px;
        height: 109px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("#{$base-img-url}/play-video.svg") center/contain no-repeat;
        z-index: 9;
        pointer-events: none;
      }

      img {
        width: calc(100% + 40px);
        transition: 0.5s;
      }
    }

    @media (min-width: 768px) {
      line-height: 32px;
      letter-spacing: 0.48px;
      padding-top: 150px;
      padding-bottom: 186px;

      a {
        overflow: hidden;
        border-radius: 15px;
      }

      .base-anniversary-10th-collection__container {
        max-width: calc(840px + 40px * 2);
      }
    }

    @media (min-width: 1024px) {
      a {
        cursor: pointer;

        &:hover, &:active, &:visited, &:focus {
          text-decoration: none;
        }

        &:hover {
          .base-anniversary-10th-collection__frame {
            background-color: black;
  
            img {
              opacity: 0.6;
              transition: 0.5s;
            }
          }
        }
      }

    }
  }

  &__lineup-menu {
    &__layout {
      @media (max-width: 767px) {
        margin-left: -40px;
        margin-right: -40px;
      }
      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 45% auto;
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 35px;
        grid-row-gap: 0px;
        align-items: center;
      }
      @media (min-width: 1024px) {
        grid-template-columns: 465px auto;
      }
      @media (min-width: 1296px) {
        grid-column-gap: 75px;
      }
      @media (min-width: 1440px) {
        margin-left: -100px;
      }
    }

    &__title {
      h2 {
        text-align: center;
        font-size: 32px;
        line-height: 2.625em;
        color: $brown-81;
        font-family: $font-miller;
        letter-spacing: 1px;

        @media (min-width: 768px) {
          font-size: 50px;
          line-height: 2.5em;
          line-height: 1;
          margin-bottom: 20px;
        }
      }
    }

    &__banner {
      &__text {
        background-color: $brown-81;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;

        &__layout {
          p {
            font-size: 15px;
            font-weight: 500 !important;
            line-height: 2;
            letter-spacing: 0.42px;
            margin-bottom: 0;
          }

          &:nth-child(2) {
            margin-left: 43px;
          }

          @media (min-width: 1296px) {
            p {
              font-size: 16px;
              line-height: 26px;
              letter-spacing: 0.48px;
            }
          }
        }

        @media (min-width: 1296px) {
          gap: 43px;
        }
      }
    }

    &__menu {
      margin-top: 23px;
      width: 100%;
      
      ul {
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        padding-left: 0;
        margin-bottom: 0;

        @media (max-width: 767px) {
          max-width: 300px;
          margin: 0 auto;
        }

        @media (min-width: 1296px) {
          gap: 17px;
        }

        li {
          background-color: white;
          border:1px solid $brown-81;
          height: 145px;
          border-bottom-left-radius: 50%;
          border-bottom-right-radius: 50%;

          @media (max-width: 767px) {
            width: 150px;
          }

          @media (min-width: 768px) {
            min-height: calc(17.5vw + 15px);
          }

          @media (min-width: 1024px) {
            min-height: 223px;
          }
        }
      }

      &__title {
        font-size: 14px;
        line-height: 2.875em;
        text-align: center;
        color: $brown-81;
        font-family: $font-miller;

        @media (min-width: 768px) {
          font-size: 2vw;
        }

        @media (min-width: 1024px) {
          font-size: 20px;
          line-height: 1;
          margin-top: 27px;
          margin-bottom: 6px;
        }
      }

      &__image {
        width: 128px;
        margin:0 auto;

        @media (min-width: 768px) {
          width: 16vw;
        }

        @media (min-width: 1024px) {
          width: 196px;
        }
      }

      &__arrow {
        width: 25.71px;
        height: 30px;
        margin:5px auto;
        transition: 0.4s;

        @media (min-width: 768px) {
          width: 3vw;
          margin-top: 10px;
        }

        @media (min-width: 1024px) {
          margin-top: 10px;
          width: 44px;
        }

        @media (min-width: 1296px) {
          margin-top: 8px;
        }
      }

      @media (min-width: 768px) {
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;

        ul {
          li {
            min-height: calc(17.5vw + 15px);
          }
        }

        &--div1 { 
          grid-area: 1 / 1 / 2 / 2; 
          align-self: end;
          display: flex;
          justify-content: flex-start;
        }

        &--div2 { 
          grid-area: 2 / 1 / 3 / 2; 
          height: 78px;
          display: flex;
          justify-content: space-between !important;
          padding: 0 19px !important;
        }

        &--div3 {
          grid-area: 1 / 2 / 4 / 3; 
          height: 100%;
          margin-right: calc(40px * -1);

          img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            aspect-ratio: 65/41;
          }
        }

        &--div4 { 
          grid-area: 3 / 1 / 4 / 2; 
          margin-bottom: 40px;
          padding-right: 0px;
          padding-left: 0px;
          
          @media (min-width: 768px) {
            margin-bottom: 4vw;
            width: 100%;
          }
        }
      }

      @media (min-width: 1024px) {
        ul {
          li {
            min-height: 223px;
            cursor: pointer;

            &:hover {
              .header-anniversary-10th-collection__lineup-menu__menu__arrow {
                transform: translateY(10px);
              }
            }
          }
        }
      }

      @media (min-width: 1120px) {
        &--div3 {
          margin-right: calc((1120px - (40px * 2) - 100vw) / 2);
        }
      }
    }
  }
}

@keyframes header-anniversary-10th-collection-swift-up {
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes header-anniversary-10th-collection-swift-up-pc {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
