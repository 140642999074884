$font-founders: 'Founders Grotesk', sans-serif;
$color-white: #fff;
$color-black: #000;
$color-gray-dark: #333;
$color-gray-light: #666;
$color-bg-light: #f4f4f4;
$color-bg-gray-dark: #333;

.services__header {
  position: relative;
  &-content {
    display: flex;
    flex-direction: column;
    padding: 34px 5%;
    h1,
    p {
      letter-spacing: 1px;
      line-height: 2;
      font-family: $font-founders;
      -webkit-font-smoothing: subpixel-antialiased;
      color: inherit;
      margin: 0;
    }

    h1 {
      font-size: 20px;
      margin-bottom: 14px;
      line-height: 1;
      font-weight: 300;
    }

    p {
      font-size: 15px;
      line-height: 1.6;
    }

    &--left {
      width: 100%;
      align-items: start;
      justify-content: center;
      text-align: start;

      h1 {
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        color: $color-white;
      }
      p {
        color: $color-white;
        max-width: 450px;
      }
    }
  }

  &-bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 767px) {
    &-content {
      background-color: $color-bg-gray-dark;
      color: $color-white;
      p {
        line-height: 1.6;
      }
      &--light {
        h1 {
          color: $color-black;
          font-weight: 600;
        }
        p {
          color: $color-black;
        }
        background-color: $color-bg-light;
      }
      &--dark {
        h1 {
          color: $color-white;
          font-weight: 600;
        }
        p {
          color: $color-white;
        }
      }
      &--sp-bg-light {
        background-color: $color-bg-light;
        h1 {
          color: $color-black;
        }
        p {
          color: $color-gray-light;
        }
      }
    }
  }
  @media (min-width: 768px) {
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      h1 {
        font-size: 40px;
        margin-bottom: 17px;
      }
      p {
        max-width: 600px;
        line-height: 1.6;
      }
      &--center {
        align-items: center;
        justify-content: center;
      }
      &--dark {
        text-align: center;
        h1 {
          color: $color-black;
        }
        p {
          color: $color-gray-light;
        }
      }
      &--light {
        h1,
        p {
          color: $color-white;
        }
      }
      &--sp-bg-light {
        text-align: center;
        h1 {
          color: $color-black;
        }
        p {
          color: $color-gray-light;
        }
      }
      &--left {
        width: 80%;
        position: absolute;
        justify-content: center;
        text-align: start;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1152px;
        padding: 0;
        margin: 0 auto;

        h1 {
          font-size: 40px;
          line-height: 1;
          color: $color-white;
          font-weight: 300;
        }
        p {
          color: $color-white;
          max-width: 450px;
          line-height: 2;
        }
      }
    }
  }
}
