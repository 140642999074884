@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Kaku+Gothic+New:wght@400;500;700;900&display=swap&family=Zen+Old+Mincho&display=swap&family=Inter:wght@100..900&display=swap');
@import url("https://fonts.cdnfonts.com/css/post-no-bills-colombo");


$black: #000;
$white: #fff;
$gold: #c18b35;
$font-noto: "Noto Sans JP", sans-serif;
$font-zen: "Zen Kaku Gothic New", sans-serif;
$font-zen-old: "Zen Old Mincho", serif;
$font-post: "Post No Bills Colombo", sans-serif;;
$font-inter: "Inter", sans-serif;

$breakpoints: (
  xs: 390px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1296px,
  xxl: 1440px
);

@mixin mq-up($size: md) {
  @if type-of($size) == number {
    @media (min-width: #{$size}px) { @content; }
  } @else {
    $breakpoint: map-get($breakpoints, $size);
    @if $breakpoint {
      @media (min-width: $breakpoint) { @content; }
    } @else {
      @media (min-width: map-get($breakpoints, md)) { @content; }
    }
  }
}

@mixin mq-down($size: md) {
  @if type-of($size) == number {
    @media (max-width: #{$size - 1}px) { @content; }
  } @else {
    $breakpoint: map-get($breakpoints, $size);
    @if $breakpoint {
      @media (max-width: ($breakpoint - 1)) { @content; }
    } @else {
      @media (max-width: (map-get($breakpoints, md) - 1)) { @content; }
    }
  }
}

:root {
  $container-padding-sp: 20px;
  $container-max-w: 1160px+($container-padding-sp * 2);
  $max-edge: 2000px;
  --pd: #{$container-padding-sp};
  --pd-minus: calc(var(--pd) * -1);
  --container-max-w: #{$container-max-w};
  --edge: var(--pd-minus);
  --edge-aw: var(--edge);
}

.fancybox__footer{
  display: none !important;
}
$backdrop-color: rgba(24, 24, 27, 0.81);

.fancybox__backdrop {
  background: $backdrop-color;
}
.l-gundam5 .slick-track
{
    display: flex !important;
}

.l-gundam5 .slick-slide
{
    height: inherit !important;
}
.l-gundam5 .slick-slide {
  margin: 0 17.5px;
}
.l-gundam5 .slick-initialized .slick-slide{
  position: relative;
  border: 4px solid rgba(193, 139, 53, .6);
  background: rgba(0, 0, 0, .5);
  padding: 20px 16px;
  height: auto;
  width: 82.05128vw !important;
  max-width: 598px !important;
  margin: 0 10px;
}

.fancybox-image-list{
  .fancybox__slide.has-image>.fancybox__content{
    margin-top: -200px;
    max-width: 640px !important;
    height: auto !important;
    padding: 0 !important;
  }
}
.fancybox__nav{
    position: relative;
    bottom: 120px;
    width: 165px;
    margin-left: auto;
    margin-right: auto;
    button{
      width: 60px;
      height: 60px;
      background-color: #fff;
      color: #666666 !important;
      svg{
        filter: none !important;
      }
      &:hover{
        background-color: #fff !important;
        color: #666666 !important;
      }
    }
    .fancybox__infobar{
      bottom: -70px;
      position: absolute;
      color: #ccc;
      font-size: 13px;
      -webkit-font-smoothing: subpixel-antialiased;
      height: 44px;
      left: 0;
      line-height: 44px;
      min-width: 44px;
      mix-blend-mode: difference;
      padding: 0 10px;
      pointer-events: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      left: 50%;
      transform: translateX(-50%);
    }
}



.fancybox__toolbar{
  .fancybox__infobar{
    opacity: 0;
  }
  .f-button{
    &:hover{
      background-color: rgba(24, 24, 27, 0.65) !important;
    }
  }
}

.l-gundam5 {
  font: 500 14px/30px $font-zen;
  color: $white;
  padding-bottom: 0.1px;
  background-color: $black;

  // Reset
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    color: inherit;
    background: none;
  }

  ul,
  ol {
    list-style: none;
  }

  button {
    cursor: pointer;
    background-color: unset;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
  }

  a {
    cursor: pointer;
    text-decoration: none !important;
    outline: none;
  }

  img,
  picture {
    width: 100%;
    height: auto;
    display: block;
    vertical-align: middle;
  }

  &__container {
    max-width: var(--container-max-w);
    padding-left: var(--pd);
    padding-right: var(--pd);
    margin-left: auto;
    margin-right: auto;
  }
  &__title {
    font: 400 36px/1 $font-post;
    letter-spacing: 3.6px;
    padding-bottom: 32px;
    color: $white;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 16px;
      border-top: 1px solid $white;
    }
    &::before {
      width: 83px;
      bottom: 13px;
    }
    &::after {
      width: 45px;
      bottom: 0;
    }

    &--right {
      text-align: right;
      &::before,
      &::after {
        left: auto;
        right: 16px;
      }
    }
    &--center {
      text-align: center;
      &::before,
      &::after {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    @include mq-up() {
      font-size: 48px;
      padding-bottom: 46px;
      &::before {
        width: 225px;
        bottom: 16px;
      }
      &::after {
        width: 123px;
      }
    }
  }
  &__btn {
    font: 600 14px/1 $font-zen;
    background-color: #b31111;
    color: $white !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0 50px;
    border-radius: 30px;
    position: relative;
    transition: 0.3s;
    border: none;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      width: 9px;
      height: 17px;
      background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-right.svg") center/contain no-repeat;
    }

    @include mq-up(lg){
      font-size: 18px;
    
      height: 80px;
      border-radius: 40px;

      &::after {
        width: 12px;
        height: 25px;
        right: 42px;
      }

      &:hover {
        animation: gundam_shine 2s;
      }
    }
  }

  // MAIN
  .main {
    color: $white;
    position: relative;
    z-index: 3;

    .l-gundam5__container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    &__img {
      position: relative;
      pointer-events: none;
      z-index: -1;

      img {
        aspect-ratio: 195/368;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      padding: 33vw var(--pd) 0;

      h1 {
        font: 900 44px/48px $font-zen-old;
        letter-spacing: 1.76px;
        order: 2;
        text-align: center;
        margin-top: 5px;
        text-shadow: 0 4px 5px rgba(0, 0, 0, 0.4);

        + small {
          font: 900 14px/1 $font-zen-old;
          order: 1;
        }
      }

      p {
        font: 500 12px/1 $font-zen;
        order: 4;
        text-align: center;
        border: 1px solid $white;
        border-left: none;
        border-right: none;
        letter-spacing: 2px;
        padding: 5px 44px 9px;

        strong {
          font-size: 16px;
          font-weight: 900;
          letter-spacing: 2.08px;
        }
      }
    }
    &__logo {
      order: 3;
      width: 187px;
      margin: 15px auto;
      aspect-ratio: 187/43;
      pointer-events: none;
    }
    &__zeon-logo {
      width: 44px;
      height: auto;
      position: absolute;
      top: 26px;
      right: var(--pd);
      aspect-ratio: 44/63;
    }
    &__scroll-down {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 99px;
      height: 99px;
      margin: 70px auto 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 28px;
      span{
        opacity: 0;
        position: absolute;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/scroll-down-circle.svg") center/contain no-repeat;
        animation: spin 10s linear infinite;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/arrow-down.svg") center/31px auto no-repeat;
      }
    }
    &__sales {
      background-color: $gold;
      color: $white;
      width: 94px;
      height: 94px;
      border-radius: 50%;
      position: absolute;
      top: 48px;
      left: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: $font-zen;
      line-height: 1;

      &-year {
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: -4px;
      }
      &-date {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.2px;
        display: flex;
        align-items: flex-end;
        margin: 2px 0px;

        small {
          font-size: 8px;
          font-weight: 700;
          letter-spacing: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.5px 1.5px 2px;
          margin-left: 3px;
          background-color: #7e530d;
        }
      }
      &-time {
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 0.18px;
      }
      &-text {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.2px;
        margin-top: 4px;
      }
    }
    @media (min-width: 480px) {
      &__content {
        padding-top: 63vw;
      }
    }
    @include mq-up() {
      &__img {
        img {
          aspect-ratio: 180/107;
        }
      }
      &__content {
        padding-top: 30vw;
      }
      &__scroll-down {
        top: calc(100% + 20px);
        bottom: auto;
        margin-top: 0;
      }
      &__sales {
        top: calc(88/768*100vw);
        left: calc(135/768*100vw);
      }
    }
    @include mq-up(lg) {
      &__content {
        padding-top: 25vw;

        h1 {
          font-size: 50px;
          letter-spacing: 2px;
          margin-top: 20px;

          small {
            font-size: 16px;
            letter-spacing: 1.44px;
          }
        }

        p {
          font-size: 15px;
          letter-spacing: 3.75px;
          padding: 8px 55px 11px;

          strong {
            font-size: 19px;
            letter-spacing: 2.47px;
          }
        }
      }
      &__logo {
        margin: 23px auto 30px;
        width: 267px;
      }
      &__zeon-logo {
        top: 69px;
        right: 60px;
        width: calc((76/1440)*100vw);
        max-width: 76px;
      }
      &__scroll-down {
        top: auto;
        bottom: 0;
        margin-top: 0;
        transform: translateY(70%);

        &:hover {
          &::after {
            animation: gundam_move 1s infinite linear;
          }
        }
      }
      &__sales {
        width: 168px;
        height: 168px;
        top: calc(105/1024*100vw);
        left: calc(125/1024*100vw);

        &-year {
          font-size: 15px;
          letter-spacing: 1.5px;
          margin-top: -8px;
        }
        &-date {
          font-size: 34px;
          letter-spacing: 2.04px;
          margin: 4px 0px;

          small {
            font-size: 14px;
            font-weight: 700;
            padding: 2px 2px 3px;
            margin-left: 4px;
          }
        }
        &-time {
          font-size: 15px;
          letter-spacing: 0.3px;
        }
        &-text {
          font-size: 21px;
          letter-spacing: 2.1px;
          margin-top: 6px;
        }
      }
    }
    @include mq-up(xl) {
      &__sales {
        top: 155px;
        left: 12.2%;
      }
    }
    @include mq-up(xxl) {
      &__content {
        padding-top: 18vw;
      }

      &__scroll-down {
        width: 151px;
        height: 151px;
        transform: translateY(1%);
        z-index: 33 !important;
        &::after {
          background-size: 48px auto;
        }
      }
    }
  }

  // CONCEPT
  .concept {
    padding: 57px 0 123px 0;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: calc((-263/390)*100vw);
      left: 0;
      right: 0;
      bottom: 0;
      background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/concept-bg-sp.webp") bottom center/cover no-repeat;
      pointer-events: none;
      z-index: -1;
    }
    .l-gundam5__container {
      position: relative;
    }
    &__img {
      position: absolute;
      top: -25px;
      left: 0;
      right: 0;
      pointer-events: none;
    }
    &__desc {
      font: 500 14px/30px $font-zen;
      letter-spacing: normal;
      color: $white;
      text-align: justify;
      padding: 30px;
      background: rgba(0, 0, 0, 0.65);
      background-blend-mode: multiply;
      margin: calc((330/390)*100vw) var(--edge) 0 auto;
      position: relative;
      z-index: 2;
      width: calc((320/390)*100vw);
      max-width: 320px;
    }
    /* &__3d {
      height: 2000px;

      &-container {
        position: sticky;
        top: 20px;
      }

      model-viewer {
        //--progress-bar-color: transparent;
        width: 100%;
        //height: 70vh;
        height: calc(100vh - 60px - 140px);
        max-height: 1000px;
        outline: none;
        border: none;
        opacity: 0;
        transform: scale(0.96);
        filter: brightness(0.1);
        transition: opacity 0.3s 0.2s, transform 2.5s 0.2s, filter 3.5s 0.2s;
        
        &.active {
          opacity: 1;
          transform: scale(1);
          filter: brightness(1);
        }
      }
      .model-poster {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        transition: 0.3s;

        &::before {
          content: "";
          background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/3d/poster.webp") center/contain no-repeat;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          width: 100%;
          height: 80%;
          transform: translateY(-50%);
          margin: 0 auto;
          filter: brightness(0.3) blur(2px);
        }
        &.inactive {
          opacity: 0;
          pointer-events: none;
        }
        &-body {
          position: absolute;
          left: 0;
          right: 0;
          top: 53%;

          i {
            display: block;
            margin: 0 auto 8px;
            width: 35px;
            height: 35px;
            background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/spinner.png") center/contain no-repeat;
            animation: spin 2.5s linear infinite;
            opacity: 0.8;
          }

          span {
            font: 400 14px/1 $font-zen;
            color: $white;
            text-align: center;
            display: block;
          }
        }
      }
    } */
    &__video-note{
      font-size: 9px;
      text-align: right;
      margin-top: 5px;
    }
    @include mq-up() {
      padding: 217px 0 123px 0;
      &::before {
        background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/concept-bg-pc.webp");
        top: calc((-292/1440)*100vw);
      }
      &__img {
        top: -140px;
        left: auto;
        width: 80%;
        max-width: 794px;
        overflow: hidden;

        img {
          transform: translateX(120px);
        }
      }
      &__desc {
        font-size: 16px;
        margin: 63px 0 0 0;
        width: 55%;
        max-width: none;
        padding: 50px;
      }
      /* &__3d {
        model-viewer {
          //height: 90vh;
          height: calc(100vh - 77px - 110px);
        }
      } */
    }
    @include mq-up(lg) {
      padding-bottom: 243px;
      &__img {
        top: -190px;
      }
      &__desc {
        width: 50%;
        line-height: 40px;
      }
      /* &__3d {
        height: 3000px;

        .model-poster {
          &-body {
            i {
              width: 40px;
              height: 40px;
              margin-bottom: 12px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      } */
      &__video-note{
        font-size: 12px;
      }
    }
    @media (min-width: 1200px) {
      &__img {
        img {
          transform: translateX(0);
        }
      }
    }
    @include mq-up(xxl) {
      &__img {
        top: -205px;
        right: -88px;
      }
    }
    @media (min-width:1920px) {
      &::before {
        background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/concept-bg-2560.webp");
        background-size: 3000px 100%;
        background-position: top center;
      }
    }
  }

  // FUNCTION
  .function {
    position: relative;
    padding: 0 0 453px;
    margin-bottom: -320px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: calc((-300/390)*100vw);
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: -1;
    }
    &::before {
      background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/function-bg-sp.webp") top center/cover no-repeat;
    }
    &__slider {
      position: relative;
      margin: 50px 0 0 0;

      .swiper {
        &-slide {
          border: 4px solid rgba(193, 139, 53, 0.6);
          background: rgba(0, 0, 0, 0.5);
          padding: 20px 16px;
          height: auto;
        }
      }
      &-img {
        position: relative;
        opacity: 1 !important;

        img {
          opacity: 1 !important;
          aspect-ratio: 280/203;
        }

        button {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 43px;
          height: 43px;
          background: rgba(255, 255, 255, 0.5) url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-zoom.svg") center/20px 20px no-repeat;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
      &-desc {
        font: 500 12px/1.6 $font-zen;
        margin-top: 20px;
      }
    }
    &__ar {
      margin: 90px var(--edge) 0;
      padding: 60px 30px;
      background: rgba(0, 0, 0, 0.6);
      background-blend-mode: multiply;

      &-title {
        font: 400 36px/1 $font-post;
        text-align: center;
      }

      &-img {
        margin: 30px auto;
        width: 100%;

        &-demo {
          aspect-ratio: 330/240;
        }
        &-qr {
          display: none;
        }
      }
      &-desc {
        font: 500 14px/30px $font-zen;
        letter-spacing: normal;
      }
      &-note {
        display: block;
        font: 500 9px/20px $font-zen;
        margin-top: 8px;
      }
      &-btn {
        font: 500 14px/1 $font-zen;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid currentColor;
        border-radius: 30px;
        margin: 30px auto 0;

        i {
          width: 27px;
          height: 27px;
          margin-right: 15px;
          background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-ar.svg") center/contain no-repeat;
        }
      }
    }
    &__price {
      padding: 30px 0 50px 0;

      &-img {
        margin: 0 var(--edge);
        width: auto;

        img {
          aspect-ratio: 130/129;
        }
      }
      &-title {
        margin: 36px auto 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          font: 500 26px/1 $font-zen;
          order: 2;
        }

        small {
          font: 500 13px/1 $font-zen;
          margin-bottom: 20px;
          order: 1;
        }
      }
      &-value {
        font: 500 21px/1$font-zen;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 30px;

        small {
          font: 500 11px/1 $font-zen;
        }
      }
      &-note {
        font: 500 11px/1 $font-zen;
        letter-spacing: 0.33px;
        display: block;
        text-align: center;
        margin-top: 15px;

        &--custom-1 {
          font-size: 8px;
          letter-spacing: 0.24px;
          margin-left: 3px;
        }
        &--custom-2 {
          font-size: 9px;
          letter-spacing: 0.27px;
        }
      }
    }
    #gallery_img_popup {
      display: none;
    }
    @include mq-up() {
      margin-bottom: -324px;

      &::before {
        background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/function-bg-pc.webp");
        background-size: 100% auto;
        top: calc((-416/1440)*100vw);
      }
      &__slider {
        margin-top: 72px;

        .swiper {
          &-slide {
            padding: 37px 33px 33px;
            max-width: 598px !important;
          }
        }

        &-img {
          button {
            width: 80px;
            height: 80px;
            background-size: 42px 42px;
          }
        }

        &-desc {
          font-size: 16px;
          line-height: 28px;
          margin-top: 34px;
        }
      }
      &__ar {
        margin: 150px 0 0;
        display: grid;
        grid-template-areas:
          "temp1 img"
          "title img"
          "desc img"
          "temp2 img";
        grid-template-columns: auto 350px;
        grid-template-rows: 1fr auto auto 1fr;
        column-gap: 40px;
        align-items: center;
        justify-content: space-between;

        &-title {
          grid-area: title;
          text-align: left;
          margin: 0 0 40px 0;
        }

        &-img {
          grid-area: img;
          margin: 0;
          position: relative;

          &-qr {
            display: block;
            background-color: $white;
            padding: 7px;
            position: absolute;
            width: 30%;
            height: auto;
            max-width: 145px;
            right: -20px;
            bottom: -30px;
          }
        }

        &-desc {
          grid-area: desc;
        }

        &-btn {
          display: none;
        }
      }
      &__price {
        padding: 0;
        margin-top: 71px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 70px;
          left: 0;
          right: 0;
          bottom: -100px;
          background: linear-gradient(180deg, rgba(35, 12, 12, 0.00) 0%, rgba(35, 12, 12, 0.60) 42%, rgba(35, 12, 12, 0.60) 67.5%, rgba(35, 12, 12, 0.00) 100%);
          z-index: -1;
        }
        .l-gundam5__container {
          display: grid;
          grid-template-areas:
            "img temp1"
            "img title"
            "img price"
            "img btn"
            "img note"
            "img temp2";
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr auto auto auto 1fr;
          column-gap: 60px;
          padding: 0 60px;
        }
        &-img {
          grid-area: img;
          margin: 0;

          img {
            aspect-ratio: 314/407;
          }
        }
        &-title {
          grid-area: title;
          margin-top: 100px;
        }
        &-value {
          grid-area: price;
        }
        &-note {
          grid-area: note;
          font-size: 15px;
          letter-spacing: 0.45px;
          margin-top: 22px;
          line-height: 1.35;

          &--custom-1 {
            font-size: inherit;
          }
          &--custom-2 {
            font-size: inherit;
          }
          &--custom-3 {
            font-size: 13px;
            letter-spacing: 0.39px;
          }
        }
        .l-gundam5__btn {
          grid-area: btn;
          max-width: 400px;
        }
      }
    }
    @include mq-up(lg) {
      &__ar {
        grid-template-columns: auto 395px;
        padding: 60px 62px;
        column-gap: 58px;

        &-title {
          font-size: 40px;
          letter-spacing: 4px;
        }

        &-desc {
          font-size: 16px;
          line-height: 40px;
        }

        &-note {
          font-size: 12px;
          margin-top: 10px;
        }

        &-img {
          &-qr {
            width: 145px;
            right: -32px;
            bottom: -30px;
          }
        }
      }
      &__price {
        .l-gundam5__container {
          justify-content: space-between;
          max-width: 931px;
        }

        &-title {
          span {
            font-size: 30px;
          }

          small {
            font-size: 16px;
          }
        }

        &-value {
          font-size: 24px;
          margin-bottom: 60px;

          small {
            font-size: 14px;
          }
        }
      }
    }
    @include mq-up(xl) {
      &__price {
        &::before {
          top: 100px;
        }

        &-title {
          margin-top: 170px;
        }
        &-note {
          white-space: nowrap;
        }

        .l-gundam5__container {
          grid-template-columns: 471px 400px;
        }
      }
    }
    @media (max-width:1919px) {
      &__slider {
        .swiper {
          &-slide {
            width: calc((320/390)*100vw) !important;
          }
        }
      }
    }
    @media (min-width:1920px) {
      &::before {
        background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/function-bg-2560.webp");
        background-size: 3000px auto;
        top: -506px;
      }
    }
  }

  // SPEC
  .spec {
    margin-bottom: 100px;
    position: relative;
    z-index: 3;
    &__list{
      padding: 0 10px;
      margin-top: 50px;
      p , span{
        font-family: $font-zen;
        font-size: 14px;
        letter-spacing: 0;
      }
      >ul{
        li + li{
          margin-top: 25px;
        }
        li{
          .note{
            font-size: 9px;
          }
        }
      }
      .spec-download{
        margin-top: 25px;
        a{
          max-width: fit-content;
          position: relative;
          padding-left: 25px;
          &:before{
            content: "";
            background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon_pdf.webp") left/contain no-repeat;
            display: inline-table;
            width: 16px;
            height: 20px;
            top: 3px;
            left:0;
            position: absolute;
            padding-left: 25px;
          }
          span{
            color:#B61114;
            border-bottom: 1px solid #B61114;
          }
        }
      }
    }
    &__note{
      border: 1px solid #999999;
      padding: 30px 25px;
      margin-top: 60px;
      position: relative;
      z-index: 3;
      background: rgba(0, 0, 0, 0.6);
      background-blend-mode: multiply;
      // &::before{
      //   content: "";
      //   background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/frame_back-opacity.webp") center/cover repeat;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   left: 0;
      //   bottom: 0;
      //   z-index: -1;
      //   opacity: 0.6;
      // }
      &-section{
        color: #999999;
        h2{
          font-size: 20px;
          font-family: $font-zen;
          text-align: center;
        }
        p{
          margin-top: 20px;
          letter-spacing: 0;
          line-height: 30px;
          small{
            font-weight: inherit;
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
    @include mq-up() {
      margin-bottom: 150px;
      &__list{
        padding: 0;
        max-width: 100%;
        width: 668px;
        margin-left: auto;
        margin-right: auto;
        >ul{
          li{
            display: grid;
            grid-template-columns: 200px 1fr;
            .note{
              font-size: 12px;
            }
          }
        }
      }
      &__note{
        max-width: 100%;
        width: 668px;
        margin-top: 80px;
        margin-left: auto;
        margin-right: auto;
        padding: 30px 40px;
      }
    }
    @include mq-up(lg) {
      &__list{
        p , span{
          font-size: 16px;
        }
      }
    }
  }

  // BANNER
  .banner {
    &__container{
      position: relative;
      picture{
        img {
          aspect-ratio: 39/73;
        }
      }
    }
    &__detail{
      position: absolute;
      top: calc((234 / 390)* 100vw);
      left: 0;
      bottom: 0;
      right: 0;
      
      .banner-category{
        background-color: #66430B;
        max-width: fit-content;
        padding: 0 12px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
        p{
          font-size: 13px;
          letter-spacing: 3.9px;
        }
      }
      .btn-banner{
        margin-top: 36px;
        background-color: $gold;
        border-radius: 8px;
        max-width: 330px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        transition: 0.3s;
        a{
          height: 60px;
          font-family: $font-zen;
          color:#fff;
          font-size: 14px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-right.svg");
          background-size: 9px;
          background-repeat: no-repeat;
          background-position: right 36px center;
          transition: 0.3s;
        }
      }
      .logo-zeon-owndays{
        img{
          width: calc((283 / 390)* 100vw);
          margin-left: auto;
          margin-right: auto;
        }
      }
      .sub-zeon-owndays{
        text-align: center;
        font-size: calc((18 / 390)* 100vw);
        letter-spacing: 2.88px;
        font-family: $font-zen-old;
        margin-top: 8px;
      }
    }
    @include mq-up() {
      padding: 0 0 59px;
      &__container{
        picture{
          img {
            aspect-ratio: 1439/528;
          }
        }
      }
      &__detail{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        bottom: auto;
        .banner-category{
          margin-bottom: 32px;
          p{
            font-size: 16px;
            letter-spacing: 4.8px;
          }
        }
      }
      .btn-banner{
        max-width: 250px;
        margin-top: 26px;
        a{
          height: 50px;
        }
        &:hover{ 
          animation: gundam_shine2 2s; 
        } 
      }
      .logo-zeon-owndays{
        img{
          width: calc((240 / 768)* 100vw);
        }
      }
      .sub-zeon-owndays{
        font-size: calc((18 / 1440)* 100vw);
        line-height: calc((39 / 1440)* 100vw);
        margin-top: 13px;
        letter-spacing: 2.88px;
        font-size: 13px;
        br{
          display: none;
        }
      }
    }
    @include mq-up(lg) {
      .logo-zeon-owndays{
        img{
          width: calc((407 / 1440)* 100vw);
        }
      }
      .btn-banner{
        margin-top: 35px;
        max-width: 350px;
        a{
          font-size: 18px;
          height: 80px;
        }
      }
      .sub-zeon-owndays{
        font-size: 18px;
      }
    }
    @media (min-width: 1300px) {
      .btn-banner{
        max-width: 400px;
      }
    }
  }

  // SHARE
  .share {
    margin: 60px 0 100px;
    padding: 43px 0 68px;
    background-color: #150707;
    h2{
      font-size: 30px;
      font-weight: 700;
      line-height: 110%; 
      color: #fff;
      font-family: $font-post;
      text-align: center;
        letter-spacing: 3px;
    }
    .list-sns{
      display: flex;
      justify-content: center;
      margin-top: 23px;
      align-items: center;
      li{
        margin: 0 20px;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
        &:nth-child(2){
          a{
              width: 28px;
              height: 28px;
          }
        }
      }
      a{
        width: 35px;
        height: 35px;
        img{
          aspect-ratio: 1/1;
        }
      }
    }
    @include mq-up(){
      margin-bottom: 130px;
      padding: 0;
      height: 100px;
      background-color: #150707;
      display: flex;
      justify-content: center;
      h2{
        font-size: 40px;
        padding-top: 5px;
        letter-spacing: 4px;
      }
      &__container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 57px;
      }
      .list-sns{
        margin-top: 0;
        gap: 36px;
        li{
          margin: 0;
        }
      }
    }
  }

  .c-modal-video {
    display: block;
    position: relative;
    width: auto;
    margin: 71px var(--edge) 0;
    transition: none;
  
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: calc((63/390)*100vw);
      max-width: 86px;
      background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/video-play.svg") center/contain no-repeat;
      z-index: 2;
      pointer-events: none;
    }
    &--iframe {
      padding-top: calc(56.25% + var(--pd) + 2px);
      overflow: hidden;
    }
    picture {
      opacity: 1 !important;
      overflow: hidden;
  
      img {
        width: 100%;
        opacity: 1 !important;
        aspect-ratio: 107/51;
      }
    }
    iframe {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @include mq-up() {
      &--iframe {
        &:hover {
          iframe {
            transform: scale(1.1);
          }
        }
      }
      picture {
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
      img,
      iframe {
        transition: transform 0.6s;
      }
    }
    @include mq-up(lg) {
      margin: 300px 0 0 0;
  
      &--iframe {
        padding-top: 56.25%;
      }
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes gundam_move {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes gundam_shine {
    0% {
      background: #B31111;
    }
  
    8% {
      background: #FF8383;
    }
  
    100% {
      background: #B31111;
    }
  }
  
  @keyframes gundam_shine2 {
    0% {
      background-color: $gold;
    }
  
    8% {
      background-color: #FFD086;
    }
  
    100% {
      background-color: $gold;
    }
  }
}

@include mq-down() {
  .u-pc-only {
    display: none !important;
  }
}
@include mq-up() {
  .u-sp-only {
    display: none !important;
  }
}

.fancybox {
  &-slide {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  &-gallery {
    .fancybox {
      &-stage {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        pointer-events: none;

        &-bottom {
          width: 100%;
          padding-top: calc((283/390)*100%);
          order: 2;
        }
      }

      &-slide {
        padding: 0;
        //height: auto;
        top: auto;
        order: 1;
        pointer-events: none;
      }

      &-content {
        position: static !important;
        transform: translate(0) !important;
        max-width: 640px !important;
        // max-height: var(--fcbox-max-h, 465px);
        max-height: 465px;
        cursor: auto !important;
        pointer-events: auto;
      }

      &-image {
        position: static !important;
      }

      &-caption {
        padding: 0;
        margin: 20px auto 0;
        position: static;
        background: none;
        color: $white;
        max-width: 640px;
        pointer-events: auto;

        &__body {
          font: 500 12px/1 $font-zen;
          text-align: left;
          padding: 0 20px 0;
          display: table;
        }
      }

      &-navigation {
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 90px;

        .fancybox-button {
          position: static;
          padding: 0;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: $white;
          border: 1px solid #666;
          top: calc(100% + 123px);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 7px;
          pointer-events: auto;
          cursor: pointer;
          
          i {
            display: inline-block;
            width: 12px;
            height: 22px;
            background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-right-black.svg") center/contain no-repeat;
          }

          &--arrow_left {
            left: calc(50% - 67px);

            i {
              transform: scaleX(-1);
            }
          }

          &--arrow_right {
            right: calc(50% - 67px);
          }
        }
      }

      &-infobar {
        text-align: center;
        position: static;
        height: auto;
        padding: 0;
        margin-top: 5px;
        line-height: 1;
        pointer-events: auto;
        transform: none !important;

        span {
          font: 500 12px/1 $font-zen;
        }
      }

      &-button {
        &--close {
          background-color: rgba(0, 0, 0, 0.6);
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 20px;
            height: 20px;
            background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-close.svg") center/contain no-repeat;
          }
        }
      }
    }
  }

  @media (min-width:640px) {
    &-gallery {
      .fancybox {
        &-stage {
          &-bottom {
            // padding-top: var(--fcbox-max-h, 465px);
            padding-top: 465px;
          }
        }

        &-navigation {
          margin-top: 70px;

          .fancybox-button {
            width: 80px;
            height: 80px;

            i {
              width: 15px;
              height: 28px;
            }
          }
        }

        &-infobar {
          >span {
            font-size: 16px;
          }
        }
      }
    }
  }

  @include mq-up() {
    &-gallery {
      .fancybox {
        &-caption {
          margin-top: 30px;

          &__body {
            font-size: 16px;
            padding: 0;
          }
        }
      }
    }
  }
}

@include mq-down(1153) {
  .breadcrumbContainer_wrapper {
    transform: translate(20px, 4px) !important;
  }
}

.fancybox__content {
  width: 100% !important;
  max-width: 1395px !important;
  height: 763px !important;
  padding: 40px !important;
  @include mq-down(768) {
    padding: 0px !important;
    height: 500px !important;
  }
}

.is-close-btn{
    background: rgba(30, 30, 30, .6);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: fixed !important;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
    top: 0 !important;
    &:hover{
      background: rgba(30, 30, 30, .6) !important;
    }
}

.yt-modal__content{
  width: 80vw;
  height: 80vh;
  margin: 0 auto;
}
.yt-modal__overlay{
  background-color: rgba(30, 30, 30, .9) !important;
}
.c-modal-video{
  cursor: pointer;
}