.od-dkp {
  width: 100%;
}

.od-dkp-banner {
  width: 100%;
  color: #000000;
  text-align: center;
  margin-bottom: 60px;
}

.od-dkp-banner__img {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 133px;
}

.od-dkp-banner__title {
  width: 100%;
  padding: 0 107px;
  font-size: 48px;
  line-height: 60px;
  margin-top: 80px;
}

.od-dkp-banner__description {
  width: 100%;
  padding: 0 283px;
  font-size: 20px;
  line-height: 40px;
  margin-top: 40px;
  opacity: 0.7;
}

.od-dkp-separator {
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.2;
}

.od-dkp-btns {
  margin-top: 60px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 76px;
}

.od-dkp .od-dkp-options {
  width: 100%;
  margin: 40px auto;
  color: #000000;
  max-width: 1209px;
}

.od-dkp .od-dkp-options .od-dkp__option-one {
  background-color: #FAFAFA;
  margin: 0 auto;
  padding-bottom: 51px;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 53px;
  padding-left: 100px;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info__left {
  max-width: 611px;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info__left__title {
  font-size: 16px;
  line-height: 19px;
  opacity: 0.5;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info__left__subTitle {
  font-size: 32px;
  line-height: 38px;
  margin-top: 30px;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info__left__description {
  max-width: 564px;
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 30px;
  font-size: 16px;
  line-height: 19px;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info__right {
  width: 300px;
  height: 470px;
  margin: 50px 145px 0 0;
}

.od-dkp .od-dkp-options .od-dkp__option-one__info__right-new-customer {
  margin: 100px 120px 100px 0;
}

.od-dkp .od-dkp-options .od-dkp__option-one__register-btn {
  margin-top: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.od-dkp .od-dkp-options .od-dkp__option-one__register-btn__description {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.od-dkp .od-dkp-options .option-two-customer {
  padding: 50px 100px;
}

.od-dkp .od-dkp-options .option-two-new-customer {
  padding: 50px 130px 108px 100px;
}

.od-dkp .od-dkp-options .od-dkp__option-two {
  background-color: #FAFAFA;
  margin: 40px auto;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 39px;
}

.od-dkp .od-dkp-options .od-dkp__option-two .od-dkp__option-two__info {
  width: 100%;
}

.od-dkp .od-dkp-options .od-dkp__option-two__title {
  font-size: 16px;
  line-height: 19px;
  opacity: 0.5;
}

.od-dkp .od-dkp-options .od-dkp__option-two__subTitle {
  margin-top: 30px;
  font-size: 32px;
  line-height: 37px;
}

.od-dkp .od-dkp-options .od-dkp__option-two__description {
  margin-top: 30px;
  font-size: 16px;
  line-height: 25px;
}

.od-dkp .od-dkp-options .od-dkp__option-two__description-list {
  margin-top: 30px;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
}

.od-dkp .od-dkp-options .od-dkp__option-two__description-list li {
  list-style: inside;
}

.od-dkp .od-dkp-options .od-dkp__option-two__btns {
  width: 100%;
  margin-top: 78px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
  color: #000000;
}

.od-dkp .od-dkp-options .od-dkp__option-two-img {
  width: 284px;
  height: 202px;
}

.od-dkp .od-contact-us-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-dkp .od-contact-us-content__separator {
  width: 20px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border: 1px solid #000000;
  opacity: 0.2;
}
