$base-img-url: 'https://static.lenskart.com/media/owndays/img/pompompurin';

$font-heisei: "heisei-maru-gothic-std", sans-serif;
$font-corporate: "Corporate Logo Rounded ver2", sans-serif;
$font-fredoka: "Fredoka", sans-serif;

$yellow-fff: #fff9b1;
$brown-76: #76332e;
$brown-ee: #eedabe;

.header-pompompurin {
  &__main {
    padding-top: 122px;
    padding-inline: 20px;
    margin-bottom: 195.03px;

    &__fg {
      position: absolute;
      top: 0;

      &--left {
        max-width: 131px;
        left: 0;
      }

      &--right {
        max-width: 150px;
        right: 0;
      }
    }

    &__grid {
      display: grid;
    }

    &__logo {
      margin: auto 0;

      img {
        aspect-ratio: 71/37;
        max-width: 221px;
        margin: 0 auto;
      }
    }

    &__img {
      img {
        aspect-ratio: 217/145;
        position: relative;
      }
    }

    @media (max-width: 767px) {
      &__logo {
        display: flex;
        margin-bottom: 60px;
      }

      &__img {
        padding-inline: 20px;
      }
    }

    @media (min-width: 768px) {
      padding: 100px 0 0 0;
      margin-bottom: 100px;

      &__grid {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        align-items: center;
        gap: 10%;
        padding-left: 80px;
      }

      &__logo {
        align-self: end;
      }

      &__img {
        grid-column: 2;
        grid-row: 1 / span 2;
      }
    }

    @media (min-width: 1024px) {
      padding-top: 200px;
      margin-bottom: 200px;

      &__fg {
        &--left {
          max-width: 309px;
        }
        &--right {
          max-width: 306px;
        }
      }

      &__grid {
        column-gap: 150px;
        padding-left: 0;
      }

      &__logo {
        img {
          max-width: 355px;
        }
      }
    }
  }

  &__about {
    margin-block: 94px 116px;

    &__fg {
      position: absolute;

      &--char-1 {
        max-width: 130px;
        top: -34px;
        right: calc(50% + 46px);
      }

      &--char-2 {
        max-width: 144px;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
      }

      &--footprints-1 {
        max-width: 75px;
        top: 53px;
        left: calc(50% + 106px);
      }

      &--footprints-2 {
        max-width: 123px;
        bottom: 18px;
        right: calc(50% + 296px);
      }

      &--pudding {
        max-width: 61px;
        right: calc(50% + 117px);
        bottom: 69px;
      }

      &--bone {
        max-width: 103px;
        top: 260px;
        right: calc(50% + 346px);
      }

      &--cherry {
        max-width: 91px;
        top: 256px;
        left: calc(50% + 323px);
      }

      &--mike {
        max-width: 71px;
        bottom: 150px;
        left: calc(50% + 328px);
      }
    }

    &__content {
      position: relative;
      background: url("#{$base-img-url}/about-circle-bg.svg") no-repeat center / auto 100%;
      padding-block: 125px 156px;
      min-height: 831px;
      display: grid;
      place-items: center;

      h2, p {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h2 {
        margin-bottom: 40px;

        img {
          aspect-ratio: 479/117;
          max-width: 307px;
          margin-inline: auto;
        }
      }

      p {
        font-size: 14px;
        font-weight: 800 !important;
        letter-spacing: 0px;
        line-height: calc(41 / 16);
        text-align: left;
        margin: 0 auto 40px auto;
        max-width: 592px;
        padding-inline: 20px;

        strong {
          font-size: 20px;
          font-weight: inherit !important;
          letter-spacing: 0.2px;
        }
      }
    }

    @media (min-width: 768px) {
      &__fg {
        &--char-1 {
          max-width: 185px;
          top: 4px;
          right: calc(50% + 156px);
        }
        &--char-2 {
          max-width: 198px;
          bottom: -11px;
        }
        &--footprints-1 {
          max-width: 123px;
          top: 36px;
          left: calc(50% + 309px);
        }
        &--pudding {
          max-width: 96px;
          bottom: 175px;
          right: calc(50% + 317px);
        }
      }
      &__content {
        min-height: 873px;

        p {
          text-align: center;
          padding-inline: 0;
        }

        h2 {
          img {
            max-width: 500px;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      margin-block: 200px 161px;
      &__content {
        padding-top: 171px;
        h2 {
          margin-bottom: 47px;
        }
        p {
          font-size: 18px;
          letter-spacing: 0.18px;
          margin-bottom: 54px;
          strong {
            font-size: 22px;
            letter-spacing: 0.22px;
          }
        }
      }
    }
  }

  &__lineup {
    background-color: $brown-76;
    padding-bottom: 179px;
    margin-top: 211px;
    z-index: 1;

    &__fg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translateY(calc(-100% + 16px));
      width: 100%;
      height: 186px;
      object-fit: cover;
      object-position: 63% top;
      z-index: -1;
      aspect-ratio: 1513/250;
    }

    &__title {
      color: white;
      font-size: 32px;
      font-weight: 700 !important;
      letter-spacing: 2.56px;
      text-align: center;
      line-height: 1.25;
      margin-bottom: 30px;
    }

    &__nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px 5px;
      max-width: 1030px;
      margin-inline: auto;

      &-item {
        position: relative;
        max-width: 112px;
        padding-top: calc(108px / 2);
        transition: 0.5s;
        list-style-type: none;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          aspect-ratio: 1/1;
          background-color: $brown-ee;
          border-radius: 100%;
          z-index: -1;
        }
      }

      &-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        aspect-ratio: 1/1;
        z-index: 1;
      }

      &-content {
        background-color: white;
        border-radius: 0px 0px 10px 10px;
        display: grid;
        gap: 4px;
        padding: 18px 9px 10px;
      }

      &-product {
        width: 100%;
        aspect-ratio: 120/67;
        max-width: 240px;
        margin-inline: auto;
        object-fit: contain;
      }

      &-text {
        font-family: $font-fredoka !important;
        font-weight: 700 !important;
        font-size: 11px;
        line-height: 10px;
        letter-spacing: 0px;
        text-align: center;
        color: $brown-76;
        white-space: nowrap;

        strong {
          display: block;
          font-family: $font-fredoka !important;
          font-weight: 700 !important;
          font-size: 15px;
          line-height: 1.25;
          letter-spacing: 1.5px;
          color: $yellow-fff;
          text-shadow: 1px 0 0 $brown-76, -1px 0 0 $brown-76, 0 1px 0 $brown-76,
            0 -1px 0 $brown-76;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: 576px;
      &__nav {
        gap: calc(40px / 1.5) calc(26px / 1.5);
        &-item {
          max-width: calc(326px / 1.5);
          padding-top: calc(217px / 2);
        }
      }
    }

    @media (min-width: 768px) {
      margin-top: 340px;
      padding-top: 27px;
      &__fg {
        height: 250px;
        background-position: center top;
        transform: translateY(calc(-100% + 14px));
      }
      &__title {
        font-size: 70px;
        letter-spacing: 5.6px;
        margin-bottom: 68px;
      }

      &__nav {
        gap: calc(40px / 1.25) calc(26px / 1.25);

        &-item {
          max-width: calc(326px / 1.25);
          padding-top: calc(260px / 2);

          &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
          }

          &:hover {
            img {
              transition: 0.5s;
              opacity: .6;
            }
          }
        }

        &-content {
          border-radius: 0px 0px 30px 30px;
          padding: 49px 25px 33px;
        }

        &-text {
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 0.49px;
          color: $brown-76;

          strong {
            font-size: 35px;
            letter-spacing: 3.567px;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      &__nav {
        &-item {
          width: 100%;
        }
      }
    }

    @media (min-width: 1296px) {
      &__nav {
        gap: 40px 26px;
        &-item {
          max-width: 326px;
          padding-top: calc(326px / 2);
        }
      }
    }
  }
}
