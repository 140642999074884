.header-anniversary-10th-collection__main {
  display: grid;
}

.header-anniversary-10th-collection__main picture {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.header-anniversary-10th-collection__main picture img {
  width: 100%;
  height: auto;
  display: block;
}

.header-anniversary-10th-collection__main__text {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding-top: 38px;
}

.header-anniversary-10th-collection__main h1 {
  font-family: "Miller-Banner";
  font-weight: 400 !important;
  line-height: 9.55vw;
  letter-spacing: 0.84px;
  color: #815624 !important;
  text-align: center;
}

.header-anniversary-10th-collection__main h1 small, .header-anniversary-10th-collection__main h1 + small {
  font-family: "Tahoma" !important;
  font-size: 4.125vw;
  font-weight: 400 !important;
  letter-spacing: 0.48px;
  color: #815624 !important;
}

.header-anniversary-10th-collection__main h1 small {
  display: block;
  font-size: 4.125vw;
  margin-top: 11px;
  line-height: 9.55vw;
  letter-spacing: 0.48px;
}

.header-anniversary-10th-collection__main h1 + small {
  display: block;
  text-align: center;
  margin-top: 11px;
}

.header-anniversary-10th-collection__main h1 strong {
  font-family: inherit !important;
  color: #815624 !important;
}

.header-anniversary-10th-collection__main__swift-up-text {
  display: block;
  font-weight: inherit;
  overflow: hidden;
  padding-bottom: 6px;
  margin-bottom: -6px;
}

.header-anniversary-10th-collection__main__swift-up-text i {
  font-style: normal;
  position: relative;
  top: calc(7.2vw + 8px);
  background: transparent;
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__main__swift-up-text i {
    top: 0;
    -webkit-transform: translateY(calc(7.2vw + 8px));
            transform: translateY(calc(7.2vw + 8px));
  }
}

.header-anniversary-10th-collection__main__swift-up-text.aos-animate span {
  -webkit-animation: .3s header-anniversary-10th-collection-swift-up ease-in-out forwards;
          animation: .3s header-anniversary-10th-collection-swift-up ease-in-out forwards;
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__main__swift-up-text.aos-animate span {
    -webkit-animation: .3s header-anniversary-10th-collection-swift-up-pc ease-in-out forwards;
            animation: .3s header-anniversary-10th-collection-swift-up-pc ease-in-out forwards;
  }
}

.header-anniversary-10th-collection__main__swift-up-text.aos-animate i {
  -webkit-animation: .4s header-anniversary-10th-collection-swift-up ease-in-out forwards;
          animation: .4s header-anniversary-10th-collection-swift-up ease-in-out forwards;
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__main__swift-up-text.aos-animate i {
    display: inline-block;
    -webkit-animation: .4s header-anniversary-10th-collection-swift-up-pc ease-in-out forwards;
            animation: .4s header-anniversary-10th-collection-swift-up-pc ease-in-out forwards;
  }
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__main picture img {
    min-height: 845px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: bottom;
       object-position: bottom;
  }
  .header-anniversary-10th-collection__main__text {
    padding-top: 80px;
  }
  .header-anniversary-10th-collection__main h1 {
    font-size: 46px;
    letter-spacing: 1.38px;
    line-height: 1;
  }
  .header-anniversary-10th-collection__main h1 small {
    font-size: 20px;
    letter-spacing: 0.6px;
    line-height: 1;
    margin-top: 23px;
  }
  .header-anniversary-10th-collection__main h1 + small {
    font-size: 20px;
    letter-spacing: 0.6px;
    line-height: 1;
    margin-top: 23px;
  }
}

.header-anniversary-10th-collection__concept {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.42px;
  color: #815624;
  padding-top: 63px;
  padding-bottom: 70px;
}

.header-anniversary-10th-collection__concept p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
}

.header-anniversary-10th-collection__concept a {
  margin-top: 64px;
  background-color: black;
}

.header-anniversary-10th-collection__concept a .base-anniversary-10th-collection__frame {
  position: relative;
}

.header-anniversary-10th-collection__concept a .base-anniversary-10th-collection__frame::after {
  content: "";
  position: absolute;
  width: 124px;
  height: 109px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: url("https://static.lenskart.com/owndays/img/anniversary-10th-collection/play-video.svg") center/contain no-repeat;
  z-index: 9;
  pointer-events: none;
}

.header-anniversary-10th-collection__concept a img {
  width: calc(100% + 40px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__concept {
    line-height: 32px;
    letter-spacing: 0.48px;
    padding-top: 150px;
    padding-bottom: 186px;
  }
  .header-anniversary-10th-collection__concept a {
    overflow: hidden;
    border-radius: 15px;
  }
  .header-anniversary-10th-collection__concept .base-anniversary-10th-collection__container {
    max-width: 920px;
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__concept a {
    cursor: pointer;
  }
  .header-anniversary-10th-collection__concept a:hover, .header-anniversary-10th-collection__concept a:active, .header-anniversary-10th-collection__concept a:visited, .header-anniversary-10th-collection__concept a:focus {
    text-decoration: none;
  }
  .header-anniversary-10th-collection__concept a:hover .base-anniversary-10th-collection__frame {
    background-color: black;
  }
  .header-anniversary-10th-collection__concept a:hover .base-anniversary-10th-collection__frame img {
    opacity: 0.6;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

@media (max-width: 767px) {
  .header-anniversary-10th-collection__lineup-menu__layout {
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__layout {
    display: grid;
    grid-template-columns: 45% auto;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 35px;
    grid-row-gap: 0px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__lineup-menu__layout {
    grid-template-columns: 465px auto;
  }
}

@media (min-width: 1296px) {
  .header-anniversary-10th-collection__lineup-menu__layout {
    grid-column-gap: 75px;
  }
}

@media (min-width: 1440px) {
  .header-anniversary-10th-collection__lineup-menu__layout {
    margin-left: -100px;
  }
}

.header-anniversary-10th-collection__lineup-menu__title h2 {
  text-align: center;
  font-size: 32px;
  line-height: 2.625em;
  color: #815624;
  font-family: "Miller-Banner";
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__title h2 {
    font-size: 50px;
    line-height: 2.5em;
    line-height: 1;
    margin-bottom: 20px;
  }
}

.header-anniversary-10th-collection__lineup-menu__banner__text {
  background-color: #815624;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 0;
}

.header-anniversary-10th-collection__lineup-menu__banner__text__layout p {
  font-size: 15px;
  font-weight: 500 !important;
  line-height: 2;
  letter-spacing: 0.42px;
  margin-bottom: 0;
}

.header-anniversary-10th-collection__lineup-menu__banner__text__layout:nth-child(2) {
  margin-left: 43px;
}

@media (min-width: 1296px) {
  .header-anniversary-10th-collection__lineup-menu__banner__text__layout p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.48px;
  }
}

@media (min-width: 1296px) {
  .header-anniversary-10th-collection__lineup-menu__banner__text {
    gap: 43px;
  }
}

.header-anniversary-10th-collection__lineup-menu__menu {
  margin-top: 23px;
  width: 100%;
}

.header-anniversary-10th-collection__lineup-menu__menu ul {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .header-anniversary-10th-collection__lineup-menu__menu ul {
    max-width: 300px;
    margin: 0 auto;
  }
}

@media (min-width: 1296px) {
  .header-anniversary-10th-collection__lineup-menu__menu ul {
    gap: 17px;
  }
}

.header-anniversary-10th-collection__lineup-menu__menu ul li {
  background-color: white;
  border: 1px solid #815624;
  height: 145px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

@media (max-width: 767px) {
  .header-anniversary-10th-collection__lineup-menu__menu ul li {
    width: 150px;
  }
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__menu ul li {
    min-height: calc(17.5vw + 15px);
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__lineup-menu__menu ul li {
    min-height: 223px;
  }
}

.header-anniversary-10th-collection__lineup-menu__menu__title {
  font-size: 14px;
  line-height: 2.875em;
  text-align: center;
  color: #815624;
  font-family: "Miller-Banner";
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__menu__title {
    font-size: 2vw;
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__lineup-menu__menu__title {
    font-size: 20px;
    line-height: 1;
    margin-top: 27px;
    margin-bottom: 6px;
  }
}

.header-anniversary-10th-collection__lineup-menu__menu__image {
  width: 128px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__menu__image {
    width: 16vw;
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__lineup-menu__menu__image {
    width: 196px;
  }
}

.header-anniversary-10th-collection__lineup-menu__menu__arrow {
  width: 25.71px;
  height: 30px;
  margin: 5px auto;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__menu__arrow {
    width: 3vw;
    margin-top: 10px;
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__lineup-menu__menu__arrow {
    margin-top: 10px;
    width: 44px;
  }
}

@media (min-width: 1296px) {
  .header-anniversary-10th-collection__lineup-menu__menu__arrow {
    margin-top: 8px;
  }
}

@media (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__menu {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
  }
  .header-anniversary-10th-collection__lineup-menu__menu ul li {
    min-height: calc(17.5vw + 15px);
  }
  .header-anniversary-10th-collection__lineup-menu__menu--div1 {
    grid-area: 1 / 1 / 2 / 2;
    -ms-flex-item-align: end;
        align-self: end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .header-anniversary-10th-collection__lineup-menu__menu--div2 {
    grid-area: 2 / 1 / 3 / 2;
    height: 78px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
    padding: 0 19px !important;
  }
  .header-anniversary-10th-collection__lineup-menu__menu--div3 {
    grid-area: 1 / 2 / 4 / 3;
    height: 100%;
    margin-right: -40px;
  }
  .header-anniversary-10th-collection__lineup-menu__menu--div3 img {
    width: 100%;
    height: 100% !important;
    -o-object-fit: cover;
       object-fit: cover;
    aspect-ratio: 65/41;
  }
  .header-anniversary-10th-collection__lineup-menu__menu--div4 {
    grid-area: 3 / 1 / 4 / 2;
    margin-bottom: 40px;
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .header-anniversary-10th-collection__lineup-menu__menu--div4 {
    margin-bottom: 4vw;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .header-anniversary-10th-collection__lineup-menu__menu ul li {
    min-height: 223px;
    cursor: pointer;
  }
  .header-anniversary-10th-collection__lineup-menu__menu ul li:hover .header-anniversary-10th-collection__lineup-menu__menu__arrow {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

@media (min-width: 1120px) {
  .header-anniversary-10th-collection__lineup-menu__menu--div3 {
    margin-right: calc(520px - 50vw);
  }
}

@-webkit-keyframes header-anniversary-10th-collection-swift-up {
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes header-anniversary-10th-collection-swift-up {
  to {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes header-anniversary-10th-collection-swift-up-pc {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes header-anniversary-10th-collection-swift-up-pc {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
