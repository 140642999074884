$font-founders: 'Founders Grotesk', sans-serif;

.odslv-modal-pt__item {
  padding: 16px 24px;
  cursor: pointer;
  margin-bottom: 16px;
  user-select: none;
  border: 1px solid #CDCDCD;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  font-family: $font-founders;

  @media (max-width: 768px) {
    gap: 8px;
    padding: 16px;
  }

  &--active {
    border: 2px solid #546073;
  }

  &__info {
    width: 100%;
  }

  &__img {
    width: 40px;
    height: 40px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 0px;
  }

  &__name {
    color: #444;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__description {
    color: #444;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
