$font-founders: 'Founders Grotesk', sans-serif;
$font-he: heisei-maru-gothic-std, sans-serif;

$blue-00: #0094c8;
$gray-53: #535353;

.footer-air {
  &__recommended {
    position: relative;
    padding-block: 80px 100px;

    h2 {
      text-align: center;
      font-family: $font-founders;
      font-size: 24px;
      font-weight: 600 !important;
      letter-spacing: .48px;
      line-height: 1.25;
      margin-bottom: 60px;
      color: black;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: grid;
      gap: 40px 35px;

      li {
        display: flex;
        flex-direction: column;
        position: relative;

        img {
          display: block;
          aspect-ratio: 39 / 23;
          margin-bottom: 16px;
          transition: all .3s ease;
        }

        h3, p {
          font-family: $font-founders;
          color: black;
          margin: 0 0 10px 0;
        }

        h3 {
          font-size: 18px;
          font-weight: 600 !important;
          line-height: 1.25;
          letter-spacing: .72px;
        }

        p, a {
          font-family: $font-he;
          font-size: 14px;
          font-weight: 500 !important;
        }

        p {
          font-weight: 500 !important;
          letter-spacing: .28px;
          line-height: 2;
        }

        a {
          outline: none;
          text-decoration: none;
          transition: .5s;
          cursor: pointer;
          display: inline-flex;
          color: $blue-00;
          line-height: 1;
          letter-spacing: .56px;

          &::before {
            content: "";
            position: absolute;
            inset: 0;
          }
        }
      }
    }

    @media (min-width: 768px) {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    @media (min-width: 1024px) {
      padding-block: 140px;

      h2 {
        font-size: 36px;
        letter-spacing: 0.72px;
        margin-bottom: 100px;
      }

      ul {
        grid-row-gap: 60px;

        li {
          h3 {
            font-size: 24px;
            letter-spacing: 1.44px;
            line-height: 1;
          }

          &:hover {
            img {
              opacity: .6;
            }
          }
        }
      }
    }
  }

  &__navs-sticky {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 55px;
    z-index: 2;

    .base-air__container {
      padding-inline: 0;
    }

    .base-air__btn {
      width: 160px !important;
      height: 55px;
      border-radius: 0;
      white-space: nowrap;
    }

    .base-air__btn--primary {
      border-top-left-radius: 15px !important;
    }

    &__inner {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: auto;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      max-width: 823px;
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      bottom: 47px;

      .base-air__btn {
        width: 340px !important;
        border-radius: 10px !important;
      }

      &__actions {
        justify-content: center;
        gap: 40px;
        max-width: none;
      }

      &__inner {
        background-color: rgba(0, 0, 0, .4);
        padding: 24px 0;
      }
    }
  }
}
