@charset "UTF-8";
:root {
  --pd: 20px;
  --pd-minus: calc(var(--pd) * -1);
  --container-max-w: 1240px;
  --edge: var(--pd-minus);
  --edge-aw: var(--edge);
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-size: 5px;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2.5px;
}

.l-hp {
  font: 400 14px/1.7 source-han-serif-jp-subset, sans-serif;
  color: #fff;
  position: relative;
  margin-top: 15px;
}

.l-hp::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://www.owndays.com/images/specials/products/harry-potter/bg-main.webp") top center/15% auto;
  pointer-events: none;
}

.l-hp *,
.l-hp *::before,
.l-hp *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  color: inherit;
  background: none;
}

.l-hp ul,
.l-hp ol {
  list-style: none;
}

.l-hp button {
  cursor: pointer;
  background-color: unset;
}

.l-hp button,
.l-hp input,
.l-hp optgroup,
.l-hp select,
.l-hp textarea {
  color: inherit;
  font: inherit;
}

.l-hp a {
  cursor: pointer;
  text-decoration: none !important;
  outline: none;
}

.l-hp img,
.l-hp picture {
  width: 100%;
  height: auto;
  display: block;
  vertical-align: middle;
}

.l-hp > section > * {
  position: relative;
  z-index: 2;
}

.l-hp__container {
  max-width: var(--container-max-w);
  padding-left: var(--pd);
  padding-right: var(--pd);
  margin-left: auto;
  margin-right: auto;
  line-height: 1.9;
}

.l-hp__container-sm {
  max-width: calc(860px + (var(--pd) * 2));
  padding-left: var(--pd);
  padding-right: var(--pd);
  margin-left: auto;
  margin-right: auto;
}

.l-hp__btn {
  display: table;
  margin: 0 auto;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.l-hp__btn-inner {
  font: 400 15px/1 pollen-web, source-han-serif-jp-subset, serif;
  letter-spacing: 1.5px;
  text-shadow: 0px 0px 3px #fff;
  padding: 4px 10px 0;
  width: 250px;
  max-width: 400px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(75deg, #bce6ee -31.92%, #ddc5a3 -22.81%, #c9a885 -10.67%, #9a7a5f 7.55%, #c9a885 22.73%, #eedebc 42.47%, #ddc5a3 50.06%, #c9a885 60.68%, #9a7a5f 77.38%, #c9a885 95.6%, #eedebc 119.89%);
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff !important;
  white-space: nowrap;
}

.l-hp__btn-inner .space {
  width: 0.4em;
}

.l-hp .text-effect span {
  font-size: inherit;
  font-weight: inherit;
  display: inline-block;
  padding-top: 2px;
  background: linear-gradient(90deg, #FBAA2B 0%, #F4B759 17%, #EDC689 37%, #E7D1B0 56%, #E3DACB 73%, #E0DFDC 88%, #E0E1E2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.l-hp .bg-lg {
  background: linear-gradient(180deg, #250000 3.24%, #72101a 8.42%, #72101a 88.54%, #250000 95.28%);
}

.l-hp__title {
  font-family: pollen-web, source-han-serif-jp-subset, serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700 !important;
  letter-spacing: 6px;
  position: relative;
  text-align: center;
  padding: 46px 0 0;
  margin-bottom: 50px;
}

.l-hp__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://www.owndays.com/images/specials/products/harry-potter/golden-snitch.svg") no-repeat top center/auto 26px;
  pointer-events: none;
}

.l-hp__title--sm {
  font-size: 25px;
  letter-spacing: 4px;
  line-height: 36px;
}

.l-hp .swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.l-hp .swiper-pagination-bullet {
  border-radius: 1rem;
  box-shadow: 0px 0px 3px 0px #fff;
}

.l-hp .swiper-pagination-bullet-active {
  width: 15px;
}

.l-hp .tab-contents .tab-content:not(.active) {
  display: none;
}

.l-hp .main {
  position: relative;
  min-height: unset;
}

.l-hp .main__detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  bottom: 0;
  padding-top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(37, 2, 2, 0.8) 58.1%, #250202 83.01%);
}

.l-hp .main__detail-col {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.l-hp .main__logo {
  width: 100%;
  max-width: 183px;
  margin: 0 auto 62px;
  display: block;
}

.l-hp .main__date {
  width: 38.8%;
  max-width: 200px;
  display: block;
  margin: 0 15px 0 0;
}

.l-hp .main__date img {
  aspect-ratio: 60/13;
}

.l-hp .main p {
  font-size: 14px;
  padding-left: 5px;
}

.l-hp .main__bg {
  position: relative;
  overflow: hidden;
  transform: none;
}

@media (max-width: 767px) {
  .l-hp .main__img {
    aspect-ratio: 125/184;
  }
  .l-hp .main__logo img {
    aspect-ratio: 183/135;
  }
}

.l-hp .concept {
  background: radial-gradient(49.95% 49.95% at 50% 50.05%, #72101a 26.41%, #250000 100%);
  margin-bottom: -1px;
}

.l-hp .concept__title {
  font: 400 16px/28px source-han-serif-jp-subset, sans-serif;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 30px;
}

.l-hp .concept p {
  font-size: 14px;
  line-height: 32px;
  letter-spacing: normal;
  margin-bottom: 60px;
}

.l-hp .concept .l-hp__container-sm {
  padding: 230px 40px 266px;
  position: relative;
}

.l-hp .concept__el {
  position: absolute;
  display: block;
  max-width: 80px;
  pointer-events: none;
}

.l-hp .concept__el--1 {
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 53.4%;
  max-width: 238px;
}

.l-hp .concept__el--2 {
  top: 166px;
  left: 40px;
  width: 11.3%;
}

.l-hp .concept__el--3 {
  top: 173px;
  right: 40px;
  width: 12.4%;
}

.l-hp .concept__el--4 {
  bottom: 169px;
  left: 40px;
  width: 14.9%;
}

.l-hp .concept__el--5 {
  bottom: 163px;
  right: 40px;
  width: 15.2%;
}

.l-hp .concept__el--6 {
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 70.6%;
  max-width: 315px;
}

@media (max-width: 767px) {
  .l-hp .concept .l-hp__container-sm {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.l-hp .lineup {
  padding: 100px 0 100px;
}

.l-hp .lineup__price {
  font: 400 14px/1 source-han-serif-jp-subset, sans-serif;
  text-align: center;
  position: relative;
  letter-spacing: 0.84px;
}

.l-hp .lineup__price-strong {
  font-family: pollen-web, source-han-serif-jp-subset, serif;
  font-size: 35px;
  font-weight: 700;
  margin-top: 10px;
  display: block;
  letter-spacing: normal;
}

.l-hp .lineup__price-span {
  font-family: source-han-serif-jp-subset, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.18px;
  margin-right: 5px;
}

.l-hp .lineup__nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 46px 24px;
  margin-top: 70px;
  counter-reset: section;
  width: calc(100% + 8px);
  transform: translateX(calc(8px / 2 * -1));
}

.l-hp .lineup__nav > li {
  position: relative;
}

.l-hp .lineup__nav > li > a {
  display: block;
  position: relative;
  opacity: 1 !important;
}

.l-hp .lineup__nav > li > a::after {
  content: "";
  position: absolute;
  width: 39px;
  height: 39px;
  background: url("https://www.owndays.com/images/specials/products/harry-potter/arrow-down-2.svg") no-repeat 50%/contain;
  margin: 4px auto 0;
  border: 1px solid #72101a;
  border-radius: 100px;
  bottom: 33px;
  right: 6px;
}

.l-hp .lineup__nav > li > a picture,
.l-hp .lineup__nav > li > a img {
  opacity: 1 !important;
  transition: 0.4s;
}

.l-hp .lineup__nav > li > a img {
  aspect-ratio: 157/95;
}

.l-hp .lineup__nav-badge {
  position: absolute;
  top: -23px;
  right: 5px;
}

.l-hp .lineup__nav-badge img {
  width: 50px;
  height: 53px;
  aspect-ratio: 50/53;
}

.l-hp .lineup__nav-details {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  color: #F9C967;
  margin-top: 16px;
}

.l-hp .lineup__nav-details-model {
  font-size: 11px;
  font-weight: 600;
  line-height: 8px;
  letter-spacing: 1.1px;
}

.l-hp .lineup__nav-details-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 1.4px;
}

.l-hp .lineup__default-pagination {
  display: flex;
  justify-content: center;
  position: static;
  margin-top: 20px;
}

.l-hp .lineup__default-pagination .swiper-slide {
  text-align: center;
}

.l-hp .lineup__default-pagination .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  margin: 0 2.5px;
  background-color: #fff;
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px #fff;
  transition: 0.3s;
}

.l-hp .lineup__default-pagination .swiper-pagination-bullet-active {
  width: 15px;
}

.l-hp .lineup__products {
  margin-top: 80px;
}

.l-hp .lineup__products > li + li {
  margin-top: 100px;
}

.l-hp .lineup__products-top {
  display: block;
  position: relative;
  margin-bottom: 40px;
}

.l-hp .lineup__products-top-swiper {
  width: auto;
  margin-left: var(--edge);
  margin-right: -4px;
  position: relative;
}

.l-hp .lineup__products-top-swiper-pagination {
  display: flex;
  justify-content: start;
  position: absolute;
  left: 20px !important;
  right: auto;
  bottom: 25px !important;
  margin: 0 auto;
  z-index: 2;
}

.l-hp .lineup__products-top-swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 5px;
  border-radius: 0;
  opacity: 0.5;
  background-color: #fff;
  margin: 0;
}

.l-hp .lineup__products-top-swiper-pagination .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 10px;
}

.l-hp .lineup__products-top-swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
}

.l-hp .lineup__products-top-swiper .swiper-slide {
  aspect-ratio: 335/339;
}

.l-hp .lineup__products-top-swiper .swiper-slide picture {
  height: 100%;
}

.l-hp .lineup__products-top-swiper .swiper-slide img {
  height: 100%;
  object-fit: cover;
  object-position: right top;
}

.l-hp .lineup__products-top-text {
  position: relative;
  z-index: 3;
}

.l-hp .lineup__products-top-text p {
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.9;
}

.l-hp .lineup__products-middle {
  margin: 0 var(--edge);
}

.l-hp .lineup__products-middle-swiper {
  position: relative;
}

.l-hp .lineup__products-middle-swiper-pagination {
  display: 14px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(43.4vw + 20px);
  z-index: 2;
}

.l-hp .lineup__products-middle .swiper-slide {
  text-align: center;
}

.l-hp .lineup__products-middle .swiper-slide img {
  aspect-ratio: 298/163;
}

.l-hp .lineup__products-middle .swiper-slide p {
  font-size: 14px;
  margin-top: 45px;
  letter-spacing: normal;
}

.l-hp .lineup__products-bottom {
  margin: 45px auto 0;
  position: relative;
}

.l-hp .lineup__products-bottom-head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}

.l-hp .lineup__products-bottom-head-title {
  font: 700 18px/1 pollen-web, source-han-serif-jp-subset, serif;
  letter-spacing: 1.6px;
  margin-right: 22px;
}

.l-hp .lineup__products-bottom-head .nav-item + .nav-item {
  margin-left: 20px;
}

.l-hp .lineup__products-bottom-head .nav-link {
  width: 35px;
  height: 35px;
  padding: 0;
  border: 1px solid #fff;
  font-size: 0;
  color: transparent;
  transition: 0.2s;
}

.l-hp .lineup__products-bottom-head .nav-link.cl-gold-1 {
  background: linear-gradient(75deg, #eedebc -31.92%, #ddc5a3 -22.81%, #c9a885 -10.67%, #b5a79b 7.55%, #f0e1d2 22.73%, #fff9ec 42.47%, #f1e5d5 50.06%, #fde5cc 60.68%, #efcfb4 77.38%, #ecc9a3 95.6%, #eedebc 119.89%);
}

.l-hp .lineup__products-bottom-head .nav-link.cl-gold-2 {
  background: linear-gradient(75deg, #eedebc -31.92%, #ddc5a3 -22.81%, #c9a885 -10.67%, #9a7a5f 7.55%, #c9a885 22.73%, #eedebc 42.47%, #ddc5a3 50.06%, #c9a885 60.68%, #9a7a5f 77.38%, #c9a885 95.6%, #eedebc 119.89%);
}

.l-hp .lineup__products-bottom-head .nav-link.active {
  box-shadow: 0 0 0 3px #fff;
}

.l-hp .lineup__products-bottom-swiper {
  margin-bottom: 40px;
}

.l-hp .lineup__products-bottom-swiper .swiper-slide {
  width: calc(100% - 40px) !important;
  border-radius: 10px;
  overflow: hidden;
}

.l-hp .lineup__products-bottom-swiper .swiper-slide img {
  aspect-ratio: 59/37;
  transition: 0.2s;
}

.l-hp .lineup__products-bottom-swiper .swiper-slide:not(.swiper-slide-visible) img {
  opacity: 0;
}

.l-hp .lineup__products-bottom-swiper .swiper-slide-prev img, .l-hp .lineup__products-bottom-swiper .swiper-slide-next img {
  opacity: 0.5 !important;
}

.l-hp .lineup__products-bottom-swiper .swiper-slide-active img {
  transition: 0.1s;
}

.l-hp .lineup__number {
  text-align: center;
  position: relative;
  margin: 58px var(--edge) 30px;
}

.l-hp .lineup__number::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: linear-gradient(90deg, #fbaa2b 0%, #f4b759 17%, #edc689 37%, #e7d1b0 56%, #e3dacb 73%, #e0dfdc 88%, #e0e1e2 100%);
  transform: rotate(-45deg) scale(0);
  z-index: 2;
  pointer-events: none;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
}

.l-hp .lineup__number::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: calc(50% - 1px);
  left: 0;
  background: linear-gradient(90deg, #fbaa2b 0%, #f4b759 17%, #edc689 37%, #e7d1b0 56%, #e3dacb 73%, #e0dfdc 88%, #e0e1e2 100%);
  transform: scaleX(0);
  transition: 0.5s;
  transition-delay: 0.5s;
  transition-timing-function: ease-in-out;
  z-index: 1;
  pointer-events: none;
}

.l-hp .lineup__number span {
  font: 700 20px/1 pollen-web, source-han-serif-jp-subset, serif;
  color: #72101a;
  position: relative;
  top: 2.5px;
  right: 1px;
  display: inline-block;
  transform: translateY(2px);
  transition: 0.5s;
  transition-delay: 0.6s;
  transition-timing-function: ease-in-out;
  opacity: 0;
  z-index: 3;
}

.l-hp .lineup__number.aos-animate::before {
  transform: rotate(-45deg) scale(1);
}

.l-hp .lineup__number.aos-animate::after {
  transform: scaleX(1);
}

.l-hp .lineup__number.aos-animate span {
  transform: translateY(0);
  opacity: 1;
}

.l-hp .lineup__title {
  margin-bottom: 24px;
}

.l-hp .lineup__title-badge {
  width: 112px;
  height: 125px;
}

.l-hp .lineup__title-badge img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.l-hp .lineup__title-text {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  color: #F9C967;
  font-family: source-han-serif-jp-subset, sans-serif;
}

.l-hp .lineup__title-text-main {
  font-size: 20px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.2px;
}

.l-hp .lineup__title-text-sub {
  font-size: 14px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0.42px;
}

.l-hp .lineup .button-next,
.l-hp .lineup .button-prev {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 27px;
  background-color: black;
  z-index: 3;
  cursor: pointer;
  background: url("https://www.owndays.com/images/specials/products/harry-potter/arrow-right.svg") no-repeat center/contain;
  font-size: 0;
  color: transparent;
}

.l-hp .lineup .button-next {
  right: 0;
  transform: translateY(calc(-50% - 15px));
}

.l-hp .lineup .button-prev {
  left: 0;
  transform: translateY(calc(-50% - 15px)) scaleX(-1);
}

.l-hp .lineup .tab-content > .tab-pane {
  display: block !important;
  height: 0;
  overflow: hidden;
  pointer-events: none;
}

.l-hp .lineup .tab-content > .active {
  height: unset;
  overflow: unset;
  pointer-events: unset;
}

.l-hp .lineup .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  line-height: 0;
}

@media (max-width: 767px) {
  .l-hp .lineup__products-middle .swiper-slide {
    width: calc(80% - 2px) !important;
  }
  .l-hp .lineup__products-middle .swiper-slide:not(.swiper-slide-active) p {
    transition: 0.2s;
    opacity: 0;
  }
  .l-hp .lineup__title {
    padding-top: 34px;
  }
  .l-hp .lineup__title-badge {
    position: absolute;
    top: -77px;
    right: 0;
  }
}

@media (min-width: 768px) {
  .l-hp .lineup {
    background: linear-gradient(180deg, #250000 3.24%, #72101a 7.19%, #72101a 88.54%, #250000 100%);
  }
}

.l-hp .merchandise {
  position: relative;
  padding-bottom: 100px;
}

.l-hp .merchandise__main {
  position: relative;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.l-hp .merchandise__main-slider-pagination {
  display: flex;
  justify-content: start;
  position: absolute;
  left: 20px !important;
  right: auto;
  bottom: 25px !important;
  gap: 10px;
  margin: 0 auto;
  z-index: 2;
}

.l-hp .merchandise__main-slider-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 5px;
  border-radius: 0;
  opacity: 0.5;
  background-color: #000;
  margin: 0;
}

.l-hp .merchandise__main-slider-pagination .swiper-pagination-bullet-active {
  background-color: #000;
  opacity: 1;
}

.l-hp .merchandise__other {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.l-hp .merchandise__buttons {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  max-width: 960px;
  margin: 100px auto 0;
}

.l-hp .merchandise__notes {
  margin-top: 30px;
}

.l-hp .merchandise__notes ul {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 500;
  line-height: 220%;
}

.l-hp .merchandise__notes ul li {
  position: relative;
  padding-left: 1em;
}

.l-hp .merchandise__notes ul li::before {
  content: '・';
  position: absolute;
  top: 0;
  left: -8px;
  font-size: 22px;
}

.l-hp .merchandise .l-hp__title--pc {
  display: none;
}

@media (max-width: 767px) {
  .l-hp .merchandise__main-slider {
    padding-right: var(--pd);
    margin-bottom: 30px;
  }
  .l-hp .merchandise__buttons .l-hp__btn-inner {
    font-size: 15px;
    letter-spacing: 1.5px;
    padding: 0 6px;
  }
}

.l-hp .shop {
  padding-bottom: 100px;
}

.l-hp .shop ul {
  margin-top: -15px;
}

.l-hp .shop ul > li {
  font: 500 13px/29px source-han-serif-jp-subset, sans-serif;
  display: flex;
}

.l-hp .shop ul > li::before {
  content: '・';
  font-size: 1em;
  margin-right: 2px;
}

.l-hp .share {
  background-color: #250101;
  position: relative;
  z-index: 2;
  padding-bottom: 160px;
}

.l-hp .share__list {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 84px;
}

.l-hp .share__list > li {
  display: inline-flex;
}

.l-hp .share__list > li a {
  font-size: 0;
  color: transparent;
}

.l-hp .share__list-link {
  display: inline-block;
  width: 56px;
  height: 56px;
  border-radius: 10rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.l-hp .share__list-link--x {
  background-image: url("https://www.owndays.com/images/specials/products/harry-potter/twitter.svg");
}

.l-hp .share__list-link--facebook {
  background-image: url("https://www.owndays.com/images/specials/products/harry-potter/Facebook.svg");
}

.l-hp .share__list-link--line {
  background-image: url("https://www.owndays.com/images/specials/products/harry-potter/line.svg");
}

.l-hp .share__copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 13px;
  font-family: sans-serif;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 auto;
  color: #F9C967;
}

.l-hp .share__copyright img {
  max-width: 117px;
}

@media (max-width: 1023px) {
  .l-hp .share__copyright {
    flex-direction: column;
    text-align: center;
  }
}

.l-hp [data-aos="main-bg"] {
  opacity: 1;
  transition-property: transform, opacity;
  transform: scale(1.3);
}

.l-hp [data-aos="main-bg"].aos-animate {
  opacity: 1;
  transform: scale(1);
}

.l-hp [data-aos="show-letter"].aos-animate .letter {
  animation: hp_fadeIn 1s ease forwards;
}

.hp-particle {
  position: fixed;
  z-index: 10002;
  width: 2px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);
  animation: hp_move .8s ease-out forwards, hp_fade 1s ease-out forwards;
}

@keyframes hp_btnJump {
  100% {
    transform: translateY(-7px);
  }
}

@keyframes hp_fadeIn {
  from {
    opacity: 0;
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes hp_move {
  to {
    transform: translateY(-100px) translateX(50px);
  }
}

@keyframes hp_fade {
  to {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .l-hp {
    background-size: 188px auto;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .l-hp {
    font-size: 16px;
    line-height: 28px;
  }
}

@media (min-width: 1024px) {
  .l-hp__btn:hover .l-hp__btn-inner span {
    animation: hp_btnJump 0.2s alternate 2;
  }
  .l-hp__btn-inner {
    font-size: 20px;
    letter-spacing: 2px;
    width: 400px;
    height: 80px;
    border-width: 4px;
  }
}

@media (min-width: 1024px) {
  .l-hp__title {
    font-size: 40px;
    left: 4px;
    line-height: 53px;
    padding-top: 49px;
    margin-bottom: 95px;
  }
  .l-hp__title--sm {
    line-height: 53px;
  }
}

@media (min-width: 768px) {
  .l-hp .main__img {
    aspect-ratio: 144/67;
  }
  .l-hp .main__detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 67px;
  }
  .l-hp .main__logo {
    max-width: 530px;
    margin-bottom: 25px;
  }
  .l-hp .main__logo img {
    aspect-ratio: 530/83;
  }
}

@media (min-width: 1024px) {
  .l-hp .main__logo {
    width: 545px;
    margin-bottom: 104px;
    max-width: none;
  }
  .l-hp .main p {
    font-size: 24px;
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .l-hp .concept__title {
    font-size: 22px;
    font-weight: 600 !important;
    letter-spacing: 1.1px;
    margin-bottom: 40px;
  }
  .l-hp .concept__title br {
    display: none;
  }
  .l-hp .concept p {
    font-size: 15px;
    text-align: center;
    line-height: 38px;
    max-width: 718px;
    margin: 0 auto 52px;
  }
  .l-hp .concept .l-hp__container-sm {
    padding-top: 295px;
    padding-bottom: 338px;
  }
  .l-hp .concept__el {
    max-width: none;
  }
  .l-hp .concept__el--1 {
    top: 150px;
    width: 343px;
    max-width: none;
  }
  .l-hp .concept__el--2 {
    top: 252px;
    width: 68px;
  }
  .l-hp .concept__el--3 {
    top: 258px;
    width: 74px;
  }
  .l-hp .concept__el--4 {
    bottom: 247px;
    width: 89px;
  }
  .l-hp .concept__el--5 {
    bottom: 238px;
    width: 92px;
  }
  .l-hp .concept__el--6 {
    bottom: 150px;
    width: 453px;
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .l-hp .concept__el--2 {
    top: 252px;
    width: 68px;
    left: 20px;
  }
  .l-hp .concept__el--3 {
    top: 258px;
    width: 74px;
    right: 20px;
  }
  .l-hp .concept__el--4 {
    bottom: 247px;
    width: 89px;
    left: 20px;
  }
  .l-hp .concept__el--5 {
    bottom: 238px;
    width: 92px;
    right: 20px;
  }
}

@media (min-width: 768px) {
  .l-hp .lineup {
    padding: 150px 0 238px;
  }
  .l-hp .lineup__price {
    font-size: 20px;
    letter-spacing: 1.4px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 55px;
  }
  .l-hp .lineup__price-strong {
    font-size: 40px;
    margin-top: 0;
  }
  .l-hp .lineup__price-span {
    font-size: 21px;
    letter-spacing: 0.21px;
  }
  .l-hp .lineup__nav {
    margin-top: 80px;
    grid-template-columns: repeat(3, 1fr);
  }
  .l-hp .lineup__products {
    margin-top: 132px;
  }
  .l-hp .lineup__products > li + li {
    margin-top: 200px;
  }
  .l-hp .lineup__products-top {
    display: flex;
    margin-bottom: 120px;
  }
  .l-hp .lineup__products-top-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-left: 20px;
    max-width: 410px;
  }
  .l-hp .lineup__products-top-swiper {
    margin-right: 0;
    width: 60%;
    height: calc(290/768*100vw);
  }
  .l-hp .lineup__products-top-swiper-pagination {
    justify-content: center;
    left: 0 !important;
    right: 0;
    bottom: 0;
  }
  .l-hp .lineup__products-top-swiper-pagination .swiper-pagination-bullet {
    width: 30px;
  }
  .l-hp .lineup__products-top-swiper .swiper-slide {
    aspect-ratio: 437/246;
  }
  .l-hp .lineup__products-middle-swiper-pagination {
    display: none;
  }
  .l-hp .lineup__products-middle .swiper p {
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1295px) {
  .l-hp .lineup__products-middle .swiper p br {
    display: none;
  }
}

@media (min-width: 768px) {
  .l-hp .lineup__products-bottom {
    margin-top: 125px;
  }
  .l-hp .lineup__products-bottom-head {
    font-size: 22px;
    letter-spacing: 2px;
    margin-top: 65px;
    position: absolute;
    bottom: 17px;
    right: calc(50% + 50px);
    margin-bottom: 0;
  }
  .l-hp .lineup__products-bottom .l-hp__btn {
    width: 330px;
    margin-left: calc(50% + 50px);
    margin-right: 0;
  }
  .l-hp .lineup__products-bottom-swiper {
    margin-bottom: 60px;
  }
  .l-hp .lineup__products-bottom .swiper-wrapper {
    max-width: 650px;
  }
  .l-hp .lineup__number {
    margin: 0 0 47px;
  }
  .l-hp .lineup__number::before {
    width: 45px;
    height: 45px;
  }
  .l-hp .lineup__title {
    display: flex;
    align-items: center;
    column-gap: 22px;
  }
  .l-hp .lineup .button-next,
  .l-hp .lineup .button-prev {
    width: 22px;
    height: 52px;
  }
}

@media (min-width: 1024px) {
  .l-hp .lineup__price-strong {
    font-size: 40px;
  }
  .l-hp .lineup__price-span {
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.21px;
  }
  .l-hp .lineup__nav > li > a::after {
    width: 43px;
    height: 43px;
  }
  .l-hp .lineup__nav > li > a:hover img {
    filter: drop-shadow(0 0 8px #fff);
  }
  .l-hp .lineup__nav-badge {
    top: -33px;
    right: 10px;
  }
  .l-hp .lineup__nav-badge img {
    width: 70px;
    height: 78px;
  }
  .l-hp .lineup__nav-details {
    margin-top: 28px;
    row-gap: 6px;
    flex-direction: column-reverse;
  }
  .l-hp .lineup__nav-details-model {
    font-size: 13px;
    line-height: 9px;
    letter-spacing: 1.3px;
  }
  .l-hp .lineup__nav-details-title {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1.8px;
  }
  .l-hp .lineup__products-top-swiper {
    width: auto;
    height: auto;
    flex: 1;
  }
  .l-hp .lineup__products-top-text p {
    font-size: 14px;
  }
  .l-hp .lineup__products-middle .swiper-slide p {
    font-size: 14px;
    font-weight: 500;
  }
  .l-hp .lineup__products-bottom-head {
    right: calc(50% + 167px);
  }
  .l-hp .lineup__products-bottom-head-title {
    font-size: 22px;
    letter-spacing: 2.2px;
    margin-right: 31px;
  }
  .l-hp .lineup__products-bottom .l-hp__btn {
    margin-left: calc(50% + 30px);
  }
  .l-hp .lineup__products-bottom-swiper .swiper-wrapper {
    max-width: none;
  }
  .l-hp .lineup__products-bottom-swiper .swiper-slide {
    max-width: 860px;
  }
  .l-hp .lineup__default-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
  .l-hp .lineup__default-pagination .swiper-pagination-bullet-active {
    width: 30px;
  }
  .l-hp .lineup__title {
    margin-bottom: 35px;
  }
  .l-hp .lineup__title-text {
    row-gap: 16px;
  }
  .l-hp .lineup__title-text-main {
    font-size: 27px;
    line-height: 20px;
    letter-spacing: 0.27px;
  }
  .l-hp .lineup__title-text-sub {
    font-size: 19px;
    line-height: 14px;
    letter-spacing: 0.57px;
  }
}

@media (min-width: 768px) {
  .l-hp .merchandise {
    background: linear-gradient(180deg, #250002 7.05%, #72101a 40.06%, #250000 83.52%);
  }
  .l-hp .merchandise__main {
    display: flex;
    align-items: center;
    margin-bottom: 160px;
  }
  .l-hp .merchandise__main-slider {
    flex-shrink: 0;
    width: calc(50% + 90px);
    margin-left: 0;
  }
  .l-hp .merchandise__main-slider-pagination {
    justify-content: center;
  }
  .l-hp .merchandise__main-text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  .l-hp .merchandise__main-text-inner {
    width: calc(50% - 90px);
    margin-left: auto;
    padding-left: 20px;
  }
  .l-hp .merchandise .l-hp__title--pc {
    display: block;
    width: calc(50% - 90px);
    margin-left: auto;
    padding-left: 20px;
    text-align: left;
    margin-bottom: 37px;
  }
  .l-hp .merchandise .l-hp__title--pc::before {
    background-position: left 20px top;
  }
  .l-hp .merchandise .l-hp__title--sp {
    display: none;
  }
}

@media (min-width: 1024px) {
  .l-hp .merchandise {
    padding-bottom: 150px;
  }
  .l-hp .merchandise .l-hp__title {
    margin-bottom: 47px;
  }
  .l-hp .merchandise__main {
    margin-bottom: 120px;
  }
  .l-hp .merchandise__main-slider-pagination .swiper-pagination-bullet {
    width: 30px;
  }
  .l-hp .merchandise__other {
    margin-bottom: 150px;
  }
  .l-hp .merchandise__other-slider-pagination {
    display: none;
  }
  .l-hp .merchandise__buttons {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 140px;
  }
  .l-hp .merchandise__buttons .l-hp__btn {
    margin: 0;
  }
  .l-hp .merchandise__notes {
    margin-top: 110px;
  }
  .l-hp .merchandise__notes ul {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .l-hp .shop {
    background-color: #250000;
  }
  .l-hp .shop__col-wrap {
    text-align: center;
  }
  .l-hp .shop__col {
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: 100px;
  }
  .l-hp .shop__col ul {
    margin-top: 0;
  }
  .l-hp .shop__col ul > li {
    font-size: 14px;
    line-height: 31px;
    font-weight: 400;
    text-align: left;
  }
  .l-hp .shop__col ul > li::before {
    margin-right: 14px;
  }
}

.l-hp .shop .l-hp__title br {
  display: none;
}

@media (min-width: 1024px) {
  .l-hp .shop {
    padding-bottom: 150px;
  }
}

@media (min-width: 768px) {
  .l-hp .share__list-link:hover {
    filter: drop-shadow(0px 0px 10px #fff);
  }
}

@media (max-width: 1023px) {
  .l-hp .share__copyright {
    flex-direction: column;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .l-hp .share {
    padding-bottom: 193px;
  }
  .l-hp .share__list {
    grid-gap: 44px;
    margin-bottom: 130px;
  }
  .l-hp .share__copyright {
    font-size: 10px;
    grid-gap: 10px;
  }
  .l-hp .share__copyright img {
    height: 40px;
  }
}

#zendesk-chat-widget {
  z-index: 3;
}
