.receive-news {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
}

.receive-news__list {
  list-style-type: none;
  margin: 16px 2px;
  padding: 0;
}

.receive-news__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.receive-news__item__checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.receive-news__item__checkbox + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  line-height: 25px;
}

.receive-news__item__checkbox + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 20px;
  height: 20px;
  border: 2px solid #546073;
  border-radius: 50%;
  background: #fff;
}

.receive-news__item__checkbox:checked + label:before {
  background: #3885ff;
  border-color: #3885ff;
}

.receive-news__item__checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 7px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.receive-news__item__name {
  margin: 0;
  font-weight: 400;
}

.receive-news__item__name a {
  color: inherit;
  text-decoration: underline;
}
