.point-lineup-freak-store__glasses__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #F7F7F7;
  margin-top: 50px;
}

.point-lineup-freak-store__glasses__container--column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
  gap: 50px !important;
  margin-top: 16px !important;
}

.point-lineup-freak-store__glasses__main {
  width: 100%;
  height: auto;
  position: relative;
}

.point-lineup-freak-store__glasses__main > .slick-dots {
  padding: 0;
  list-style-type: none;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
  width: 33px;
  height: 33px;
}

@media (min-width: 539px) {
  .point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
    width: 45px;
    height: 45px;
  }
}

@media (min-width: 604px) {
  .point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
    width: 48px;
    height: 48px;
  }
}

@media (min-width: 678px) {
  .point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
    width: 57px;
    height: 57px;
  }
}

@media (min-width: 768px) {
  .point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
    width: 33px;
    height: 33px;
  }
}

@media (min-width: 831px) {
  .point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
    width: 39px;
    height: 39px;
  }
}

@media (min-width: 1024px) {
  .point-lineup-freak-store__glasses__main > .slick-dots li, .point-lineup-freak-store__glasses__main > .slick-dots li > button {
    width: 47.4px;
    height: 47.4px;
  }
}

.point-lineup-freak-store__glasses__main > .slick-dots li:nth-child(1), .point-lineup-freak-store__glasses__main > .slick-dots li:nth-child(2), .point-lineup-freak-store__glasses__main > .slick-dots li:nth-child(3) {
  position: absolute;
}

.point-lineup-freak-store__glasses__main > .slick-dots li:nth-child(1)::before, .point-lineup-freak-store__glasses__main > .slick-dots li:nth-child(2)::before, .point-lineup-freak-store__glasses__main > .slick-dots li:nth-child(3)::before {
  color: white;
  font-size: clamp(18px, 5vw, 30px);
  font-family: din-condensed, sans-serif;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.point-lineup-freak-store__glasses__main > .slick-dots li > button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  border: 0;
  background: transparent;
  color: white;
  font-size: clamp(18px, 5vw, 30px);
  font-family: din-condensed, sans-serif;
  line-height: 1.1;
}

.point-lineup-freak-store__glasses__point {
  width: 100%;
  height: auto;
  margin-top: 25px;
  padding: 0 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.point-lineup-freak-store__glasses__point h3 {
  text-align: center;
  margin: 42px 0 0 0;
  color: #555E2B;
  font-family: "NeuzeitGroBold";
  font-size: 20px;
  font-weight: bold !important;
  letter-spacing: 0.6px;
  line-height: 1.5;
}

.point-lineup-freak-store__glasses__point img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 165px;
  border-radius: 50%;
  margin: 0 auto;
}

.point-lineup-freak-store__glasses__point p {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
  margin: 8px auto 30px auto;
  padding: 0 15px;
}

.point-lineup-freak-store__glasses__point > .slick-list {
  width: 320px;
}

@media (min-width: 1024px) {
  .point-lineup-freak-store__glasses__point > .slick-list {
    width: 300px;
  }
}

.point-lineup-freak-store__glasses__point > .slick-dots {
  padding: 0;
  list-style-type: none;
  margin: 0;
  position: absolute;
  top: 182px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 5px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  z-index: 1;
}

.point-lineup-freak-store__glasses__point > .slick-dots li, .point-lineup-freak-store__glasses__point > .slick-dots li > button {
  width: 40px;
  height: 5px;
}

.point-lineup-freak-store__glasses__point > .slick-dots li > button {
  border: 0;
  opacity: 1;
  margin: 0;
  background-color: #ddd;
  color: transparent;
}

.point-lineup-freak-store__glasses__point > .slick-dots > .slick-active > button {
  background-color: black;
}

.point-lineup-freak-store__product__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 15px;
}

.point-lineup-freak-store__product__slider {
  position: relative;
}

.point-lineup-freak-store__product__slider img {
  display: block;
  width: 100%;
  height: auto;
}

.point-lineup-freak-store__product__slider__desc {
  padding-top: 17px;
  color: black;
}

.point-lineup-freak-store__product__slider__desc h3 {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 9px;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.point-lineup-freak-store__product__slider__desc h3 span {
  font-family: "NeuzeitGroBold";
}

.point-lineup-freak-store__product__slider__desc h3 span:nth-child(1) {
  font-size: 16px;
}

.point-lineup-freak-store__product__slider__desc h3 span:nth-child(2) {
  font-size: 20px;
}

.point-lineup-freak-store__product__slider__desc p {
  font-family: "NeuzeitGroBold";
  font-size: 18px;
  line-height: 2;
  margin: 0;
}

.point-lineup-freak-store__product__slider > .slick-dots {
  list-style-type: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  gap: 8px;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 3px;
  right: 0;
  z-index: 1;
}

.point-lineup-freak-store__product__slider > .slick-dots > li, .point-lineup-freak-store__product__slider > .slick-dots > li > button {
  width: 27px;
  height: 27px;
}

.point-lineup-freak-store__product__slider > .slick-dots > li > button {
  border: 0;
}

.point-lineup-freak-store__product__slider > .slick-dots .slick-active button {
  border: 2px solid white;
  outline: 2px solid black;
}

.point-lineup-freak-store__product__slider__dot--black {
  background: #000 center / contain no-repeat;
}

.point-lineup-freak-store__product__slider__dot--brown-ce {
  background: #CEB39C center/contain no-repeat;
}

.point-lineup-freak-store__product__slider__dot--bullet-grey {
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/bullet-color-grey.webp") center/contain no-repeat;
}

.point-lineup-freak-store__product__slider__dot--brown-demi {
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/color-glass-brown-demi.webp") center/contain no-repeat;
}

.point-lineup-freak-store__product__slider__dot--gold {
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/color-glass-gold.webp") center/contain no-repeat;
}

.point-lineup-freak-store__product__slider__dot--silver {
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/color-glass-silver.webp") center/contain no-repeat;
}

.point-lineup-freak-store__product__after__title {
  font-family: din-condensed, sans-serif;
  font-weight: bold !important;
  color: #555E2B;
  font-size: 14px;
  letter-spacing: 0.42px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  margin-top: 20px;
}

.point-lineup-freak-store__product__after__title::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 16px;
  height: 16px;
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/arrow-down3layer.webp") center/contain no-repeat;
}

.point-lineup-freak-store__product__after img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.point-lineup-freak-store__product__after__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
  gap: 10px;
  padding: 10px 0;
}

.point-lineup-freak-store__product__after__desc--tag {
  padding: 11px 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: #CBCEC7;
  -webkit-clip-path: polygon(8px 0%, calc(100% - 9px) 0%, 100% 20%, 100% 80%, calc(100% - 9px) 100%, 8px 100%, 0% 80%, 0% 20%);
          clip-path: polygon(8px 0%, calc(100% - 9px) 0%, 100% 20%, 100% 80%, calc(100% - 9px) 100%, 8px 100%, 0% 80%, 0% 20%);
}

.point-lineup-freak-store__product__after__desc--tag p {
  margin: 0;
  font-weight: bold;
  color: #555E2B;
  line-height: 9.8px;
  font-family: din-condensed, sans-serif;
  font-size: 15px;
  letter-spacing: 0.48px;
}

.point-lineup-freak-store__product__after__desc--color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.point-lineup-freak-store__product__after__desc--color img {
  display: block;
  width: 25px;
  height: 25px;
}

.point-lineup-freak-store__product__after__desc--color span {
  font-family: "NeuzeitGroBold";
  font-size: 17px;
}

@media (min-width: 768px) {
  .point-lineup-freak-store__glasses__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 50px;
    margin-top: 84px;
  }
  .point-lineup-freak-store__glasses__container--column-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
    gap: 50px !important;
    margin-top: 84px !important;
    padding-bottom: 120px;
  }
  .point-lineup-freak-store__glasses__main {
    width: 53.2%;
    height: auto;
  }
  .point-lineup-freak-store__glasses__point {
    margin-top: 0;
  }
  .point-lineup-freak-store__glasses__point img {
    display: block;
    border-radius: 50%;
  }
  .point-lineup-freak-store__product__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 25px;
    margin-top: 40px;
  }
  .point-lineup-freak-store__product__container--center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 120px;
  }
  .point-lineup-freak-store__product__container--center:last-child {
    margin-bottom: 0;
  }
  .point-lineup-freak-store__product__slider {
    width: 50%;
  }
  .point-lineup-freak-store__product__slider__desc {
    padding-top: 20px;
  }
  .point-lineup-freak-store__product__after {
    position: relative;
    width: 40%;
    max-width: 320px;
    margin-left: auto;
    margin-top: 36px;
  }
  .point-lineup-freak-store__product__after__title {
    position: absolute;
    top: 32%;
    left: -17%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .point-lineup-freak-store__product__after__title::before {
    width: 30px;
    height: 30px;
    rotate: -90deg;
    top: -50px;
    left: 0;
  }
}

@media (min-width: 768px) and (min-width: 896px) {
  .point-lineup-freak-store__product__after__title {
    left: -25%;
  }
}

@media (min-width: 768px) and (min-width: 1002px) {
  .point-lineup-freak-store__product__after__title {
    left: -29.15%;
  }
}

@media (min-width: 768px) {
  .point-lineup-freak-store__product__after img {
    max-width: 100%;
  }
  .point-lineup-freak-store__product__after__desc {
    padding: 0;
  }
  .point-lineup-freak-store__product__after__desc--tag p {
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  .point-lineup-freak-store__product__container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }
  .point-lineup-freak-store__product__slider__desc h3 span:nth-child(1) {
    font-size: 20px;
  }
  .point-lineup-freak-store__product__slider__desc h3 span:nth-child(2) {
    font-size: 26px;
  }
  .point-lineup-freak-store__product__slider__desc p {
    font-size: 20px;
  }
  .point-lineup-freak-store__product__slider > .slick-dots > li, .point-lineup-freak-store__product__slider > .slick-dots > li > button {
    width: 30px;
    height: 30px;
  }
  .point-lineup-freak-store__product__after img {
    max-width: 100%;
  }
  .point-lineup-freak-store__product__after__desc {
    margin-top: 15px;
  }
  .point-lineup-freak-store__product__after__desc--tag {
    padding: 10.1px 20px;
  }
}

.dot-lineup-freak-store__FK2001B-4S_C4 > .slick-dots li:nth-child(1) {
  top: -1px;
  left: 33.8%;
}

.dot-lineup-freak-store__FK2001B-4S_C4 > .slick-dots li:nth-child(2) {
  bottom: 0px;
  right: 19.1%;
}

.dot-lineup-freak-store__FK2001B-4S_C4 > .slick-dots li:nth-child(3) {
  top: 18%;
  right: 11.4%;
}

.dot-lineup-freak-store__FK2001B-4S_C5 > .slick-dots li:nth-child(1) {
  top: -1px;
  left: 33.8%;
}

.dot-lineup-freak-store__FK2001B-4S_C5 > .slick-dots li:nth-child(2) {
  bottom: 0px;
  right: 19.5%;
}

.dot-lineup-freak-store__FK2001B-4S_C5 > .slick-dots li:nth-child(3) {
  top: 18%;
  right: 10%;
}

.dot-lineup-freak-store__FK2001B-4S_C6 > .slick-dots li:nth-child(1) {
  top: -1px;
  left: 33.8%;
}

.dot-lineup-freak-store__FK2001B-4S_C6 > .slick-dots li:nth-child(2) {
  bottom: -1px;
  right: 19.3%;
}

.dot-lineup-freak-store__FK2001B-4S_C6 > .slick-dots li:nth-child(3) {
  top: 18%;
  right: 11%;
}

.dot-lineup-freak-store__FK2002N-4S_C1 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK2002N-4S_C2 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK2002N-4S_C3 > .slick-dots li:nth-child(1) {
  top: -1px;
  left: 42.9%;
}

.dot-lineup-freak-store__FK2002N-4S_C1 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK2002N-4S_C2 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK2002N-4S_C3 > .slick-dots li:nth-child(2) {
  bottom: -1px;
  right: 31.3%;
}

.dot-lineup-freak-store__FK2002N-4S_C1 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK2002N-4S_C2 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK2002N-4S_C3 > .slick-dots li:nth-child(3) {
  top: 9%;
  right: 2.5%;
}

.dot-lineup-freak-store__FK1001B-4S_C1 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK1002B-4S_C1 > .slick-dots li:nth-child(1) {
  top: -1px;
  left: 38.8%;
}

.dot-lineup-freak-store__FK1001B-4S_C1 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK1002B-4S_C1 > .slick-dots li:nth-child(2) {
  bottom: -1px;
  right: 19.1%;
}

.dot-lineup-freak-store__FK1001B-4S_C1 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK1002B-4S_C1 > .slick-dots li:nth-child(3) {
  top: 20%;
  right: 7.2%;
}

.dot-lineup-freak-store__FK1001B-4S_C2 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK1001B-4S_C3 > .slick-dots li:nth-child(1) {
  top: -1px;
  left: 38.5%;
}

.dot-lineup-freak-store__FK1001B-4S_C2 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK1001B-4S_C3 > .slick-dots li:nth-child(2) {
  bottom: -1px;
  right: 19.7%;
}

.dot-lineup-freak-store__FK1001B-4S_C2 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK1001B-4S_C3 > .slick-dots li:nth-child(3) {
  top: 20%;
  right: 7.7%;
}

.dot-lineup-freak-store__FK1002B-4S_C2 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK1002B-4S_C3 > .slick-dots li:nth-child(1) {
  top: 4px;
  left: 39.9%;
}

.dot-lineup-freak-store__FK1002B-4S_C2 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK1002B-4S_C3 > .slick-dots li:nth-child(2) {
  bottom: 3px;
  right: 20.1%;
}

.dot-lineup-freak-store__FK1002B-4S_C2 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK1002B-4S_C3 > .slick-dots li:nth-child(3) {
  top: 22%;
  right: 9.9%;
}

@media (min-width: 1024px) {
  .dot-lineup-freak-store__FK2001B-4S_C4 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 34.3%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C4 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 19.4%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C4 > .slick-dots li:nth-child(3) {
    top: 19%;
    right: 11.9%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C5 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 34.3%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C5 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 19.7%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C5 > .slick-dots li:nth-child(3) {
    top: 19.1%;
    right: 10.3%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C6 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 34.3%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C6 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 19.7%;
  }
  .dot-lineup-freak-store__FK2001B-4S_C6 > .slick-dots li:nth-child(3) {
    top: 18.3%;
    right: 11.3%;
  }
  .dot-lineup-freak-store__FK2002N-4S_C1 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK2002N-4S_C2 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK2002N-4S_C3 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 43.5%;
  }
  .dot-lineup-freak-store__FK2002N-4S_C1 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK2002N-4S_C2 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK2002N-4S_C3 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 31.7%;
  }
  .dot-lineup-freak-store__FK2002N-4S_C1 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK2002N-4S_C2 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK2002N-4S_C3 > .slick-dots li:nth-child(3) {
    top: 10.3%;
    right: 2.9%;
  }
  .dot-lineup-freak-store__FK1001B-4S_C1 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 39.5%;
  }
  .dot-lineup-freak-store__FK1001B-4S_C1 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 19.4%;
  }
  .dot-lineup-freak-store__FK1001B-4S_C1 > .slick-dots li:nth-child(3) {
    top: 21.2%;
    right: 7.6%;
  }
  .dot-lineup-freak-store__FK1002B-4S_C1 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 39.3%;
  }
  .dot-lineup-freak-store__FK1002B-4S_C1 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 19.4%;
  }
  .dot-lineup-freak-store__FK1002B-4S_C1 > .slick-dots li:nth-child(3) {
    top: 21.2%;
    right: 9.2%;
  }
  .dot-lineup-freak-store__FK1001B-4S_C2 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK1001B-4S_C3 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 38.9%;
  }
  .dot-lineup-freak-store__FK1001B-4S_C2 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK1001B-4S_C3 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 20%;
  }
  .dot-lineup-freak-store__FK1001B-4S_C2 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK1001B-4S_C3 > .slick-dots li:nth-child(3) {
    top: 21.2%;
    right: 8.1%;
  }
  .dot-lineup-freak-store__FK1002B-4S_C2 > .slick-dots li:nth-child(1), .dot-lineup-freak-store__FK1002B-4S_C3 > .slick-dots li:nth-child(1) {
    top: 1px;
    left: 39.3%;
  }
  .dot-lineup-freak-store__FK1002B-4S_C2 > .slick-dots li:nth-child(2), .dot-lineup-freak-store__FK1002B-4S_C3 > .slick-dots li:nth-child(2) {
    bottom: 1px;
    right: 19.3%;
  }
  .dot-lineup-freak-store__FK1002B-4S_C2 > .slick-dots li:nth-child(3), .dot-lineup-freak-store__FK1002B-4S_C3 > .slick-dots li:nth-child(3) {
    top: 21.2%;
    right: 9.1%;
  }
}
