$breakpoint-mintabletview: 768px;
$breakpoint-ninetabletview: 992px;
$breakpoint-fourteen: 1400px;
$breakpoint-maxscreen: 1152px;
$breakpoint-maxtabletview: 1024px;
.custom-width {
  min-width: 200px;
  max-width: 220px;
  @media (max-width: 1380px) {
    min-width: 100px !important;
    max-width: 183px !important;
  }
}
.nav-subcategory-level {
  padding: 0 30px;
  width: 100%;
  margin-top: 0;
  justify-content: space-around;
  display: flex;
  height: 100%;
  @media (max-width: 1050px) {
    padding: 0 20px;
  }

  .nav-level-3 {
    margin-right: 50px;
    min-width: 200px;
    max-width: 230px;
    &.flex-img-grow {
      max-width: none;
      min-width: none;
      margin-right: 0;
    }
    .level-5 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 40px;
      background-color: #fbfbfb;
      height: 280px;
      width: 240px;
      border-radius: 20px;
    }
    .two {
      .image {
        flex-basis: 50%;
      }
    }
    .three {
      .image {
        flex-basis: 33%;
      }
    }
    .four {
      .image {
        flex-basis: 25%;
      }
    }
    .one {
      .image {
        flex-basis: 100%;
      }
    }

    @media (min-width: $breakpoint-mintabletview) and (max-width: $breakpoint-ninetabletview) {
      min-width: 100px;
      max-width: 150px;
      margin-right: 10px;
    }
    
  }

  .nav-level-4 {
    font-size: 14px;

    @media (min-width: $breakpoint-mintabletview) and (max-width: $breakpoint-ninetabletview) {
      font-size: 13px;
    }
  }

  .nav-level-4-item {
    display: flex;
    color: #000000B2;
    align-items: center;
    margin-bottom: 20px;

    span {
      margin-right: 5px;
      display: block;
      color: #000000B2;
      height: auto;
      font-size: 14px;
      line-height: 20px;
      &:hover {
        color: #000000;
      }
    }

    &:hover {
      color: #000000;
      text-decoration: none;

      .fa-angle-right {
        display: block !important;
      }
    }
  }
}
