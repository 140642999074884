.od-category-menu {
  position: relative;
}

.od-category-menu > a {
  display: inline-block;
  text-decoration: none;
}

.od-category-menu > a:hover {
  text-decoration: none;
}

.od-category-menu .category .od-category-menu_list {
  min-height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #000042;
  font-size: 13px;
}

.od-category-menu .category .od-category-menu_list .od-category-menu_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 135px;
}

.od-category-menu .category .od-category-menu_list .od-category-menu_items span {
  display: block;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
}

.od-category-menu .category:hover {
  background: #FAFAFA;
}

.od-category-menu .category:hover .select-submenu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
}

.od-category-menu .category:hover .select-submenu .nav-subcategory-level {
  margin-top: 0;
}

.od-category-menu_data {
  min-height: 70px;
  padding: 10px 25px 10px 20px;
}

.od-category-menu_data:not(:last-child) {
  margin-bottom: 20px;
}

.od-category-menu_data a:hover {
  text-decoration: none;
}
