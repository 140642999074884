.app-footer {
  background-color: #ffffff;
  letter-spacing: 1px;
  font-family: 'Founders Grotesk', sans-serif;
}

.app-footer-top {
  padding: 60px 0;
  background-color: #fafafa;

  &__layout {
    display: flex;
    justify-content: space-between;
  }
}

.app-footer-nav {
  &__block {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    font-weight: 400;
    font-family: 'Founders Grotesk', sans-serif;
    text-transform: uppercase;
  }

  &__link {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 12px;
    color: #999;
    cursor: pointer;
    transition: color 0.3s ease;
    font-weight: 400;

    &:hover {
      color: #666;
      text-decoration: none;
    }

    &--new-users {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.app-footer-contact {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;

  &__title {
    margin-bottom: 18px;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    color: #000000;
  }

  &__call-block {
    display: flex;
    align-items: flex-end;
    gap: 37px;
  }

  &__phone {
    margin-bottom: 0;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
  }

  &__hours {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
  }
}

.app-footer-info {
  padding: 40px 0;

  &__copyright {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    color: #000000;
    opacity: 0.7;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  &__link {
    text-decoration: underline;
  }

  &__link,
  &__divider {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
    color: #000000;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      color: #000000;
      opacity: 0.3;
    }
  }

  &__country {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &-img {
      width: 25px;
    }

    &__toggle {
      width: 8px;
      height: 5px;
    }

    span {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0em;
      color: #000000;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        color: #000000;
        opacity: 0.3;
      }
    }
  }
}
