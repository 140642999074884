.od-membership-status {
  width: 380px;
  padding: 40px;
  min-height: 461px;

  &__title {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  &__points {
    margin: 23px 0 76px 0;
    font-size: 24px;
    line-height: 40px;
    width: 100%;
    text-align: center;
  }

  &__btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    padding: 20px;
    min-height: auto;

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__points {
      margin: 15px 0 40px 0;
      font-size: 18px;
      line-height: 32px;
    }
  }
}

.block-color-bronze {
  background-color: #FFF5EA;
}

.block-color-silver {
  background-color: #FBFBFB;
}

.block-color-gold {
  background-color: #FFFAE1;
}
