@import url("https://use.typekit.net/faz0ttr.css");
@font-face {
  font-family: "Miller-Banner";
  src: url("https://static.lenskart.com/owndays/fonts/Miller-Banner/Miller-Banner.woff2") format("woff2"), url("https://static.lenskart.com/owndays/fonts/Miller-Banner/Miller-Banner.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tahoma";
  src: url("https://static.lenskart.com/owndays/fonts/Tahoma/Tahoma-Regular.woff2") format("woff2"), url("https://static.lenskart.com/owndays/fonts/Tahoma/Tahoma-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.base-anniversary-10th-collection {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  padding-bottom: 143px;
  position: relative;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  overflow: hidden;
}
.base-anniversary-10th-collection::before {
  content: "";
  position: absolute;
  top: 170vw;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  background-color: #f6dcc3;
}
.base-anniversary-10th-collection.loaded {
  opacity: 1;
}
.base-anniversary-10th-collection.loaded.bg::before {
  background: url("https://static.lenskart.com/owndays/img/anniversary-10th-collection/bg-sp.webp") top center/100% auto;
}
@media (min-width: 768px) {
  .base-anniversary-10th-collection {
    padding-bottom: 169px;
  }
  .base-anniversary-10th-collection::before {
    top: 840px;
  }
  .base-anniversary-10th-collection.loaded.bg::before {
    background-image: url("https://static.lenskart.com/owndays/img/anniversary-10th-collection/bg-pc.webp");
  }
}
.base-anniversary-10th-collection__container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1120px;
}
.base-anniversary-10th-collection__heading {
  font-family: "Miller-Banner";
  font-weight: 400 !important;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  margin-bottom: 32px;
  letter-spacing: 0.96px;
}
.base-anniversary-10th-collection__heading span {
  display: inline-block;
}
@media (min-width: 768px) {
  .base-anniversary-10th-collection__heading {
    font-size: 50px;
    text-align: center;
    margin-bottom: 80px;
    letter-spacing: 1.68px;
  }
}
.base-anniversary-10th-collection__heading-lg {
  font-weight: 500 !important;
  font-size: 50px;
  margin-bottom: 40px;
  line-height: 46px;
  color: #815624;
  opacity: 0.5;
}
@media (max-width: 767px) {
  .base-anniversary-10th-collection__heading-lg {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .base-anniversary-10th-collection__heading-lg {
    font-size: 56px;
    letter-spacing: 1.68px;
  }
}
.base-anniversary-10th-collection__btn {
  min-height: 70px;
  border-radius: 70px;
  background-color: #815624;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 20px;
  color: white !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.7px;
}
.base-anniversary-10th-collection__btn::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  border: 1px solid white;
  border-radius: 70px;
  pointer-events: none;
}
.base-anniversary-10th-collection__btn--black {
  background-color: black;
}
.base-anniversary-10th-collection__btn--small-sp {
  font-size: 13px;
  letter-spacing: 0.65px;
}
@media (min-width: 768px) {
  .base-anniversary-10th-collection__btn {
    max-width: 430px;
    min-height: 80px;
    border-radius: 80px;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 1px;
  }
  .base-anniversary-10th-collection__btn::before {
    top: 5.7px;
    bottom: 5.7px;
    left: 6.9px;
    right: 6.9px;
  }
  .base-anniversary-10th-collection__btn--small-sp {
    font-size: 17px;
    letter-spacing: 0.85px;
  }
}
@media (min-width: 1024px) {
  .base-anniversary-10th-collection__btn:hover {
    background-color: black;
  }
  .base-anniversary-10th-collection__btn--black:hover {
    background-color: #815624;
  }
}
.base-anniversary-10th-collection__btn-gp {
  display: grid;
  gap: 21px 0;
}
.base-anniversary-10th-collection__btn-gp .base-anniversary-10th-collection__btn {
  width: 100%;
}
@media (max-width: 767px) {
  .base-anniversary-10th-collection__btn-gp {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .base-anniversary-10th-collection__btn-gp .base-anniversary-10th-collection__btn {
    min-height: 97.65px;
  }
}
@media (min-width: 1024px) {
  .base-anniversary-10th-collection__btn-gp {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  .base-anniversary-10th-collection__btn-gp .base-anniversary-10th-collection__btn {
    max-width: none;
  }
}
.base-anniversary-10th-collection__frame {
  position: relative;
  width: calc(100% + 80px);
  height: 100%;
  margin: 64px -40px 0 -40px;
  border-radius: inherit;
  overflow: hidden;
}
.base-anniversary-10th-collection__frame img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.base-anniversary-10th-collection__frame.aos-animate img {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@media (min-width: 768px) {
  .base-anniversary-10th-collection__frame {
    width: 100%;
    margin: 90px 0 0 0;
  }
}
