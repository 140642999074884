.membership-discount {

  &__container {
    display: flex;
    padding: 15px;
    grid-column: span 2;
    flex-direction: column;
    gap: 12px;
    background-color: #E9EEF6;
    margin: 0 -16px;
  }
  
  &__title {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    cursor: pointer;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;

    @media (min-width: 767px) {
      font-size: 16px;
    }
  }
}
