@charset "UTF-8";
.service_l {
  margin: 0 auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}
@media (max-width: 1023px) {
  .service_l {
    margin: 0 auto 60px;
  }
}
.service_l__section {
  position: relative;
  overflow: hidden;
  display: block;
}
.service_l__container {
  width: 90%;
  max-width: 1296px;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .service_l__container {
    width: 100%;
    padding: 0 5%;
  }
}
.service_l__container-retail {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .service_l__container-retail {
    width: 100%;
    padding: 0;
  }
}
.service_l__container-warranty {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1296px;
}
@media (max-width: 767px) {
  .service_l__container-warranty {
    width: 100%;
    padding: 0 5%;
  }
}
.l-account__price {
  width: 100%;
  background-color: #f4f4f4;
}
.l-account__price-container {
  padding: 70px 7%;
  text-align: center;
}
@media (max-width: 767px) {
  .l-account__price-container {
    padding: 50px 7%;
  }
}
.l-account__price-container-title,
.l-account__price-container-main span,
.l-account__price-container-main strong {
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}
.l-account__price-container-title {
  color: #000;
  margin-bottom: 40px;
  font-size: 26px;
  line-height: 2;
}
@media (max-width: 767px) {
  .l-account__price-container-title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
.l-account__price-container-main {
  background-color: #fff;
  max-width: 570px;
  border-radius: 10px;
  margin: 0 auto 30px;
  padding: 26px 0;
  line-height: 2;
  text-align: center;
}
.l-account__price-container-main span,
.l-account__price-container-main strong {
  font-size: 20px;
  color: #000;
}
.l-account__price-container-main span {
  font-weight: 400;
}
.l-account__price-container-main strong {
  font-weight: 600;
  margin-right: 5px;
}
.l-account__price-container-text, .l-account__price-container-text-description {
  color: #000;
  font-size: 18px;
  line-height: 2;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media (max-width: 767px) {
  .l-account__price-container-text, .l-account__price-container-text-description {
    font-size: 14px;
  }
}
.l-account__price-container-text-description, .l-account__price-container-text-description-description {
  font-size: 15px;
}
@media (max-width: 767px) {
  .l-account__price-container-text-description, .l-account__price-container-text-description-description {
    text-align: left;
  }
}
.l-account__price-container-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 8px 0 30px;
}
.l-account__price-container-table__c-price {
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #c7002e;
  font-size: 30px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.l-account__price-container-table .u-svg__icon {
  display: inline-block;
  vertical-align: middle;
}
.l-account__price-container-table .u-svg__icon-frame {
  width: 50px;
}
.l-account__price-container-table .u-svg__icon-lens {
  width: 30px;
}
.l-account__price-container-table .u-mark {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  margin: 0 9px;
  position: relative;
}
.l-account__price-container-table .u-mark:before, .l-account__price-container-table .u-mark:after {
  content: '';
  position: absolute;
  background: #000;
}
.l-account__price-container-table .u-mark__plus:before {
  width: 15px;
  height: 1px;
  top: 50%;
  left: 0;
}
.l-account__price-container-table .u-mark__plus:after {
  height: 15px;
  width: 1px;
  top: 0;
  left: 50%;
}
.l-account__price-container-table .u-mark__equal:before, .l-account__price-container-table .u-mark__equal:after {
  width: 15px;
  height: 1px;
  left: 0;
}
.l-account__price-container-table .u-mark__equal:before {
  top: 50%;
  margin-top: -3px;
}
.l-account__price-container-table .u-mark__equal:after {
  top: 50%;
  margin-top: 3px;
}
.l-account__inner {
  width: 90%;
  max-width: 1296px;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 80px;
}
@media (max-width: 767px) {
  .l-account__inner {
    margin-bottom: 50px;
    padding-top: 40px;
  }
}
.l-account__inner--content {
  width: 80%;
  max-width: 1152px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .l-account__inner--content {
    width: 100%;
    padding: 0 5%;
  }
}
.l-account__inner--title, .l-account__inner--subtitle {
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  line-height: 1.6;
}
.l-account__inner--title {
  margin-bottom: 40px;
  font-size: 26px;
  text-align: center;
}
@media (max-width: 767px) {
  .l-account__inner--title {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.l-account__inner--terms-conditions {
  width: 100%;
}
.l-account__inner--subtitle {
  font-size: 26px;
  font-weight: 700;
  margin: 70px 0 35px;
  letter-spacing: 2px;
  line-height: 1.6;
  position: relative;
}
.l-account__inner--subtitle::before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  height: 100%;
  left: -20px;
  top: -2px;
  border-left: 6px solid #000;
}
@media (max-width: 767px) {
  .l-account__inner--subtitle {
    font-size: 18px;
    margin-top: 35px;
    margin-bottom: 25px;
  }
  .l-account__inner--subtitle::before {
    width: 5px;
    top: -2px;
    left: -21px;
    border-left: 5px solid #000;
  }
}
.l-account__inner .l-account__inner--image {
  width: calc(100% + 80px);
  height: auto;
  display: block;
  max-width: none !important;
  margin: 0 -40px;
  aspect-ratio: 5 / 2;
}
@media (min-width: 768px) {
  .l-account__inner .l-account__inner--image {
    width: 100%;
    margin: 0 auto;
  }
}
.l-account__inner--list {
  padding-left: 0;
  margin-left: 0;
  width: 100%;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  list-style: none;
}
.l-account__inner--list-item {
  font-size: 15px;
  color: #000;
  position: relative;
  padding-left: 20px;
  letter-spacing: 1px;
  line-height: 2;
}
.l-account__inner--list-item::before {
  content: '・';
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 12px;
}
