@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&family=BIZ+UDPGothic:wght@400;700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ultra&display=swap");
@font-face {
  font-family: "SFCompactDisplay";
  src: url("https://static.lenskart.com/owndays/fonts/SFCompactDisplay/SFCompactDisplay-Regular.woff2") format("woff2"), url("https://static.lenskart.com/owndays/fonts/SFCompactDisplay/SFCompactDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SFCompactDisplay";
  src: url("https://static.lenskart.com/owndays/fonts/SFCompactDisplay/SFCompactDisplay-Bold.woff2") format("woff2"), url("https://static.lenskart.com/owndays/fonts/SFCompactDisplay/SFCompactDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
.base-huawei-2-titanium {
  overflow: hidden;
}
.base-huawei-2-titanium__container {
  padding-left: 23px;
  padding-right: 23px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1220px;
}
@media (min-width: 1240px) {
  .base-huawei-2-titanium__container {
    padding: 0;
  }
}
.base-huawei-2-titanium__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  height: 45px;
  border-radius: 10px;
  color: white;
  background-color: #292929;
  font-family: "BIZ UDPGothic", serif;
  font-weight: 700 !important;
  letter-spacing: 0.14px;
  line-height: 1;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .base-huawei-2-titanium__btn {
    line-height: 28px;
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  .base-huawei-2-titanium__btn {
    cursor: pointer;
  }
}
.base-huawei-2-titanium__btn:hover, .base-huawei-2-titanium__btn:active, .base-huawei-2-titanium__btn:visited, .base-huawei-2-titanium__btn:focus {
  text-decoration: none;
}
.base-huawei-2-titanium__btn--sm {
  background: white;
  color: #535353;
  border: 1px solid #535353;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  max-width: 280px;
  padding: 0 72px;
  margin-inline: auto;
  font-size: 12px;
  line-height: 1;
  font-weight: 700 !important;
  letter-spacing: 0.12px;
}
.base-huawei-2-titanium__btn--sm:hover, .base-huawei-2-titanium__btn--sm:active, .base-huawei-2-titanium__btn--sm:visited, .base-huawei-2-titanium__btn--sm:focus {
  color: #535353;
}
@media (min-width: 1024px) {
  .base-huawei-2-titanium__btn--sm:hover {
    color: white;
  }
  .base-huawei-2-titanium__btn--sm:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  .base-huawei-2-titanium__btn--sm::after {
    background: #535353;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100% !important;
    height: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
    transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
    z-index: -1;
    will-change: transform;
  }
}
.base-huawei-2-titanium__btn--gold {
  background-color: #B47E00;
  color: white;
}
.base-huawei-2-titanium__btn--gold:hover, .base-huawei-2-titanium__btn--gold:active, .base-huawei-2-titanium__btn--gold:visited, .base-huawei-2-titanium__btn--gold:focus {
  color: white;
}
@media (min-width: 1024px) {
  .base-huawei-2-titanium__btn--gold::after {
    background-color: #875D07;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 140px;
    -webkit-transform: skewY(-10deg) scaleY(0);
            transform: skewY(-10deg) scaleY(0);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
    transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
    z-index: -1;
    will-change: transform;
  }
  .base-huawei-2-titanium__btn--gold:hover {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: #875D07;
  }
  .base-huawei-2-titanium__btn--gold:hover::after {
    -webkit-transform: skewY(-10deg) scale(1);
            transform: skewY(-10deg) scale(1);
  }
}
@media (min-width: 768px) {
  .base-huawei-2-titanium__btn--sm {
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .base-huawei-2-titanium__btn {
    font-size: 14px;
    line-height: 30.8px;
  }
  .base-huawei-2-titanium__btn--lg {
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: 0.28px;
    height: 70px;
  }
  .base-huawei-2-titanium__btn--sm {
    max-width: 260px;
    height: 54px;
    font-size: 14px;
    letter-spacing: 0.14px;
  }
}
