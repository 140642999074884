.base-gift-cards-products__container {
  width: 100%;
  max-width: 1152px;
  height: auto;
  margin: 0 auto;
  padding: 0 5%;
}

@media (min-width: 768px) {
  .base-gift-cards-products__container {
    width: 80%;
    padding: 0;
  }
}

.base-gift-cards-products__main {
  grid-area: left;
}

.base-gift-cards-products__link {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #0094c8;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 1px;
}

.base-gift-cards-products__link:hover, .base-gift-cards-products__link:active, .base-gift-cards-products__link:visited, .base-gift-cards-products__link:focus {
  text-decoration: none;
}

.base-gift-cards-products__link small {
  font-family: inherit;
  font-size: 60%;
  font-weight: 400 !important;
}

@media (min-width: 1024px) {
  .base-gift-cards-products__layout {
    display: grid;
    grid-template-areas: "left right" "detail right";
    width: 80%;
    height: auto;
    margin: 0 auto;
    max-width: 1152px;
  }
  .base-gift-cards-products__main {
    max-width: 650px;
  }
  .base-gift-cards-products__main .base-gift-cards-products__container {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1295px) {
  .base-gift-cards-products__main {
    padding-right: 3%;
    max-width: 570px;
  }
}
