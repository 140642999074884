.header-air__main {
  position: relative;
}

.header-air__main .base-air__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}

.header-air__main__bg img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 425 / 654;
}

.header-air__main__text {
  position: absolute;
  inset: 0;
  padding-block: 50px 70px;
  font-family: "Founders Grotesk", sans-serif;
}

.header-air__main__text h1, .header-air__main__text h2 {
  margin: 0;
}

.header-air__main__text h1 {
  font: 0 / 0 a;
}

.header-air__main__text h1 img {
  display: block;
  max-width: 170px;
  margin: 0 auto;
}

.header-air__main__text h2 {
  color: white;
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: 0.72px;
}

.header-air__main::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: 1px;
  height: 60px;
  background-color: #d9d9d9;
  z-index: 2;
}

@media (min-width: 768px) {
  .header-air__main__bg img {
    aspect-ratio: 2 / 1;
  }
  .header-air__main__text h2 {
    line-height: 1.2;
  }
}

@media (min-width: 1024px) {
  .header-air__main__bg img {
    min-height: 720px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .header-air__main__text {
    padding-block: 76px 120px;
  }
  .header-air__main__text img {
    max-width: 192px;
  }
  .header-air__main__text h2 {
    font-size: 40px;
    letter-spacing: 0.8px;
  }
  .header-air__main::after {
    height: 80px;
  }
}

.header-air__about {
  position: relative;
}

.header-air__about__bg {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header-air__about__bg::before {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(352.03% 121.7% at 50% 7.5%, rgba(0, 0, 0, .4) 0, rgba(217, 217, 217, .3) 100%);
}

.header-air__about__bg video {
  display: inline-block;
  width: 100%;
  height: 524px;
  -o-object-fit: cover;
     object-fit: cover;
}

.header-air__about__text {
  position: absolute;
  inset: auto 0 32px 0;
  color: white;
}

.header-air__about__text p {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  margin: 0;
}

.header-air__about__play {
  position: absolute;
  inset: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
  font: 0 / 0 a;
  background: url("https://static.lenskart.com/media/owndays/img/air/icon-play.svg") no-repeat 50%/80px auto;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

@media (min-width: 1024px) {
  .header-air__about__bg video {
    height: 580px;
  }
  .header-air__about__text {
    inset: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 20px;
  }
  .header-air__about__text p {
    font-size: 16px;
    letter-spacing: 0.48px;
    line-height: 32px;
    max-width: calc(50% - 147px);
  }
}

.header-air__navs {
  position: relative;
  padding-block: 24px;
}

.header-air__navs ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
}

.header-air__navs ul a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
  letter-spacing: 0.96px;
  line-height: normal;
  color: black;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  background-color: #f7f6f2;
  height: 52px;
  padding-inline: 20px;
  border-radius: 5px;
  z-index: 1;
}

.header-air__navs ul a::after {
  content: "";
  width: 28px;
  height: 28px;
  border-radius: 160px;
  border-radius: 10rem;
  border: 1px solid black;
  background: black url("https://static.lenskart.com/media/owndays/img/air/icon-arrow-down.svg") no-repeat 50%/8px auto;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

@media (min-width: 768px) {
  .header-air__navs ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    background-color: #f7f6f2;
    height: 76px;
    border-radius: 10px;
  }
  .header-air__navs ul li {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 100%;
            flex: 0 1 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header-air__navs ul li:not(:last-child):before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 48px;
    background-color: rgba(0, 0, 0, .2);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .header-air__navs ul li a {
    padding: 20px;
    gap: 28px;
  }
  .header-air__navs ul li a:hover {
    color: #999;
    text-decoration: none;
  }
  .header-air__navs ul li a:hover::after {
    background-color: white;
    background-image: url("https://static.lenskart.com/media/owndays/img/air/icon-arrow-down--black.svg");
  }
}

@media (min-width: 1024px) {
  .header-air__navs {
    padding-block: 60px 100px;
  }
  .header-air__navs ul a {
    font-size: 16px;
    letter-spacing: .96px;
  }
}
