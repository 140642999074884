.auth-panel {
    position: relative;

    &:hover {
        .auth-panel__dropdown-menu {
            display: block;
        }

        .auth-panel__dropdown-toggler {
            transform: rotate(180deg)
        }
    }

    &__dropdown-menu {
        display: none;
        padding: 10px;
        margin: 0;
        position: absolute;
        z-index: 21;

        &--top {
            bottom: 100%;
        }

        &--bottom {
            top: 100%;
        }

        &--center {
            left: 50%;
            transform: translateX(-50%)
        }

        &--left {
            right: -30px;
        }

        &--right {
            left: -30px;
        }
    }

    &__dropdown-content {
        width: 170px;
        background: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    &__dropdown-list {
        display: block;
        padding: 10px;
        color: #000000;
        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
            color: #000000;
            background-color: #fafafa; 
            opacity: 30%;
        }
    }
    
    &__dropdown-toggler {
        margin-left: 3px;
        transition: transform 0.3s ease-in-out;
    }
}
