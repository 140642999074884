.header-owndays-pc__container__banner {
  background-color: #f9f9f9;
  padding: 100px 0 255px;
  position: relative;
}

.header-owndays-pc__container__nav {
  text-align: center;
  padding: 100px 0;
}

.header-owndays-pc__logo {
  margin: 0 0 50px 0;
}

.header-owndays-pc__logo img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 617px;
}

.header-owndays-pc__heading {
  font-family: "NeuzeitGroReg";
  font-size: 18px;
  font-weight: normal !important;
  letter-spacing: 0.1em;
  line-height: 1.6;
  margin: 0 0 44px 0;
}

.header-owndays-pc__tag img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 140px;
}

.header-owndays-pc__product {
  position: absolute;
  left: calc(50% - 169px);
  right: -161px;
  bottom: 30px;
  pointer-events: none;
}

.header-owndays-pc__product img {
  display: block;
}

.header-owndays-pc__nav-title {
  font-family: "NeuzeitGroReg";
  font-weight: normal !important;
  font-size: 16px;
  line-height: 1.375;
}

.header-owndays-pc__nav-title--long {
  margin: 0 0 20px 0;
}

.header-owndays-pc__nav-title--short {
  margin: 0 0 40px 0;
}

.header-owndays-pc___nav-list {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 60px 120px;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
}

.header-owndays-pc___nav-list .slick-current {
  z-index: 1;
}

.header-owndays-pc__nav-item img {
  display: block;
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 72px;
}

@media (min-width: 768px) {
  .header-owndays-pc__container__banner {
    padding: 267px 0 155px;
  }
  .header-owndays-pc__container__nav {
    padding-bottom: 180px;
  }
  .header-owndays-pc__logo {
    max-width: 50%;
  }
  .header-owndays-pc__heading br {
    display: none;
  }
  .header-owndays-pc__tag img {
    max-width: 160px;
  }
  .header-owndays-pc__product {
    top: 45%;
    left: 50%;
    right: -255px;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .header-owndays-pc__product img {
    width: 100%;
  }
  .header-owndays-pc__nav-title {
    font-size: 20px;
    letter-spacing: 1px;
  }
  .header-owndays-pc__nav-title__hide-new-line {
    display: none;
  }
  .header-owndays-pc__nav-title--long, .header-owndays-pc__nav-title--short {
    margin: 0 0 30px 0;
  }
  .header-owndays-pc___nav-list {
    grid-template-columns: repeat(2, calc(50% - 60px));
  }
  .header-owndays-pc__nav-item img {
    margin-bottom: 55px;
  }
}

.product-od-pc__container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 94px;
}

@media (min-width: 768px) {
  .product-od-pc__container {
    bottom: 92px;
  }
}

.product-od-pc__palette {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 14px;
}

@media (min-width: 768px) {
  .product-od-pc__palette {
    gap: 12px;
  }
}

.product-od-pc__color {
  padding: 0;
  width: 28px;
  height: 28px;
  background: transparent;
  background-size: contain;
  border: 3px solid #ffffff;
  font-size: 0;
  color: transparent;
  cursor: pointer;
}

@media (min-width: 860px) {
  .product-od-pc__color {
    width: 22px;
    height: 22px;
  }
}

.product-od-pc__color--selected {
  -webkit-box-shadow: 0 0 0 1px #000000;
          box-shadow: 0 0 0 1px #000000;
}
