@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
html {
  font-size: inherit;
}
.base-kimetsu {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg_ptn.jpg") repeat top/contain;
  background-attachment: fixed;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400 !important;
  letter-spacing: 1px;
  overflow-x: hidden;
}
.base-kimetsu__container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
}
@media (min-width: 768px) {
  .base-kimetsu__container {
    padding: 0 8%;
  }
}
@media (min-width: 1024px) {
  .base-kimetsu__container {
    padding: 0 25px;
  }
}
.base-kimetsu img {
  backdrop-filter: blur(0);
}
@media (max-width: 767px) {
  .base-kimetsu a:hover {
    opacity: 1;
    color: white;
  }
}
.base-kimetsu__show--xl {
  display: none !important;
}
@media (min-width: 1296px) {
  .base-kimetsu__show--xl {
    display: block !important;
  }
}
.base-kimetsu__hide--pc {
  display: block !important;
}
@media (min-width: 768px) {
  .base-kimetsu__hide--pc {
    display: none !important;
  }
}
.base-kimetsu__hide--tb {
  display: none !important;
}
@media (min-width: 1024px) {
  .base-kimetsu__hide--tb {
    display: block !important;
  }
}
.base-kimetsu__hide--xl {
  display: block !important;
}
@media (min-width: 1200px) {
  .base-kimetsu__hide--xl {
    display: none !important;
  }
}
.base-kimetsu__hide--sp {
  display: none !important;
}
@media (min-width: 768px) {
  .base-kimetsu__hide--sp {
    display: block !important;
  }
}
