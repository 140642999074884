$font-founders: 'Founders Grotesk', sans-serif;

$red: #BF0000;
$white: #FFF;
$black: #000;

.od-header-owndays-progressive-cp {
  $baseImgUrl: 'https://static.lenskart.com/media/owndays/img/progressive-cp';

  width: 100%;
  height: auto;
  position: relative;

  &__container {
    width: 100%;
    height: auto;
    aspect-ratio: auto 1321 / 584;

    &__desc {
      position: absolute;
      left: 120px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 1438px) {
      object-position: left 100px bottom 0px;
    }

    @media (max-width: 1208px) {
      &__desc {
        left: 5%;
      }
    }

    @media (max-width: 1024px) {
      object-position: left 150px bottom 0px;
    }

    @media (max-width: 895px) {
      content: url("#{$baseImgUrl}/main-sp-10-2024.webp");
      min-height: 658px;
      max-height: 850px;
      object-fit: cover;
      object-position: initial;
      margin-top: 346px;

      &__desc {
        position: absolute;
        left: 50%;
        top: 13%;
        transform: translate(-50%, 0px);
      }
    }

    @media (max-width: 595px) {
      object-fit: cover;
      min-height: auto;
      max-height: none;
      margin-top: 236px;

      &__desc {
        top: 80px;
        width: 100%;
        max-width: 83.85%;
      }
    }

    @media (max-width: 430px) {
      margin-top: 247px;
    }
  }

  &__header {
    border-bottom: 1px solid $black;
    padding-bottom: 30px;

    &__main {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 5px;
      height: 63px;
    }

    &--black, &--red {
      font-family: $font-founders;
      font-style: normal;
      font-weight: 600 !important;
    }

    &--black {
      color: $black;
      font-size: 30px;
      margin-top: 8px;
      line-height: 36px;
      letter-spacing: 3px;
    }

    &--red {
      color: $red;
      font-size: 100px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 10px;
    }

    @media (max-width: 595px) {
      padding-bottom: 20px;
  
      &__main {
        gap: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        height: 38px;
      }
  
      &--black {
        font-size: 24px;
        margin: 3px 0 0 0;
        letter-spacing: 3px;
        line-height: 24px;
      }
  
      &--red {
        font-size: 60px;
        letter-spacing: 6px;
      }
    }

    @media (max-width: 430px) {
      &--black {
        font-size: 20px;
        letter-spacing: 2px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 30px 0 0 0;

    &__tag-pc, h3 {
      font-family: $font-founders;
    }

    &__tag-pc, &__tag-sp {
      width: fit-content;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      background-color: $red;
      font-size: 20px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 36px;
      letter-spacing: 1px;
      color: white;
      margin-bottom: 0;

      &__price {
        font-family: $font-founders;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 1.5px;
        padding: 0 5px 0 10px;
      }
    }

    &__tag-sp {
      display: none;
    }

    h3 {
      color: $black;
      font-size: 24px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
      letter-spacing: 1.2px;
      margin: 24px 0 0 0;

      &::after {
        content: '';
        display: block;
        height: 6px;
      }
    }

    @media (max-width: 1274px) {
      padding: 30px 0 0 0;

      h3 {
        font-size: 18px;
        letter-spacing: 0.9px;
      }
    }

    @media (max-width: 1024px) {
      &::after {
        height: 4px;
      }

      h3 {
        line-height: normal;

        @media (max-width: 776px) {
          br {
            display: none;
          }
        }
      }
    }

    @media (max-width: 895px) {
      &__tag-pc {
        display: none;
      }

      &__tag-sp {
        display: flex;

        &--now {
          margin: 10px 0 0 auto;
        }
      }
    }

    @media (max-width: 595px) {
      padding: 20px 0 0 0;

      &__tag-sp {
        font-size: 18px;
        padding: 0 11px 0 9px;

        &__price {
          font-size: 24px;
          letter-spacing: 1.2px;
        }

        &--now {
          margin: 10px 0 0 auto;
        }
      }

      h3 {
        margin-top: 16px;
      }
    }
  }

  &__custom-breadcrumbs {
    @media (max-width: 476px) {
      margin-top: 76px;

      nav ol li {
        font-size: 10px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;

        &::before {
          padding: 0 8px;
        }
      }
    }
  }

  &__remark {
    position: absolute;
    right: 122px;
    bottom: 10px;
    color: $white;
    text-align: right;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
    font-family: $font-founders;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.7px;

    @media (max-width: 895px) {
      width: 100%;
      height: auto;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }

    @media (max-width: 595px) {
      font-size: 12px;
      bottom: 4px;
    }
  }
}
