@import '../../../theme/mixins/breakpoints';

$gray-66: #666;

.brands {
  padding-block: 50px 60px;
  font: 400 13px/2 'Founders Grotesk', sans-serif;
  letter-spacing: 1px;
  @include mq-up('lg') {
    font-size: 16px;
  }
  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1132px + 40px);
  }
  &__heading {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    color: black;

    &--long {
      color: $gray-66;
      font-size: 14px;
      font-weight: normal !important;
      margin: 12px auto 0;
      line-height: 1.5;
      padding-bottom: 30px;
      text-align: center;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  &__card {
    position: relative;
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &::before {
      background-color: rgba(#000, 0.4);
    }
    &::after {
      background: url('https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg')
        no-repeat center/27px auto;
      filter: invert(1);
    }
    img {
      display: block;
      aspect-ratio: 1/1;
      width: 100%;
    }
    &:hover,
    &.is-open {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    padding: 48px 15px 35px;
    border: 1px solid #999;
    position: relative;
    grid-column: span 2;
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      width: 40px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    &-slider {
      opacity: 0;
      transition: opacity 0.4s ease-out;
      
      &.is-visible {
        opacity: 1;
      }

      @media (max-width: 767px) { // Mobile only
        height: auto !important; // Override any height animations
        max-height: none !important; // Override any height animations
      }
      .slick {
        &-slide {
          * {
            outline: none;
          }
          img {
            width: 100%;
            aspect-ratio: 3/2;
          }
        }
        &-dots {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex !important;
          justify-content: center;
          gap: 14px;
          margin-top: 10px;
          li {
            display: inline-flex;
            button {
              font: 0/0 a;
              padding: 0;
              margin-bottom: 20px;
              background-color: #999;
              border: none;
              border-radius: 0;
              width: 10px;
              height: 10px;
              cursor: pointer;
            }
            &.slick-active {
              button {
                background-color: #000;
              }
            }
          }
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
    }
    &-logo {
      font: 0/0 a;
      margin: 0;
      text-align: left;
      padding: 0;
      img {
        // Mobile styles (default)
        max-height: 37px;  // Smaller height for mobile
        margin: 40px 0 20px 0;
        display: block;
      }

      &--expanded {
        img {
          width: 300px;
          object-fit: cover;

          @media (max-width: 767px) {
            width: 83%;
          }
        }
      }
    }
    &-recommended {
      border-style: solid;
      border-color: #999;
      border-width: 1px 0px 1px 0px;
      padding: 16px 0;
      margin-bottom: 20px;
      font-size: 15px;
    }
    &-bottom {
      display: flex;
      flex-direction: column;
    }

    &-actions {
      display: flex;
      flex-direction: row;
      gap: 12px;
      order: 1;
      margin-bottom: 20px;
      .button {
        flex: 1;
        min-width: 0;
        
        &:focus,
        &:hover {
          text-decoration: none;
        }
        
        &:focus {
          text-decoration: none !important;
        }
      }
    }

    &-title {
      order: 2;
      font-family: 'Founders Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 28.8px;
      vertical-align: cap;
      margin: 0 0 20px 0;
    }

    &-description {
      order: 3;
      margin:0;
    }

    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transition: none;
    margin-top: 0;
    
    &.is-visible {
      visibility: visible;
      opacity: 1;
      max-height: 800px;
      animation: expandHeight 0.6s ease-out forwards;
    }

    &.is-closing {
      animation: collapseHeight 0.4s ease-out forwards;
    }

    @keyframes expandHeight {
      0% {
        max-height: 0;
        opacity: 0;
      }
      100% {
        max-height: 800px;
        opacity: 1;
      }
    }

    @keyframes collapseHeight {
      0% {
        max-height: 800px;
        opacity: 1;
        visibility: visible;
      }
      100% {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    @include mq-up('md') {
      &.is-visible {
        animation: expandHeight 0.6s ease-out forwards;
      }

      &.is-closing {
        animation: collapseHeight 0.4s ease-out forwards;
      }
    }
  }
  @include mq-up('md') {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
    &__details {
      display: grid;
      grid-template-columns: 1fr 50%;
      gap: 25px;
      align-items: center;
      grid-column: span 3;
      &-slider{
        overflow: hidden;
      }
      &-bottom {
        flex-direction: column;
      }
      &-actions {
        flex-direction: row;
        order: 3;
      }
      &-title {
        order: 1;
      }
      &-description {
        order: 2;
        margin-bottom: 30px;
      }
    }
  }

  @media (min-width: 768px) {
    padding-block: 110px 120px;
    &__heading {
      font-size: 40px;
      
      &--long {
        margin: 17px auto 0;
        padding-bottom: 90px;
        line-height: 1.5;
      }
    }
  }

  @include mq-up('lg') {
    &__list {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
    &__card{
      &::after{
        background-size: 53px auto;
      }
    }
    &__details {
      gap: 50px;
      padding: 50px;
      grid-column: span 4;
      &-recommended {
        font-size: 16px;
        
      }
    }
  }
}
