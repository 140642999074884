@media (max-width: 430px) {
  .header-gift-ticket__breadcrumbs nav ol li:last-child::before {
    padding-left: 0;
  }
}

.header-gift-ticket__banner {
  position: relative;
}

.header-gift-ticket__banner picture img {
  display: block;
  width: 100%;
  height: 510px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (min-width: 768px) {
  .header-gift-ticket__banner picture img {
    height: auto;
  }
}

.header-gift-ticket__banner__message {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: auto;
  max-width: 66%;
  padding: 50px 5% 0 5%;
}

.header-gift-ticket__banner__message__container {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header-gift-ticket__banner__message__logo {
  width: 147px;
  height: auto;
  margin: 0 auto 15px auto;
}

.header-gift-ticket__banner__message__desc {
  margin-bottom: 0;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  font-size: 12px;
  text-align: center;
  line-height: 2;
  color: white;
}

@media (min-width: 768px) {
  .header-gift-ticket__banner__message {
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: none;
            transform: none;
    width: 80%;
    padding: 50px 0;
    margin: 0 auto;
  }
  .header-gift-ticket__banner__message__logo {
    width: 200px;
    margin: 0 0 30px 0;
  }
  .header-gift-ticket__banner__message__desc {
    font-size: 14px;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .header-gift-ticket__banner__message__logo {
    width: 226px;
  }
  .header-gift-ticket__banner__message__desc {
    font-size: 20px;
  }
}
