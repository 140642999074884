.od-banner {
  width: 100%;
  position: relative;

  &__content {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    color: #ffffff;
    transform: translate(0, -50%);

    &__img {
      margin: 0 auto 100px auto;
    }

    &__title {
      width: 100%;
      color: #FFFFFF;
      line-height: 84px;
      font-size: 72px;
      padding: 0 197px;
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      &__title {
        font-size: 36px;
        line-height: 48px;
        padding: 0 20px;
      }

      &__img {
        margin-bottom: 50px;
      }
    }
  }
}
