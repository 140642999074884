.od-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #F5F2EA;
  margin: 60px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__p {
    position: absolute;
    top: 61px;
    left: 63px;
  }

  &__smile-first {
    position: absolute;
    top: 61px;
    left: 49px;
  }

  &__smile-second {
    position: absolute;
    top: 61px;
    left: 84px;
  }

  &__stairs-border {
    position: absolute;
    top: 48px;
    left: 48px;
  }

  &__vector {
    position: absolute;
    top: 45px;
    left: 45px;
  }
}
