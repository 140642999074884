$font-space-mono: 'Space Mono', monospace;
$font-neuzeitgro-reg: 'NeuzeitGroReg';

$black: #000000;

.footer-owndays-pc {
  &__lineup {
    padding-bottom: 100px;

    &__mb-0 {
      margin-bottom: 0 !important;
    }

    &__product {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 60px 12px;
      margin-top: 60px;
      list-style-type: none;
      padding-left: 0;

      @media (min-width: 768px) {
        margin-top: 0;
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1024px) {
        column-gap: 40px;

        &--first {
          margin-top: 0;
        }
      }

      a {
        img {
          display: block;
          width: 100%;
          height: auto;
          transition: 0.6s;
        }

        &:hover, &:active, &:visited, &:focus {
          text-decoration: none;
        }

        &:hover {
          color: $black;
          img {
            opacity: 1;
          }
        }
      }

      figure {
        position: relative;
        margin: 0;
        
        @media (min-width: 768px) {
          &:hover {
            img {
              transform: scale(1.03);
            }
          }
        }
      }

      &-color {
        font-family: $font-space-mono;
        font-size: 12px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        color: $black;
        margin: 0;
      }

      &-sku {
        font-family: $font-neuzeitgro-reg;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        color: $black;
        margin: 0;
      }
    }

    &__more {
      margin-top: 40px;
      margin-bottom: 100px;

      @media (min-width: 768px) {
        margin-top: 60px;
        margin-bottom: 90px;
      }
    }

    &__footer {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px 0;
      margin-top: 100px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 40px 20px;
      }

      @media (min-width: 1024px) {
        gap: 90px 180px;
      }

      .base-owndays-pc__btn {
        width: 100%;
        max-width: 450px;

        @media (min-width: 768px) {
          &:first-child {
            margin-left: auto;
            margin-right: 0;
          }

          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
    
    @media (min-width: 1024px) {
      padding-bottom: 180px;
    }
  }
}
