.od-applied-gift-cards {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-column: span 2;
}

.od-applied-gift-cards__card {
  padding: 10px 15px;
  background-color: rgba(84, 96, 115, .1);
  color: #546073;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.od-applied-gift-cards__card img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
