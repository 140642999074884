.blog-news__categories {
  margin: 0 auto;
  padding: 81px 0 86px;
  text-align: center;
}

@media (max-width: 767px) {
  .blog-news__categories {
    width: 100%;
    padding: 42px 5%;
  }
}

.blog-news__categories ul {
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  list-style-type: none !important;
}

@media (max-width: 767px) {
  .blog-news__categories ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
  }
}

.blog-news__categories ul li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 19px 0 0;
  padding: 0 6px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 2px;
  text-indent: 3px;
  cursor: pointer;
  width: auto;
  height: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(#000));
  background-image: linear-gradient(#000, #000);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.3s ease-in-out;
  transition: background-size 0.3s ease-in-out;
  list-style-type: none !important;
}

.blog-news__categories ul li:hover, .blog-news__categories ul li:focus, .blog-news__categories ul li:active {
  background-size: 100% 0.1em;
  background-position-x: 0%;
  background-position-y: 19px;
}

@media (min-width: 767px) {
  .blog-news__categories ul li:hover, .blog-news__categories ul li:focus, .blog-news__categories ul li:active {
    color: #999;
  }
}

.blog-news__categories ul li a {
  color: #000;
  text-decoration: none;
}

@media (max-width: 767px) {
  .blog-news__categories ul li {
    padding: 12px 0;
    width: 100%;
    height: 37px;
    margin: 0;
    letter-spacing: 1.5px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-image: none;
  }
}

.blog-news__categories ul li:last-of-type {
  margin: 0;
}

.blog-news__container {
  width: 80%;
  max-width: 1152px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .blog-news__container {
    width: 100%;
    padding: 0 5%;
  }
}

.blog-news__block {
  margin: 0 0 29px;
}

@media (max-width: 767px) {
  .blog-news__block {
    margin: 0 0 60px;
  }
}

.blog-news__list {
  display: none;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 0 150px 0;
  list-style-type: none !important;
}

.blog-news__list > li {
  width: calc(33.33333% - 20px);
  margin: 0 30px 55px 0;
  opacity: 0;
  -webkit-transform: translate(0, 16px);
          transform: translate(0, 16px);
  font-family: 'Founders Grotesk', sans-serif;
  list-style-type: none !important;
}

@media (min-width: 768px) {
  .blog-news__list > li:hover {
    opacity: 1;
  }
  .blog-news__list > li:hover a {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1);
  }
  .blog-news__list > li:hover .blog-news__list-thumbnail img {
    opacity: 0.6;
  }
}

.blog-news__list > li:nth-of-type(3n), .blog-news__list > li:last-of-type {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blog-news__list > li {
    width: 100%;
    margin: 0 0 30px;
    -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
  }
  .blog-news__list > li:last-of-type {
    margin: 0;
  }
}

.blog-news__list > li a {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog-news__list > li-thumbnail {
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
}

.blog-news__list > li-thumbnail img {
  width: 100%;
}

.blog-news__list > li figure {
  margin: 0;
}

.blog-news__list > li figure figcaption {
  text-align: center;
  padding: 29px 5%;
}

@media (max-width: 767px) {
  .blog-news__list > li figure figcaption {
    padding: 29px 5% 23px;
  }
}

.blog-news__list--open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}

.blog-news__list--open li {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-transition: opacity 0.2s ease 0.2s, -webkit-transform 0.2s ease;
  transition: opacity 0.2s ease 0.2s, -webkit-transform 0.2s ease;
  transition: opacity 0.2s ease 0.2s, transform 0.2s ease;
  transition: opacity 0.2s ease 0.2s, transform 0.2s ease, -webkit-transform 0.2s ease;
}

@media (max-width: 767px) {
  .blog-news__list--open {
    display: block;
  }
}

.blog-news__list-category {
  margin: 0 0 16px;
  font-size: 14px;
  text-indent: 3px;
  color: #000;
  font-weight: 300 !important;
  letter-spacing: 3px;
}

.blog-news__list-title {
  font-size: 15px;
  margin: 0 0 13px;
  color: #000;
  line-height: 1.5;
  font-weight: normal !important;
  letter-spacing: 1px;
}

.blog-news__list-view-more {
  color: #0094c8;
  font-size: 15px;
  margin: 0;
  font-weight: normal !important;
  letter-spacing: 1px;
}

.new-active {
  opacity: 1 !important;
  -webkit-transform: translate(0, 0) !important;
          transform: translate(0, 0) !important;
  -webkit-transition-delay: .5s !important;
          transition-delay: .5s !important;
}

.selected-new-category {
  background-color: #000;
  color: #fff;
}

@media (min-width: 767px) {
  .selected-new-category {
    background-color: transparent;
    color: #000;
    border-bottom: 1px solid #000;
    background-image: none !important;
  }
}
