.services__lens-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 30px;
  margin: 0;
}

.services__lens-details-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #f4f4f4;
}

.services__lens-details-header {
  display: grid;
  grid-template-columns: 55px 1fr;
  border-bottom: 1px solid #f4f4f4;
  padding: 20px 0;
}

.services__lens-details-header-icon {
  max-width: 30px;
  margin: 0 auto;
}

.services__lens-details-header-details {
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.services__lens-details-header-details i {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70px;
  height: 17px;
  text-align: center;
  font-size: 11px;
  line-height: 1;
  color: #fff;
  margin-bottom: 3px;
  font-style: normal;
}

.services__lens-details-header-details i.standard {
  background-color: #0094c8;
}

.services__lens-details-header-details i.option {
  background-color: #c7002e;
}

.services__lens-details-header-details div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  row-gap: 4px;
}

.services__lens-details-header-details div h3 {
  font-size: 14px;
  font-weight: 600 !important;
  color: #000;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.services__lens-details-header-details div h3 span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.services__lens-details-header-details div h3 span img {
  width: auto;
  height: 27px;
}

.services__lens-details-header-details div h3 small {
  color: #c7002e;
  font-weight: 400;
  font-size: 100%;
}

.services__lens-details-header-details div p {
  color: #c7002e;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}

.services__lens-details-header-details div p small {
  font-weight: 400;
}

.services__lens-details-body {
  padding: 20px;
  font-size: 16px;
}

.services__lens-details-body h4 {
  font-size: 14px;
  font-weight: 600 !important;
  margin: 0 0 10px;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.services__lens-details-body h4 strong {
  font-weight: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}

.services__lens-details-body h4 strong small {
  color: #c7002e;
  font-size: inherit;
  font-weight: 400 !important;
}

.services__lens-details-body h4 span {
  color: #c7002e;
  margin-left: 8px;
}

.services__lens-details-body h5 {
  color: #000;
}

.services__lens-details-body p {
  font-size: 15px;
  line-height: 2;
  color: #666;
  margin: 0 0 20px;
}

.services__lens-details-body ul.list-disc > li {
  position: relative;
  padding-left: 1.5em;
}

.services__lens-details-body ul.list-disc > li::before {
  content: '\00B7';
  position: absolute;
  top: 0;
  left: 1em;
}

.services__lens-details-body ul.list-notes {
  font-size: 11px;
  color: #000;
}

.services__lens-details-body-features {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.services__lens-details-body-features tr th,
.services__lens-details-body-features tr td {
  border-style: solid;
  border-color: #f4f4f4;
}

.services__lens-details-body-features tr th {
  font-weight: 400;
  border-width: 1px 1px 1px 0;
  padding: 10px 14px 10px 0;
  color: #000;
}

.services__lens-details-body-features tr td {
  color: #666;
  border-width: 1px 0 1px 1px;
  padding: 10px 0 10px 14px;
}

.services__lens-details-body-features tr td ul li {
  position: relative;
  padding-left: 30px;
}

.services__lens-details-body-features tr td ul li::before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  background: url("https://static.lenskart.com/media/owndays/img/services-lens/check.svg") no-repeat center/contain;
}

.services__lens-details-body-lineup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.services__lens-details-body-lineup > li {
  border-top: 1px solid #f4f4f4;
  padding-top: 20px;
  margin-top: 20px;
}

.services__lens-details-body-lineup > li:first-child {
  margin-top: 0;
}

.services__lens-details-body-examples {
  font-size: 11px;
  padding: 20px;
  border: 1px solid #f4f4f4;
  margin-top: 20px;
}

.services__lens-details-body-examples ul {
  margin-left: auto;
  margin-right: auto;
}

.services__lens-details-body-examples ul li h6 {
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 1.6;
  margin: 0 0 10px;
  color: #000;
}

.services__lens-details-body-examples ul li div p {
  font-size: inherit;
  margin-bottom: 10px;
}

.services__lens-details-body-examples ul li div figure figcaption {
  text-align: center;
  margin-top: 5px;
}

.services__lens-details-body-examples ul li div figure figcaption.figcaption-pclens {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 0;
}

.services__lens-details-body-examples ul li div figure figcaption.figcaption-pclens span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  max-width: 100px;
}

.services__lens-details-body-examples ul li div figure figcaption.figcaption-left {
  text-align: left;
}

.services__lens-details-body-examples figure {
  margin: 0;
}

.services__lens-details-body-examples .cols {
  display: grid;
  grid-gap: 10px;
}

.services__lens-details-body-examples .cols-2 {
  grid-template-columns: 1fr 1fr;
}

.services__lens-details-body-examples .cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.services__lens-details-body-examples .cols-lens-transition {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.services__lens-details-body-examples .cols-lens-transition li {
  max-width: 33.33333%;
}

.services__lens-details-body-examples .cols-lens-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.services__lens-details-body-examples .cols-lens-color li {
  max-width: 70px;
}

.services__lens-details-body-notes {
  margin-top: 10px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .services__lens-details-header {
    grid-template-columns: 68px 1fr;
  }
  .services__lens-details-header-icon {
    max-width: 36px;
  }
  .services__lens-details-header-details {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .services__lens-details-header-details i {
    height: 30px;
    margin-bottom: 0;
  }
  .services__lens-details-header-details div {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 20px;
  }
  .services__lens-details-header-details div h3 {
    font-size: 16px;
  }
  .services__lens-details-body {
    padding: 30px 25px;
  }
  .services__lens-details-body-features tr td ul {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
  }
  .services__lens-details-body-examples {
    text-align: center;
  }
  .services__lens-details-body-examples ul li h6 {
    font-size: 14px;
  }
  .services__lens-details-body-examples ul li div figure figcaption {
    margin-top: 10px;
  }
  .services__lens-details-body-examples .cols-pc-2 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  }
  .services__lens-details-body-examples .cols-pc-bd > li:not(:last-child) {
    position: relative;
  }
  .services__lens-details-body-examples .cols-pc-bd > li:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(100% + 20px);
    bottom: 0;
    border-right: 1px solid #f4f4f4;
  }
  .services__lens-details-body-examples .cols-pc-aspheric {
    grid-template-columns: 2fr 3fr;
  }
  .services__lens-details-body-examples .cols-lens-transition {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .services__lens-details-body-examples .cols-lens-transition li {
    max-width: 124px;
  }
}
