$font-founders: 'Founders Grotesk', sans-serif;

$white-f7: #f7f6f2;

.body-sun {
  &__styles {
    position: relative;
    display: flex;
    flex-direction: column;
    
    &--reverse {
      padding-top: 100px;
    }

    &__item {
      &:nth-child(even) {
        .body-sun__styles__features {
          .base-sun__container {
            &::before {
              left: 40px;
              right: 0;
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 40px;
            }
          }
        }
      }

      @media (min-width: 390px) {
        .base-sun__btn {
          width: 326px;
          max-width: 326px;
          margin: auto;
        }
      }

      @media (min-width: 768px) {
        .base-sun__btn {
          width: 340px;
          margin: 0;
        }
      }
    }

    &__top {
      position: relative;

      &-img {
        overflow: hidden;

        &-slider {
          aspect-ratio: 130 / 203;

          img {
            display: block;
          }
        }
      }

      &-text {
        margin-bottom: 60px;

        @media (min-width: 1024px) {
          margin-bottom: 0;
        }

        h2 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-family: $font-founders;
          font-size: 27px;
          font-weight: 600 !important;
          line-height: 40px;
          letter-spacing: 1.68px;
          margin-bottom: 32px;
          color: black;

          span {
            display: inline-flex;
            font-weight: inherit;
            overflow: hidden;
            transition-delay: 0.6s;
            position: relative;

            &::after {
              content: "";
              transition: all 0.3s;
              width: 0%;
              height: 90%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: black;
              z-index: -1;
            }
          }

          &.aos-animate {
            span {
              animation: textanicolor 1.5s forwards;
  
              &::after {
                animation: textani 1.5s forwards;
              }
            }
          }
        }
      }

      &-text-details {
        h3, p {
          font-family: $font-founders;
          color: black;
        }

        h3 {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: .72px;
          padding-bottom: 8px;
          border-bottom: 1px solid black;
          margin-bottom: 16px;
          line-height: 40px;

          span {
            display: inline-flex;
            font-weight: inherit;
            overflow: hidden;
            transition-delay: 0.6s;
            position: relative;

            &::before {
              content: "";
              transition: all 0.3s;
              width: 0%;
              height: 90%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: black;
              z-index: -1;
            }
          }

          &.aos-animate {
            span {
              animation: textanicolor 1.5s forwards;

              &::before {
                animation: textani 1.5s forwards;
              }
            }
          }
        }

        p {
          font-size: 14px;
          font-weight: 500 !important;
          letter-spacing: .56px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        &.aos-animate {
          span {
            animation: textanicolor 1.5s forwards;

            &::before {
              animation: textani 1.5s forwards;
            }
          }
        }

        &-img {
          position: relative;
          width: 100%;
          max-width: 310px;
          margin-left: 20px;

          span {
            display: block;
            width: 180px;
            height: 90px;
            border-style: solid;
            border-color: black;
            border-width: 0 0 1px 1px;
            position: absolute;
            top: 0;
            left: 0;
          }

          img {
            display: block;
            position: relative;
            max-width: 160px;
            margin-left: auto;
            z-index: 1;
            aspect-ratio: 1 / 1;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 10rem;
            background-color: black;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &__option {
      background-color: $white-f7;
      border-radius: 40px;
      padding: 53px 22px 50px;
      margin-block: 80px;

      @media (max-width: 768px) {
        margin: 80px 0 0;
      }

      &-title {
        font-family: $font-founders;
        font-size: 32px;
        font-weight: 600 !important;
        letter-spacing: 1.28px;
        text-align: center;
        margin-block: -70px 30px;
        color: black;
        line-height: 1;
      }

      &-sub-title {
        font-family: $font-founders;
        font-size: 18px;
        font-weight: 600 !important;
        letter-spacing: 0.54px;
        text-align: center;
        padding-bottom: 18px;
        color: black;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        margin: 0;
      }

      &-desc {
        font-family: $font-founders;
        font-size: 14px;
        font-weight: 500 !important;
        letter-spacing: 0.42px;
        line-height: 1.8;
        color: black;

        &--remark {
          position: relative;
          padding-left: 10px;

          &::before {
            content: '*';
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      &-step {
        display: flex;
        justify-content: space-between;
        margin: 20px auto 0;
        max-width: 400px;
        list-style-type: none;
        padding: 0;

        > li {
          margin-left: 0;

          &:has(.icon-plus) {
            flex: none;
          }
        }

        p {
          font-family: $font-founders;
          font-size: 12px;
          font-weight: 600 !important;
          text-align: center;
          margin: 8px 0 0 0;
          color: black;
        }

        &-img-wrap {
          display: flex;
          align-items: center;
          position: relative;

          img {
            margin: 0 auto;
          }
        }

        .icon-plus {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 11px;
          height: 11px;
          margin-top: 31px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        &--3col {
          img {
            width: 70px;
          }
        }
      }
      
      &-recommended {
        display: grid;
        gap: 26px;
        list-style-type: none;
        padding: 0;
        margin: 0;

        > li > a {
          &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
          }
        }

        > li > a > img {
          width: 100%;
          height: auto;
          aspect-ratio: 340 / 200.46;
        }

        &-title {
          font-family: $font-founders;
          font-size: 18px;
          font-weight: 600 !important;
          line-height: 30.6px;
          color: black;
          letter-spacing: 0.54px;
          text-align: center;
          margin-block: 35px 20px;
          padding-top: 30px;
          border-top: 1px solid black;
        }

        &-footer {
          display: flex;
          justify-content: space-between;
          margin-top: 17px;

          &-title {
            font-family: $font-founders;
            font-size: 18px;
            font-weight: 600 !important;
            line-height: normal;
            color: black;
            letter-spacing: 0.72px;
            margin: 0 0 5px 0;
          }

          &-more {
            font-family: $font-founders;
            font-size: 14px;
            font-weight: 500 !important;
            line-height: normal;
            letter-spacing: 0.56px;
            color: #0094c8;
          }

          &-price {
            font-family: $font-founders;
            font-size: 23px;
            font-weight: 500 !important;
            line-height: 1.4;
            color: black;
            letter-spacing: 1.15px;
          }
        }
      }

      &-note {
        margin-top: 30px;
        padding: 0;

        > li {
          display: flex;
          font-family: $font-founders;
          font-size: 12px;
          font-weight: 500 !important;
          line-height: 1.8;
          color: #999;
          letter-spacing: normal;

          &::before {
            content: "*";
            margin-right: 5px;
          }
        }
      }

      @media (min-width: 768px) {
        &-top {
          display: grid;
          grid-template-columns: auto auto;
          gap: 30px;

          &-detail {
            order: 2;
          }
        }

        &-step {
          order: 1;
          width: 100%;
          margin-top: 0;
          grid-column-gap: 10px;
          max-width: none;

          > li {
            margin-left: -4px;
          }
        }

        &-title {
          margin-bottom: 62px;
        }

        &-sub-title {
          text-align: left;
          br {
            display: none;
          }
        }

        &-recommended {
          grid-template-columns: 1fr 1fr;
          
          &-title {
            border: none;
            padding-top: 0;
            margin-top: 57px;
          }
        }
      }

      @media (min-width: 1024px) {
        &-top {
          gap: 73px;
        }

        &-step {    
          > li {
            margin-left: 0;
          }

          img {
            width: 120px;
          }

          p {
            font-size: 16px;
            margin-top: 15px;
            white-space: nowrap;
            line-height: 1.3;
          }

          &-price {
            font-size: 23px;
            letter-spacing: 1.15px;

            small {
              font-size: 12px;
              letter-spacing: 0.6px;
            }
          }

          .icon-plus  {
            width: 20px;
            height: 20px;
            margin-top: 50px;
          }
        }

        &-title {
          font-size: 48px;
          letter-spacing: 1.92px;
          margin-block: -80px 92px;
        }

        &-sub-title {
          font-size: 24px;
          letter-spacing: 0.72px;
          line-height: 42px;
        }

        &-recommended {
          > li > a:hover {
            img {
              opacity: .6;
              transition: .5s;
            }

            .body-sun__styles__option-recommended-footer-title, .body-sun__styles__option-recommended-footer-price {
              color: #999;
              transition: .5s;
            }
          }

          &-title {
            border: none;
            padding-top: 0;
            margin-block: 57px 35px;
            font-size: 24px;
            line-height: 42px;
            letter-spacing: 0.72px;
          }

          &-footer {
            margin-top: 20px;

            &-title {
              font-size: 20px;
              letter-spacing: 0.8px;
            }
          }
        }
        &-note {
          margin-top: 40px;
        }
      }

      @media (min-width: 1296px) {
        padding: 120px 80px 80px;
        margin-block: 120px 0;

        &-title {
          margin-top: -150px;
        }
        &-step {
          grid-column-gap: 25px;

          > li {
            margin-left: -20px;
          }

          p {
            br {
              display: none;
            }
          }

          &--2col {
            padding-inline: 0;
          }
        }
        &-recommended {
          grid-template-columns: repeat(3, 1fr);
          gap: 30px;
        }
      }
    }

    @media (min-width: 768px) {
      &--reverse {
        .body-sun__styles__top {
          &-img {
            left: 0;
            right: calc(50% + 40px);
          }

          &-text {
            &-details,
            h2 {
              max-width: 50%;
              margin-left: auto;
            }
          }
        }
        
        .body-sun__styles__top-img-slider > .slick-list > .slick-track {
          .slick-slide {
            display: flex !important;
            justify-content: end;
          }
        }
      }

      &__clear-sunglasses {
        margin-top: 120px;
      }

      &__top {
        margin-bottom: 30px;

        &-img {
          position: absolute;
          top: 0;
          left: calc(50% + 40px);
          right: 0;
          bottom: 0;
          overflow: hidden;

          &-slider {
            aspect-ratio: 34 / 39;
          }
        }

        &-text-details {
          max-width: 50%;

          &-img {
            margin-left: auto;
          }
        }

        &-text {
          .base-sun__container {
            h2 {
              max-width: 50%;
            }
          }
        }
      }

      &__item {
        &:first-child {
          .base-sun__btn {
            max-width: 340px;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      &--reverse {
        padding-top: 120px;
      }

      &__top {
        min-height: 780px;
        margin-bottom: 60px;

        &-img {
          &-slider {
            img {
              height: 780px;
              object-fit: cover;
              object-position: left top;
            }
          }
        }

        &-text {
          padding-top: 56px;
          padding-left: 40px;

          h2 {
            max-width: 54%;
            font-size: 36px;
            line-height: 61.2px;
            letter-spacing: 0.72px;
            margin-bottom: 45px;
          }
        }

        &-text-details {
          max-width: 50%;
          
          h3 {
            font-size: 24px;
            letter-spacing: 1.44px;
            padding-bottom: 16px;
            margin-bottom: 24px;
          }

          p {
            font-size: 16px;
            margin-bottom: 40px;
            letter-spacing: 0.48px;
          }

          &-img {
            margin-left: auto;
            max-width: 500px;

            span {
              width: 240px;
              height: 110px;
            }

            img {
              max-width: 220px;
            }
          }
        }
      }
    }
  }

  &__features {
    position: relative;

    .base-sun__container {
      position: relative;
      padding-bottom: 40px;

      &::before {
        content: "";
        position: absolute;
        inset: 16px 40px 0 0;
        background-color: $white-f7;
        z-index: -1;
        left: 40px;
        right: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 40px;
      }
    }

    h3 {
      font-family: $font-founders;
      font-size: 32px;
      font-weight: 700 !important;
      line-height: 1;
      letter-spacing: 1.28px;
      margin-bottom: 40px;
      color: black;
    }

    ul {
      list-style-type: none;
      margin: 0;
      display: grid;
      gap: 30px 26px;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;
        border: 1px solid rgba(0, 0, 0, .2);
        background-color: white;
        border-radius: 100rem;
        padding-right: 18px;

        span {
          flex-shrink: 0;
          width: 100px;
          height: 100px;
          border-radius: 10rem;
          background-color: white;
          border: 1px solid black;
          display: grid;
          place-content: center;

          img {
            max-width: 68px;
          }
        }

        small, p {
          color: black;
        }

        small {
          position: absolute;
          top: 0;
          left: 104px;
          font-size: 20px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: .8px;
          padding-inline: 4px;
          transform: translateY(-58%);

          &::before {
            content: "";
            position: absolute;
            top: 58%;
            left: 0;
            right: 0;
            height: 2px;
            background-color: white;
            transform: translateY(-50%);
            z-index: -1;
          }
        }

        p {
          width: 100%;
          height: auto;
          font-family: $font-founders;
          font-size: 13px;
          font-weight: 500 !important;
          letter-spacing: .52px;
          margin: 0;
        }
      }
    }

    @media (min-width: 768px) {
      .base-sun__container {
        &::before {
          top: 24px;
          left: 40px;
          right: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 40px;
        }
      }

      ul {
        grid-template-columns: repeat(4, 1fr);
        gap: 30px 20px;

        li {
          grid-column: span 2;

          span {
            width: 120px;
            height: 120px;
          }

          small {
            margin-left: 20px;
            font-size: 28px;
            letter-spacing: 2px;
          }

          p {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.42px;
          }

          &:last-child {
            grid-column: 2 / span 2;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      .base-sun__container {
        padding-bottom: 100px;

        &::before {
          right: -1000%;
          left: 100px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 60px;
        }
      }

      h3 {
        font-size: 48px;
        letter-spacing: 1.92px;
        margin-bottom: 70px;
      }

      ul {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 20px;

        li {
          grid-column: span 1;

          &:last-child {
            grid-column: unset;
          }
        }
      }
    }
  }

  &__description {
    padding-block: 40px 55px;

    h3, p {
      font-family: $font-founders;
      color: black;
    }

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: 600 !important;
      line-height: 170%;
      letter-spacing: .72px;
      margin-block: 60px 20px;
    }

    p {
      position: relative;
      font-size: 14px;
      font-weight: 400 !important;
      letter-spacing: 0.48px;
      line-height: 32px;
      max-width: 730px;
      margin-inline: auto;
      mask-image: linear-gradient(290deg, transparent 30%, black 65%, black);
      mask-position: 100% 0;
      mask-repeat: no-repeat;
      mask-size: 300% 100%;
      opacity: 0;

      &.aos-animate {
        mask-position: 0 0;
        opacity: 1;
        transition: opacity 1200ms,
          mask-position 2000ms cubic-bezier(0.5, 1, 0.89, 1);
      }
    }

    @media (min-width: 768px) {
      .base-sun__btn {
        max-width: 340px;
        margin-inline: auto;
      }
    }

    @media (min-width: 1024px) {
      padding-block: 50px 105px;

      h3 {
        font-size: 24px;
        letter-spacing: .96px;
        margin-block: 80px 16px;
      }
    }
  }

  &__lineup {
    position: relative;
    padding-bottom: 100px;

    &::before {
      content: "";
      position: absolute;
      inset: 16px 0 0;
      background-color: $white-f7;
      z-index: -1;
      top: 19px;
    }

    h3 {
      font-family: $font-founders;
      font-size: 32px;
      font-weight: 700 !important;
      line-height: 1;
      letter-spacing: 1.28px;
      margin-bottom: 40px;
      color: black;
    }

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: grid;
      gap: 60px 20px;
      grid-template-columns: 1fr;
    }

    &__item {
      width: 100%;
      max-width: 100%; 
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      position: relative;

      .base-sun__btn {
        width: 100%;
        margin: 0 auto;

        @media (min-width: 390px) {
          width: 326px;
          max-width: 326px;
        }
      }

      > .slick-slider {
        overflow: hidden;

        .slick-dots {
          display: flex !important;
          gap: 4px;
          justify-content: flex-end;
          padding: 16px 0 24px 0;
          list-style-type: none;
          margin-bottom: 0;

          > li > button {
            width: 32px;
            height: 32px;
            border: 1px solid transparent;
            border-radius: 10rem;
            outline: none;
            background-color: transparent;
            padding: 0;
            margin: 0;
            display: grid;
            place-content: center;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              object-fit: cover;
              border-radius: inherit;
            }
          }

          .slick-active {
            border: 1px solid black;
            border-radius: 50%;
            background-color: white;
          }
        }
      }

      &::before {
        content: attr(data-model);
        position: absolute;
        bottom: 31px;
        left: 0;
        font-family: $font-founders;
        font-size: 18px;
        font-weight: 500 !important;
        letter-spacing: .36px;
        color: black;
      }
    }

    @media (min-width: 768px) {
      &__list {
        grid-template-columns: repeat(4, 1fr);
      }

      &__item {
        grid-column: span 2;
        &::before {
          bottom: 30px;
        }
      }
    }

    @media (min-width: 1024px) {
      &::before {
        top: 24px;
      }

      h3 {
        font-size: 48px;
        letter-spacing: 1.92px;
        margin-bottom: 70px;
      }

      &__list {
        grid-template-columns: repeat(3, 1fr);
      }

      &__item {
        grid-column: span 1;

        &:last-child {
          width: 100%;
          grid-column: unset;
          grid-row: unset;
        }

        .base-sun__btn {
          width: 340px;
          margin: 0 auto;
        }

        &::before {
          font-size: 18px;
          bottom: 29px;
        }
      }
    }
  }

  @keyframes textanicolor {
    0% {
      color: black;
    }
  
    50% {
      color: white;
    }
  
    100% {
      color: black;
    }
  }

  @keyframes textani {
    0% {
      width: 0;
    }
  
    50% {
      width: 100%;
      left: 0;
    }
  
    100% {
      width: 100%;
      left: 102%;
    }
  }
}
