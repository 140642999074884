$font-founders: 'Founders Grotesk', sans-serif;

$gray: #516075;
$gray66: #666;
$gray6b: #6b788a;
$white: #FFF;
$whitef6: #F6F6F6;
$whitee3: #e3e3e3;
$whiteed: #EDF8F6;

.body-gift-ticket {
  &__concept {
    &__btn {
      width: 100%;
      max-width: 460px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-founders;
      font-size: 14px;
      margin: 0 auto;
      transition-duration: 0.5s;
      cursor: pointer;

      &:hover, &:active, &:visited, &:focus {
        text-decoration: none;
      }

      &--gray {
        font-weight: bold !important;
        background-color: $gray;
        color: $white;
      }

      &--white {
        background-color: $white;
        color: $gray66;
        font-weight: 500 !important;
        border: 1px solid $gray66;
      }

      @media (min-width: 768px) {
        &--gray {
          &:hover {
            background-color: $gray6b;
            color: $white;
          }
        }

        &--white {
          &:hover {
            background-color: $gray66;
            color: $white;
          }
        }
      }
    }

    &__header {
      width: 100%;
      height: auto;
      padding: 50px 5% 60px;

      &__title, &__desc {
        font-family: $font-founders;
        margin-bottom: 30px;
      }
      
      &__title {
        font-size: 18px;
        font-weight: 400 !important;
        text-align: center;
        line-height: 1.5;
      }

      &__desc {
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
      }

      @media (min-width: 768px) {
        padding: 80px 5% 130px;
        margin: 0 auto;

        &__title {
          font-size: 24px;
        }

        &__desc {
          font-size: 16px;
          max-width: 614.39px;
          margin: 0 auto 50px;
        }
      }

      @media (min-width: 1024px) {
        &__desc {
          max-width: 720px;
        }
      }
    }

    &__body-bg {
      padding: 0 5% 60px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $whitef6;
        z-index: -1;
      }

      @media (min-width: 768px) {
        padding-bottom: 0;

        &::before {
          top: 44px;
          box-sizing: border-box;
        }
      }
    }

    &__body {
      width: 100%;
      height: auto;
      position: relative;

      &__img {
        transform: translateY(-30px);
        margin: 0 auto;
        position: relative;
        width: 100%;
        height: 210px;
        overflow-y: hidden;

        img {
          display: block;
          width: 145px;
          height: auto;
          position: absolute;
          top: 16px;
          left: 50%;
          transform: rotate(-8deg) translateX(-50%);
        }
      }

      &__desc, &__remark {
        font-family: $font-founders;
        font-weight: normal !important;
        margin-bottom: 0;
      }

      &__desc {
        font-size: 15px;
        line-height: 2;
      }

      &__remark {
        font-size: 11px !important;
        line-height: 1.7;
        margin-top: 5px;
        position: relative;
        padding-left: 12px;
        color: #999;

        &::before {
          content: '*';
          position: absolute;
          left: 0;
        }
      }

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: calc(495px / 2) 1fr;
        align-items: center;
        max-width: 924px;
        margin: 0 auto;

        &__img {
          transform: none;
          height: calc(458px / 2);

          img {
            width: 100%;
            max-width: calc(334px / 2);
          }
        }
      }

      @media (min-width: 1024px) {
        grid-template-columns: 495px 1fr;

        &__img {
          height: 458px;
  
          img {
            width: 100%;
            max-width: 334px;
          }
        }

        &__desc {
          font-size: 16px;
        }
      }
    }

    &__footer {
      background-color: $white;
      padding: 60px 0 80px;

      &__container {
        width: 100%;
        padding: 0 5%;
        max-width: 924px;
        margin: 0 auto;
      }

      &__title {
        h2, p {
          font-family: $font-founders;
          margin-bottom: 33px;
        }

        h2 {
          text-align: center;
          font-size: 18px;
          font-weight: 400 !important;
          line-height: 1.5;
        }

        p {
          font-size: 15px;
          font-weight: normal;
          line-height: 2;

          br {
            display: none;
          }
        }
      }

      &__list {
        list-style-type: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        margin-bottom: 90px;
      }

      &__btn {
        width: 100%;
        padding: 0 5%;
        display: flex;
        align-items: center;
      }

      @media (min-width: 1024px) {
        padding: 120px 0 140px;

        &__container {
          display: grid;
          grid-template-columns: 1fr 450px;
          gap: 15px;
          align-items: center;
          padding: 0 0 170px 0;
          max-width: 924px;
        }

        &__title {
          h2 {
            text-align: left;
            font-size: 24px;
            margin-bottom: 24px;
          }
  
          p {
            font-size: 16px;
            font-weight: normal;
            line-height: 2;

            br {
              display: block;
            }
          }
        }

        &__list {
          margin-bottom: 0;
        }
      }
    }
  }

  &__how-to {
    background-color: $whiteed;

    &__title {
      padding: 0 0 35px 0;
      font-family: $font-founders;
      font-size: 18px;
      font-weight: normal !important;
      line-height: 1;
      text-align: center;
      margin-bottom: 0;
    }

    &__container {
      padding-top: 60px ;

      &:last-child {
        position: relative;
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 5%;
          right: 5%;
          height: 1px;
          background-color: $whitee3;
        }
      }
    }

    &__list {
      list-style-type: none;
      padding: 0 20px;
      display: grid;
      grid-template-columns: 227px 227px 227px;
      gap: 20px;
      overflow-x: scroll;
    }

    &__item {
      padding-bottom: 50px;

      img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
      }

      h5, p {
        font-family: $font-founders;
      }

      h5 {
        font-size: 16px;
        font-weight: bold !important;
        line-height: 1;
        margin-bottom: 8px;
        text-align: center;
      }

      p {
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
        margin-bottom: 0;
      }
    }

    @media (min-width: 768px) {
      &__title {
        font-size: 24px;
        padding: 0 0 55px 0;
      }

      &__container {
        padding: 100px 5% 0 5%;
        max-width: 924px;
        margin: 0 auto;
      }

      &__list {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        padding: 0;
        margin-bottom: 0;
      }

      &__item {
        padding-bottom: 80px;
  
        img {
          margin-bottom: 24px;
        }
  
        h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
}
