.od-upload-prescription-d {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-style: normal;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-upload-prescription-d__left {
  margin-right: 100px;
  padding-top: 50px;
  position: relative;
}

.od-upload-prescription-d__left__thumbnail {
  max-width: 30vw;
  height: auto;
}

.od-upload-prescription-d__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.od-upload-prescription-d .od-upload-prescription-card {
  background: #fafafa;
  padding: 45px 30px;
}

.od-upload-prescription-d .od-upload-prescription-card__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #000000;
  font-family: 'Founders Grotesk', sans-serif;
  margin-bottom: 40px;
}

.od-upload-prescription-d .od-upload-prescription-card__para {
  font-family: 'NeuzeitGroReg', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.3px;
  color: #808080;
  margin-bottom: 35px;
}

.od-upload-prescription-d .od-upload-prescription-card__upload {
  color: #808080;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0px 34px;
}

.od-upload-prescription-d .od-upload-prescription-card__upload-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Founders Grotesk', sans-serif;
  color: #000000;
}

.od-upload-prescription-d .od-upload-prescription-card__upload-item img {
  max-width: 20px;
  margin-right: 10px;
}

.od-upload-prescription-d .od-upload-prescription-card__upload-item:not(:last-child) {
  margin-right: 20px;
}

.od-upload-prescription-d .od-upload-prescription-line {
  margin-bottom: 30px;
}

.od-upload-prescription-d__footer {
  margin-top: 100px;
}

.od-upload-prescription-d__footer__acceptTerms {
  font-size: 16px;
  font-family: 'NeuzeitGroReg', sans-serif;
  opacity: 0.5;
  margin-bottom: 20px;
}

.od-upload-prescription-d__footer .od-upload-prescription-d__continueBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  font-family: 'Founders Grotesk', sans-serif;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
}
