.body-news {
  margin-top: 84px;
  width: 100%;
  height: auto;
  font-family: "Founders Grotesk", sans-serif;
}

@media (max-width: 767px) {
  .body-news {
    margin-top: 30px;
  }
}

.body-news a:hover, .body-news a:active, .body-news a:visited, .body-news a:focus {
  text-decoration: none;
}

.body-news__news {
  margin: 0 auto;
  opacity: 0;
  -webkit-transform: translate(0, 16px);
          transform: translate(0, 16px);
  -webkit-transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: auto;
  max-width: 1152px;
  padding: 0 60px;
}

@media (max-width: 767px) {
  .body-news__news {
    width: 100%;
  }
}

.body-news__news figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  overflow: hidden;
  margin: 0;
}

@media (max-width: 767px) {
  .body-news__news figure {
    display: block;
    overflow: visible;
  }
}

.body-news__news figure figcaption {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(100% - 460px);
  padding: 20px 5%;
  text-align: center;
}

@media (max-width: 1023px) {
  .body-news__news figure figcaption {
    width: calc(100% - 320px);
    padding: 45px 5%;
  }
}

@media (max-width: 767px) {
  .body-news__news figure figcaption {
    display: block;
    position: relative;
    z-index: 2;
    width: calc(100% - 32px);
    margin: -48px auto 0;
    padding: 42px 5%;
    background-color: #fff;
    border: 0;
    -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
  }
}

.body-news__news-thumbnail {
  width: 460px;
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
}

@media (max-width: 1023px) {
  .body-news__news-thumbnail {
    width: 320px;
  }
}

@media (max-width: 767px) {
  .body-news__news-thumbnail {
    width: 100%;
  }
}

.body-news__news-thumbnail img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.body-news__news__card {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-box-shadow 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-box-shadow 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: box-shadow 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: box-shadow 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-box-shadow 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (min-width: 768px) {
  .body-news__news__card:hover {
    color: #000;
    opacity: 1;
    -webkit-box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1);
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  .body-news__news__card:hover .body-news__news-thumbnail {
    opacity: 0.6;
  }
}

.body-news__news--active {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.body-news__news-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.body-news__news-text {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.body-news__news-latest {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 162px;
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
}

@media (max-width: 1023px) {
  .body-news__news-latest {
    top: 5%;
    left: 5%;
    width: 81px;
  }
}

@media (max-width: 767px) {
  .body-news__news-latest {
    top: 10%;
    left: 8%;
  }
}

.body-news__news-latest img {
  width: 100%;
}

.body-news__news-category {
  margin: 0 0 16px;
  font-size: 14px;
  letter-spacing: 3px;
  text-indent: 3px;
  color: #000;
}

.body-news__news-title {
  margin: 0 0 16px;
  font-size: 26px;
  letter-spacing: 1px;
  color: #000;
}

@media (max-width: 1023px) {
  .body-news__news-title {
    font-size: 20px;
  }
}

.body-news__news-description {
  line-height: 1.5;
  margin-bottom: 16px;
  color: #000;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .body-news__news-description {
    margin: 0 0 16px;
  }
  .body-news__news-description br {
    display: none;
  }
}

.body-news__news-link {
  width: 100%;
  margin-top: auto;
  color: #0094c8;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .body-news__news-link {
    position: static;
  }
}

.body-news__l-content {
  margin: 78px auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1023px) {
  .body-news__l-content {
    margin: 0 auto 60px;
  }
}

@media (max-width: 767px) {
  .body-news__l-content--inner--full-sp {
    padding: 0;
  }
}
