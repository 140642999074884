$base-img-url: 'https://static.lenskart.com/media/owndays/img/freaks-store';

$font-noto: "Noto Sans JP", sans-serif;
$font-din: din-condensed, sans-serif;

$gray-cb: #CBCEC7;
$green-55: #555E2B;

.lens-colour-freak-store {
  margin: 80px calc(15px * -1);

  &__title {
    h2 {
      font-family: $font-din;
      font-size: 32px;
      font-weight: bold !important;
      letter-spacing: 0.96px;
      line-height: 1.5;
      margin: 0;
      text-align: center;
    }
  }

  &__tabs {
    margin: 40px 0 0 0;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;

    li > a {
      outline: none;
      text-decoration: none;
      transition: 0.5s;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 12px;
      border-bottom: 3px solid $gray-cb;
      width: 100%;
      height: 53px;

      span {
        font-family: $font-din;
        font-size: 20px;
        font-weight: bold !important;
        line-height: normal;
        color: black;
      }
    }

    &--active {
      background-color: $gray-cb;
    }
  }

  &__content {
    padding: 30px 15px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step-images, &__step-lens, &__desc-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__step-images {
    display: flex;
    gap: 23px;
  }

  &__step-lens {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 25px;
    width: fit-content;
    height: auto;
    min-height: 30px;
    padding-left: 10px;
    padding-top: 5px;
    margin: 15px auto 30px auto;

    &::after {
      content: "";
      background: url("#{$base-img-url}/line-step.webp");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: -15px;
      left: 65%;
      transform: translateX(-48%);
      width: 103%;
      height: 18px;
    }

    li {
      font: 400 14px / 26px $font-noto;
      letter-spacing: normal;
      color: black;

      &:nth-child(2) {
        font-weight: bold !important;
        color: $green-55;
      }
    }
  }

  &__desc-list {
    display: flex;
    gap: 10px;
    justify-content: center;

    &--only-one {
      width: 100%;
      height: auto;
      
      @media (min-width: 768px) {
        position: relative;

        > ul {
          position: absolute;
          bottom: 104px;
          right: 0;
        }
      }
    }

    li {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 1px solid $gray-cb;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title, &__value {
      font-family: $font-noto;
      font-weight: normal !important;
      text-align: center;
      line-height: 18px;
    }
    
    &__title {
      font-size: 12px;
      color: $green-55;
      border-bottom: 1px dashed $green-55;
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }

    &__value {
      font-size: 14px;
      margin-top: 6px;
      color: black;
    }
  }

  @media (min-width: 1024px) {
    margin: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;

    &__title {
      h2 {
        font-size: 38px;
        letter-spacing: 0;
        line-height: 1.5;
      }
    }

    &__tabs {  
      li > a {
        padding-top: 13px;
  
        span {
          font-size: 28px;
          letter-spacing: 0.84px;
        }
      }
    }

    &__step-images, &__step-lens, &__desc-list {
      max-width: 710px;
    }
  
    &__step-images {
      gap: 110px;
    }
  
    &__step-lens {
      gap: 157.5px;
      
      &::after {
        content: "";
        background: url("#{$base-img-url}/line-step-pc.webp");
        background-size: cover;
        width: 424px;
        height: 20px;
        top: -30px;
        left: 50%;
      }
  
      li {
        font-size: 16px;
      }
    }
  
    &__desc-list {
      gap: 20px;
  
      li {
        width: 130px;
        height: 130px;
        border-radius: 100px;
      }

      &__title {
        font-size: 14px;
      }
  
      &__value {
        font-size: 18px;
      }
    }
  }
}
