.services {
  font-family: 'Founders Grotesk', sans-serif;
  letter-spacing: 1px;
}

.services a {
  text-decoration: none;
}

.services a:hover {
  text-decoration: none;
}

.services img {
  width: 100%;
}

.services ul, .services ol {
  list-style: none;
  padding: 0;
}

.services__container {
  width: 100%;
  padding: 0 5% 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1152px;
}

.services__subtitle {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9;
  letter-spacing: 1px;
  margin: 50px 0 30px;
  color: #000;
}

.services__lens-replacement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fbfbfb;
  padding: 30px calc(5% + 10px);
  position: relative;
  color: #000;
  font-size: 15px;
  line-height: 2;
  margin-top: 40px;
}

.services__lens-replacement:hover {
  color: inherit;
}

.services__lens-replacement::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid #fff;
  pointer-events: none;
}

.services__lens-replacement-title {
  font-size: 26px;
  line-height: 1.6;
  margin: 0 0 10px;
}

.services__lens-replacement-subtitle, .services__lens-replacement-des, .services__lens-replacement-more, .services__lens-replacement-price {
  margin-bottom: 0;
}

.services__lens-replacement-des, .services__lens-replacement-more {
  color: #999;
}

.services__lens-replacement-price {
  color: #c7002e;
  font-size: 24px;
}

@media (min-width: 768px) {
  .services__container {
    width: 90%;
    padding-bottom: 120px;
  }
  .services__subtitle {
    font-size: 26px;
    margin-top: 80px;
  }
  .services__lens-replacement {
    display: grid;
    grid-template-columns: 1fr 60%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 10px;
  }
  .services__lens-replacement-details {
    max-width: 80%;
  }
}
