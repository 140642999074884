.footer-eye-camp__history {
  border-top: 1px solid #e3e3e3;
  padding: 40px 0 60px;
}

@media (min-width: 768px) {
  .footer-eye-camp__history {
    padding: 90px 0 120px;
  }
}

.footer-eye-camp__history__container {
  width: 80%;
  height: auto;
  max-width: 1152px;
  margin: 0 auto;
}

.footer-eye-camp__history__title {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 300 !important;
  text-align: center;
  line-height: 1.6;
  padding: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .footer-eye-camp__history__title {
    font-size: 26px;
    line-height: 1.9;
  }
}

.footer-eye-camp__history__list {
  list-style-type: none;
  margin: 20px -16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0;
}

.footer-eye-camp__history__list li > a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer-eye-camp__history__list li > a:hover, .footer-eye-camp__history__list li > a:active, .footer-eye-camp__history__list li > a:visited, .footer-eye-camp__history__list li > a:focus {
  text-decoration: none;
}

@media (min-width: 768px) {
  .footer-eye-camp__history__list {
    margin: 50px -10px 0;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media (min-width: 1024px) {
  .footer-eye-camp__history__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.footer-eye-camp__history__card, .footer-eye-camp__history__card-no-more {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
}

.footer-eye-camp__history__card::before, .footer-eye-camp__history__card::after, .footer-eye-camp__history__card-no-more::before, .footer-eye-camp__history__card-no-more::after {
  content: "";
  display: block;
  position: absolute;
}

.footer-eye-camp__history__card::before, .footer-eye-camp__history__card-no-more::before {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  background-color: rgba(0, 0, 0, .4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}

.footer-eye-camp__history__card img, .footer-eye-camp__history__card-no-more img {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #f4f4f4;
}

.footer-eye-camp__history__card div, .footer-eye-camp__history__card-no-more div {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
  text-align: center;
  line-height: 1.1;
  padding: 20px 10px;
  color: black;
}

.footer-eye-camp__history__card div span, .footer-eye-camp__history__card-no-more div span {
  font-weight: 700 !important;
  display: block;
  padding-right: 0;
}

.footer-eye-camp__history__card div p, .footer-eye-camp__history__card-no-more div p {
  font-size: 11px;
  margin: 4px 0 0 0;
}

@media (min-width: 768px) {
  .footer-eye-camp__history__card div span, .footer-eye-camp__history__card-no-more div span {
    padding-right: 5px;
    display: inline-block;
  }
}

.footer-eye-camp__history__card {
  position: relative;
}

.footer-eye-camp__history__card::after {
  border: 25px solid transparent;
  border-left: 25px solid #0094c8;
  right: -25px;
  bottom: -25px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.footer-eye-camp__history__card--clicked::before, .footer-eye-camp__history__card--clicked img {
  opacity: 0.6;
}

@media (min-width: 1024px) {
  .footer-eye-camp__history__card {
    cursor: pointer;
  }
  .footer-eye-camp__history__card:hover::before, .footer-eye-camp__history__card:hover img {
    opacity: 0.6;
  }
}

.footer-eye-camp__history__plus {
  position: absolute;
  right: 5px;
  bottom: 8px;
  z-index: 1;
  width: 10px;
  height: 10px;
}

.footer-eye-camp__history__plus::before, .footer-eye-camp__history__plus::after {
  content: "";
  display: block;
  position: absolute;
  background-color: white;
}

.footer-eye-camp__history__plus::before {
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
}

.footer-eye-camp__history__plus::after {
  transition: transform .25s ease-out, -webkit-transform .25s ease-out;
  margin-top: -1px;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
}

.footer-eye-camp__history__icon-search {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  opacity: 0;
  z-index: 2;
  color: white;
  width: 27px !important;
  height: 27px !important;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  border: 0 !important;
}

.footer-eye-camp__history__icon-search--show {
  opacity: 1;
}

@media (min-width: 768px) {
  .footer-eye-camp__history__icon-search {
    width: 52px !important;
    height: 52px !important;
  }
}

.footer-eye-camp__history-detail__card-detail {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 0;
  background-color: #f4f4f4;
  grid-column: 1 / span 2;
  overflow: hidden;
  opacity: 0;
  padding: 25px 15px;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}

.footer-eye-camp__history-detail__card-detail--show {
  opacity: 1;
}

.footer-eye-camp__history-detail__card-detail--hide {
  opacity: 0;
  max-height: 0;
}

@media (min-width: 768px) {
  .footer-eye-camp__history-detail__card-detail {
    grid-column: 1 / span 3;
  }
  .footer-eye-camp__history-detail__card-detail--show {
    padding: 60px 50px 50px;
  }
}

@media (min-width: 1024px) {
  .footer-eye-camp__history-detail__card-detail {
    grid-column: 1 / span 4;
  }
}

.footer-eye-camp__history-detail__close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.footer-eye-camp__history-detail__close svg {
  fill: #999;
}

.footer-eye-camp__history-detail__header {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  color: black;
  font-weight: 400 !important;
  margin: 0 0 20px 0;
}

.footer-eye-camp__history-detail__header--country {
  display: block;
  font-weight: 700 !important;
}

.footer-eye-camp__history-detail__header--date {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400 !important;
  display: block;
}

@media (min-width: 768px) {
  .footer-eye-camp__history-detail__header {
    font-size: 20px;
    margin: 0 0 30px 0;
  }
  .footer-eye-camp__history-detail__header--country {
    display: inline-block;
    padding-right: 10px;
  }
  .footer-eye-camp__history-detail__header--date {
    margin-top: 6px;
  }
}

.footer-eye-camp__history-detail__message__text {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  letter-spacing: 1px;
  padding-bottom: 25px;
  color: black;
  text-align: left;
  margin: 0;
}

.footer-eye-camp__history-detail__opinions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  list-style-type: none;
  margin: 20px 0 0 0;
  border-top: 2px dotted black;
  padding: 40px 0 20px 0;
}

.footer-eye-camp__history-detail__opinions__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 17px;
  margin-bottom: 15px;
}

.footer-eye-camp__history-detail__opinions__header img {
  width: 60px;
  height: auto;
  border-radius: 100%;
}

.footer-eye-camp__history-detail__opinions__header div h4, .footer-eye-camp__history-detail__opinions__header div p {
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  color: black;
}

.footer-eye-camp__history-detail__opinions__header div h4 {
  font-weight: 700 !important;
  font-size: 17px;
  line-height: 1.5;
}

.footer-eye-camp__history-detail__opinions__header div p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 11px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700 !important;
  margin: 0;
}

.footer-eye-camp__history-detail__opinions__header div p img {
  width: 24px;
  height: 16.79px;
  margin-right: 7px;
  border: 1px solid #e3e3e3;
  border-radius: 0;
}

.footer-eye-camp__history-detail__opinions__comment {
  color: black;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 12px;
  font-weight: normal !important;
  letter-spacing: 1px;
  background-color: white;
  line-height: 2;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  margin: 0;
}

.footer-eye-camp__history-detail__opinions__comment::before {
  content: "";
  position: absolute;
  left: 21px;
  top: -11px;
  display: block;
  width: 0;
  height: 0;
  border-right: 9px solid transparent;
  border-bottom: 13px solid white;
  border-left: 9px solid transparent;
}

@media (min-width: 768px) {
  .footer-eye-camp__history-detail__opinions {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .footer-eye-camp__history-detail__opinions {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.footer-eye-camp__history-detail__custom-slider .slick-slide {
  padding: 0 2.5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.footer-eye-camp__history-detail__custom-slider .slick-list {
  margin: 0 -2.5px;
}

.footer-eye-camp__history-detail__custom-slider .slick-dots {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  list-style-type: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px 0 20px 0;
}

.footer-eye-camp__history-detail__custom-slider .slick-dots li {
  width: 10px;
  margin: 5px 7px;
}

.footer-eye-camp__history-detail__custom-slider .slick-dots li button {
  width: 10px;
  height: 10px;
  background: #D6D6D6;
  display: block;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  border-radius: 0;
  font-size: 0;
  color: transparent;
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.footer-eye-camp__history-detail__custom-slider .slick-dots li button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.footer-eye-camp__history-detail__custom-slider .slick-dots li.slick-active button {
  background: #999;
}

.footer-eye-camp__history-detail__iframe-container {
  width: 100%;
  height: auto;
  display: inline-block;
  aspect-ratio: 16/9;
  margin-top: 20px;
}

.footer-eye-camp__history-detail__iframe-container iframe {
  margin: 0;
}

@media (min-width: 768px) {
  .footer-eye-camp__history-detail__iframe-container {
    margin-top: 35px;
  }
}
