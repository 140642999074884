$base-img-url: "https://static.lenskart.com/owndays/img/huawei2-titanium";

$font-biz: "BIZ UDPGothic", serif;
$font-advent-pro: "Advent Pro", sans-serif;
$font-sf: "SFCompactDisplay", sans-serif;

$white-f2: #F2F2F2;
$white-f7: #F7F7F7;
$gray-53: #535353;
$gray-7c: #7C7C7C;
$gray-99: #999999;
$yellow-b4: #b47e00;
$brown-8b: #8B6554;
$red-ad: #AD0824;

.body-huawei-2-titanium {
  &__lineup {
    padding: 70px 0 91.75px 0;
    background-color: $white-f7;

    @media (min-width: 1024px) {
      padding: 120px 0;
    }

    &__title {
      text-align: center;
      font-family: $font-biz;
      font-size: 32px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 51.2px;
      letter-spacing: 0.32px;
      color: black;
      margin-bottom: 0;

      @media (min-width: 1024px) {
        font-size: 66px;
        line-height: 105.6px;
        letter-spacing: 0.66px;
      }
    }

    &__title-img, &__title-text {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: black;
        z-index: -1;
      }

      span {
        display: inline-flex;
        padding-inline: 25px;
        background-color: $white-f7;
      }
    }

    &__title-img {
      font: 0/0 a;
      margin: 45px 0 0 0;

      span {
        img {
          width: 132px;
          height: auto;
        }
      }

      @media (min-width: 1024px) {
        margin: 60px 0 0 0;

        span {
          img {
            width: 164.408px;
          }
        }
      }
    }

    &__title-text {
      color: black;
      text-align: center;
      font-family: $font-biz;
      font-size: 12px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 19.2px;
      letter-spacing: 0.12px;
      margin: 60px 0 0 0;

      @media (min-width: 1024px) {
        font-size: 14px;
        font-weight: 700 !important;
        line-height: 22.4px;
        letter-spacing: 0.14px;
        margin: 100px 0 0 0;
      }
    }

    &__section {
      margin-top: 60px;

      @media (min-width: 1024px) {
        margin-top: 100px;
      }
    }

    &__desc {
      display: flex;
      flex-direction: column;
      gap: 15px;
    
      h3 {
        color: $white-f7;
        text-shadow: 1px 0px 0px black, -1px 0px 0px black, 0px 1px 0 black, 0px -1px 0 black;
        font-family: $font-advent-pro;
        font-size: 48px;
        font-weight: 700 !important;
        line-height: 67.2px;
        margin-bottom: 0;
      }

      p {
        font-family: $font-biz;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 28px;
        letter-spacing: 0.14px;
        margin-bottom: 0;
        color: $gray-53;
      }

      @media (min-width: 1024px) {
        width: 100%;
        height: auto;
        max-width: 900px;
        margin: 0 auto;

        h3 {
          font-size: 80px;
          line-height: 112px;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 32px;
          letter-spacing: 0.16px;
        }
      }
    }

    &__product {
      &-container {
        margin-top: 25px;

        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: calc(100% - 199px) 199px;
          column-gap: 10px;
          justify-content: end; 
          align-items: center;
        }

        @media (min-width: 1024px) {
          grid-template-columns: calc(100% - 258px) 258px;
          margin-top: 40px;
          column-gap: 30px;
        }

        @media (min-width: 1296px) {
          grid-template-columns: calc(100% - 280.28px) 280.28px;
          column-gap: 50px;
        }

        @media (min-width: 1440px) {
          grid-template-columns: calc(100% - 310px) 310px;
        }
      }

      &-layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 18.32px;

        @media (min-width: 768px) {
          flex-direction: column;
          margin-top: 18px;
          align-items: flex-start;
        }
      }

      &-main {
        display: flex;
        align-items: center;
        gap: 7px;

        p {
          margin-bottom: 0;
          color: black;
          font-style: normal;
          font-weight: 400 !important;

          &:nth-child(1) {
            font-family: $font-biz;
            font-size: 12px;
            line-height: 21.6px;
          }

          &:nth-child(2) {
            font-family: $font-sf;
            font-size: 13px;
            line-height: 23.4px;
          }
        }

        @media (min-width: 768px) {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 15px;
        }

        @media (min-width: 1024px) {
          p {
            font-weight: 700 !important;
  
            &:nth-child(1) {
              font-size: 14px;
              line-height: 25.2px;
              letter-spacing: 0.14px;
            }
  
            &:nth-child(2) {
              font-family: $font-biz;
              font-size: 16px;
              line-height: 28.8px;
              letter-spacing: 0.16px;
            }
          }
        }
      }

      &-tag {
        width: 57px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: $yellow-b4;
        font-size: 14px;
        line-height: 22.4px;
        font-family: $font-biz;
        font-weight: 700 !important;
        letter-spacing: 0.28px;
        border: 1px solid $yellow-b4;
        margin-bottom: 0;
      }

      &-number {
        color: $gray-7c;
        font-family: $font-sf;
        font-size: 20px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 36px;
        margin-bottom: 0;

        @media (min-width: 1024px) {
          font-size: 26px;
          font-weight: 700 !important;
          line-height: 46.8px;
          letter-spacing: 0.26px;
        }
      }

      &-color-and-arrows {
        @media (min-width: 768px) {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
        }
      }

      &-colors {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        li {
          width: 38px;
          height: 38px;

          @media (min-width: 1024px) {
            width: 44px;
            height: 44px;
          }

          button {
            width: 100%;
            height: 100%;
            background-color: transparent;
            background-clip: content-box;
            border-width: 1px;
            border-style: solid;
            border-color: transparent;
            border-image: initial;
            outline: none;
            border-radius: 10rem;
            padding: 6px;

            &[data-color="dark-gun"], &[data-color="black"] {
              background-color: black;
            }

            &[data-color="silver"], &[data-color="clear-grey"] {
              background-color: $gray-99;
            }

            &[data-color="clear-brown"] {
              background-color: $brown-8b;
            }

            &[data-color-selected="true"] {
              border-color: $gray-99;
            }
          }
        }
      }

      &-btn {
        margin-top: 25.5px;

        @media (min-width: 768px) {
          margin-top: 40px;
        }
      }

      &-snap {
        &__layout {
          width: calc(100% + 40px);
          margin: 60px -23px 0 -23px;
          height: auto;
          background-color: $white-f2;
          padding: 0 23px;
          position: relative;

          &::before {
            position: absolute;
            content: '';
            width: 73px;
            height: 73px;
            top: -36.5px;
            right: 37px;
            background: url("#{$base-img-url}/plus-icon.svg");
            background-repeat: no-repeat;
            background-size: 100%;
          }

          &--sp {
            display: block;
          }

          &--pc {
            display: none;
          }

          @media (min-width: 768px) {
            &--sp {
              display: none;
            }
  
            &--pc {
              display: block;
              grid-column: span 2;
              width: 100%;
              max-width: 800px;
              height: auto;
              margin: 56px auto 0 auto;
              padding: 0 14px 0 40px;
            }
          }
        }

        &__content {
          display: grid;
          grid-template-columns: 130px 1fr;
          column-gap: 20px;
          align-items: center;
          padding: 36px 0 49px 20px;

          @media (min-width: 768px) {
            padding: 35px 0 20px 0;
            grid-template-columns: 213px 1fr;
            column-gap: 40px;
          }
        }

        &__main {
          p {
            margin-bottom: 0;
            white-space: nowrap;
          }
          
          &-lens {
            color: $red-ad;
            font-family: $font-biz;
            font-size: 11px;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 19.8px;
          }

          &-name {
            color: $gray-7c;
            font-family: $font-sf;
            font-size: 14px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 25.2px;
          }

          &-price {
            color: black;
            font-family: $font-sf;
            font-size: 14px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 25.2px;
          }

          @media (min-width: 768px) {
            p:nth-child(1) {
              white-space: unset;
            }

            &-lens {
              font-size: 14px;
              line-height: 25.2px;
              letter-spacing: 0.14px;
              margin-top: 12px;
            }

            &-name, &-price {
              font-size: 16px;
              line-height: 28.8px;
            }
          }
        }

        &__desc {
          margin-bottom: 0;

          &--sp {
            display: block;
            grid-column: span 2;
            margin-top: 14px;
            color: $gray-53;
            font-family: $font-biz;
            font-size: 13px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 23.4px;
          }

          &--pc {
            display: none;
          }

          @media (min-width: 768px) {
            &--sp {
              display: none;
            }

            &--pc {
              display: block;
              color: $gray-53;
              font-family: $gray-53;
              font-size: 16px;
              font-style: normal;
              font-weight: 700 !important;
              line-height: 28.8px;
              letter-spacing: 0.16px;
            }
          }
        }
      }
    }

    &__slider {
      width: calc(100% + 46px);
      margin: 0 -23px;
      height: auto;

      > .slick {
        &-list {
          margin: 0 -6.5px;
          max-height: 190px;

          .slick {
            &-slide {
              padding: 0 6.5px;
              max-width: 303.874px;
              aspect-ratio: 303.874/190;
            }
          }

          @media (min-width: 430px) {
            max-height: none;
          }

          @media (min-width: 768px) {
            margin: 0;
            padding: 0px 0 0 40% !important;

            .slick {
              &-slide {
                padding: 0 8.5px;
              }
            }
          }

          @media (min-width: 1024px) {
            justify-content: flex-end;
            padding: 0px 0 0 37% !important;

            .slick {
              &-slide {
                width: 424px;
                max-width: 559.768px;
                aspect-ratio: 559.768/350;
              }
            }
          }

          @media (min-width: 1296px) {
            padding: 0px 0 0 33.7% !important;

            .slick {
              &-slide {
                width: 577px;
                max-width: none;
                max-width: 560/350;
              }
            }
          }
        }
      }

      @media (min-width: 768px) {
        width: calc(100% + 500px);
        margin: 0 0 0 -500px;
      }

      @media (min-width: 1140px) {
        width: calc(100% + 500px);
        margin: 0 0 0 -500px;
      }
    }

    &__arrows {
      display: flex;
      align-items: center;
      gap: 4px;

      @media (max-width: 767px) {
        display: none;
      }

      &-prev, &-next {
        width: 54px;
        height: 50px;
        background-color: $gray-7c;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 21px 23px;
        border: none;
        outline: none;
        transition: background-color 1s;

        @media (min-width: 1024px) {
          width: 68px;
          cursor: pointer;

          &:hover {
            transition: 0.5s;
            background-color: black;
          }
        }
      }

      &-prev {
        background-image: url("#{$base-img-url}/arrow-left-icon.svg");
        border-radius: 10px 0 0 10px;
      }

      &-next {
        background-image: url("#{$base-img-url}/arrow-right-icon.svg");
        border-radius: 0 10px 10px 0;
      }
    }
  }
}
