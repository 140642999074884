.full-widget-width {
  width: calc(100% - 255px);
}

.blankwidgets {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 15px;
}

.blankwidgets_box {
  border: 1px solid #ddd;
}

.blankwidgets_box_animatedBackground {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

.blankwidgets_box_animatedBackground_header {
  height: 20px;
}

.blankwidgets_box_animatedBackground_image {
  height: 200px;
}

.blankwidgets_box_headerWrap {
  width: 80%;
  margin: 3px;
}

.blankwidgets_box_imageWrap {
  margin: 3px 3px 10px 3px;
}

.blankwidgets_box_footerWrap {
  margin: 3px;
}

.blankwidgets_box_footerWrap_left {
  width: 70%;
  display: inline-block;
}

.blankwidgets_box_footerWrap_right {
  float: right;
  width: 30%;
}

.blankwidgets_box_footerWrap_right div,
.blankwidgets_box_footerWrap_left div {
  margin-bottom: 6px;
  height: 15px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
