$font-base: 'Founders Grotesk', sans-serif;
$black: #000;
$white: #fff;
$light: #dee2e6;
$blue: #2e5aa8;
$grey: #999;

.bundle-sale {
  width: 100%;
  max-width: 1020px;
  padding: 35px 20px;
  margin-left: auto;
  margin-right: auto;
  font-family: $font-base;
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 1px;
  &__title {
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 1.6;
    letter-spacing: 2px;
    padding: 15px 0;
    margin: 0 0 10px;
  }
  &__thumbnail {
    width: 100%;
    margin-bottom: 20px;
    aspect-ratio: 5/2;
  }
  &__container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  &__text {
    margin-bottom: 25px;
    strong {
      font-size: 17px;
      font-weight: 600;
    }
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 700 !important;
    letter-spacing: 2px;
    line-height: 1.6;
    margin: 70px 0 35px;
    position: relative;
    &::before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      top: -2px;
      left: -20px;
      background-color: $black;
    }
  }
  &__highlights {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 0 24px;
    &-li {
      border: 1px solid $light;
      padding: 24px;
      display: grid;
      grid-gap: 16px;
    }
    &-img {
      aspect-ratio: 1/1;
      width: 100%;
    }
    &-details {
      h3 {
        font-size: 15px;
        font-weight: 700 !important;
        line-height: 1.5;
        margin: 0 0 24px;
      }
      h4 {
        font-size: 15px;
        font-weight: 400;
        line-height: 2;
        margin: 0;
      }
      ul{
        list-style: none;
        padding-inline-start: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        li{
          display: flex;
          flex-wrap: wrap;
          column-gap: .5em;
          strong{
            font-family: $font-base;
            font-weight: 600;
          }
          a {
            color: $blue;
            transition: all 0.5s ease;
            &:hover {
              color: $grey;
              text-decoration: none;
            }
          }
        }
      }
    }
    &-terms{
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      font-weight: 600;
      line-height: 1;
      color: $blue;
      display: inline-flex;
      align-items: center;
      column-gap: .25em;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        border-bottom: 1px solid currentColor;
      }
      img{
        width: 15px;
        height: 15px;
        object-fit: contain;
      }
    }
  }
  &__modal{
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($black,.2);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding: 20px;
    }
    &-content {
      background: $white;
      color: $black;
      padding: 57px 27px;
      width: 100%;
      max-width: 960px;
      max-height: 90vh;
      position: relative;
      font-size: 14px;
      letter-spacing: 1px;
      overflow-y: auto;
    }
    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
    }
    &-title{
      text-align: center;
      font-weight: 500 !important;
      font-size: 20px;
      line-height: 1;
      letter-spacing: .02em;
      margin: 0 0 27px;
    }
    &-subtitle{
      font-weight: 700;
      text-decoration: underline;
      margin: 0;
    }
    &-terms{
      list-style: none;
      padding-inline-start: 0;
      line-height: 2;
      margin-bottom: 0;
      li{
        position: relative;
        padding-left: 20px;
        &::before{
          content: '';
          position: absolute;
          top: 12px;
          left: 4px;
          width: 4px;
          height: 4px;
          background-color: $black;
          border-radius: 100%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__modal{
      &-content{
        padding-bottom: 200px;
      }
    }
  }
  @media (min-width: 768px) {
    &__highlights {
      padding: 0;
      &-li {
        grid-template-columns: 1fr 50%;
        align-items: center;
      }
    }
  }
  @media (min-width: 1024px) {
    padding-top: 80px;
    padding-bottom: 80px;
    &__title {
      font-size: 34px;
      font-weight: 400 !important;
      padding: 10px 0 30px;
      margin-bottom: 30px;
    }
    &__subtitle {
      font-size: 26px;
      line-height: 1.6;
      &::before {
        width: 6px;
      }
    }
    &__highlights {
      row-gap: 48px;
      &-li {
        padding: 48px;
        grid-gap: 0 48px;
      }
    }
    &__modal{
      &-close{
        width: 38px;
        height: 38px;
        top: 35px;
        right: 35px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-content{
        padding: 79px 102px 111px;
        font-size: 15px;
      }
      &-title{
        font-size: 22px;
        margin-bottom: 60px;
      }
    }
  }
}