.base-eye-camp__container--inner {
  padding-top: 20px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .base-eye-camp__container--inner {
    width: 80%;
    height: auto;
    max-width: 1152px;
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 100px;
  }
}

.base-eye-camp__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -7.5px;
}

.base-eye-camp__col {
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media (min-width: 768px) {
  .base-eye-camp__col {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

.base-eye-camp__hide--pc {
  display: block;
}

@media (min-width: 768px) {
  .base-eye-camp__hide--pc {
    display: none;
  }
}

.base-eye-camp__hide--sp {
  display: none;
}

@media (min-width: 768px) {
  .base-eye-camp__hide--sp {
    display: block;
  }
}

.base-eye-camp__bg {
  max-width: 3840px;
}

.base-eye-camp__bg::before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, .4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.base-eye-camp__bg picture {
  margin: 0 auto;
}

.base-eye-camp__bg picture img {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: unset;
}

.base-eye-camp__title {
  width: 100%;
  max-width: 762px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  background-color: transparent;
  padding: 0 10%;
  text-align: center;
  color: white;
  z-index: 2;
}

.base-eye-camp__title h1 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 40px;
  font-weight: 400 !important;
  line-height: 1;
  margin: 0;
}

.base-eye-camp__title h1 span {
  display: block;
  font-size: 13px;
  margin-top: 5px;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .base-eye-camp__title {
    width: 80%;
    max-width: 1152px;
    margin: 0 auto;
  }
  .base-eye-camp__title h1 span {
    margin-top: 20px;
  }
}
