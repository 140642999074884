$color-primary: #2e5aa8;
$color-text: #413d3d;
$color-light: #f7f9fc;
$color-white: #fff;

$font-nato: 'Noto Sans', sans-serif;
$font-din: din-2014, sans-serif;

$pd: 27px;
$container-max-w: calc(1120px + #{$pd * 2});
$container--sub-max-w: 924px;

$base-img-url: 'https://static.lenskart.com/media/owndays/img/ortho-k-lens';

.base-ortho-k-lens {
  font-family: $font-nato;
  font-weight: 400 !important;
  color: $color-text;
  letter-spacing: 0.02em;
  line-height: 2.2;

  @media (max-width: 430px) {
    overflow-x: hidden;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    max-width: calc(1120px + 220px);

    @media (max-width: 1450px) {
      max-width: calc(1120px + 100px);
    }

    @media (max-width: 1295px) {
      padding-right: $pd;
      padding-left: $pd;
      max-width: $container-max-w;
    }

    @media (max-width: 1176px) {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      padding-right: $pd;
      padding-left: $pd;
      max-width: $container-max-w;
    }

    &--sub {
      max-width: $container--sub-max-w;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    font: 700 28px/1.2 $font-din;
    font-weight: 700 !important;
    color: $color-text;
    letter-spacing: 0.05em;
    margin: 0;

    &--center {
      text-align: center;
    }

    @media (min-width: 768px) {
      font-size: 40px;
    }

    @media (min-width: 1024px) {
      font-size: 51px;
    }
  }

  &__button {
    font: 700 12px/1.4 $font-nato;
    letter-spacing: 0.02em;
    color: $color-primary;
    height: 75px;
    border: 3px solid $color-primary;
    border-radius: 4em;
    padding: 0 16px 0 20px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 5px;
    width: 100%;
    max-width: 418px;
    background-color: $color-light;
    position: relative;
  
    &::before {
      content: '';
      position: absolute;
      right: 16px;
      width: 43px;
      height: 43px;
      background-color: $color-primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }

    &--white {
      background-color: $color-white;
    }

    &--down, &--right {
      position: relative;
    
      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 8px);
        right: calc(16px + 43px/2 - 8px);
        width: 15px;
        height: 15px;
        background-image: url("#{$base-img-url}/chevron-down-solid-white.svg");
        background-size: cover;
      }
    }
    
    &--right {
      &::after {
        transform: rotate(270deg);
      }
    }

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    @media (min-width: 768px) {
      font-size: 15px;
    }

    @media (min-width: 1024px) {
      padding: 0 16px 0 32px;

      &:hover {
        color: $color-white;
        background-color: $color-primary;
        transition: 0.5s;
        
        &::before {
          background-color: $color-white;
        }
  
        &::after {
          background-image: url("#{$base-img-url}/chevron-down-solid-blue.svg");
        }
      }
    }
  }

  &__note {
    font-size: 11px;
    line-height: 1.8;
    display: flex;
    margin: 0;

    &::before {
      content: "*";
      margin-right: 8px;
    }
  }
}
