$font-heisei: "heisei-maru-gothic-std", sans-serif;
$font-corporate: "Corporate Logo Rounded ver2", sans-serif;
$font-fredoka: "Fredoka", sans-serif;

$yellow-ffc: #ffcb14;
$yellow-ffe: #ffe57a;
$yellow-fff: #fff9b1;
$brown-76: #76332e;
$brown-ee: #eedabe;

$base-img-url: 'https://static.lenskart.com/media/owndays/img/pompompurin';

@keyframes pompomFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pompomLoading {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.body-pompompurin {
  &__models {
    &__item {
      padding-block: 69px 217px;
      background-color: $yellow-ffe;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -114px;
        left: 0;
        right: 0;
        height: 185px;
        background: url("#{$base-img-url}/wave-1.svg") no-repeat center top/cover;
        z-index: 1;
      }

      .base-pompompurin__container {
        position: relative;
        z-index: 2;
        max-width: 1196px;
      }

      .body-pompompurin__models__feature-list, .body-pompompurin__models__products-slider {
        >.slick {
          &-dots {
            margin-top: 14px;
            padding: 0;
            list-style-type: none;
            display: flex !important;
            justify-content: center;
      
            li > button {
              border: 0;
              color: transparent;
            }
      
            li {
              width: 24px;
              height: 19px;
              margin: 0 6px;
      
              button {
                width: 100%;
                height: 100%;
                padding: 0;
                background: url("#{$base-img-url}/footprint.svg") no-repeat center/contain;
                opacity: 0.25;
                &::before {
                  display: none;
                }
              }
      
              &.slick-active {
                button {
                  opacity: 1;
                }
              }
            }
      
            @media (min-width: 768px) {
              margin-top: 32px;
              
              li {
                width: 37px;
                height: 30px;
                margin: 0 9px;
              }
            }
          }
        }
      }

      &:nth-child(even) {
        background-color: $yellow-fff;
        &::before {
          background-image: url("#{$base-img-url}/wave-2.svg");
        }
      }

      &:nth-child(5) {
        padding-bottom: 0;
      }
    }

    &__title {
      display: flex;
      flex-direction: column;
      gap: 6px;
      font-family: $font-fredoka !important;
      font-weight: 700 !important;
      font-size: 30px;
      line-height: 1.25;
      letter-spacing: 3px;
      color: white;
      -webkit-text-stroke-width: 6px;
      -webkit-text-stroke-color: $brown-76;
      paint-order: stroke;
      margin-bottom: 30px;

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $brown-76;
        color: white;
        padding: 3px 10px 5px;
        font-family: $font-fredoka !important;
        font-weight: 700 !important;
        font-size: 29px;
        line-height: 1;
        letter-spacing: 0.66px;
        white-space: nowrap;

        @media (max-width: 767px) {
          padding: 9px 10px;
        }
      }
    }

    &__char{
      &--1, &--2, &--3, &--4, &--5 {
        display: block;
        width: 100%;
      }

      &--1 {
        img{
          aspect-ratio: 196/197;
        }
      }
      &--2 {
        img{
          aspect-ratio: 124/113;
        }
      }
      &--3 {
        img{
          aspect-ratio: 616/613;
        }
      }
      &--4 {
        img{
          aspect-ratio: 604/581;
        }
      }
      &--5 {
        img{
          aspect-ratio: 620/633;
        }
      }
    }

    &__content {
      display: grid;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 23px;

        h3, h4 {
          font-family: $font-corporate !important;
          margin-bottom: 0;
        }

        h3 {
          font: 700 20px/22px $font-corporate;
          padding-bottom: 7px;
          border-bottom: 3px solid currentColor;
        }

        h4 {
          font: 700 20px/33px $font-corporate;
          letter-spacing: 0.2px;
          small {
            font-family: $font-heisei !important;
            font: 800 12px/1 $font-heisei;
            letter-spacing: 0.72px;
            margin-left: 5px;
          }
        }
      }

      &-product{
        display: block;
        
        img{
          aspect-ratio: 221/160;
        }
      }

      &-text {
        font-size: 13px;
        font-weight: 800 !important;
        letter-spacing: 0px;
        margin-bottom: 30px;
      }
    }

    &__feature {
      margin-block: 70px 80px;

      &-item {
        padding-inline: 5px;
      }

      &-title {
        text-align: center;
        font-family: $font-fredoka !important;
        font-weight: 700 !important;
        font-size: 26px;
        line-height: 1.25;
        letter-spacing: 2.08px;
        margin-bottom: 35px;
      }

      &-content {
        display: flex;
        flex-direction: column;
        background-color: $brown-76;
        color: white;
        position: relative;
        border-radius: 100rem;
        aspect-ratio: 250/333;

        span {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 67px;
          height: 47px;
          font: 700 20px/1 $font-fredoka;
          letter-spacing: 0.4px;
          color: $brown-76;
          padding-left: 5px;
          background: url("#{$base-img-url}/pudding.svg") no-repeat center/contain;
        }

        img{
          aspect-ratio: 31/27;
        }

        p {
          display: grid;
          width: 171px;
          font-size: 11px;
          font-weight: 800 !important;
          line-height: 20px;
          text-align: center;
          flex-grow: 0.75;
          align-content: center;
          margin: 0 auto;
          letter-spacing: 0;
        }
      }
    }

    &__products {
      &-colors {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 11px;
        margin-bottom: 4px;
        margin-inline: 4%;
       
        @media (max-width: 767px) {
          margin-inline: 10%;
        }

        @media (min-width: 768px) {
          margin-inline: 0;
        }

        @media (min-width: 1024px) {
          margin-inline: 4%;
        }

        p {
          font: 700 16px/1.25 $font-fredoka;
          letter-spacing: 1.28px;
          margin-bottom: 0;
        }

        ul {
          display: flex;
          align-items: center;
          gap: 4px;
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-radius: 10rem;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;

            span {
              display: inline-flex;
              width: 14px;
              height: 14px;
              border-radius: 10rem;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }

            &.active {
              border-color: black;
            }
          }

          li[data-product="#product-SR1007B-4A-C1"] {
            span {
              background-color: rgb(134, 89, 40);
            }
          }

          li[data-product="#product-SR1007B-4A-C2"] {
            span {
              background-color: rgb(252, 154, 192);
            }
          }

          li[data-product="#product-SR1008B-4A-C1"] {
            span {
              background-color: rgb(134, 89, 40);;
            }
          }

          li[data-product="#product-SR1008B-4A-C2"] {
            span {
              background-color: rgb(158, 122, 83);
            }
          }
          
          li[data-product="#product-SR2003J-4A-C1"] {
            span {
              background-color: rgb(250, 245, 214);
            }
          }

          li[data-product="#product-SR2003J-4A-C2"] {
            span {
              background-color: rgb(236, 213, 193);
            }
          }
          
          li[data-product="#product-SR2004N-4A-C1"] {
            span {
              background-color: rgb(134, 89, 40);
            }
          }

          li[data-product="#product-SR2004N-4A-C2"] {
            span {
              background-image: url("#{$base-img-url}/color-1.webp");
            }
          }

          li[data-product="#product-SR2005N-4A-C1"] {
            span {
              background-image: url("#{$base-img-url}/color-2.webp");
            }
          }

          li[data-product="#product-SR2005N-4A-C2"] {
            span {
              background-color: rgb(206, 179, 156);
            }
          }
        }
      }

      &-slider {
        width: 100%;

        > .slick {
          &-list {
            overflow: visible;

            .slick-slide {
              padding-inline: 4px;
              padding-bottom: 8px;

              &.slick-current {
                picture {
                  transform: translateY(8px);
                }
              }
              
            }
          }
          
          &-arrow {
            position: absolute;
            top: 34%;
            width: 40px;
            height: 40px;
            z-index: 1;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            &::before {
              display: none;
            }

            @media (min-width: 768px) {
              top: 38%;
            }

            @media (min-width: 1025px) {
              display: none !important;
            }
          }

          &-prev, &-next {
            color: transparent;
            background-color: transparent;
            border: 0;
          }

          &-prev {
            left: 20px;
            background-image: url("#{$base-img-url}/slick-prev.svg");
          }
          &-next {
            right: 20px;
            background-image: url("#{$base-img-url}/slick-next.svg");
          }
        }

        picture {
          background-color: white;
          border-radius: 20px;
          border: 3px solid $brown-76;
          padding: 14px 12px;
          transition: all 0.3s ease;

          img {
            display: block;
            width: 100%;
            height: auto;
            aspect-ratio: 591/373;
          }
        }
      }

      .base-pompompurin__btn {
        margin-top: 45px;
      }
    }

    &__bottom {
      background-color: $yellow-ffe;
      padding: 88px 20px 25px;

      @media (max-width: 767px) {
        padding: 38px 20px 18px;
      }
    }

    &__note {
      text-align: center;
      font-size: 10px;
      font-weight: 800;
      line-height: 17px;
      letter-spacing: 0.2px;
      font-family: $font-heisei;
      margin-bottom: 0;
    }

    @media (max-width: 1023px) {
      &__top,
      &__content {
        max-width: 425px;
        margin-inline: auto;
      }

      &__title {
        align-items: center;
        -webkit-text-stroke-width: 1.5px;
      }

      &__char {
        padding-inline: 20px;
        margin-bottom: 40px;
        margin-top: -8px;
      }

      &__feature {
        margin-inline: -20px;
      }
    }

    @media (min-width: 768px) {
      &__products{
        position: relative;
        &-slider picture img{
          max-width: 577px;
        }

        &-colors {
          gap: 24px;
          position: absolute;
          top: 490px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;

          p {
            font-size: 36px;
            letter-spacing: 2.88px;
          }

          ul {
            gap: 9px;

            li {
              width: 43px;
              height: 43px;

              span {
                width: 31px;
                height: 31px;
              }
            }
          }
        }

        .base-pompompurin__btn{
          margin-top: 90px;
        }
      }
    }

    @media (min-width: 1024px) {
      &__item {
        padding-bottom: 509px;

        &::before {
          top: -504px;
          left: -175px;
          right: -175px;
          height: 642px;
        }

        .base-pompompurin__container {
          position: relative;
        }

        &:nth-of-type(odd) {
          .body-pompompurin__models__content {
            &-header,
            &-text {
              margin-left: auto;
            }

            &-header {
              position: relative;

              &::before {
                content: "";
                position: absolute;
                width: 100%;
                max-width: 175px;
                height: auto;
                aspect-ratio: 57/85;
                background: url("#{$base-img-url}/line-dot.svg") no-repeat center right / contain;
                z-index: 1;
                left: 95%;
                top: -167px;
                left: -102px;
              }
            }
          }
        }

        &:nth-of-type(even) {
          .body-pompompurin__models {
            &__title {
              width: fit-content;
              margin-left: auto;
            }

            &__char {
              left: 20px;
              right: auto;
            }

            &__content {
              &-product {
                margin-left: auto;
              }

              &-header {
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  max-width: 175px;
                  height: auto;
                  aspect-ratio: 57/85;
                  background: url("#{$base-img-url}/line-dot-2.svg") no-repeat center left / contain;
                  z-index: 1;
                  top: -167px;
                  right: -102px;
                }
              }
            }
          }
        }

        &:nth-child(2) {
          .body-pompompurin__models__title {
            margin-left: calc(100% - 541px);
          }
        }

        &:nth-child(4) {
          .body-pompompurin__models__title {
            margin-left: calc(100% - 465px);
          }
        }

        @media (max-width: 1125px) {
          .body-pompompurin__models__content-product::before {
            display: none;
          }
        }
        
      }

      &__top{
        position: relative;
      }

      &__title {
        align-items: flex-start;
        gap: 16px;
        font-size: 67px;
        letter-spacing: 6.695px;
        margin-bottom: 150px;

        span {
          font-size: 58px;
          letter-spacing: 1.32px;
          padding: 11px 20px;
        }
      }

      &__char {
        position: absolute;
        top: 0;
        right: 20px;

        img {
          width: 100%;
        }

        &--1{
          max-width: 481px;
          top: -107px;
        }

        &--2{
          max-width: 429px;
          top: -127px;
        }

        &--3{
          max-width: 502px;
          top: -210px;
        }

        &--4{
          max-width: 467px;
          top: -140px;
        }

        &--5{
          max-width: 528px;
          top: -138px;
        }
      }

      &__content {
        display: block;
        margin-bottom: 154px;
        position: relative;

        &-product {
          max-width: calc(45% + 35px);
        }

        &-header,
        &-text {
          max-width: calc(50% - 25px);
        }

        &-header {
          h3 {
            font-size: 26px;
            line-height: 32.8px;
            letter-spacing: 0.26px;
            padding-bottom: 9px;
            border-bottom-width: 5px;
          }
          h4 {
            font-size: 25px;
            letter-spacing: 0.25px;
          }
        }

        &-text {
          font-size: 18px;
          line-height: 33px;
          letter-spacing: 0.16px;
          margin-bottom: 0;
        }

        .base-pompompurin__container {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      &__feature {
        margin-bottom: 120px;

        &-title {
          font-size: 36px;
        }
        &-list {
          margin-inline: -33px;
        }
        &-item {
          padding-inline: 33px;
        }

        &-content {
          p {
            width: 277px;
            font-size: 15px;
            line-height: 25px;
          }
        }
      }

      &__products {
        max-width: 1440px;
        margin: 0 auto;
        
        &-colors {
          left: 0;
          transform: translateX(0);
        }

        &-slider {

          > .slick-list {
            .slick-slide {
              padding-inline: 8px;
              padding-bottom: 18px;
  
              &.slick-current {
                picture {
                  transform: translateY(18px);
                }
              }
            }
          }
          
          picture {
            padding: 28px 25px;
            border-width: 5px;
            border-radius: 30px;
            img {
              max-width: 591px;
            }
          }
        }
      }
    }

    @media (min-width: 1296px) {
      &__char {
        right: 0;

        img {
          width: 100%;
        }

        &--1{
          max-width: 581px;
          top: -107px;
        }

        &--2{
          max-width: 597px;
          top: -127px;
        }

        &--3{
          max-width: 602px;
          top: -210px;
        }

        &--4{
          max-width: 567px;
          top: -140px;
        }

        &--5{
          max-width: 628px;
          top: -138px;
        }
      }

      &__item {
        padding-bottom: 509px;

        &::before {
          top: -504px;
          left: -175px;
          right: -175px;
          height: 642px;
        }

        &:nth-of-type(odd) {
          .body-pompompurin__models__content {
            &-header,
            &-text {
              margin-left: auto;
            }
          }
        }

        &:nth-of-type(even) {
          .body-pompompurin__models {
            &__title {
              width: fit-content;
              margin-left: auto;
            }

            &__char {
              left: 20px;
              right: auto;
            }
          }
        }

        &:nth-child(2) {
          .body-pompompurin__models__title {
            margin-left: calc(100% - 506px);
          }
        }

        &:nth-child(3) {
          .body-pompompurin__models__content-product::before {
            top: 44%;
          }
        }

        &:nth-child(4) {
          .body-pompompurin__models__title {
            margin-left: calc(100% - 465px);
          }
        }
        
      }
    }
  }

  &__case {
    padding-block: 112px 164px;
    background-color: $brown-76;

    &__content {
      background-color: white;
      border-radius: 20px;
      padding: 70px 20px 50px;
      position: relative;
    }

    &__fg {
      aspect-ratio: 76/51;
      max-width: 159px;
      position: absolute;
      top: -72px;
      left: calc(50% - 63px);
    }

    &__heading {
      font-family: $font-fredoka !important;
      font-weight: 700 !important;
      font-size: 23px;
      line-height: 30px;
      letter-spacing: 1.84px;
      margin: 0;
    }

    &__img {
      aspect-ratio: 253/189;
    }

    &__text {
      width: 100%;
      max-width: 495px;
      height: auto;
      font-size: 14px;
      font-weight: 800 !important;
      line-height: 31px;
      letter-spacing: 0.28px;
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      &__heading {
        text-align: center;
      }

      &__img {
        margin-bottom: 5px;
      }
    }

    @media (min-width: 768px) {
      &__content {
        display: grid;
        grid-template-columns: 1fr 50%;
        align-items: center;
        gap: 20px 47px;
      }
      &__fg {
        top: auto;
        left: -57px;
        bottom: -59px;
      }
      &__heading {
        align-self: flex-end;
      }
      &__img {
        grid-row: 1 / span 2;
        grid-column: 2;
      }
      &__text {
        align-self: flex-start;
      }
    }

    @media (min-width: 1024px) {
      padding-block: 120px 288px;
      &__content {
        padding: 25px 60px 60px;
      }
      &__fg {
        max-width: 228px;
      }
      &__heading {
        font-size: calc(37px / 1.25);
        line-height: normal;
        letter-spacing: calc(2.974px / 1.25);
      }
    }

    @media (min-width: 1296px) {
      &__heading {
        font-size: calc(37px);
        letter-spacing: calc(2.974px);
      }
      &__text {
        width: 495px;
      }
    }
  }

  &__accessories {
    margin-top: -1px;
    padding-bottom: 18px;
    z-index: 1;
    background-color: $brown-76;

    &::before {
      content: "";
      position: absolute;
      inset: calc((671px - 89px) / 2) 0 0;
      background-color: $yellow-fff;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      top: -89px;
      left: -115px;
      right: -115px;
      height: 671px;
      background-color: $yellow-fff;
      border-radius: 100%;
      z-index: -1;
    }

    .base-pompompurin__container {
      position: relative;
    }

    &__fg {
      width: 100%;
      aspect-ratio: 175/198;
      max-width: 140px;
      position: absolute;
      left: 50%;
      bottom: -180px;
      transform: translateX(-50%);
      z-index: 1;
    }

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      font-family: $font-fredoka !important;
      font-weight: 700;
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: 2.56px;
      margin-top: 0;
      margin-bottom: 40px;
      
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $yellow-ffc;
        color: $brown-76;
        height: 23px;
        padding-inline: 7px;
        border-radius: 3px;
        font: 700 13px/1 $font-heisei;
        letter-spacing: 0.26px;
      }
    }
    &__list {
      display: grid;
      gap: 60px;
      width: 100%;
      overflow: hidden;
      margin-bottom: 38px;
      list-style-type: none;
      padding: 0;
    }
    &__item {
      width: 100%;
      overflow: hidden;
      display: grid;
      gap: 35px;
    }

    &__slider {
      width: 100%;
      overflow: hidden;
      opacity: 0;

      img {
        aspect-ratio: 139/91;
      }
      &.loaded {
        animation: pompomFadeIn 1s ease 1s forwards;
      }
      > .slick-list {
        aspect-ratio: 139/91;
      }

      > .slick-dots {
        margin-top: 20px;
        padding: 0;
        list-style-type: none;
        display: flex !important;
        justify-content: center;
  
        li > button {
          border: 0;
          color: transparent;
        }
  
        li {
          width: 24px;
          height: 19px;
          margin: 0 6px;
  
          button {
            width: 100%;
            height: 100%;
            padding: 0;
            background: url("#{$base-img-url}/footprint.svg") no-repeat center/contain;
            opacity: 0.25;
            &::before {
              display: none;
            }
          }
  
          &.slick-active {
            button {
              opacity: 1;
            }
          }
        }
  
        @media (min-width: 768px) {
          margin-top: 32px;

          li {
            width: 37px;
            height: 30px;
            margin: 0 9px;
          }
        }
      }
    }

    &__content {
      p {
        font-size: 14px;
        font-weight: 800;
        letter-spacing: 0.28px;
        margin-bottom: 30px;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 23px;

      h3, h4 {
        margin: 0;
      }

      h3 {
        font-family: $font-fredoka !important;
        font-weight: 700 !important;
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 7px;
        border-bottom: 3px solid currentColor;
      }
      h4 {
        font-family: $font-corporate !important;
        font: 700 20px/33px $font-corporate;
        letter-spacing: 0.2px;
        small {
          font-family: $font-heisei !important;
          font: 800 12px/1 $font-heisei;
          letter-spacing: 0.72px;
          margin-left: 5px;
        }
      }
    }

    &__note {
      text-align: center;
      font-size: 10px;
      font-weight: 800;
      line-height: 17px;
      letter-spacing: 0.2px;
      font-family: $font-heisei;
      margin: 0 0 140px 0;
    }

    @media (min-width: 768px) {
      &__item {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        &:nth-child(even) {
          .body-pompompurin__accessories__slider {
            order: 1;
          }
        }
      }
      &__content {
        .base-pompompurin__btn {
          margin-left: 0;
        }
      }

      &__note {
        margin-bottom: 150px;
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: 25px;

      &__fg {
        left: 90%;
        bottom: -48px;
      }

      &::before {
        top: calc(1166px / 5);
      }
      &::after {
        top: -178px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        width: 1507px;
        height: 1166px;
      }
      &__title {
        font-size: 60px;
        line-height: 73px;
        letter-spacing: 3px;
        gap: 10px;
        margin-bottom: 70px;
        span {
          font-size: 21px;
          letter-spacing: 0.42px;
          height: 37px;
          padding-inline: 18px;
        }
      }
      &__list {
        gap: 67px;
        margin-bottom: 98px;
      }
      &__item {
        gap: 60px;
      }
      &__header {
        margin-bottom: 27px;
        h3 {
          font-size: 34px;
          line-height: 50px;
          padding-bottom: 9px;
          border-bottom-width: 5px;
        }
        h4 {
          font-size: 25px;
          line-height: 33px;
          letter-spacing: 0.25px;
          small {
            font-size: 14px;
            letter-spacing: 0.84px;
          }
        }
      }
      &__content {
        p {
          font-size: 16px;
          line-height: 35px;
          letter-spacing: 0.32px;
          margin-bottom: 26px;
        }
      }

      &__note {
        margin-bottom: 0;
      }
    }
    @media screen and (min-width: 1440px) {
      &::before {
        top: calc((1166px / 2) - 178px);
      }
      &::after {
        left: -34px;
        right: -34px;
        transform: none;
        width: auto;
      }
    }
  }
}
