.cart-item {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  width: 721px;
  float: left;
}

.cart-item_container {
  padding: 6px 0 0px 0px;
  width: 148px;
  float: left;
}

.cart-item_container_thumb {
  float: left;
  margin: 15px 8px 0px 2px;
  margin-top: 0px;
  width: 75px;
}

.cart-item_container_qty-block {
  float: left;
  width: 52px;
  font-size: 16px;
  color: #777;
}

.cart-item_container_qty-block_title {
  float: left;
  width: 30px;
  margin-top: 25px;
}

.cart-item_container_qty-block .blck-qty {
  float: left;
  width: 22px;
}

.cart-item_container_qty-block .blck-qty i {
  float: left;
  width: 16px;
  height: 16px;
  background-position: -1048px -168px;
  margin: 3px 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 47%;
  padding: 4px;
}

.cart-item_container_qty-block .blck-qty .plus {
  background-position: -1098px -168px;
}

.cart-item_container_qty-block .blck-qty .qty-value {
  width: 27px;
  margin: 0px;
  text-align: center;
}

.cart-item_amount {
  width: 566px;
  padding: 26px 0 15px;
  border-left: 1px solid #f0f0f0;
  min-height: 95px;
  color: #777;
  float: left;
  text-align: center;
}

.cart-item_amount .old-price {
  text-decoration: line-through;
  overflow: hidden;
  display: block;
}

.cart-item_amount .off-price {
  font-size: 14px;
  color: #777;
  overflow: hidden;
  display: block;
  line-height: 22px;
}

.cart-item_amount .crt-frm-prce {
  float: left;
  width: 155px;
}

.cart-item_amount .crt-frm-prce > div {
  padding-left: 10px;
}

.cart-item_amount .crt-lns-prce .toggle-arrow {
  cursor: pointer;
  color: #329c92;
  font-weight: bold;
}

.cart-item_amount .crt-lns-prce .toggle-arrow.toggle-arrow-view:after {
  content: "-";
  font-size: 20px;
}

.cart-item_amount .crt-lns-prce .toggle-arrow:after {
  content: "+";
  font-size: 16px;
  line-height: 0px;
  width: 18px;
  display: inline-block;
}

.cart-item_amount .crt-lns-prce .view-power-lens {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cart-item_amount .crt-lns-prce .view-power-lens li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cart-item_amount .crt-lns-prce .view-power-lens li > span {
  color: #445961;
  font-weight: 700;
}

.cart-item_amount .pls-crt-sign {
  font-size: 24px;
  margin: 25px 0 0 0;
  line-height: 20px;
  width: 14px;
  display: inline-block;
}

.cart-item_amount_status {
  overflow: hidden;
  display: block;
}

.cart-item_amount_status span {
  display: block;
  color: #999;
  font-style: italic;
}

.cart-item table.lns-detail-tab {
  margin-top: 2px;
}

.cart-item table.lns-detail-tab td {
  padding: 0px 3px 0 0;
  font-size: 13px;
  border: none;
}

.cart-item table.lns-detail-tab td:first-child {
  text-align: left;
}

.cart-item_remove-icon {
  position: absolute;
  top: 0;
  right: -15px;
  float: right;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-position: -1000px -150px;
  margin: 10px 20px 0 0;
}

.cart-item_message {
  background-color: #ffffe6;
  border: 0.25px solid #cccccc;
  color: #777777;
  clear: both;
  width: 100%;
  text-align: center;
  font-family: -webkit-body;
  letter-spacing: 1px;
}

.cart-item_message .oos-text {
  position: relative;
  top: -4px;
}

.cart-item_message .fa-bullhorn {
  position: relative;
  left: -8px;
  color: #31b19e;
}

.row-fluid {
  width: 100%;
}

.row-fluid [class*="span"]:first-child {
  margin-left: 0;
  text-align: right;
}

.row-fluid .span6 {
  width: 49.33875890132248%;
}

.row-fluid [class*="span"] {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 1.3224821973550356%;
}

.row-fluid .span2 {
  width: 15.564598168870804%;
}

.row-fluid .span3 {
  width: 24.008138351983725%;
}

.content {
  border: none !important;
}

.color-yellow {
  color: #e8792a;
}

.packges-block {
  display: block;
}

.chkout-cart-region .power-details-cart {
  clear: both;
}

.chkout-cart-region .power-table-block {
  width: 100%;
  padding: 0;
  margin: auto;
}

.chkout-cart-region .power-table-block.shadow {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.chkout-cart-region .power-table-block .power-item {
  padding-bottom: 0;
}

.chkout-cart-region .power-table-block .display-grid {
  border-top: 1px solid #f0f0f0;
  width: 100%;
  padding-right: 30%;
  padding-left: 20px;
  margin-top: -5px;
}

.chkout-cart-region .power-table-block .bg-yellowish-brown {
  margin: 0;
  border-bottom: 0;
}

.chkout-cart-region .power-table-block .name-strip {
  margin-left: 0;
  margin-right: 0;
  border-bottom: none;
  padding-left: 0px;
}

.chkout-cart-region .power-status-message {
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 0;
  position: relative;
  margin: auto;
}

.chkout-cart-region .power-status-message:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 149px;
  height: 100%;
}
