.about-referral {
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.about-referral__title {
  margin-top: 37px;
  margin-bottom: 100px;
  text-align: center;
  color: #000;
  font-size: 48px;
  font-style: normal;
  line-height: 60px;
}

.about-referral-hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 100px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 1200px) {
  .about-referral-hero {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.about-referral-hero__subtitle {
  margin-bottom: 10px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.32px;
}

.about-referral-hero__title {
  margin-bottom: 10px;
  color: #000;
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}

.about-referral-hero__button {
  margin-top: 40px;
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
  text-transform: capitalize;
}

.about-referral-benefits {
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(2, 1fr);
  gap: 91px;
}

@media only screen and (max-width: 1200px) {
  .about-referral-benefits {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 30px;
  }
}

.about-referral-benefits__block {
  padding: 20px;
  background-color: #FAFAFA;
}

.about-referral-benefits__title {
  margin-bottom: 30px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
}

.about-referral-benefits__beneficiary {
  text-transform: capitalize;
}

.about-referral-benefits__list {
  padding-left: 25px;
  list-style-type: unset;
}

.about-referral-benefits__benefit {
  color: rgba(0, 0, 0, .5);
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  list-style-type: unset;
}

.about-referral-conditions__list {
  padding-left: 25px;
  list-style-type: unset;
}

.about-referral-conditions__condition {
  color: rgba(0, 0, 0, .5);
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  list-style-type: unset;
}
