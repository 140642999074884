.base-lens-replacement__container {
  width: 100%;
  max-width: 1296px;
  height: auto;
  padding: 0 5%;
  margin: 0 auto;
  letter-spacing: 1px;
}

.base-lens-replacement__content {
  padding: 0 15px;
  margin: 25px 0;
}

.base-lens-replacement__content--inner {
  width: 100%;
  max-width: 1152px;
  border: 1px solid #e3e3e3;
  margin-top: 25px;
}

.base-lens-replacement__content--inner h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  padding: 18px 0 13px;
  line-height: 1;
  margin-block: 0;
  color: #000;
  font-weight: 400;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .base-lens-replacement__content--inner {
    width: 80%;
    margin: 80px auto 0 auto;
  }
}

@media (min-width: 768px) {
  .base-lens-replacement__content {
    padding: 0 24px;
    margin: 40px 0;
  }
}

.base-lens-replacement__list-remark {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.base-lens-replacement__list-remark li {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 1px;
  color: #999;
  padding-left: 20px;
  position: relative;
  padding-top: 5px;
}

.base-lens-replacement__list-remark li::before {
  content: '*';
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 4px;
}
