/*
 *  Define scss variables here.
*/
/* Toast Classes */
.message-toast {
  z-index: 999999999999;
  width: 100vw;
  bottom: -999px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.toast-text {
  color: white;
  padding: 8px 15px;
  max-width: 95vw;
}
.white-space-pre {
  white-space: pre-wrap;
}
.background-dark_grey_blue {
  background: #27394e;
}
.background-green {
  background: #61ab44;
}
.background-orange {
  background: #fd6b0b;
}
.background-red {
  background: #ff0000;
}
/* Only used on old payment page */
