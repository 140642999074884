.body-tom-and-jerry__lineup__main__bg-header {
  width: 100%;
  height: auto;
  position: relative;
}

.body-tom-and-jerry__lineup__main__bg-header::before, .body-tom-and-jerry__lineup__main__bg-header::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -4;
}

.body-tom-and-jerry__lineup__main__bg-header img {
  width: 100%;
  height: auto;
  position: relative;
  z-index: -1;
}

.body-tom-and-jerry__lineup__main__bg-header::before {
  top: -1px;
  height: 95%;
  background-color: #0f99c4;
}

.body-tom-and-jerry__lineup__main__bg-header::after {
  bottom: -1px;
  height: 5%;
  background-color: #ec7497;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="chase"]::before {
  background-color: #ec7497;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="chase"]::after {
  background-color: #00ac87;
  z-index: 0;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="chase"] img {
  z-index: 0;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="friendly-face"]::before {
  z-index: 0;
  background-color: transparent;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="friendly-face"]::after {
  background-color: #7b868c;
  z-index: 0;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="friendly-face"] img {
  z-index: 0;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="cheese"]::before {
  z-index: 0;
  background-color: transparent;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="cheese"]::after {
  background-color: #fae445;
  z-index: 0;
}

.body-tom-and-jerry__lineup__main__bg-header[data-key="cheese"] img {
  z-index: 0;
}

.body-tom-and-jerry__lineup__main__bg-body {
  width: 100%;
  margin: 0 auto;
  height: auto;
  padding: 50px 20px 93px 20px;
  position: relative;
}

.body-tom-and-jerry__lineup__main__bg-body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #ec7497;
}

.body-tom-and-jerry__lineup__main__bg-body[data-key="chase"]::before {
  z-index: 0;
  background-color: #00ac87 !important;
}

.body-tom-and-jerry__lineup__main__bg-body[data-key="friendly-face"]::before {
  z-index: 0;
  background-color: #7b868c !important;
}

.body-tom-and-jerry__lineup__main__bg-body[data-key="cheese"]::before {
  z-index: 0;
  background-color: #fae445 !important;
}

.body-tom-and-jerry__lineup__main__bg-footer {
  width: 100%;
  height: auto;
  background-color: #ec7497;
  padding-bottom: 340px;
}

.body-tom-and-jerry__lineup__main__bg-footer[data-key="chase"] {
  background-color: #00ac87;
}

.body-tom-and-jerry__lineup__main__bg-footer[data-key="friendly-face"] {
  background-color: #7b868c;
}

.body-tom-and-jerry__lineup__main__bg-footer[data-key="cheese"] {
  background-color: #fae445;
}

.body-tom-and-jerry__lineup__main__figure-1 {
  width: 192.67px;
  height: auto;
  position: absolute;
  top: -125px;
  right: -41px;
  z-index: 2;
}

.body-tom-and-jerry__lineup__main__figure-1-addon {
  position: absolute;
  height: auto;
  z-index: 1;
}

.body-tom-and-jerry__lineup__main__figure-1-addon[data-key="friendly-face"] {
  width: 83.359px;
  top: -95px;
  left: 11%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.body-tom-and-jerry__lineup__main__figure-1[data-key="chase"] {
  width: 134px;
  height: 127.916px;
  top: -92px;
  right: unset;
  left: -20px;
}

.body-tom-and-jerry__lineup__main__figure-1[data-key="friendly-face"] {
  width: 303.43px;
  height: auto;
  top: -105px;
  left: calc(50% - 24px);
  right: unset;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

.body-tom-and-jerry__lineup__main__figure-1[data-key="cheese"] {
  width: 125.238px;
  height: auto;
  top: -49px;
  right: unset;
  left: -20px;
  -webkit-transform: rotate(-11.122deg);
          transform: rotate(-11.122deg);
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-animation: cartoonSwinging 2s linear infinite;
          animation: cartoonSwinging 2s linear infinite;
}

.body-tom-and-jerry__lineup__main__figure-2 {
  width: 99px;
  height: auto;
  position: absolute;
  bottom: -53px;
  left: -11px;
  z-index: 2;
}

.body-tom-and-jerry__lineup__main__figure-2[data-key="chase"] {
  width: 132.437px;
  height: 74px;
  bottom: unset;
  left: unset;
  top: 2px;
  right: 2px;
}

.body-tom-and-jerry__lineup__main__figure-2[data-key="friendly-face"] {
  width: 113.55px;
  height: auto;
  -webkit-transform: rotate(-56.879deg) !important;
          transform: rotate(-56.879deg) !important;
  left: unset;
  right: -75px;
  bottom: -101px;
}

.body-tom-and-jerry__lineup__main__figure-2[data-key="cheese"] {
  width: 92.981px;
  height: auto;
  bottom: -22px;
  left: unset;
  right: -9px;
}

.body-tom-and-jerry__lineup__main__figure-3 {
  position: absolute;
  z-index: 2;
}

.body-tom-and-jerry__lineup__main__figure-3[data-key="chase"] {
  width: 146px;
  height: auto;
  bottom: -66px;
  right: -8px;
}

.body-tom-and-jerry__lineup__main__layout-model, .body-tom-and-jerry__lineup__main__layout-point {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 2px solid black;
  background: white;
}

.body-tom-and-jerry__lineup__main__layout-model {
  border-radius: 30px 30px 0 0;
  padding: 32px 20px 40.5px 20px;
}

.body-tom-and-jerry__lineup__main__layout-point {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 40.5px 20px 31.2px 20px;
  border-top-width: 0;
  border-bottom-width: 8.79px;
  border-radius: 0 0 30px 30px;
}

.body-tom-and-jerry__lineup__main__layout-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.body-tom-and-jerry__lineup__main__layout-pc {
  position: relative;
  z-index: 4;
}

.body-tom-and-jerry__lineup__main__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.body-tom-and-jerry__lineup__main__header-sp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.body-tom-and-jerry__lineup__main__header-pc {
  display: none;
}

.body-tom-and-jerry__lineup__main__header-title {
  color: black;
  text-align: center;
  font-family: "GenEi POPle Pw";
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .body-tom-and-jerry__lineup__main__header-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: 32px;
  }
}

.body-tom-and-jerry__lineup__main__header-model {
  width: 198px;
  height: auto;
  margin-left: 13px;
}

@media (max-width: 767px) {
  .body-tom-and-jerry__lineup__main__header-model {
    width: 127px;
    margin: 0 auto;
  }
}

.body-tom-and-jerry__lineup__main__header-desc img {
  width: 100%;
  height: auto;
}

.body-tom-and-jerry__lineup__main__header-desc p {
  color: #682f25;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: normal;
  margin-bottom: 0;
}

.body-tom-and-jerry__lineup__main__header__order-funny-1, .body-tom-and-jerry__lineup__main__header__order-funny-2, .body-tom-and-jerry__lineup__main__header__order-funny-3, .body-tom-and-jerry__lineup__main__header__order-chase-1, .body-tom-and-jerry__lineup__main__header__order-chase-2, .body-tom-and-jerry__lineup__main__header__order-chase-3, .body-tom-and-jerry__lineup__main__header__order-friendly-face-1, .body-tom-and-jerry__lineup__main__header__order-friendly-face-2, .body-tom-and-jerry__lineup__main__header__order-friendly-face-3, .body-tom-and-jerry__lineup__main__header__order-cheese-1, .body-tom-and-jerry__lineup__main__header__order-cheese-2, .body-tom-and-jerry__lineup__main__header__order-cheese-3 {
  display: none;
}

.body-tom-and-jerry__lineup__main__slider__points__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-bottom: 29px;
}

.body-tom-and-jerry__lineup__main__slider__points__desc p {
  margin-bottom: 0;
}

.body-tom-and-jerry__lineup__main__slider__points__desc p:nth-child(1) {
  padding: 0 11px;
  background-color: #e1002a;
  line-height: 0;
}

.body-tom-and-jerry__lineup__main__slider__points__desc p:nth-child(1) span {
  color: white;
  font-family: "Karu";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 33px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.body-tom-and-jerry__lineup__main__slider__points__desc p:not(:first-child) {
  color: #682f25;
  text-align: center;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 29px;
  min-height: 87px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}

.body-tom-and-jerry__lineup__main__slider__points__img {
  width: calc(100% - 16px);
  height: auto;
  border: 2px solid black;
  margin: 0 auto;
}

.body-tom-and-jerry__lineup__main__slider__products > .slick-list {
  margin: 0 -5px;
}

.body-tom-and-jerry__lineup__main__slider__products > .slick-list .slick-slide {
  padding: 0 5px;
  max-width: 503px;
  aspect-ratio: 603/385;
}

.body-tom-and-jerry__lineup__main__product__layout-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px;
}

.body-tom-and-jerry__lineup__main__product__sku {
  color: white;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: black;
  paint-order: stroke;
  font-family: "Karu";
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 32.8px;
  letter-spacing: 2.2px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .body-tom-and-jerry__lineup__main__product__sku {
    -webkit-text-stroke-width: 3px;
  }
}

.body-tom-and-jerry__lineup__main__product__color-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  z-index: 2;
}

.body-tom-and-jerry__lineup__main__product__color-name {
  color: white;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 2;
  margin-bottom: 0;
}

.body-tom-and-jerry__lineup__main__product__color-name__cheese {
  color: black;
}

.body-tom-and-jerry__lineup__main__product__color-chip__funny, .body-tom-and-jerry__lineup__main__product__color-chip__chase, .body-tom-and-jerry__lineup__main__product__color-chip__friendly-face, .body-tom-and-jerry__lineup__main__product__color-chip__cheese {
  width: 34px;
  height: 34px;
}

.body-tom-and-jerry__lineup__main__product__color-chip__funny--selected, .body-tom-and-jerry__lineup__main__product__color-chip__chase--selected, .body-tom-and-jerry__lineup__main__product__color-chip__friendly-face--selected, .body-tom-and-jerry__lineup__main__product__color-chip__cheese--selected {
  border: 3px solid white;
}

.body-tom-and-jerry__lineup__main__product__color-c1, .body-tom-and-jerry__lineup__main__product__color-c2 {
  display: block;
  width: 100%;
  height: 100%;
}

.body-tom-and-jerry__lineup__main__product__color-c1 {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/funny/color-1.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c1[data-key="chase"] {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/chase/color-1.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c1[data-key="friendly-face"] {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/friendly-face/color-1.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c1[data-key="cheese"] {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/cheese/color-1.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c2 {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/funny/color-2.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c2[data-key="chase"] {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/chase/color-2.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c2[data-key="friendly-face"] {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/friendly-face/color-2.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__color-c2[data-key="cheese"] {
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/cheese/color-2.webp") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__product__price {
  color: white;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 32.8px;
  margin-top: -4px;
  margin-bottom: 0;
}

.body-tom-and-jerry__lineup__main__product__price__cheese {
  color: black;
}

.body-tom-and-jerry__lineup__main__product__button {
  max-width: 300px;
  padding: 0 0 65px 0;
  margin: 0 auto;
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__bg-header[data-key] {
    background-color: transparent;
    margin-top: -2px;
  }
  .body-tom-and-jerry__lineup__main__bg-body {
    padding: 32px 20px 82px 20px;
    margin-top: 0;
  }
  .body-tom-and-jerry__lineup__main__bg-footer {
    padding-bottom: 445px;
  }
  .body-tom-and-jerry__lineup__main__figure-1 {
    top: -125px;
    right: -49px;
  }
  .body-tom-and-jerry__lineup__main__figure-1-addon[data-key="friendly-face"] {
    top: -96px;
    left: 35%;
  }
}

@media (min-width: 961px) and (min-width: 1200px) {
  .body-tom-and-jerry__lineup__main__figure-1-addon[data-key="friendly-face"] {
    left: 37.5%;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__figure-1[data-key="chase"] {
    top: 49px;
    left: 3px;
  }
  .body-tom-and-jerry__lineup__main__figure-1[data-key="friendly-face"] {
    width: 331.43px;
  }
  .body-tom-and-jerry__lineup__main__figure-1[data-key="cheese"] {
    width: 201px;
    top: -15px;
    left: -62px;
  }
  .body-tom-and-jerry__lineup__main__figure-2 {
    bottom: -29px;
    left: -43px;
  }
  .body-tom-and-jerry__lineup__main__figure-2[data-key="chase"] {
    top: 2px;
    right: 10px;
  }
  .body-tom-and-jerry__lineup__main__figure-2[data-key="friendly-face"] {
    width: 134.521px;
    -webkit-transform: unset !important;
            transform: unset !important;
    bottom: -91px;
  }
}

@media (min-width: 961px) and (min-width: 1200px) {
  .body-tom-and-jerry__lineup__main__figure-2[data-key="friendly-face"] {
    right: -67px;
    bottom: -49px;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__figure-2[data-key="cheese"] {
    width: 156.628px;
    bottom: -154px;
    right: -58px;
  }
}

@media (min-width: 961px) and (min-width: 1200px) {
  .body-tom-and-jerry__lineup__main__figure-2[data-key="cheese"] {
    bottom: -74px;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__figure-3[data-key="chase"] {
    width: 220px;
    bottom: -120px;
    right: -50px;
  }
  .body-tom-and-jerry__lineup__main__layout-pc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    max-width: 1152px;
    height: auto;
    margin: 0 auto;
    position: relative;
    z-index: 4;
  }
  .body-tom-and-jerry__lineup__main__layout-pc--inner {
    display: grid;
    grid-template-columns: 60% 40%;
    aspect-ratio: 1154/571;
    max-width: 100%;
  }
  .body-tom-and-jerry__lineup__main__layout-model, .body-tom-and-jerry__lineup__main__layout-point {
    border: 3px solid black;
    border-bottom-width: 11px;
    border-top-width: 0;
  }
  .body-tom-and-jerry__lineup__main__layout-model {
    border-radius: 0 0 0 40px;
    padding: 0 13px 40.5px 13px;
  }
  .body-tom-and-jerry__lineup__main__layout-point {
    padding: 0;
    border-left-width: 0;
    border-right-width: 10px;
    border-radius: 0 0 40px 0;
  }
  .body-tom-and-jerry__lineup__main__layout-product {
    display: grid;
    grid-template-columns: 40% 60%;
    padding-top: 75px;
  }
  .body-tom-and-jerry__lineup__main__layout-product .body-tom-and-jerry__lineup__main__slider__products {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .body-tom-and-jerry__lineup__main__layout-product .body-tom-and-jerry__lineup__main__slider__products li img {
    border-radius: 40px;
    border: 3px solid #000;
    width: 99.3%;
  }
}

@media (min-width: 961px) and (max-width: 767px) {
  .body-tom-and-jerry__lineup__main__layout-product .body-tom-and-jerry__lineup__main__slider__products li img {
    width: 100%;
    border-radius: unset;
    border: unset;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__layout-product div:nth-child(2) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .body-tom-and-jerry__lineup__main__header-sp {
    display: none;
  }
  .body-tom-and-jerry__lineup__main__header-pc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 8px;
    padding: 20px;
    background-color: white;
    border-radius: 40px 40px 0 0;
    border: 3px solid black;
    border-right-width: 10px;
  }
  .body-tom-and-jerry__lineup__main__header-desc {
    position: relative;
  }
  .body-tom-and-jerry__lineup__main__header-desc p {
    font-size: 16px;
    line-height: 36px;
    margin-top: -43px;
    padding: 0 49px;
  }
  .body-tom-and-jerry__lineup__main__header__order-funny-1 {
    display: block;
    position: absolute;
    top: 20%;
    left: 32%;
  }
  .body-tom-and-jerry__lineup__main__header__order-funny-2 {
    display: block;
    position: absolute;
    top: 28%;
    left: 62%;
  }
  .body-tom-and-jerry__lineup__main__header__order-funny-3 {
    display: block;
    position: absolute;
    top: 16%;
    left: 51%;
  }
  .body-tom-and-jerry__lineup__main__header__order-chase-1 {
    display: block;
    position: absolute;
    top: 33%;
    left: 59%;
  }
  .body-tom-and-jerry__lineup__main__header__order-chase-2 {
    display: block;
    position: absolute;
    top: 14%;
    left: 8%;
  }
  .body-tom-and-jerry__lineup__main__header__order-chase-3 {
    display: block;
    position: absolute;
    top: 33%;
    left: 40%;
  }
  .body-tom-and-jerry__lineup__main__header__order-friendly-face-1 {
    display: block;
    position: absolute;
    top: 23%;
    left: 1%;
  }
  .body-tom-and-jerry__lineup__main__header__order-friendly-face-2 {
    display: block;
    position: absolute;
    top: 28%;
    left: 36%;
  }
  .body-tom-and-jerry__lineup__main__header__order-friendly-face-3 {
    display: block;
    position: absolute;
    top: 35%;
    right: 0;
  }
  .body-tom-and-jerry__lineup__main__header__order-cheese-1 {
    display: block;
    position: absolute;
    top: 14%;
    left: 42%;
  }
  .body-tom-and-jerry__lineup__main__header__order-cheese-2 {
    display: block;
    position: absolute;
    top: 37%;
    left: 39%;
  }
  .body-tom-and-jerry__lineup__main__header__order-cheese-3 {
    display: block;
    position: absolute;
    top: 32%;
    left: 61%;
  }
  .body-tom-and-jerry__lineup__main__header-option {
    width: 79px;
    height: 72.121px;
    position: relative;
  }
}

@media (min-width: 961px) and (min-width: 1024px) {
  .body-tom-and-jerry__lineup__main__header-option {
    cursor: pointer;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__header-option::before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/lineup_inactive-point.svg") center/100% no-repeat;
    -webkit-transition: .5s;
    transition: .5s;
    cursor: pointer;
  }
  .body-tom-and-jerry__lineup__main__header-option__funny--active::before, .body-tom-and-jerry__lineup__main__header-option__funny:hover::before, .body-tom-and-jerry__lineup__main__header-option__chase--active::before, .body-tom-and-jerry__lineup__main__header-option__chase:hover::before, .body-tom-and-jerry__lineup__main__header-option__friendly-face--active::before, .body-tom-and-jerry__lineup__main__header-option__friendly-face:hover::before, .body-tom-and-jerry__lineup__main__header-option__cheese--active::before, .body-tom-and-jerry__lineup__main__header-option__cheese:hover::before {
    background: url("https://static.lenskart.com/owndays/img/tomandjerry/products/lineup_active-point.svg") center/100% no-repeat !important;
  }
  .body-tom-and-jerry__lineup__main__header-option span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: white;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: black;
    paint-order: stroke;
    font-family: "Karu";
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 33px;
    letter-spacing: 3.12px;
  }
}

@media (min-width: 961px) and (max-width: 767px) {
  .body-tom-and-jerry__lineup__main__header-option span {
    -webkit-text-stroke-width: 3px;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__slider__points {
    margin-bottom: 0 !important;
  }
  .body-tom-and-jerry__lineup__main__slider__points__desc {
    gap: 25px;
    margin: 73px 0;
  }
  .body-tom-and-jerry__lineup__main__slider__points__desc p:nth-child(1) {
    padding: 4px 10px 2px;
  }
  .body-tom-and-jerry__lineup__main__slider__points__desc p:nth-child(1) span {
    font-size: 30px;
    letter-spacing: 2px;
  }
  .body-tom-and-jerry__lineup__main__slider__points__desc p:not(:first-child) {
    font-size: 18px;
    line-height: 36px;
    min-height: 108px;
  }
  .body-tom-and-jerry__lineup__main__slider__points__img {
    width: 100%;
    border: 3px solid black;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-radius: 0 0 40px 0;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-list {
    margin: 0 -8px;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-list .slick-slide {
    padding: 0 8px;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -75px;
    z-index: 1;
    background: transparent;
    color: transparent;
    border: none;
  }
}

@media (min-width: 961px) and (min-width: 1024px) {
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow {
    cursor: pointer;
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow::before, .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow::after {
    position: absolute;
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow::before {
    background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/lineup_arrow-icon-menu.svg");
    opacity: 1;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow::after {
    background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/lineup_arrow-icon-menu-hover.svg");
    opacity: 0;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow:hover::before {
    opacity: 0;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-arrow:hover::after {
    opacity: 1;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-prev {
    left: calc(100% - 174px);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-next {
    right: 64px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .body-tom-and-jerry__lineup__main__product__layout-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
    padding: 40px 20px 20px 20px;
  }
  .body-tom-and-jerry__lineup__main__product__sku {
    font-size: 33px;
    letter-spacing: 3.3px;
  }
  .body-tom-and-jerry__lineup__main__product__color-name {
    font-size: 13px;
    margin: 8px 0;
  }
  .body-tom-and-jerry__lineup__main__product__price {
    font-size: 24px;
  }
  .body-tom-and-jerry__lineup__main__product__button {
    padding-bottom: 0;
    margin: 0 0 0 20px;
    position: relative;
    z-index: 2;
  }
  .body-tom-and-jerry__lineup__main__layout-product {
    margin-left: auto;
    grid-template-columns: 32% 68%;
    position: relative;
    z-index: 4;
    max-width: calc(100% - 20px);
    gap: 40px;
  }
}

@media (min-width: 961px) and (min-width: 1024px) {
  .body-tom-and-jerry__lineup__main__layout-product {
    gap: 0;
    padding-top: 157px;
  }
}

@media (min-width: 961px) and (min-width: 1440px) {
  .body-tom-and-jerry__lineup__main__layout-product {
    max-width: calc(100% - 156px);
  }
}

@media (min-width: 961px) and (min-width: 1600px) {
  .body-tom-and-jerry__lineup__main__layout-product {
    max-width: calc(100% - 230px);
  }
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__header__order-funny-1 {
    top: 24%;
    left: 36%;
  }
  .body-tom-and-jerry__lineup__main__header__order-funny-2 {
    top: 31%;
    left: 65%;
  }
  .body-tom-and-jerry__lineup__main__header__order-funny-3 {
    top: 19%;
    left: 50%;
  }
  .body-tom-and-jerry__lineup__main__figure-1 {
    width: 319.47px;
    top: -209px;
    right: -89px;
  }
  .body-tom-and-jerry__lineup__main__figure-2 {
    width: 155px;
    bottom: -79px;
    left: -67px;
  }
  .body-tom-and-jerry__lineup__main__slider__products > .slick-list > .slick-track > .slick-slide {
    max-width: 603px;
  }
  .body-tom-and-jerry__lineup__main__product__layout {
    padding: 55px 20px 20px 20px;
  }
  .body-tom-and-jerry__lineup__main__product__layout-row {
    padding-left: 0;
  }
  .body-tom-and-jerry__lineup__main__product__button {
    margin-left: 0;
  }
}

.body-tom-and-jerry__lineup__funny, .body-tom-and-jerry__lineup__chase, .body-tom-and-jerry__lineup__friendly-face, .body-tom-and-jerry__lineup__cheese {
  position: relative;
}

.body-tom-and-jerry__lineup__funny::before, .body-tom-and-jerry__lineup__chase::before, .body-tom-and-jerry__lineup__friendly-face::before, .body-tom-and-jerry__lineup__cheese::before {
  content: "";
  position: absolute;
  top: -43px;
  left: -733px;
  right: -733px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

@media (max-width: 960px) {
  .body-tom-and-jerry__lineup__funny::before, .body-tom-and-jerry__lineup__chase::before, .body-tom-and-jerry__lineup__friendly-face::before, .body-tom-and-jerry__lineup__cheese::before {
    content: none;
  }
}

.body-tom-and-jerry__lineup__funny::after, .body-tom-and-jerry__lineup__chase::after, .body-tom-and-jerry__lineup__friendly-face::after, .body-tom-and-jerry__lineup__cheese::after {
  content: "";
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.body-tom-and-jerry__lineup__chase, .body-tom-and-jerry__lineup__friendly-face, .body-tom-and-jerry__lineup__cheese {
  margin-top: -335px;
}

.body-tom-and-jerry__lineup__funny::before {
  z-index: 0;
  background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/products/funny/bg-funny-text.webp");
}

.body-tom-and-jerry__lineup__funny::after {
  background-color: #ec7497;
}

.body-tom-and-jerry__lineup__chase::before {
  z-index: 1;
  background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/products/chase/bg-chase-text.webp");
}

.body-tom-and-jerry__lineup__chase::after {
  background-color: #00ac87;
}

.body-tom-and-jerry__lineup__friendly-face {
  z-index: 1;
}

.body-tom-and-jerry__lineup__friendly-face::before {
  z-index: 2;
  background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/products/friendly-face/bg-friendly-face-text.webp");
}

.body-tom-and-jerry__lineup__friendly-face::after {
  background-color: #7b868c;
}

.body-tom-and-jerry__lineup__cheese {
  z-index: 2;
}

.body-tom-and-jerry__lineup__cheese::before {
  z-index: 3;
  background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/products/cheese/bg-cheese-text.webp");
}

.body-tom-and-jerry__lineup__cheese::after {
  background-color: #fae445;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider {
  position: relative;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots {
  list-style-type: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin: 8px 0 0 0;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li,
.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li > button,
.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li > button::before {
  width: 11px;
  height: 11px;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li {
  margin: 0 6px;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li > button {
  color: transparent;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li > button {
  content: "";
  background-color: #fde360;
  border-radius: 50%;
  opacity: 1;
  border: none;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-dots > li.slick-active > button {
  background-color: #F0A04A;
  opacity: 1;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 38px;
  height: 38px;
  top: 64.5%;
  z-index: 1;
  background: transparent;
  color: transparent;
  border: none;
}

@media (min-width: 961px) {
  .body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-arrow {
    display: none !important;
  }
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-arrow::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: "";
  display: block;
  width: 38px;
  height: 38px;
  opacity: 1;
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/lineup_arrow-icon-menu.svg") center/100% no-repeat;
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-prev {
  left: -5px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.body-tom-and-jerry__lineup__main__layout-point > .slick-slider > .slick-next {
  right: -5px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

@-webkit-keyframes cartoonSwinging {
  0%,
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@keyframes cartoonSwinging {
  0%,
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}
