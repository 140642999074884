.lkpanel .collapse {
  overflow: hidden;
  -webkit-transition-duration: .35s;
          transition-duration: .35s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0, 1);
          transition-timing-function: cubic-bezier(0, 1, 0, 1);
  max-height: 0;
}

.lkpanel .collapse.in {
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
  max-height: 10000px;
}
