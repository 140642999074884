.contact-lens-card {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  cursor: pointer;

  &__img {
    width: 320px;
  }

  &__title {
    font-size: 14px;
    color: #000;
  }

  &:hover {
    opacity: 0.5;
  }
}
