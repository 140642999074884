.footer-tom-and-jerry__case {
  position: relative;
  margin-top: -341px;
  z-index: 2;
}

.footer-tom-and-jerry__case__bg__header {
  background-color: transparent;
}

.footer-tom-and-jerry__case__bg__header img {
  display: block;
  width: 100%;
  height: auto;
}

.footer-tom-and-jerry__case__bg__body {
  background-color: #15A9D8;
  position: relative;
  z-index: 0;
}

.footer-tom-and-jerry__case__bg__body::before, .footer-tom-and-jerry__case__bg__body::after {
  content: '';
  width: 100%;
  height: 40px;
  background-color: #15A9D8;
  position: absolute;
  z-index: -1;
}

.footer-tom-and-jerry__case__bg__body::before {
  top: -20px;
}

.footer-tom-and-jerry__case__bg__body::after {
  bottom: -20px;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__case__bg__body {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .footer-tom-and-jerry__case__bg__body::before {
    top: -30px;
  }
  .footer-tom-and-jerry__case__bg__body::after {
    bottom: -30px;
  }
}

.footer-tom-and-jerry__case__bg__footer {
  background-color: #0F99C4;
}

.footer-tom-and-jerry__case__bg__footer img {
  display: block;
  width: 100%;
  height: auto;
}

.footer-tom-and-jerry__case__card {
  padding: 60px 0 92.36px 0;
  border: 2px solid black;
  background: white;
  border-top-width: 2px;
  border-bottom-width: 8.79px;
  border-radius: 30px;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__case__card {
    border-radius: 40px;
    border: 3px solid black;
    border-bottom-width: 8px;
    display: grid;
    max-width: 100%;
    grid-template-columns: 50% 50%;
    padding: 79px 24px 62px 24px;
    margin: 30px 0;
    -webkit-column-gap: 24px;
       -moz-column-gap: 24px;
            column-gap: 24px;
  }
}

.footer-tom-and-jerry__case__header img {
  display: block;
  width: 292.569px;
  height: auto;
  margin: 0 auto;
}

.footer-tom-and-jerry__case__header p {
  padding: 0 16px;
  color: #682F25;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 32px;
  margin: 30.65px auto 89px auto;
  letter-spacing: 0px;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__case__header {
    padding-left: 28px;
  }
  .footer-tom-and-jerry__case__header img {
    margin-left: 0;
  }
  .footer-tom-and-jerry__case__header p {
    padding: 0;
    margin-top: 30.45px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__case__header {
    padding-top: 55px;
  }
  .footer-tom-and-jerry__case__header img {
    width: 500.554px;
  }
  .footer-tom-and-jerry__case__header p {
    line-height: 30px;
  }
}

.footer-tom-and-jerry__case__footer {
  position: relative;
}

.footer-tom-and-jerry__case__footer__main-img {
  display: block;
  width: 100%;
  height: auto;
}

.footer-tom-and-jerry__case__footer__figure-1, .footer-tom-and-jerry__case__footer__figure-2 {
  position: absolute;
  height: auto;
}

.footer-tom-and-jerry__case__footer__figure-1 {
  width: 153.321px;
  top: -66px;
  right: 5.34px;
}

.footer-tom-and-jerry__case__footer__figure-2 {
  width: 153.321px;
  left: 7px;
  bottom: -44.42px;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__case__footer__figure-1 {
    width: 216.31px;
    top: -138px;
    right: -58px;
  }
  .footer-tom-and-jerry__case__footer__figure-2 {
    width: 216.032px;
    left: -67px;
    bottom: -114.42px;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__case__footer__figure-1 {
    width: 316.31px;
    top: -159px;
    right: -80px;
  }
  .footer-tom-and-jerry__case__footer__figure-2 {
    width: 316.31px;
    left: -76px;
    bottom: -146.42px;
  }
}

.footer-tom-and-jerry__accessories {
  background-color: #0F99C4;
  position: relative;
}

.footer-tom-and-jerry__accessories__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 60px;
  position: relative;
}

.footer-tom-and-jerry__accessories__header::before {
  content: '';
  width: 100%;
  height: 20px;
  position: absolute;
  top: -2px;
  background-color: #0F99C4;
}

.footer-tom-and-jerry__accessories__header img {
  display: block;
  height: auto;
}

.footer-tom-and-jerry__accessories__header img:nth-child(1) {
  width: 199px;
}

.footer-tom-and-jerry__accessories__header img:nth-child(2) {
  width: 304px;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__header img:nth-child(1) {
    width: 228px;
  }
  .footer-tom-and-jerry__accessories__header img:nth-child(2) {
    width: 529px;
  }
}

.footer-tom-and-jerry__accessories__body {
  margin-top: 89px;
  position: relative;
}

.footer-tom-and-jerry__accessories__body__figure {
  position: absolute;
  top: -64px;
  left: -24px;
  z-index: 1;
  width: 181.313px;
  height: 113.206px;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body__figure {
    width: 316.31px;
    height: 197.494px;
    top: -78px;
    left: -77px;
  }
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list {
  margin: 0 -3px;
  height: 245px;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list {
    height: auto;
  }
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track {
  height: auto !important;
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide {
  height: auto !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 3px;
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide:focus {
  outline: none;
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide > div {
  height: auto;
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide > div > li {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide > div > li > img {
  display: block;
  width: 311px;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide > div > li > img {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-list > .slick-track > .slick-slide > div > li > img {
    width: 560px;
  }
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots {
  list-style-type: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin: 8px 0 0 0;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots {
    margin-top: 20px;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li,
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li > button,
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li > button::before {
    width: 11px;
    height: 11px;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li {
    margin: 0 6px;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li > button {
    color: transparent;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li > button {
    content: "";
    background-color: #FDE360;
    border-radius: 50%;
    opacity: 1;
    border: none;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li.slick-active {
    width: 51px;
    height: 34.423px;
    margin-right: 4px;
    margin-left: 4px;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li.slick-active > button {
    width: 51px;
    height: 34.423px;
    position: relative;
    background-color: transparent;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-dots > li.slick-active > button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 51px;
    height: 34.423px;
    background: url("https://static.lenskart.com/owndays/img/tomandjerry/accessories/accessories-cheese-icon.svg") no-repeat 50%/contain;
  }
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-arrow {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 38%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  background: transparent;
  color: transparent;
  border: none;
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-arrow::before {
  position: absolute;
  content: "";
  display: block;
  width: 38px;
  height: 38px;
  opacity: 1;
  background: url("https://static.lenskart.com/owndays/img/tomandjerry/lineup_arrow-icon-menu.svg") center/100% no-repeat;
  -webkit-transition: .5s;
  transition: .5s;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-arrow {
    width: 58px;
    height: 58px;
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-arrow::before {
    width: 58px;
    height: 58px;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-arrow {
    display: none !important;
  }
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-prev {
  right: calc(100% - 57px);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.footer-tom-and-jerry__accessories__body > .slick-slider > .slick-next {
  left: calc(100% - 57px);
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-prev {
    right: calc(100% - 126px);
  }
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick-next {
    left: calc(100% - 126px);
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider > .slick {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body > .slick-slider {
    height: 389px;
  }
}

.footer-tom-and-jerry__accessories__body__message {
  margin-top: 20px;
}

.footer-tom-and-jerry__accessories__body__message__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer-tom-and-jerry__accessories__body__message__header p {
  margin-bottom: 0;
}

.footer-tom-and-jerry__accessories__body__message__header p:nth-child(1) {
  color: white;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: black;
  paint-order: stroke;
  font-family: "Karu";
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 32.8px;
  letter-spacing: 2.2px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .footer-tom-and-jerry__accessories__body__message__header p:nth-child(1) {
    -webkit-text-stroke-width: 3px;
  }
}

@media (min-width: 961px) {
  .footer-tom-and-jerry__accessories__body__message__header p:nth-child(1) {
    font-size: 33px;
    letter-spacing: 3.3px;
  }
}

.footer-tom-and-jerry__accessories__body__message__header p:nth-child(2) {
  color: white;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 32.8px;
  margin-top: -4px;
  margin-bottom: 0;
}

@media (min-width: 961px) {
  .footer-tom-and-jerry__accessories__body__message__header p:nth-child(2) {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body__message__header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.footer-tom-and-jerry__accessories__body__message__body p {
  margin-bottom: 0;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 32px;
  letter-spacing: 0px;
  color: white;
  margin-top: 12px;
}

.footer-tom-and-jerry__accessories__body__message__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
  padding: 30px 0 100px 0;
}

.footer-tom-and-jerry__accessories__body__message__footer a {
  width: 316px;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__accessories__body__message__footer a {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body__message__footer {
    padding: 25px 0 0 50px;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body__message {
    margin-top: 0;
    height: 389px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer-tom-and-jerry__accessories__body__message .base-tom-and-jerry__container {
    padding: 0 0 0 50px;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__accessories__body {
    display: grid;
    grid-template-columns: 560px 1fr;
    padding: 0 20px;
    max-width: 1192px;
    margin: 90.3px auto 0 auto;
    padding-bottom: 215.7px;
  }
}

.footer-tom-and-jerry__share {
  position: relative;
  background-color: black;
  padding: 50px 0 51.6px 0;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__share {
    padding: 61px 0 30.6px 0;
  }
  .footer-tom-and-jerry__share > .base-tom-and-jerry__container > div:nth-child(1) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 65px;
  }
}

.footer-tom-and-jerry__share__header {
  text-align: center;
  margin: 0;
}

.footer-tom-and-jerry__share__header img {
  width: 142px;
  height: auto;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__share__header img {
    width: 176px;
  }
}

.footer-tom-and-jerry__share__list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 24px;
  gap: 24px;
}

.footer-tom-and-jerry__share__list li a {
  font-size: 37.856px;
  color: white;
  display: block;
  width: 100%;
  height: 100%;
}

.footer-tom-and-jerry__share__list li a i {
  background: transparent;
}

.footer-tom-and-jerry__share__list li:nth-child(3) {
  width: 37.856px;
  height: 37.856px;
  position: relative;
}

.footer-tom-and-jerry__share__list li:nth-child(3) a {
  color: black;
  position: relative;
}

.footer-tom-and-jerry__share__list li:nth-child(3) a i {
  font-size: 26.669px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.footer-tom-and-jerry__share__list li:nth-child(3) a::before {
  position: absolute;
  content: '';
  width: 37.856px;
  height: 37.856px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 0;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__share__list {
    margin: 0;
    gap: 49.37px;
  }
  .footer-tom-and-jerry__share__list li a {
    font-size: 48px;
    cursor: pointer;
    -webkit-transition: .5s;
    transition: .5s;
  }
  .footer-tom-and-jerry__share__list li:not(:nth-child(3)) a:hover {
    color: #e1002a;
  }
  .footer-tom-and-jerry__share__list li:nth-child(3) a::before {
    width: 48px;
    height: 48px;
  }
  .footer-tom-and-jerry__share__list li:nth-child(3) a:hover::before {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #e1002a;
  }
}

.footer-tom-and-jerry__share__tom-and-jerry {
  width: 263px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  padding: 30.14px 0 19.14px 0;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__share__tom-and-jerry {
    display: none;
  }
}

.footer-tom-and-jerry__share__copyright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 11.09px;
}

.footer-tom-and-jerry__share__copyright img {
  width: 44.7px;
  height: auto;
}

.footer-tom-and-jerry__share__copyright p {
  margin-bottom: 0;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  color: white;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__share__copyright {
    margin-top: 36.91px;
  }
  .footer-tom-and-jerry__share__copyright p {
    font-size: 12px;
  }
}

.footer-tom-and-jerry__navs-sticky {
  position: absolute;
  width: 100%;
  height: 50px;
  right: 0;
  bottom: 0;
  z-index: 8;
}

.footer-tom-and-jerry__navs-sticky__inner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
}

.footer-tom-and-jerry__navs-sticky__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  max-width: 823px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__navs-sticky {
    height: 70.062px;
  }
  .footer-tom-and-jerry__navs-sticky__gap {
    height: 100px;
  }
  .footer-tom-and-jerry__navs-sticky__actions {
    padding-right: 20px;
    max-width: none;
  }
  .footer-tom-and-jerry__navs-sticky__inner {
    background-color: transparent;
    padding: 0;
  }
}

.footer-tom-and-jerry__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 55px;
  border-radius: 0;
  white-space: nowrap;
  text-align: center;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.footer-tom-and-jerry__btn--primary {
  width: 124px;
  border-radius: 20px 0px 0px 0px;
  border-top: 2px solid #e1002a;
  border-left: 2px solid #e1002a;
  background: #e1002a;
  color: white;
}

.footer-tom-and-jerry__btn--primary:hover {
  color: white;
}

.footer-tom-and-jerry__btn--secondary {
  width: 150px;
  border-radius: 0px 0px 0px 0px;
  border-top: 2px solid #e1002a;
  border-right: 2px solid #e1002a;
  border-left: 2px solid #e1002a;
  background: white;
  color: #e1002a;
}

.footer-tom-and-jerry__btn--secondary:hover {
  color: #e1002a;
}

.footer-tom-and-jerry__btn:hover, .footer-tom-and-jerry__btn:active, .footer-tom-and-jerry__btn:visited, .footer-tom-and-jerry__btn:focus {
  text-decoration: none;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__btn {
    width: 260px;
    height: 70.062px;
    font-size: 16px;
  }
  .footer-tom-and-jerry__btn--primary {
    border-top-left-radius: 20px !important;
  }
  .footer-tom-and-jerry__btn--secondary {
    border-radius: 0px 20px 0px 0px;
    border-top-right-radius: 20px !important;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__btn {
    cursor: pointer;
  }
  .footer-tom-and-jerry__btn--primary:hover, .footer-tom-and-jerry__btn--secondary:hover {
    background-color: black;
    color: white;
    border-color: black;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.footer-tom-and-jerry__shops {
  position: relative;
  background-color: #FDE360;
}

.footer-tom-and-jerry__shops__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.footer-tom-and-jerry__shops__bg img {
  display: block;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__shops__bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer-tom-and-jerry__shops__bg img {
    width: 1114.638px;
    height: auto;
    margin: auto;
  }
}

.footer-tom-and-jerry__shops__title, .footer-tom-and-jerry__shops__list-sp, .footer-tom-and-jerry__shops__list-pc, .footer-tom-and-jerry__shops__footer {
  position: relative;
  z-index: 1;
}

.footer-tom-and-jerry__shops__title {
  width: 100%;
  height: auto;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 75px 0 49px 0;
}

.footer-tom-and-jerry__shops__title img {
  display: block;
  width: 250px;
  height: auto;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__shops__title img {
    width: 403px;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__shops__title {
    padding: 160px 0 94px 0;
  }
  .footer-tom-and-jerry__shops__title img {
    width: 699px;
  }
}

.footer-tom-and-jerry__shops__list-sp {
  display: block;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__shops__list-sp {
    display: none;
  }
}

.footer-tom-and-jerry__shops__list-sp {
  padding: 0 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  margin: 0 auto;
  list-style-type: disc;
  display: block;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__shops__list-sp {
    display: none;
  }
}

.footer-tom-and-jerry__shops__list-sp li {
  color: #682F25;
  font-family: "Zen Kaku Gothic New", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 32px;
  letter-spacing: 0.28px;
  position: relative;
  margin-left: 28px;
  list-style-type: inherit;
}

.footer-tom-and-jerry__shops__list-pc {
  display: none;
}

@media (min-width: 768px) {
  .footer-tom-and-jerry__shops__list-pc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer-tom-and-jerry__shops__list-pc ul {
    padding: 0 20px;
    margin: 0;
    list-style-type: disc;
  }
  .footer-tom-and-jerry__shops__list-pc ul li {
    color: #682F25;
    font-family: "Zen Kaku Gothic New", serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 900 !important;
    line-height: 40px;
    letter-spacing: 0.32px;
    position: relative;
    margin-left: 28px;
    list-style-type: inherit;
  }
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__shops__list-pc ul:nth-child(2) {
    padding-left: 80px;
  }
}

.footer-tom-and-jerry__shops__footer {
  padding: 45px 20px 100px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.footer-tom-and-jerry__shops__footer a {
  max-width: 372px;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__shops__footer {
    padding-top: 90px;
    padding-bottom: 116px;
  }
}

.footer-tom-and-jerry__shops__figure-footer {
  display: none;
}

@media (min-width: 1024px) {
  .footer-tom-and-jerry__shops__figure-footer {
    display: block;
    position: absolute;
    bottom: 0;
    right: 26px;
    width: 263px;
    height: 114px;
  }
}

@media (min-width: 1200px) {
  .footer-tom-and-jerry__shops__figure-footer {
    width: 395px;
    height: 171px;
  }
}
