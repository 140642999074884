.od-retry-payment {
  display: grid;
  padding: 10px 175px 148px;
  grid-template-columns: 2.4fr 1fr;
  gap: 40px;
  background-color: #F7F7F7;
}

.od-retry-payment-bill-details {
  max-width: 360px;
  padding-top: 20px;

  &__title {
    color: #444;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  &__rows {
    display: grid;
    margin-top: 24px;
    margin-bottom: 24px;
    grid-template-columns: auto min-content;
    align-items: center;
    row-gap: 16px;
    column-gap: 32px;
		white-space: nowrap;
  }

  &__total {
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    text-transform: capitalize;
  }

  &__total-price {
    text-align: right;
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
  }

  &__divider {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 4px 0;
    grid-column: span 2;
  }

  &__features {
    display: flex;
    margin-top: 24px;
    align-items: stretch;
    gap: 12px;
  }

  &__feature {
    display: flex;
    padding: 7px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    gap: 8px;
    background: #FAFAFA;
  }

  &__image {
    max-width: 48px;
    max-height: 48px;
  }

  &__feature-title {
    color: #444;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    text-transform: capitalize;
  }

  &__success {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: calc(100vh - 83px);
  }
  
  &__success-image {
    margin-top: -100px;
  }
}
