.lens-colour-freak-store {
  margin: 80px -15px;
}

.lens-colour-freak-store__title h2 {
  font-family: din-condensed, sans-serif;
  font-size: 32px;
  font-weight: bold !important;
  letter-spacing: 0.96px;
  line-height: 1.5;
  margin: 0;
  text-align: center;
}

.lens-colour-freak-store__tabs {
  margin: 40px 0 0 0;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}

.lens-colour-freak-store__tabs li > a {
  outline: none;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 12px;
  border-bottom: 3px solid #CBCEC7;
  width: 100%;
  height: 53px;
}

.lens-colour-freak-store__tabs li > a span {
  font-family: din-condensed, sans-serif;
  font-size: 20px;
  font-weight: bold !important;
  line-height: normal;
  color: black;
}

.lens-colour-freak-store__tabs--active {
  background-color: #CBCEC7;
}

.lens-colour-freak-store__content {
  padding: 30px 15px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lens-colour-freak-store__step-images, .lens-colour-freak-store__step-lens, .lens-colour-freak-store__desc-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lens-colour-freak-store__step-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 23px;
}

.lens-colour-freak-store__step-lens {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  min-height: 30px;
  padding-left: 10px;
  padding-top: 5px;
  margin: 15px auto 30px auto;
}

.lens-colour-freak-store__step-lens::after {
  content: "";
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/line-step.webp");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -15px;
  left: 65%;
  -webkit-transform: translateX(-48%);
          transform: translateX(-48%);
  width: 103%;
  height: 18px;
}

.lens-colour-freak-store__step-lens li {
  font: 400 14px/26px "Noto Sans JP", sans-serif;
  letter-spacing: normal;
  color: black;
}

.lens-colour-freak-store__step-lens li:nth-child(2) {
  font-weight: bold !important;
  color: #555E2B;
}

.lens-colour-freak-store__desc-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.lens-colour-freak-store__desc-list--only-one {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .lens-colour-freak-store__desc-list--only-one {
    position: relative;
  }
  .lens-colour-freak-store__desc-list--only-one > ul {
    position: absolute;
    bottom: 104px;
    right: 0;
  }
}

.lens-colour-freak-store__desc-list li {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid #CBCEC7;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lens-colour-freak-store__desc-list__title, .lens-colour-freak-store__desc-list__value {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: normal !important;
  text-align: center;
  line-height: 18px;
}

.lens-colour-freak-store__desc-list__title {
  font-size: 12px;
  color: #555E2B;
  border-bottom: 1px dashed #555E2B;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.lens-colour-freak-store__desc-list__value {
  font-size: 14px;
  margin-top: 6px;
  color: black;
}

@media (min-width: 1024px) {
  .lens-colour-freak-store {
    margin: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }
  .lens-colour-freak-store__title h2 {
    font-size: 38px;
    letter-spacing: 0;
    line-height: 1.5;
  }
  .lens-colour-freak-store__tabs li > a {
    padding-top: 13px;
  }
  .lens-colour-freak-store__tabs li > a span {
    font-size: 28px;
    letter-spacing: 0.84px;
  }
  .lens-colour-freak-store__step-images, .lens-colour-freak-store__step-lens, .lens-colour-freak-store__desc-list {
    max-width: 710px;
  }
  .lens-colour-freak-store__step-images {
    gap: 110px;
  }
  .lens-colour-freak-store__step-lens {
    gap: 157.5px;
  }
  .lens-colour-freak-store__step-lens::after {
    content: "";
    background: url("https://static.lenskart.com/media/owndays/img/freaks-store/line-step-pc.webp");
    background-size: cover;
    width: 424px;
    height: 20px;
    top: -30px;
    left: 50%;
  }
  .lens-colour-freak-store__step-lens li {
    font-size: 16px;
  }
  .lens-colour-freak-store__desc-list {
    gap: 20px;
  }
  .lens-colour-freak-store__desc-list li {
    width: 130px;
    height: 130px;
    border-radius: 100px;
  }
  .lens-colour-freak-store__desc-list__title {
    font-size: 14px;
  }
  .lens-colour-freak-store__desc-list__value {
    font-size: 18px;
  }
}
