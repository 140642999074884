.cart-content [class^="icon-"],
.cart-content [class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 32px;
  line-height: 14px;
  background-image: url(//static.lenskart.com/skin/frontend/base/default/img/sprite-v2.png);
  background-repeat: no-repeat;
  vertical-align: middle;
}
