.od-payment-success-d {
  display: grid;
  padding: 40px 100px 36px 100px;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  background-color: #fafafa;
}

.od-payment-success-d__content-wrapper {
  padding: 0px 40px;
  width: 50%;
}

.od-payment-success-d-left__title {
  color: #444;
  font-size: 24px;
  letter-spacing: -0.48px;
}

.od-payment-success-d-banner {
  padding: 55px 40px;
  position: relative;
  background: url("https://static1.lenskart.com/owndays/img/payment-success/order-confirm-banner-pc.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 24px;
}

.od-payment-success-d-banner__title {
  color: #fff;
  font-size: 32px;
  line-height: 28px;
  letter-spacing: -0.64px;
}

.od-payment-success-d-banner__description {
  margin-top: 16px;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.4px;
  max-width: 424px;
}

.od-payment-success-d-banner__btn {
  margin-top: 40px;
  padding: 12px 24px;
  border-radius: 60px;
  background: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  cursor: pointer;
}

.od-payment-success-d-banner__btn span {
  color: #546073;
  font-size: 20px;
  line-height: 24px;
}

.od-payment-success-d-next {
  padding: 34px 40px;
  margin-top: 24px;
  background-color: #fff;
}

.od-payment-success-d-next__title {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.64px;
}

.od-payment-success-d-next__description {
  margin-top: 15px;
  color: #000;
  font-size: 20px;
  line-height: 35px;
}

.od-payment-success-d-next__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  margin-top: 39px;
}

.od-payment-success-d-next__btns__divider {
  color: #000;
  font-size: 20px;
}

.od-payment-success-d-next__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 16px;
  padding: 18px 40px;
  color: #546073;
  font-size: 16px;
  line-height: 24px;
  border-radius: 60px;
  background: #e9eef6;
  border: none;
}

.od-payment-success-d-prescriptions__title {
  margin-bottom: 14px;
  color: #444;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.48px;
}

.od-payment-success-d-prescription-card {
  display: grid;
  padding: 16px 24px;
  margin-bottom: 16px;
  grid-template-columns: 291fr 328fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
}

.od-payment-success-d-prescription-card__thumbnail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-payment-success-d-prescription-card__thumbnail {
  max-width: 100%;
}

.od-payment-success-d-prescription-card__order-no {
  color: rgba(68, 68, 68, .5);
  font-size: 14px;
  line-height: 18px;
}

.od-payment-success-d-prescription-card__name {
  margin-top: 8px;
  color: rgba(68, 68, 68, .7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.od-payment-success-d-prescription-card__coating {
  margin-top: 8px;
  color: rgba(68, 68, 68, .5);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.28px;
}

.od-payment-success-d-prescription-card__divider {
  border: none;
  margin-top: 16px;
}

.od-payment-success-d-prescription-card__button {
  width: 100%;
  padding: 12px 20px;
  background-color: #546073;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.od-payment-success-d-prescription-card__button:hover {
  background-color: #546073;
  color: #fff;
}

.od-payment-success-d-prescription-card__status {
  color: rgba(68, 68, 68, .7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.od-payment-success-d-container-img {
  max-width: 150px;
  height: auto;
}

.upload-prescription-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 16px;
  width: 60%;
}

.upload-prescription-card__thumbnail {
  width: 40%;
}

.upload-prescription-card__thumbnail img {
  height: 120px;
}

.upload-prescription-card__label {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.upload-prescription-card__name {
  color: #444;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.32px;
}
