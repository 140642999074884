$font-founders: 'Founders Grotesk', sans-serif;

$black: #000;
$gray-99: #999;

.base-lens-replacement {
  &__container {
    width: 100%;
    max-width: 1296px;
    height: auto;
    padding: 0 5%;
    margin: 0 auto;
    letter-spacing: 1px;
  }

  &__content {
    padding: 0 15px;
    margin: 25px 0;

    &--inner {
      width: 100%;
      max-width: 1152px;
      border: 1px solid #e3e3e3;
      margin-top: 25px;

      h2 {
        font-family: $font-founders;
        font-size: 18px;
        text-align: center;
        border-bottom: 1px solid #e3e3e3;
        padding: 18px 0 13px;
        line-height: 1;
        margin-block: 0;
        color: $black;
        font-weight: 400;
        letter-spacing: 1px;
      }
      
      @media (min-width: 768px) {
        width: 80%;
        margin: 80px auto 0 auto;
      }
    }

    @media (min-width: 768px) {
      padding: 0 24px;
      margin: 40px 0;
    }
  }

  &__list-remark {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    

    li {
      font-family: $font-founders;
      font-weight: normal !important;
      line-height: 2;
      letter-spacing: 1px;
      color: $gray-99;
      padding-left: 20px;
      position: relative;
      padding-top: 5px;

      &::before {
        content: '*';
        font-size: 14px;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
}
