.contact-lens-card {
  max-width: 320px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  cursor: pointer;
}

.contact-lens-card__img {
  width: 320px;
}

.contact-lens-card__title {
  font-size: 14px;
  color: #000;
}

.contact-lens-card:hover {
  opacity: 0.5;
}
