.owndays-btn {
    display: block;
    padding: 12px;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    font-size: 14px;
    line-height: 120%;

    &:focus,
    &:hover {
        text-decoration: none;
    }

    &--white {
        background: #FFFFFF;
        border-color: #555555;
        color: #555555;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #555555;
        }
    }

    &--white-no-hover:hover {
        background-color: #FFFFFF;
        color: #555555;
    }

    &--black {
        background: #000;
        backdrop-filter: blur(7px);
        color: #FFF;

        &:active,
        &:hover {
            color: #000;
            background-color: #FFF;
        }
    }

    &--black-no-hover:hover {
        background-color: #000;
        color: #FFF;
    }

    &--white-secondary {
        background: #FFFFFF;
        color: #000000;
        border: none;
        opacity: 0.7;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #555555;
        }
    }

    &--white-secondary-no-hover:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    &--white-ternary {
        background: #FFFFFF;
        border: 1px solid #555555;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #555555;
        }
    }

    &--white-ternary-no-hover:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    &--white-4 {
        background: #FFFFFF;
        color: #000000;
        border: 1px solid #000;
        box-shadow: 0px 4px 9px 0px #000;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #555555;
        }
    }

    &--white-4-no-hover:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    &--white-5 {
        background: #FFFFFF;
        color: #000000;
        border: 1px solid #000;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #000;
        }
    }

    &--white-5-no-hover:hover {
        background-color: #FFFFFF;
        color: #000000;
    }

    &--white-6 {
        background: #FFFFFF;
        color: #444;
        border: 1px solid #546073;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #546073;
        }
    }

    &--white-6-no-hover:hover {
        background-color: #FFFFFF;
        color: #444;
    }

    &--white-7 {
        background-color: #FFFFFF;
        color: #444;
        border: 1px solid #444;
    }

    &--white-8 {
        background-color: #FFFFFF;
        color: #546073;
        border: none;
    }

    &--transparent-light {
        background: transparent;
        border-color: #555555;
        color: #555555;

        &:active,
        &:hover {
            color: #ffffff;
            background-color: #555555;
        }
    }

    &--transparent-light-no-hover:hover {
        background-color: transparent;
        color: #555555;
    }

    &--grey {
        background: #546073;
        border-color: #555555;
        color: #ffffff;

        &:active,
        &:hover {
            color: #fff;
            background-color: #546073;
        }
    }

    &--borderless {
        border-width: 0;
    }

    &--grey-no-hover:hover {
        background-color: #546073;
        color: #ffffff;
    }

    &--grey-secondary {
        background: #FAFAFA;
        color: #546073;

        &:active,
        &:hover {
            color: #FAFAFA;
            background-color: #546073;
        }
    }

    &--grey-secondary-no-hover:hover {
        background-color: #FFFFFF;
        color: #555555;
    }

    &--grey-tertiary {
        background: #5460731A;
        color: #546073;

        &:active,
        &:hover {
            color: #fff;
            background-color: #546073;
        }
    }

    &--grey-tertiary-no-hover:hover {
        background-color: #FFFFFF;
        color: #555555;
    }

    &--oval-white {
        display: flex;
        border: solid #fff 1px;
        border-radius: 9999px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: #000;
        background-color: #fff;
        transition: border-color 0.15s ease-out, background-color 0.15s ease-out;

        &:hover {
            border-color: rgba(255,255,255,0.75);
            background-color: rgba(255,255,255,0.75);
        }
    }
    
    &--oval-black {
        display: flex;
        border: solid #fff 1px;
        border-radius: 9999px;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #000;
        transition: border-color 0.15s ease-out, background-color 0.15s ease-out;

        &:hover {
            border-color: rgba(255,255,255,0.75);
            background-color: rgba(255,255,255,0.75);
        }
    }

    &--oval-transparent {
        @extend .owndays-btn--oval-black;
        border: solid #fff 2px;
        color: #fff;
        background-color: transparent;
        transition: border-color 0.15s ease-out, color 0.15s ease-out;
        
        &:hover {
            border-color: #fff;
            color: #fff;
        }
    }

    &--oval-grey {
        display: flex;
        border: solid #546073 1px;
        border-radius: 9999px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: #fff;
        background-color: #546073;
        transition: background-color 0.15s ease-out;

        &:hover {
            border-color: rgba(84, 96, 115, 0.75);
            background-color: rgba(84, 96, 115, 0.75);
        }
    }
    &--secondary{
        font-family: 'Founders Grotesk';
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        padding: 16px 40px 17px;
        border-radius: 10rem;
        border-color: #546073;
        color: #546073;
        background-color: #ffffff;
        transition: all 0.15s ease-out;
        &:hover{
            background-color: #546073;
            color: #fff;
        }
    }

    &:disabled {
        background-color: #C5C5C5;
        border: none;

        &:hover {
            background: #546073;
            color: #fff;
            cursor: not-allowed;
        }
    }
}

.owndays-btn-font--mobile {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
}
