@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap");
@import url("https://use.typekit.net/ikm7woc.css");
@font-face {
  font-family: "Karu";
  src:
    url("https://static.lenskart.com/owndays/fonts/Karu/karu-black.woff2") format("woff2"),
    url("https://static.lenskart.com/owndays/fonts/Karu/karu-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GenEi POPle Pw";
  src:
    url("https://static.lenskart.com/owndays/fonts/GenEiPOPlePw/GenEiPOPlePw-Bk.woff2") format("woff2"),
    url("https://static.lenskart.com/owndays/fonts/GenEiPOPlePw/GenEiPOPlePw-Bk.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

$font-zen: "Zen Kaku Gothic New", serif;
$font-marvin: marvin, sans-serif;
$font-karu: "Karu";
$font-genei: "GenEi POPle Pw";

$base-img-url: 'https://static.lenskart.com/owndays/img/tomandjerry';

$red-e1: #e1002a;
$grey-bd: #bdbdbd;


.base-tom-and-jerry {
  overflow: hidden;

  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1152px + (20px * 2));
  }

  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    background: black url("#{$base-img-url}/btn-arrow.svg") no-repeat right 26px center/8px auto;
    color: white;
    border-radius: 10rem;
    padding-inline: 36px;
    font-size: 15px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 0.45px;
    transition: 0.3s cubic-bezier(0.5, 1, 0.89, 1);

    &:hover,
    &:focus {
      background-color: black;
      color: white;
    }
    &[disabled] {
      background-color: $grey-bd;
      pointer-events: none;
    }

    @media (min-width: 768px) {
      font-size: 16px;
      letter-spacing: 0.48px;
    }

    @media (min-width: 1024px) {
      &:hover, &:active, &:visited, &:focus {
        text-decoration: none;
      }

      &:hover {
        font-size: 18px;
        letter-spacing: 0.54px;
        background-color: $red-e1;
        transform: scale(1.1);
      }
    }
  }
}
