$font-founders: 'Founders Grotesk', sans-serif;
$font-hiragino: 'HiraginoMinchoPro', sans-serif;

.body-senichisaku {
  &__bs {
    padding-top: 13px;
    padding-bottom: 130px;

    &__title {
      font-size: 24px;
      letter-spacing: 0.15em;
      margin-bottom: 50px;
      line-height: 1.6;

      small {
        color: white;
        display: block;
        font-size: 11px;
        letter-spacing: 0.08em;
      }
    }

    &__img {
      &--glasses {
        margin-bottom: 70px;
      }

      &--town {
        position: relative;
        margin-bottom: 60px;
      }

      &--frame{
        max-width: 1050px;
        margin-top: 130px;
        margin-left: auto;
        margin-right: -5.5%;
      }
    }

    &__subtitle {
      font-size: 24px;
      margin-bottom: 50px;
      line-height: 1.6;
    }

    &__text {
      margin-bottom: 70px;
    }

    &__slider {
      margin-bottom: 70px;

      &-item{
        padding-left: 1px;
        padding-right: 1px;

        img {
          width: 100%;
        }
      }
    }

    .base-senichisaku__btn-group {
      margin-bottom: 130px;
    }

    @media (max-width: 767px) {
      &__img {
        &--town {
          margin-left: -5.5%;
          margin-right: -5.5%;
        }
      }
    }
    
    @media (min-width: 768px) {
      &__title, &__subtitle {
        line-height: 1.9;
      }
    }

    @media (min-width: 1024px) {
      padding-top: 80px;
      padding-bottom: 200px;

      &__title{
        font-style: 32px;
        margin-bottom: 80px;

        small{
          font-size: 13px;
        }
      }

      &__subtitle{
        font-size: 32px;
        margin-bottom: 100px;
      }

      &__text{
        margin-bottom: 130px;
      }

      &__img{
        &--glasses{
          margin-bottom: 130px;
        }

        &--town {
          margin-bottom: 100px;
        }

        &--frame{
          margin-top: 200px;
        }
      }

      &__slider{
        margin-bottom: 130px;

        &-item{
          padding-left: 4px;
          padding-right: 4px;
        }
      }

      .base-senichisaku__btn-group {
        margin-bottom: 200px;
      }
    }
  }

  &__craftsmen {
    padding-bottom: 80px;

    &__top {
      position: relative;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 50px;
      line-height: 1.6;
    }

    &__text {
      margin-bottom: 70px;
    }

    &__block {
      margin-bottom: 50px;

      &-title {
        font-size: 18px;
        margin-bottom: 30px;
        position: relative;
        padding-left: calc(40px + 14px);
        line-height: 1.6;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          border-top: 1px solid white;
          width: 40px;
        }
      }

      &--odd {
        .body-senichisaku__craftsmen__block-img {
          margin-left: -5.5%;
        }
      }

      &--even {
        .body-senichisaku__craftsmen__block-img {
          margin-right: -5.5%;
        }
      }
    }

    @media (max-width: 1023px) {
      &__top {
        > .base-senichisaku__container {
          margin-top: -200px;
        }
      }

      &__block {
        &-img {
          margin-bottom: 50px;
        }
      }
    }

    @media (min-width: 768px) {
      &__title, &__block-title {
        line-height: 1.9;
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: 100px;

      &__top {
        > .base-senichisaku__container {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      &__title {
        font-size: 32px;
        margin-bottom: 100px;
      }

      &__text {
        margin-bottom: 140px;
      }

      &__block {
        display: flex;
        max-width: 1173px;
        margin-bottom: 100px;
        &-details {
          padding-top: 90px;
        }

        &-img {
          flex-shrink: 0;
          width: 50%;
          max-width: 633px;
        }

        &-title {
          font-size: 28px;
          margin-bottom: 40px;
        }

        &-text {
          font-size: 16px;
        }

        &--odd {
          margin-right: auto;

          .body-senichisaku__craftsmen__block-details {
            padding-left: 80px;
          }
        }
        
        &--even {
          margin-left: auto;
          flex-direction: row-reverse;

          .body-senichisaku__craftsmen__block-details {
            padding-right: 80px;
            padding-left: 12px;
          }
        }
      }
    }
  }

  &__process {
    padding-bottom: 80px;

    picture {
      display: block;
  
      img {
        width: 100%;
        height: auto;
      }
    }

    &__cover {
      margin-bottom: 70px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 50px;
      line-height: 1.6;
    }

    &__block {
      margin-bottom: 50px;

      &-title {
        font: 300 13px $font-hiragino;
        letter-spacing: 0.015em;
        margin-bottom: 10px;
      }

      &-text {
        font: 300 11px $font-hiragino;
        line-height: 1.9;
        margin-bottom: 20px;
      }

      &-media{
        pointer-events: none;
      }
    }

    @media (max-width: 1023px) {
      &__block {
        &-media {
          margin-bottom: 25px;
        }

        &--sm,
        &--sm2 {
          padding-left: 28px;
          padding-right: 28px;
        }

        &--lg {
          .body-senichisaku__process__block-media {
            margin-left: -5.5%;
            margin-right: -5.5%;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: 140px;

      &__cover {
        margin-bottom: 130px;
      }

      &__title {
        font-size: 32px;
        margin-bottom: 100px;
      }

      &__block {
        margin-bottom: 60px;
        display: flex;
        align-items: center;

        &-title {
          font-size: 20px;
        }

        &-text {
          font-size: 13px;
          line-height: 2.2;
        }

        &--odd {
          .body-senichisaku__process__block-details {
            padding-left: 67px;
            padding-right: 80px;
            max-width: 69%;
          }
        }

        &--even {
          flex-direction: row-reverse;
          .body-senichisaku__process__block-details {
            padding-right: 67px;
          }
        }

        &--sm {
          .body-senichisaku__process__block-media {
            max-width: 817px;
          }
        }

        &--sm2 {
          .body-senichisaku__process__block-media {
            max-width: 364px;
          }
          .body-senichisaku__process__block-details {
            max-width: 53%;
          }
        }

        &--md {
          .body-senichisaku__process__block-media {
            max-width: 492px;
          }

          .body-senichisaku__process__block-details {
            padding-left: 45px;
            max-width: 57.3%;
          }
        }
      }
    }
  }
}
