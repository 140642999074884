.form-policy-with-email__container {
  padding: 16px 24px 40px 24px;
  margin: 0 auto;
}

@media (min-width: 430px) {
  .form-policy-with-email__container {
    padding: 16px 40px 40px 40px;
  }
}

@media (min-width: 768px) {
  .form-policy-with-email__container {
    padding: 16px 100px 40px 100px;
  }
}

@media (min-width: 1024px) {
  .form-policy-with-email__container {
    padding: 16px 140px 40px 140px;
  }
}

@media (min-width: 1296px) {
  .form-policy-with-email__container {
    padding: 40px 200px 80px 200px;
  }
}

.form-policy-with-email__box {
  border-radius: 6px;
  border: 1px solid #EBEDF3;
  padding: 16px 24px;
}

.form-policy-with-email__box:first-child {
  margin-bottom: 24px;
}

@media (min-width: 1076px) {
  .form-policy-with-email__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.form-policy-with-email__question, .form-policy-with-email__name {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 32px;
  color: #000;
  margin: 0;
}

@media (min-width: 768px) {
  .form-policy-with-email__question, .form-policy-with-email__name {
    font-size: 20px;
    line-height: 40px;
  }
}

.form-policy-with-email__agree-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.form-policy-with-email__list {
  list-style-type: none;
  margin: 14px 0 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 1076px) {
  .form-policy-with-email__list {
    margin-top: 0;
    row-gap: 16px;
  }
}

.form-policy-with-email__item {
  list-style-type: none;
  display: grid;
  grid-template-columns: 24px 1fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}

.form-policy-with-email__radio, .form-policy-with-email__checkbox {
  width: 24px;
  height: 24px;
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
}

.form-policy-with-email__radio:checked, .form-policy-with-email__checkbox:checked {
  accent-color: #000;
}

.form-policy-with-email__input {
  width: 100%;
  height: 48px;
  padding: 14px;
  background-color: #F4F4F4;
  border: 0;
  font-family: "Founders Grotesk", sans-serif;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-top: -8px;
}

@media (min-width: 1076px) {
  .form-policy-with-email__input {
    width: 420px;
    height: 48px;
    padding: 14px 16px;
    margin-top: 0;
  }
}

.form-policy-with-email__input::-webkit-input-placeholder {
  font-family: "Founders Grotesk", sans-serif;
  color: #999;
}

.form-policy-with-email__input::-moz-placeholder {
  font-family: "Founders Grotesk", sans-serif;
  color: #999;
}

.form-policy-with-email__input::-ms-input-placeholder {
  font-family: "Founders Grotesk", sans-serif;
  color: #999;
}

.form-policy-with-email__input::placeholder {
  font-family: "Founders Grotesk", sans-serif;
  color: #999;
}

.form-policy-with-email__confirm-button {
  width: 100%;
  max-width: 377px;
  height: 50px;
  margin: 24px auto 0 auto;
  border-radius: 30px;
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 30px;
  padding: 0 24px;
  background-color: #546073;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 0;
}

.form-policy-with-email__confirm-button--inactive {
  cursor: not-allowed;
  opacity: 0.5;
}

.form-policy-with-email__confirm-button--active {
  cursor: pointer;
}

@media (min-width: 430px) {
  .form-policy-with-email__confirm-button {
    max-width: 320px;
  }
}

@media (min-width: 768px) {
  .form-policy-with-email__confirm-button {
    margin: 48px auto 0 auto;
  }
}
