.od-payment-success-d {
  display: grid;
  padding: 40px 100px 36px 100px;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  background-color: #fafafa;

  &__content-wrapper {
    padding: 0px 40px;
    width: 50%;
  }
}

.od-payment-success-d-left {
  &__title {
    color: #444;
    font-size: 24px;
    letter-spacing: -0.48px;
  }
}

.od-payment-success-d-banner {
  padding: 55px 40px;
  position: relative;
  background: url('https://static1.lenskart.com/owndays/img/payment-success/order-confirm-banner-pc.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 24px;

  &__title {
    color: #fff;
    font-size: 32px;
    line-height: 28px;
    letter-spacing: -0.64px;
  }

  &__description {
    margin-top: 16px;
    color: #fff;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
    max-width: 424px;
  }

  &__btn {
    margin-top: 40px;
    padding: 12px 24px;
    border-radius: 60px;
    background: #fff;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    span {
      color: #546073;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.od-payment-success-d-next {
  padding: 34px 40px;
  margin-top: 24px;
  background-color: #fff;

  &__title {
    color: #000;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.64px;
  }

  &__description {
    margin-top: 15px;
    color: #000;
    font-size: 20px;
    line-height: 35px;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 39px;

    &__divider {
      color: #000;
      font-size: 20px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 18px 40px;
    color: #546073;
    font-size: 16px;
    line-height: 24px;
    border-radius: 60px;
    background: #e9eef6;
    border: none;
  }
}

.od-payment-success-d-prescriptions {
  &__title {
    margin-bottom: 14px;
    color: #444;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.48px;
  }
}

.od-payment-success-d-prescription-card {
  display: grid;
  padding: 16px 24px;
  margin-bottom: 16px;
  grid-template-columns: 291fr 328fr;
  align-items: center;
  background-color: #fff;

  &__thumbnail-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__thumbnail {
    max-width: 100%;
  }

  &__order-no {
    color: rgba(68, 68, 68, 0.5);
    font-size: 14px;
    line-height: 18px;
  }

  &__name {
    margin-top: 8px;
    color: rgba(68, 68, 68, 0.7);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  &__coating {
    margin-top: 8px;
    color: rgba(68, 68, 68, 0.5);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.28px;
  }

  &__divider {
    border: none;
    margin-top: 16px;
  }

  &__button {
    width: 100%;
    padding: 12px 20px;
    background-color: #546073;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    cursor: pointer;

    &:hover {
      background-color: #546073;
      color: #fff;
    }
  }

  &__status {
    color: rgba(68, 68, 68, 0.7);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}

.od-payment-success-d-container-img {
  max-width: 150px;
  height: auto;
}

.upload-prescription-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 16px;

  &__thumbnail {
    width: 40%;
    img {
      height: 120px;
    }
  }

  &__label {
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  &__name {
    color: #444;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.32px;
  }
  width: 60%;
}
