.odslv-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;

  .select-lens-page-title {
    color: #444;
    font-size: 24px;
    letter-spacing: -0.48px;
    font-family: 'Founders Grotesk', sans-serif;
    margin: 8px 0 40px 0;
  }
  
  &__right {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    top: 0;

    &__main {
      height: 100%;
      position: relative;
      padding-bottom:120px;
      overflow-y: auto;
      &__content {
        padding: 61px 100px 0;

        @media (max-width: 768px) {
          padding: 24px 16px;
        }
      }
    }
    &__footer {
      width: 100%;
      bottom: 0;
      position: fixed;

      &--content {
        font-family: 'NeuzeitGroLig', sans-serif;
        color: #444444;
        opacity: 0.5;
        padding: 0px 30px;
        font-size: 18px;
        margin-bottom: 20px;
      }
      &--action {
        border-radius: 10px 10px 0 0;
        background: #FFF;
        box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0.00), 0px -21px 8px 0px rgba(0, 0, 0, 0.01), 0px -12px 7px 0px rgba(0, 0, 0, 0.02), 0px -5px 5px 0px rgba(0, 0, 0, 0.03), 0px -1px 3px 0px rgba(0, 0, 0, 0.04);
        padding: 24px 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
          padding: 16px;
        }

        &-info {
          flex: 1 0 0;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
          }

          &__price {
            color: #444;
            font-size: 24px;
            letter-spacing: -0.48px;

            @media (max-width: 768px) {
              font-feature-settings: 'clig' off, 'liga' off;
              font-size: 20px;
            }
          }

          &__description {
            color: #444;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 13px;
            line-height: 20px;
            opacity: 0.5;

            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }

        button {
          flex: 1 0 0;
        }
      }
    }
  }
}

.od-fram-only-description {
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 16px;
  margin-top: 8px;
  padding: 16px;
  h1 {
    color: #000;
    margin-bottom: 32px;
    font-size: 26px;
    margin-top: 0px;
    font-family: 'Founders Grotesk', sans-serif;
  }
  ul {
    color: #444;
    opacity: 0.5;
    padding-left: 16px;
    li {
      list-style: disc;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
