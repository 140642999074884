$font-founders: "Founders Grotesk", sans-serif;

$border-e3: #e3e3e3;
$border-f4: #f4f4f4;
$blue: #0094c8;
$gray: #999;
$gray-d6: #D6D6D6;

.footer-eye-camp {
  &__history {
    border-top: 1px solid $border-e3;
    padding: 40px 0 60px;

    @media (min-width: 768px) {
      padding: 90px 0 120px;
    }

    &__container {
      width: 80%;
      height: auto;
      max-width: 1152px;
      margin: 0 auto;
    }

    &__title {
      font-family: $font-founders;
      font-size: 24px;
      font-weight: 300 !important;
      text-align: center;
      line-height: 1.6;
      padding: 0;
      margin-bottom: 0;

      @media (min-width: 768px) {
        font-size: 26px;
        line-height: 1.9;
      }
    }

    &__list {
      list-style-type: none;
      margin: 20px -16px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      padding: 0;

      li > a {
        transition: 0.5s;

        &:hover, &:active, &:visited, &:focus {
          text-decoration: none;
        }   
      }

      @media (min-width: 768px) {
        margin: 50px -10px 0;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    &__card, &__card-no-more {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $border-f4;

      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
      }

      &::before {
        transition: all .5s ease;
        background-color: rgba(0, 0, 0, .4);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        border: 1px solid $border-f4;
      }

      div {
        font-family: $font-founders;
        font-size: 16px;
        text-align: center;
        line-height: 1.1;
        padding: 20px 10px;
        color: black;

        span {
          font-weight: 700 !important;
          display: block;
          padding-right: 0;
        }

        p {
          font-size: 11px;
          margin: 4px 0 0 0;
        }

        @media (min-width: 768px) {
          span {
            padding-right: 5px;
            display: inline-block;
          }
        }
      }
    }

    &__card {
      position: relative;
      
      &::after {
        border: 25px solid transparent;
        border-left: 25px solid $blue;
        right: -25px;
        bottom: -25px;
        transform: rotate(45deg);
      }

      &--clicked {
        &::before, img {
          opacity: 0.6;
        }
      }

      @media (min-width: 1024px) {
        cursor: pointer;

        &:hover {
          &::before, img {
            opacity: 0.6;
          }
        }
      }
    }

    &__plus {
      position: absolute;
      right: 5px;
      bottom: 8px;
      z-index: 1;
      width: 10px;
      height: 10px;

      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: white;
      }

      &::before {
        transition: transform .25s ease-out, -webkit-transform .25s ease-out;
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
      }

      &::after {
        transition: transform .25s ease-out, -webkit-transform .25s ease-out;
        margin-top: -1px;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
      }
    }

    &__icon-search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      opacity: 0;
      z-index: 2;
      color: white;
      width: 27px !important;
      height: 27px !important;
      transition: all .5s ease;
      border: 0 !important;

      &--show {
        opacity: 1;
      }

      @media (min-width: 768px) {
        width: 52px !important;
        height: 52px !important;
      }
    }
  }

  &__history-detail {
    &__card-detail {
      position: relative;
      width: 100%;
      height: auto;
      max-height: 0;
      background-color: $border-f4;
      grid-column: 1 / span 2;
      overflow: hidden;
      opacity: 0;
      padding: 25px 15px;
      transition: height 0.3s ease-in-out;

      &--show {
        opacity: 1;
      }

      &--hide {
        opacity: 0;
        max-height: 0;
      }

      @media (min-width: 768px) {
        grid-column: 1 / span 3;

        &--show {
          padding: 60px 50px 50px;
        }
      }

      @media (min-width: 1024px) {
        grid-column: 1 / span 4;
      }
    }

    &__close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      svg {
        fill: $gray;
      }
    }

    &__header {
      font-family: $font-founders;
      font-size: 16px;
      line-height: 1.3;
      text-align: center;
      color: black;
      font-weight: 400 !important;
      margin: 0 0 20px 0;
    
      &--country {
        display: block;
        font-weight: 700 !important;
      }

      &--date {
        margin-top: 3px;
        font-size: 14px;
        font-weight: 400 !important;
        display: block;
      }

      @media (min-width: 768px) {
        font-size: 20px;
        margin: 0 0 30px 0;

        &--country {
          display: inline-block;
          padding-right: 10px;
        }

        &--date {
          margin-top: 6px;
        }
      }
    }

    &__message {
      &__text {
        font-family: $font-founders;
        font-size: 15px;
        font-weight: normal !important;
        letter-spacing: 1px;
        padding-bottom: 25px;
        color: black;
        text-align: left;
        margin: 0;
      }
    }

    &__opinions {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
      list-style-type: none;
      margin: 20px 0 0 0;
      border-top: 2px dotted black;
      padding: 40px 0 20px 0;

      &__header {
        display: flex;
        align-items: center;
        gap: 17px;
        margin-bottom: 15px;

        img {
          width: 60px;
          height: auto;
          border-radius: 100%;
        }

        div {
          h4, p {
            margin: 0;
            font-family: $font-founders;
            color: black;
          }

          h4 {
            font-weight: 700 !important;
            font-size: 17px;
            line-height: 1.5;
          }

          p {
            display: flex;
            font-size: 11px;
            align-items: center;
            font-weight: 700 !important;
            margin: 0;

            img {
              width: 24px;
              height: 16.79px;
              margin-right: 7px;
              border: 1px solid $border-e3;
              border-radius: 0;
            }
          }
        }
      }

      &__comment {
        color: black;
        font-family: $font-founders;
        font-size: 12px;
        font-weight: normal !important;
        letter-spacing: 1px;
        background-color: white;
        line-height: 2;
        padding: 20px;
        border-radius: 20px;
        position: relative;
        margin: 0;

        &::before {
          content: "";
          position: absolute;
          left: 21px;
          top: -11px;
          display: block;
          width: 0;
          height: 0;
          border-right: 9px solid transparent;
          border-bottom: 13px solid white;
          border-left: 9px solid transparent;
        }
      }

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__custom-slider {
      .slick-slide {
        padding: 0 2.5px;
        box-sizing: border-box;
      }
    
      .slick-list {
        margin: 0 -2.5px;
      }
    
      .slick-dots {
        display: flex !important;
        list-style-type: none;
        justify-content: center;
        padding: 12px 0 20px 0;

        li {
          width: 10px;
          margin: 5px 7px;
    
          button {
            width: 10px;
            height: 10px;
            background: $gray-d6;
            display: block;
            transition: opacity 200ms ease;
            border-radius: 0;
            font-size: 0;
            color: transparent;
            border: 0;
            box-sizing: border-box;
    
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
    
          &.slick-active button {
            background: $gray;
          }
        }
      }
    }

    &__iframe-container {
      width: 100%;
      height: auto;
      display: inline-block;
      aspect-ratio: 16/9;
      margin-top: 20px;

      iframe {
        margin: 0;
      }

      @media (min-width: 768px) {
        margin-top: 35px;
      }
    }
  }
}
