.body-anniversary-10th-collection__lineup {
  margin-top: 60px;
}

.body-anniversary-10th-collection__lineup #metal {
  margin-top: 80px;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup > div > .base-anniversary-10th-collection__container {
    position: relative;
  }
  .body-anniversary-10th-collection__lineup #plastic > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__title {
    right: 40px;
  }
  .body-anniversary-10th-collection__lineup #metal > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__title {
    left: 40px;
  }
}

@media (min-width: 961px) {
  .body-anniversary-10th-collection__lineup > div > .base-anniversary-10th-collection__container {
    max-width: none;
  }
}

@media (min-width: 1240px) {
  .body-anniversary-10th-collection__lineup > div > .base-anniversary-10th-collection__container {
    max-width: 1120px;
  }
}

@media (min-width: 1240px) {
  .body-anniversary-10th-collection__lineup #plastic > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__banner {
    margin-right: calc(520px - 50vw);
  }
  .body-anniversary-10th-collection__lineup #metal > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__banner {
    margin-left: calc(520px - 50vw);
  }
}

.body-anniversary-10th-collection__lineup__title {
  font-size: 30px;
  text-align: center;
  color: #815624;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__title {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
  }
  .body-anniversary-10th-collection__lineup__title h3 {
    margin: 0;
  }
}

.body-anniversary-10th-collection__lineup__banner .base-anniversary-10th-collection__frame {
  margin-top: 0;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__banner .base-anniversary-10th-collection__frame {
    width: calc(100% + 80px);
    margin: 188px -40px 0 -40px;
  }
}

.body-anniversary-10th-collection__lineup__content__detail {
  margin-top: 60px;
}

.body-anniversary-10th-collection__lineup__content__detail .base-anniversary-10th-collection__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.body-anniversary-10th-collection__lineup__content__detail__sku {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 23px;
  margin-top: 20px;
}

.body-anniversary-10th-collection__lineup__content__detail__sku h4 {
  font-size: 20px;
  line-height: 1.5em;
  font-family: "Miller-Banner";
  letter-spacing: 1px;
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}

.body-anniversary-10th-collection__lineup__content__detail__desc {
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
  margin-bottom: 0;
  color: black;
}

.body-anniversary-10th-collection__lineup__content__chips {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.body-anniversary-10th-collection__lineup__content__chips li {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  position: relative;
}

@media (min-width: 1024px) {
  .body-anniversary-10th-collection__lineup__content__chips li {
    cursor: pointer;
  }
}

.body-anniversary-10th-collection__lineup__content__chips li[data-color="clear-grey"] {
  background-color: #A9A6B1;
}

.body-anniversary-10th-collection__lineup__content__chips li[data-color="clear-brown"] {
  background-color: #685333;
}

.body-anniversary-10th-collection__lineup__content__chips li[data-color="navy-blue"] {
  background-color: #48597D;
}

.body-anniversary-10th-collection__lineup__content__chips li[data-color="olive-green"] {
  background-color: #324924;
}

.body-anniversary-10th-collection__lineup__content__chips li[data-color="gunmetal"] {
  background-color: #5B564F;
}

.body-anniversary-10th-collection__lineup__content__chips li[data-color="silver"] {
  background-color: #9FA4A8;
}

.body-anniversary-10th-collection__lineup__content__chips__active::after {
  content: "";
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: -1;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__content__detail {
    margin-top: 107px;
  }
  .body-anniversary-10th-collection__lineup__content__detail .base-anniversary-10th-collection__container {
    display: grid;
  }
  .body-anniversary-10th-collection__lineup__content__detail__sku {
    margin-top: 0;
  }
  .body-anniversary-10th-collection__lineup__content__detail__desc {
    margin-top: 23px;
    width: 56%;
    letter-spacing: 0.48px;
  }
}

.body-anniversary-10th-collection__lineup__img-layout {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__img-layout {
    margin-top: 46px;
  }
  .body-anniversary-10th-collection__lineup__img-layout .base-anniversary-10th-collection__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .body-anniversary-10th-collection__lineup__img-layout .base-anniversary-10th-collection__container > div:nth-child(1) {
    width: 64%;
  }
  .body-anniversary-10th-collection__lineup__img-layout .base-anniversary-10th-collection__container > div:nth-child(2) {
    width: 34%;
    max-width: 350px;
  }
}

@media (min-width: 1024px) {
  .body-anniversary-10th-collection__lineup__img-layout .base-anniversary-10th-collection__container > div:nth-child(1) {
    width: 56%;
  }
}

.body-anniversary-10th-collection__lineup__points-wrapper {
  position: relative;
}

.body-anniversary-10th-collection__lineup__points-main-img {
  display: block;
  width: 100%;
  height: auto;
  mix-blend-mode: darken;
  aspect-ratio: 115 / 72;
}

.body-anniversary-10th-collection__lineup__selected-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.body-anniversary-10th-collection__lineup__selected-dots {
  padding-left: 0;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  list-style-type: none;
  position: relative;
}

.body-anniversary-10th-collection__lineup__selected-dots li {
  position: absolute;
}

.body-anniversary-10th-collection__lineup__selected-dots li:nth-child(1) {
  top: 84%;
  left: 1%;
  -webkit-transform: translate(-5%, -83%);
          transform: translate(-5%, -83%);
}

.body-anniversary-10th-collection__lineup__selected-dots li:nth-child(1) span::after {
  content: '';
  position: absolute;
  width: 45.714285714285714%;
  height: 74.285714285714286%;
  right: -46%;
  bottom: 47%;
  border-right: 1px solid #815624;
  border-bottom: 1px solid #815624;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__selected-dots li:nth-child(1) {
    left: 0;
    top: inherit;
    bottom: 0;
  }
  .body-anniversary-10th-collection__lineup__selected-dots li:nth-child(1) span::after {
    width: 31px;
  }
}

.body-anniversary-10th-collection__lineup__selected-dots li:nth-child(2) {
  top: 68%;
  right: 8%;
  -webkit-transform: translate(-8%, -30%);
          transform: translate(-8%, -30%);
}

.body-anniversary-10th-collection__lineup__selected-dots li:nth-child(2) span::after {
  content: '';
  position: absolute;
  width: 102.857142857142857%;
  height: 140%;
  right: 97.142857142857143%;
  bottom: 50%;
  border-left: 1px solid #815624;
  border-bottom: 1px solid #815624;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__selected-dots li:nth-child(2) {
    top: auto;
    bottom: 14%;
  }
}

.body-anniversary-10th-collection__lineup__selected-dots li:nth-child(3) {
  top: 21%;
  right: 17%;
  -webkit-transform: translate(-8%, -30%);
          transform: translate(-8%, -30%);
}

.body-anniversary-10th-collection__lineup__selected-dots li:nth-child(3) span::after {
  content: '';
  position: absolute;
  width: 134.285714285714286%;
  height: 45.714285714285714%;
  left: -134%;
  bottom: 5%;
  border-top: 1px solid #815624;
  border-left: 1px solid #815624;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__selected-dots li:nth-child(3) {
    top: 14%;
    left: inherit;
    right: 29%;
  }
}

.body-anniversary-10th-collection__lineup__selected-dots li span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 34px;
  height: 34px;
  background-color: #815624;
  border-radius: 50%;
  opacity: 0.5;
}

.body-anniversary-10th-collection__lineup__selected-dots li p {
  margin-bottom: 0;
  color: white;
  letter-spacing: 0.03em;
  font-family: "Miller-Banner";
  font-size: 16px;
  font-weight: normal !important;
}

.body-anniversary-10th-collection__lineup__selected-dots__active span {
  opacity: 1 !important;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__selected-dots li span {
    width: 48.42px;
    height: 48.42px;
  }
}

@media (min-width: 1024px) {
  .body-anniversary-10th-collection__lineup__selected-dots li {
    cursor: pointer;
  }
  .body-anniversary-10th-collection__lineup__selected-dots li span {
    width: 58.14px;
    height: 58.14px;
  }
  .body-anniversary-10th-collection__lineup__selected-dots li p {
    font-size: clamp(16px, 1.781vw, 30px);
  }
}

@media (min-width: 1296px) {
  .body-anniversary-10th-collection__lineup__selected-dots li span {
    width: 64.06px;
    height: 64.06px;
  }
}

.body-anniversary-10th-collection__lineup__slider {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  max-width: 100%;
}

.body-anniversary-10th-collection__lineup__slider__title {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.body-anniversary-10th-collection__lineup__slider__title h3 {
  font-size: 20px;
  text-align: center;
  font-family: "Miller-Banner";
  color: #815624;
  font-weight: normal !important;
  line-height: 1.5;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__slider__title {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .body-anniversary-10th-collection__lineup__slider__title h3 {
    text-align: left;
  }
}

.body-anniversary-10th-collection__lineup__slider__title--hide-sp {
  display: none;
}

.body-anniversary-10th-collection__lineup__slider__title--hide-pc {
  display: block;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__slider__title--hide-sp {
    display: block;
  }
  .body-anniversary-10th-collection__lineup__slider__title--hide-pc {
    display: none;
  }
}

.body-anniversary-10th-collection__lineup__slider__img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 154/75;
  border: 1px solid #815624;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__slider__img {
    aspect-ratio: 232/129;
  }
}

@media (min-width: 1024px) {
  .body-anniversary-10th-collection__lineup__slider__img {
    aspect-ratio: 319/178;
  }
}

.body-anniversary-10th-collection__lineup__slider__desc {
  color: #815624;
  font-size: 15px;
  font-weight: 100 !important;
  line-height: 2;
  letter-spacing: 0.42px;
  margin: 40px 0 0 0;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__slider__desc {
    margin: 0;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.48px;
  }
}

.body-anniversary-10th-collection__lineup__slider > .slick-dots {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: absolute;
  top: calc(62% + 10px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.body-anniversary-10th-collection__lineup__slider > .slick-dots > li {
  width: 20px;
  height: 20px;
  padding: 5px;
}

.body-anniversary-10th-collection__lineup__slider > .slick-dots > li > button {
  width: 10px;
  height: 10px;
  color: transparent;
  position: relative;
  padding: 0;
  border: none;
  background-color: transparent;
}

.body-anniversary-10th-collection__lineup__slider > .slick-dots > li > button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #815624;
  border-radius: 50%;
  border: none;
  opacity: 0.5;
}

.body-anniversary-10th-collection__lineup__slider > .slick-dots > .slick-active > button::before {
  opacity: 1;
}

@media (min-width: 768px) {
  .body-anniversary-10th-collection__lineup__slider > .slick-dots {
    top: 145px;
  }
}
