.header-contact-us__container {
  width: 100%;
  height: auto;
  padding: 0 5%;
  max-width: 764px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .header-contact-us__container {
    width: 80%;
    padding: 0;
  }
}

.header-contact-us__main__title {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-weight: normal !important;
  line-height: 1.5;
  margin: 0;
  color: black;
  padding-top: 34px;
  text-align: center;
  letter-spacing: 1px;
}

.header-contact-us__main__title span {
  display: block;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  line-height: 1.6;
  margin-top: 15px;
  color: #666;
  letter-spacing: 1px;
}

.header-contact-us__main nav {
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header-contact-us__main nav a {
  border: 1px solid #e3e3e3;
  width: 100%;
  height: 115px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.header-contact-us__main nav a:nth-child(2) {
  border-left: 0;
  border-right: 0;
}

.header-contact-us__main nav a span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
}

.header-contact-us__main nav a span img {
  display: block;
  width: 100%;
  height: auto;
}

.header-contact-us__main nav a p {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 11px;
  font-weight: normal !important;
  line-height: 1.4;
  padding-top: 2px;
  margin: 0;
  color: black;
  letter-spacing: 1px;
}

.header-contact-us__main__tel {
  border: 6px solid #e3e3e3;
  text-align: center;
  padding: 25px 10px;
}

.header-contact-us__main__tel__title {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 1.6;
  color: black;
  margin: 0;
  letter-spacing: 1px;
}

.header-contact-us__main__tel--number {
  color: black;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 34px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 1px;
  margin: 0;
}

.header-contact-us__main__tel--number::before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 38px;
  background-image: url("https://static.lenskart.com/owndays/img/contact-us/tel.svg");
  background-size: contain;
  background-position: 0 5px;
  background-repeat: no-repeat;
  width: 22px;
  height: 31px;
  margin-right: 3px;
}

.header-contact-us__main__tel--time {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  margin: 7px auto 0 auto;
  padding: 0 10px;
  background-color: #e3e3e3;
}

.header-contact-us__main__tel--time span {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 12px;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 1px;
  color: black;
}

.header-contact-us__main__list {
  padding: 0 0 40px 0;
  margin: 15px 0 0 0;
  list-style-type: none;
}

.header-contact-us__main__list li {
  color: #999;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.7;
  position: relative;
  padding-left: 12px;
  letter-spacing: 1px;
}

.header-contact-us__main__list li::before {
  content: "*";
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media (min-width: 768px) {
  .header-contact-us__main__title {
    font-size: 38px;
    padding-top: 70px;
  }
  .header-contact-us__main__title span {
    text-align: center;
  }
  .header-contact-us__main nav {
    padding: 55px 0 50px 0;
    grid-template-columns: 32% 32% 32%;
    -webkit-column-gap: 2%;
       -moz-column-gap: 2%;
            column-gap: 2%;
  }
  .header-contact-us__main nav a {
    height: 235px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .header-contact-us__main nav a:nth-child(2) {
    border: 1px solid #e3e3e3;
  }
  .header-contact-us__main nav a span {
    height: 80px;
  }
  .header-contact-us__main nav a span img {
    display: block;
    width: 100%;
    height: auto;
  }
  .header-contact-us__main nav a p {
    font-size: 15px;
    padding-top: 7px;
  }
  .header-contact-us__main nav a:hover, .header-contact-us__main nav a:active, .header-contact-us__main nav a:visited, .header-contact-us__main nav a:focus {
    text-decoration: none;
  }
  .header-contact-us__main__tel {
    padding: 20px;
  }
  .header-contact-us__main__tel > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 5px auto 0 auto;
  }
  .header-contact-us__main__tel--number {
    font-size: 44px;
    padding-right: 20px;
  }
  .header-contact-us__main__tel--number::before {
    margin-right: 5px;
  }
  .header-contact-us__main__tel--time {
    margin-top: 0;
  }
  .header-contact-us__main__tel--time span {
    font-size: 16px;
  }
  .header-contact-us__main__list {
    padding: 0 0 70px 0;
  }
}

@media (min-width: 1024px) {
  .header-contact-us__main nav a:hover {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);
  }
}
