$base-img-url: 'https://static.lenskart.com/media/owndays/img/freaks-store';

$font-noto: "Noto Sans JP", sans-serif;
$font-din: din-condensed, sans-serif;
$font-neuzeit-reg: 'NeuzeitGroReg';
$font-neuzeit-bold: 'NeuzeitGroBold';

$white-f7: #F7F7F7;
$green-55: #555E2B;
$brown-9D: #9D674F;
$gray-dd: #ddd;
$gray-e5: #e5e5e5;
$gray-72: #727272;

.goods-freaks-store {
  background-color: white;

  &__button {
    .base-freaks-store__container {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  
    .base-freaks-store__btn {
      margin: auto;
  
      @media (min-width: 768px) {
        width: 380px;
      }
    }
  }

  &__text {
    font-family: $font-noto;
    font-weight: bold;
    
    &--brown {
      color: $brown-9D;
    }
  }
  
  &__header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;

    &__title {
      &-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
      }

      &-text, &-subtitle {
        margin: 0;
      }

      &-text {
        font-family: $font-din;
        font-weight: bold !important;
        font-size: 42px;
        line-height: 34px;
        letter-spacing: 2.1px;
        color: $green-55;
      }

      &-subtitle {
        text-align: center;
        font-family: $font-noto;
        font-size: 18px;
        font-style: normal !important;
        font-weight: 700;
        line-height: 25px;
        color: black;
      }
    }

    &__description {
      &-layout {
        padding: 40px 0 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-title, &-subtitle {
        margin: 0;
        font-family: $font-noto;
        font-weight: bold !important;
        color: black;
      }

      &-title {
        font-size: 14px;
        position: relative;
        padding: 0 20px;

        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          content: url("#{$base-img-url}/original-goods-slash-left-icon.svg");
        }

        &::after {
          position: absolute;
          top: 4px;
          right: 0;
          content: url("#{$base-img-url}/original-goods-slash-right-icon.svg");
        }
      }

      &-subtitle {
        font-size: 32px;
      }
    }

    @media (min-width: 768px) {
      padding-bottom: 96px;

      &__title {
        &-layout {
          gap: 13px
        }

        &-text {
          font-size: 70px;
          line-height: 57px;
        }
      }

      &__description {
        &-layout {
          padding: 81px 0 40px 0;
        }
  
        &-title {
          font-size: 20px;
        }
      }
    }
  }

  &__typing-effect {
    clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
  }

  &__typing-effect[data-aos="goods-typing-animation"].aos-animate {
    animation: typing-effect 1s ease forwards;
  }

  &__message {
    width: 100%;
    height: auto;
    font-family: $font-noto;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 2;
    color: black;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 660px;
      font-size: 16px;
    }
  }

  &__how-to {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    padding-bottom: 60px;
    gap: 10px;

    &__main-layout {
      width: 330px;
      height: 239px;
      position: relative;
      margin: 0 auto;
    }

    &__image {
      width: 100%;
      max-width: 352px;
      height: auto;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &__image-step {
      &-01, &-02, &-03 {
        height: auto;
        position: absolute;
        cursor: pointer;
      }

      &-01 {
        width: 31px;
        top: 0;
        left: 24px;
      }

      &-02 {
        width: 74px;
        top: 0;
        right: 18px;
      }

      &-03 {
        width: 55px;
        bottom: 0;
        top: 163px;
        left: 177px;
      }
    }

    &__point {
      width: 222px;
      height: auto;
      margin: 0 auto;
      position: relative;

      img {
        display: block;
        width: 165px;
        height: auto;
        margin: 0 auto;
      }

      > .slick-dots {
        padding: 0;
        list-style-type: none;
        margin: 0;
        position: absolute;
        top: 180px;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 5px;
        display: flex !important;
        justify-content: center;
        gap: 10px;
        z-index: 1;

        li, li > button {
          width: 40px;
          height: 5px;
        }

        li > button {
          border: 0;
          opacity: 1;
          margin: 0;
          background-color: $gray-dd;
          color: transparent;
        }

        > .slick-active > button {
          background-color: black;
        }
      }

      &__label {
        margin: 40px auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &--circle-bg {
          width: 30px;
          height: 30px;
          background-color: $green-55;
          border-radius: 50%;
          position: relative;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $font-din;
            font-weight: bold !important;
            font-size: 18px;
            letter-spacing: 0.54px;
            color: white;
          }
        }

        &--name {
          width: fit-content;
          height: auto;
          font-family: $font-noto;
          font-weight: bold !important;
          font-size: 18px;
          line-height: 18px;
          color: $green-55;
          white-space: nowrap;
        }
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 38px;
      padding-bottom: 132px;

      .base-freaks-store__container {
        width: fit-content;
        padding: 0;
        margin: 0;
      }

      &__main-layout {
        margin: 0;
      }

      &__point {
        margin: 0;
      }
    }

    @media (min-width: 1024px) {
      &__main-layout {
        width: 624px;
        height: 413px;
        margin: 0;
      }
  
      &__image {
        width: 624px;
        max-width: none;
      }
  
      &__image-step {
        &-01, &-02, &-03 {
          height: auto;
          position: absolute;
          cursor: pointer;
        }
  
        &-01 {
          width: 57px;
          top: 0;
          left: 46px;
        }
  
        &-02 {
          width: 130px;
          top: 0;
          right: 43px;
        }
  
        &-03 {
          width: 103px;
          top: unset;
          bottom: 0;
          left: 334px;
        }
      }

      &__point {
        img {
          width: 230px;
          height: 230px;
        }

        > .slick-dots {
          top: 250px;
        }
      }
    }
  }

  &__multiway-design {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 0 30px 0;

    .goods-freaks-store__header__description-subtitle {
      font-size: 22px;
      line-height: 1.1;
    }

    @media (min-width: 768px) {
      padding: 0 0 60px 0;

      .goods-freaks-store__header__description-subtitle {
        font-size: 32px;
      }
    }
  }

  &__design {
    display: flex;
    justify-content: center;
    padding: 0 15px;

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  &__design-slider {
    list-style-type: none;
    padding: 0 0 21px 0;
    margin: 0;
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar {
      display: block !important;
      height: 5px !important;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $gray-e5;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $gray-72;
    }

    &__item {
      width: 100%;
      height: auto;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
    }

    &__tag {
      width: fit-content;
      height: auto;
      background-color: $green-55;
      padding: 7px 15px;
      border-radius: 50px;

      span {
        font-family: $font-noto;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 14px;
        color: white;
        white-space: nowrap;
      }
    }

    &__link-show-modal {
      outline: none;
      transition: 0.5s;
      cursor: pointer;
      display: flex;
      gap: 3px;
      align-items: center;

      &:hover, &:active, &:visited, &:focus {
        text-decoration: none;
      }
      
      span {
        font-family: $font-noto;
        font-weight: bold;
        font-size: 12px;
        line-height: 25px;
        color: $brown-9D;
        white-space: nowrap;
        text-decoration: underline;
        text-underline-offset: 2px;
      }

      img {
        display: block;
        width: 12px;
        height: 12px;
        margin-top: 2px;
      }
    }

    &__image {
      display: block;
      min-width: 270px;
      height: auto;
      aspect-ratio: 350/530;
    }

    @media (min-width: 1024px) {
      padding: 0;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }

  &__remark {
    width: fit-content;
    height: auto;
    margin: 0 auto;
    font-family: $font-noto;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    padding: 30px 0 60px 15px;
    color: black;
    position: relative;

    &::before {
      content: '*';
      position: absolute;
      left: 0;
    }
  }

  &__collapse {
    padding-bottom: 100px;

    &__button {
      width: 100%;
      height: auto;
      padding: 15px 0;
      background-color: $green-55;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      h4 {
        margin: 0;
        font-family: $font-noto;
        font-weight: bold !important;
        font-size: 16px;
        color: white;
        text-align: center;
        position: relative;
        margin-left: 26px;

        &::before {
          content: url("#{$base-img-url}/original-goods-warning-icon.svg");
          position: absolute;
          top: 0;
          left: -26px;
        }
      }

      img {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__animation {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
    }

    &__content {
      padding: 30px 0;
      background-color: $white-f7;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      section {
        width: calc(100% - 40px);
        max-width: 773px;
        height: auto;

        &:not(:first-child) {
          margin-top: 30px;
        }

        h4 {
          font-family: $font-noto;
          font-weight: bold !important;
          font-size: 11px;
          line-height: 25px;
          color: black;
          margin: 0 4px 0 0;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          
          li {
            font-family: $font-noto;
            font-weight: 400 !important;
            font-size: 11px;
            line-height: 25px;
            color: black;
            position: relative;
            margin: 0 0 0 15px;

            &::before {
              content: '*';
              position: absolute;
              left: -15px;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      &__content {
        padding: 60px 0;
      }
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
      animation: fadeIn 0.3s ease;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: white;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      width: calc(100% - 40px);
      height: auto;
      animation: slideIn 0.3s ease;
    }

    &__header {
      padding: 15px 13px 30px 13px;
      display: flex;
      flex-direction: column;

      &__close-icon {
        width: 18px;
        height: 18px;
        display: flex;
        align-self: end;
        cursor: pointer;
      }

      &-title-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-title, &-subtitle {
        font-family: $font-neuzeit-bold;
        color: black;
        margin: 0;
      }

      &-title {
        font-size: 14px;
      }

      &-subtitle {
        font-size: 22px;
      }
    }

    &__body {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 51px;
      position: relative;
    }

    &__number {
      width: 30px;
      height: 30px;
      background-color: black;
      border-radius: 50%;
      margin: 30px 0 15px 0;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $font-neuzeit-bold;
        font-size: 24px;
        line-height: 23px;
        color: white;
      }
    }

    &__images, &__desc-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &__images {
      width: calc(100% - 40px);
      height: auto;
      max-width: 829px;
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 40px;
      margin: 0 auto;

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 54px;
          right: -30px;
          width: 20px;
          height: 20px;
          background-image: url("#{$base-img-url}/original-goods-step-arrow.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }

        &:last-child {
          &::before {
            content: none;
          }
        }
      }
    }

    &__desc-list {
      padding: 20px 20px 0 20px;

      li {
        display: flex;
        align-items: center;
        gap: 7px;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          width: calc(100% - 37px);
          height: auto;
          font-family: $font-neuzeit-reg;
          font-weight: 400 !important;
          font-size: 14px;
          line-height: 20px;
          color: black;
          margin: 0;
          letter-spacing: normal;
        }

        .goods-freaks-store__modal__number {
          margin: 0;
        }
      }
    }

    @media (min-width: 768px) {  
      &__content {
        width: 100%;
        max-width: 1079px;
      }
  
      &__header {
        padding: 90px 30px 60px 30px;
  
        &__close-icon {
          width: 27px;
          height: 27px;
        }
  
        &-subtitle {
          font-size: 26px;
        }
      }
  
      &__body {
        padding-bottom: 114px;
      }
  
      &__images {
        gap: 59px;

        li {
          img {
            width: 190px;
            height: auto;
          }

          &::before {
            top: 157px;
            right: -53px;
            width: 46px;
            height: 36px;
          }
        }
      }
  
      &__desc-list {
        display: grid;
        grid-template-columns: 237px 237px 237px;
        justify-content: center;
        gap: 59px;
        padding-top: 0;

        li {
          gap: 0;
          margin-bottom: 0;
          justify-content: center;
          align-items: baseline;

          p {
            width: fit-content;
          }
  
          .goods-freaks-store__modal__number {
            display: none;
          }
        }
      }
    }
  }

  @keyframes typing-effect {
    from {
      clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
}
