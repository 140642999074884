.od-product-preview {
  width: 50%;
  overflow: hidden;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.od-product-preview__content__img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.od-product-preview__content__description {
  color: #000;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  opacity: 0.5;
  margin-top: -20px;
}

.od-product-preview__content__description hr {
  display: none;
}
