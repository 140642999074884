.services-page-hero {
    position: relative;

    &__layout {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 20px 200px;
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__logo {
        margin-bottom: 50px;
    }

    &__title {
        margin: 0;
        font-size: 72px;
        line-height: 60px;
        letter-spacing: 0em;
        color: #000000;
    }
}

.services-page-list {
    padding: 120px 200px 60px;

    &__layout {
        max-width: 1200px;
        padding-bottom: 60px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0 auto;
    }

    &__description {
        padding: 0 80px;
        margin-bottom: 50px;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0em;
        color: #000000;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 50px;
    }

    &__item-info {
        padding: 40px;
    }

    &__item-title {
        margin: 0 0 40px;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0em;
        color: rgba(0, 0, 0, 0.3);
    }

    &__item-subtitle {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0em;
        color: #000000;
    }

    &__item-price {
        margin-bottom: 20px;
        color: rgba(84, 96, 115, 0.7);
    }

    &__item-description {
        margin-bottom: 0;   
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0em;
        color: rgba(0, 0, 0, 0.7);
    }

    &__item-image {
        background-repeat: no-repeat;
        background-position: center;
    }
}