@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    top: -10px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: -10px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -10px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -10px;
  }
}
