.header-pompompurin__main {
  padding-top: 122px;
  padding-inline: 20px;
  margin-bottom: 195.03px;
}

.header-pompompurin__main__fg {
  position: absolute;
  top: 0;
}

.header-pompompurin__main__fg--left {
  max-width: 131px;
  left: 0;
}

.header-pompompurin__main__fg--right {
  max-width: 150px;
  right: 0;
}

.header-pompompurin__main__grid {
  display: grid;
}

.header-pompompurin__main__logo {
  margin: auto 0;
}

.header-pompompurin__main__logo img {
  aspect-ratio: 71/37;
  max-width: 221px;
  margin: 0 auto;
}

.header-pompompurin__main__img img {
  aspect-ratio: 217/145;
  position: relative;
}

@media (max-width: 767px) {
  .header-pompompurin__main__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
  }
  .header-pompompurin__main__img {
    padding-inline: 20px;
  }
}

@media (min-width: 768px) {
  .header-pompompurin__main {
    padding: 100px 0 0 0;
    margin-bottom: 100px;
  }
  .header-pompompurin__main__grid {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10%;
    padding-left: 80px;
  }
  .header-pompompurin__main__logo {
    -ms-flex-item-align: end;
        align-self: end;
  }
  .header-pompompurin__main__img {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
}

@media (min-width: 1024px) {
  .header-pompompurin__main {
    padding-top: 200px;
    margin-bottom: 200px;
  }
  .header-pompompurin__main__fg--left {
    max-width: 309px;
  }
  .header-pompompurin__main__fg--right {
    max-width: 306px;
  }
  .header-pompompurin__main__grid {
    -webkit-column-gap: 150px;
       -moz-column-gap: 150px;
            column-gap: 150px;
    padding-left: 0;
  }
  .header-pompompurin__main__logo img {
    max-width: 355px;
  }
}

.header-pompompurin__about {
  margin-block: 94px 116px;
}

.header-pompompurin__about__fg {
  position: absolute;
}

.header-pompompurin__about__fg--char-1 {
  max-width: 130px;
  top: -34px;
  right: calc(50% + 46px);
}

.header-pompompurin__about__fg--char-2 {
  max-width: 144px;
  bottom: -14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header-pompompurin__about__fg--footprints-1 {
  max-width: 75px;
  top: 53px;
  left: calc(50% + 106px);
}

.header-pompompurin__about__fg--footprints-2 {
  max-width: 123px;
  bottom: 18px;
  right: calc(50% + 296px);
}

.header-pompompurin__about__fg--pudding {
  max-width: 61px;
  right: calc(50% + 117px);
  bottom: 69px;
}

.header-pompompurin__about__fg--bone {
  max-width: 103px;
  top: 260px;
  right: calc(50% + 346px);
}

.header-pompompurin__about__fg--cherry {
  max-width: 91px;
  top: 256px;
  left: calc(50% + 323px);
}

.header-pompompurin__about__fg--mike {
  max-width: 71px;
  bottom: 150px;
  left: calc(50% + 328px);
}

.header-pompompurin__about__content {
  position: relative;
  background: url("https://static.lenskart.com/media/owndays/img/pompompurin/about-circle-bg.svg") no-repeat center/auto 100%;
  padding-block: 125px 156px;
  min-height: 831px;
  display: grid;
  place-items: center;
}

.header-pompompurin__about__content h2, .header-pompompurin__about__content p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header-pompompurin__about__content h2 {
  margin-bottom: 40px;
}

.header-pompompurin__about__content h2 img {
  aspect-ratio: 479/117;
  max-width: 307px;
  margin-inline: auto;
}

.header-pompompurin__about__content p {
  font-size: 14px;
  font-weight: 800 !important;
  letter-spacing: 0px;
  line-height: 2.5625;
  text-align: left;
  margin: 0 auto 40px auto;
  max-width: 592px;
  padding-inline: 20px;
}

.header-pompompurin__about__content p strong {
  font-size: 20px;
  font-weight: inherit !important;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {
  .header-pompompurin__about__fg--char-1 {
    max-width: 185px;
    top: 4px;
    right: calc(50% + 156px);
  }
  .header-pompompurin__about__fg--char-2 {
    max-width: 198px;
    bottom: -11px;
  }
  .header-pompompurin__about__fg--footprints-1 {
    max-width: 123px;
    top: 36px;
    left: calc(50% + 309px);
  }
  .header-pompompurin__about__fg--pudding {
    max-width: 96px;
    bottom: 175px;
    right: calc(50% + 317px);
  }
  .header-pompompurin__about__content {
    min-height: 873px;
  }
  .header-pompompurin__about__content p {
    text-align: center;
    padding-inline: 0;
  }
  .header-pompompurin__about__content h2 img {
    max-width: 500px;
  }
}

@media (min-width: 1024px) {
  .header-pompompurin__about {
    margin-block: 200px 161px;
  }
  .header-pompompurin__about__content {
    padding-top: 171px;
  }
  .header-pompompurin__about__content h2 {
    margin-bottom: 47px;
  }
  .header-pompompurin__about__content p {
    font-size: 18px;
    letter-spacing: 0.18px;
    margin-bottom: 54px;
  }
  .header-pompompurin__about__content p strong {
    font-size: 22px;
    letter-spacing: 0.22px;
  }
}

.header-pompompurin__lineup {
  background-color: #76332e;
  padding-bottom: 179px;
  margin-top: 211px;
  z-index: 1;
}

.header-pompompurin__lineup__fg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateY(calc(-100% + 16px));
          transform: translateY(calc(-100% + 16px));
  width: 100%;
  height: 186px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 63% top;
     object-position: 63% top;
  z-index: -1;
  aspect-ratio: 1513/250;
}

.header-pompompurin__lineup__title {
  color: white;
  font-size: 32px;
  font-weight: 700 !important;
  letter-spacing: 2.56px;
  text-align: center;
  line-height: 1.25;
  margin-bottom: 30px;
}

.header-pompompurin__lineup__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px 5px;
  max-width: 1030px;
  margin-inline: auto;
}

.header-pompompurin__lineup__nav-item {
  position: relative;
  max-width: 112px;
  padding-top: 54px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  list-style-type: none;
}

.header-pompompurin__lineup__nav-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  aspect-ratio: 1/1;
  background-color: #eedabe;
  border-radius: 100%;
  z-index: -1;
}

.header-pompompurin__lineup__nav-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  aspect-ratio: 1/1;
  z-index: 1;
}

.header-pompompurin__lineup__nav-content {
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  display: grid;
  gap: 4px;
  padding: 18px 9px 10px;
}

.header-pompompurin__lineup__nav-product {
  width: 100%;
  aspect-ratio: 120/67;
  max-width: 240px;
  margin-inline: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.header-pompompurin__lineup__nav-text {
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: center;
  color: #76332e;
  white-space: nowrap;
}

.header-pompompurin__lineup__nav-text strong {
  display: block;
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 15px;
  line-height: 1.25;
  letter-spacing: 1.5px;
  color: #fff9b1;
  text-shadow: 1px 0 0 #76332e, -1px 0 0 #76332e, 0 1px 0 #76332e, 0 -1px 0 #76332e;
}

@media (min-width: 1024px) {
  .header-pompompurin__lineup {
    padding-bottom: 576px;
  }
  .header-pompompurin__lineup__nav {
    gap: 26.66667px 17.33333px;
  }
  .header-pompompurin__lineup__nav-item {
    max-width: 217.33333px;
    padding-top: 108.5px;
  }
}

@media (min-width: 768px) {
  .header-pompompurin__lineup {
    margin-top: 340px;
    padding-top: 27px;
  }
  .header-pompompurin__lineup__fg {
    height: 250px;
    background-position: center top;
    -webkit-transform: translateY(calc(-100% + 14px));
            transform: translateY(calc(-100% + 14px));
  }
  .header-pompompurin__lineup__title {
    font-size: 70px;
    letter-spacing: 5.6px;
    margin-bottom: 68px;
  }
  .header-pompompurin__lineup__nav {
    gap: 32px 20.8px;
  }
  .header-pompompurin__lineup__nav-item {
    max-width: 260.8px;
    padding-top: 130px;
  }
  .header-pompompurin__lineup__nav-item:hover, .header-pompompurin__lineup__nav-item:active, .header-pompompurin__lineup__nav-item:visited, .header-pompompurin__lineup__nav-item:focus {
    text-decoration: none;
  }
  .header-pompompurin__lineup__nav-item:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: .6;
  }
  .header-pompompurin__lineup__nav-content {
    border-radius: 0px 0px 30px 30px;
    padding: 49px 25px 33px;
  }
  .header-pompompurin__lineup__nav-text {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.49px;
    color: #76332e;
  }
  .header-pompompurin__lineup__nav-text strong {
    font-size: 35px;
    letter-spacing: 3.567px;
  }
}

@media (min-width: 1024px) {
  .header-pompompurin__lineup__nav-item {
    width: 100%;
  }
}

@media (min-width: 1296px) {
  .header-pompompurin__lineup__nav {
    gap: 40px 26px;
  }
  .header-pompompurin__lineup__nav-item {
    max-width: 326px;
    padding-top: 163px;
  }
}
