.breadcrumbContainer {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(84, 96, 115, 0.10);
    border-bottom: 1px solid rgba(84, 96, 115, 0.10);

    &_wrapper {
        font-size: 11px;
        letter-spacing: 1px;
        border-radius: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px 5px 0;

        &_breadcrumb {
            padding: 0;
            margin: 0;
            li {
                font-size: 16px;
                line-height: 30px;
                opacity: 0.3;
                display: inline-block;

                &:after {
                    content: '>';
                    padding: 0 15px;
                    font-family: 'NeuzeitGroReg', sans-serif;
                }

                &:last-child:after {
                   display: none;
                }

                &:last-child {
                    opacity: 1;
                }
            }

            span {
                color: #000000;
            }

            a {
                color: #000000;
            }
        }
    }
}

@media only screen and (min-width:1025px) {
    .breadcrumbContainer_wrapper {
        margin-top: 5px;
        padding-left: 50px;

        &_breadcrumb {
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 1153px) {
    .breadcrumbContainer_wrapper {
        transform: translateY(20px);
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

.animatedBackground {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
}
