$base-img-url: 'https://static.lenskart.com/media/owndays/img/freaks-store';

$font-noto: "Noto Sans JP", sans-serif;
$font-din: din-condensed, sans-serif;

$font-neuzeit-bold: 'NeuzeitGroBold';

$white-f7: #F7F7F7;
$green-55: #555E2B;
$brown-ce: #CEB39C;
$gray-dd: #ddd;
$gray-cb: #CBCEC7;

.point-lineup-freak-store {
  &__glasses {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white-f7;
      margin-top: 50px;

      &--column-reverse {
        flex-direction: column-reverse !important;
        gap: 50px !important;
        margin-top: 16px !important;
      }
    }

    &__main {
      width: 100%;
      height: auto;
      position: relative;

      > .slick-dots {
        padding: 0;
        list-style-type: none;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        li, li > button {
          width: calc(30px * 1.1);
          height: calc(30px * 1.1);

          @media (min-width: 539px) {
            width: calc(30px* 1.5);
            height: calc(30px* 1.5);
          }

          @media (min-width: 604px) {
            width: calc(30px* 1.6);
            height: calc(30px* 1.6);
          }

          @media (min-width: 678px) {
            width: calc(30px* 1.9);
            height: calc(30px* 1.9);
          }

          @media (min-width: 768px) {
            width: calc(30px* 1.1);
            height: calc(30px* 1.1);
          }

          @media (min-width: 831px) {
            width: calc(30px* 1.3);
            height: calc(30px* 1.3);
          }

          @media (min-width: 1024px) {
            width: calc(30px* 1.58);
            height: calc(30px* 1.58);
          }
          
        }

        li {
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            position: absolute;

            &::before {
              color: white;
              font-size: clamp(18px, calc(18 / 360 * 100vw), 30px);
              font-family: $font-din;
              position: absolute;
              transform: translate(-50%, -50%);
            }
          }

          > button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 0;
            background: transparent;
            color: white;
            font-size: clamp(18px, calc(18 / 360* 100vw), 30px);
            font-family: din-condensed, sans-serif;
            line-height: 1.1;
          }
        }
      }
    }

    &__point {
      width: 100%;
      height: auto;
      margin-top: 25px;
      padding: 0 20px;
      position: relative;
      display: flex;
      justify-content: center;

      h3 {
        text-align: center;
        margin: 42px 0 0 0;
        color: $green-55;
        font-family: $font-neuzeit-bold;
        font-size: 20px;
        font-weight: bold !important;
        letter-spacing: 0.6px;
        line-height: 1.5;
      }
      
      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 165px;
        border-radius: 50%;
        margin: 0 auto;
      }

      p {
        font-family: $font-noto;
        font-size: 15px;
        font-weight: normal;
        line-height: 2;
        margin: 8px auto 30px auto;
        padding: 0 15px;
      }

      > .slick-list {
        width: 320px;

        @media (min-width: 1024px) {
          width: 300px;
        }
      }

      > .slick-dots {
        padding: 0;
        list-style-type: none;
        margin: 0;
        position: absolute;
        top: 182px;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 5px;
        display: flex !important;
        justify-content: center;
        gap: 10px;
        z-index: 1;

        li, li > button {
          width: 40px;
          height: 5px;
        }

        li > button {
          border: 0;
          opacity: 1;
          margin: 0;
          background-color: $gray-dd;
          color: transparent;
        }

        > .slick-active > button {
          background-color: black;
        }
      }
    }
  }

  &__product {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 15px
    }

    &__slider {
      position: relative;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &__desc {
        padding-top: 17px;
        color: black;

        h3 {
          margin: 0;
          display: flex;
          gap: 9px;
          align-items: baseline;

          span {
            font-family: $font-neuzeit-bold;

            &:nth-child(1) {
              font-size: 16px;
            }

            &:nth-child(2) {
              font-size: 20px;
            }
          }
        }

        p {
          font-family: $font-neuzeit-bold;
          font-size: 18px;
          line-height: 2;
          margin: 0;
        }
      }

      > .slick-dots {
        list-style-type: none;
        display: flex !important;
        gap: 8px;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 3px;
        right: 0;
        z-index: 1;

        > li, > li > button {
          width: 27px;
          height: 27px;
        }

        > li > button {
          border: 0;
        }

        .slick-active {
          button {
            border: 2px solid white;
            outline: 2px solid black;
          }
        }
      }

      &__dot {
        &--black {
          background: #000 center / contain no-repeat;
        }

        &--brown-ce {
          background: $brown-ce center / contain no-repeat;
        }

        &--bullet-grey {
          background: url("#{$base-img-url}/bullet-color-grey.webp") center / contain no-repeat;
        }

        &--brown-demi {
          background: url("#{$base-img-url}/color-glass-brown-demi.webp") center / contain no-repeat;
        }

        &--gold {
          background: url("#{$base-img-url}/color-glass-gold.webp") center / contain no-repeat;
        }

        &--silver {
          background: url("#{$base-img-url}/color-glass-silver.webp") center / contain no-repeat;
        }
      }
    }

    &__after {
      &__title {
        font-family: $font-din;
        font-weight: bold !important;
        color: $green-55;
        font-size: 14px;
        letter-spacing: 0.42px;
        margin: 0;
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 20px;

        &::before {
          content: '';
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
          background: url("#{$base-img-url}/arrow-down3layer.webp") center / contain no-repeat;
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
      }

      &__desc {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        gap: 10px;
        padding: 10px 0;

        &--tag {
          padding: 11px 20px;
          max-width: fit-content;
          background-color: $gray-cb;
          clip-path: polygon(8px 0%, calc(100% - 9px) 0%, 100% 20%, 100% 80%, calc(100% - 9px) 100%, 8px 100%, 0% 80%, 0% 20%);

          p {
            margin: 0;
            font-weight: bold;
            color: $green-55;
            line-height: 9.8px;
            font-family: $font-din;
            font-size: 15px;
            letter-spacing: 0.48px;
          }
        }

        &--color {
          display: flex;
          gap: 5px;
          justify-content: flex-start;
          align-items: center;

          img {
            display: block;
            width: 25px;
            height: 25px;
          }

          span {
            font-family: $font-neuzeit-bold;
            font-size: 17px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    &__glasses {
      &__container {
        flex-direction: row;
        justify-content: center;
        gap: 50px;
        margin-top: 84px;

        &--column-reverse {
          flex-direction: row !important;
          gap: 50px !important;
          margin-top: 84px !important;
          padding-bottom: 120px;
        }
      }

      &__main {
        width: 53.2%;
        height: auto;
      }

      &__point {
        margin-top: 0;
        
        img {
          display: block;
          border-radius: 50%;
        }
      }
    }

    &__product {
      &__container {
        flex-direction: row;
        gap: 25px;
        margin-top: 40px;

        &--center {
          justify-content: center;
          margin-bottom: 120px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
  
      &__slider {
        width: 50%;
  
        &__desc {
          padding-top: 20px;
        }
      }
  
      &__after {
        position: relative;
        width: 40%;
        max-width: 320px;
        margin-left: auto;
        margin-top: 36px;

        &__title {
          position: absolute;
          top: 32%;
          left: -17%;
          transform: translateY(-50%);

          &::before {
            width: 30px;
            height: 30px;
            rotate: -90deg;
            top: -50px;
            left: 0;
          }

          @media (min-width: 896px) {
            left: -25%;
          }

          @media (min-width: 1002px) {
            left: -29.15%;
          }
        }
  
        img {
          max-width: 100%;
        }
  
        &__desc {
          padding: 0;

          &--tag {
            p {
              line-height: 1;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    &__product {
      &__container {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
      }

      &__slider {
        &__desc {
          h3 {
            span {
              &:nth-child(1) {
                font-size: 20px;
              }
  
              &:nth-child(2) {
                font-size: 26px;
              }
            }
          }
  
          p {
            font-size: 20px;
          }
        }
  
        > .slick-dots {
          > li, > li > button {
            width: 30px;
            height: 30px;
          }
        }
      }

      &__after {
        img {
          max-width: 100%;
        }

        &__desc {
          margin-top: 15px;
  
          &--tag {
            padding: 10.1px 20px;
          }
        }
      }
    }
  }
}

.dot-lineup-freak-store {
  &__FK2001B-4S_C4 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: -1px;
          left: 33.8%;
        }

        &:nth-child(2) {
          bottom: 0px;
          right: 19.1%;
        }

        &:nth-child(3) {
          top: 18%;
          right: 11.4%;
        }
      }
    }
  }

  &__FK2001B-4S_C5 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: -1px;
          left: 33.8%;
        }

        &:nth-child(2) {
          bottom: 0px;
          right: 19.5%;
        }

        &:nth-child(3) {
          top: 18%;
          right: 10%;
        }
      }
    }
  }

  &__FK2001B-4S_C6 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: -1px;
          left: 33.8%;
        }

        &:nth-child(2) {
          bottom: -1px;
          right: 19.3%;
        }

        &:nth-child(3) {
          top: 18%;
          right: 11%;
        }
      }
    }
  }

  &__FK2002N-4S_C1, &__FK2002N-4S_C2, &__FK2002N-4S_C3 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: -1px;
          left: 42.9%;
        }

        &:nth-child(2) {
          bottom: -1px;
          right: 31.3%;
        }

        &:nth-child(3) {
          top: 9%;
          right: 2.5%;
        }
      }
    }
  }

  &__FK1001B-4S_C1, &__FK1002B-4S_C1 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: -1px;
          left: 38.8%;
        }

        &:nth-child(2) {
          bottom: -1px;
          right: 19.1%;
        }

        &:nth-child(3) {
          top: 20%;
          right: 7.2%;
        }
      }
    }
  }

  &__FK1001B-4S_C2, &__FK1001B-4S_C3 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: -1px;
          left: 38.5%;
        }

        &:nth-child(2) {
          bottom: -1px;
          right: 19.7%;
        }

        &:nth-child(3) {
          top: 20%;
          right: 7.7%;
        }
      }
    }
  }

  &__FK1002B-4S_C2, &__FK1002B-4S_C3 {
    > .slick-dots {
      li {
        &:nth-child(1) {
          top: 4px;
          left: 39.9%;
        }

        &:nth-child(2) {
          bottom: 3px;
          right: 20.1%;
        }

        &:nth-child(3) {
          top: 22%;
          right: 9.9%;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    &__FK2001B-4S_C4 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 34.3%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 19.4%;
          }
  
          &:nth-child(3) {
            top: 19%;
            right: 11.9%;
          }
        }
      }
    }

    &__FK2001B-4S_C5 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 34.3%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 19.7%;
          }
  
          &:nth-child(3) {
            top: 19.1%;
            right: 10.3%;
          }
        }
      }
    }

    &__FK2001B-4S_C6 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 34.3%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 19.7%;
          }
  
          &:nth-child(3) {
            top: 18.3%;
            right: 11.3%;
          }
        }
      }
    }

    &__FK2002N-4S_C1, &__FK2002N-4S_C2, &__FK2002N-4S_C3 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 43.5%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 31.7%;
          }
  
          &:nth-child(3) {
            top: 10.3%;
            right: 2.9%;
          }
        }
      }
    }

    &__FK1001B-4S_C1 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 39.5%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 19.4%;
          }
  
          &:nth-child(3) {
            top: 21.2%;
            right: 7.6%;
          }
        }
      }
    }

    &__FK1002B-4S_C1 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 39.3%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 19.4%;
          }
  
          &:nth-child(3) {
            top: 21.2%;
            right: 9.2%;
          }
        }
      }
    }

    &__FK1001B-4S_C2, &__FK1001B-4S_C3 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 38.9%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 20%;
          }
  
          &:nth-child(3) {
            top: 21.2%;
            right: 8.1%;
          }
        }
      }
    }

    &__FK1002B-4S_C2, &__FK1002B-4S_C3 {
      > .slick-dots {
        li {
          &:nth-child(1) {
            top: 1px;
            left: 39.3%;
          }
  
          &:nth-child(2) {
            bottom: 1px;
            right: 19.3%;
          }
  
          &:nth-child(3) {
            top: 21.2%;
            right: 9.1%;
          }
        }
      }
    }
  }
}
