.footer-gift-cards-products__preview {
  grid-area: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer-gift-cards-products__preview__title {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: bold !important;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-align: left;
  color: black;
}

.footer-gift-cards-products__preview__card {
  border: 1px solid #e3e3e3;
  padding: 20px;
  margin-bottom: 50px;
}

.footer-gift-cards-products__preview__card img {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 31 / 23;
}

.footer-gift-cards-products__preview__card pre, .footer-gift-cards-products__preview__card p {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  letter-spacing: 1px;
  color: black;
  margin: 0;
}

.footer-gift-cards-products__preview__card pre {
  font-size: 14px;
  line-height: 25px;
  word-wrap: break-word;
  white-space: normal;
  border: 0;
  background-color: transparent;
  padding: 0;
  word-break: normal;
}

.footer-gift-cards-products__preview__card p {
  font-size: 15px;
  line-height: 2;
  margin-left: auto;
  text-align: right;
}

.footer-gift-cards-products__preview__summary__price {
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1;
  margin-bottom: 20px;
  font-weight: 400 !important;
  font-size: 28px;
  text-align: left;
  color: black;
}

.footer-gift-cards-products__preview__summary__exp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f4f4f4;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.footer-gift-cards-products__preview__summary__exp p {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  margin-bottom: 0;
  color: black;
}

.footer-gift-cards-products__preview__summary__add-cart-btn {
  width: 100%;
  height: auto;
  background-color: #516075;
  color: white;
  border: none;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  padding: 18px 0;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  margin-bottom: 45px;
  cursor: pointer;
}

.footer-gift-cards-products__preview__summary__add-cart-btn:hover {
  background-color: #6b788a;
}

@media (min-width: 768px) {
  .footer-gift-cards-products__preview__title {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .footer-gift-cards-products__preview {
    padding: 80px 15px 0 15px;
    width: 335px;
    margin-left: auto;
  }
  .footer-gift-cards-products__preview .base-gift-cards-products__container {
    width: 100%;
  }
  .footer-gift-cards-products__preview > div:nth-child(1) {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .footer-gift-cards-products__preview > div:nth-child(2) {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}

.footer-gift-cards-products__detail {
  padding: 50px 0 40px 0;
  border-top: 1px solid #e3e3e3;
  grid-area: detail;
}

.footer-gift-cards-products__detail__how-to-use {
  list-style-type: none;
  padding: 0;
  margin-bottom: 40px;
}

.footer-gift-cards-products__detail__how-to-use__title, .footer-gift-cards-products__detail__how-to-use__sub-title {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: bold !important;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  color: black;
}

.footer-gift-cards-products__detail__how-to-use__title {
  margin-bottom: 15px;
}

.footer-gift-cards-products__detail__how-to-use__sub-title {
  position: relative;
  margin-bottom: 10px;
  padding-left: 13px;
}

.footer-gift-cards-products__detail__how-to-use__sub-title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 3px;
  height: 18px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: black;
}

.footer-gift-cards-products__detail__how-to-use__desc {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
  margin-bottom: 0;
  color: black;
  letter-spacing: 1px;
}

.footer-gift-cards-products__detail__how-to-use__item:nth-child(1) p {
  margin-bottom: 20px;
}

.footer-gift-cards-products__detail__how-to-use__item:nth-child(2) p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .footer-gift-cards-products__detail__how-to-use__title {
    font-size: 16px;
  }
}

.footer-gift-cards-products__detail__terms__title {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: bold !important;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 15px;
  color: black;
}

.footer-gift-cards-products__detail__terms__list {
  list-style-type: none;
  padding: 0;
}

.footer-gift-cards-products__detail__terms__list li {
  color: black;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
  padding-left: 1em;
  letter-spacing: 1px;
  position: relative;
}

.footer-gift-cards-products__detail__terms__list li::before {
  content: '*';
  position: absolute;
  left: 0;
}

@media (min-width: 768px) {
  .footer-gift-cards-products__detail__terms__title {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .footer-gift-cards-products__detail {
    max-width: 650px;
  }
  .footer-gift-cards-products__detail .base-gift-cards-products__container {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1295px) {
  .footer-gift-cards-products__detail {
    padding-right: 3%;
    max-width: 570px;
  }
}

@media (min-width: 1296px) {
  .footer-gift-cards-products__detail {
    padding-bottom: 110px;
  }
}
