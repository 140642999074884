$color-primary: #2e5aa8;
$color-white: #fff;
$color-light: #f7f9fc;
$color-text: #413d3d;
$color-blue: #0094c8;

$font-din: din-2014, sans-serif;
$font-noto: 'Noto Sans', sans-serif;

$base-img-url: 'https://static.lenskart.com/media/owndays/img/ortho-k-lens';
$icon-url: "https://static1.lenskart.com/owndays/img/icons";

.body-ortho-k-lens {
  &__key-benefits {
    padding: 70px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100vw;
      right: -100vw;
      bottom: 0;
      background-color: $color-primary;
      z-index: -1;
    }

    .base-ortho-k-lens__title {
      color: $color-white;
    }

    &__list {
      display: grid;
      gap: 23px;
      margin: 50px 0 0 0;
      list-style-type: none;
      padding-left: 0;

      >li {
        padding: 35px 20px 45px;
        background-color: $color-white;
        border: 1px solid #707070;
      }

      &-top {
        padding-bottom: 25px;
        margin-bottom: 20px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 6px;
          background: url("#{$base-img-url}/dot-blue.svg") left/contain repeat-x;
        }
      }

      &-img {
        width: 150px;
        margin: 0 auto 15px;
        display: block;
      }

      &-title {
        font: 700 22px/1.5 $font-din;
        font-weight: 700 !important;
        color: $color-primary;
        text-align: center;
        margin: 0;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    @media (min-width: 768px) {
      &__list {
        grid-template-columns: 1fr 1fr;
      }
    }

    @media (min-width: 1024px) {
      padding: 120px 0 130px;
      &__list {
        margin: 64px 0 0 0;
        gap: 45px;

        > li {
          padding: 50px 53px 53px;
        }

        &-top {
          display: grid;
          grid-template-columns: 150px 1fr;
          align-items: center;
          gap: 24px;
        }

        &-title {
          text-align: left;
        }
      }
    }
  }

  &__recommended {
    margin-top: 70px;
    .base-ortho-k-lens__title {
      white-space: nowrap;
    }

    &__list {
      margin: 38px 0 48px;
      padding-left: 0;
      list-style-type: none;

      > li {
        display: flex;
        font-size: 13px;

        &::before {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: $color-light url("#{$base-img-url}/icon-check.svg") center/15px auto no-repeat;
          flex: none;
          margin: 7px 18px 0 0;
        }

        +li {
          margin-top: 25px;
        }
      }
    }

    &__left-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      text-align: left;
    }

    &__link {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding-top: 15px;
      font-family: $font-noto;
      font-weight: 400;
      font-size: 15px;
      color: $color-blue !important;
      text-decoration: underline;
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 21px;
        background-image: url("#{$icon-url}/pdf.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover::after {
        opacity: 0.5;
      }
    }

    @media (min-width: 768px) {
      &__content {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 40px;
      }

      &__list {
        margin: 0 0 91px;

        > li {
          font-size: 15px;
        }
      }
    }

    @media (min-width: 1024px) {
      margin-top: 120px;
      &__content {
        grid-template-columns: 1fr 1fr;
        gap: 0;
      }

      &__list {


        > li {
          &::before {
            content: "";
            width: 38px;
            height: 38px;
          }
        }
      }
    }
  }

  &__treatment-process {
    margin-top: 70px;

    @media (min-width: 768px) {
      margin-top: 130px;
    }

    @media (min-width: 1024px) {
      .base-ortho-k-lens__container--sub {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__list {
      margin: 30px 0 0 0;
      counter-reset: step-counter;
      padding-right: 0;
      list-style-type: none;

      @media (max-width: 767px) {
        margin-left: -15px;
        margin-top: 25px;
      }

      @media (min-width: 768px) {
        margin-top: 64px;
      }

      > li {
        display: flex;
        counter-increment: step-counter;
        cursor: pointer;

        &:last-child {
          .body-ortho-k-lens__treatment-process__list-step {
            &::after {
              content: none;
            }
          }
        }
      }

      &-content {
        width: 100%;
        margin-top: calc(2px * -1);
      }

      &--question {
        position: relative;
        padding: 40px 20px;
        border-bottom: 2px solid $color-text;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: 700 16px/1.5 $font-din;
        letter-spacing: 0.02em;

        @media (max-width: 767px) {
          padding: 25px 10px;
        }

        &:first-child {
          border-top: 2px solid $color-text;
        }

        > span {
          &:first-child {
            max-width: 94%;

            @media (max-width: 767px) {
              max-width: 88%;
            }
          }
        }

        &__plus {
          position: relative;
          width: 20px;
          height: 20px;

          &::before,
          &::after {
            content: '';
            position: absolute;
            background-color: #999;
            transition: transform 0.25s ease-out;
          }

          &::before {
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
          }

          &::after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            margin-top: -1px;
          }
        }

        &--opened {
          .body-ortho-k-lens__treatment-process__list--question__plus {

            &::before {
              transform: rotate(90deg);
            }

            &::after {
              transform: rotate(180deg);
              height: 0;
            }
          }
        }

        @media (min-width: 768px) {
          font-size: 22px;
        }
      }

      &--answer {
        background-color: $color-light;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;

        > div {
          position: relative;
          padding: 30px 30px 30px 46px;

          @media (max-width: 767px) {
            padding: 38px 15px 38px 30px;
          }

          sup {
            font-size: 8px;
          }
        }

        a {
          display: inline;
        }

        p {
          font-size: 13px;
          line-height: 1.9;
          margin: 0;
        }

        .base-ortho-k-lens__note {
          margin-top: 13px;
        }

        @media (min-width: 768px) {
          p {
            font-size: 14px;
          }
        }
      }

      &-step {
        position: relative;
        margin-right: 18px;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 9px;
          bottom: calc(9px * -1);
          border-left: 2px solid #dedede;
          z-index: -1;
          transform: translateX(-50%);
          transition: 0.5s;
        }

        &-inner {
          font-size: 13px;
          font-weight: 700 !important;
          line-height: 1.2;
          text-align: center;
          width: 52px;
          height: 52px;
          background-color: #dedede;
          color: $color-white;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-top: 3px;
          flex: none;
          margin-top: 9px;
          transition: 0.5s;

          &::before {
            content: "STEP";
            font-size: 11px;
          }

          &::after {
            content: counter(step-counter);
          }
        }

        &__active {
          &::after {
            border-left-color: $color-primary;
          }

          .body-ortho-k-lens__treatment-process__list-step-inner {
            background-color: $color-primary;
          }
        }

        @media (min-width: 768px) {
          margin-right: 38px;

          &::after {
            top: 27px;
            bottom: calc(27px * -1);
            border-left-width: 3px;
          }

          &-inner {
            font-size: 17px;
            width: 60px;
            height: 60px;
            margin-top: 27px;

            &::before {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &__price {
    margin-top: 70px;

    .base-ortho-k-lens__title {
      margin-bottom: 19px;

      + p {
        font-size: 13px;
        padding-right: 20px;
        margin: 0;
      }
    }

    &__content {
      margin-top: 20px;

      p {
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
        margin: 0;
      }

      ul {
        list-style-type: none;
        padding-left: 0;

        li {
          position: relative;
          padding-left: 15px;
          text-align: left;
          font-size: 15px;

          &::before {
            content: "*";
            position: absolute;
            left: 0;
          }
        }
      }
    }

    @media (min-width: 768px) {
      .base-ortho-k-lens__title {
        + p {
          font-size: 15px;
        }
      }

      &__content {
        ul {
          li {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: auto 676px;
      margin-top: 130px;
      padding-bottom: 138px;

      .base-ortho-k-lens__title {
        + p {
          max-width: 310px;
        }
      }
    }

    @media (min-width: 1296px) {
      grid-template-columns: auto 766px;
    }
  }

  &__stores {
    margin-top: 70px;
    padding-bottom: 70px;

    .base-ortho-k-lens__title {
      margin-bottom: 19px;

      + p {
        font-size: 13px;
        padding-right: 20px;
        margin: 0;
      }
    }

    &__list {
      list-style-type: none;
      padding-left: 0;

      @media (max-width: 1023px) {
        > li {
          margin-top: 41px;
        }
      }
    }

    &__title {
      font: 700 15px/1.6 $font-noto;
      font-weight: 700 !important;
      letter-spacing: 0.02em;
      margin: 20px 0 28px;
    }

    .base-ortho-k-lens__note {
      margin-top: 35px;
    }

    @media (min-width: 768px) {
      .base-ortho-k-lens__title {
        + p {
          font-size: 15px;
        }
      }

      &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        > li {
          display: flex;
          flex-direction: column;
        }

        .base-ortho-k-lens__button {
          margin-top: auto;
        }
      }
    }

    @media (min-width: 1024px) {
      margin-top: 0;
      display: grid;
      grid-template-columns: auto 676px;
      padding-bottom: 138px;

      .base-ortho-k-lens__title {
        + p {
          max-width: 310px;
        }
      }

      &__list {
        gap: 36px;
      }
    }

    @media (min-width: 1296px) {
      grid-template-columns: auto 766px;
    }
  }

  &__guide {
    position: relative;
    padding: 80px 0;

    &::before{
      content: "";
      position: absolute;
      top:0;
      left:-100vw;
      right:-100vw;
      bottom: 0;
      background-color: $color-primary;
      z-index: -1;
    }

    &__title{
      .base-ortho-k-lens__title {
        color:$color-white;
        margin-bottom: 35px;
      }

      h3 {
        font-size: 13px;
        font-weight: bold !important;
        line-height: 21px;
        color: $color-white;
        text-align: center;
        margin: 0 0 40px;
      }
    }

    &__frame__slide {
      margin-bottom: 50px;
    }

    &__row{
      position: relative;
      margin-top: 50px;

      &:not(:first-child){
        padding: 50px 0 0;
        &::after {
          content: "";
          background: url("#{$base-img-url}/dot-white.svg") repeat-x left center/contain;
          height: 5px;
          position: absolute;
          left: -27px;
          right: -27px;
          top: 0;
        }
      }

      &__title{
        margin-bottom: 30px;

        &__main-title {
          font-size: 18px;
          font-weight: bold !important;
          max-width: fit-content;
          color: $color-white;
          padding: 10px 25px;
          border: 1px solid $color-white;
          border-radius: 50px;
          margin: 0 auto;

          h4 {
            margin: 0;
          }
        }

        &__sub-title{
          text-align: center;
          p {
            color: $color-white;
            font-size: 14px;
            font-weight: 200 !important;
            margin: 15px 0 0 0;
          }
        }
      }
    }


    &__slide {
      margin-left: -27px;
      padding-left: 27px;
      position: relative;
      overflow: hidden;

      > div {
        overflow: visible;
      }

      @media (max-width: 767px) {
        margin-right: -27px;
      }

      &__item {
        width: 250px !important;
        margin-right: 15px;
        list-style-type: none;

        @media (max-width: 1023px) {
          width: 220px !important;
        }
      }

      &__img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background-color: $color-white;
        width: 250px;
        height: 250px;
        position: relative;

        @media (max-width: 1023px) {
          width: 220px;
          height: 220px;
        }
      }

      &__order {
        position: absolute;
        top: 15px;
        left: 20px;

        span {
          color:$color-primary;
          font-weight: bold !important;
          font: 600 25px/1 $font-din !important;
        }
      }

      &__detail{
        margin-top: 20px;

        p {
          color: $color-white;
          font-size: 14px;
          line-height: 23px;
          margin: 0;
        }
      }
    }

    &__clean {
      &__list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
      }

      &__item {
        background-color: $color-white;
        border-radius: 30px;
        padding: 40px 20px;

        &__main-title-card{
          font-size: 25px;
          font-weight: bold !important;
          color:$color-primary;
          text-align: center;
          margin: 0;
        }

        &__img-clean{
          margin-top: 25px;
          margin-left: auto;
          margin-right: auto;

          img {
            display: block;
            width: 100%;
            max-height: 136px;
            margin: 0 auto;
          }

          .weekly-img{
            width: 100%;
            max-height: 128px;
            margin: 0 auto;
          }
        }

        &__detail {
          margin-top: 25px;
          p {
            color: $color-text !important;
            font-size: 14px !important;
            line-height: 26px !important;
            margin: 0;
          }

          @media (max-width: 768px) {
            font-size: 13px !important;
          }
        }
      }

      li+ li{
        @media (max-width: 767px) {
          margin-top: 24px;
        }
      }
    }


    @media (min-width: 768px) {
      padding: 130px 0;

      &__frame__slide {
        margin-top: 60px;
      }
      &__row{
        display: grid;
        grid-template-columns: 200px auto;
        align-items: center;
        padding-bottom: 0;

        &:first-child{
          .body-ortho-k-lens__guide__row__slide{
            margin-top: 0;
            padding-top: 0;
            &::after{
              content: none;
            }
          }
        }

        &:not(:first-child){
          padding: 0;
          &::after {
            content: none;
          }
        }
        &__title{
          margin-bottom: 0;

          h4{
            margin-right:inherit;
            margin-left:inherit;
          }

          &__group{
            max-width: fit-content;
          }
        }
        &__sub-title{
          p{
            margin-top: 10px;
          }
        }
      }

      &__title{
        h3 {
          font-size: 19px;
          margin: 60px 0 30px;
        }
      }

      &__clean__row{
        max-width: 924px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        ul{
          display: flex;
          justify-content: space-between;
          padding: 30px;

          li{
            width: 47.402%;
            &__detail{
              p{
                font-size: 14px;
                line-height: 26px;
              }
            }
            + li {
              margin-top: 0;
            }
          }
        }
      }
    }
    @media (min-width: 1296px) {
      &__row{
        grid-template-columns: 250px auto;
      }
    }
  }
}
