@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

$black: #000;
$gray: #666;
$blue: #0094c8;

$font-founders: 'Founders Grotesk', sans-serif;
$font-open-sans: 'Open Sans', sans-serif;

.description-policy {
  &__container {
    padding: 0 24px;
    margin: 0 auto;

    @media (min-width: 430px) {
      padding: 0 40px;
    }

    @media (min-width: 768px) {
      padding: 0 100px;
    }

    @media (min-width: 1024px) {
      padding: 0 140px;
    }

    @media (min-width: 1296px) {
      padding: 0 200px;
    }
  }

  &__header {
    padding: 40px 0 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &__title, &__subtitle  {
      font-style: normal;
      text-align: center;
      margin: 0;
    }

    &__title {
      font-family: $font-founders;
      font-size: 28px;
      font-weight: 500 !important;
      line-height: 30.8px;
      color: $black;
    }

    &__subtitle {
      font-family: $font-open-sans;
      font-size: 18px;
      font-weight: 400 !important;
      line-height: 21.6px;
      color: $gray;
      position: relative;
      padding-left: 18px;

      &::before {
        content: '※';
        font-family: $font-open-sans;
        font-size: 24px;
        color: $gray;
        position: absolute;
        left: 0;
        top: -3px;
      }
    }

    @media (min-width: 768px) {
      padding: 80px 0 48px 0;

      &__title {
        font-size: 48px;
        line-height: normal;
      }

      &__subtitle {
        font-size: 24px;
        line-height: 32px;
        padding-left: 24px;

        &::before {
          font-size: 29px;
        }
      }
    }
  }

  &__title, &__message  {
    font-family: $font-founders;
    font-style: normal;
    color: $black;
  }

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    padding: 16px 0;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 24px;
      font-weight: 500 !important;
      padding: 40px 0 16px 0;
    }
  }

  &__message {
    font-family: $font-founders;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 32px;
    margin-bottom: 8px;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 40px;
    }
  }

  &__link {
    cursor: pointer;
    color: $blue;
  }
}
