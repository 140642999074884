$font-base: yu-mincho-pr6n, sans-serif;

$base-img-url: 'https://static.lenskart.com/owndays/img/senichisaku';

.base-senichisaku {
  background-color: black;
  color: white;
  font-family: $font-base !important;
  font-weight: normal !important;
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 2.33;
  overflow-x: hidden;

  .breadcrumb.breadcrumb--2 {
    border-radius: 0;
  }

  .breadcrumb.breadcrumb--2 > li + li::before {
    color: black;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  h2, h5 {
    font-family: $font-base !important;
  }

  > section {
    position: relative;
  }

  &__container {
    width: 100%;
    max-width: calc(1160px + 10%);
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;

    &--sm {
      max-width: calc(780px + 10%);
    }
    
    &--lg {
      max-width: calc(1600px + 10%);
    }
  }

  &__hide {
    &--sp {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    &--pc {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  &__btn {
    border: 1px solid white;
    width: 100%;
    height: 61px;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
      color: white;
    }

    > span {
      display: inline-block;
      position: relative;
      padding-left: calc(12px + 20px);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 12px;
        height: 18px;
        background: url("#{$base-img-url}/arrow-right.png") no-repeat center center/contain;
        transition: all 0.3s ease;
      }
    }
    
    &-group {
      max-width: 1040px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-gap: 20px 70px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }

    @media (min-width: 1024px) {
      height: 68px;

      &:hover {
        color: white;
  
        > span::before {
          transform: translateY(-50%) translateX(5px);
        }
      }
    }
  }
}
