@charset "UTF-8";
.od-faq {
  width: 100%;
  color: #000000;
  margin-bottom: 60px;
  padding-bottom: 100px;
}

.od-faq__title {
  margin: 60px 0 10px 0;
  font-size: 48px;
  line-height: 60px;
  padding: 0 107px;
  text-align: center;
}

.od-faq__content {
  margin-top: 90px;
  padding: 0 200px;
  color: #000000;
}

@media (max-width: 1023px) {
  .od-faq__content {
    padding: 20px 20px;
    margin-top: 20px;
  }
}

.od-faq__content__list {
  width: 100%;
}

.od-faq__content__list__item-title {
  width: 100%;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  cursor: pointer;
  margin-top: 80px;
  position: relative;
}

.od-faq__content__list__item-title span {
  margin-left: 24px;
}

.od-faq__content__list__item-title span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '•';
  font-size: 24px;
}

.od-faq__content__list__item-description {
  width: 100%;
  background-color: #fbfbfd;
  padding: 20px;
  font-size: 20px;
  line-height: 40px;
}

.od-faq__content__list__item-description ul li {
  list-style-type: '*';
  text-indent: 10px;
}

.od-faq__content__list__item-description ul li a {
  margin-left: 5px;
  color: #0094c8;
  text-decoration: none;
}
