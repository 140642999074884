.common-loader {
    position: fixed;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
    overflow: hidden;
    &_overlay {
        position: fixed;
        background: #000;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    img {
        text-align: center;
        vertical-align: middle;
        margin: 0 auto;
        position: relative;
        height: 120px;
        width: 120px;
        top: 50%;
        transform: perspective(1px) translateY(-50%);
    }
}