$font-miller: "Miller-Banner";

$brown-81: #815624;

.body-anniversary-10th-collection {
  &__lineup {
    margin-top: 60px;

    #metal {
      margin-top: 80px;
    }

    @media (min-width: 768px) {
      > div > .base-anniversary-10th-collection__container {
        position: relative;
      }

      #plastic > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__title {
        right: 40px;
      }

      #metal > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__title {
        left: 40px;
      }
    }

    @media (min-width: 961px) {
      > div > .base-anniversary-10th-collection__container {
        max-width: none;
      }
    }

    @media (min-width: 1240px) {
      > div > .base-anniversary-10th-collection__container {
        max-width: 1120px;
      }
    }

    @media (min-width: 1240px) {
      #plastic > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__banner {
        margin-right: calc((1120px - (40px * 2) - 100vw) / 2);
      }

      #metal > .base-anniversary-10th-collection__container > .body-anniversary-10th-collection__lineup__banner {
        margin-left: calc((1120px - (40px * 2) - 100vw) / 2);
      }
    }

    &__title {
      font-size: 30px;
      text-align: center;
      color: $brown-81;

      @media (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        h3 {
          margin: 0;
        }
      }
    }

    &__banner {
      .base-anniversary-10th-collection__frame {
        margin-top: 0;

        @media (min-width: 768px) {
          width: calc(100% + 80px);
          margin: 188px -40px 0 -40px;
        }
      }
    }

    &__content {
      &__detail {
        margin-top: 60px;

        .base-anniversary-10th-collection__container {
          display: flex;
          flex-direction: column-reverse;
        }

        &__sku {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 23px;
          margin-top: 20px;

          h4 {
            font-size: 20px;
            line-height: 1.5em;
            font-family: $font-miller;
            letter-spacing: 1px;
            color: black;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        &__desc {
          font-size: 15px;
          font-weight: normal;
          line-height: 2;
          margin-bottom: 0;
          color: black;
        }
      }

      &__chips {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        gap: 12px;
        align-items: center;

        li {
          width: 16px;
          height: 16px;
          display: block;
          border-radius: 50%;
          position: relative;

          @media (min-width: 1024px) {
            cursor: pointer;
          }

          &[data-color="clear-grey"] {
            background-color: #A9A6B1;
          }

          &[data-color="clear-brown"] {
            background-color: #685333;
          }

          &[data-color="navy-blue"] {
            background-color: #48597D;
          }

          &[data-color="olive-green"] {
            background-color: #324924;
          }

          &[data-color="gunmetal"] {
            background-color: #5B564F;
          }

          &[data-color="silver"] {
            background-color: #9FA4A8;
          }
        }

        &__active {
          &::after {
            content: "";
            position: absolute;
            border: 1px solid black;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-sizing: border-box;
            z-index: -1;
          }
        }
      }

      @media (min-width: 768px) {
        &__detail {
          margin-top: 107px;

          .base-anniversary-10th-collection__container {
            display: grid;
          }

          &__sku {
            margin-top: 0;
          }
  
          &__desc {
            margin-top: 23px;
            width: 56%;
            letter-spacing: 0.48px;
          }
        }

        
      }
    }

    &__img-layout {
      margin-top: 40px;

      @media (min-width: 768px) {
        margin-top: 46px;

        .base-anniversary-10th-collection__container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            &:nth-child(1) {
              width: 64%;
            }

            &:nth-child(2) {
              width: 34%;
              max-width: 350px;
            }
          }
        }
      }

      @media (min-width: 1024px) {
        .base-anniversary-10th-collection__container {
          > div {
            &:nth-child(1) {
              width: 56%;
            }
          }
        }
      }
    }

    &__points {
      &-wrapper {
        position: relative;
      }

      &-main-img {
        display: block;
        width: 100%;
        height: auto;
        mix-blend-mode: darken;
        aspect-ratio: 115 / 72;
      }
    }

    &__selected {
      &-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &-dots {
        padding-left: 0;
        margin-bottom: 0;
        width: 100%;
        height: 100%;
        list-style-type: none;
        position: relative;
  
        li {
          position: absolute;

          &:nth-child(1) {
            top: 84%;
            left: 1%;
            transform: translate(-5%, -83%);

            span {
              &::after{
                content: '';
                position: absolute;
                width: 45.714285714285714%;
                height: 74.285714285714286%;
                right: -46%;
                bottom: 47%;
                border-right: 1px solid $brown-81;
                border-bottom: 1px solid $brown-81;
              }
            }

            @media (min-width: 768px) {
              left: 0;
              top: inherit;
              bottom: 0;

              span {
                &::after {
                  width: 31px;
                }
              }
            }
          }

          &:nth-child(2) {
            top: 68%;
            right: 8%;
            transform: translate(-8%, -30%);

            span {
              &::after{
                content: '';
                position: absolute;
                width: 102.857142857142857%;
                height: 140%;
                right: 97.142857142857143%;
                bottom: 50%;
                border-left: 1px solid $brown-81;
                border-bottom: 1px solid $brown-81;
              }
            }

            @media (min-width: 768px) {
              top: auto;
              bottom: 14%;
            }
          }

          &:nth-child(3) {
            top: 21%;
            right: 17%;
            transform: translate(-8%, -30%);

            span {
              &::after{
                content: '';
                position: absolute;
                width: 134.285714285714286%;
                height: 45.714285714285714%;
                left: -134%;
                bottom: 5%;
                border-top: 1px solid $brown-81;
                border-left: 1px solid $brown-81;
              }
            }

            @media (min-width: 768px) {
              top: 14%;
              left: inherit;
              right: 29%;
            }
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 34px;
            background-color: $brown-81;
            border-radius: 50%;
            opacity: 0.5;
          }
  
          p {
            margin-bottom: 0;
            color: white;
            letter-spacing: 0.03em;
            font-family: $font-miller;
            font-size: 16px;
            font-weight: normal !important;
          }
        }

        &__active {
          span {
            opacity: 1 !important;
          }
        }

        @media (min-width: 768px) {
          li {
            span {
              width: 48.42px;
              height: 48.42px;
            }
          }
        }

        @media (min-width: 1024px) {
          li {
            cursor: pointer;
            
            span {
              width: 58.14px;
              height: 58.14px;
            }

            p {
              font-size: clamp(16px, 1.781vw, 30px);
            }
          }
        }

        @media (min-width: 1296px) {
          li {
            span {
              width: 64.06px;
              height: 64.06px;
            }
          }
        }
      }
    }

    &__slider {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
      max-width: 100%;

      &__title {
        width: 100%;
        height: auto;
        margin-bottom: 10px;

        h3 {
          font-size: 20px;
          text-align: center;
          font-family: $font-miller;
          color: $brown-81;
          font-weight: normal !important;
          line-height: 1.5;
          letter-spacing: 1px;
        }

        @media (min-width: 768px) {
          margin-top: 30px;
          margin-bottom: 0;

          h3 {
            text-align: left;
          }
        }

        &--hide {
          &-sp {
            display: none;
          }

          &-pc {
            display: block;
          }

          @media (min-width: 768px) {
            &-sp {
              display: block;
            }
  
            &-pc {
              display: none;
            }  
          }
        }
        
      }

      &__img {
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: 154/75;
        border: 1px solid $brown-81;

        @media (min-width: 768px) {
          aspect-ratio: 232/129;
        }

        @media (min-width: 1024px) {
          aspect-ratio: 319/178;
        }
      }

      &__desc {
        color: $brown-81;
        font-size: 15px;
        font-weight: 100 !important;
        line-height: 2;
        letter-spacing: 0.42px;
        margin: 40px 0 0 0;

        @media (min-width: 768px) {
          margin: 0;
          font-size: 16px;
          line-height: 34px;
          letter-spacing: 0.48px;
        }
      }

      > .slick {
        &-dots {
          display: flex !important;
          align-items: center;
          padding-left: 0;
          margin-bottom: 0;
          list-style-type: none;
          position: absolute;
          top: calc(62% + 10px);
          left: 50%;
          transform: translate(-50%, -50%);

          > li {
            width: 20px;
            height: 20px;
            padding: 5px;
          }

          > li > button {
            width: 10px;
            height: 10px;
            color: transparent;
            position: relative;
            padding: 0;
            border: none;
            background-color: transparent;
            
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              background-color: $brown-81;
              border-radius: 50%;
              border: none;
              opacity: 0.5;
            }
          }

          > .slick-active > button::before {
            opacity: 1;
          }

          @media (min-width: 768px) {
            top: 145px;
          }
        }
      }
    }
  }
}
