.cart-footer {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    overflow: hidden;
    display: block;
    .icon-chkout-arw {
        width: 20px;
        height: 20px;
        background-position: -1150px -100px;
        vertical-align: -4px;
        margin-left: 3px;
    }
    &_green {
        padding: 8px 0px;
        background: #e8792a;
        color: #fff;
        display: block;
        overflow: hidden;
        width: 262px;
        border-radius: 2px;
        text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
        font-size: 15px;
        float: none;
        margin: 0 0 0 31px;
        letter-spacing: 1px;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
}

.loaderLK.proceed-checkout-loader {
    position: relative;
    text-align: center;
    background: #e8782b;
}

.loaderLK.proceed-checkout-loader .barLK {
    float: none;
    display: inline-block;
}


/* Initial state */

.barLK {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    float: left;
    margin-right: 4px;
    margin-top: 6px;
    width: 6px;
    height: 18px;
    /* Set the animation properties */
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: loadingbarLK;
}

/* Delay both the second and third bar at the start */

.loaderLK .barLK:nth-child(2) {
    animation-delay: 0.1s;
}

.loaderLK .barLK:nth-child(3) {
    animation-delay: 0.2s;
}

/* The actual animation */

@keyframes loadingbarLK {
    0% {}
    10% {
        margin-top: 5px;
        height: 22px;
        border-color: #ffffff;
        background-color: #ffffff;
    }
    20% {
        margin-top: 0px;
        height: 32px;
        border-color: #ffffff;
        background-color: #ffffff;
    }
    30% {
        margin-top: 1px;
        height: 30px;
        border-color: #ffffff;
        background-color: #ffffff;
    }
    40% {
        margin-top: 3px;
        height: 26px;
    }
    50% {
        margin-top: 5px;
        height: 22px;
    }
    60% {
        margin-top: 6px;
        height: 18px;
    }
    70% {}
    /* Missing frames will cause the extra delay */
    100% {}
}

.finl-cart-conti {
    float: left;
    border-left: 1px dashed #ccc;
    padding: 12px 0 4px;
    min-height: 69px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &.custom-checkoutBtn {
        background-color: #e8792a;
        padding: 0;
        .button-green {
            padding: 5.5% 0;
            background: #e8792a;
            color: #fff;
            display: block;
            overflow: hidden;
            width: 300px;
            border-radius: 2px;
            text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
            font-size: 15px;
            float: none;
            margin: 0 0 0 31px;
            letter-spacing: 1px;
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
            span {
                font-size: 20px;
                font-family: FuturaStd-Heavy;
                text-transform: uppercase;
                &.sub-text {
                    font-size: 14px;
                    letter-spacing: 1.5px;
                    font-family: FuturaStd-Medium;
                    display: block;
                    text-transform: none;
                }
            }
        }
        .icon-chkout-arw {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            background: none;
            margin-top: -5px;
            width: auto;
            height: auto;
            vertical-align: middle;
            &:before {
                content: "\F178";
            }
        }
        .proceed-checkout-loader {
            height: 100%;
            width: 100%;
            left: 0;
            padding: 5.5% 0;
        }
    }
}