// Breakpoint map
$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1440px
);

// Function to get breakpoint value
@function get-breakpoint-value($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @return map-get($breakpoints, $breakpoint);
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}.";
  }
}

// Mixin for 'up' breakpoints
@mixin mq-up($breakpoint) {
  @media (min-width: get-breakpoint-value($breakpoint)) {
    @content;
  }
}

// Mixin for 'down' breakpoints
@mixin mq-down($breakpoint) {
  @media (max-width: get-breakpoint-value($breakpoint) - 1) {
    @content;
  }
}

// Mixin for 'only' breakpoints
@mixin mq-only($breakpoint) {
  $next-breakpoint: nth(map-keys($breakpoints), index(map-keys($breakpoints), $breakpoint) + 1);
  @if $next-breakpoint {
    @media (min-width: get-breakpoint-value($breakpoint)) and (max-width: get-breakpoint-value($next-breakpoint) - 1) {
      @content;
    }
  } @else {
    @media (min-width: get-breakpoint-value($breakpoint)) {
      @content;
    }
  }
}

// Mixin for 'between' breakpoints
@mixin mq-between($lower, $upper) {
  @media (min-width: get-breakpoint-value($lower)) and (max-width: get-breakpoint-value($upper) - 1) {
    @content;
  }
}

// General breakpoint mixin (for backward compatibility and flexibility)
@mixin mq($breakpoint, $direction: 'up') {
  @if $direction == 'up' {
    @include breakpoint-up($breakpoint) { @content; }
  } @else if $direction == 'down' {
    @include breakpoint-down($breakpoint) { @content; }
  } @else if $direction == 'only' {
    @include breakpoint-only($breakpoint) { @content; }
  } @else if $direction == 'between' {
    @error "For 'between', use breakpoint-between(lower, upper) instead.";
  } @else {
    @error "Invalid direction: #{$direction}. Use 'up', 'down', or 'only'.";
  }
}
