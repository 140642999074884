$font-founders: 'Founders Grotesk', sans-serif;

$white: #fff;
$whitee5: #E5E8ED;
$whiteee: #EEF0F2;
$black: #000;
$black28: #283750;
$gray93: #939598;
$red: #BF0000;
$brown: #B47E00;

.od-body-owndays-progressive-cp {
  $baseImgUrl: 'https://static.lenskart.com/media/owndays/img/progressive-cp';

  width: 100%;
  height: auto;

  &__container {
    &__meaning-box {
      width: 100%;
      height: auto;
      position: relative;
    }

    &__meaning {
      width: 100%;
      max-width: 760px;
      height: auto;
      padding: 144px 0 150px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      &--text {
        padding: 144px 0 0 0;
      }

      &--vdo {
        padding: 0 0 140px 0;
      }

      @media (max-width: 885px) {
        padding: 144px 40px 150px;
      }

      @media (max-width: 430px) {
        padding: 100px 40px 100px;

        &--text {
          padding: 100px 40px 0 40px;
        }

        &--vdo {
          padding: 0 0 100px 0;
          background-color: $whitee5;
        }
      }
    }

    &__meaning-presbyopia {
      width: calc(100% - 120px);
      max-width: 1665px;
      height: 632px;
      position: relative;
      margin: 0 auto;
      zoom: 160%;

      @media (max-width: 2431px) {
        zoom: 135%;
      }

      @media (max-width: 2378px) {
        zoom: 138%;
      }

      @media (max-width: 2111px) {
        zoom: 120%;
      }

      @media (max-width: 1794px) {
        width: 100%;
        max-width: 1440px;
        zoom: unset;
      }

      @media (max-width: 1176px) {
        height: 850px;
      }

      @media (max-width: 671px) {
        height: 797px;
      }

      @media (max-width: 609px) {
        height: 823px;
      }

      @media (max-width: 448px) {
        height: 844px;
        background-color: $whitee5;
      }

      @media (max-width: 427px) {
        height: 859px;
      }

      @media (max-width: 406px) {
        height: 877px;
      }

      @media (max-width: 390px) {
        height: 880px;
      }

      @media (max-width: 390px) {
        height: 880px;
      }
    }

    &__book-online-btn {
      padding: 150px 0;

      @media (max-width: 550px) {
        padding: 100px 20px;
      }
    }

    &__benefits-box {
      width: 100%;
      height: auto;
      position: relative;
    }

    &__benefits {
      width: calc(100% - 120px);
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;
      margin: 0 auto;
      padding: 150px 0;

      @media (max-width: 550px) {
        width: calc(100% - 40px);
        padding: 0 0 126px 0;
      }
      
    }

    &__benefits-list {
      display: grid;
      grid-template-columns: 280px 280px 280px 280px;
      column-gap: 53px;

      @media (max-width: 1240px) {
        grid-template-columns: 260px 260px;
        row-gap: 53px;
      }

      @media (max-width: 700px) {
        grid-template-columns: 260px 260px;
        row-gap: 10px;
        column-gap: 10px;
      }

      @media (max-width: 574px) {
        grid-template-columns: 230px 230px;
      }

      @media (max-width: 550px) {
        grid-template-columns: 200px 200px;
      }

      @media (max-width: 475px) {
        grid-template-columns: 170px 170px;
      }
    }

    &__persons {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      padding-bottom: 101px;

      @media (max-width: 1240px) {
        padding-top: 74px;
      }

      @media (max-width: 550px) {
        gap: 34px;
        padding-bottom: 101px;
        background-color: $black28;
      }
    }

    &__persons-list {
      width: calc(100% - 340px);
      height: auto;
      display: flex;
      gap: 40px;
      justify-content: center;

      @media (max-width: 1240px) {
        width: calc(100% - 120px);
      }

      @media (max-width: 860px) {
        width: calc(100% - 60px);
        flex-direction: column;
      }

      @media (max-width: 550px) {
        width: calc(100% - 40px);
        gap: 30px;
      }
    }

    &__types {
      width: calc(100% - 340px);
      height: auto;
      padding-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
      margin: 0 auto;

      @media (max-width: 1240px) {
        width: calc(100% - 120px);
        padding-top: 100px;
      }

      @media (max-width: 704px) {
        width: calc(100% - 60px);
      }

      @media (max-width: 650px) {
        width: calc(100% - 40px);
        gap: 30px;
        padding-bottom: 50px;
      }

      @media (max-width: 550px) {
        position: relative;
        
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -20px;
          width: calc(100% + 40px);
          height: 100%;
          background-color: $whitee5;
          z-index: -1;
        }
      }
    }

    &__comparison-box {
      width: 100%;
      height: auto;
      position: relative;
    }

    &__comparison {
      width: calc(100% - 120px);
      max-width: 1440px;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      @media (max-width: 768px) {
        width: calc(100% - 40px);
      }
    }

    &__lens-features {
      width: calc(100% - 120px);
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      gap: 50px;
      padding: 150px 0 0 0;

      @media (max-width: 768px) {
        width: calc(100% - 40px);
      }

      @media (max-width: 550px) {
        width: calc(100% - 20px);
        gap: 30px;
        padding: 100px 0 0 0;
      }
    }
  }

  .meaning {
    &__title, &__desc {
      font-family: $font-founders;
    }

    &__title {
      color: $black28;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 46px;
      letter-spacing: 3px;
      margin: 0;

      br {
        display: none;
      }
    }

    &__desc {
      color: $black;
      font-family: $font-founders;
      font-size: 18px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 32px;
      letter-spacing: 0.9px;
      margin: 54px 0 50px 0;
    }

    &__vdo {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      object-fit: cover;

      &-cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &-container {
        width: 100%;
        height: auto;
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding-top: 48.85%;

        &:hover {
          .meaning__vdo-cover {
            background-color: transparent;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80px;
          height: 80px;
          background: url("#{$baseImgUrl}/play-vdo-icon.svg") no-repeat center center; /* Use your play button image */
          background-size: contain;
          cursor: pointer;
          z-index: 2;
          transition: opacity 0.3s ease;

          @media (max-width: 430px) {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    &__playing {
      width: 100%;
      height: auto;
      position: relative;
      display: inline-block;
      cursor: pointer;
      padding-top: 48.85%;
      
      .meaning__vdo-container {
        &::before {
          display: none;
        }
      }
    }

    @media (max-width: 669px) {
      &__title {
        br {
          display: block;
        }
      }
    }

    @media (max-width: 430px) {
      &__title {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 2px;
      }

      &__desc {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.8px;
        margin: 30px 0 50px 0;
      }

      &__vdo {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .meaning-presbyopia {
    &__desc {
      &-box {
        width: calc(100% - 154px);
        max-width: 900px;
        height: auto;
        background-color: $black28;
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
        border-radius: 0px 50px 0px 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;

        @media (max-width: 1383px) {
          width: 60%;
        }

        @media (max-width: 1200px) {
          width: 55%;
        }

        @media (max-width: 1176px) {
          width: 100%;
          z-index: 1;
          padding: 130px 0 100px 0;
        }

        @media (max-width: 671px) {
          gap: 30px;
        }
      }

      &-title, &-detail {
        font-family: $font-founders;
        color: $white;
      }

      &-title {
        font-size: 30px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 46px;
        letter-spacing: 3px;
        margin: 0;
        text-align: center;

        br {
          display: none;
        }

        @media (max-width: 671px) {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: 2px;

          br {
            display: block;
          }
        }
      }

      &-detail {
        font-size: 18px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 32px;
        letter-spacing: 0.9px;
        padding: 0 120px;

        @media (max-width: 1383px) {
          padding: 0 60px;
        }

        @media (max-width: 858px) {
          padding: 0 40px;
        }

        @media (max-width: 671px) {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }

    &__image {
      width: 720px;
      height: 400px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      @media (max-width: 1176px) {
        z-index: 2;
      }

      @media (max-width: 858px) {
        width: 680px;
        height: 360px;
      }

      @media (max-width: 671px) {
        width: calc(100% - 40px);
        height: auto;
      }
    }
  }

  .book-online-btn {
    width: 500px;
    height: 80px;
    border-radius: 40px;
    background-color: $black28;
    padding: 29px 20px 28px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    border: 3px solid $black28;
    transition: 0.5s;

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    @media (min-width: 768px) {
      &:hover {
        text-decoration: none;
        background-color: $white;
        border: 3px solid $black28;

        span {
          color: $black28;
        }

        img {
          content: url("#{$baseImgUrl}/arrow-right-circle-blue-icon.svg");
        }
      }
    }

    span {
      color: $white;
      font-family: $font-founders;
      font-size: 20px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 32px;
      letter-spacing: 2px;
    }

    img {
      width: 50px;
      height: 50px;
    }

    @media (max-width: 550px) {
      width: 350px;
      height: 60px;
      padding: 25px 10px 25px 40px;

      span {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.8px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .bg-light-blue {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 175px);
    height: calc(100% - 670px);
    border-radius: 0px 50px 0px 0px;
    background-color: $whitee5;
    z-index: -1;

    @media (max-width: 1176px) {
      display: none;
    }
  }

  .bg-dark-blue {
    position: absolute;
    top: 454px;
    left: 0;
    width: 100%;
    height: 652px;
    background-color: $black28;
    z-index: -1;

    &::before {
      position: absolute;
      top: 652px;
      content: '';
      width: 100%;
      height: calc(100% + 680px);
      background-color: $whiteee;
      z-index: -1;
    }

    @media (max-width: 1240px) {
      top: 49%;
      height: 83%;

      &::before {
        display: none;
      }
    }

    @media (max-width: 860px) {
      top: 100%;
      height: 83%;
    }

    @media (max-width: 430px) {
      display: none;
    }
  }

  .bg-dark-blue-comparison {
    position: absolute;
    top: 410px;
    left: 0;
    width: 100%;
    height: 93%;
    background-color: $black28;
    z-index: -1;

    &::before {
      position: absolute;
      top: 100%;
      content: '';
      width: 100%;
      height: 100%;
      background-color: $whiteee;
      z-index: -1;
    }

    @media (max-width: 1240px) {
      top: 37%;
      height: 86%;
    }

    @media (max-width: 1024px) {
      top: 37%;
      height: 87%;
    }

    @media (max-width: 550px) {
      top: 78%;
      height: 50%;

      &::after {
        position: absolute;
        top: -156%;
        content: "";
        width: 100%;
        height: 156%;
        background-color: $whitee5;
        z-index: -1;
      }
    }
  }

  .benefits {
    &__title {
      margin: 0;
      font-family: $font-founders;
      font-size: 30px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 46px;
      letter-spacing: 3px;
      text-align: center;

      br {
        display: none;
      }
    }

    &__item {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: baseline;
      gap: 30px;
      padding: 26px 30px 50px 30px;
      border-radius: 10px;
      background-color: $whiteee;

      h5, span {
        font-family: $font-founders;
        color: $black;
        text-align: center;
      }

      img {
        width: auto;
        max-width: 104px;
        height: auto;
        max-height: 60px;
      }

      h5 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 32px;
        letter-spacing: 2px;
        margin: 0;
      }

      span {
        margin: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 32px;
        letter-spacing: 0.4px;
      }

      &:nth-child(1) {
        img {
          margin-top: 19px;
        }
      }

      &:nth-child(4) {
        h5 {
          white-space: nowrap;
        }
      }
    }

    @media (max-width: 550px) {
      &__title {
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 28px;

        br {
          display: block;
        }
      }

      &__item {
        gap: 20px;
        padding: 25px 20px 30px 20px;
  
        img {
          width: auto;
          max-width: 91px;
          height: auto;
          max-height: 56px;
        }
  
        h5 {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.8px;
          margin: 0;
        }
  
        span {
          margin: 0;
          line-height: 22px;
          letter-spacing: 0.8px;
          text-align: left;
        }
  
        &:nth-child(1) {
          img {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .persons {
    &__title {
      margin: 0;
      color:#FFF;
      font-family: $font-founders;
      font-size: 30px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 46px;
      letter-spacing: 3px;
      text-align: center;

      br {
        display: none;
      }

      @media (max-width: 860px) {
        br {
          display: block;
        }
      }

      @media (max-width: 550px) {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 2px;
      }
    }

    &__item {
      width: 360px;
      height: auto;
      padding: 30px;
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      border-radius: 10px;
      box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.10);

      img {
        width: 250px;
        height: auto;
      }

      span {
        color: $black;
        font-family: $font-founders;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 32px;
        letter-spacing: 0.9px;
      }

      @media (max-width: 1240px) {
        width: 100%;
      }

      @media (max-width: 860px) {
        flex-direction: row;
      }

      @media (max-width: 550px) {
        gap: 10px;
        padding: 20px;

        img {
          width: 150px;
        }

        span {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.8px;
          text-align: left;
        }
      }
    }
  }

  .types {
    h2, span {
      font-family: $font-founders;
    }

    h2 {
      color: $black28;
      font-size: 30px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 46px;
      letter-spacing: 3px;
      margin: 0;
      text-align: center;
    }

    span {
      width: 100%;
      max-width: 760px;
      height: auto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 32px;
      letter-spacing: 0.9px;
      color: $black;
      margin: 0 auto;
    }

    @media (max-width: 650px) {
      h2 {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 2px;
      }

      span {
        width: calc(100% - 20px);
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.8px;
      }
    }
  }

  .comparison {
    &__title {
      font-family: $font-founders;
      font-size: 20px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 32px;
      letter-spacing: 2px;
      color: $black28;
      text-align: center;
      margin: 0 0 50px 0;

      @media (max-width: 550px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.8px;
        margin: 0 0 30px 0;
      }
    }

    &__nav {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 4px;

      @media (max-width: 1265px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 4px;
      }
    }

    &__nav-item {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray93;
      cursor: pointer;

      &--active {
        &:nth-child(1) {
          background-color: $red;
        }

        &:nth-child(2) {
          background-color: $black28;
        }

        &:nth-child(3) {
          background-color: $brown;
        }

        &:nth-child(4) {
          background-color: $black;
        }
      }

      strong {
        font-family: $font-founders;
        font-size: 18px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 20px;
        letter-spacing: 1.8px;
        color: $white;
        text-align: center;

        @media (max-width: 550px) {
          font-size: 16px;
          letter-spacing: 1.6px;
        }

        @media (max-width: 414px) {
          padding: 0 10px;
        }
      }
    }

    &__desc {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 67.08% 32.92%;
      grid-template-rows: 680px;
      
      &__header-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__detail {
        width: 100%;
        height: auto;
        padding: 50px;
        background-color: $white;
        position: relative;

        &__rank {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: space-between;

          &:nth-child(2) {
            margin: 30px 0;
          }

          span {
            color: $black;
            font-family: $font-founders;
            font-size: 20px;
            font-style: normal;
            font-weight: 500 !important;
            line-height: 20px;
            letter-spacing: 1px;
          }

          .circle-box {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          .circle {
            border-radius: 50%;
            width: 13px;
            height: 13px;
            border: 1px solid $black;

            &--selected {
              background-color: $black;
            }
          }

          @media (max-width: 550px) {
            &:nth-child(2) {
              margin: 20px 0;
            }

            span {
              font-size: 16px;
              letter-spacing: 0.8px;
            }

            .circle-box {
              gap: 3.85px;
              margin-right: 25%;
            }

            .circle {
              width: 10px;
              height: 10px;
            }
          }

          @media (max-width: 385px) {
            .circle-box {
              margin-right: 0;
            }
          }
        }

        &__key-differences {
          padding: 50px 0;

          h3 {
            margin: 0;
            font-family: $font-founders;
            font-size: 20px;
            font-style: normal;
            font-weight: 500 !important;
            line-height: 20px;
            letter-spacing: 1px;
            text-decoration: underline;
            text-underline-offset: 5px;
          }

          ul {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            list-style-type: none;
            padding-left: 0;

            li {
              font-size: 18px;
              font-style: normal;
              font-weight: 400 !important;
              line-height: 32px;
              letter-spacing: 0.9px;
              // margin-left: -10px;
              position: relative;
              padding-left: 1em;

              &::before {
                content: '';
                position: absolute;
                top: 13px;
                left: 0;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $black;
              }

            }
          }

          @media (max-width: 550px) {
            padding: 40px 0;

            h3 {
              font-size: 14px;
              text-underline-offset: 0.7px;
            }

            ul {
              margin-top: 25px;
              // margin-left: -10px;
  
              li {
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.8px;
                position: relative;
  
                &::before {
                  top: 12px;
                  // left: -16px;
                  width: 5px;
                  height: 5px;
                }
              }
            }
          }
        }

        &__price {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;

          h3, &__value, &__plus {
            font-family: $font-founders;
          }

          h3 {
            margin: 0;
            font-size: 20px;
            font-style: normal;
            font-weight: 500 !important;
            line-height: 20px;
            letter-spacing: 1px;
            text-decoration: underline;
            text-underline-offset: 5px;
          }

          &__value-box {
            margin-top: 25px;
            display: flex;
            align-items: center;

            &--zero {
              font-family: $font-founders;
              margin-top: 50px;
            }

            &--600 {
              margin-bottom: 80px;
            }
          }

          &__value {
            color: $red;
            font-size: 30px;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 20px;
            letter-spacing: 1.5px;
            margin-left: 23px;
            position: relative;

            &--zero {
              font-family: $font-founders;
              font-size: 100px;
              font-style: normal;
              font-weight: 600 !important;
              line-height: 20px;
              letter-spacing: 5px;
            }

            &::before {
              content: '$';
              position: absolute;
              left: -23px;
              top: 0;
              font-size: 30px;
              letter-spacing: 1.5px;
            }

            &--suffix {
              color: $black;
              font-family: $font-founders;
              font-size: 16px;
              font-style: normal;
              font-weight: 500 !important;
              line-height: 16px;
              letter-spacing: 0.8px;
              margin-left: 5px;
            }
          }

          &__plus {
            color: $red;
            font-size: 30px;
            font-style: normal;
            font-weight: 600 !important;
            line-height: 20px;
            letter-spacing: 1.5px;
            margin: 2px 10px 2px 0;
          }

          @media (max-width: 550px) {
            h3 {
              font-size: 14px;
              letter-spacing: 0.7px;
            }

            &__value-box {
              margin-top: 25px;
  
              &--zero {
                font-family: $font-founders;
                margin-top: 29px;
              }
            }

            &__value {
              font-size: 20px;
              letter-spacing: 1px;
              margin-left: 16px;
  
              &--zero {
                font-family: $font-founders;
                font-size: 70px;
                letter-spacing: 3.5px;
              }
  
              &::before {
                font-size: 20px;
                letter-spacing: 1px;
                left: -16px;
              }
  
              &--suffix {
                font-size: 14px;
                letter-spacing: 0.7px;
                margin-left: 8px;
              }
            }
  
            &__plus {
              font-size: 20px;
              letter-spacing: 1px;
              margin: 0 4px 4px 0;
            }
          }

          @media (max-width: 430px) {
            height: 148px;
          }
        }

        &__remark {
          position: absolute;
          right: 50px;
          bottom: 5%;
          color: $black;
          font-family: $font-founders;
          font-size: 14px;
          font-style: normal;
          font-weight: 400 !important;
          line-height: 24px;
          letter-spacing: 0.7px;

          &--600 {
            width: 75%;

            @media (max-width: 1265px) {
              width: auto;
              left: 40px;
            }
          }

          @media (max-width: 550px) {
            right: 20px;
            font-size: 12px;
            letter-spacing: 0.6px;
            bottom: 20px;

            &--600 {
              width: 89%;
              left: 20px 
            }
          }
        }

        @media (max-width: 1292px) {
          padding: 40px;
        }

        @media (max-width: 550px) {
          padding: 20px;
        }
      }

      &__image-text {
        color: $white;
        font-family: $font-founders;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 24px;
        letter-spacing: 0.7px;
        margin-top: 8px;

        @media (max-width: 550px) {
          font-size: 12px;
          letter-spacing: 0.6px;
        }
      }

      @media (max-width: 1265px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }
  }

  .lens-features {
    h2 {
      margin: 0;
      color: $white;
      text-align: center;
      font-family: $font-founders;
      font-size: 30px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 46px;
      letter-spacing: 3px;

      @media (max-width: 550px) {
        font-size: 20px;
      }
    }

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      @media (max-width: 1024px) {
        gap: 25px;
      }

      @media (max-width: 550px) {
        gap: 10px;
      }
    }

    &__item {
      width: 260px;
      height: 187px;
      border-radius: 10px;
      background-color: $white;
      box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.10);
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      padding: 22px 0;

      &-img {
        width: auto;
        max-width: 100px;
        height: auto;
      }

      &-text {
        color: $black;
        text-align: center;
        font-family: $font-founders;
        font-size: 20px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 32px;
        letter-spacing: 2px;
      }

      &:nth-child(1) {
        gap: 30px;

        .lens-features__item-img {
          margin-top: 1px;
        }
      }

      &:nth-child(3) {
        .lens-features__item-img {
          margin-top: 13px;
        }

        .lens-features__item-text {
          br {
            display: none;
          }
        }
      }

      @media (max-width: 950px) {
        width: 240px;
      }

      @media (max-width: 890px) {
        width: 200px;
        height: 220px;
      }

      @media (max-width: 768px) {
        width: 130px;
        height: 184px;
        padding: 20px 0;
        gap: 20px;

        &-img {
          max-width: 62px;
        }

        &-text {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.8px;
        }

        &:nth-child(1) {
          gap: 30px;
        }

        &:nth-child(1), &:nth-child(3) {
          .lens-features__item-img {
            margin-top: 0;
          }
        }
  
        &:nth-child(3) {
          .lens-features__item-img {
            height: 48px;
          }

          .lens-features__item-text {
            br {
              display: block;
            }
          }
        }
      }

      @media (max-width: 430px) {
        width: 110px;
        height: 165px;
      }
    }
  }
}
