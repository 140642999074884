.secondary-header {
  width: 100%;
  padding: 16px 50px 17px;
  position: fixed;
  top: 0;
  z-index: 999;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

@media (max-width: 1024px) {
  .secondary-header {
    padding: 10px 20px;
  }
}

.secondary-header.scrolled {
  background-color: white;
}
