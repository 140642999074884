.od-care {
  margin-bottom: 120px;
}

.od-care-banner {
  width: 100%;
  margin: 0 auto;
}

.od-care-pay-description {
  width: 100%;
  text-align: center;
}

.od-care-pay-description__text {
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  margin: 120px 0;
}
