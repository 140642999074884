.od-category-menu {
  position: relative;
  > a {
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .category {

    .od-category-menu_list {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000042;
      font-size: 13px;

      .od-category-menu_items {
        display: flex;
        flex-direction: column;
        max-width: 135px;

        span {
          display: block;
          color: #000000;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &:hover {
      background: #FAFAFA;
      .select-submenu {
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
        .nav-subcategory-level {
          margin-top: 0;
        }
      }
    }
  }

  &_data {
    min-height: 70px;
    padding: 10px 25px 10px 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
