@import url("https://use.typekit.net/mxa1grw.css");
html {
  scroll-behavior: smooth;
}
.base-freaks-store__hide {
  display: none !important;
}
.base-freaks-store__container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1110px;
  font: 400 14px/26px "Noto Sans JP", sans-serif;
  letter-spacing: normal;
  color: black;
  overflow: hidden;
}
.base-freaks-store__btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  height: 66px;
  border-radius: 10rem;
  background: white url("https://static.lenskart.com/media/owndays/img/freaks-store/arrow-right--green.svg") no-repeat right 20px center/8px auto;
  color: #555E2B;
  font: 700 14px/20px "Noto Sans JP", sans-serif;
  padding: 0 40px 0 20px;
}
.base-freaks-store__btn--black {
  background: black url("https://static.lenskart.com/media/owndays/img/freaks-store/arrow-right--white.svg") no-repeat right 20px center/8px auto;
  color: white;
}
@media (min-width: 1024px) {
  .base-freaks-store__btn {
    height: 70px;
    padding: 0 50px 0 30px;
    background-position: right 30px center;
  }
  .base-freaks-store__btn:hover {
    background-color: #CBCEC7;
    color: white;
  }
  .base-freaks-store__btn:hover, .base-freaks-store__btn:active, .base-freaks-store__btn:visited, .base-freaks-store__btn:focus {
    text-decoration: none;
  }
  .base-freaks-store__btn--black:hover {
    background-color: #555E2B;
  }
}
.base-freaks-store__title {
  font: 700 12px/10px "Noto Sans JP", sans-serif;
  letter-spacing: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 12px;
  margin: 0;
}
.base-freaks-store__title strong {
  font: 700 42px/32px din-condensed, sans-serif;
  letter-spacing: normal;
  color: #555E2B;
  letter-spacing: 0.03em;
}
@media (min-width: 768px) {
  .base-freaks-store__title {
    font-size: 18px;
    line-height: 25px;
  }
  .base-freaks-store__title strong {
    font-size: 70px;
    line-height: 60px;
  }
}
