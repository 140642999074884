.search-bar-static {
  position: relative;
  
  &__layout {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 8px 30px;
    border-bottom: 1px solid #555;
    align-items: center;
    gap: 30px;
  }

  &__input {
    flex: 1 0 auto;
    padding: 0;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.28px;
    color: #000;

    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.28px;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__icon {
    width: 14px;
    flex-shrink: 0;
    cursor: pointer;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;

    &:hover {
        opacity: 0.5;
    }
    
    &--close {
      width: 10px;
    }
  }
}
