$font-neuzeitgro-reg: 'NeuzeitGroReg';
$font-neuzeitgro-bold: 'NeuzeitGroBold';
$font-space-mono: 'Space Mono', monospace;

$blue: #4e7098;
$black: #000000;
$white: #ffffff;

$base-img-url: 'https://static.lenskart.com/media/owndays/img/owndays-pc';

.base-owndays-pc {
  &__hidden-overflow-x {
    overflow-x: hidden;
  }

  &__container {
    width: 100%;
    max-width: calc(1200px + 10%);
    height: auto;
    padding: 0 5%;

    @media (min-width: 768px) {
      max-width: calc(1200px + 20%);
      padding: 0 10%;
      margin: 0 auto;
    }
  }

  &__container-bg-grid {
    width: 100%;
    max-width: calc(1200px + 20%);
    padding: 0 10%;

    @media (min-width: 768px) {
      max-width: calc(1200px + 10%);
      padding: 0 5%;
      margin: 0 auto;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    background-color: $blue;
    color: $white;
    border-radius: 10rem;
    height: 60px;
    font: 700 14px/20px $font-neuzeitgro-bold;
    font-weight: 700 !important;
    letter-spacing: 0.1em;
    padding: 9px 60px 8px 30px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 30px;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      background-repeat: repeat;
    }

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    &--down {
      &::before {
        background-image: url("#{$base-img-url}/arrow-alt-circle-down.png");
        background-position: center 0px;
        background-size: 16px 16px;
      }
    }

    &--left {
      &::before {
        background-image: url("#{$base-img-url}/arrow-alt-circle-left.png");
        background-position: 0px center;
        background-size: 16px 16px;
      }
    }

    &--right {
      &::before {
        background-image: url("#{$base-img-url}/arrow-alt-circle-right.png");
        background-position: 0px center;
        background-size: 16px 16px;
      }
    }

    &--black {
      background-color: $black;
      letter-spacing: 0.05em;

      &:hover,
      &:focus,
      &:visited {
        background-color: $black;
        color: $white;
      }
    }

    &--circle {
      width: 54px;
      height: 54px;
      padding: 0;
      border: none;
      justify-content: center;

      &::before {
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
      }
    }

    @media (min-width: 768px) {
      max-width: 220px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
      transition: 0.5s;

      &:hover,
      &:focus,
      &:focus:hover ,&:visited:hover {
        background-color: $black;
        color: $white;
      }

      &--down {
        &:hover {
          &::before {
            animation: owndays-pc-btn-hover-down 0.3s ease forwards;
          }
        }
      }

      &--left {
        &:hover {
          &::before {
            animation: owndays-pc-btn-hover-left 0.3s ease forwards;
          }
        }
      }

      &--right {
        &:hover {
          &::before {
            animation: owndays-pc-btn-hover-right 0.3s ease forwards;
          }
        }
      }

      &--black {
        &:hover,
        &:focus,
        &:visited:hover {
          background-color: $blue;
          color: $white;
        }
        &:visited {
          background-color: $black;
          color: $white;
        }
      }

      &--circle {
        &:focus {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }

  &__bg {
    background-image: url("#{$base-img-url}/line-bg-sp.png");
    background-repeat: repeat;
    background-position: left top;
    background-size: auto;
  
    @media (min-width: 1024px) {
      background-image: url("#{$base-img-url}/line-bg-pc.svg");
    }
  }

  &__title {
    font-family: $font-space-mono;
    font-weight: 300 !important;
    line-height: 1.5;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 40px;

    &-name {
      font-size: 50px;
    }

    &-no {
      font-size: 10px;
      position: relative;
      letter-spacing: 2px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(2em + 5px);
        width: 119px;
        border-top: 1px solid $black;
        transform: translateY(-50%);
      }
    }

    &-content {
      p {
        font-family: $font-neuzeitgro-reg;
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
        letter-spacing: 0;

        &:nth-child(1) {
          margin-bottom: 20px;
        }

        &:nth-child(2) {
          margin-bottom: 40px;
        }
      }
    }

    @media (min-width: 1024px) {
      margin-bottom: 100px;

      &-name {
        font-size: 90px;
      }

      &-no {
        &::before {
          width: 200px;
        }
      }

      &-grid {
        display: grid;
        grid-template-columns: 1fr calc(50% + 120px);
      }

      &-content {
        margin-top: 50px;
        line-height: 1.625;
        letter-spacing: 0.5px;

        p {
          font-size: 15px;
        }

        div {
          .base-owndays-pc__btn {
            max-width: 275px;
          }
        }
      }
    }
  }
}

@keyframes owndays-pc-btn-hover-down {
  0% {
    background-position: center 0px;
  }
  100% {
    background-position: center 16px;
  }
}

@keyframes owndays-pc-btn-hover-left {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: -16px center;
  }
}

@keyframes owndays-pc-btn-hover-right {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: 16px center;
  }
}
