.base-ortho-k-lens {
  font-family: "Noto Sans", sans-serif;
  font-weight: 400 !important;
  color: #413d3d;
  letter-spacing: 0.02em;
  line-height: 2.2;
}

@media (max-width: 430px) {
  .base-ortho-k-lens {
    overflow-x: hidden;
  }
}

.base-ortho-k-lens__container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  max-width: 1340px;
}

@media (max-width: 1450px) {
  .base-ortho-k-lens__container {
    max-width: 1220px;
  }
}

@media (max-width: 1295px) {
  .base-ortho-k-lens__container {
    padding-right: 27px;
    padding-left: 27px;
    max-width: 1174px;
  }
}

@media (max-width: 1176px) {
  .base-ortho-k-lens__container {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .base-ortho-k-lens__container {
    padding-right: 27px;
    padding-left: 27px;
    max-width: 1174px;
  }
}

.base-ortho-k-lens__container--sub {
  max-width: 924px;
  margin-left: auto;
  margin-right: auto;
}

.base-ortho-k-lens__title {
  font: 700 28px/1.2 din-2014, sans-serif;
  font-weight: 700 !important;
  color: #413d3d;
  letter-spacing: 0.05em;
  margin: 0;
}

.base-ortho-k-lens__title--center {
  text-align: center;
}

@media (min-width: 768px) {
  .base-ortho-k-lens__title {
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .base-ortho-k-lens__title {
    font-size: 51px;
  }
}

.base-ortho-k-lens__button {
  font: 700 12px/1.4 "Noto Sans", sans-serif;
  letter-spacing: 0.02em;
  color: #2e5aa8;
  height: 75px;
  border: 3px solid #2e5aa8;
  border-radius: 4em;
  padding: 0 16px 0 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 418px;
  background-color: #f7f9fc;
  position: relative;
}

.base-ortho-k-lens__button::before {
  content: '';
  position: absolute;
  right: 16px;
  width: 43px;
  height: 43px;
  background-color: #2e5aa8;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.base-ortho-k-lens__button--white {
  background-color: #fff;
}

.base-ortho-k-lens__button--down, .base-ortho-k-lens__button--right {
  position: relative;
}

.base-ortho-k-lens__button--down::after, .base-ortho-k-lens__button--right::after {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  right: 29.5px;
  width: 15px;
  height: 15px;
  background-image: url("https://static.lenskart.com/media/owndays/img/ortho-k-lens/chevron-down-solid-white.svg");
  background-size: cover;
}

.base-ortho-k-lens__button--right::after {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.base-ortho-k-lens__button:hover, .base-ortho-k-lens__button:active, .base-ortho-k-lens__button:visited, .base-ortho-k-lens__button:focus {
  text-decoration: none;
}

@media (min-width: 768px) {
  .base-ortho-k-lens__button {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .base-ortho-k-lens__button {
    padding: 0 16px 0 32px;
  }
  .base-ortho-k-lens__button:hover {
    color: #fff;
    background-color: #2e5aa8;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .base-ortho-k-lens__button:hover::before {
    background-color: #fff;
  }
  .base-ortho-k-lens__button:hover::after {
    background-image: url("https://static.lenskart.com/media/owndays/img/ortho-k-lens/chevron-down-solid-blue.svg");
  }
}

.base-ortho-k-lens__note {
  font-size: 11px;
  line-height: 1.8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.base-ortho-k-lens__note::before {
  content: "*";
  margin-right: 8px;
}
