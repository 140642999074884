.blog-news {
  &__categories {
    margin: 0 auto;
    padding: 81px 0 86px;
    text-align: center;

    @media (max-width: 767px) {
      width: 100%;
      padding: 42px 5%;
    }

    ul {
      font-size: 0;
      line-height: 0;
      letter-spacing: 0;
      margin: 0;
      padding: 0;
      list-style-type: none !important;

      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid #000;
        border-left: 1px solid #000;
      }

      li {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0 19px 0 0;
        padding: 0 6px;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 2px;
        text-indent: 3px;
        cursor: pointer;
        width: auto;
        height: 20px;
        background-image: linear-gradient(#000, #000);
        background-size: 0% 0.1em;
        background-position-y: 100%;
        background-position-x: 100%;
        background-repeat: no-repeat;
        transition: background-size 0.3s ease-in-out;
        list-style-type: none !important;

        &:hover,
        &:focus,
        &:active {
          background-size: 100% 0.1em;
          background-position-x: 0%;
          background-position-y: 19px;
          
          @media (min-width: 767px) {
            color:  #999;
          }
        }

        a {
          color: #000;
          text-decoration: none;
        }

        @media (max-width: 767px) {
          padding: 12px 0;
          width: 100%;
          height: 37px;
          margin: 0;
          letter-spacing: 1.5px;
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: none;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  &__container {
    width: 80%;
    max-width: 1152px;
    margin: 0 auto;

    @media (max-width: 767px) {
      width:100%;
      padding: 0 5%
    }
  }

  &__block {
    margin: 0 0 29px;

    @media (max-width: 767px) {
      margin: 0 0 60px;
    }
  }

  &__list {
    display: none;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 150px 0;
    list-style-type: none !important;

    > li {
      width: calc(100% / 3 - 20px);
      margin: 0 30px 55px 0;
      opacity: 0;
      transform: translate(0, 16px);
      font-family: 'Founders Grotesk', sans-serif;
      list-style-type: none !important;

      @media (min-width: 768px) {
        &:hover  {
          opacity: 1;
          
          a {
            transform: scale(1.02);
            box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1);
          }

          .blog-news__list-thumbnail img {
            opacity: 0.6;
          }
        }
      }

      &:nth-of-type(3n),
      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 30px;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);

        &:last-of-type {
          margin: 0;
        }
      }

      a {
        width: 100%;
        height: 100%;
        display: block;
        transition: .4s cubic-bezier(0.645, 0.045, 0.355, 1),
      }

      &-thumbnail {
        font-size: 0;
        line-height: 0;
        letter-spacing: 0;

        img {
          width: 100%;
        }
      }

      figure {
        margin: 0;

        figcaption {
          text-align: center;
          padding: 29px 5%;

          @media (max-width: 767px) {
            padding: 29px 5% 23px;
          }
        }
      }
    }

    &--open {
      display: flex;
      padding: 0;

      li {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 0.2s ease 0.2s, transform 0.2s ease;
      }

      @media (max-width: 767px) {
        display: block;
      }
    }

    &-category {
      margin: 0 0 16px;
      font-size: 14px;
      text-indent: 3px;
      color: #000;
      font-weight: 300 !important;
      letter-spacing: 3px;
    }

    &-title {
      font-size: 15px;
      margin: 0 0 13px;
      color: #000;
      line-height: 1.5;
      font-weight: normal !important;
      letter-spacing: 1px;
    }

    &-view-more {
      color: #0094c8;
      font-size: 15px;
      margin: 0;
      font-weight: normal !important;
      letter-spacing: 1px;
    }
  }
}

.new-active {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
  transition-delay: .5s !important;
}

.selected-new-category {
  background-color: #000;
  color: #fff;
  
  @media (min-width: 767px) {
    background-color: transparent;
    color: #000;
    border-bottom: 1px solid #000;
    background-image: none !important;
  }
}
