.od-membership-function-container {
  margin-top: 40px;
  padding: 0 200px;

  .od-membership-function {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 35px;

    &__item {
      width: 380px;
      min-height: 628px;
      background-color: #FAFAFA;
      margin-bottom: 5px;
      padding: 40px;
      color: #000000;
      text-align: center;

      &__title {
        margin-top: 41px;
        font-size: 22px;
        line-height: 26px;
      }

      &__description {
        margin-top: 10px;
        font-size: 16px;
        line-height: 19px;
        opacity: 0.7;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;

    .od-membership-function {
      gap: 20px;
    }

    .od-membership-function__item {
      width: 100%;
      padding: 20px 20px 30px 20px;
      min-height: auto;
    }

    .od-membership-function__item__title {
      margin-top: 20px;
      font-size: 22px;
      line-height: 26px;
    }
  }
}
