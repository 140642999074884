.od-care {
  margin-bottom: 120px;

  &-banner {
    width: 100%;
    margin: 0 auto;
  }

  &-pay-description {
    width: 100%;
    text-align: center;

    &__text {
      font-size: 48px;
      line-height: 56px;
      color: #000000;
      margin: 120px 0;
    }
  }
}
