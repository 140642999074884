@import "../../../theme/mobile/variables";
/* Toast Classes */
.message-toast {
  z-index: 999999999999;
  width: 100vw;
  bottom: -999px;
  transition: all 0.4s ease-in-out;
}
.toast-text {
  color: white;
  padding: 8px 15px;
  max-width: 95vw;
}
.white-space-pre {
  white-space: pre-wrap;
}
.background-dark_grey_blue {
  background: map-get($colorpallete, dark_grey_blue);
}
.background-green {
  background: map-get($colorpallete, color_green);
}
.background-orange {
  background: map-get($colorpallete, orange);
}
.background-red {
  background: map-get($colorpallete, color_red);
}
/* Only used on old payment page */
