.lang-popup-wrapper {
  max-width: 790px;

  .lang-popup {
    padding: 0 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 790px;
    margin: 0 auto;

    &__cross {
      top: 20px;
      right: 20px;
      margin: 0;
      opacity: 0.5;
    }

    &__title {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: 'Founders Grotesk', sans-serif;
      color: #000;
      padding: 40px 0 30px;
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: .04em;
    }
    &__columns{
      height: 100%;
      padding-bottom: 40px;
      overflow-y: auto;
    }

    .country-item {
      
      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 16px 20px 0;
        background-color: #fff;
        border-width: 1px 0;
        border-style: solid;
        border-color: #e3e3e3;
        cursor: pointer;
        width: 100%;
        margin-top: -1px;

        &__btn {
          background: none;
          border: none;
          cursor: pointer;
          font-family: 'Founders Grotesk', sans-serif;
          color: #000;
          font-size: 14px;
          line-height: 1.25;
          display: flex;
          align-items: center;
          gap: 13px;
          padding: 0;
  
          img {
            width: 25px;
            height: 16px;
            border: 1px solid #e3e3e3;
            aspect-ratio: 25/12;
            object-fit: cover;
          }
        }

        &__toggle-img {
          opacity: .25;
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
        &.active{
          .country-item__content__btn{
            color: #0094c8;
          }
        }
      }

      &__language-list {
        padding: 8px 0;
        background-color: #f8f8f8;
  
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
  
          li {
            padding: 8px 16px;
            cursor: pointer;
            font-size: 15px;
            line-height: 1.5;
            color: #666;
            &.active{
              color: #0094c8;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .lang{
      &-popup{
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        height: auto;
        border: 1px solid rgba(#000,.2);
        background-clip: padding-box;
        padding: 0 40px 40px;
        &__title{
          padding: 50px 0 35px;
        }
        &__columns{
          display: grid;
          grid-template-columns: repeat(2,1fr);
          gap: 0 30px;
          padding-bottom: 0;
        }
        &__column{
          margin-top: 1px;
        }
      }
    }
  }
}

@keyframes slideIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}

@keyframes slideOut {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
  }
}

.country-item__language-list.open {
  animation: slideIn 0.3s ease-in-out forwards;
}

.country-item__language-list.lang-close {
  animation: slideOut 0.3s ease-in-out forwards;
}