.lkpanel {
    .collapse {
        overflow: hidden;
        transition-duration: .35s;
        transition-property: all;
        transition-timing-function: cubic-bezier(0, 1, 0, 1);
        max-height: 0;
    }
    .collapse.in {
        transition-duration: 1s;
        transition-timing-function: ease-in;
        max-height: 10000px;
    }
}