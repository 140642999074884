$font-founders: 'Founders Grotesk', sans-serif;

$black-07: #070303;
$gray-66: #666666;

.base-result-submit {
  &__container {
    width: 100%;
    height: auto;
    padding: 80px 28px;
    margin: 0 auto;

    @media (min-width: 768px) {
      padding: 131px 28px;
    }
  }

  &__result {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
      }

      h1, p {
        font-family: $font-founders;
        font-style: normal;
        font-weight: 500 !important;
        margin: 0;
      }

      h1 {
        font-size: 22px;
        margin-bottom: 16px;
        color: $black-07;
      }

      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        color: $gray-66;
      }

      @media (min-width: 768px) {
        img {
          width: 112px;
          height: 112px;
        }

        h1 {
          font-size: 48px;
          line-height: 58px;
        }

        p {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }
}
