.base-senichisaku {
  background-color: black;
  color: white;
  font-family: yu-mincho-pr6n, sans-serif !important;
  font-weight: normal !important;
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 2.33;
  overflow-x: hidden;
}

.base-senichisaku .breadcrumb.breadcrumb--2 {
  border-radius: 0;
}

.base-senichisaku .breadcrumb.breadcrumb--2 > li + li::before {
  color: black;
}

@media (min-width: 1024px) {
  .base-senichisaku {
    font-size: 18px;
  }
}

.base-senichisaku h2, .base-senichisaku h5 {
  font-family: yu-mincho-pr6n, sans-serif !important;
}

.base-senichisaku > section {
  position: relative;
}

.base-senichisaku__container {
  width: 100%;
  max-width: calc(1160px + 10%);
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
}

.base-senichisaku__container--sm {
  max-width: calc(780px + 10%);
}

.base-senichisaku__container--lg {
  max-width: calc(1600px + 10%);
}

.base-senichisaku__hide--sp {
  display: none;
}

@media (min-width: 768px) {
  .base-senichisaku__hide--sp {
    display: block;
  }
}

.base-senichisaku__hide--pc {
  display: block;
}

@media (min-width: 768px) {
  .base-senichisaku__hide--pc {
    display: none;
  }
}

.base-senichisaku__btn {
  border: 1px solid white;
  width: 100%;
  height: 61px;
  color: white;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
}

.base-senichisaku__btn:hover, .base-senichisaku__btn:active, .base-senichisaku__btn:visited, .base-senichisaku__btn:focus {
  text-decoration: none;
  color: white;
}

.base-senichisaku__btn > span {
  display: inline-block;
  position: relative;
  padding-left: 32px;
}

.base-senichisaku__btn > span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 12px;
  height: 18px;
  background: url("https://static.lenskart.com/owndays/img/senichisaku/arrow-right.png") no-repeat center center/contain;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.base-senichisaku__btn-group {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-gap: 20px 70px;
}

@media (min-width: 768px) {
  .base-senichisaku__btn-group {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .base-senichisaku__btn {
    height: 68px;
  }
  .base-senichisaku__btn:hover {
    color: white;
  }
  .base-senichisaku__btn:hover > span::before {
    -webkit-transform: translateY(-50%) translateX(5px);
            transform: translateY(-50%) translateX(5px);
  }
}
