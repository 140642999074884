.od-membership-block {
  width: 100%;
  margin-top: 20px;

  &__table {
    margin-top: 40px;

    &-item {
      width: 100%;
      padding: 0 200px;
      display: flex;
      justify-content: space-between;

      &--ref-friend {
        width: 100%;
        padding: 0 200px;
        display: flex;
        justify-content: space-between;
        gap: 19px;

        @media screen and (max-width: 768px) {
          padding: 0 20px;
          flex-direction: column;
        }
      }

      &--care {
        width: 100%;
        padding: 0 200px;
        display: flex;
        justify-content: space-between;
        gap: 19px;
      }

      &__content {
        &--ref-friend {
          min-height: 236px;
          width: 50%;
          padding: 40px;
          background-color: #fafafa;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        &--care {
          min-height: 253px;
          width: 50%;
          padding: 40px;
          background-color: #fafafa;
        }

        &--points {
          min-height: 309px;
          width: 50%;
          padding: 40px;
          background-color: #fafafa;
        }

        &__title {
          color: #000000;
          font-size: 20px;
          line-height: 23px;
          opacity: 0.5;
        }

        &__rebate {
          margin-top: 40px;
          color: #000000;

          .text-bold {
            font-size: 48px;
            line-height: 56px;
          }

          .text-reg {
            font-size: 24px;
            line-height: 28px;
          }

          .care-text-bold {
            font-size: 24px;
            line-height: 54px;

            &--secondary {
              font-size: 48px;
              line-height: 56px;
            }
          }

          .care-text-reg {
            font-size: 24px;
            line-height: 28px;
          }
        }

        &__description {
          font-size: 20px;
          line-height: 23px;
          color: #000000;
          opacity: 0.5;
          margin-top: 15px;
        }

        &__rebate-description {
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          opacity: 0.5;
          margin-top: 40px;
        }
      }

      &__img {
        width: 50%;
        min-height: 309px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 0 20px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__table {
      &-item {
        width: 100%;
        flex-direction: column;
        gap: 20px;
        align-items: stretch;
        padding: 0 20px;
  
        &__content--points {
          width: 100%;
        }
  
        &__img {
          width: 100%;
          min-height: 100%;
        }

        &:nth-child(2n) {
          flex-direction: column-reverse;
        }

        &__content__rebate {

          .text-bold {
            font-size: 32px;
          }
        }
      }
  
      &--ref-friend,
      &--care,
      &--points {
        width: 100%;
        padding: 0 20px;
      }
  
      &__description-list,
      &__terms-conditions {
        padding: 0 20px;
      }
    }
  }  

  &__description-list {
    padding: 0 200px;
    margin-top: 40px;

    li {
      list-style: inside;
      font-size: 16px;
      line-height: 40px;
      color: #000000;
      opacity: 0.7;
    }

    @media screen and (max-width: 768px) {
      padding: 0 20px;

      li {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__terms-conditions {
    color: #000000;
    padding: 0 200px;
    margin-top: 40px;
    opacity: 0.7;

    &__title {
      font-size: 16px;
      line-height: 40px;
    }

    &__description {
      font-size: 12px;
      line-height: 30px;
    }
  }
}
