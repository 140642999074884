.hto-hero {
  padding: 120px 170px 60px;
}

.hto-hero__layout {
  max-width: 1040px;
  margin: 0 auto;
  text-align: center;
}

.hto-hero__image {
  margin-bottom: 80px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.hto-hero__title {
  margin: 0 0 40px;
  text-align: center;
  font-size: 48px;
  line-height: 60px;
  color: #000000;
}

.hto-hero__description {
  margin-bottom: 80px;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  color: rgba(0, 0, 0, .7);
}

.hto-hero__button {
  margin: 0 auto;
}

.hto-benefits {
  padding: 60px 170px 60px;
  background-color: #fafafa;
}

.hto-benefits__layout {
  max-width: 1270px;
  margin: 0 auto;
}

.hto-benefits__title {
  margin: 0 0 50px;
  text-align: center;
  font-size: 32px;
  line-height: 60px;
  color: #000000;
}

.hto-benefits__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 40px;
}

.hto-benefits__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 30%;
          flex: 1 0 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.hto-benefits__item-image {
  max-width: 212px;
  max-height: 158px;
  margin-bottom: 30px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.hto-benefits__item-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, .7);
  white-space: pre-line;
}

.hto-steps {
  padding: 60px 170px 120px;
}

.hto-steps__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.hto-steps__header-line {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.hto-steps__layout {
  max-width: 1270px;
  margin: 0 auto;
}

.hto-steps__title {
  padding: 0 67px;
  margin: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.hto-steps__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 60px;
}

.hto-steps__item {
  max-width: 300px;
  padding: 30px;
  background-color: #fafafa;
}

.hto-steps__item-image {
  max-width: 225px;
  max-height: 448px;
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hto-steps__item-title {
  margin: 0 0 8px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.hto-steps__item-description {
  margin-bottom: 0;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, .7);
}

.hto-steps__hint {
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 30px;
  text-align: justify;
  color: rgba(0, 0, 0, .7);
}

.hto-steps__button {
  margin: 0 auto;
}

@media (max-width: 1050px) {
  .hto .hto-hero {
    padding: 60px 20px 20px 20px;
  }
  .hto .hto-hero__layout .owndays-btn {
    padding: 16px !important;
  }
  .hto .hto-benefits {
    padding: 60px 20px 20px 20px;
  }
  .hto .hto-benefits__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
  }
  .hto .hto-steps {
    padding: 60px 20px 20px 20px;
  }
  .hto .hto-steps__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
  }
  .hto .hto-steps .owndays-btn {
    padding: 16px !important;
  }
}
