.od-membership-status {
  width: 380px;
  padding: 40px;
  min-height: 461px;
}

.od-membership-status__title {
  width: 100%;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.od-membership-status__points {
  margin: 23px 0 76px 0;
  font-size: 24px;
  line-height: 40px;
  width: 100%;
  text-align: center;
}

.od-membership-status__btns {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 11px;
}

@media screen and (max-width: 768px) {
  .od-membership-status {
    width: auto;
    padding: 20px;
    min-height: auto;
  }
  .od-membership-status__title {
    font-size: 24px;
    line-height: 32px;
  }
  .od-membership-status__points {
    margin: 15px 0 40px 0;
    font-size: 18px;
    line-height: 32px;
  }
}

.block-color-bronze {
  background-color: #FFF5EA;
}

.block-color-silver {
  background-color: #FBFBFB;
}

.block-color-gold {
  background-color: #FFFAE1;
}
