.search-bar-static {
  position: relative;
}

.search-bar-static__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 30px;
  border-bottom: 1px solid #555;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}

.search-bar-static__input {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 0;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  color: #000;
}

.search-bar-static__input::-webkit-input-placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  color: rgba(0, 0, 0, .3);
}

.search-bar-static__input::-moz-placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  color: rgba(0, 0, 0, .3);
}

.search-bar-static__input::-ms-input-placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  color: rgba(0, 0, 0, .3);
}

.search-bar-static__input::placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  color: rgba(0, 0, 0, .3);
}

.search-bar-static__icon {
  width: 14px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
  will-change: opacity;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.search-bar-static__icon:hover {
  opacity: 0.5;
}

.search-bar-static__icon--close {
  width: 10px;
}
