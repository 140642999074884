.base-result-submit__container {
  width: 100%;
  height: auto;
  padding: 80px 28px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .base-result-submit__container {
    padding: 131px 28px;
  }
}

.base-result-submit__result__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.base-result-submit__result__container img {
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
}

.base-result-submit__result__container h1, .base-result-submit__result__container p {
  font-family: "Founders Grotesk", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  margin: 0;
}

.base-result-submit__result__container h1 {
  font-size: 22px;
  margin-bottom: 16px;
  color: #070303;
}

.base-result-submit__result__container p {
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  color: #666666;
}

@media (min-width: 768px) {
  .base-result-submit__result__container img {
    width: 112px;
    height: 112px;
  }
  .base-result-submit__result__container h1 {
    font-size: 48px;
    line-height: 58px;
  }
  .base-result-submit__result__container p {
    font-size: 24px;
    line-height: 36px;
  }
}
