$brown-81: #815624;
$cream-f1: #f1e2d2;

.footer-anniversary-10th-collection {
  &__case-cloth {
    color: $brown-81;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.42px;
    background-color: $cream-f1;
    padding-top: 68px;
    margin: 80px 0 60px;
    overflow: hidden;

    .base-anniversary-10th-collection__container > * {
      position: relative;
      z-index: 1;
    }

    .base-anniversary-10th-collection__frame {
      margin-top: 0;
    }

    .base-anniversary-10th-collection__heading {
      font-size: 25px;
      line-height: 32px;
      letter-spacing: 0.75px;
      margin-bottom: 27px;
      text-align: left;

      + p {
        font-size: 15px;
        line-height: 2;
        letter-spacing: 1px;
        margin-bottom: 0;
      }
    }

    &__img{
      margin-top: -24vw;
      width: auto;
      position: relative;
      z-index: 0 !important;
    }

    @media (min-width: 768px) {
      padding-top: 0;
      margin-bottom: 113px;

      .base-anniversary-10th-collection__container {
        display: grid;
        grid-template-columns: 65% auto;
        grid-template-areas:
          "img before"
          "img heading"
          "img des"
          "img after";
        align-items: center;
      }

      .base-anniversary-10th-collection__heading {
        grid-area: heading;
      }

      p {
        grid-area: des;
      }

      &__img {
        margin-top: 0;
        margin-left: -15vw;
        margin-right: -90px;
        grid-area: img;
      }
    }
    @media (min-width: 500px) and (max-width: 767px){
      .base-anniversary-10th-collection__heading {
        br {
          display: none;
        }
      }
    }

    @media (min-width: 1024px) {
      margin-bottom: 106px;

      .base-anniversary-10th-collection__container {
        grid-template-columns: 1fr 1fr;
      }
      .base-anniversary-10th-collection__heading {
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 1.2px;
        margin-bottom: 44px;
        padding-left: 92px;
        
        + p {
          padding-left: 92px;
          margin-right: -15px;
          margin-bottom: 0;
        }
      }

      &__img {
        margin-right: -110px;
      }
    }

    @media (min-width: 1296px) {
      &__img {
        margin-left: -208px;
        margin-right: -190px;
      }
    }
  }

  &__terms, &__shops {
    margin-top: 60px;

    .base-anniversary-10th-collection__heading {
      color: black;
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0.72px;
      margin-bottom: 27px;
    }

    ul {
      list-style-type: disc;
      margin-right: calc((40px/2) * -1);
      padding-left: 0;
      margin-bottom: 0;

      > li {
        list-style-type: disc;
        color: black;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.39px;
      }
    }
    
    .base-anniversary-10th-collection__shops {
      display: flex;
      flex-wrap: wrap;

      > li {
        line-height: 2.5;
        width: 100%;

        @media (min-width: 768px) {
          width: 33.333%;
        }
      }
    }

    @media (min-width: 768px) {
      margin-top: 130px;

      .base-anniversary-10th-collection__heading {
        font-size: 40px;
        letter-spacing: 1.2px;
        margin-bottom: 48px;
      }

      ul {  
        > li {
          font-size: 14px;
          line-height: 32px;
          letter-spacing: 0.42px;
        }
      }
    }
  }
}
