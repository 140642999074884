.od-primer-card__two-fields-wrapper {
  display: grid;
  margin-top: 24px;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.od-primer-card__fields-wrapper {
  margin-top: 24px;
}

.od-primer-card .axtra-error-field {
  display: none;
}

.od-primer-card-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 64px;
  padding: 20px 24px;
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  background-color: #FAFAFA;
  overflow: hidden;
}

.od-primer-card-field__label {
  margin-bottom: 0;
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  white-space: nowrap;
}

.od-primer-card-field__input {
  height: 24px;
  padding: 0;
  border: none;
  color: #444;
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  outline: none;
}

.od-primer-card-field__input::-webkit-input-placeholder {
  opacity: 0.2;
}

.od-primer-card-field__input::-moz-placeholder {
  opacity: 0.2;
}

.od-primer-card-field__input::-ms-input-placeholder {
  opacity: 0.2;
}

.od-primer-card-field__input::placeholder {
  opacity: 0.2;
}
