.discount-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  padding: 13px 0;
  width: 100%;
  top: 0;

  @media (max-width: 767px) {
    padding: 13px 30px;
  }

  &__title {
    margin: 0;
    color: #fff;
    font-size: 20px;

    @media (max-width: 767px) {
      font-size: 14px;
      text-align: center;
    }
  }
}
