.body-eye-camp__message {
  width: 100%;
  height: auto;
  max-width: 1152px;
  margin: 0 auto;
  padding: 0 5%;
}

.body-eye-camp__message--logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 7.5px;
  padding: 20px 15px 30px;
  border: 1px solid black;
  text-align: center;
}

.body-eye-camp__message--logo img {
  width: 48px;
  height: 48px;
  margin: 0 auto;
}

.body-eye-camp__message--logo h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 400 !important;
  margin: 10px 0 0 0;
  line-height: 1.6;
  letter-spacing: 1px;
  color: black;
}

.body-eye-camp__message--logo h2 span {
  display: block;
  font-size: 13px;
  font-weight: 700 !important;
  margin-top: 10px;
}

.body-eye-camp__message--text {
  margin: 25px 7.5px 0;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.body-eye-camp__message--text h3, .body-eye-camp__message--text p {
  font-family: "Founders Grotesk", sans-serif;
  color: black;
}

.body-eye-camp__message--text h3 {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 1.8;
  margin: 0;
}

.body-eye-camp__message--text p {
  margin: 20px 0 0 0;
  font-size: 15px;
  font-weight: normal !important;
  letter-spacing: 1px;
  line-height: 2;
}

.body-eye-camp__message--image {
  width: 100%;
  max-width: 406px;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  height: auto;
  padding-right: 7.5px;
  padding-left: 7.5px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.body-eye-camp__message--image img {
  width: 100%;
  height: auto;
  display: block;
}

@media (min-width: 768px) {
  .body-eye-camp__message {
    padding-left: 0;
    padding-right: 0;
  }
  .body-eye-camp__message--logo {
    width: 100%;
    max-width: 406px;
    height: 406px;
    margin: 0;
  }
  .body-eye-camp__message--logo img {
    width: 96px;
    height: 96px;
    margin: 0 auto;
  }
  .body-eye-camp__message--logo h2 {
    font-size: 24px;
    margin-top: 30px;
  }
  .body-eye-camp__message--text {
    margin: 0 0 0 60px;
  }
  .body-eye-camp__message--image {
    margin-left: 60px;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}

.body-eye-camp__eye-camp {
  position: relative;
}

.body-eye-camp__eye-camp__title {
  background-color: black;
  padding: 32px 23px;
  width: 162px;
  max-width: 762px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  text-align: center;
  color: white;
  margin: 0 auto;
}

.body-eye-camp__eye-camp__title h3 {
  width: 116px;
  height: auto;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 1;
  letter-spacing: 1px;
  margin: 0;
}

.body-eye-camp__eye-camp__title h3 span {
  display: block;
  font-size: 13px;
  font-weight: 400 !important;
  line-height: 1.6;
  margin-top: 6px;
}

@media (min-width: 768px) {
  .body-eye-camp__eye-camp__message {
    margin-left: 0 !important;
  }
}
