.body-freaks-store__lineup {
  padding-bottom: 80px;
  background-color: #F7F7F7;
}

@media (min-width: 1024px) {
  .body-freaks-store__lineup {
    padding-bottom: 120px;
  }
  .body-freaks-store__lineup__frame-section {
    margin-top: 80px;
  }
}
