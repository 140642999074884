$font-founders: 'Founders Grotesk', sans-serif;

$gray-e3: #e3e3e3;

.footer-contact-us {
  padding: 20px 0 40px 0;
  border-bottom: 1px solid $gray-e3;
  margin-bottom: 60px;

  &__container {
    width: 100%;
    height: auto;
    padding: 0 5%;
    max-width: 764px;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 80%;
      padding: 0;
    }
  }

  h2 {
    font-family: $font-founders;
    font-size: 18px;
    font-weight: 400 !important;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: black;
    margin: 0;

    span {
      width: 60px;
      height: auto;
      display: block;
      margin: 0 auto;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 40px 0 0 0;
    max-width: 510px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;

    li {
      display: grid;
      grid-template-columns: 130px 1fr;
      grid-template-rows: 30px;
      align-items: baseline;

      &:nth-child(2) {
        grid-template-rows: 60px;
      }
      
      h4, p {
        font-family: $font-founders;
        font-weight: normal !important;
        font-size: 15px;
        line-height: 2;
        letter-spacing: 1px;
        color: black;
        margin: 0;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 40px 0 70px 0;

    h2 {
      span {
        width: 80px;
      }
    }

    ul {
      margin: 40px auto 0;
      max-width: 510px;

      li {
        &:nth-child(2) {
          grid-template-rows: 30px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: 120px;
  }
}
