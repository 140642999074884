.popup {
  font-family: "Founders Grotesk", sans-serif;
}

.popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .popup__overlay {
    padding: 0 20px;
  }
}

.popup__content {
  background: #ffffff;
  width: 100%;
  max-width: 570px;
  padding: 60px 60px 20px 60px;
  position: relative;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .popup__content {
    padding: 60px 20px 20px 20px;
  }
}

.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.popup__close::before, .popup__close::after {
  content: "";
  position: absolute;
  width: 30px;
  border: 1px solid black;
}

.popup__close::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.popup__close::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.popup__message-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.popup__message {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 1;
  margin: 0;
  color: black;
}

@media screen and (max-width: 768px) {
  .popup__message {
    font-size: 14px;
  }
}

.popup__button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 40px;
}

.popup__button {
  background: white;
  color: black;
  padding: 10px 0;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid black;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  line-height: 2;
  letter-spacing: 2;
  width: 100%;
  max-width: 300px;
}

.popup__button:hover {
  background: black;
  color: white;
}
