.od-banner {
  width: 100%;
  position: relative;
}

.od-banner__content {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  color: #ffffff;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.od-banner__content__img {
  margin: 0 auto 100px auto;
}

.od-banner__content__title {
  width: 100%;
  color: #FFFFFF;
  line-height: 84px;
  font-size: 72px;
  padding: 0 197px;
}

@media screen and (max-width: 768px) {
  .od-banner__content__title {
    font-size: 36px;
    line-height: 48px;
    padding: 0 20px;
  }
  .od-banner__content__img {
    margin-bottom: 50px;
  }
}
