$font-founders: 'Founders Grotesk', sans-serif;

$color-white: #fff;
$color-black: #000;
$color-gray: #666;
$color-dark-gray: #999;
$color-red: #c7002e;

$background-color-light: #f4f4f4;
$background-color-white: #fff;
$background-color-gray: #666;
$background-color-black: #000;

$border-color-gray: #666;
$border-color-gray-2: #e3e3e3;

.service_l {
  margin: 0 auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;

  @media (max-width: 1023px) {
    margin: 0 auto 60px;
  }

  &__section {
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__container {
    width: 90%;
    max-width: 1296px;
    height: auto;
    margin: 0 auto;

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 5%;
    }
  }

  &__container-retail {
    width: 100%;
    height: auto;
    margin: 0 auto;

    @media (max-width: 767px) {
      width: 100%;
      padding: 0;
    }
  }

  &__container-warranty {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-width: 1296px;

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 5%;
    }
  }
}
