$font-founders: 'Founders Grotesk', sans-serif;

$black: #000;
$black-66: #666;
$gray-e3: #e3e3e3;
$white: #fff;
$link: #0094c8;

$base-img-url: 'https://static.lenskart.com/media/owndays/img/lens-replacement';

.body-lens-replacement {
  letter-spacing: 1px;
  &__price {
    h2 {
      margin-top: 50px;
      font-weight: 400;
      letter-spacing: 1px;
    }

    &__images {
      width: fit-content;
      max-width: 550px;
      display: block;
      margin: 25px auto 0;

      &__option {
        width: 18%;
        height: auto;
        vertical-align: middle;
        position: relative;

        &--hide-plus {
          &::before, &::after {
            display: none;
          }
        }

        img {
          display: block;
          width: 50px;
          height: auto;
          margin: 0 auto;
        }

        p {
          text-align: center;
          font-family: $font-founders;
          font-size: 11px;
          margin-bottom: 0;
          letter-spacing: 1px;
          color: $black;
        }

        &::before, &::after {
          content: "";
          position: absolute;
          background: $black;
        }

        &::before {
          top: 30px;
          right: 0;
          width: 1px;
          height: 11px;
        }

        &::after {
          top: 50%;
          right: -5px;
          width: 11px;
          margin-top: -5px;
          height: 1px;
        }
      }

      @media (min-width: 768px) {
        margin: 40px auto;
        max-width: 620px;

        &__option {
          width: 22%;

          &::before {
            top: 26px;
            height: 21px;
          }

          &::after {
            right: -10px;
            width: 21px;
          }
        }
      }
    }

    &__price-value {
      margin: 14px auto 25px auto;
      width: 30%;
      height: auto;
      padding: 0 22px;

      &--hide-sp {
        display: none;

        @media (min-width: 768px) {
          display: table-cell;
        }
      }

      &--hide-pc {
        display: block;

        @media (min-width: 768px) {
          display: none;
        }
      }

      p {
        display: inline;
        position: relative;
        margin-bottom: 0;
        text-align: center;
        font-family: $font-founders;
        font-size: 28px;
        line-height: 1;
        color: $black;

        &::before, &::after {
          content: "";
          position: absolute;
          background: $black;
        }

        &::before {
          top: 20px;
          left: -30px;
          width: 15px;
          height: 1px;
        }

        &::after {
          top: 12px;
          left: -30px;
          width: 15px;
          height: 1px;
        }
      }

      @media (min-width: 768px) {
        margin: 14px 0 25px;
      }
    }

    &__detail {
      font-family: $font-founders;
      font-size: 15px;
      font-weight: normal !important;
      line-height: 2;
      letter-spacing: 1px;
      margin-bottom: 0;
      color: $black;
    }
  }

  &__lens {
    list-style-type: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 0;

    &__option {
      border-bottom: 1px dashed $gray-e3;
      margin: 20px 0;
      padding: 0 15px 20px;
      position: relative;
      color: $black;

      &:not(:first-child) {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 15px;
        width: 30px;
        height: 33.3px;
        margin: 0;
        background: url("#{$base-img-url}/detail_1-lens.webp") center center / cover no-repeat;
      }

      &.progressive {
        &::before {
          background: url('#{$base-img-url}/detail_2-lens.webp') center center / cover no-repeat;
        }
      }

      &.polarised {
        &::before {
          background: url('#{$base-img-url}/detail_3-lens.webp') center center / cover no-repeat;
        }
      }

      &.transitions {
        &::before {
          background: url('#{$base-img-url}/detail_4-lens.webp') center center / cover no-repeat;
        }
      }

      &.pc,
      &.pc420 {
        &::before {
          background: url('#{$base-img-url}/detail_5-lens.webp') center center / cover no-repeat;
        }
      }

      &.color {
        &::before {
          background: url('#{$base-img-url}/detail_6-lens.webp') center center / cover no-repeat;
        }
      }

      &.antifog {
        &::before {
          background: url('#{$base-img-url}/detail_7-lens.webp') center center / cover no-repeat;
        }
      }
    }

    &__name {
      font-family: $font-founders;
      padding-left: 50px;
      
      h3 {
        font-size: 14px;
        font-weight: 300 !important;
        line-height: 1.6;
        margin: 0;
      }
    }

    &__tag-layout {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0 10px;
    }

    &__tag {
      &--prescription, &--with-out-prescription, &--price {
        font-family: $font-founders;
        margin: 5px 0 0 0;
      }

      &--prescription, &--with-out-prescription {
        padding-right: 6px;

        span {
          height: 18px;
          display: block;
          font-size: 10px;
          font-weight: normal !important;
          letter-spacing: normal;
          line-height: 19px;
          text-align: center;
          padding-inline: 6px;
        }
      }

      &--prescription {
        span {
          color: $white;
          background: $black-66;
        }
      }

      &--with-out-prescription {
        span {
          background: $gray-e3;
        }
      }

      &--price {
        font-size: 14px;
        font-weight: 600 !important;
        padding-right: 10px;
        line-height: 20px;
      }
    }

    &__table {
      font-family: $font-founders;
      font-size: 12px;
      margin-left: 48px;
      width: calc(100% - 50px);
      height: auto;
      margin-bottom: 0;
      tr{
        th,td{
          vertical-align: top;
        }
      }

      tbody > tr > th {
        width: 140px;
        height: auto;
        font-weight: 300 !important;
        position: relative;
      }

      td > p {
        margin-bottom: 0;
        line-height: 2;
      }

      &__tooltip {

        img {
          width: 18px;
          height: auto;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;

        li {
          float: left;
          padding-left: 0;

          p {
            margin-bottom: 0;
            line-height: 2;
          }
        }
      }
    }

    &__detail {
      padding-top: 10px;
    }

    &__btn-layout {
      width: 223px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $black-66;
      margin: 25px auto 0 auto;

      a {
        font-family: $font-founders;
        font-size: 14px;
        font-weight: 500 !important;
        color: $black-66;
        text-decoration: none !important;
      }
    }

    &__tooltip-wrapper {
      position: relative;
      display: inline-block;
      margin: 0 0px 4px 10px;
      
      @media (min-width: 768px) {
        margin: 0 0px 4px 8px;
      }

      .body-lens-replacement__lens__table__tooltip {
        position: relative;
        cursor: pointer;

        &::after {
          content: attr(data-title);
          position: absolute;
          top: 100%; /* Position below the icon */
          left: 50%;
          transform: translateX(-50%);
          visibility: hidden;
          opacity: 0;
          width: 200px;
          background-color: rgba(0, 0, 0, 0.75);
          color: #fff;
          text-align: center;
          padding: 8px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 1.4;
          white-space: pre-wrap; /* Allows for line breaks */
          transition: opacity 0.3s ease, visibility 0.3s ease;
          z-index: 1000;
          margin-top: 8px; /* Adds spacing between the icon and tooltip */
        }

        &::before {
          /* Arrow styling */
          content: '';
          position: absolute;
          bottom: -8px; /* Position arrow above the tooltip box */
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          z-index: 999;
        }

        /* Show the tooltip on hover */
        &:hover::after,
        &:hover::before {
          visibility: visible;
          opacity: 1;
        }
      }
    }    

    @media (min-width: 768px) {
      &__option {
        padding: 0 24px 20px 80px;

        &::before {
          width: 36px;
          height: 40px;
          left: 25px;
          top: calc(50% - 10px);
          transform: translateY(-50%);
        }
      }

      &__name {
        display: flex;
        align-items: center;
        padding-left: 0;

        h3 {
          color: $black;
          font-size: 16px;
        }
      }

      &__tag-layout {
        margin: 10px 0 10px 20px;
      }

      &__tag {
        &--prescription, &--with-out-prescription, &--price {
          margin: 5px 0 0 0;
        }
  
        &--prescription, &--with-out-prescription {
          padding-right: 10px;
  
          span {
            font-size: 11px;
          }
        }
  
        &--price {
          font-size: 18px;
        }
      }

      &__table {
        font-size: 15px;
        margin-left: -3px;
        width: 100%;
  
        tbody > tr > th {
          width: 160px;
        }
  
        &__tooltip {
          right: 0;
        }
  
        ul {
          li {
            padding-right: 20px;
          }
        }
      }

      &__btn-layout {  
        width: 298px;
        transition: 0.5s;

        &:hover {
          cursor: pointer;
          background-color: $black-66;

          a {
            color: $white;
          }
        }
      }
    }
  }

  &__steps {
    &__list {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    &__step {
      font-family: $font-founders;
      padding: 0 15px 20px;
      border-bottom: 1px dashed $gray-e3;
      margin: 20px 0;

      &:last-child {
        margin-bottom: 0;
        border: 0;
      }

      h3 {
        position: relative;
        margin-top: 0;
        margin-bottom: 15px;
        color: $black;
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 1.6;
        letter-spacing: 1px;

        &::before {
          content: "";
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: contain;
          position: absolute;
          left: 0;
          top: 0;
        }

        span {
          display: block;
          font-size: 14px;
          font-weight: 600 !important;
          color: $black;
          line-height: 30px;
          padding-left: 40px;
          margin-bottom: 5px;
        }
      }

      p {
        color: $black;
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
        letter-spacing: 1px;
        margin-bottom: 0;

        a {
          color: $link;
          text-decoration: none !important;
        }
      }

      &--1 {
        h3::before {
          background-image: url("#{$base-img-url}/store.svg")
        }
      }

      &--2 {
        h3::before {
          background-image: url("#{$base-img-url}/lens.svg")
        }
      }

      &--3 {
        h3::before {
          background-image: url("#{$base-img-url}/cleaning.svg")
        }
      }
    }
  }
}
