$base-img-url: 'https://static.lenskart.com/media/owndays/img/kimetsu';

$font-se-jp: "Noto Serif JP", serif;
$font-sa-noto: "Noto Sans JP", sans-serif;

$black-22: #22140b;
$black-43: #434343;
$gold-86: #86846A;
$gray-99: #999;

.header-kimetsu {
  &__main {
    background-color: $black-22;
    position: relative;
    padding-bottom: 24px;

    &--ban-dai {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 47px;
      height: 47px;
      object-fit: contain;
    }

    &--kimetsu {
      display: block;
      width: 235px;
      height: 222px;
      margin: 0px auto 50px;

      @media (max-width: 767px) {
        width: 123px;
        height: 101px;
        margin: 0;
      }
    }

    &--owndays {
      position: absolute;
      width: 174px;
      height: 174px;
      background: url('#{$base-img-url}/bg-owndays.svg') no-repeat center center/contain;
      color: white;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h5,
      h6 {
        font-family: $font-sa-noto;
      }

      h5 {
        font-size: 11px;
        font-weight: 700 !important;
        margin-bottom: 5px;
      }

      h6 {
        font-size: 9px;
        font-weight: 500 !important;
        margin: 0;

        small {
          font-size: 8px;
          font-weight: 500;
        }
      }

      img {
        height: 25px;
        width: auto;
      }
    }

    &__heading {
      color: white;
      text-align: center;
      position: relative;

      &-bg {
        display: block;
        position: absolute;
        top: -42px;
        left: 135px;
        width: 346px;
        height: auto;
        max-width: none;

        @media (min-width: 768px) {
          top: -60px;
          left: 270px;
          width: 700px;
        }
      }

      h1 {
        position: relative;
        font-family: $font-se-jp;
        font-weight: 900 !important;
        font-size: 16px;
        line-height: 1.25;
        margin: -11px 0 0 0;
        letter-spacing: 0.05em;
        line-height: 1.4;

        span {
          display: block;
          font-weight: 700 !important;
          font-size: 11px;
          margin-bottom: 4px;
        }
      }
    }

    @media (min-width: 1024px) {
      &-inner {
        max-width: 500px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        text-align: center;

        .header-kimetsu__main--kimetsu {
          width: 235px;
          height: 222px;
          margin: 0px auto 50px;
        }
      }

      &__heading {
        &-bg {
          top: -10%;
          left: 5%;
          width: 493px;

          h1 {
            font-size: 56px;

            span {
              font-size: 20px;
            }
          }
        }
      }
    }

    &--img {
      display: block;
      max-width: 670px;
      margin-left: auto;
      margin-right: -36px;

      @media (max-width: 1023px) {
        max-width: 97%;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }

    @media (min-width: 1024px) {
      &--ban-dai {
        width: 90px;
        height: 90px;
        top: 42px;
        right: calc(-58px + 25px);
      }
    }

    @media (min-width: 768px) {
      &--owndays {
        bottom: -43px;
        right: 0;
        width: 297px;
        height: 297px;

        h5 {
          font-size: 18px;
          line-height: 25px;
          margin: 0 0 8px 0;
        }

        h6 {
          font-size: 15px;

          small {
            font-size: 14px;
          }
        }

        img {
          width: 109px;
          height: 43px;
        }
      }

      &__heading {
        margin-bottom: 40px;

        h1 {
          font-size: 36px;

          @media (max-width: 1023px) {
            margin-right: 10%;
          }

          @media (max-width: 767px) {
            margin-right: 0;
          }

          span {
            font-size: 16px;
            letter-spacing: 0.1em;
            margin-bottom: 8px;
          }
        }
      }
    }

    @media (max-width: 1023px) {
      padding-bottom: 24px;

      >.base-kimetsu__container {
        padding-left: 0;
        padding-right: 0;
      }

      &--owndays {
        top: 60%;
        left: 3%;
      }

      &--kimetsu {
        position: absolute;
        top: 3%;
        left: 3%;
      }

      &__heading {
        display: flex;
        justify-content: flex-end;
        margin: -40px 16px 12px 0;
      }
    }

    @media (max-width: 767px) {
      &--owndays {
        left: -3%;
      }

      &__heading {
        margin: 15px 16px 12px 0;
      }
    }
  }

  &__sub {
    background: url('#{$base-img-url}/sub-bg.png') no-repeat center bottom/cover;
    color: white;
    padding: 54px 0 80px;

    &-title {
      text-align: center;
      display: flex;
      align-items: center;
      margin-bottom: 38px;

      &-line {
        display: block;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        height: 16px;
        width: 100%;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          background-color: white;
          transform: translateY(-50%);
        }

        &--dark {
          border-top: 1px solid black;
          border-bottom: 1px solid black;

          &::before {
            background-color: black;
          }
        }
      }

      &-text {
        font-family: $font-se-jp;
        font-size: 21px;
        font-weight: 700 !important;
        white-space: nowrap;
        padding: 0 23px;
      }

      @media (min-width: 768px) {
        &-line {
          height: 20px;
        }

        &-text {
          font-size: 38px;
          line-height: 55px;
        }
      }
    }

    &-text {
      font-weight: 400 !important;
      font-size: 13px;
      letter-spacing: .52px;
      line-height: 2;
      margin-bottom: 35px;

      @media (min-width: 768px) {
        font-size: 16px;
        margin-bottom: 40px;
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        letter-spacing: .26px;
      }
    }

    &__nav {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -7px;
      padding: 0;

      &-item {
        list-style-type: none;
        width: calc(100% / 3);
        padding: 0 7px;
      }

      &-link {
        background-color: $black-43;
        border: 1.5px solid $gold-86;
        position: relative;
        display: inline-block;
        margin-bottom: 58px;
        transition: .5s;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          top: calc(100% + 13px);
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 19px;
          background: url('#{$base-img-url}/arrow-down.svg') no-repeat center center/contain;
        }

        &:hover {
          color: $gray-99;
          text-decoration: none;

          img {
            opacity: .6;
            transition: .5s;
          }
        }
      }

      &-img {
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 1px;
          border-bottom: 13px solid transparent;
          z-index: 1;
        }

        &::before {
          left: 1px;
          border-left: 13px solid $gold-86;
        }

        &::after {
          right: 1px;
          border-right: 13px solid $gold-86;
        }
      }

      &-title {
        color: white;
        font-family: $font-se-jp;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        padding: 4px 0 5px;
        margin: 0;
        letter-spacing: .01em;
      }

      @media (min-width: 768px) {
        &-title {
          font-size: 15px;
          line-height: 2;
        }
      }
    }

    @media (min-width: 768px) {
      text-align: center;
      padding-top: 100px;
      padding-bottom: 150px;

      &-title {
        &-text {
          padding-left: 62px;
          padding-right: 62px;
          letter-spacing: .2em;
        }
      }

      &__nav {
        &-item {
          width: calc(100% / 4);
        }

        &-link {
          border-width: 2.5px;
          margin-bottom: 65px;

          &::before {
            top: calc(100% + 14px);
            width: 50px;
            height: 24px;
          }
        }
      }
    }
  }
}
