
* {
  font-display: swap;
}

@import "../variables";
@import "../fonts";
@import "../vendor/font-awesome.min";
@import '../desktop/desktop-variables.scss';
@import '../desktop/reusables.scss';
@import '../buttons.scss';

html {
  height: 100%;
}
.btn-focus{
  outline: none !important;
  box-shadow: none;
}

body {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 13px;
  line-height: 1.42857;
  color: #333;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.seller-label {
  letter-spacing: 0.8px;
}
.seller-label {
  letter-spacing: 0.8px;
}
.default-filters {
  max-width: 255px;
}
@for $i from -5 through 50 {
  .padding-#{$i} {
    padding: #{$i}px;
  }

  .padding-l#{$i} {
    padding-left: #{$i}px;
  }

  .padding-t#{$i} {
    padding-top: #{$i}px;
  }

  .padding-r#{$i} {
    padding-right: #{$i}px;
  }

  .padding-b#{$i} {
    padding-bottom: #{$i}px;
  }

  .margin-#{$i} {
    margin: #{$i}px;
  }

  .margin-r#{$i} {
    margin-right: #{$i}px;
  }

  .margin-t#{$i} {
    margin-top: #{$i}px;
  }

  .margin-b#{$i} {
    margin-bottom: #{$i}px;
  }

  .margin-l#{$i} {
    margin-left: #{$i}px;
  }
}

.margin-t60 {
  margin-top: 60px;
}
.margin-tauto {
  margin-top: auto;
}
.margin-bauto {
  margin-bottom: auto
}
/* Font Size Classes */

@for $i from 8 through 40 {
  .fs#{$i} {
    font-size: #{$i}px;
  }
}

$colorpallete: (
  color-red: #e53935,
  topaz: #18cfa8,
  color-blue: #29b6f6,
  color-green: #28c7bf,
  color-darkgreen: #2a8588,
  color-white: #ffffff,
  color-black: #000000,
  color-grey: #999999,
  color-cc: #ccc,
  color-66: #666,
  color-33: #333,
  color-primary: #00bac6,
  color-orange: #f18131,
  color-bright-yellow: #faa61a,
  color_ee: #eeeeee,
  color_white_smoke: #f5f5f5,
);

@each $color-name, $color in $colorpallete {
  .bg-#{$color-name} {
    background-color: $color;
  }

  .text-#{$color-name} {
    color: $color;
  }
}

/* Letter Spacing */
@for $i from 1 through 30 {
  .ls-#{$i} {
    letter-spacing: #{$i/10}px;
  }
}

/* Font Size Classes */
@for $i from 2 through 10 {
  .border-radius#{$i} {
    border-radius: #{$i}px;
  }
}

/* Layout setup  */
.main-content-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  padding: 0 100px;
  box-sizing: content-box;
  letter-spacing: 1px;
  @include mQ-min($bigScreenWidth){
    max-width: $bigScreenWidth;
  }
}

.od-container {
  width: 100%;
  padding: 0 2vw;
  margin: 0 auto;

  &--lg {
    max-width: 1200px;
  }

  &--xl {
    padding: 0 100px;
  }
}
/* Text colors */

.text-black {
  color: #494949;
}
.text-red {
  color: #f00;
}

.max-width-100 {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.text-center {
  text-align: center;
  text-align: -webkit-center;
}
.bg-white {
  background-color: transparent;
}
.no-padding {
  padding: 0 !important;
}

.bg-cyan {
  background-color: rgba(0, 185, 198, 0.08);
}
.search-inner-container {
  position: relative;
}

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.font-bold {
  font-family: "FuturaStd-Heavy";
}

.font-medium {
  font-family: "FuturaStd-Medium";
}

strong {
  color: #000000;
  font-family: "NeuzeitGroBold";
  font-weight: normal;
}

a {
  color: #329c92;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-underline {
  text-decoration: underline;
}

.onhover-underline:hover {
  text-decoration: underline;
}

.color-green {
  color: #329c92;
}

.text-left{
  text-align: left;
}

.text-link {
  color: #329c92;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-link-gray {
  color: #333;
  cursor: pointer;

  &:hover {
    color: #333;
    text-decoration: underline;
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url(//static.lenskart.com/skin/frontend/base/default/img/sprite-v2.png);
  background-repeat: no-repeat;
  vertical-align: middle;
}

.display-ib {
  display: inline-block;
}

.display-block {
  display: block;
}

.display-flex,
.layout {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.display-grid {
  display: grid;
}

@for $i from 0 through 5 {
  .grid-col-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }

  .grid-row-#{$i} {
    grid-template-rows: repeat($i, 1fr);
  }

  .grid-gap-#{$i * 5} {
    grid-gap: #{$i * 5}px;
  }
}

.layout.center,
.align-items-center {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-row-reverse{
  flex-direction: row-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-items-end {
  align-items: flex-end;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-center {
  display: flex;
  align-items: center;
}
.flex-both-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-items-start {
  align-items: flex-start;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}

$ratingSize: 75px;

.star-rating {
  height: ($ratingSize/5);
  position: relative;
  width: $ratingSize;

  &:before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAgNTAiIGZpbGw9IiM3ZjhjOGQiID4gICAgPHBhdGggZD0iTSAyNSAyLjI3MzQzNzUgTCAxOC42MDkzNzUgMTguNDgwNDY5IEwgMC44MTA1NDY4OCAxOS40MTc5NjkgTCAxNC42NDg0MzggMzAuNDEyMTA5IEwgMTAuMDcwMzEyIDQ3LjIyMjY1NiBMIDI1IDM3Ljc3MTQ4NCBMIDM5LjkyOTY4OCA0Ny4yMjI2NTYgTCAzNS4zNTE1NjIgMzAuNDEyMTA5IEwgNDkuMTg5NDUzIDE5LjQxNzk2OSBMIDMxLjM5MDYyNSAxOC40ODA0NjkgTCAyNSAyLjI3MzQzNzUgeiI+PC9wYXRoPjwvc3ZnPg==");
    background-repeat: repeat-x;
    background-size: ($ratingSize/5);
    bottom: 0;
    content: "";
    display: block;
    height: ($ratingSize/5);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: $ratingSize;
  }

  .star {
    display: block;
    text-indent: -10000px;
    width: $ratingSize;
    height: ($ratingSize/5);
    position: absolute;
    overflow: hidden;

    &:before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAgNTAiIGZpbGw9IiNmMWM0MGYiID4gICAgPHBhdGggZD0iTSAyNSAyLjI3MzQzNzUgTCAxOC42MDkzNzUgMTguNDgwNDY5IEwgMC44MTA1NDY4OCAxOS40MTc5NjkgTCAxNC42NDg0MzggMzAuNDEyMTA5IEwgMTAuMDcwMzEyIDQ3LjIyMjY1NiBMIDI1IDM3Ljc3MTQ4NCBMIDM5LjkyOTY4OCA0Ny4yMjI2NTYgTCAzNS4zNTE1NjIgMzAuNDEyMTA5IEwgNDkuMTg5NDUzIDE5LjQxNzk2OSBMIDMxLjM5MDYyNSAxOC40ODA0NjkgTCAyNSAyLjI3MzQzNzUgeiI+PC9wYXRoPjwvc3ZnPg==");
      background-repeat: repeat-x;
      background-size: ($ratingSize/5);
      bottom: 0;
      content: "";
      display: block;
      height: ($ratingSize/5);
      left: 0;
      position: absolute;
      right: 0;
      text-indent: 10000px;
      top: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cursor-auto {
  cursor: auto;
}

.close-me-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1103;
  display: block;
  background: #404042;
  border-radius: 50%;

  &:before,
  &:after {
    position: absolute;
    left: 14px;
    content: " ";
    height: 16px;
    width: 2px;
    background-color: #fff;
    top: 7px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.uppercase {
  text-transform: uppercase;
}
.border-none {
  border: none
}
.capitalize {
  text-transform: capitalize;
}

.nowrap {
  white-space: nowrap;
}

.white-space-normal{
  white-space: normal;
}
.border-green {
  border: 1px solid #28c7bf;
}

.border {
  border: 1px solid #ebebeb;
}

.border-topaz {
  border: 1px solid #d4ead5;
}

.border-blue {
  border: 1px solid #00bac6;
}

.margin-0-auto {
  margin: 0 auto;
}

.border-bottom {
  border-bottom: 1px solid #ebebeb;
}

.border-top {
  border-top: 1px solid #ebebeb;
}

.border-right {
  border-right: 1px solid #ebebeb;
}

.border-left {
  border-left: 1px solid #ebebeb;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw900 {
  font-weight: 900;
}

.bold {
  font-family: "FuturaStd-Heavy";
}

.strike-through {
  text-decoration: line-through;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Founders Grotesk', sans-serif;
  font-weight: 400;
}

.btn {
  &.btn-primary {
    color: #fff;
    background-color: #f18132;
    border-color: #ef721a;
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 14px;
    padding: 12px 2px;
    border-radius: 5px;
    letter-spacing: 1px;
    outline: none;
    white-space: initial;

    &:hover,
    &:focus {
      background-color: #e1660f;
      border-color: #bf570d;
      color: #fff;
    }
  }
  &.btn-main {
    color: #fff;
    background-color: #00BAC6;
    border-color: #00BAC6;
    font-family: "FuturaStd-Medium";
    font-size: 14px;
    padding: 12px 20px;
    border-radius: 5px;
    letter-spacing: 1px;
    outline: none;
    white-space: initial;

    &:hover,
    &:focus {
      background-color: #00BAC6;
      border-color: #00BAC6;
      color: #fff;
    }
  }
}

div:focus {
  outline: 0px solid transparent;
}
.width-vw{
  width: 28vw
}

.full-width {
  width: 100% !important;
}

.display-none {
  display: none;
}

.h-100cent {
  height: 100%;
}
body {
  overflow-y: auto !important;
}
body.overflow-hidden.h-100cent{
  overflow: hidden !important;
}
input:focus,
button:focus {
  outline: none;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

/* Initial state */

.barLK {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  float: left;
  margin-right: 4px;
  margin-top: 6px;
  width: 6px;
  height: 18px;
  /* Set the animation properties */
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loadingbarLK;
}

/* Delay both the second and third bar at the start */

.loaderLK .barLK:nth-child(2) {
  animation-delay: 0.1s;
}

.loaderLK .barLK:nth-child(3) {
  animation-delay: 0.2s;
}

/* The actual animation */

@keyframes loadingbarLK {
  0% {
  }

  10% {
    margin-top: 5px;
    height: 22px;
    border-color: #ffffff;
    background-color: #ffffff;
  }

  20% {
    margin-top: 0px;
    height: 32px;
    border-color: #ffffff;
    background-color: #ffffff;
  }

  30% {
    margin-top: 1px;
    height: 30px;
    border-color: #ffffff;
    background-color: #ffffff;
  }

  40% {
    margin-top: 3px;
    height: 26px;
  }

  50% {
    margin-top: 5px;
    height: 22px;
  }

  60% {
    margin-top: 6px;
    height: 18px;
  }

  70% {
  }

  /* Missing frames will cause the extra delay */
  100% {
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.pos-fix { position: fixed;}
.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.component-loader-container {
  position: relative;
  min-height: 250px;

  .loader {
    position: absolute;

    &_overlay {
      position: absolute;
      background: #fff;
    }
  }
}

.hideElement {
  visibility: hidden;
  height: 0;
}

.screen-hidden {
  position: absolute !important;
  left: -9999px !important;
  height: 0px !important;
}

.no-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

/**tooltip**/

.tool-tip-hover {
  display: inline-block;
}

.tool-tip-hover:before {
  content: "?";
  color: #fff;
  background: #515151;
  width: 20px;
  height: 20px;
  display: block;
  font-family: "FuturaStd-Heavy";
  font-weight: normal;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}

/**tooltip**/

.modal-backdrop {
  z-index: 1050 !important;
}

.beta-span {
  font-family: "FuturaStd-Medium";
  position: relative;
  top: 10px;
  left: 2px;
  color: white;
}

.sans-serif-semi-bold {
  font-family: SourceSansProSemibold, sans-serif;
}

.futur-std {
  font-family: FuturaStd-Book;
}

/* Line Height Classes */
$maxLineHeight: 26;
@for $i from 12 through $maxLineHeight {
  .line-height-#{$i} {
    line-height: $i + px;
  }
}

.lh15 {
  line-height: 15px;
}

.font-style-normal {
  font-style: normal;
}

ul,
li {
  list-style-type: none;
}

.clear-both {
  clear: both;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.table {
  .thead-light {
    th {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.separator {
  display: block;
  margin: 12px 0;
  height: 1px;
  padding: 0;

  &.dashed {
    border-top: 1px dashed #ccc;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;

  li:not(.disabled) {
    a {
      color: #3bb3a9;

      &.active-page {
        background-color: #3bb3a9;
        color: #fff;
        border-color: #3bb3a9;
      }
    }
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.tooltip-custom {
  .tooltip-inner {
    background: #fff;
    box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.75);
    padding: 15px;
  }

  .arrow {
    &:before {
      border-right-color: rgba(0, 0, 0, 0.75);
    }
  }
}

.mr-0auto {
  margin: 0 auto;
}

.text-color_brick_red {
  color: #d54c44;
}
.text-color_primary{
  color: #329c92;
}
.text-color_green{
  color: green;
}
.bora-4 {
  border-radius: 4px;
}

.bora-8 {
  border-radius: 8px;
}
.bora-50cent {
  border-radius: 50%;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-space-between {
  justify-content: space-between;
}

.bg-topaz {
  background-color: #18cfa8;
}

.blur-text,
.blur-text label {
  color: transparent;
  text-shadow: 0 0 1.5px rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.modal-center {
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}
.icon-info {
  background: url("https://static5.lenskart.com/images/cust_mailer/Mar-03/Info_SubmitPowerAlert.png") no-repeat 0 0;
  height: 16px;
  width: 16px;
  vertical-align: top;
  background-size: contain;
}
.bullet-list {
  list-style-type: disc;
  list-style-position: inside;
}
.width100 {
  width: 100%;
}
.width75 {
  width: 75%;
}
.width65 {
  width: 65%;
}
.width50 {
  width: 50%;
}
.width25 {
  width: 25%;
}
.width85 {
  width: 85%;
}
.width60 {
  width: 60%
}
.width80 {
  width: 80%;
}
.width60 {
  width: 60%
}

/* flex-basis classes */
@for $i from 4 through 25 {
  $value: $i * 4;
  .flex-basis-#{$value} {
    flex-basis: #{$value}#{"%"};
  }
}

.align-self-center {
  align-self: center;
}

.fw-bold {
  font-weight: bold;
}

@for $i from 6 to 12 {
  $width: $i * 5;
  .down-arrow {
    visibility: hidden;
    height: 7px;
    position: absolute; 
    left: 36px; 
    top: 5px;
  }
  .country-code-display-#{$width}{
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    color: map-get($colorpallete, color-grey) !important;
    -webkit-text-fill-color: map-get($colorpallete, color-grey) !important;
    -webkit-opacity: 1;
    opacity: 1;
    left: 0;
    width: #{$width}px !important;
    outline: none;
    ~ input {
      padding-left: #{$width}px !important;
      ~ label {
        transform: translateX(#{$width}px) !important;
      }
      &:focus ~ label,
      &:not([value=""]) ~ label{
        transform: translateX(0) !important;
      }
    }
  }
  @supports (-moz-appearance:none) {
    .country-code-display-#{$width} {
      width: #{$width + 3}px !important;
    }
    .moz-left {
      left: -3px !important;
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) { _::-webkit-full-page-media, _:future, :root , .country-code-display-#{$width}{  -webkit-appearance: none; left: 3px; } _::-webkit-full-page-media, _:future, :root , .down-arrow { visibility: visible; } }
}
.country-code-display-40.sa-country-class {
  width: 42px !important;
}
.big-screen-wrapper{
  @include bigScreenWrapper()
}
.verify-btn {
  height: 32px;
  width: 73px;
  background: #c2c2c2;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #666666;
  letter-spacing: 0.02rem;
  line-height: 16px;
  &.isEnabled {
    background: #2440a6 !important;
    color: #ffffff !important;
  }
}    
.DittoSandbox{
  @include mQ-min($bigScreenWidth) {
    position: relative;
    left: calc(50vw - #{$bigScreenWidth}/2);
    max-width: $bigScreenWidth;
  }
}

.od-font-bold {
  font-family: 'Founders Grotesk', sans-serif;
  letter-spacing: 0.02em;
}

.od-font-light {
  font-family: "NeuzeitGroLig";
  letter-spacing: -0.02em;
}

.od-font-reg {
  font-family: 'Founders Grotesk', sans-serif;
  letter-spacing: 0.05em;
}

.od-font-raleway {
  font-family: "Raleway";
  letter-spacing: -0.24em;
}

.od-membership-status-bronze {
  color: #8E5010;
}

.od-membership-status-silver {
  color: #939393;
}

.od-membership-status-gold {
  color: #C7A907;
}

.od-separator {
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.2;
}

#launcher {
  display: none;
}


@media only screen and (min-width: 900px) {
  .od-c-breadcrumb {
    display: flex;
    padding: 0px 5px 5px 0;
    border-top: 1px solid rgba(84, 96, 115, 0.10);
    border-bottom: 1px solid rgba(84, 96, 115, 0.10);
    border-radius: 0px;
    align-items: center;
    font-size: 11px;
    letter-spacing: 1px;

    ul {
        display: flex;
        padding: 0;
        margin: 0;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            color: #000000;
            font-family: 'Founders Grotesk', sans-serif;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: -0.02em;
            opacity: 0.3;

            &::after {
                content: '>';
                padding: 0 15px;
            }

            &:last-child {
                font-family: 'Founders Grotesk', sans-serif;

                &::after {
                    content: '';
                    padding: 0;
                }
            }

            a {
                color: #000000;
                font-family: 'Founders Grotesk', sans-serif;
            }
        }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1153px) {
    .od-c-breadcrumb {
        transform: translateY(20px);
    }
}

@media only screen and (min-width: 1025px) {
    .od-c-breadcrumb {
        padding: 5px 5px 5px 50px;

        ul {
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
        }
    }
}