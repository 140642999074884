@import url('https://use.typekit.net/faz0ttr.css');

@font-face {
  font-family: "Miller-Banner";
  src: url("https://static.lenskart.com/owndays/fonts/Miller-Banner/Miller-Banner.woff2") format("woff2"),
      url("https://static.lenskart.com/owndays/fonts/Miller-Banner/Miller-Banner.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tahoma";
  src: url("https://static.lenskart.com/owndays/fonts/Tahoma/Tahoma-Regular.woff2") format("woff2"),
      url("https://static.lenskart.com/owndays/fonts/Tahoma/Tahoma-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-itc: "itc-avant-garde-gothic-pro", sans-serif;
$font-miller: "Miller-Banner";
$font-tahoma: "Tahoma";

$brown-81: #815624;
$cream-f6: #f6dcc3;

$base-img-url: 'https://static.lenskart.com/owndays/img/anniversary-10th-collection';

.base-anniversary-10th-collection {
  font-family: $font-itc;
  padding-bottom: 143px;
  position: relative;
  opacity: 0;
  transition: opacity 0.2s;
  overflow: hidden;
  
  &::before {
    content: "";
    position: absolute;
    top: 170vw;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
    background-color: $cream-f6;
  }

  &.loaded {
    opacity: 1;
    &.bg {
      &::before {
        background: url("#{$base-img-url}/bg-sp.webp") top center/100% auto;
      }
    }
  }
  
  @media (min-width: 768px) {
    padding-bottom: 169px;

    &::before {
      top: 840px;
    }

    &.loaded {
      &.bg {
        &::before {
          background-image: url("#{$base-img-url}/bg-pc.webp");
        }
      }
    }
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    max-width: calc(1040px + 40px * 2);
  }

  &__heading {
    font-family: $font-miller;
    font-weight: 400 !important;
    font-size: 32px;
    line-height: 1;
    text-align: center;
    margin-bottom: 32px;
    letter-spacing: 0.96px;

    span{
      display: inline-block;
    }
    
    @media (min-width: 768px) {
      font-size: 50px;
      text-align: center;
      margin-bottom: 80px;
      letter-spacing: 1.68px;
    }

    &-lg {
      font-weight: 500 !important;
      font-size: 50px;
      margin-bottom: 40px;
      line-height: 46px;
      color: $brown-81;
      opacity: 0.5;

      @media (max-width: 767px) {
        text-align: center;
      }

      @media (min-width: 768px) {
        font-size: 56px;
        letter-spacing: 1.68px;
      }
    }
  }

  &__btn {
    min-height: 70px;
    border-radius: 70px;
    background-color: $brown-81;
    font-family: $font-itc;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 20px;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 0.7px;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
      border: 1px solid white;
      border-radius: 70px;
      pointer-events: none;
    }
    &--black {
      background-color: black;
    }
    
    &--small-sp {
      font-size: 13px;
      letter-spacing: 0.65px;
    }

    @media (min-width: 768px){
      max-width: 430px;
      min-height: 80px;
      border-radius: 80px;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 1px;
    
      &::before {
        top: 5.7px;
        bottom: 5.7px;
        left: 6.9px;
        right: 6.9px;
      }

      &--small-sp {
        font-size: 17px;
        letter-spacing: 0.85px;
      }
    }

    @media (min-width: 1024px){
      &:hover {
        background-color: black;
      }

      &--black {
        &:hover {
          background-color: $brown-81;
        }
      }
    }
  }

  &__btn-gp {
    display: grid;
    gap: 21px 0;

    .base-anniversary-10th-collection__btn {
      width: 100%;
    }

    @media (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    
    @media (min-width: 768px) {
      .base-anniversary-10th-collection__btn {
        min-height: 97.65px;
      }
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      gap: 40px;

      .base-anniversary-10th-collection__btn {
        max-width: none;
      }
    }
  }

  &__frame {
    position: relative;
    width: calc(100% + 80px);
    height: 100%;
    margin: 64px -40px 0 -40px;
    border-radius: inherit;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
      opacity: 0;
      transform: scale(1.2);
      transition: all 1s ease;
    }

    &.aos-animate {
      img {
        opacity: 1;
        transform: scale(1);
      }
    }

    @media (min-width: 768px) {
      width: 100%;
      margin: 90px 0 0 0;
    }
  }
}
