.search-bar-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 25px;
  border: 1px solid #555555;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 10;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-color: #FFFFFF;
}

.search-bar-results__trending {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #555555;
  opacity: 0.5;
}

.search-bar-results__view-all {
  margin: 20px auto 0;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #555555;
  text-align: center;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.search-bar-results__view-all:hover {
  opacity: 0.3;
}

.search-bar-results__result, .search-bar-results__result:visited {
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #555555;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.search-bar-results__result:hover {
  color: #555555;
  text-decoration: none;
  opacity: 0.3;
}

.search-bar-results__result:last-child {
  margin-bottom: 0;
}
