.body-lens-replacement {
  letter-spacing: 1px;
}

.body-lens-replacement__price h2 {
  margin-top: 50px;
  font-weight: 400;
  letter-spacing: 1px;
}

.body-lens-replacement__price__images {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 550px;
  display: block;
  margin: 25px auto 0;
}

.body-lens-replacement__price__images__option {
  width: 18%;
  height: auto;
  vertical-align: middle;
  position: relative;
}

.body-lens-replacement__price__images__option--hide-plus::before, .body-lens-replacement__price__images__option--hide-plus::after {
  display: none;
}

.body-lens-replacement__price__images__option img {
  display: block;
  width: 50px;
  height: auto;
  margin: 0 auto;
}

.body-lens-replacement__price__images__option p {
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 11px;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #000;
}

.body-lens-replacement__price__images__option::before, .body-lens-replacement__price__images__option::after {
  content: "";
  position: absolute;
  background: #000;
}

.body-lens-replacement__price__images__option::before {
  top: 30px;
  right: 0;
  width: 1px;
  height: 11px;
}

.body-lens-replacement__price__images__option::after {
  top: 50%;
  right: -5px;
  width: 11px;
  margin-top: -5px;
  height: 1px;
}

@media (min-width: 768px) {
  .body-lens-replacement__price__images {
    margin: 40px auto;
    max-width: 620px;
  }
  .body-lens-replacement__price__images__option {
    width: 22%;
  }
  .body-lens-replacement__price__images__option::before {
    top: 26px;
    height: 21px;
  }
  .body-lens-replacement__price__images__option::after {
    right: -10px;
    width: 21px;
  }
}

.body-lens-replacement__price__price-value {
  margin: 14px auto 25px auto;
  width: 30%;
  height: auto;
  padding: 0 22px;
}

.body-lens-replacement__price__price-value--hide-sp {
  display: none;
}

@media (min-width: 768px) {
  .body-lens-replacement__price__price-value--hide-sp {
    display: table-cell;
  }
}

.body-lens-replacement__price__price-value--hide-pc {
  display: block;
}

@media (min-width: 768px) {
  .body-lens-replacement__price__price-value--hide-pc {
    display: none;
  }
}

.body-lens-replacement__price__price-value p {
  display: inline;
  position: relative;
  margin-bottom: 0;
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 28px;
  line-height: 1;
  color: #000;
}

.body-lens-replacement__price__price-value p::before, .body-lens-replacement__price__price-value p::after {
  content: "";
  position: absolute;
  background: #000;
}

.body-lens-replacement__price__price-value p::before {
  top: 20px;
  left: -30px;
  width: 15px;
  height: 1px;
}

.body-lens-replacement__price__price-value p::after {
  top: 12px;
  left: -30px;
  width: 15px;
  height: 1px;
}

@media (min-width: 768px) {
  .body-lens-replacement__price__price-value {
    margin: 14px 0 25px;
  }
}

.body-lens-replacement__price__detail {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 1px;
  margin-bottom: 0;
  color: #000;
}

.body-lens-replacement__lens {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 0;
}

.body-lens-replacement__lens__option {
  border-bottom: 1px dashed #e3e3e3;
  margin: 20px 0;
  padding: 0 15px 20px;
  position: relative;
  color: #000;
}

.body-lens-replacement__lens__option:not(:first-child) {
  margin-top: 0;
}

.body-lens-replacement__lens__option:last-child {
  margin-bottom: 0;
}

.body-lens-replacement__lens__option::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 15px;
  width: 30px;
  height: 33.3px;
  margin: 0;
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_1-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__option.progressive::before {
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_2-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__option.polarised::before {
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_3-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__option.transitions::before {
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_4-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__option.pc::before, .body-lens-replacement__lens__option.pc420::before {
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_5-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__option.color::before {
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_6-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__option.antifog::before {
  background: url("https://static.lenskart.com/media/owndays/img/lens-replacement/detail_7-lens.webp") center center/cover no-repeat;
}

.body-lens-replacement__lens__name {
  font-family: "Founders Grotesk", sans-serif;
  padding-left: 50px;
}

.body-lens-replacement__lens__name h3 {
  font-size: 14px;
  font-weight: 300 !important;
  line-height: 1.6;
  margin: 0;
}

.body-lens-replacement__lens__tag-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 5px 0 10px;
}

.body-lens-replacement__lens__tag--prescription, .body-lens-replacement__lens__tag--with-out-prescription, .body-lens-replacement__lens__tag--price {
  font-family: "Founders Grotesk", sans-serif;
  margin: 5px 0 0 0;
}

.body-lens-replacement__lens__tag--prescription, .body-lens-replacement__lens__tag--with-out-prescription {
  padding-right: 6px;
}

.body-lens-replacement__lens__tag--prescription span, .body-lens-replacement__lens__tag--with-out-prescription span {
  height: 18px;
  display: block;
  font-size: 10px;
  font-weight: normal !important;
  letter-spacing: normal;
  line-height: 19px;
  text-align: center;
  padding-inline: 6px;
}

.body-lens-replacement__lens__tag--prescription span {
  color: #fff;
  background: #666;
}

.body-lens-replacement__lens__tag--with-out-prescription span {
  background: #e3e3e3;
}

.body-lens-replacement__lens__tag--price {
  font-size: 14px;
  font-weight: 600 !important;
  padding-right: 10px;
  line-height: 20px;
}

.body-lens-replacement__lens__table {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 12px;
  margin-left: 48px;
  width: calc(100% - 50px);
  height: auto;
  margin-bottom: 0;
}

.body-lens-replacement__lens__table tr th, .body-lens-replacement__lens__table tr td {
  vertical-align: top;
}

.body-lens-replacement__lens__table tbody > tr > th {
  width: 140px;
  height: auto;
  font-weight: 300 !important;
  position: relative;
}

.body-lens-replacement__lens__table td > p {
  margin-bottom: 0;
  line-height: 2;
}

.body-lens-replacement__lens__table__tooltip img {
  width: 18px;
  height: auto;
}

.body-lens-replacement__lens__table ul {
  list-style-type: none;
  padding-left: 0;
}

.body-lens-replacement__lens__table ul li {
  float: left;
  padding-left: 0;
}

.body-lens-replacement__lens__table ul li p {
  margin-bottom: 0;
  line-height: 2;
}

.body-lens-replacement__lens__detail {
  padding-top: 10px;
}

.body-lens-replacement__lens__btn-layout {
  width: 223px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #666;
  margin: 25px auto 0 auto;
}

.body-lens-replacement__lens__btn-layout a {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  color: #666;
  text-decoration: none !important;
}

.body-lens-replacement__lens__tooltip-wrapper {
  position: relative;
  display: inline-block;
  margin: 0 0px 4px 10px;
}

@media (min-width: 768px) {
  .body-lens-replacement__lens__tooltip-wrapper {
    margin: 0 0px 4px 8px;
  }
}

.body-lens-replacement__lens__tooltip-wrapper .body-lens-replacement__lens__table__tooltip {
  position: relative;
  cursor: pointer;
  /* Show the tooltip on hover */
}

.body-lens-replacement__lens__tooltip-wrapper .body-lens-replacement__lens__table__tooltip::after {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  /* Position below the icon */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  width: 200px;
  background-color: rgba(0, 0, 0, .75);
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.4;
  white-space: pre-wrap;
  /* Allows for line breaks */
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  margin-top: 8px;
  /* Adds spacing between the icon and tooltip */
}

.body-lens-replacement__lens__tooltip-wrapper .body-lens-replacement__lens__table__tooltip::before {
  /* Arrow styling */
  content: '';
  position: absolute;
  bottom: -8px;
  /* Position arrow above the tooltip box */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, .75) transparent;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

.body-lens-replacement__lens__tooltip-wrapper .body-lens-replacement__lens__table__tooltip:hover::after, .body-lens-replacement__lens__tooltip-wrapper .body-lens-replacement__lens__table__tooltip:hover::before {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 768px) {
  .body-lens-replacement__lens__option {
    padding: 0 24px 20px 80px;
  }
  .body-lens-replacement__lens__option::before {
    width: 36px;
    height: 40px;
    left: 25px;
    top: calc(50% - 10px);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .body-lens-replacement__lens__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0;
  }
  .body-lens-replacement__lens__name h3 {
    color: #000;
    font-size: 16px;
  }
  .body-lens-replacement__lens__tag-layout {
    margin: 10px 0 10px 20px;
  }
  .body-lens-replacement__lens__tag--prescription, .body-lens-replacement__lens__tag--with-out-prescription, .body-lens-replacement__lens__tag--price {
    margin: 5px 0 0 0;
  }
  .body-lens-replacement__lens__tag--prescription, .body-lens-replacement__lens__tag--with-out-prescription {
    padding-right: 10px;
  }
  .body-lens-replacement__lens__tag--prescription span, .body-lens-replacement__lens__tag--with-out-prescription span {
    font-size: 11px;
  }
  .body-lens-replacement__lens__tag--price {
    font-size: 18px;
  }
  .body-lens-replacement__lens__table {
    font-size: 15px;
    margin-left: -3px;
    width: 100%;
  }
  .body-lens-replacement__lens__table tbody > tr > th {
    width: 160px;
  }
  .body-lens-replacement__lens__table__tooltip {
    right: 0;
  }
  .body-lens-replacement__lens__table ul li {
    padding-right: 20px;
  }
  .body-lens-replacement__lens__btn-layout {
    width: 298px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .body-lens-replacement__lens__btn-layout:hover {
    cursor: pointer;
    background-color: #666;
  }
  .body-lens-replacement__lens__btn-layout:hover a {
    color: #fff;
  }
}

.body-lens-replacement__steps__list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.body-lens-replacement__steps__step {
  font-family: "Founders Grotesk", sans-serif;
  padding: 0 15px 20px;
  border-bottom: 1px dashed #e3e3e3;
  margin: 20px 0;
}

.body-lens-replacement__steps__step:last-child {
  margin-bottom: 0;
  border: 0;
}

.body-lens-replacement__steps__step h3 {
  position: relative;
  margin-top: 0;
  margin-bottom: 15px;
  color: #000;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 1.6;
  letter-spacing: 1px;
}

.body-lens-replacement__steps__step h3::before {
  content: "";
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
}

.body-lens-replacement__steps__step h3 span {
  display: block;
  font-size: 14px;
  font-weight: 600 !important;
  color: #000;
  line-height: 30px;
  padding-left: 40px;
  margin-bottom: 5px;
}

.body-lens-replacement__steps__step p {
  color: #000;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.body-lens-replacement__steps__step p a {
  color: #0094c8;
  text-decoration: none !important;
}

.body-lens-replacement__steps__step--1 h3::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/lens-replacement/store.svg");
}

.body-lens-replacement__steps__step--2 h3::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/lens-replacement/lens.svg");
}

.body-lens-replacement__steps__step--3 h3::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/lens-replacement/cleaning.svg");
}
