.od-login-page {
  position: fixed;
  inset: 0;
  z-index: 9999;
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  input[type='date'] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 50px;
  }

  .od-login-page-number-wrapper {
    display: flex;
    &_code {
      width: 60px;
      padding-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      &::placeholder {
        color: #000;
      }

      &--disabled {
        opacity: 0.5;
      }
    }
    &_number {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      &--disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  &-left {
    padding: 48px;
    background: url('https://static1.lenskart.com/media/owndays/img/customer/account/auth-bg_pc.webp') no-repeat left top/cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: normal;
    &__back{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      background-color: #fff;
      border-radius: 10rem;
      margin-bottom: 24px;
      font: 0/0 a;
    }
    &__heading{
      font: 0/0 a;
      margin: 0 0 80px;
      img{
        display: block;
        width: 100%;
        max-width: 342px;
      }
    }
    &__benefits{
      list-style: none;
      padding: 0;
      margin: 0 0 80px;
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 24px;
      max-width: 624px;
      li{
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 12px;
        background: rgba(76, 76, 76, 0.40);
        backdrop-filter: blur(4px);
        padding: 16px;
        p{
          font-size: 20px;
          margin: 0;
        }
      }
    }
    &__copyright{
      margin-block: auto 0;
      font-size: 18px;
      text-align: center;
    }
  }

  &-right {
    position: relative;
    background-color: #fafafa;
    z-index: 9999999999;
    padding-block: 46px;
    overflow-y: auto;

    &-h1 {
      font-family: 'Founders Grotesk', sans-serif;
      font-size: 24px;
      text-align: center;
      margin: 0 0 24px 0;
      padding: 0px 30px;
      letter-spacing: -2%;

      @media (min-width: 900px) {
        margin: 0 0 50px 0;
      }
    }
    &-flex {
      display: -webkit-box;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;
    }
    &-form {
      background-color: #fff;
      display: flex;
      gap: 24px;
      padding: 24px;
      border-radius: 10px;
      flex-direction: column;
      width: 60%;
      @media (max-width: 1024px) {
        width: 80%;
      }
      h4 {
        font-family: 'Founders Grotesk', sans-serif;
        line-height: 26px;
        font-size: 24px;
      }
      input,
      select {
        display: block;
        padding: 12px 16px;
        background-color: #fafafa;
        border: none;
        border-radius: 8px;
        margin-bottom: 16px;
        font-size: 16px;
        width: 100%;
      }
      &-fp {
        text-align: right;
        font-family: 'Founders Grotesk', sans-serif;
        color: #444444;
        text-decoration: underline;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 24px;
      }
      &-sb {
        width: 100%;
        background-color: #546073;
        padding: 12px 20px;
        border: none;
        border-radius: 40px;
        color: #fff;
        font-size: 20px;
        margin-top: 24px;
        transition: opacity 0.3s ease, background-color 0.3s ease;
        &:disabled {
          opacity: 0.5; 
          cursor: not-allowed;
          background-color: #8090a3; // Lighter shade for disabled state

        }
        &:not(:disabled):hover {
          background-color: #3a4350; // Darker shade for hover state when not disabled
        }
      }
    }
  }
  
  .od-login-page-input-error {
    color: #a94442;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .od-login-page-phone-error {
    color: #a94442;
    margin-bottom: 16px;
  }

  .divider-line {
    display: flex;
    align-items: center;
    justify-content: center;
    &-left {
      border: 1px solid #00000010;
      margin-right: 20px;
      width: 100%;
      height: 1px;
    }
    &-text {
      font-family: 'Founders Grotesk', sans-serif;
    }
    &-right {
      border: 1px solid #00000010;
      margin-left: 20px;
      width: 100%;
      height: 1px;
    }
  }

  .od-login-page-number-h {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .od-login-page-right-form-fp {
      margin-bottom: 0px;
      &__disabled {
        color: #444444;
        user-select: none;
      }
    }
  }
}
