@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Kaku+Gothic+New:wght@400;500;700;900&display=swap&family=Zen+Old+Mincho&display=swap&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/post-no-bills-colombo");
.fancybox__footer {
  display: none !important;
}
.fancybox__backdrop {
  background: rgba(24, 24, 27, .81);
}
.l-gundam5 .slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.l-gundam5 .slick-slide {
  height: inherit !important;
}
.l-gundam5 .slick-slide {
  margin: 0 17.5px;
}
.l-gundam5 .slick-initialized .slick-slide {
  position: relative;
  border: 4px solid rgba(193, 139, 53, .6);
  background: rgba(0, 0, 0, .5);
  padding: 20px 16px;
  height: auto;
  width: 82.05128vw !important;
  max-width: 598px !important;
  margin: 0 10px;
}
.fancybox-image-list .fancybox__slide.has-image > .fancybox__content {
  margin-top: -200px;
  max-width: 640px !important;
  height: auto !important;
  padding: 0 !important;
}
.fancybox__nav {
  position: relative;
  bottom: 120px;
  width: 165px;
  margin-left: auto;
  margin-right: auto;
}
.fancybox__nav button {
  width: 60px;
  height: 60px;
  background-color: #fff;
  color: #666666 !important;
}
.fancybox__nav button svg {
  -webkit-filter: none !important;
          filter: none !important;
}
.fancybox__nav button:hover {
  background-color: #fff !important;
  color: #666666 !important;
}
.fancybox__nav .fancybox__infobar {
  bottom: -70px;
  position: absolute;
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.fancybox__toolbar .fancybox__infobar {
  opacity: 0;
}
.fancybox__toolbar .f-button:hover {
  background-color: rgba(24, 24, 27, .65) !important;
}
.l-gundam5 {
  font: 500 14px/30px "Zen Kaku Gothic New", sans-serif;
  color: #fff;
  padding-bottom: 0.1px;
  background-color: #000;
}
.l-gundam5 *,
.l-gundam5 *::before,
.l-gundam5 *::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  border: none;
  color: inherit;
  background: none;
}
.l-gundam5 ul,
.l-gundam5 ol {
  list-style: none;
}
.l-gundam5 button {
  cursor: pointer;
  background-color: unset;
}
.l-gundam5 button,
.l-gundam5 input,
.l-gundam5 optgroup,
.l-gundam5 select,
.l-gundam5 textarea {
  color: inherit;
  font: inherit;
}
.l-gundam5 a {
  cursor: pointer;
  text-decoration: none !important;
  outline: none;
}
.l-gundam5 img,
.l-gundam5 picture {
  width: 100%;
  height: auto;
  display: block;
  vertical-align: middle;
}
.l-gundam5__container {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.l-gundam5__title {
  font: 400 36px/1 "Post No Bills Colombo", sans-serif;
  letter-spacing: 3.6px;
  padding-bottom: 32px;
  color: #fff;
  position: relative;
}
.l-gundam5__title::before, .l-gundam5__title::after {
  content: "";
  position: absolute;
  left: 16px;
  border-top: 1px solid #fff;
}
.l-gundam5__title::before {
  width: 83px;
  bottom: 13px;
}
.l-gundam5__title::after {
  width: 45px;
  bottom: 0;
}
.l-gundam5__title--right {
  text-align: right;
}
.l-gundam5__title--right::before, .l-gundam5__title--right::after {
  left: auto;
  right: 16px;
}
.l-gundam5__title--center {
  text-align: center;
}
.l-gundam5__title--center::before, .l-gundam5__title--center::after {
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .l-gundam5__title {
    font-size: 48px;
    padding-bottom: 46px;
  }
  .l-gundam5__title::before {
    width: 225px;
    bottom: 16px;
  }
  .l-gundam5__title::after {
    width: 123px;
  }
}
.l-gundam5__btn {
  font: 600 14px/1 "Zen Kaku Gothic New", sans-serif;
  background-color: #b31111;
  color: #fff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 60px;
  padding: 0 50px;
  border-radius: 30px;
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: none;
}
.l-gundam5__btn[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
.l-gundam5__btn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 9px;
  height: 17px;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-right.svg") center/contain no-repeat;
}
@media (min-width: 1024px) {
  .l-gundam5__btn {
    font-size: 18px;
    height: 80px;
    border-radius: 40px;
  }
  .l-gundam5__btn::after {
    width: 12px;
    height: 25px;
    right: 42px;
  }
  .l-gundam5__btn:hover {
    -webkit-animation: gundam_shine 2s;
            animation: gundam_shine 2s;
  }
}
.l-gundam5 .main {
  color: #fff;
  position: relative;
  z-index: 3;
}
.l-gundam5 .main .l-gundam5__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.l-gundam5 .main__img {
  position: relative;
  pointer-events: none;
  z-index: -1;
}
.l-gundam5 .main__img img {
  aspect-ratio: 195/368;
}
.l-gundam5 .main__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 33vw 20px 0;
}
.l-gundam5 .main__content h1 {
  font: 900 44px/48px "Zen Old Mincho", serif;
  letter-spacing: 1.76px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  text-align: center;
  margin-top: 5px;
  text-shadow: 0 4px 5px rgba(0, 0, 0, .4);
}
.l-gundam5 .main__content h1 + small {
  font: 900 14px/1 "Zen Old Mincho", serif;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.l-gundam5 .main__content p {
  font: 500 12px/1 "Zen Kaku Gothic New", sans-serif;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  text-align: center;
  border: 1px solid #fff;
  border-left: none;
  border-right: none;
  letter-spacing: 2px;
  padding: 5px 44px 9px;
}
.l-gundam5 .main__content p strong {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2.08px;
}
.l-gundam5 .main__logo {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  width: 187px;
  margin: 15px auto;
  aspect-ratio: 187/43;
  pointer-events: none;
}
.l-gundam5 .main__zeon-logo {
  width: 44px;
  height: auto;
  position: absolute;
  top: 26px;
  right: 20px;
  aspect-ratio: 44/63;
}
.l-gundam5 .main__scroll-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 99px;
  height: 99px;
  margin: 70px auto 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 28px;
}
.l-gundam5 .main__scroll-down span {
  opacity: 0;
  position: absolute;
}
.l-gundam5 .main__scroll-down::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/scroll-down-circle.svg") center/contain no-repeat;
  -webkit-animation: spin 10s linear infinite;
          animation: spin 10s linear infinite;
}
.l-gundam5 .main__scroll-down::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/arrow-down.svg") center/31px auto no-repeat;
}
.l-gundam5 .main__sales {
  background-color: #c18b35;
  color: #fff;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  position: absolute;
  top: 48px;
  left: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  line-height: 1;
}
.l-gundam5 .main__sales-year {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: -4px;
}
.l-gundam5 .main__sales-date {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 2px 0px;
}
.l-gundam5 .main__sales-date small {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1.5px 1.5px 2px;
  margin-left: 3px;
  background-color: #7e530d;
}
.l-gundam5 .main__sales-time {
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.l-gundam5 .main__sales-text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-top: 4px;
}
@media (min-width: 480px) {
  .l-gundam5 .main__content {
    padding-top: 63vw;
  }
}
@media (min-width: 768px) {
  .l-gundam5 .main__img img {
    aspect-ratio: 180/107;
  }
  .l-gundam5 .main__content {
    padding-top: 30vw;
  }
  .l-gundam5 .main__scroll-down {
    top: calc(100% + 20px);
    bottom: auto;
    margin-top: 0;
  }
  .l-gundam5 .main__sales {
    top: 11.45833vw;
    left: 17.57813vw;
  }
}
@media (min-width: 1024px) {
  .l-gundam5 .main__content {
    padding-top: 25vw;
  }
  .l-gundam5 .main__content h1 {
    font-size: 50px;
    letter-spacing: 2px;
    margin-top: 20px;
  }
  .l-gundam5 .main__content h1 small {
    font-size: 16px;
    letter-spacing: 1.44px;
  }
  .l-gundam5 .main__content p {
    font-size: 15px;
    letter-spacing: 3.75px;
    padding: 8px 55px 11px;
  }
  .l-gundam5 .main__content p strong {
    font-size: 19px;
    letter-spacing: 2.47px;
  }
  .l-gundam5 .main__logo {
    margin: 23px auto 30px;
    width: 267px;
  }
  .l-gundam5 .main__zeon-logo {
    top: 69px;
    right: 60px;
    width: 5.27778vw;
    max-width: 76px;
  }
  .l-gundam5 .main__scroll-down {
    top: auto;
    bottom: 0;
    margin-top: 0;
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
  .l-gundam5 .main__scroll-down:hover::after {
    -webkit-animation: gundam_move 1s infinite linear;
            animation: gundam_move 1s infinite linear;
  }
  .l-gundam5 .main__sales {
    width: 168px;
    height: 168px;
    top: 10.25391vw;
    left: 12.20703vw;
  }
  .l-gundam5 .main__sales-year {
    font-size: 15px;
    letter-spacing: 1.5px;
    margin-top: -8px;
  }
  .l-gundam5 .main__sales-date {
    font-size: 34px;
    letter-spacing: 2.04px;
    margin: 4px 0px;
  }
  .l-gundam5 .main__sales-date small {
    font-size: 14px;
    font-weight: 700;
    padding: 2px 2px 3px;
    margin-left: 4px;
  }
  .l-gundam5 .main__sales-time {
    font-size: 15px;
    letter-spacing: 0.3px;
  }
  .l-gundam5 .main__sales-text {
    font-size: 21px;
    letter-spacing: 2.1px;
    margin-top: 6px;
  }
}
@media (min-width: 1296px) {
  .l-gundam5 .main__sales {
    top: 155px;
    left: 12.2%;
  }
}
@media (min-width: 1440px) {
  .l-gundam5 .main__content {
    padding-top: 18vw;
  }
  .l-gundam5 .main__scroll-down {
    width: 151px;
    height: 151px;
    -webkit-transform: translateY(1%);
            transform: translateY(1%);
    z-index: 33 !important;
  }
  .l-gundam5 .main__scroll-down::after {
    background-size: 48px auto;
  }
}
.l-gundam5 .concept {
  padding: 57px 0 123px 0;
  position: relative;
  z-index: 2;
  /* &__3d {
      height: 2000px;

      &-container {
        position: sticky;
        top: 20px;
      }

      model-viewer {
        //--progress-bar-color: transparent;
        width: 100%;
        //height: 70vh;
        height: calc(100vh - 60px - 140px);
        max-height: 1000px;
        outline: none;
        border: none;
        opacity: 0;
        transform: scale(0.96);
        filter: brightness(0.1);
        transition: opacity 0.3s 0.2s, transform 2.5s 0.2s, filter 3.5s 0.2s;
        
        &.active {
          opacity: 1;
          transform: scale(1);
          filter: brightness(1);
        }
      }
      .model-poster {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        transition: 0.3s;

        &::before {
          content: "";
          background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/3d/poster.webp") center/contain no-repeat;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          width: 100%;
          height: 80%;
          transform: translateY(-50%);
          margin: 0 auto;
          filter: brightness(0.3) blur(2px);
        }
        &.inactive {
          opacity: 0;
          pointer-events: none;
        }
        &-body {
          position: absolute;
          left: 0;
          right: 0;
          top: 53%;

          i {
            display: block;
            margin: 0 auto 8px;
            width: 35px;
            height: 35px;
            background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/spinner.png") center/contain no-repeat;
            animation: spin 2.5s linear infinite;
            opacity: 0.8;
          }

          span {
            font: 400 14px/1 $font-zen;
            color: $white;
            text-align: center;
            display: block;
          }
        }
      }
    } */
}
.l-gundam5 .concept::before {
  content: "";
  position: absolute;
  top: -67.4359vw;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/concept-bg-sp.webp") bottom center/cover no-repeat;
  pointer-events: none;
  z-index: -1;
}
.l-gundam5 .concept .l-gundam5__container {
  position: relative;
}
.l-gundam5 .concept__img {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  pointer-events: none;
}
.l-gundam5 .concept__desc {
  font: 500 14px/30px "Zen Kaku Gothic New", sans-serif;
  letter-spacing: normal;
  color: #fff;
  text-align: justify;
  padding: 30px;
  background: rgba(0, 0, 0, .65);
  background-blend-mode: multiply;
  margin: 84.61538vw -20px 0 auto;
  position: relative;
  z-index: 2;
  width: 82.05128vw;
  max-width: 320px;
}
.l-gundam5 .concept__video-note {
  font-size: 9px;
  text-align: right;
  margin-top: 5px;
}
@media (min-width: 768px) {
  .l-gundam5 .concept {
    padding: 217px 0 123px 0;
    /* &__3d {
        model-viewer {
          //height: 90vh;
          height: calc(100vh - 77px - 110px);
        }
      } */
  }
  .l-gundam5 .concept::before {
    background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/concept-bg-pc.webp");
    top: -20.27778vw;
  }
  .l-gundam5 .concept__img {
    top: -140px;
    left: auto;
    width: 80%;
    max-width: 794px;
    overflow: hidden;
  }
  .l-gundam5 .concept__img img {
    -webkit-transform: translateX(120px);
            transform: translateX(120px);
  }
  .l-gundam5 .concept__desc {
    font-size: 16px;
    margin: 63px 0 0 0;
    width: 55%;
    max-width: none;
    padding: 50px;
  }
}
@media (min-width: 1024px) {
  .l-gundam5 .concept {
    padding-bottom: 243px;
    /* &__3d {
        height: 3000px;

        .model-poster {
          &-body {
            i {
              width: 40px;
              height: 40px;
              margin-bottom: 12px;
            }
            span {
              font-size: 16px;
            }
          }
        }
      } */
  }
  .l-gundam5 .concept__img {
    top: -190px;
  }
  .l-gundam5 .concept__desc {
    width: 50%;
    line-height: 40px;
  }
  .l-gundam5 .concept__video-note {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .l-gundam5 .concept__img img {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media (min-width: 1440px) {
  .l-gundam5 .concept__img {
    top: -205px;
    right: -88px;
  }
}
@media (min-width: 1920px) {
  .l-gundam5 .concept::before {
    background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/concept-bg-2560.webp");
    background-size: 3000px 100%;
    background-position: top center;
  }
}
.l-gundam5 .function {
  position: relative;
  padding: 0 0 453px;
  margin-bottom: -320px;
  z-index: 1;
}
.l-gundam5 .function::before {
  content: "";
  position: absolute;
  top: -76.92308vw;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
}
.l-gundam5 .function::before {
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/function-bg-sp.webp") top center/cover no-repeat;
}
.l-gundam5 .function__slider {
  position: relative;
  margin: 50px 0 0 0;
}
.l-gundam5 .function__slider .swiper-slide {
  border: 4px solid rgba(193, 139, 53, .6);
  background: rgba(0, 0, 0, .5);
  padding: 20px 16px;
  height: auto;
}
.l-gundam5 .function__slider-img {
  position: relative;
  opacity: 1 !important;
}
.l-gundam5 .function__slider-img img {
  opacity: 1 !important;
  aspect-ratio: 280/203;
}
.l-gundam5 .function__slider-img button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 43px;
  height: 43px;
  background: rgba(255, 255, 255, .5) url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-zoom.svg") center/20px 20px no-repeat;
  border: none;
  outline: none;
  cursor: pointer;
}
.l-gundam5 .function__slider-desc {
  font: 500 12px/1.6 "Zen Kaku Gothic New", sans-serif;
  margin-top: 20px;
}
.l-gundam5 .function__ar {
  margin: 90px -20px 0;
  padding: 60px 30px;
  background: rgba(0, 0, 0, .6);
  background-blend-mode: multiply;
}
.l-gundam5 .function__ar-title {
  font: 400 36px/1 "Post No Bills Colombo", sans-serif;
  text-align: center;
}
.l-gundam5 .function__ar-img {
  margin: 30px auto;
  width: 100%;
}
.l-gundam5 .function__ar-img-demo {
  aspect-ratio: 330/240;
}
.l-gundam5 .function__ar-img-qr {
  display: none;
}
.l-gundam5 .function__ar-desc {
  font: 500 14px/30px "Zen Kaku Gothic New", sans-serif;
  letter-spacing: normal;
}
.l-gundam5 .function__ar-note {
  display: block;
  font: 500 9px/20px "Zen Kaku Gothic New", sans-serif;
  margin-top: 8px;
}
.l-gundam5 .function__ar-btn {
  font: 500 14px/1 "Zen Kaku Gothic New", sans-serif;
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid currentColor;
  border-radius: 30px;
  margin: 30px auto 0;
}
.l-gundam5 .function__ar-btn i {
  width: 27px;
  height: 27px;
  margin-right: 15px;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-ar.svg") center/contain no-repeat;
}
.l-gundam5 .function__price {
  padding: 30px 0 50px 0;
}
.l-gundam5 .function__price-img {
  margin: 0 -20px;
  width: auto;
}
.l-gundam5 .function__price-img img {
  aspect-ratio: 130/129;
}
.l-gundam5 .function__price-title {
  margin: 36px auto 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.l-gundam5 .function__price-title span {
  font: 500 26px/1 "Zen Kaku Gothic New", sans-serif;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.l-gundam5 .function__price-title small {
  font: 500 13px/1 "Zen Kaku Gothic New", sans-serif;
  margin-bottom: 20px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.l-gundam5 .function__price-value {
  font: 500 21px/1 "Zen Kaku Gothic New", sans-serif;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 30px;
}
.l-gundam5 .function__price-value small {
  font: 500 11px/1 "Zen Kaku Gothic New", sans-serif;
}
.l-gundam5 .function__price-note {
  font: 500 11px/1 "Zen Kaku Gothic New", sans-serif;
  letter-spacing: 0.33px;
  display: block;
  text-align: center;
  margin-top: 15px;
}
.l-gundam5 .function__price-note--custom-1 {
  font-size: 8px;
  letter-spacing: 0.24px;
  margin-left: 3px;
}
.l-gundam5 .function__price-note--custom-2 {
  font-size: 9px;
  letter-spacing: 0.27px;
}
.l-gundam5 .function #gallery_img_popup {
  display: none;
}
@media (min-width: 768px) {
  .l-gundam5 .function {
    margin-bottom: -324px;
  }
  .l-gundam5 .function::before {
    background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/function-bg-pc.webp");
    background-size: 100% auto;
    top: -28.88889vw;
  }
  .l-gundam5 .function__slider {
    margin-top: 72px;
  }
  .l-gundam5 .function__slider .swiper-slide {
    padding: 37px 33px 33px;
    max-width: 598px !important;
  }
  .l-gundam5 .function__slider-img button {
    width: 80px;
    height: 80px;
    background-size: 42px 42px;
  }
  .l-gundam5 .function__slider-desc {
    font-size: 16px;
    line-height: 28px;
    margin-top: 34px;
  }
  .l-gundam5 .function__ar {
    margin: 150px 0 0;
    display: grid;
    grid-template-areas: "temp1 img" "title img" "desc img" "temp2 img";
    grid-template-columns: auto 350px;
    grid-template-rows: 1fr auto auto 1fr;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .l-gundam5 .function__ar-title {
    grid-area: title;
    text-align: left;
    margin: 0 0 40px 0;
  }
  .l-gundam5 .function__ar-img {
    grid-area: img;
    margin: 0;
    position: relative;
  }
  .l-gundam5 .function__ar-img-qr {
    display: block;
    background-color: #fff;
    padding: 7px;
    position: absolute;
    width: 30%;
    height: auto;
    max-width: 145px;
    right: -20px;
    bottom: -30px;
  }
  .l-gundam5 .function__ar-desc {
    grid-area: desc;
  }
  .l-gundam5 .function__ar-btn {
    display: none;
  }
  .l-gundam5 .function__price {
    padding: 0;
    margin-top: 71px;
    position: relative;
  }
  .l-gundam5 .function__price::before {
    content: "";
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: -100px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(35, 12, 12, 0)), color-stop(42%, rgba(35, 12, 12, .6)), color-stop(67.5%, rgba(35, 12, 12, .6)), to(rgba(35, 12, 12, 0)));
    background: linear-gradient(180deg, rgba(35, 12, 12, 0) 0%, rgba(35, 12, 12, .6) 42%, rgba(35, 12, 12, .6) 67.5%, rgba(35, 12, 12, 0) 100%);
    z-index: -1;
  }
  .l-gundam5 .function__price .l-gundam5__container {
    display: grid;
    grid-template-areas: "img temp1" "img title" "img price" "img btn" "img note" "img temp2";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto auto auto 1fr;
    -webkit-column-gap: 60px;
       -moz-column-gap: 60px;
            column-gap: 60px;
    padding: 0 60px;
  }
  .l-gundam5 .function__price-img {
    grid-area: img;
    margin: 0;
  }
  .l-gundam5 .function__price-img img {
    aspect-ratio: 314/407;
  }
  .l-gundam5 .function__price-title {
    grid-area: title;
    margin-top: 100px;
  }
  .l-gundam5 .function__price-value {
    grid-area: price;
  }
  .l-gundam5 .function__price-note {
    grid-area: note;
    font-size: 15px;
    letter-spacing: 0.45px;
    margin-top: 22px;
    line-height: 1.35;
  }
  .l-gundam5 .function__price-note--custom-1 {
    font-size: inherit;
  }
  .l-gundam5 .function__price-note--custom-2 {
    font-size: inherit;
  }
  .l-gundam5 .function__price-note--custom-3 {
    font-size: 13px;
    letter-spacing: 0.39px;
  }
  .l-gundam5 .function__price .l-gundam5__btn {
    grid-area: btn;
    max-width: 400px;
  }
}
@media (min-width: 1024px) {
  .l-gundam5 .function__ar {
    grid-template-columns: auto 395px;
    padding: 60px 62px;
    -webkit-column-gap: 58px;
       -moz-column-gap: 58px;
            column-gap: 58px;
  }
  .l-gundam5 .function__ar-title {
    font-size: 40px;
    letter-spacing: 4px;
  }
  .l-gundam5 .function__ar-desc {
    font-size: 16px;
    line-height: 40px;
  }
  .l-gundam5 .function__ar-note {
    font-size: 12px;
    margin-top: 10px;
  }
  .l-gundam5 .function__ar-img-qr {
    width: 145px;
    right: -32px;
    bottom: -30px;
  }
  .l-gundam5 .function__price .l-gundam5__container {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 931px;
  }
  .l-gundam5 .function__price-title span {
    font-size: 30px;
  }
  .l-gundam5 .function__price-title small {
    font-size: 16px;
  }
  .l-gundam5 .function__price-value {
    font-size: 24px;
    margin-bottom: 60px;
  }
  .l-gundam5 .function__price-value small {
    font-size: 14px;
  }
}
@media (min-width: 1296px) {
  .l-gundam5 .function__price::before {
    top: 100px;
  }
  .l-gundam5 .function__price-title {
    margin-top: 170px;
  }
  .l-gundam5 .function__price-note {
    white-space: nowrap;
  }
  .l-gundam5 .function__price .l-gundam5__container {
    grid-template-columns: 471px 400px;
  }
}
@media (max-width: 1919px) {
  .l-gundam5 .function__slider .swiper-slide {
    width: 82.05128vw !important;
  }
}
@media (min-width: 1920px) {
  .l-gundam5 .function::before {
    background-image: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/function-bg-2560.webp");
    background-size: 3000px auto;
    top: -506px;
  }
}
.l-gundam5 .spec {
  margin-bottom: 100px;
  position: relative;
  z-index: 3;
}
.l-gundam5 .spec__list {
  padding: 0 10px;
  margin-top: 50px;
}
.l-gundam5 .spec__list p, .l-gundam5 .spec__list span {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
}
.l-gundam5 .spec__list > ul li + li {
  margin-top: 25px;
}
.l-gundam5 .spec__list > ul li .note {
  font-size: 9px;
}
.l-gundam5 .spec__list .spec-download {
  margin-top: 25px;
}
.l-gundam5 .spec__list .spec-download a {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: relative;
  padding-left: 25px;
}
.l-gundam5 .spec__list .spec-download a:before {
  content: "";
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon_pdf.webp") left/contain no-repeat;
  display: inline-table;
  width: 16px;
  height: 20px;
  top: 3px;
  left: 0;
  position: absolute;
  padding-left: 25px;
}
.l-gundam5 .spec__list .spec-download a span {
  color: #B61114;
  border-bottom: 1px solid #B61114;
}
.l-gundam5 .spec__note {
  border: 1px solid #999999;
  padding: 30px 25px;
  margin-top: 60px;
  position: relative;
  z-index: 3;
  background: rgba(0, 0, 0, .6);
  background-blend-mode: multiply;
}
.l-gundam5 .spec__note-section {
  color: #999999;
}
.l-gundam5 .spec__note-section h2 {
  font-size: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
}
.l-gundam5 .spec__note-section p {
  margin-top: 20px;
  letter-spacing: 0;
  line-height: 30px;
}
.l-gundam5 .spec__note-section p small {
  font-weight: inherit;
  font-size: 12px;
  color: #fff;
}
@media (min-width: 768px) {
  .l-gundam5 .spec {
    margin-bottom: 150px;
  }
  .l-gundam5 .spec__list {
    padding: 0;
    max-width: 100%;
    width: 668px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-gundam5 .spec__list > ul li {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  .l-gundam5 .spec__list > ul li .note {
    font-size: 12px;
  }
  .l-gundam5 .spec__note {
    max-width: 100%;
    width: 668px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 40px;
  }
}
@media (min-width: 1024px) {
  .l-gundam5 .spec__list p, .l-gundam5 .spec__list span {
    font-size: 16px;
  }
}
.l-gundam5 .banner__container {
  position: relative;
}
.l-gundam5 .banner__container picture img {
  aspect-ratio: 39/73;
}
.l-gundam5 .banner__detail {
  position: absolute;
  top: 60vw;
  left: 0;
  bottom: 0;
  right: 0;
}
.l-gundam5 .banner__detail .banner-category {
  background-color: #66430B;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}
.l-gundam5 .banner__detail .banner-category p {
  font-size: 13px;
  letter-spacing: 3.9px;
}
.l-gundam5 .banner__detail .btn-banner {
  margin-top: 36px;
  background-color: #c18b35;
  border-radius: 8px;
  max-width: 330px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.l-gundam5 .banner__detail .btn-banner a {
  height: 60px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-right.svg");
  background-size: 9px;
  background-repeat: no-repeat;
  background-position: right 36px center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.l-gundam5 .banner__detail .logo-zeon-owndays img {
  width: 72.5641vw;
  margin-left: auto;
  margin-right: auto;
}
.l-gundam5 .banner__detail .sub-zeon-owndays {
  text-align: center;
  font-size: 4.61538vw;
  letter-spacing: 2.88px;
  font-family: "Zen Old Mincho", serif;
  margin-top: 8px;
}
@media (min-width: 768px) {
  .l-gundam5 .banner {
    padding: 0 0 59px;
  }
  .l-gundam5 .banner__container picture img {
    aspect-ratio: 1439/528;
  }
  .l-gundam5 .banner__detail {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    bottom: auto;
  }
  .l-gundam5 .banner__detail .banner-category {
    margin-bottom: 32px;
  }
  .l-gundam5 .banner__detail .banner-category p {
    font-size: 16px;
    letter-spacing: 4.8px;
  }
  .l-gundam5 .banner .btn-banner {
    max-width: 250px;
    margin-top: 26px;
  }
  .l-gundam5 .banner .btn-banner a {
    height: 50px;
  }
  .l-gundam5 .banner .btn-banner:hover {
    -webkit-animation: gundam_shine2 2s;
            animation: gundam_shine2 2s;
  }
  .l-gundam5 .banner .logo-zeon-owndays img {
    width: 31.25vw;
  }
  .l-gundam5 .banner .sub-zeon-owndays {
    font-size: 1.25vw;
    line-height: 2.70833vw;
    margin-top: 13px;
    letter-spacing: 2.88px;
    font-size: 13px;
  }
  .l-gundam5 .banner .sub-zeon-owndays br {
    display: none;
  }
}
@media (min-width: 1024px) {
  .l-gundam5 .banner .logo-zeon-owndays img {
    width: 28.26389vw;
  }
  .l-gundam5 .banner .btn-banner {
    margin-top: 35px;
    max-width: 350px;
  }
  .l-gundam5 .banner .btn-banner a {
    font-size: 18px;
    height: 80px;
  }
  .l-gundam5 .banner .sub-zeon-owndays {
    font-size: 18px;
  }
}
@media (min-width: 1300px) {
  .l-gundam5 .banner .btn-banner {
    max-width: 400px;
  }
}
.l-gundam5 .share {
  margin: 60px 0 100px;
  padding: 43px 0 68px;
  background-color: #150707;
}
.l-gundam5 .share h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 110%;
  color: #fff;
  font-family: "Post No Bills Colombo", sans-serif;
  text-align: center;
  letter-spacing: 3px;
}
.l-gundam5 .share .list-sns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 23px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.l-gundam5 .share .list-sns li {
  margin: 0 20px;
}
.l-gundam5 .share .list-sns li:first-child {
  margin-left: 0;
}
.l-gundam5 .share .list-sns li:last-child {
  margin-right: 0;
}
.l-gundam5 .share .list-sns li:nth-child(2) a {
  width: 28px;
  height: 28px;
}
.l-gundam5 .share .list-sns a {
  width: 35px;
  height: 35px;
}
.l-gundam5 .share .list-sns a img {
  aspect-ratio: 1/1;
}
@media (min-width: 768px) {
  .l-gundam5 .share {
    margin-bottom: 130px;
    padding: 0;
    height: 100px;
    background-color: #150707;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .l-gundam5 .share h2 {
    font-size: 40px;
    padding-top: 5px;
    letter-spacing: 4px;
  }
  .l-gundam5 .share__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 57px;
  }
  .l-gundam5 .share .list-sns {
    margin-top: 0;
    gap: 36px;
  }
  .l-gundam5 .share .list-sns li {
    margin: 0;
  }
}
.l-gundam5 .c-modal-video {
  display: block;
  position: relative;
  width: auto;
  margin: 71px -20px 0;
  -webkit-transition: none;
  transition: none;
}
.l-gundam5 .c-modal-video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 16.15385vw;
  max-width: 86px;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/video-play.svg") center/contain no-repeat;
  z-index: 2;
  pointer-events: none;
}
.l-gundam5 .c-modal-video--iframe {
  padding-top: calc(56.25% + 22px);
  overflow: hidden;
}
.l-gundam5 .c-modal-video picture {
  opacity: 1 !important;
  overflow: hidden;
}
.l-gundam5 .c-modal-video picture img {
  width: 100%;
  opacity: 1 !important;
  aspect-ratio: 107/51;
}
.l-gundam5 .c-modal-video iframe {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .l-gundam5 .c-modal-video--iframe:hover iframe {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .l-gundam5 .c-modal-video picture:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .l-gundam5 .c-modal-video img,
  .l-gundam5 .c-modal-video iframe {
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
  }
}
@media (min-width: 1024px) {
  .l-gundam5 .c-modal-video {
    margin: 300px 0 0 0;
  }
  .l-gundam5 .c-modal-video--iframe {
    padding-top: 56.25%;
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes gundam_move {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes gundam_move {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes gundam_shine {
  0% {
    background: #B31111;
  }
  8% {
    background: #FF8383;
  }
  100% {
    background: #B31111;
  }
}
@keyframes gundam_shine {
  0% {
    background: #B31111;
  }
  8% {
    background: #FF8383;
  }
  100% {
    background: #B31111;
  }
}
@-webkit-keyframes gundam_shine2 {
  0% {
    background-color: #c18b35;
  }
  8% {
    background-color: #FFD086;
  }
  100% {
    background-color: #c18b35;
  }
}
@keyframes gundam_shine2 {
  0% {
    background-color: #c18b35;
  }
  8% {
    background-color: #FFD086;
  }
  100% {
    background-color: #c18b35;
  }
}
@media (max-width: 767px) {
  .u-pc-only {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .u-sp-only {
    display: none !important;
  }
}
.fancybox-slide {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.fancybox-gallery .fancybox-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
}
.fancybox-gallery .fancybox-stage-bottom {
  width: 100%;
  padding-top: 72.5641%;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.fancybox-gallery .fancybox-slide {
  padding: 0;
  top: auto;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  pointer-events: none;
}
.fancybox-gallery .fancybox-content {
  position: static !important;
  -webkit-transform: translate(0) !important;
          transform: translate(0) !important;
  max-width: 640px !important;
  max-height: 465px;
  cursor: auto !important;
  pointer-events: auto;
}
.fancybox-gallery .fancybox-image {
  position: static !important;
}
.fancybox-gallery .fancybox-caption {
  padding: 0;
  margin: 20px auto 0;
  position: static;
  background: none;
  color: #fff;
  max-width: 640px;
  pointer-events: auto;
}
.fancybox-gallery .fancybox-caption__body {
  font: 500 12px/1 "Zen Kaku Gothic New", sans-serif;
  text-align: left;
  padding: 0 20px 0;
  display: table;
}
.fancybox-gallery .fancybox-navigation {
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 90px;
}
.fancybox-gallery .fancybox-navigation .fancybox-button {
  position: static;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #666;
  top: calc(100% + 123px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 7px;
  pointer-events: auto;
  cursor: pointer;
}
.fancybox-gallery .fancybox-navigation .fancybox-button i {
  display: inline-block;
  width: 12px;
  height: 22px;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-right-black.svg") center/contain no-repeat;
}
.fancybox-gallery .fancybox-navigation .fancybox-button--arrow_left {
  left: calc(50% - 67px);
}
.fancybox-gallery .fancybox-navigation .fancybox-button--arrow_left i {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.fancybox-gallery .fancybox-navigation .fancybox-button--arrow_right {
  right: calc(50% - 67px);
}
.fancybox-gallery .fancybox-infobar {
  text-align: center;
  position: static;
  height: auto;
  padding: 0;
  margin-top: 5px;
  line-height: 1;
  pointer-events: auto;
  -webkit-transform: none !important;
          transform: none !important;
}
.fancybox-gallery .fancybox-infobar span {
  font: 500 12px/1 "Zen Kaku Gothic New", sans-serif;
}
.fancybox-gallery .fancybox-button--close {
  background-color: rgba(0, 0, 0, .6);
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.fancybox-gallery .fancybox-button--close i {
  width: 20px;
  height: 20px;
  background: url("https://www.owndays.com/images/specials/products/gundam5-zakuhead/icon-close.svg") center/contain no-repeat;
}
@media (min-width: 640px) {
  .fancybox-gallery .fancybox-stage-bottom {
    padding-top: 465px;
  }
  .fancybox-gallery .fancybox-navigation {
    margin-top: 70px;
  }
  .fancybox-gallery .fancybox-navigation .fancybox-button {
    width: 80px;
    height: 80px;
  }
  .fancybox-gallery .fancybox-navigation .fancybox-button i {
    width: 15px;
    height: 28px;
  }
  .fancybox-gallery .fancybox-infobar > span {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .fancybox-gallery .fancybox-caption {
    margin-top: 30px;
  }
  .fancybox-gallery .fancybox-caption__body {
    font-size: 16px;
    padding: 0;
  }
}
@media (max-width: 1152px) {
  .breadcrumbContainer_wrapper {
    -webkit-transform: translate(20px, 4px) !important;
            transform: translate(20px, 4px) !important;
  }
}
.fancybox__content {
  width: 100% !important;
  max-width: 1395px !important;
  height: 763px !important;
  padding: 40px !important;
}
@media (max-width: 767px) {
  .fancybox__content {
    padding: 0px !important;
    height: 500px !important;
  }
}
.is-close-btn {
  background: rgba(30, 30, 30, .6);
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: fixed !important;
  -webkit-transition: color .2s;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
  top: 0 !important;
}
.is-close-btn:hover {
  background: rgba(30, 30, 30, .6) !important;
}
.yt-modal__content {
  width: 80vw;
  height: 80vh;
  margin: 0 auto;
}
.yt-modal__overlay {
  background-color: rgba(30, 30, 30, .9) !important;
}
.c-modal-video {
  cursor: pointer;
}
