.auth-panel {
  position: relative;
}

.auth-panel:hover .auth-panel__dropdown-menu {
  display: block;
}

.auth-panel:hover .auth-panel__dropdown-toggler {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.auth-panel__dropdown-menu {
  display: none;
  padding: 10px;
  margin: 0;
  position: absolute;
  z-index: 21;
}

.auth-panel__dropdown-menu--top {
  bottom: 100%;
}

.auth-panel__dropdown-menu--bottom {
  top: 100%;
}

.auth-panel__dropdown-menu--center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.auth-panel__dropdown-menu--left {
  right: -30px;
}

.auth-panel__dropdown-menu--right {
  left: -30px;
}

.auth-panel__dropdown-content {
  width: 170px;
  background: #fff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
}

.auth-panel__dropdown-list {
  display: block;
  padding: 10px;
  color: #000000;
  -webkit-transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.auth-panel__dropdown-list:hover {
  color: #000000;
  background-color: #fafafa;
  opacity: 30%;
}

.auth-panel__dropdown-toggler {
  margin-left: 3px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
