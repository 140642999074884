.upload-prescription-sidebar {
  overflow: hidden;

  &__product {
    width: 100%;
    padding: 24px 32px 16px 32px;
    display: flex;
    gap: 32px;
    align-items: center;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #000042;
      line-height: 20px;

      &__order-id {
        font-size: 14px;
        font-family: 'Founders Grotesk', sans-serif;
      }

      &__title {
        font-size: 14px;
        font-family: 'NeuzeitGroReg', sans-serif;
      }

      &__description {
        font-size: 12px;
        font-family: 'NeuzeitGroReg', sans-serif;
      }
    }

    .od-pc-image-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .od-pc-image {
        height: 128px;
      }
    }
  }

  &__cross {
    display: none;
  }

  &__order-id {
    padding: 0 34px 24px 34px;
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.28px;
    color: #444444b2;
  }

  &__title {
    padding: 0 34px 24px 34px;
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.48px;
    color: #444;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 64px 34px 16px 34px;

    &__back-btn {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        height: 24px;
        opacity: 0.7;
      }

      &__text {
        font-size: 16px;
        color: #444444b2;
        font-family: 'NeuzeitGroReg', sans-serif;
      }
    }

    &__close {
      cursor: pointer;

      img {
        height: 24px;
        opacity: 0.7;
      }
    }

    &__title {
      font-size: 24px;
      line-height: 36px;
      color: #000042;
      font-family: 'Founders Grotesk', sans-serif;
    }
  }

  &__content {
    padding: 28px 34px;
    height: calc(100vh - 108px);
    overflow: scroll;
    padding-bottom: 200px;

    .upload-prescription-sidebar__collapaseMenu {
      background: #fff;
      border-radius: 15px;
      user-select: none;
      margin-bottom: 32px;
      border: 1px solid rgba(84, 96, 115, 0.1);
      padding: 28px 16px;

      &-title {
        font-family: 'Founders Grotesk', sans-serif;
        color: #444;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        & > div:first-child {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
      &-body {
        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 16px;
          color: #444;
          font-family: 'NeuzeitGroReg', sans-serif;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: -0.4px;
          cursor: pointer;
          background-color: #fafafa;
          margin-top: 16px;
          border-radius: 10px;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 24px 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;

    &__price {
      color: #444;
      line-height: 24px;
      font-family: 'NeuzeitGroReg', sans-serif;
      font-size: 24px;

      span {
        font-family: 'Founders Grotesk', sans-serif;
        font-size: 32px;
      }
    }

    button {
      cursor: pointer;
    }
  }
}

.saved-prescription-sidebar-panel {
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;

  &__img {
    margin-top: 24px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px 32px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #fafafa;

    button {
      cursor: pointer;
    }
  }
  .saved-prescription-radio {
    background-color: #fafafa;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    cursor: pointer;
    &__left {
      &_name {
        color: #444;
        font-family: 'Founders Grotesk', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.24px;
      }
      &_date {
        color: rgba(68, 68, 68, 0.7);
        font-family: 'Founders Grotesk', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: -0.24px;
      }
    }
    &__right {
      &_check_uncheck {
        width: 18px;
      }
    }
  }
}

.upload-prescription-sidebar-panel {
  &__heading-footer {
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    list-style: disc;
    list-style-type: disc;
    margin-bottom: 8px;
    color: #333368;
  }

  &__list {
    padding-left: 12px;
    &_item {
      font-family: 'Founders Grotesk', sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.32px;
      list-style: disc;
      list-style-type: disc;
      padding-left: 16px;
      margin-bottom: 20px;
      color: #333368;
    }
  }

  &__uploadBtn {
    button {
      display: flex;
      align-items: center;
      color: #000042;
      background-color: #fafafa;
      border: none;
      cursor: pointer;
      margin: 16px 0 24px 0;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px 32px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;

    button {
      cursor: pointer;
    }
  }
}
