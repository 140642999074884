.od-membership-statuses-container {
  width: 100%;
  padding: 0 200px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }
}
