.footer-senichisaku {
  padding-bottom: 200px;
}

.footer-senichisaku__img {
  margin-bottom: 30px;
}

.footer-senichisaku__title {
  font-size: 24px;
  margin-bottom: 50px;
  line-height: 1.6;
}

.footer-senichisaku__text {
  margin-bottom: 70px;
}

.footer-senichisaku picture {
  display: block;
}

.footer-senichisaku picture img {
  width: 100%;
  height: auto;
}

@media (min-width: 1024px) {
  .footer-senichisaku {
    padding-bottom: 270px;
  }
  .footer-senichisaku__img {
    margin-bottom: 70px;
  }
  .footer-senichisaku__title {
    font-size: 32px;
    margin-bottom: 100px;
    line-height: 1.9;
  }
  .footer-senichisaku__text {
    margin-bottom: 130px;
  }
}
