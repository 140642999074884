.servicers__nav {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;

  &-link {
    font-family: 'Founders Grotesk', sans-serif;
    line-height: 1;
  }
  &--mobile {
    margin-top: 20px;
    .servicers__nav {
      &-list {
        display: flex;
        flex-direction: column;
        padding: 0 0 10px;
        margin: 0;
        border: 1px solid #e3e3e3;
        list-style-type: none;
      }
      &-item {
        &:first-child {
          margin-bottom: 10px;
          .servicers__nav-link {
            justify-content: center;
            border-bottom: 1px dashed #e3e3e3;
          }
        }
        &:not(:first-child) {
          .servicers__nav-link {
            &::before {
              content: '>';
              margin-right: 5px;
              color: #000;
            }
          }
        }
      }
      &-link {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        position: relative;
        font-size: 15px;
        font-weight: 400;
        color: #0094c8;
      }
    }
  }
  &--desktop {
    background-color: #333;
    .servicers__nav {
      &-list {
        display: flex;
        width: 90%;
        max-width: 1296px;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
      }
      &-item {
        flex: 1 1 auto;
        border-left: 1px solid #444;
        &:last-child {
          border-right: 1px solid #444;
        }
      }
      &-link {
        font-size: 15px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        height: 40px;
        cursor: pointer;
        transition: all 0.3s ease;
        &.active {
          background-color: #777;
        }
        &:hover {
          background-color: #777;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &--desktop {
      display: none;
    }
  }
  @media (min-width: 768px) {
    &--mobile {
      display: none;
    }
  }
}
