.about-referral {
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
   

    &__title {
        margin-top: 37px;
        margin-bottom: 100px;
        text-align: center;
        color: #000;
        font-size: 48px;
        font-style: normal;
        line-height: 60px;
    }
}

.about-referral-hero {
    display: flex;
    margin-bottom: 100px;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }

    &__subtitle {
        margin-bottom: 10px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    &__title {
        margin-bottom: 10px;
        color: #000;
        font-size: 50px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -1px;
    }

    &__button {
        margin-top: 40px;
        color: #FFF;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.4px;
        text-transform: capitalize;
    }
}

.about-referral-benefits {
    display: grid;
    padding-bottom: 50px;
    grid-template-columns: repeat(2, 1fr);
    gap: 91px;
    @media only screen and (max-width: 1200px) {
       display: flex;
       flex-direction: column;
       gap: 30px;
      }

    &__block {
        padding: 20px;
        background-color: #FAFAFA;
    }

    &__title {
        margin-bottom: 30px;
        color: #000;
        font-size: 20px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.4px;
    }

    &__beneficiary {
        text-transform: capitalize;
    }

    &__list {
        padding-left: 25px;
        list-style-type: unset;
    }

    &__benefit {
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        list-style-type: unset;
    }
}

.about-referral-conditions {
    &__list {
        padding-left: 25px;
        list-style-type: unset;
    }

    &__condition {
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        list-style-type: unset;
    }
}
