$white-f7: #F7F7F7;

.body-freaks-store {
  &__lineup {
    padding-bottom: 80px;
    background-color: $white-f7;

    @media (min-width: 1024px) {
      padding-bottom: 120px;

      &__frame-section {
        margin-top: 80px;
      }
    }
  }
}
