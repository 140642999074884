.od-payment-methods__title {
  padding-top: 28px;
  color: #444;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-payment-methods__title-backBTN {
  cursor: pointer;
  margin-bottom: -11px;
  margin-right: 15px;
}

.od-payment-methods-active {
  display: grid;
  padding: 30px 16px;
  margin-top: 16px;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  background-color: #fff;
}

.od-payment-methods-options {
  height: 100%;
  border-right: 1px dashed rgba(84, 96, 115, .1);
}

.od-payment-methods-options__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}

.od-payment-methods-options__option-label {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.od-payment-methods-options .atom-payment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 4px;
}

.od-payment-methods-options .atom-payment__title {
  overflow: hidden;
  color: #444;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.28px;
}

.od-payment-methods-options .atom-payment__description {
  overflow: hidden;
  color: rgba(68, 68, 68, 0.69804);
  text-overflow: ellipsis;
  font-size: 12px;
  letter-spacing: -0.24px;
}

.od-payment-methods-options__others {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 16px 0 52px;
  margin-top: 12px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.od-payment-methods-checkbox {
  position: relative;
}

.od-payment-methods-checkbox__check {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.od-payment-methods-checkbox__check--active, .od-payment-methods-checkbox__check:hover {
  opacity: 1;
}

.od-payment-methods-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  border-top: 1px dashed rgba(84, 96, 115, .1);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  background-color: #fff;
}

.od-payment-methods-more__method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 46px;
  height: 32px;
  border: 1px solid rgba(84, 96, 115, .1);
  border-radius: 4px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-payment-methods-options__radio__check {
  height: 13px;
  width: 13px;
}
