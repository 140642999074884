$pd: 27px;
$container-max-w: calc(1120px + #{$pd * 2});

$base-img-url: 'https://static.lenskart.com/media/owndays/img/ortho-k-lens';

$color-light: #f7f9fc;
$color-white: #fff;

$font-din: din-2014, sans-serif;
$font-nato: 'Noto Sans', sans-serif;

.header-ortho-k-lens {
  &__top {
    &__img {
      position: relative;
      margin-left: -$pd;
    }
    &__title {
      position: absolute;
      bottom: 69px;
      left: 0;
      right: 0;
      width: 74%;
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      &__title {
        top: 50%;
        bottom: auto;
        left: auto;
        right: 40px;
        width: 352px;
        transform: translateY(-50%);
      }
    }

    @media (min-width: 1024px) {
      &__img {
        margin-left: calc((#{$container-max-w} - #{$pd} * 2 - 100vw) / 2)
      }

      &__title {
        right: 160px;
        width: 462px;
      }
    }
  }

  &__intro {
    margin-top: 79px;

    .base-ortho-k-lens__title {
      margin-bottom: 29px;
      grid-area: title;
    }

    p {
      font-size: 14px;
      font-weight: 600 !important;
      grid-area: text;
      margin: 0;
    }

    &__img {
      display: block;
      margin: 48px auto 0;
      width: 89.6%;
      max-width: 470px;
      grid-area: img;
    }

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      grid-template-areas:
      "title img"
      "text img";
      column-gap: 20px;

      &__img {
        margin-right: 0;
      }
    }

    @media (min-width: 1024px) {
      margin-top: 168px;
      grid-template-columns: 1fr 449px;

      p {
        font-size: 16px;
      }

      &__img {
        margin-top: 0;
        width: 100%;
        max-width: none;
      }
    }

    @media (min-width: 1296px) {
      column-gap: 112px;
    }
  }

  &__video {
    margin: 61px auto 0;

    .base-ortho-k-lens__container--sub {
      @media (max-width: 430px) {
        height: 214.45px;
      }

      @media (max-width: 390px) {
        height: 191.63px;
      }
    }

    a {
      position: relative;

      @media (min-width: 1024px) {
        &:hover {
          img {
            transition: 0.5s;
            opacity: 0.6;
          }
        }
      }
    }

    &__iframe-container {
      width: 100%;
      width: 100%;
      height: 100%;
      display: inline-block;
      aspect-ratio: 16/9;
    }

    @media (min-width: 768px) {
      margin-top: 82px;
      a {
        position: relative;

        &::before {
          background-size: 86px auto;
        }
      }
    }
  }

  &__how-works {
    margin-top: 70px;
    padding: 70px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100vw;
      right: -100vw;
      bottom: 0;
      background-color: $color-light;
      padding-top: 70px;
      z-index: -1;
    }

    .base-ortho-k-lens__container--sub {
      max-width: 1022px;
      margin: 0 auto;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    &__list {
      margin: 67px #{-$pd} calc(30px * -1 + 38px);
      padding: 37px $pd 30px;
      display: flex;
      flex-wrap: nowrap;
      counter-reset: how-works-counter;
      list-style-type: none;

      @media (max-width: 1023px) {
        overflow: overlay;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0 calc(100% - 30px));
      }

      > li {
        width: 80%;
        max-width: 213px;
        flex: none;
        background-color: $color-white;
        border-radius: 28px;
        padding: 44px 10px 30px;
        position: relative;
        counter-increment: how-works-counter;
        list-style-type: none;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -20px;
          width: 15px;
          height: 15px;
          transform: translateY(calc(-50% - 10px));
          background: url("#{$base-img-url}/icon-caret-right.svg") center/contain no-repeat;
        }

        &:first-child {
          &::before {
            content: none;
          }
        }

        +li {
          margin-left: 26px;
        }
      }

      &-number {
        font: 700 27px/1 $font-din;
        font-weight: 700 !important;
        width: 74px;
        height: 74px;
        border-radius: 50%;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -80px auto 10px;
        &::before {
          content: counter(how-works-counter);
        }
      }

      &-img {
        display: block;
        margin: 0 auto;
      }

      &-title {
        font: 700 19px/1.6 $font-nato;
        font-weight: 700 !important;
        text-align: center;
        margin: 20px 0 0 0;

        small {
          font-size: 15px;
          font-weight: 300 !important;
          letter-spacing: 0.01em;
          line-height: 1.6;
          display: block;
        }
      }
    }

    &__des {
      font-size: 13px;
      font-weight: normal !important;
      margin: 0;
    }

    @media (min-width: 1024px) {
      margin-top: 134px;
      padding: 108px 0 120px;
      &__list {
        margin: 55px 0 66px 0;
        padding: 0;
        justify-content: center;
      }

      &__des {
        font-size: 15px;
      }
    }

    @media (min-width: 1296px) {
      &__list {
        justify-content: space-between;

        > li {
          &::before {
            left: -33px;
          }
          + li {
            margin-left: 57px;
          }
        }
      }
    }
  }
}
