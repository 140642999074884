$font-founders: "Founders Grotesk", sans-serif;

.body-eye-camp {
  &__message {
    width: 100%;
    height: auto;
    max-width: 1152px;
    margin: 0 auto;
    padding: 0 5%;

    &--logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 7.5px;
      padding: 20px 15px 30px;
      border: 1px solid black;
      text-align: center;

      img {
        width: 48px;
        height: 48px;
        margin: 0 auto;
      }

      h2 {
        font-family: $font-founders;
        font-size: 24px;
        font-weight: 400 !important;
        margin: 10px 0 0 0;
        line-height: 1.6;
        letter-spacing: 1px;
        color: black;

        span {
          display: block;
          font-size: 13px;
          font-weight: 700 !important;
          margin-top: 10px;
        }
      }
    }

    &--text {
      margin: 25px 7.5px 0;
      order: 2;

      h3, p {
        font-family: $font-founders;
        color: black;
      }

      h3 {
        font-size: 16px;
        font-weight: 700 !important;
        line-height: 1.8;
        margin: 0;
      }

      p {
        margin: 20px 0 0 0;
        font-size: 15px;
        font-weight: normal !important;
        letter-spacing: 1px;
        line-height: 2;
      }
    }

    &--image {
      width: 100%;
      max-width: 406px;
      flex-basis: auto;
      height: auto;
      padding-right: 7.5px;
      padding-left: 7.5px;
      order: 1;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    @media (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;

      &--logo {
        width: 100%;
        max-width: 406px;
        height: 406px;
        margin: 0;

        img {
          width: 96px;
          height: 96px;
          margin: 0 auto;
        }

        h2 {
          font-size: 24px;
          margin-top: 30px;
        }
      }

      &--text {
        margin: 0 0 0 60px;
      }

      &--image {
        margin-left: 60px;
        order: 2;
      }
    }
  }

  &__eye-camp {
    position: relative;

    &__title {
      background-color: black;
      padding: 32px 23px;
      width: 162px;
      max-width: 762px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      text-align: center;
      color: white;
      margin: 0 auto;
  
      h3 {
        width: 116px;
        height: auto;
        font-family: $font-founders;
        font-size: 24px;
        font-weight: 700 !important;
        line-height: 1;
        letter-spacing: 1px;
        margin: 0;
  
        span {
          display: block;
          font-size: 13px;
          font-weight: 400 !important;
          line-height: 1.6;
          margin-top: 6px;
        }
      }
    }

    &__message {
      @media (min-width: 768px) {
        margin-left: 0 !important;
      }
    }
  }
}
