$clear-contactlens: #59b2e7;
$color-contactlens: #71475e;

.htu {
  color: black;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.8px;
  width: 100%;
  overflow-x: hidden;
  img {
    display: block;
    width: 100%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }
  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1152px + 40px);
    &--lg {
      max-width: calc(1300px + 40px);
    }
    &--sm {
      max-width: calc(900px + 40px);
    }
  }
  &__header {
    background-color: #f4f4f4;
    padding-block: 80px;
    h1 {
      margin: 0 0 50px;
      text-align: center;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1.6px;
      padding-bottom: 16px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 60px;
        height: 4px;
      }
    }
    p {
      margin: 0 0 48px;
    }
    ul {
      display: grid;
      gap: 8px;
      li {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 16px;
        position: relative;
        cursor: pointer;
        h3 {
          font-size: inherit;
          font-weight: inherit;
          margin: 0;
          display: flex;
          align-items: flex-end;
          gap: 8px;
          strong {
            font-family: inherit;
            font-size: 20px;
          }
        }
        img {
          max-width: 150px;
          margin: 16px auto;
        }
        svg {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
        }
      }
    }
  }
  &__steps {
    padding-bottom: 136px;
    &-sec {
      padding-top: 136px;
      h2 {
        margin: 0 0 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        strong {
          font-family: inherit;
          font-size: 40px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      ul {
        display: grid;
        gap: 16px;
        li {
          border: 1px solid #e3e3e3;
          img {
            max-width: 150px;
            margin-inline: auto;
          }
          div {
            padding: 16px 32px;
            h3 {
              margin: 0;
              font-size: 20px;
              font-weight: 700;
              line-height: normal;
              display: flex;
              align-items: flex-end;
              gap: 8px;
              strong{
                font-family: inherit;
                font-size: 30px;
                font-weight: 700;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }

  &--clear-contactlens{
    .htu__header h1::before{
      background-color: $clear-contactlens;
    }
    .htu__header ul li svg{
      fill: $clear-contactlens;
    }
    .htu__steps-sec {
      h2 strong, ul li div h3 strong{
        color: $clear-contactlens;
      }
    }
  }
  &--color-contactlens{
    .htu__header h1::before{
      background-color: $color-contactlens;
    }
    .htu__header ul li svg{
      fill: $color-contactlens;
    }
    .htu__steps-sec {
      h2 strong, ul li div h3 strong{
        color: $color-contactlens;
      }
    }
  }
  @media (max-width: 767px) {
    &__header {
      ul {
        li {
          a {
            img {
              display: none;
            }
          }
        }
      }
    }
    &__steps{
      &-sec{
        ul{
          li{
            img{
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    &__header {
      p {
        text-align: center;
      }
      ul {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        li {
          padding-bottom: 46px;
          h3 {
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 24px;
            border-bottom: 1px dashed #e3e3e3;
          }
          svg {
            top: auto;
            left: 50%;
            right: auto;
            bottom: 16px;
            transform: translate(-50%, 0%) rotate(90deg);
          }
        }
      }
    }
    &__steps{
      &-sec{
        ul{
          li{
            display: grid;
            grid-template-columns: 150px 1fr;
            align-items: center;
            img{
              padding: 16px 32px;
              margin-block: 16px;
              border-right: 1px dashed #e3e3e3;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &__header {
      ul {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
