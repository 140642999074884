.body-gift-cards-products__form section {
  margin-bottom: 50px;
}

.body-gift-cards-products__form-title {
  color: black;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 1;
  margin-bottom: 20px;
  text-align: left;
  letter-spacing: 1px;
}

.body-gift-cards-products__form-remain-message {
  color: #999;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 11px;
  font-weight: normal !important;
  letter-spacing: 1px;
  line-height: 1.7;
  margin-bottom: 10px;
  text-align: right;
}

.body-gift-cards-products__form-error-message {
  color: #c7002e;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 2;
  margin: 0;
}

.body-gift-cards-products__form-card-design ul {
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -4px;
  overflow-y: scroll;
  max-height: 80vw;
}

.body-gift-cards-products__form-card-design ul li {
  width: 33.33333%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 4px;
}

.body-gift-cards-products__form-card-design ul li label {
  display: block;
  margin: 0;
}

.body-gift-cards-products__form-card-design ul li label img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 31 / 23;
}

.body-gift-cards-products__form-card-design__selected {
  position: relative;
}

.body-gift-cards-products__form-card-design__selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #0094c8;
  border-radius: 4px;
}

.body-gift-cards-products__form-card-denomination ul {
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -4px;
}

.body-gift-cards-products__form-card-denomination ul li {
  width: 33.33333%;
  height: auto;
  padding: 4px;
}

.body-gift-cards-products__form-card-denomination ul li:last-child {
  width: 66.66667%;
}

.body-gift-cards-products__form-card-denomination ul li label {
  display: block;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 17px;
  font-weight: normal !important;
  letter-spacing: 1px;
  padding: 16px 20px 17px;
  line-height: 1;
  color: black;
  margin: 0;
}

.body-gift-cards-products__form-card-denomination ul li:last-child label {
  padding: 6px 10px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.body-gift-cards-products__form-card-denomination__selected {
  border: 2px solid #0094c8 !important;
}

.body-gift-cards-products__form-card-denomination input[type="number"] {
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
       appearance: auto !important;
}

.body-gift-cards-products__form-card-denomination input[type="number"]::-webkit-outer-spin-button,
.body-gift-cards-products__form-card-denomination input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: auto !important;
}

.body-gift-cards-products__form-card-denomination__custom-price {
  font-family: "Founders Grotesk", sans-serif;
  cursor: pointer;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  border-style: solid;
  font-size: 14px;
  padding: 9px 10px 8px;
  width: 100%;
  margin-left: 5px;
}

.body-gift-cards-products__form-card-denomination__remark {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 11px;
  font-weight: normal !important;
  letter-spacing: 1px;
  position: relative;
  padding-left: 1em;
  color: #999;
  line-height: 1.7;
  margin: 10px 0 0 0;
}

.body-gift-cards-products__form-card-denomination__remark::before {
  content: '*';
  position: absolute;
  left: 0;
}

.body-gift-cards-products__form-message .body-gift-cards-products__form-title {
  margin-bottom: 0;
}

.body-gift-cards-products__form-message textarea {
  width: 100%;
  min-height: 190px;
  height: auto;
  border-width: 0;
  padding: 20px 15px;
  background-color: #f4f4f4;
  cursor: pointer;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  resize: vertical;
  outline: none;
}

.body-gift-cards-products__form-message textarea::-webkit-input-placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-message textarea::-moz-placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-message textarea::-ms-input-placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-message textarea::placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-name .body-gift-cards-products__form-title {
  margin-bottom: 0;
}

.body-gift-cards-products__form-name input {
  width: 100%;
  height: auto;
  border-width: 0;
  padding: 15px 15px 16px;
  background-color: #f4f4f4;
  cursor: pointer;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
}

.body-gift-cards-products__form-name input::-webkit-input-placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-name input::-moz-placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-name input::-ms-input-placeholder {
  color: #6c757d;
}

.body-gift-cards-products__form-name input::placeholder {
  color: #6c757d;
}

@media (min-width: 768px) {
  .body-gift-cards-products__form-title {
    font-size: 16px;
  }
  .body-gift-cards-products__form-card-design ul {
    margin: -5px;
    max-height: 53vw;
  }
  .body-gift-cards-products__form-card-design ul li {
    width: 25%;
    padding: 5px;
  }
}

@media (min-width: 1024px) {
  .body-gift-cards-products__form-card-design ul {
    max-height: 325px;
  }
  .body-gift-cards-products__form-card-design ul li label {
    cursor: pointer;
  }
  .body-gift-cards-products__form-card-denomination ul li {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .body-gift-cards-products__form-card-denomination ul li:last-child {
    width: auto;
  }
  .body-gift-cards-products__form-card-denomination ul li label {
    cursor: pointer;
  }
}
