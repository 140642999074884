@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');

$base-font: 'https://static.lenskart.com/media/owndays/fonts';
$base-img-url: 'https://static.lenskart.com/media/owndays/img/kuromi-collection';

@font-face {
  font-family: "Corporate Logo Rounded";
  src: url("#{$base-font}/corporate-logo-rounded/Corporate-Logo-Rounded.ttf") format("truetype"),
      url("#{$base-font}/corporate-logo-rounded/Corporate-Logo-Rounded.woff2") format("woff2"),
      url("#{$base-font}/corporate-logo-rounded/Corporate-Logo-Rounded.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Katakanaboy";
  src: url("#{$base-font}/katakanaboy/Katakanaboy-R.ttf") format("truetype"),
      url("#{$base-font}/katakanaboy/Katakanaboy-R.woff2") format("woff2"),
      url("#{$base-font}/katakanaboy/Katakanaboy-R.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Eveleth Clean";
  src: url("#{$base-font}/eveleth-clean/EvelethCleanRegular.woff2") format("woff2"),
      url("#{$base-font}/eveleth-clean/EvelethCleanRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$base-img-url: 'https://static.lenskart.com/media/owndays/img/kuromi-collection';

$font-co: "Corporate Logo Rounded";
$font-ev: "Eveleth Clean";
$font-do: 'Dosis', sans-serif;

$black-2b: #2b2045;
$pink-e8: #e8c4e4;
$white-e3: #e3e3e3;
$purple-cd: #cdb4d8;

.base-kuromi-collection {
  &__container {
    font: 700 15px / 1.7 $font-co;
    max-width: calc(1224px + 40px);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    color: $black-2b;
    letter-spacing: 0;

    @media (min-width: 768px) {
      padding-top: 120px;
      padding-bottom: 75px;
    }

    @media (min-width: 1024px) {
      max-width: 1224px;
      padding-left: 73px;
      padding-right: 73px;
    }
  }

  &__hide {
    &-pc {
      display: block !important;

      @media (min-width: 768px) {
        display: none !important;
      }
    }

    &-sp {
      display: none !important;

      @media (min-width: 768px) {
        display: block !important;
      }
    }
  }

  &__item-corner-top, &__item-corner-bottom {
    &::before, &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: $black-2b;  
      width: 10px;
      height: 10px;
    }

    &::before {
      left: 14px;
    }

    &::after {
      right: 14px;
    }
  }

  &__item-corner-top {
    &::before, &::after {
      top: 14px;
    }
  }

  &__item-corner-bottom {
    &::before, &::after {
      bottom: 14px;
    }
  }

  &__title {
    font: 400 42px / 1 $font-ev;
    letter-spacing: 0.03em;
    text-align: center;
    color: white;
    -webkit-text-stroke: 2px $black-2b;
    padding-bottom: 0.6em;
    background: url("#{$base-img-url}/title-bg.svg") bottom center / 58px auto no-repeat;
    position: relative;
    z-index: 9;

    @media (min-width: 768px) {
      -webkit-text-stroke-width: 3px;
    }

    @media (min-width: 1024px) {
      font-size: 80px;
      background-size: 92px auto;
    }
  }

  &__center {
    text-align: center;
  }

  &__color {
    display: inline-flex;
    margin: 20px 0 0 0;
    list-style-type: none;
    padding: 0;

    &__item--black, &__item--pink-e8, &__item--purple-cd, &__item--white-e3 {
      width: 29px;
      height: 29px;
      background-color: white;
      position: relative;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 4px;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 50%;
      }
    }

    &__item--black {
      &::before {
        background-color: black;
      }
    }

    &__item--pink-e8 {
      &::before {
        background-color: $pink-e8;
      }
    }

    &__item--purple-cd {
      &::before {
        background-color: $purple-cd;
      }
    }

    &__item--white-e3 {
      &::before {
        background-color: $white-e3;
      }
    }

    @media (min-width: 768px) {
      align-items: center;
      margin-top: auto;
      
      &::before {
        content: attr(data-title);
        letter-spacing: 0.06em;
        font: 700 22px / 1 $font-do;
        margin-right: 28px;
      }

      &__item--black, &__item--pink-e8, &__item--purple-cd, &__item--white-e3 {
        width: 42px;
        height: 42px;

        &::before {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__button {
    font: 700 15px / 1 $font-co;
    color: $black-2b;
    position: relative;
    padding: 0 14px 0 30px;
    margin: 0 auto 8px;
    height: 66px;
    width: 100%;
    display: flex;
    z-index: 3;

    &[disabled] {
      pointer-events: none;

      &::before, &::after {
        border-color: $white-e3;
      }

      span {
        color: $white-e3;
        background: none;
      }
    }

    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 3px solid currentColor;
      border-radius: 5em;
      background-color: white;
    }

    &::before {
      z-index: -1;
    }

    &::after {
      transition: 0.4s;
      z-index: -2;
      transform: translate(8px, 8px);
    }

    span {
      background: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      background: url("#{$base-img-url}/arrow-right.svg") center right / 29px 29px no-repeat;
    }

    @media (min-width: 768px) {
      height: 62px;
      max-width: 281px;

      &:hover {
        opacity: 1;
        color: currentColor;
        transform: translate(3px,3px);
        &::after {
          transform: translate(0, 0);
        }
      }

      &:hover, &:active, &:visited, &:focus {
        color: currentColor;
        text-decoration: none;
      }
    }
  }

  &__button-group {
    margin-top: 70px;
    display: grid;
    row-gap: 15px;

    @media (min-width: 768px) {
      max-width: 747px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__scroll-x-hidden {
    overflow-x: hidden;
  }
}
