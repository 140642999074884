$base-img-url: "https://static.lenskart.com/owndays/img/huawei2-titanium";

$font-biz: "BIZ UDPGothic", serif;

$black-41: #414141;
$gray-b3: #b3b3b3;
$gray-f7: #F7F7F7;
$gray-53: #535353;
$blue-00: #0094C8;

.footer-huawei-2-titanium {
  &__spec {
    padding-top: 57px;

    @media (min-width: 768px) {
      padding-top: 120px;
    }

    &__title {
      text-align: center;
      font-family: $font-biz;
      font-size: 32px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 51.2px;
      letter-spacing: 0.32px;
      text-transform: uppercase;
      color: black;
      padding-bottom: 45px;
      margin-bottom: 0;

      @media (min-width: 1024px) {
        font-size: 66px;
        line-height: 105.6px;
        padding-bottom: 60px;
      }
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      &--sp {
        display: block;
      }

      &--pc {
        display: none;
      }

      > li:not(:first-child) {
        margin-top: 40px;
      }

      h3, p {
        margin-bottom: 0;
      }

      h3 {
        font-family: $font-biz;
        font-size: 14px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 30.8px;
        letter-spacing: 0.14px;
        color: black;
        margin-bottom: 16.667px;
        display: grid;
        grid-template-columns: 23.589px 1fr;
        gap: 12.78px;
        position: relative;
        
        &::after {
          position: absolute;
          left: 0;
          bottom: -8px;
          content: "";
          display: block;
          width: 100%;
          height: auto;
          border-bottom: 0.667px solid black;
        }

        > span:nth-child(1) {
          margin: auto;
        }
      }

      p {
        font-family: $font-biz;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 30.8px;
        letter-spacing: 0.14px;
        color: black;
      }

      @media (min-width: 768px) {
        &--sp {
          display: none;
        }

        &--pc {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 45px 6.18%;

          > ul:first-child > li:not(:first-child), > ul:nth-child(2) > li:not(:first-child) {
            margin-top: 45px;
          }
  
          > ul:last-child {
            grid-column: span 2;
          }
        }
      }

      @media (min-width: 1024px) {
        h3 {
          font-size: 16px;
          line-height: 35.2px;
          letter-spacing: 0.16px;
          margin-bottom: 30.667px;
          display: grid;
          grid-template-columns: 30.12px 1fr;
          gap: 18px;
          
          &::after {
            bottom: -15px;
          }
        }
  
        p {
          font-size: 13px;
          font-weight: 700 !important;
          line-height: 30px;
          letter-spacing: 0.16px;
          color: black;
        }
      }
    }

    &__item__remarks {
      list-style-type: none;
      padding-left: 11px;
      margin-bottom: 0;
      color: $gray-53;
      font-family: "BIZ UDPGothic";
      font-size: 11px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 20.9px;
      margin-top: 10px;
      position: relative;

      li {
        &::before {
          content: '*';
          position: absolute;
          left: 0;
        }
      }
      

      @media (min-width: 1024px) {
        font-size: 13px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 24.7px;
        letter-spacing: 0.13px;
        padding-left: 20.15px;
      }
    }

    &__item__accessories {
      list-style-type: none;
      margin-bottom: 0;
      padding-left: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 17px 19px;
      margin-top: 13px;

      li > p {
        color: black;
        font-family: $font-biz;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.11px;
      }

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;

        &__accessories-pc {
          display: grid;
          grid-template-columns: calc(50% - 32px) 1fr;
          column-gap: 40px;
          margin: 20px 0;
        }
      }

      @media (min-width: 1024px) {
        li > p {
          font-size: 13px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 1.6;
          letter-spacing: 0.13px;
        }
      }
    }

    &__item-speakers {
      h3 > span > img {
        width: 23.589px;

        @media (min-width: 1024px) {
          width: 30.12px;
        }
      }
    }

    &__item-microphones {
      h3 > span > img {
        width: 13.76px;

        @media (min-width: 1024px) {
          width: 19px;
        }
      }
    }

    &__item-battery {
      h3 > span > img {
        width: 13.027px;
        filter: invert(1);

        @media (min-width: 1024px) {
          width: 18px;
        }
      }
    }

    &__item-product-weight {
      h3 > span > img {
        width: 18.674px;

        @media (min-width: 1024px) {
          width: 24px;
        }
      }

      h3 > span:last-child {
        position: relative;

        &::before {
          content: '* excluding lenses';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5%;
          font-family: $font-biz;
          font-size: 11px;
          font-style: normal;
          font-weight: 400 !important;
          line-height: 220%;
          letter-spacing: 0.11px;

          @media (min-width: 1024px) {
            right: unset;
            left: 206px;
          }
        }
      }
    }

    &__item-others {
      h3 > span > img {
        width: 21.623px;

        @media (min-width: 1024px) {
          width: 30px;
        }
      }

      > p:last-child {
        margin-top: 10px;
      }
    }

    &__item-in-box {
      h3 > span > img {
        width: 24px;

        @media (min-width: 1024px) {
          width: 36.182px;
        }
      }

      &__manual {
        a {
          color: $blue-00;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 10px;
          
          u {
            text-decoration: underline;
            text-underline-offset: 2px;
          }

          img {
            width: 20.101px;
          }

          @media (min-width: 1024px) {
            cursor: pointer;
            
            &:hover {
              img {
                transition: 0.5s;
                opacity: 0.6;
              }
            }
          }
        }
      }

      &__box-arrow {
        margin-top: 35px;
        background-color: $gray-f7;
        padding: 20px 15px;
        position: relative;
        color: $gray-53;      

        &::after {
          content: "";
          position: absolute;
          top: -18px;
          left: 70px;
          width: 23px;
          height: 22px;
          background-image: url("#{$base-img-url}/polygon-icon.svg");
          background-repeat: no-repeat;
          background-position: 0;
          background-size: auto 23px;
        }

        a {
          color: $blue-00;
          text-decoration: underline;
          text-underline-offset: 2px;
        }

        @media (min-width: 768px) {
          margin-top: 0;
          padding: 20px;
          
          &::after {
            top: 28px;
            left: -18px;
            transform: rotate(25deg);
          }
        }
      }
    }
  }

  &__attention {
    width: 100%;
    height: auto;
    background-color: $gray-f7;
    padding-block: 70px;
    margin: 70px 0;

    &__title {
      text-align: center;
      font-family: $font-biz;
      font-size: 32px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 51.2px;
      letter-spacing: 0.32px;
      margin: 0;
      color: black;
    }

    &__question {
      width: 100%;
      height: auto;
      max-width: 900px;
      border-radius: 10px;
      border: 1px solid $gray-53;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 45px auto 0 auto;

      &__layout {
        &--q {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          position: relative;

          &::before, &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 23px;
            height: 1px;
            background-color: $gray-53;
          }

          &::after {
            transform: translateY(-50%) rotate(90deg);
            transition: transform 0.3s ease, opacity 0.3s ease;
            opacity: 1;
          }

          &__open {
            &::after {
              transform: translateY(-50%) rotate(0deg) !important;
              opacity: 0;
            }
          }
        }

        &--a {
          padding: 0 20px 0 20px;
          transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
          overflow: hidden;
        }
      }

      &__q {
        font-family: $font-biz;
        font-size: 14px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 30.8px;
        letter-spacing: 0.14px;
        padding-left: 20px;
        color: $gray-53;
      }

      h3, h4 {
        font-weight: 700 !important;
      }

      h3, h4, ul, p {
        font-family: $font-biz;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 28.6px;
        margin: 0;
        color: $gray-53;
      }

      h4 {
        margin-top: 24px;
      }

      ul, p {
        font-weight: 400 !important;
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin: 10px 0 0 0;

        &:last-child {
          padding-bottom: 20px;
        }

        li {
          display: flex;

          span {
            &:nth-child(1) {
              margin-right: 7px;
            }
          }
        }
      }

      &__note {
        position: relative;
        padding-left: 12px;

        &::before {
          content: '*';
          position: absolute;
          left: 0;
        }
      }
    }

    &__remarks {
      width: 100%;
      height: auto;
      max-width: 900px;
      list-style-type: none;
      padding-left: 0;
      margin: 37px auto 0 auto;
      
      li {
        position: relative;
        padding-left: 12px;
        font-family: $font-biz;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.6px;
        letter-spacing: 0.13px;
        color: $gray-53;

        &::before {
          content: '*';
          position: absolute;
          left: 0;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-block: 120px;
      margin-top: 120px;

      &__title {
        font-size: 66px;
        line-height: 105.6px;
        letter-spacing: 0.66px;
      }

      &__question {
        max-width: 900px;
        margin: 60px auto 0 auto;
        cursor: pointer;
  
        &__layout {
          &--q {
            height: 122px;
  
            &::before, &::after {
              width: 40px;
              height: 1px;
              background-color: $gray-53;
              right: 42px;
            }
          }
  
          &--a {
            padding: 0 42px;
          }
        }
  
        &__q {
          font-size: 18px;
          line-height: 39.6px;
          padding-left: 42px;
        }
  
        h3, h4, ul, p {
          font-size: 16px;
          line-height: 35.2px;
          letter-spacing: normal;
        }
  
        ul {  
          &:last-child {
            padding-bottom: 56px;
          }
        }
  
        &__note {
          padding-left: 19px;
        }
      }

      &__remarks {
        margin: 60px auto 0 auto;
        
        li {
          padding-left: 19px;
          font-size: 16px;
          font-weight: 700 !important;
          line-height: 35.2px;
        }
      }
    }
  }

  &__share {
    width: 100%;
    height: auto;
    background-color: $black-41;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 34px;
    padding: 43px 0 58px 0;

    h2 {
      margin: 0;
      color: white;
      text-align: center;
      font-family: $font-biz;
      font-size: 30px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 33px;
      letter-spacing: 3px;
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;

      li {
        a {
          font-size: 35px;
          color: white;
          display: block;
          width: 35px;
          height: 35px;

          i {
            background: transparent;
          }
        }

        &:nth-child(1), &:nth-child(2) {
          margin-top: -5px;
        }

        &:nth-child(3) {
          width: 35px;
          height: 35px;
          position: relative;

          a {
            color: $black-41;
            position: relative;

            i {
              font-size: 22.23px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::before {
              position: absolute;
              content: '';
              width: 35px;
              height: 35px;
              background-color: white;
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 0;
            }
          }
        }
      }

      @media (min-width: 1024px) {
        margin: 0;
        gap: 36px;

        li {
          a {
            cursor: pointer;
            transition: .5s;
            max-height: none;
          }

          &:not(:nth-child(3)) {
            a {
              &:hover {
                color: $gray-b3;
              }
            }

            margin-top: -13px;
          }

          &:nth-child(3) {
            a {
               &:hover {
                 &::before {
                   transition: 0.5s;
                   background-color: $gray-b3;
                 }
               }
             }
           }
        }
      }
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
      gap: 40px;

      h2 {
        font-size: 40px;
        line-height: 44px;
        letter-spacing: 4px;
      }
    }
  }
}
