.articles{
  padding-block: 50px;
  &__container{
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1020px + 40px);
  }
  &__title{
    text-align: center;
    font: 400 20px/1.5 "Founders Grotesk", sans-serif;
    margin-block: 0 40px;
  }
  &__list{
    display: grid;
    gap: 30px;
    padding: 0;
    margin: 0;
    list-style: none;
    li{
      a{
        display: grid;
        gap: 15px;
        text-decoration: none;
        color: #000;
      }
      img{
        aspect-ratio: 7/4;
      }
      h3{
        font: 400 13px/1.5 "Founders Grotesk", sans-serif;
        margin: 0;
      }
    }
  }
  @media (max-width: 767px) {
    &__list{
      li{
        a{
          grid-template-columns: 3fr 7fr;
        }
      }
    }
  }
  @media (min-width: 768px) {
    &__list{
      grid-template-columns: repeat(3,1fr);
    }
  }
  @media (min-width: 1024px) {
    padding-block: 90px;
    &__title{
      margin-bottom: 80px;
      font-size: 30px;
    }
    &__list{
      gap: 40px 20px;
      li{
        a{
          gap: 12px;
          &:hover{
            img{
              opacity: .6;
            }
          }
        }
        img{
          transition: opacity .3s ease;
        }
        h3{
          font-size: 14px;
        }
      }
    }
  }
}