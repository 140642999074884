$font-founders: 'Founders Grotesk', sans-serif;

.footer-sun {
  &__gap {
    width: 100%;
    height: 80px;
  }

  &__navs-sticky {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 55px;
    z-index: 2;

    .base-sun__container {
      padding-inline: 0;
    }

    .base-sun__btn {
      width: 160px !important;
      height: 55px;
      border-radius: 0;
      white-space: nowrap;
      text-align: center;
    }

    .base-sun__btn--primary {
      border-top-left-radius: 15px !important;
    }

    &__inner {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: auto;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      max-width: 823px;
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      &__gap {
        height: 100px;
      }

      .base-sun__btn {
        width: 260px !important;
      }

      .base-sun__btn--primary {
        border-top-left-radius: 20px !important;
      }

      .base-sun__btn--secondary {
        border-top-right-radius: 20px !important;
      }

      &__actions {
        padding-right: 20px;
        max-width: none;
      }

      &__inner {
        background-color: transparent;
        padding: 0;
      }
    }
  }
}
