.custom-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.custom-btn:hover {
  background-color: #e6e6e6;
}

.custom-btn.outlined {
  background-color: white;
  border: 1px solid #ccc;
}

.custom-btn.outlined:hover {
  background-color: #e0dfdf;
}

.custom-btn.danger {
  color: #fff;
  background-color: #d9534f;
}

.custom-btn.danger:hover {
  background-color: #c9302c;
}

.custom-btn.outlined-blue {
  color: #000042;
  border: 1px solid #737397;
  background-color: #ffffff;
  -webkit-transition: 150ms ease all;
  transition: 150ms ease all;
}

.custom-btn.outlined-blue:hover {
  background-color: #eeeeee;
}

.custom-btn.green {
  color: #000042;
  background-color: #11DAAC;
  -webkit-transition: 150ms ease all;
  transition: 150ms ease all;
}

.custom-btn.green:hover {
  background-color: #00C99B;
}
