.product-price {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Founders Grotesk";
}

.product-price--mobile {
  margin: 4px 0;
}

.product-price__cost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  gap: 4px;
  color: #000000;
}

.product-price__cost--mobile {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.product-price__tax {
  margin-left: 13px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #9999B3;
}

.product-price__tax--mobile {
  color: rgba(0, 0, 0, .5);
  font-family: Raleway;
  font-weight: 400;
}
