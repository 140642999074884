.odslv-modal__progress {
  width: 100%;
  height: 8px;
  background-color: #F3F3F3;
  position: relative;
  z-index: 1;
}

.odslv-modal__progress__value {
  height: 100%;
  position: absolute;
  background-color: #0094C8;
}

.odslv-modal__progress__value {
  width: 0;
}

.odslv-modal__progress__value--select-prescription {
  width: 256px;
}

@media (max-width: 768px) {
  .odslv-modal__progress__value--select-prescription {
    width: 128px;
  }
}

.odslv-modal__progress__value--select-lens {
  width: 512px;
}

@media (max-width: 768px) {
  .odslv-modal__progress__value--select-lens {
    width: 256px;
  }
}

.odslv-modal__progress__value--frame-only,
.odslv-modal__progress__value--select-addons {
  width: 100%;
}
