@charset "UTF-8";
.footer-freaks-store__about__main {
  width: 100%;
  height: auto;
  background-color: #555E2B;
  padding: 60px 0;
}
.footer-freaks-store__about__intro {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 45px;
}
.footer-freaks-store__about__intro__company-view {
  display: block;
  width: 100%;
  height: auto;
  max-width: 510px;
}
.footer-freaks-store__about__intro__company-logo {
  display: block;
  width: 268px;
  height: auto;
  margin: 45px auto 0 auto;
}
.footer-freaks-store__about__intro__text {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 26px;
  color: white;
  margin-top: 30px;
  margin-bottom: 20px;
}
.footer-freaks-store__about__company-info__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.footer-freaks-store__about__company-info__link__text {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold !important;
  font-size: 13px;
  line-height: 26px;
  color: white;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.footer-freaks-store__about__company-info__link__icon {
  display: block;
  width: 13px;
  height: 13px;
  margin-top: 2px;
}
@media (min-width: 768px) {
  .footer-freaks-store__about__main {
    padding: 100px 0;
  }
  .footer-freaks-store__about__intro__company-logo {
    width: 268px;
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .footer-freaks-store__about__intro {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .footer-freaks-store__about__intro__company-view {
    width: 510px;
    height: auto;
  }
  .footer-freaks-store__about__intro__company-logo {
    width: 360px;
    height: auto;
  }
}
.footer-freaks-store__shop .base-freaks-store__title {
  margin: 60px 0;
}
.footer-freaks-store__shop__list {
  width: 100%;
  height: auto;
  padding: 0 5%;
  max-width: 1152px;
  margin: 0 auto;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
}
.footer-freaks-store__shop__list li {
  position: relative;
  width: 100%;
  height: auto;
  line-height: 30px;
  padding-left: 18px;
}
.footer-freaks-store__shop__list li::before {
  content: "・";
  position: absolute;
  left: 0;
  top: 11px;
  font-size: 12px;
  line-height: 1;
}
.footer-freaks-store__shop__list li a {
  outline: none;
  text-decoration: none;
  display: block;
  color: black;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}
.footer-freaks-store__shop__button {
  padding: 60px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-freaks-store__shop__button a {
  width: 380px;
}
@media (min-width: 768px) {
  .footer-freaks-store__shop__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .footer-freaks-store__shop__button {
    padding: 80px 0;
  }
}
.footer-freaks-store__share {
  background-color: #F5F4EF;
  padding: 45px 0 75px;
}
.footer-freaks-store__share .base-freaks-store__title strong {
  font: 700 30px/22px "NeuzeitGroBold";
}
.footer-freaks-store__share ul {
  display: grid;
  grid-template-columns: repeat(3, auto);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 36px;
  margin: 30px 0 0 0;
  list-style-type: none;
  padding: 0;
}
.footer-freaks-store__share ul li a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #555E2B;
  font-size: 35px;
}
.footer-freaks-store__share ul li a i::before {
  background-color: #F5F4EF;
}
.footer-freaks-store__share ul li:nth-child(2) a i::before {
  content: "\1D54F" !important;
}
@media (min-width: 768px) {
  .footer-freaks-store__share {
    padding: 100px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 90px;
  }
  .footer-freaks-store__share .base-freaks-store__title strong {
    font-size: 50px;
  }
  .footer-freaks-store__share ul {
    margin-top: 0;
  }
}
