.service_l {
  margin: 0 auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}

@media (max-width: 1023px) {
  .service_l {
    margin: 0 auto 60px;
  }
}

.service_l__section {
  position: relative;
  overflow: hidden;
  display: block;
}

.service_l__container {
  width: 90%;
  max-width: 1296px;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container {
    width: 100%;
    padding: 0 5%;
  }
}

.service_l__container-retail {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container-retail {
    width: 100%;
    padding: 0;
  }
}

.service_l__container-warranty {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1296px;
}

@media (max-width: 767px) {
  .service_l__container-warranty {
    width: 100%;
    padding: 0 5%;
  }
}

.l-services__content {
  display: block;
  position: relative;
  margin: 5% auto;
  overflow: hidden;
}

.l-services__content-text {
  background-color: #f4f4f4;
  position: absolute;
  width: 35%;
  height: 100%;
  right: 0;
  padding: 3%;
}

.l-services__content-text--frame {
  width: 40%;
}

.l-services__content-text .l-services__content-text__container {
  height: 100%;
  display: table !important;
}

.l-services__content-text .l-services__content-text__content {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 1px;
  line-height: 2;
  font-size: 15px;
  vertical-align: middle;
  display: table-cell !important;
}

@media (max-width: 767px) {
  .l-services__content-text .l-services__content-text__content {
    line-height: 1.9;
  }
}

.l-services__content-text .l-services__content-text__content__title {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}

.l-services__content-text .l-services__content-text__content__subtitle {
  font-size: 16px;
  font-weight: 400;
  padding: 15px 0 0;
  color: inherit;
  line-height: 1.9;
}

@media (max-width: 767px) {
  .l-services__content-text .l-services__content-text__content__subtitle {
    line-height: 1.7;
  }
}

.l-services__content-text .l-services__content-text__content__description {
  margin: 10px 0 0;
  color: #666;
}

.l-services__content-text .l-services__content-text__content__table {
  margin: 15px 0;
  letter-spacing: 1px;
}

.l-services__content-text .l-services__content-text__content__table .u-svg__icon-frame,
.l-services__content-text .l-services__content-text__content__table .u-svg__icon-lens {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1px;
}

.l-services__content-text .l-services__content-text__content__table .u-svg__icon-frame.u-svg__icon-frame,
.l-services__content-text .l-services__content-text__content__table .u-svg__icon-lens.u-svg__icon-frame {
  width: 50px;
}

.l-services__content-text .l-services__content-text__content__table .u-svg__icon-frame.u-svg__icon-lens,
.l-services__content-text .l-services__content-text__content__table .u-svg__icon-lens.u-svg__icon-lens {
  width: 30px;
}

.l-services__content-text .l-services__content-text__content__table .u-mark {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: inherit;
  width: 15px;
  height: 15px;
  position: relative;
  margin: 0px 9px;
}

.l-services__content-text .l-services__content-text__content__table .u-mark:before, .l-services__content-text .l-services__content-text__content__table .u-mark:after {
  content: '';
  position: absolute;
  background: #000;
}

.l-services__content-text .l-services__content-text__content__table .u-mark__plus:before {
  width: 15px;
  height: 1px;
  top: 50%;
  left: 0;
}

.l-services__content-text .l-services__content-text__content__table .u-mark__plus:after {
  height: 15px;
  width: 1px;
  top: 0;
  left: 50%;
}

.l-services__content-text .l-services__content-text__content__table .u-mark__equal:before, .l-services__content-text .l-services__content-text__content__table .u-mark__equal:after {
  width: 15px;
  height: 1px;
  left: 0;
}

.l-services__content-text .l-services__content-text__content__table .u-mark__equal:before {
  top: 50%;
  margin-top: -3px;
}

.l-services__content-text .l-services__content-text__content__table .u-mark__equal:after {
  top: 50%;
  margin-top: 3px;
}

.l-services__content-text .l-services__content-text__content__btn {
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  margin: 0 auto;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.l-services__content-text .l-services__content-text__content__btn a,
.l-services__content-text .l-services__content-text__content__btn button {
  display: block;
  width: 100%;
  padding: 18px 0;
  font-size: 14px;
  font-weight: 500;
  border-width: 0;
}

.l-services__content-text .l-services__content-text__content__btn--primary {
  border-color: #666;
  margin-top: 20px;
}

.l-services__content-text .l-services__content-text__content__btn--primary:active, .l-services__content-text .l-services__content-text__content__btn--primary:focus, .l-services__content-text .l-services__content-text__content__btn--primary:hover {
  background-color: #666;
}

.l-services__content-text .l-services__content-text__content__btn--primary:hover a,
.l-services__content-text .l-services__content-text__content__btn--primary:hover button {
  color: #fff;
}

.l-services__content-text .l-services__content-text__content__btn--primary a,
.l-services__content-text .l-services__content-text__content__btn--primary button {
  color: #666;
}

.l-services__content-text .l-services__content-text__content__btn--primary a:active, .l-services__content-text .l-services__content-text__content__btn--primary a:focus, .l-services__content-text .l-services__content-text__content__btn--primary a:hover,
.l-services__content-text .l-services__content-text__content__btn--primary button:active,
.l-services__content-text .l-services__content-text__content__btn--primary button:focus,
.l-services__content-text .l-services__content-text__content__btn--primary button:hover {
  color: #fff;
  text-decoration: none;
}

.l-services__content-text .l-services__content-text__content__btn--primary a.is-active,
.l-services__content-text .l-services__content-text__content__btn--primary button.is-active {
  color: #fff;
}

.l-services__content-text .l-services__content-text__content__red {
  color: #c7002e;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1px;
}

.l-services__content-image {
  width: 65%;
  float: left;
}

.l-services__content.turn-over .l-services__content-text {
  left: 0;
}

.l-services__content.turn-over .l-services__content-image {
  float: right;
}

@media (max-width: 1023px) {
  .l-services__content-text {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 30px 5%;
  }
  .l-services__content-image {
    float: none;
    width: 100%;
  }
  .l-services__content.turn-over .l-services__content-image {
    float: none;
  }
}
