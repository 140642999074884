.od-membership {
  width: 100%;
  margin: 0 auto;
}

.od-membership-banner {
  width: 100%;
  margin: 0 auto;
}

.od-membership__title--features {
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 80px;
}

.od-membership__title--features:before, .od-membership__title--features:after {
  content: ' ';
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  border-bottom: 1px solid #000000;
  opacity: 0.2;
}

.od-membership__title--features:before {
  margin: auto 67px auto 0;
}

.od-membership__title--features:after {
  margin: auto 0 auto 67px;
}

.od-membership-perks {
  width: 100%;
}

.od-membership-perks__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin-top: 120px;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  padding: 0 283px;
}

.od-membership-perks__description {
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  opacity: 0.7;
  text-align: center;
  padding: 0 283px;
  margin-top: 40px;
}

.od-membership-perks__btns {
  margin-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 76px;
}

.od-membership-features {
  width: 100%;
  margin-top: 120px;
}

.od-membership-features__table {
  width: 811px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #555555;
}

.od-membership-features__table-item {
  width: 404px;
  min-height: 404px;
  border-bottom: 1px solid #555555;
}

.od-membership-features__table-item:nth-child(2n+1) {
  border-right: 1px solid #555555;
}

.od-membership-features__table-item__title {
  max-width: 314px;
  margin: 0 auto;
  opacity: 0.7;
  margin-top: 40px;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

.od-membership-ref-friend {
  margin-top: 20px;
}

.od-membership-ref-friend-container {
  width: 100%;
  padding: 0 200px;
  margin-top: 40px;
}

.od-membership-ref-friend-container .od-membership-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 19px;
}

.od-membership-ref-friend-container .od-membership-information__block {
  width: 100%;
  min-height: 236px;
  background-color: #FAFAFA;
}

.od-membership-ref-friend-container .od-membership-information__block__content {
  width: 100%;
  padding: 40px;
}

.od-membership-ref-friend-container .od-membership-information__block__content__title {
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  opacity: 0.5;
}

.od-membership-ref-friend__description {
  width: 100%;
  padding: 0 283px;
  color: #000000;
  text-align: center;
  opacity: 0.7;
  font-size: 20px;
  line-height: 40px;
}

.od-membership-account-reg {
  width: 100%;
  padding: 60px 0;
  background-color: #FAFAFA;
  text-align: center;
  margin: 120px 0 60px 0;
}

.od-membership-account-reg__title {
  width: 100%;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
}

.od-membership-account-reg__btn {
  width: 100%;
  margin-top: 80px;
}

.od-membership-account-reg__btn button {
  width: 518px;
  margin: 0 auto;
  padding: 30px 0;
}

.od-membership-account-reg__description {
  margin-top: 40px;
  width: 100%;
  color: #000000;
  font-size: 20px;
  line-height: 40px;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .od-membership .od-membership-perks__title, .od-membership .od-membership-perks__description, .od-membership .od-membership-perks__btns {
    padding: 0 20px;
  }
  .od-membership .od-membership-perks__title, .od-membership .od-membership-perks__description {
    margin-top: 30px;
  }
  .od-membership .od-membership-perks__title {
    font-size: 32px;
    line-height: 42px;
  }
  .od-membership .od-membership-perks__btns {
    margin-top: 15px;
    gap: 0;
  }
  .od-membership .od-membership-features {
    margin-top: 50px;
  }
  .od-membership .od-membership-features__table {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .od-membership .od-membership-features__table-item {
    width: 100%;
    border: none;
    border-bottom: 1px solid #555555;
  }
  .od-membership .od-membership-features__table-item:first-child {
    border-top: 1px solid #555555;
  }
  .od-membership .od-membership-ref-friend-container {
    padding: 0 20px;
  }
  .od-membership .od-membership-ref-friend__description {
    padding: 0 20px;
  }
  .od-membership .od-membership-account-reg {
    margin: 50px 0 50px 0;
    padding: 20px;
  }
  .od-membership .od-membership-account-reg__title, .od-membership .od-membership-account-reg__btn, .od-membership .od-membership-account-reg__description {
    padding: 0 20px;
  }
  .od-membership .od-membership-account-reg__title {
    font-size: 32px;
    line-height: 42px;
  }
  .od-membership .od-membership-account-reg__btn {
    margin-top: 50px;
  }
  .od-membership .od-membership-account-reg__btn button {
    width: 100%;
    padding: 10px 0;
  }
  .od-membership .od-membership__title--features {
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
  }
}
