.od-membership {
  width: 100%;
  margin: 0 auto;

  &-banner {
    width: 100%;
    margin: 0 auto;
  }

  &__title--features {
    font-size: 48px;
    line-height: 56px;
    color: #000000;
    display: flex;
    margin-bottom: 80px;

    &:before,
    &:after {
      content: ' ';
      flex: 1 1;
      border-bottom: 1px solid #000000;
      opacity: 0.2;
    }

    &:before {
      margin: auto 67px auto 0;
    }

    &:after {
      margin: auto 0 auto 67px;
    }
  }

  &-perks {
    width: 100%;

    &__title {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 120px;
      font-size: 48px;
      line-height: 56px;
      color: #000000;
      padding: 0 283px;
    }

    &__description {
      font-size: 20px;
      line-height: 40px;
      color: #000000;
      opacity: 0.7;
      text-align: center;
      padding: 0 283px;
      margin-top: 40px;
    }

    &__btns {
      margin-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 76px;
    }
  }

  &-features {
    width: 100%;
    margin-top: 120px;

    &__table {
      width: 811px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1px;
      flex-wrap: wrap;
      margin: 0 auto;
      border-top: 1px solid;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: #555555;

      &-item {
        width: 404px;
        min-height: 404px;
        border-bottom: 1px solid #555555;

        &:nth-child(2n+1) {
          border-right: 1px solid #555555;
        }

        &__title {
          max-width: 314px;
          margin: 0 auto;
          opacity: 0.7;
          margin-top: 40px;
          font-size: 24px;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }

 &-ref-friend {
  margin-top: 20px;

  &-container {
    width: 100%;
    padding: 0 200px;
    margin-top: 40px;

    .od-membership-information {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 19px;
      
      &__block {
        width: 100%;
        min-height: 236px;
        background-color: #FAFAFA;

        &__content {
          width: 100%;
          padding: 40px;

          &__title {
            font-size: 20px;
            line-height: 23px;
            color: #000000;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &__description {
    width: 100%;
    padding: 0 283px;
    color: #000000;
    text-align: center;
    opacity: 0.7;
    font-size: 20px;
    line-height: 40px;
  }
 }

 &-account-reg {
  width: 100%;
  padding: 60px 0;
  background-color: #FAFAFA;
  text-align: center;
  margin: 120px 0 60px 0;

  &__title {
    width: 100%;
    font-size: 48px;
    line-height: 56px;
    color: #000000;
  }

  &__btn {
    width: 100%;
    margin-top: 80px;

    button {
      width: 518px;
      margin: 0 auto;
      padding: 30px 0;
    }
  }

  &__description {
    margin-top: 40px;
    width: 100%;
    color: #000000;
    font-size: 20px;
    line-height: 40px;
    opacity: 0.7;
  }
 }

  @media screen and (max-width: 768px) {
    .od-membership {
      &-perks {
        &__title,
        &__description,
        &__btns {
          padding: 0 20px;
        }

        &__title,
        &__description {
          margin-top: 30px;
        }

        &__title {
          font-size: 32px;
          line-height: 42px;
        }

        &__btns {
          margin-top: 15px;
          gap: 0;
        }
      }

      &-features {
        margin-top: 50px;

        &__table {
          width: 100%;
          flex-direction: column;

          &-item {
            width: 100%;
            border: none;
            border-bottom: 1px solid #555555;

            &:first-child {
              border-top: 1px solid #555555;
            }
          }
        }
      }

      &-ref-friend {
        &-container {
          padding: 0 20px;
        }

        &__description {
          padding: 0 20px;
        }
      }

      &-account-reg {
        margin: 50px 0 50px 0;
        padding: 20px;

        &__title,
        &__btn,
        &__description {
          padding: 0 20px;
        }

        &__title {
          font-size: 32px;
          line-height: 42px;
        }

        &__btn {
          margin-top: 50px;

          button {
            width: 100%;
            padding: 10px 0;
          }
        }
      }

      &__title {
          
        &--features {
          text-align: center;
          font-size: 40px;
          margin-bottom: 50px;
        }
      }
    }
  }
}
