$font-founders: 'Founders Grotesk', sans-serif;

$black: #000;
$gray-e3: #e3e3e3;
$blue: #0094c8;
$red: #c7002e;

.footer-lens-replacement {
  margin-bottom: 60px !important;
  letter-spacing: 1px;

  @media (min-width: 768px) {
    margin-bottom: 200px !important;
  }

  &__qa {
    margin-bottom: 80px;

    &__list {
      list-style-type: none;
      margin: 0;
      padding-left: 0;
    }

    &__item {

      &:last-child {
        &-a {
          padding-bottom: 40px;
        }
      }

      &-q {
        padding: 20px 0;
        cursor: pointer;
        position: relative;
        transition: all .5s ease;
        border-top: 1px solid $gray-e3;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--border-top-none {
          border-top: none;
        }

        h3 {
          color: $black;
          font-family: $font-founders;
          font-size: 14px;
          font-weight: 400 !important;
          line-height: 1.9;
          text-align: left;
          padding: 0 24px 0 48px;
          position: relative;
          margin-bottom: 0;
          margin-top: 0;
          letter-spacing: 1px;

          &::before {
            content: "Q";
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 600 !important;
            color: $blue;
            letter-spacing: 1px;

            @media (min-width: 768px) {
              left: 24px;
            }
          }
        }
      }

      &-navigate {
        padding-right: 24px;
        font-family: $font-founders;
        font-size: 15px;
        font-weight: normal !important;
        color: $black;
        letter-spacing: 1px;
      }

      &-a {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease;
        
        p {
          color: $black;
          font-family: $font-founders;
          font-size: 15px;
          font-weight: normal !important;
          letter-spacing: 1px;
          line-height: 2;
          padding: 0 15px 20px 48px;
          margin-bottom: 0;
          position: relative;

          &::before {
            content: "A";
            position: absolute;
            left: 15px;
            top: 0;
            font-weight: 600 !important;
            color: $red;
          }

          @media (min-width: 768px) {
            padding: 0 24px 0 48px;

            &::before {
              left: 24px;
            }
          }
        }
      }

      &__open {
        border-top: 1px dashed $gray-e3;
        overflow: visible;
        padding: 20px 0;
      }
    }
  }
}
