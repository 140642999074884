.footer-anniversary-10th-collection__case-cloth {
  color: #815624;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.42px;
  background-color: #f1e2d2;
  padding-top: 68px;
  margin: 80px 0 60px;
  overflow: hidden;
}

.footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__container > * {
  position: relative;
  z-index: 1;
}

.footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__frame {
  margin-top: 0;
}

.footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__heading {
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.75px;
  margin-bottom: 27px;
  text-align: left;
}

.footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__heading + p {
  font-size: 15px;
  line-height: 2;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.footer-anniversary-10th-collection__case-cloth__img {
  margin-top: -24vw;
  width: auto;
  position: relative;
  z-index: 0 !important;
}

@media (min-width: 768px) {
  .footer-anniversary-10th-collection__case-cloth {
    padding-top: 0;
    margin-bottom: 113px;
  }
  .footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__container {
    display: grid;
    grid-template-columns: 65% auto;
    grid-template-areas: "img before" "img heading" "img des" "img after";
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__heading {
    grid-area: heading;
  }
  .footer-anniversary-10th-collection__case-cloth p {
    grid-area: des;
  }
  .footer-anniversary-10th-collection__case-cloth__img {
    margin-top: 0;
    margin-left: -15vw;
    margin-right: -90px;
    grid-area: img;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__heading br {
    display: none;
  }
}

@media (min-width: 1024px) {
  .footer-anniversary-10th-collection__case-cloth {
    margin-bottom: 106px;
  }
  .footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__container {
    grid-template-columns: 1fr 1fr;
  }
  .footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__heading {
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 1.2px;
    margin-bottom: 44px;
    padding-left: 92px;
  }
  .footer-anniversary-10th-collection__case-cloth .base-anniversary-10th-collection__heading + p {
    padding-left: 92px;
    margin-right: -15px;
    margin-bottom: 0;
  }
  .footer-anniversary-10th-collection__case-cloth__img {
    margin-right: -110px;
  }
}

@media (min-width: 1296px) {
  .footer-anniversary-10th-collection__case-cloth__img {
    margin-left: -208px;
    margin-right: -190px;
  }
}

.footer-anniversary-10th-collection__terms, .footer-anniversary-10th-collection__shops {
  margin-top: 60px;
}

.footer-anniversary-10th-collection__terms .base-anniversary-10th-collection__heading, .footer-anniversary-10th-collection__shops .base-anniversary-10th-collection__heading {
  color: black;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.72px;
  margin-bottom: 27px;
}

.footer-anniversary-10th-collection__terms ul, .footer-anniversary-10th-collection__shops ul {
  list-style-type: disc;
  margin-right: -20px;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-anniversary-10th-collection__terms ul > li, .footer-anniversary-10th-collection__shops ul > li {
  list-style-type: disc;
  color: black;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.39px;
}

.footer-anniversary-10th-collection__terms .base-anniversary-10th-collection__shops, .footer-anniversary-10th-collection__shops .base-anniversary-10th-collection__shops {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.footer-anniversary-10th-collection__terms .base-anniversary-10th-collection__shops > li, .footer-anniversary-10th-collection__shops .base-anniversary-10th-collection__shops > li {
  line-height: 2.5;
  width: 100%;
}

@media (min-width: 768px) {
  .footer-anniversary-10th-collection__terms .base-anniversary-10th-collection__shops > li, .footer-anniversary-10th-collection__shops .base-anniversary-10th-collection__shops > li {
    width: 33.333%;
  }
}

@media (min-width: 768px) {
  .footer-anniversary-10th-collection__terms, .footer-anniversary-10th-collection__shops {
    margin-top: 130px;
  }
  .footer-anniversary-10th-collection__terms .base-anniversary-10th-collection__heading, .footer-anniversary-10th-collection__shops .base-anniversary-10th-collection__heading {
    font-size: 40px;
    letter-spacing: 1.2px;
    margin-bottom: 48px;
  }
  .footer-anniversary-10th-collection__terms ul > li, .footer-anniversary-10th-collection__shops ul > li {
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.42px;
  }
}
