$base-img-url: 'https://static.lenskart.com/media/owndays/img/kuromi-collection';

$font-co: "Corporate Logo Rounded";
$font-ev: "Eveleth Clean";
$font-he: heisei-maru-gothic-std, sans-serif;

$black-2b: #2b2045;
$purple-bc: #bcadd3;

.footer-kuromi-collection {
  &__accessory {
    padding-top: 139px;
    background-color: $purple-bc;

    .base-kuromi-collection__container {
      padding-top: 0;
    }

    &__inner {
      background-color: white;
      padding: 1px 30px 65px;
      border: 5px solid $black-2b;
      border-radius: 20px;
      position: relative;
    }

    &__character {
      display: block;
      width: 190px;
      margin: -80px auto 33px;
      aspect-ratio: 65 / 63;
    }

    &__title {
      font: 400 20px / 1.7 $font-ev;
      letter-spacing: 0.06em;
      text-align: center;
      margin: 0;
    }

    &__img {
      display: block;
      margin: 33px auto 45px;
      max-width: 450px;
      aspect-ratio: 315 / 302;
    }

    &__detail {
      font: 700 12px / 2.5 $font-he;
      letter-spacing: 0.04em;
      position: relative;
      padding-top: 35px;
      margin: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: auto;
        left: 0;
        right: 0;
        height: 8px;
        background: url("#{$base-img-url}/dot.svg") top center repeat-x;
      }
    }

    @media (min-width: 768px) {
      .base-kuromi-collection__item-corner-top {
        &::before, &::after {
          top: 24px;
        }
      }
  
      .base-kuromi-collection__item-corner-bottom {
        &::before, &::after {
          bottom: 24px;
        }
      }

      &__inner {
        padding: 20px 30px 10px 80px;
        display: grid;
        grid-template-areas: "title img" "detail img";
        column-gap: 25px;
      }

      &__character {
        width: 140px;
        position: absolute;
        left: -80px;
        bottom: 0px;
      }

      &__title {
        text-align: left;
        grid-area: title;
        align-self: end;
      }

      &__character {
        width: 210px;
        left: -130px;
      }

      &__img {
        grid-area: img;
        margin: 0;
        aspect-ratio: 280 / 201;
      }

      &__detail {
        padding-top: 20px;
        grid-area: detail;

        &::before {
          content: none;
        }
      }
    }

    @media (min-width: 1024px) {
      &__inner {
        padding: 20px 15px 10px 100px;
        border-radius: 30px;
      }

      &__title {
        font-size: 28px;
      }

      &__img {
        max-width: none;
      }

      &__detail {
        width: 360px;
        font-size: 14px;
        padding-top: 30px;
      }
    }

    @media (min-width: 1296px) {
      &__inner {
        padding-right: 5px;
        grid-template-columns: auto 560px;
      }
    }
  }

  &__novelty {
    background-color: $purple-bc;
    padding-top: 73px;

    .base-kuromi-collection__button {
      width: 330px;
    }

    &__inner {
      background-color: white;
      padding: 50px 30px 65px;
      margin-top: 25px;
      border: 5px solid $black-2b;
      border-radius: 20px;
      position: relative;

      > small {
        font: 700 12px / 1 $font-he;
        padding: 10px 12px 7px;
        background-color: $black-2b;
        color: white;
        display: table;
        margin: 0 auto;
      }
    }

    &__title {
      font: 700 28px / 1 $font-co;
      letter-spacing: 0.04em;
      text-align: center;
      line-height: 1.5;
      margin-top: 23px;
      position: relative;
      z-index: 2;
      margin: 23px 0 0 0;

      small {
        display: block;
        font-size: 14px;
      }
    }

    &__img {
      display: block;
      width: 100%;
      height: auto;
      margin: -17px auto 0;
      max-width: 450px;
      aspect-ratio: 322 / 263;
    }

    &__detail {
      font: 800 13px / 2 $font-he;
      color: black;
      margin: 0;
    }

    &__note {
      font-size: 11px;
      font-weight: 400;
      display: block;
      margin-top: 18px;
      padding-left: 10px;
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-style: normal;
        background: none;
        content: '*';
      }
    }

    &__character {
      display: block;
      width: 76px;
      position: absolute;
      right: -20px;
      bottom: -23px;
      z-index: 2;
    }

    @media (min-width: 768px) {
      .base-kuromi-collection__container {
        padding-top: 0;
        padding-bottom: 0;
      }

      .base-kuromi-collection__button {
        max-width: none;
      }

      &__inner {
        display: grid;
        grid-template-areas:
        "img label"
        "img title"
        "img detail";
        grid-template-rows: 0fr auto 0fr;
        grid-template-columns: 55% auto;
        column-gap: 20px;
        padding: 50px 30px 50px 16px;

        > small {
          margin-left: 0;
          grid-area: label;
          align-self: end;
        }
      }

      &__title {
        grid-area: title;
        text-align: left;
        margin-bottom: 25px;
      }

      &__img {
        grid-area: img;
        align-self: center;
      }

      &__detail {
        grid-area: detail;
      }

      &__goforit {
        position: absolute;
        top: 58px;
        left: -73px;
        width: 140px;
      }
    }

    @media (min-width: 1024px) {
      padding-top: 110px;

      &__inner {
        margin-top: 35px;
        padding: 80px 78px 80px 16px;

        > small {
          font-size: 13px;
          letter-spacing: 0.12em;
          padding: 11px 12px 7px;
        }
      }

      &__title {
        font-size: 34px;

        small {
          font-size: 16px;
        }
      }

      &__character {
        width: 101px;
        right: -51px;
        bottom: -18px;
      }
    }

    @media (min-width: 1296px) {
      &__inner {
        grid-template-columns: 492px auto;
      }
    }
  }

  &__about {
    background-color: $purple-bc;
    padding: 30vw 0 32vw;

    &__inner {
      position: relative;
      margin-bottom: 50px;
      padding: 20px 30px 30px;
      background: url("#{$base-img-url}/about-bg-body-sp.webp") top left / contain repeat-y;

      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 13vw;
        background: url("#{$base-img-url}/about-bg-top-sp.webp") bottom left / contain no-repeat;
      }

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 8vw;
        background: url("#{$base-img-url}/about-bg-bottom-sp.webp") top left / contain no-repeat;
      }
    }

    &__character {
      display: block;
      width: 189px;
      height: auto;
      margin: 0 auto;
      grid-area: img;
      aspect-ratio: 193 / 220;
    }

    &__title {
      margin: 30px 0 18px;
      font: 700 21px / 1.7 $font-co;
    }

    &__detail {
      font: 800 13px / 2.3 $font-he;
      margin-bottom: 20px;
    }

    &__el {
      position: absolute;
      z-index: 3;

      &--1 {
        top: -18vw;
        right: -9vw;
        width: 33vw;
      }

      &--2 {
        top: -14vw;
        left: -7vw;
        width: 22vw;
      }

      &--3 {
        bottom: -11vw;
        right: -7vw;
        width: 22vw;
      }

      &--4 {
        bottom: -16vw;
        left: -10vw;
        width: 31vw;
        max-width: 150px;
      }
    }

    @media (min-width: 768px) {
      padding-top: 135px;
      padding-bottom: 110px;

      .base-kuromi-collection__container {
        padding-top: 0;
        padding-bottom: 0;
      }

      .base-kuromi-collection__button {
        margin-left: 0;
      }

      &__inner {
        margin-left: 45px;
        background: url("#{$base-img-url}/about-bg-body-pc.webp") top left / contain repeat-x;
        display: grid;
        grid-template-areas:
        "title img"
        "detail img"
        "button img";
        grid-template-columns: 55% auto;
        -webkit-column-gap: 25px;
        column-gap: 25px;
        padding: 55px 30px;

        &::before {
          left: -31px;
          top: 0;
          bottom: 0;
          right: 0;
          height: unset;
          background: url("#{$base-img-url}/about-bg-left-pc.webp") top left / contain no-repeat;
          pointer-events: none;
        }

        &::after {
          left: 0;
          top: 0;
          bottom: 0;
          right: -7px;
          height: unset;
          background: url("#{$base-img-url}/about-bg-right-pc.webp") top right / contain no-repeat;
          pointer-events: none;
        }
      }

      &__character {
        width: 100%;
      }

      &__title {
        text-align: left;
      }

      &__el {
        &--1 {
          top: -81px;
          right: -4vw;
          width: 178px;
        }

        &--2 {
          top: -25px;
          left: -7vw;
          width: 108px;
        }

        &--3 {
          bottom: -11px;
          right: -3vw;
          width: 108px;
        }

        &--4 {
          bottom: -40px;
          left: -10vw;
          width: 140px;
        }
      }
    }

    @media (min-width: 1024) {
      padding-bottom: 120px;
    }

    @media (min-width: 1296px) {
      &__inner {
        grid-template-columns: auto 313px;
        column-gap: 50px;
        padding: 100px 50px;

        &::before {
          left: -37px;
        }
      }

      &__el {
        &--1 {
          top: -49px;
          right: 37px;
        }

        &--2 {
          width: 130px;
          top: -25px;
          left: -69px;
        }

        &--3 {
          bottom: -14px;
          right: -37px;
          width: 130px;
        }

        &--4 {
          bottom: 27px;
          left: -98px;
        }
      }
    }
  }

  &__share {
    color: white;
    margin-top: -88px;
    padding: 0 0 55px;
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $black-2b;
      -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 12%, 100% 100%, 0% 100%);
      z-index: -1;
    }

    &__social {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 56px;
      margin-bottom: 56px;
      padding-left: 0;

      &-item {
        background-color: white;
        width: 66px;
        height: 66px;
        margin: 0 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        transition: 0.4s;

        &::before {
          content: '';
          width: 25px;
          height: 25px;
          background: center/contain no-repeat;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: transparent !important;
          overflow: hidden;
          white-space: nowrap;
          transition: 0.5s;
        }

        &--fb {
          &::before {
            width: 19px;
            background-image: url("#{$base-img-url}/icon-fb.svg");
          }
        }

        &--tw {
          &::before {
            background-image: url("#{$base-img-url}/icon-tw.svg");
          }
        }

        &--line {
          &::before {
            background-image: url("#{$base-img-url}/icon-line.svg");
          }
        }
      }
    }

    &__copyright {
      font: 300 11px / 2 "Noto Sans", sans-serif;
      text-align: center;
      margin: 0;
    }

    @media (min-width: 768px) {
      padding: 0 0 30px;

      &::before {
        top: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);
      }

      &__social {
        margin-top: 60px;
        margin-bottom: 83px;

        &-item {
          width: 73px;
          height: 73px;
          margin: 0 15px;

          &::before {
            width: 28px;
            height: 28px;
          }

          &:hover {
            background-color: black;
            opacity: 1;
            &::before {
              filter: invert(1) brightness(2);
            }
          }

          &--fb {
            &::before {
              width: 21px;
            }
          }
        }
      }

      &__copyright {
        font-size: 12px;
      }
    }

    @media (min-width: 1024px) {
      margin-top: -113px;

      &::before {
        top: -9px;
        bottom: 0;
        left: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
      }
    }
  }
}
