$color-light: #f7f9fc;

$font-din: din-2014, sans-serif;
$font-noto-jp: "Noto Sans JP", sans-serif;
$font-outfit: "Outfit", sans-serif;

.footer-ortho-k-lens {
  &__faq{
    margin-top: 70px;
    @media (max-width: 1023px) {
      margin-bottom: 70px;
    }
    
    .base-ortho-k-lens__title{
      @media (max-width: 1023px) {
        text-align: center;
      }
    }

    &__q-a{
      &__accordion {
        margin-top: 40px;
        padding-left: 0;
        list-style-type: none;
        
        >li {
          border-bottom: 3px solid #000;

          &:first-child {
            border-top: 3px solid #000;
          }
        }

        &__title {
          padding: 23px 14px;
          cursor: pointer;
          position: relative;
          user-select: none;
        }

        &__content {
          height: 0;
          overflow: hidden;
          transition: height 0.35s ease;
        }
      }

      &__q-title {
        font-size: 13px;
        letter-spacing: 0.06em;
        line-height: 1.5;
        display: flex;
        column-gap: 12px;
        font-weight: 700 !important;
        font-family: $font-noto-jp;
        margin: 0;

        @media (max-width: 767px) {
          align-items: center;
        }

        &::before {
          content: 'Q';
          font: 700 15px/1 $font-outfit;
          min-width: 28px;
          height: 30px;
          background-color: #000;
          color: #fff;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          display: flex;
          letter-spacing: .06em;
          align-items: center;
        }
      }

      &__a-inner {
        font-size: 15px;
        font-weight: 500 !important;
        letter-spacing: 0.06em;
        line-height: 2.15;
        display: flex;
        align-items: flex-start;
        column-gap: 12px;
        padding: 23px 14px;
        background-color: #f8f8f8;
        
        &::before {
          content: 'A';
          display: flex;
          font: 700 15px/1 $font-outfit;
          min-width: 28px;
          height: 30px;
          background-color: #fff;
          border: 1px solid #000;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          letter-spacing: .06em;
        }

        p {
          flex: 1;
          margin: 0;
          font-weight: normal !important;
          line-height: 2;
        }
      }

      @media (min-width: 768px) {
        &__accordion {
          &__title {
            padding: 35px 21px;
          }
        }

        &__q-title {
          font-size: 15px;
          column-gap: 21px;
        }

        &__a-inner {
          font-size: 13px;
          column-gap: 21px;
          padding: 31px 21px;
        }
      }
    }

    &__link-color {
      color: #2e5aa8;
      font-weight: bold !important;
      text-decoration: underline;
    }

    @media (min-width: 1024px) {
      display: grid;
      margin-top: 130px;
      padding-bottom: 138px;
      grid-template-columns: auto 843px;

      &__title {
        min-width: 277px;
        font-size: 51px !important;
        text-align: left;
      }

      &__q-a {
        &__accordion {
          margin-top: 0px;
        }
      }
      
      &__link-color {
        &:hover, &:active, &:visited, &:focus {
          color: #2e5aa8;
        }
      }
    }
  }

  &__contact-lenses{
    position: relative;
    padding: 70px 0;
    margin-bottom: 120px;

    &:before{
      content: "";
      position: absolute;
      top:0;
      left:-100vw;
      right:-100vw;
      bottom: 0;
      background-color: $color-light;
      z-index: -1;
    }

    &__title {
      h3 {
        font: 700 28px/1.3 $font-din !important;
        letter-spacing: .06em !important;
        line-height: 34px !important;
        text-align: center;
        margin: 0;
      }
    }

    &__list {
      margin-top: 40px;
      ul {
        display: grid;
        gap: 10px;
        padding-left: 0;
        margin: 0;
        list-style-type: none;

        li:hover {
          a img {
            opacity: 0.6;
            transition: 0.5s;
          }
        }
      }
    }

    @media (min-width: 500px) {
      &__list {
        ul {
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        width: fit-content;

        h3 {
          letter-spacing: .045em !important;
          line-height: 1.3 !important;
          text-align: left;
          font-size: clamp(35.2px, 3.34vw, 53.44px) !important;
        }
      }

      &__list{
        width: 66%;
        margin-top: 0;
      }
    }
  }
}
