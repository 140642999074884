.header-senichisaku__main {
  text-align: center;
}

.header-senichisaku__main picture img {
  display: block;
  width: 100%;
}

.header-senichisaku__main .base-senichisaku__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header-senichisaku__main__title {
  font-size: 0;
  line-height: 1;
  margin-bottom: 25px;
}

.header-senichisaku__main__title img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 167px;
  margin: 0 auto 7px;
}

.header-senichisaku__main__text {
  font-size: 17px;
  line-height: 1.65;
  margin: 0;
}

@media (min-width: 1024px) {
  .header-senichisaku__main__title {
    margin-bottom: 50px;
  }
  .header-senichisaku__main__title img {
    max-width: 340px;
    margin-bottom: 6px;
  }
  .header-senichisaku__main__text {
    font-size: 28px;
  }
}
