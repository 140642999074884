.body-air__styles {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.body-air__styles--reverse {
  padding-top: 100px;
}

.body-air__styles__item:nth-child(even) .body-air__styles__features .base-air__container::before {
  left: 40px;
  right: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 40px;
}

.body-air__styles__top {
  position: relative;
}

.body-air__styles__top-img {
  overflow: hidden;
}

.body-air__styles__top-img-slider {
  aspect-ratio: 130 / 203;
}

.body-air__styles__top-img-slider img {
  display: block;
}

.body-air__styles__top-text h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 27px;
  font-weight: 600 !important;
  line-height: 40px;
  letter-spacing: 1.68px;
  margin-bottom: 32px;
  color: black;
}

.body-air__styles__top-text h2 span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: inherit;
  overflow: hidden;
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
  position: relative;
}

.body-air__styles__top-text h2 span::after {
  content: "";
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 0%;
  height: 90%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: -1;
}

.body-air__styles__top-text h2.aos-animate span {
  -webkit-animation: textanicolor 1.5s forwards;
          animation: textanicolor 1.5s forwards;
}

.body-air__styles__top-text h2.aos-animate span::after {
  -webkit-animation: textani 1.5s forwards;
          animation: textani 1.5s forwards;
}

.body-air__styles__top-text-details h3, .body-air__styles__top-text-details p {
  font-family: "Founders Grotesk", sans-serif;
  color: black;
}

.body-air__styles__top-text-details h3 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .72px;
  padding-bottom: 8px;
  border-bottom: 1px solid black;
  margin-bottom: 16px;
  line-height: 40px;
}

.body-air__styles__top-text-details h3 span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: inherit;
  overflow: hidden;
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
  position: relative;
}

.body-air__styles__top-text-details h3 span::before {
  content: "";
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 0%;
  height: 90%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: -1;
}

.body-air__styles__top-text-details h3.aos-animate span {
  -webkit-animation: textanicolor 1.5s forwards;
          animation: textanicolor 1.5s forwards;
}

.body-air__styles__top-text-details h3.aos-animate span::before {
  -webkit-animation: textani 1.5s forwards;
          animation: textani 1.5s forwards;
}

.body-air__styles__top-text-details p {
  font-weight: normal !important;
  letter-spacing: .56px;
  line-height: 32px;
  margin-bottom: 20px;
}

.body-air__styles__top-text-details.aos-animate span {
  -webkit-animation: textanicolor 1.5s forwards;
          animation: textanicolor 1.5s forwards;
}

.body-air__styles__top-text-details.aos-animate span::before {
  -webkit-animation: textani 1.5s forwards;
          animation: textani 1.5s forwards;
}

.body-air__styles__top-text-details-img {
  position: relative;
  width: 100%;
  max-width: 310px;
  margin-left: 20px;
}

.body-air__styles__top-text-details-img span {
  display: block;
  width: 180px;
  height: 90px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 1px 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.body-air__styles__top-text-details-img img {
  display: block;
  position: relative;
  max-width: 160px;
  margin-left: auto;
  z-index: 1;
  aspect-ratio: 1 / 1;
}

.body-air__styles__top-text-details-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 160px;
  border-radius: 10rem;
  background-color: black;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .body-air__styles--reverse .body-air__styles__top-img {
    left: 0;
    right: calc(50% + 40px);
  }
  .body-air__styles--reverse .body-air__styles__top-text-details,
  .body-air__styles--reverse .body-air__styles__top-text h2 {
    max-width: 50%;
    margin-left: auto;
  }
  .body-air__styles--reverse .body-air__styles__top-img-slider > .slick-list > .slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
  }
  .body-air__styles__top {
    margin-bottom: 30px;
  }
  .body-air__styles__top-img {
    position: absolute;
    top: 0;
    left: calc(50% + 40px);
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .body-air__styles__top-img-slider {
    aspect-ratio: 34 / 39;
  }
  .body-air__styles__top-text-details {
    max-width: 50%;
  }
  .body-air__styles__top-text-details-img {
    margin-left: auto;
  }
  .body-air__styles__top-text .base-air__container h2 {
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .body-air__styles--reverse {
    padding-top: 120px;
  }
  .body-air__styles__top {
    min-height: 780px;
    margin-bottom: 60px;
  }
  .body-air__styles__top-img-slider img {
    height: 780px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: left top;
       object-position: left top;
  }
  .body-air__styles__top-text {
    padding-top: 56px;
    padding-left: 40px;
  }
  .body-air__styles__top-text h2 {
    max-width: 54%;
    font-size: 36px;
    line-height: 61.2px;
    letter-spacing: 0.72px;
    margin-bottom: 45px;
  }
  .body-air__styles__top-text-details {
    max-width: 50%;
  }
  .body-air__styles__top-text-details h3 {
    font-size: 24px;
    letter-spacing: 1.44px;
    padding-bottom: 16px;
    margin-bottom: 24px;
  }
  .body-air__styles__top-text-details p {
    font-size: 16px;
    margin-bottom: 40px;
    letter-spacing: 0.48px;
  }
  .body-air__styles__top-text-details-img {
    margin-left: auto;
    max-width: 500px;
  }
  .body-air__styles__top-text-details-img span {
    width: 240px;
    height: 110px;
  }
  .body-air__styles__top-text-details-img img {
    max-width: 220px;
  }
}

.body-air__features {
  position: relative;
}

.body-air__features .base-air__container {
  position: relative;
  padding-bottom: 40px;
}

.body-air__features .base-air__container::before {
  content: "";
  position: absolute;
  inset: 16px 40px 0 0;
  background-color: #f7f6f2;
  border-bottom-right-radius: 40px;
  z-index: -1;
}

.body-air__features h3 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 32px;
  font-weight: 700 !important;
  line-height: 1;
  letter-spacing: 1.28px;
  margin-bottom: 40px;
  color: black;
}

.body-air__features ul {
  list-style-type: none;
  margin: 0;
  display: grid;
  gap: 30px 26px;
  padding: 0;
}

.body-air__features ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: white;
  border-radius: 100rem;
  padding-right: 18px;
}

.body-air__features ul li span {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 10rem;
  background-color: white;
  border: 1px solid black;
  display: grid;
  place-content: center;
}

.body-air__features ul li span img {
  max-width: 68px;
}

.body-air__features ul li small, .body-air__features ul li p {
  color: black;
}

.body-air__features ul li small {
  position: absolute;
  top: 0;
  left: 104px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: .8px;
  padding-inline: 4px;
  -webkit-transform: translateY(-58%);
          transform: translateY(-58%);
}

.body-air__features ul li small::before {
  content: "";
  position: absolute;
  top: 58%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: white;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}

.body-air__features ul li p {
  width: 100%;
  height: auto;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 13px;
  font-weight: 500 !important;
  letter-spacing: .52px;
  margin: 0;
}

@media (min-width: 768px) {
  .body-air__features .base-air__container::before {
    top: 24px;
    left: -1000%;
    right: 100px;
    border-bottom-right-radius: 60px;
  }
  .body-air__features ul {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px 20px;
  }
  .body-air__features ul li {
    grid-column: span 2;
  }
  .body-air__features ul li span {
    width: 120px;
    height: 120px;
  }
  .body-air__features ul li small {
    margin-left: 20px;
    font-size: 28px;
    letter-spacing: 2px;
  }
  .body-air__features ul li p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.42px;
  }
  .body-air__features ul li:last-child {
    grid-column: 2 / span 2;
  }
}

@media (min-width: 1024px) {
  .body-air__features .base-air__container {
    padding-bottom: 100px;
  }
  .body-air__features .base-air__container::before {
    top: 24px;
    left: -1000%;
    right: 100px;
    border-bottom-right-radius: 60px;
  }
  .body-air__features h3 {
    font-size: 48px;
    letter-spacing: 1.92px;
    margin-bottom: 70px;
  }
  .body-air__features ul {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 20px;
  }
  .body-air__features ul li {
    grid-column: span 1;
  }
  .body-air__features ul li:last-child {
    grid-column: unset;
  }
}

.body-air__description {
  padding-block: 40px 55px;
}

.body-air__description h3, .body-air__description p {
  font-family: "Founders Grotesk", sans-serif;
  color: black;
}

.body-air__description h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 170%;
  letter-spacing: .72px;
  margin-block: 60px 20px;
}

.body-air__description p {
  position: relative;
  font-size: 14px;
  font-weight: 400 !important;
  letter-spacing: 0.48px;
  line-height: 32px;
  max-width: 730px;
  margin-inline: auto;
  -webkit-mask-image: linear-gradient(290deg, transparent 30%, black 65%, black);
          mask-image: linear-gradient(290deg, transparent 30%, black 65%, black);
  -webkit-mask-position: 100% 0;
          mask-position: 100% 0;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: 300% 100%;
          mask-size: 300% 100%;
  opacity: 0;
}

.body-air__description p.aos-animate {
  -webkit-mask-position: 0 0;
          mask-position: 0 0;
  opacity: 1;
  -webkit-transition: opacity 1200ms, -webkit-mask-position 2000ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: opacity 1200ms, -webkit-mask-position 2000ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: opacity 1200ms, mask-position 2000ms cubic-bezier(0.5, 1, 0.89, 1);
  transition: opacity 1200ms, mask-position 2000ms cubic-bezier(0.5, 1, 0.89, 1), -webkit-mask-position 2000ms cubic-bezier(0.5, 1, 0.89, 1);
}

@media (min-width: 768px) {
  .body-air__description .base-air__btn {
    max-width: 340px;
    margin-inline: auto;
  }
}

@media (min-width: 1024px) {
  .body-air__description {
    padding-block: 50px 105px;
  }
  .body-air__description h3 {
    font-size: 24px;
    letter-spacing: .96px;
    margin-block: 80px 16px;
  }
}

.body-air__lineup {
  position: relative;
  padding-bottom: 100px;
}

.body-air__lineup::before {
  content: "";
  position: absolute;
  inset: 16px 0 0;
  background-color: #f7f6f2;
  z-index: -1;
  top: 19px;
}

.body-air__lineup h3 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 32px;
  font-weight: 700 !important;
  line-height: 1;
  letter-spacing: 1.28px;
  margin-bottom: 40px;
}

.body-air__lineup__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 60px 20px;
  grid-template-columns: 1fr;
}

.body-air__lineup__item {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  position: relative;
}

.body-air__lineup__item .base-air__btn {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 390px) {
  .body-air__lineup__item .base-air__btn {
    width: 326px;
    max-width: 326px;
  }
}

.body-air__lineup__item > .slick-slider {
  overflow: hidden;
}

.body-air__lineup__item > .slick-slider .slick-dots {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  gap: 4px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 16px 0 24px 0;
  list-style-type: none;
  margin-bottom: 0;
}

.body-air__lineup__item > .slick-slider .slick-dots > li > button {
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 10rem;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.body-air__lineup__item > .slick-slider .slick-dots > li > button img {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: inherit;
}

.body-air__lineup__item > .slick-slider .slick-dots .slick-active {
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
}

.body-air__lineup__item::before {
  content: attr(data-model);
  position: absolute;
  bottom: 71px;
  left: 0;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 500 !important;
  letter-spacing: .36px;
  color: black;
}

@media (min-width: 768px) {
  .body-air__lineup__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .body-air__lineup__item:last-child {
    width: 50%;
    margin: 0 auto;
    grid-column: 1 / span 2;
    grid-row: 2;
  }
  .body-air__lineup__item::before {
    bottom: 69px;
  }
}

@media (min-width: 1024px) {
  .body-air__lineup::before {
    top: 24px;
  }
  .body-air__lineup h3 {
    font-size: 48px;
    letter-spacing: 1.92px;
    margin-bottom: 70px;
  }
  .body-air__lineup__list {
    grid-template-columns: repeat(3, 1fr);
  }
  .body-air__lineup__item {
    grid-column: span 1;
  }
  .body-air__lineup__item:last-child {
    width: 100%;
    grid-column: unset;
    grid-row: unset;
  }
  .body-air__lineup__item .base-air__btn {
    width: 340px;
    margin: 0 auto;
  }
  .body-air__lineup__item::before {
    font-size: 22px;
    letter-spacing: 0.88px;
    bottom: 80px;
  }
}

@-webkit-keyframes textanicolor {
  0% {
    color: black;
  }
  50% {
    color: white;
  }
  100% {
    color: black;
  }
}

@keyframes textanicolor {
  0% {
    color: black;
  }
  50% {
    color: white;
  }
  100% {
    color: black;
  }
}

@-webkit-keyframes textani {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 102%;
  }
}

@keyframes textani {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 102%;
  }
}
