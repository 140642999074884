.button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  color: #000;
  min-height: 50px;
  max-height: 50px;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s ease;
  text-decoration: none;
  &:hover,&:focus{
    text-decoration: none;
    color: #000;
    border-color: #000;
    outline: none;
  }
  &--primary,&--primary:hover,&--primary:focus{
    background-color: #000;
    color: #fff;
  }
  &--secondary,&--secondary:hover,&--secondary:focus{
    color: #666;
    border-color: #666;
    background-color: #fff;
  }
  @media (min-width: 1024px) {
    &--primdary{
      &:hover{
        color: #fff;
        background-color: #999;
        border-color: #999;
      }
      &:focus{
        background-color: #000;
        color: #fff;
      }
    }
    &--secondary{
      &:hover{
        background-color: #666;
        color: #fff;
      }
      &:focus{
        color: #666;
        background-color: #fff;
      }
    }
  }
}

.button-group{
  display: grid;
  gap: 16px;
  @media (min-width: 768px) {
    &--2{
      grid-template-columns: repeat(2,1fr);
    }
    &--3{
      grid-template-columns: repeat(3,1fr);
    }
    &--4{
      grid-template-columns: repeat(4,1fr);
    }
  }
}