.social-media-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 28px;
  padding: 15px 0 0 0;
}

.social-media-links-mweb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 28px;
  margin: 0;
  padding: 15px 0 15px 0;
  list-style-type: none;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
