.header-tom-and-jerry__main {
  position: relative;
  padding-block: 124px 199px;
  background: #fde360 url("https://static.lenskart.com/owndays/img/tomandjerry/main-bg_sp.svg") no-repeat left 18px top 21px/100% auto;
}

.header-tom-and-jerry__main::after {
  content: "";
  position: absolute;
  top: calc(100% - 77px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 180vw;
  height: 700px;
  background-color: #15a9d8;
  border-radius: 100%;
}

.header-tom-and-jerry__main__top {
  position: absolute;
  top: 0;
  left: 0;
}

.header-tom-and-jerry__main__logo {
  font: 0/0 a;
}

.header-tom-and-jerry__main__logo img {
  display: block;
  aspect-ratio: 305/187;
  max-width: 179px;
  margin-inline: auto;
}

.header-tom-and-jerry__main__content {
  position: relative;
  width: 100%;
  height: 310px;
}

.header-tom-and-jerry__main__img {
  position: absolute;
  z-index: 1;
}

.header-tom-and-jerry__main__img--tom {
  top: 0;
  left: calc(50% - 55px);
}

.header-tom-and-jerry__main__img--tom img {
  aspect-ratio: 438/253;
  width: 386px;
  height: 223px;
  max-width: none;
}

.header-tom-and-jerry__main__img--jn {
  bottom: 0;
  right: calc(50% - 30px);
}

.header-tom-and-jerry__main__img--jn img {
  aspect-ratio: 227/245;
  width: 206px;
  height: 154px;
}

.header-tom-and-jerry__main__img--85y {
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header-tom-and-jerry__main__img--85y img {
  aspect-ratio: 185/186;
  max-width: 146px;
}

@media (max-width: 1023px) {
  .header-tom-and-jerry__main__logo {
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .header-tom-and-jerry__main {
    padding-block: 305px 653px;
    background: #fde360 url("https://static.lenskart.com/owndays/img/tomandjerry/main-bg_pc.svg") no-repeat right 82px top 91px/100% auto;
  }
  .header-tom-and-jerry__main::after {
    width: 115vw;
    height: 1196px;
    top: calc(100% - 304px);
  }
  .header-tom-and-jerry__main__logo img {
    max-width: 305px;
  }
  .header-tom-and-jerry__main__content {
    width: 1735px;
    height: 503px;
    position: absolute;
    top: 313px;
    left: calc(50% - 665px);
  }
  .header-tom-and-jerry__main__img--tom {
    left: auto;
    right: 0;
  }
  .header-tom-and-jerry__main__img--tom img {
    width: 871px;
    height: 503px;
  }
  .header-tom-and-jerry__main__img--jn {
    top: 108px;
    left: 0;
    right: auto;
    bottom: auto;
  }
  .header-tom-and-jerry__main__img--jn img {
    width: 465px;
    height: 348px;
  }
  .header-tom-and-jerry__main__img--85y {
    top: 25px;
    right: 25px;
    left: auto;
    bottom: auto;
    -webkit-transform: none;
            transform: none;
  }
  .header-tom-and-jerry__main__img--85y img {
    max-width: 184px;
  }
}

.header-tom-and-jerry__about {
  position: relative;
  background-color: #15a9d8;
  padding-block: 45px 70px;
}

.header-tom-and-jerry__about__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
}

.header-tom-and-jerry__about__content p {
  font-family: "Karu";
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 41px;
  letter-spacing: 1px;
  margin: 0 auto 0 auto;
}

.header-tom-and-jerry__about__content p em {
  background-color: #e1002a;
  font-style: normal;
  padding: 0 4px;
}

.header-tom-and-jerry__about__content img {
  display: block;
  aspect-ratio: 32/35;
  max-width: 288px;
  margin-inline: auto;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__about__content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 0;
  }
  .header-tom-and-jerry__about__content p {
    width: 50%;
    text-align: left;
    margin: 0;
    padding-right: 38px;
  }
  .header-tom-and-jerry__about__content p br {
    display: none;
  }
}

@media (min-width: 1024px) {
  .header-tom-and-jerry__about {
    padding-block: 0 114px;
    margin-top: -90px;
    background-color: transparent;
  }
  .header-tom-and-jerry__about::before {
    content: "";
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #15a9d8;
  }
  .header-tom-and-jerry__about__content {
    position: relative;
  }
  .header-tom-and-jerry__about__content p {
    font-size: 20px;
    line-height: 49px;
  }
  .header-tom-and-jerry__about__content img {
    max-width: 439px;
  }
}

.header-tom-and-jerry__lineup-menu {
  position: relative;
  background-color: #0f99c4;
}

.header-tom-and-jerry__lineup-menu__layout {
  width: 100%;
  height: auto;
  padding: 70px 0;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu__layout {
    padding: 160px 0 106px;
  }
}

@media (min-width: 1024px) {
  .header-tom-and-jerry__lineup-menu__layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 70px;
  }
}

.header-tom-and-jerry__lineup-menu__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 89px;
  aspect-ratio: 218/89;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}

.header-tom-and-jerry__lineup-menu__header h2 {
  margin-bottom: 0;
}

.header-tom-and-jerry__lineup-menu__header-logo {
  width: 162px;
  height: auto;
}

.header-tom-and-jerry__lineup-menu__header p {
  color: #2b2926;
  font-family: "Karu";
  font-size: 20px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 0;
  white-space: nowrap;
}

@media (min-width: 1024px) {
  .header-tom-and-jerry__lineup-menu__header {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    margin: 0 0 0 20px;
    max-height: none;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    gap: 33px;
  }
  .header-tom-and-jerry__lineup-menu__header-logo {
    width: 276px;
  }
  .header-tom-and-jerry__lineup-menu__header p {
    font-size: 26px;
    letter-spacing: 1.3px;
  }
}

@media (min-width: 1200px) {
  .header-tom-and-jerry__lineup-menu__header {
    margin: 0 0 0 60px;
  }
}

.header-tom-and-jerry__lineup-menu__title {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 3.19px;
}

.header-tom-and-jerry__lineup-menu__title h3 {
  color: black;
  font-family: "GenEi POPle Pw";
  font-size: 16px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 19.19px;
  margin: 0;
}

.header-tom-and-jerry__lineup-menu__title img {
  display: block;
  width: 63px;
  height: auto;
  aspect-ratio: 3.94/1;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu__title {
    gap: 5.89px;
  }
  .header-tom-and-jerry__lineup-menu__title h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .header-tom-and-jerry__lineup-menu__title img {
    width: 79px;
  }
}

.header-tom-and-jerry__lineup-menu__character {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.header-tom-and-jerry__lineup-menu__arrow::before {
  content: '';
  position: absolute;
  width: 29.639px;
  height: 29.639px;
  background-image: url("https://static.lenskart.com/owndays/img/tomandjerry/lineup_arrow-icon-menu.svg");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  bottom: 7.54px;
  right: 7.54px;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu__arrow::before {
    width: 44.638px;
    height: 44.638px;
  }
}

.header-tom-and-jerry__lineup-menu ul {
  list-style-type: none !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 8.41px;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu ul {
    gap: 12.644px;
  }
}

@media (min-width: 1200px) {
  .header-tom-and-jerry__lineup-menu ul {
    margin: 0 60px 0 0;
  }
}

.header-tom-and-jerry__lineup-menu ul li {
  width: 100%;
  max-height: 278px;
  height: auto;
  border: 2px solid black;
  position: relative;
}

@media (max-width: 390px) {
  .header-tom-and-jerry__lineup-menu ul li {
    width: 168.651px;
    height: 184.587px;
    aspect-ratio: 168.651 / 184.587;
  }
}

@media (min-width: 1024px) {
  .header-tom-and-jerry__lineup-menu ul li {
    cursor: pointer;
    border: 2.678px solid black;
    width: 254px;
    height: 278px;
    /* Top-left (horizontal line) */
    /* Bottom-right (horizontal line) */
    /* Bottom-left (vertical line) */
    /* Top-right (vertical line) */
    /* Hover effect */
  }
  .header-tom-and-jerry__lineup-menu ul li::after, .header-tom-and-jerry__lineup-menu ul li::before,
  .header-tom-and-jerry__lineup-menu ul li span::after,
  .header-tom-and-jerry__lineup-menu ul li span::before {
    content: "";
    position: absolute;
    background-color: white;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .header-tom-and-jerry__lineup-menu ul li::after {
    width: 0;
    height: 2px;
    top: 0;
    left: 0;
  }
  .header-tom-and-jerry__lineup-menu ul li::before {
    width: 0;
    height: 2px;
    bottom: 0;
    right: 0;
  }
  .header-tom-and-jerry__lineup-menu ul li span::after {
    width: 2px;
    height: 0;
    bottom: 0;
    left: 0;
  }
  .header-tom-and-jerry__lineup-menu ul li span::before {
    width: 2px;
    height: 0;
    top: 0;
    right: 0;
  }
  .header-tom-and-jerry__lineup-menu ul li:hover {
    border-color: transparent;
  }
  .header-tom-and-jerry__lineup-menu ul li:hover::after, .header-tom-and-jerry__lineup-menu ul li:hover::before {
    width: 100%;
  }
  .header-tom-and-jerry__lineup-menu ul li:hover span::after,
  .header-tom-and-jerry__lineup-menu ul li:hover span::before {
    height: 100%;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(1) {
  background-color: #ec7497;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(1) .header-tom-and-jerry__lineup-menu__title {
  top: 12.28px;
  right: 12.3px;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(1) .header-tom-and-jerry__lineup-menu__title h3 {
  margin-left: auto;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(1) .header-tom-and-jerry__lineup-menu__title {
    top: 20px;
    right: 20px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(1) .header-tom-and-jerry__lineup-menu__character {
  aspect-ratio: 21/20;
  padding: 21.25px 11.53px 22.85px 9.3px;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(1) .header-tom-and-jerry__lineup-menu__character {
    padding: 32px 17.36px 34.42px 14px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(2) {
  background-color: #00ac87;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(2) .header-tom-and-jerry__lineup-menu__title {
  top: 12.28px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(2) .header-tom-and-jerry__lineup-menu__title h3 {
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(2) .header-tom-and-jerry__lineup-menu__title img {
  margin: 0 auto;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(2) .header-tom-and-jerry__lineup-menu__title {
    top: 20px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(2) .header-tom-and-jerry__lineup-menu__character {
  aspect-ratio: 29/20;
  padding: 55.15px 11.38px 27.63px 12.2px;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(2) .header-tom-and-jerry__lineup-menu__character {
    padding: 83.06px 17.52px 41.61px 18px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(3) {
  background-color: #7b868c;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(3) .header-tom-and-jerry__lineup-menu__title {
  top: 12.28px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(3) .header-tom-and-jerry__lineup-menu__title h3 {
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(3) .header-tom-and-jerry__lineup-menu__title img {
  margin: 0 auto;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(3) .header-tom-and-jerry__lineup-menu__title {
    top: 20px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(3) .header-tom-and-jerry__lineup-menu__character {
  aspect-ratio: 91/100;
  padding: 58.77px 12.79px 23.86px 13.6px;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(3) .header-tom-and-jerry__lineup-menu__character {
    padding: 84px 19.74px 40.45px 20px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(4) {
  background-color: #fae445;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(4) .header-tom-and-jerry__lineup-menu__title {
  top: 12.28px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(4) .header-tom-and-jerry__lineup-menu__title h3 {
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(4) .header-tom-and-jerry__lineup-menu__title img {
  margin: 0 auto;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(4) .header-tom-and-jerry__lineup-menu__title {
    top: 20px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:nth-child(4) .header-tom-and-jerry__lineup-menu__character {
  aspect-ratio: 31/20;
  padding: 61.46px 13.74px 30.84px 12.94px;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu ul li:nth-child(4) .header-tom-and-jerry__lineup-menu__character {
    padding: 91px 25.2px 41.6px 25px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:last-child {
  max-width: none;
  background-color: #15a9d8;
  grid-column: span 2;
  aspect-ratio: 19/11;
  margin: 0 auto;
  display: none;
}

.header-tom-and-jerry__lineup-menu ul li:last-child .header-tom-and-jerry__lineup-menu__title {
  left: 12.46px;
  bottom: 12.2px;
}

.header-tom-and-jerry__lineup-menu ul li:last-child .header-tom-and-jerry__lineup-menu__title img {
  width: 124px;
  aspect-ratio: 31/4;
}

@media (min-width: 1296px) {
  .header-tom-and-jerry__lineup-menu ul li:last-child .header-tom-and-jerry__lineup-menu__title {
    left: 19.79px;
    bottom: 19.46px;
  }
  .header-tom-and-jerry__lineup-menu ul li:last-child .header-tom-and-jerry__lineup-menu__title img {
    width: 154px;
  }
}

.header-tom-and-jerry__lineup-menu ul li:last-child .header-tom-and-jerry__lineup-menu__character {
  aspect-ratio: 63/16;
  padding: 17.92px 49.2px 53.46px 50px;
}

@media (min-width: 768px) {
  .header-tom-and-jerry__lineup-menu ul li:last-child .header-tom-and-jerry__lineup-menu__character {
    padding: 35px 74.41px 72.5px 93px;
  }
}
