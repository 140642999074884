.membership-discount__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  grid-column: span 2;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  background-color: #E9EEF6;
  margin: 0 -16px;
}

.membership-discount__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  cursor: pointer;
}

.membership-discount__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

@media (min-width: 767px) {
  .membership-discount__details {
    font-size: 16px;
  }
}
