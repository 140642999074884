.hto-hero {
  padding: 120px 170px 60px;

  &__layout {
    max-width: 1040px;
    margin: 0 auto;
    text-align: center;
  }

  &__image {
    margin-bottom: 80px;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin: 0 0 40px;
    text-align: center;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
  }

  &__description {
    margin-bottom: 80px;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__button {
    margin: 0 auto;
  }
}

.hto-benefits {
  padding: 60px 170px 60px;
  background-color: #fafafa;

  &__layout {
    max-width: 1270px;
    margin: 0 auto;
  }

  &__title {
    margin: 0 0 50px;
    text-align: center;
    font-size: 32px;
    line-height: 60px;
    color: #000000;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  &__item {
    display: flex;
    flex: 1 0 30%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__item-image {
    max-width: 212px;
    max-height: 158px;
    margin-bottom: 30px;
    flex-shrink: 0;
  }

  &__item-description {
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: 20px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.7);
    white-space: pre-line;
  }
}

.hto-steps {
  padding: 60px 170px 120px;

  &__header {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
  }

  &__header-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    flex-grow: 1;
  }

  &__layout {
    max-width: 1270px;
    margin: 0 auto;
  }

  &__title {
    padding: 0 67px;
    margin: 0;
    flex-shrink: 0;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
  }

  &__list {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    gap: 60px;
  }

  &__item {
    max-width: 300px;
    padding: 30px;
    background-color: #fafafa;
  }

  &__item-image {
    max-width: 225px;
    max-height: 448px;
    margin-bottom: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__item-title {
    margin: 0 0 8px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  &__item-description {
    margin-bottom: 0;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__hint {
    margin-bottom: 40px;
    font-size: 12px;
    line-height: 30px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.7);
  }

  &__button {
    margin: 0 auto;
  }
}

@media (max-width: 1050px) {
  .hto {
    .hto-hero {
      padding: 60px 20px 20px 20px;  
      &__layout {
        .owndays-btn {
            padding: 16px !important;
        }
      }
    }
    .hto-benefits {
        padding: 60px 20px 20px 20px; 
        &__list {
            display: flex;
            overflow: hidden;
            overflow-x: scroll;
        } 
    }
    .hto-steps {
        padding: 60px 20px 20px 20px;
        &__list {
            display: flex;
            overflow: hidden;
            overflow-x: scroll;
        } 

        .owndays-btn {
            padding: 16px !important;
        }
    }
  }
}
