$font-pollen: pollen-web, source-han-serif-jp-subset, serif;
$font-shs: source-han-serif-jp-subset, sans-serif;

$black: #000;
$white: #fff;
$red: #72101a;
$yellow: #F9C967;

$breakpoints: (
  xs: 390px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1296px,
  xxl: 1440px);

@mixin mq-up($size: md) {
  @if type-of($size)==number {
    @media (min-width: #{$size}px) {
      @content;
    }
  }

  @else {
    $breakpoint: map-get($breakpoints, $size);

    @if $breakpoint {
      @media (min-width: $breakpoint) {
        @content;
      }
    }

    @else {
      @media (min-width: map-get($breakpoints, md)) {
        @content;
      }
    }
  }
}

@mixin mq-down($size: md) {
  @if type-of($size)==number {
    @media (max-width: #{$size - 1}px) {
      @content;
    }
  }

  @else {
    $breakpoint: map-get($breakpoints, $size);

    @if $breakpoint {
      @media (max-width: ($breakpoint - 1)) {
        @content;
      }
    }

    @else {
      @media (max-width: (map-get($breakpoints, md) - 1)) {
        @content;
      }
    }
  }
}

:root {
  $container-padding-sp: 20px;
  $container-max-w: 1200px + ($container-padding-sp * 2);
  --pd: #{$container-padding-sp};
  --pd-minus: calc(var(--pd) * -1);
  --container-max-w: #{$container-max-w};
  --edge: var(--pd-minus);
  --edge-aw: var(--edge);

  --swiper-pagination-color: white;
  --swiper-pagination-bullet-size: 5px;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 2.5px;
}

.l-hp {
  font: 400 14px/1.7 $font-shs;
  color: $white;
  position: relative;
  margin-top: 15px;

  &::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("https://www.owndays.com/images/specials/products/harry-potter/bg-main.webp") top center/15% auto;
    pointer-events: none;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    color: inherit;
    background: none;
  }

  ul,
  ol {
    list-style: none;
  }

  button {
    cursor: pointer;
    background-color: unset;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
  }

  a {
    cursor: pointer;
    text-decoration: none !important;
    outline: none;
  }

  img,
  picture {
    width: 100%;
    height: auto;
    display: block;
    vertical-align: middle;
  }
  
  > section {
    > * {
      position: relative;
      z-index: 2;
    }
  }

  &__container {
    max-width: var(--container-max-w);
    padding-left: var(--pd);
    padding-right: var(--pd);
    margin-left: auto;
    margin-right: auto;
    line-height: 1.9;

    &-sm {
      max-width: calc(860px + (var(--pd) * 2));
      padding-left: var(--pd);
      padding-right: var(--pd);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__btn {
    display: table;
    margin: 0 auto;
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    &-inner {
      font: 400 15px/1 $font-pollen;
      letter-spacing: 1.5px;
      text-shadow: 0px 0px 3px $white;
      padding: 4px 10px 0;
      width: 250px;
      max-width: 400px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 3px;
      border-image-source: linear-gradient(
        75deg,
        #bce6ee -31.92%,
        #ddc5a3 -22.81%,
        #c9a885 -10.67%,
        #9a7a5f 7.55%,
        #c9a885 22.73%,
        #eedebc 42.47%,
        #ddc5a3 50.06%,
        #c9a885 60.68%,
        #9a7a5f 77.38%,
        #c9a885 95.6%,
        #eedebc 119.89%
      );
      background-color: rgba($color: $white, $alpha: 0.15);
      color: $white !important;
      white-space: nowrap;

      .space {
        width: 0.4em;
      }
    }
  }

  .text-effect {
    span {
      font-size: inherit;
      font-weight: inherit;
      display: inline-block;
      padding-top: 2px;
      background: linear-gradient(90deg, #FBAA2B 0%, #F4B759 17%, #EDC689 37%, #E7D1B0 56%, #E3DACB 73%, #E0DFDC 88%, #E0E1E2 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .bg-lg {
    background: linear-gradient(
      180deg,
      #250000 3.24%,
      #72101a 8.42%,
      #72101a 88.54%,
      #250000 95.28%
    );
  }

  &__title {
    font-family: $font-pollen;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700 !important;
    letter-spacing: 6px;
    position: relative;
    text-align: center;
    padding: 46px 0 0;
    margin-bottom: 50px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("https://www.owndays.com/images/specials/products/harry-potter/golden-snitch.svg") no-repeat top center/auto 26px;
      pointer-events: none;
    }

    &--sm {
      font-size: 25px;
      letter-spacing: 4px;
      line-height: 36px;
    }
  }

  .swiper {
    &-pagination {
      &-bullets {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      &-bullet {
        border-radius: 1rem;
        box-shadow: 0px 0px 3px 0px $white;

        &-active {
          width: 15px;
        }
      }
    }
  }

  .tab-contents {
    .tab-content {
      &:not(.active) {
        display: none;
      }
    }
  }

  .main {
    position: relative;
    min-height: unset;

    &__detail {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      bottom: 0;
      padding-top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(37, 2, 2, 0.80) 58.1%, #250202 83.01%);

      &-col {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__logo {
      width: 100%;
      max-width: 183px;
      margin: 0 auto 62px;
      display: block;
    }

    &__date {
      width: 38.8%;
      max-width: 200px;
      display: block;
      margin: 0 15px 0 0;

      img {
        aspect-ratio: 60/13;
      }
    }

    p {
      font-size: 14px;
      padding-left: 5px;
    }

    &__bg {
      position: relative;
      overflow: hidden;
      transform: none;
    }

    @include mq-down() {
      &__img {
        aspect-ratio: 125/184;
      }

      &__logo {
        img {
          aspect-ratio: 183/135;
        }
      }
    }
  }

  .concept {
    background: radial-gradient(49.95% 49.95% at 50% 50.05%, #72101a 26.41%,#250000 100%);
    margin-bottom: -1px;

    &__title {
      font: 400 16px/28px $font-shs;
      letter-spacing: 0.8px;
      text-align: center;
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;
      line-height: 32px;
      letter-spacing: normal;
      margin-bottom: 60px;
    }

    .l-hp__container-sm {
      padding: 230px 40px 266px;
      position: relative;
    }

    &__el {
      position: absolute;
      display: block;
      max-width: 80px;
      pointer-events: none;

      &--1 {
        top: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 53.4%;
        max-width: 238px;
      }
      &--2 {
        top: 166px;
        left: 40px;
        width: 11.3%;
      }
      &--3 {
        top: 173px;
        right: 40px;
        width: 12.4%;
      }
      &--4 {
        bottom: 169px;
        left: 40px;
        width: 14.9%;
      }
      &--5 {
        bottom: 163px;
        right: 40px;
        width: 15.2%;
      }
      &--6 {
        bottom: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 70.6%;
        max-width: 315px;
      }
    }

    @include mq-down() {
      .l-hp__container-sm {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  .lineup {
    padding: 100px 0 100px;

    &__price {
      font: 400 14px / 1 $font-shs;
      text-align: center;
      position: relative;
      letter-spacing: 0.84px;

      &-strong {
        font-family: $font-pollen;
        font-size: 35px;
        font-weight: 700;
        margin-top: 10px;
        display: block;
        letter-spacing: normal;
      }
      &-span {
        font-family: $font-shs;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.18px;
        margin-right: 5px;
      }
    }

    &__nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 46px 24px;
      margin-top: 70px;
      counter-reset: section;
      width: calc(100% + 8px);
      transform: translateX(calc(8px / 2 * -1));

      >li {
        position: relative;

        >a {
          display: block;
          position: relative;
          opacity: 1 !important;

          &::after {
            content: "";
            position: absolute;
            width: 39px;
            height: 39px;
            background: url("https://www.owndays.com/images/specials/products/harry-potter/arrow-down-2.svg") no-repeat 50%/contain;
            margin: 4px auto 0;
            border: 1px solid $red;
            border-radius: 100px;
            bottom: 33px;
            right: 6px;
          }

          picture,
          img {
            opacity: 1 !important;
            transition: 0.4s;
          }

          img {
            aspect-ratio: 157/95;
          }
        }
      }

      &-badge {
        position: absolute;
        top: -23px;
        right: 5px;

        img {
          width: 50px;
          height: 53px;
          aspect-ratio: 50/53;
        }
      }

      &-details {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        color: $yellow;
        margin-top: 16px;

        &-model {
          font-size: 11px;
          font-weight: 600;
          line-height: 8px;
          letter-spacing: 1.1px;
        }

        &-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 10px;
          letter-spacing: 1.4px;
        }
      }
    }

    &__default-pagination {
      display: flex;
      justify-content: center;
      position: static;
      margin-top: 20px;

      .swiper {
        &-slide {
          text-align: center;
        }

        &-pagination-bullet {
          width: 5px;
          height: 5px;
          margin: 0 2.5px;
          background-color: $white;
          opacity: 1;
          border-radius: 5px;
          box-shadow: 0px 0px 3px 0px $white;
          transition: 0.3s;

          &-active {
            width: 15px;
          }
        }
      }
    }

    &__products {
      margin-top: 80px;

      >li {
        +li {
          margin-top: 100px;
        }
      }

      &-top {
        display: block;
        position: relative;
        margin-bottom: 40px;

        &-swiper {
          width: auto;
          margin-left: var(--edge);
          margin-right: -4px;
          position: relative;

          &-pagination {
            display: flex;
            justify-content: start;
            position: absolute;
            left: 20px !important;
            right: auto;
            bottom: 25px !important;
            margin: 0 auto;
            z-index: 2;

            .swiper-pagination-bullet {
              width: 20px;
              height: 5px;
              border-radius: 0;
              opacity: 0.5;
              background-color: $white;
              margin: 0;

              +.swiper-pagination-bullet {
                margin-left: 10px;
              }

              &-active {
                background-color: $white;
                opacity: 1;
              }
            }
          }

          .swiper {
            &-slide {
              aspect-ratio: 335/339;

              picture {
                height: 100%;
              }

              img {
                height: 100%;
                object-fit: cover;
                object-position: right top;
              }
            }
          }
        }

        &-text {
          position: relative;
          z-index: 3;

          p {
            font-size: 14px;
            letter-spacing: normal;
            line-height: 1.9;
          }
        }
      }

      &-middle {
        margin: 0 var(--edge);

        &-swiper {
          position: relative;

          &-pagination {
            display: 14px;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: calc(43.4vw + 20px);
            z-index: 2;
          }
        }

        .swiper {
          &-slide {
            text-align: center;

            img {
              aspect-ratio: 298/163;
            }

            p {
              font-size: 14px;
              margin-top: 45px;
              letter-spacing: normal;
            }
          }
        }
      }

      &-bottom {
        margin: 45px auto 0;
        position: relative;

        &-head {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 45px;

          &-title {
            font: 700 18px / 1 $font-pollen;
            letter-spacing: 1.6px;
            margin-right: 22px;
          }

          .nav {
            &-item {
              +.nav-item {
                margin-left: 20px;
              }
            }

            &-link {
              width: 35px;
              height: 35px;
              padding: 0;
              border: 1px solid $white;
              font-size: 0;
              color: transparent;
              transition: 0.2s;

              &.cl-gold-1 {
                background: linear-gradient(75deg,
                  #eedebc -31.92%,
                  #ddc5a3 -22.81%,
                  #c9a885 -10.67%,
                  #b5a79b 7.55%,
                  #f0e1d2 22.73%,
                  #fff9ec 42.47%,
                  #f1e5d5 50.06%,
                  #fde5cc 60.68%,
                  #efcfb4 77.38%,
                  #ecc9a3 95.6%,
                  #eedebc 119.89%);
              }

              &.cl-gold-2 {
                background: linear-gradient(75deg,
                  #eedebc -31.92%,
                  #ddc5a3 -22.81%,
                  #c9a885 -10.67%,
                  #9a7a5f 7.55%,
                  #c9a885 22.73%,
                  #eedebc 42.47%,
                  #ddc5a3 50.06%,
                  #c9a885 60.68%,
                  #9a7a5f 77.38%,
                  #c9a885 95.6%,
                  #eedebc 119.89%);
              }

              &.active {
                box-shadow: 0 0 0 3px $white;
              }
            }
          }
        }

        &-swiper {
          margin-bottom: 40px;

          .swiper {
            &-slide {
              width: calc(100% - 40px) !important;
              border-radius: 10px;
              overflow: hidden;

              img {
                aspect-ratio: 59/37;
                transition: 0.2s;
              }

              &:not(.swiper-slide-visible) {
                img {
                  opacity: 0;
                }
              }

              &-prev,
              &-next {
                img {
                  opacity: 0.5 !important;
                }
              }

              &-active {
                img {
                  transition: 0.1s;
                }
              }
            }
          }
        }
      }
    }

    &__number {
      text-align: center;
      position: relative;
      margin: 58px var(--edge) 30px;

      &::before {
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: linear-gradient(90deg,
          #fbaa2b 0%,
          #f4b759 17%,
          #edc689 37%,
          #e7d1b0 56%,
          #e3dacb 73%,
          #e0dfdc 88%,
          #e0e1e2 100%);
        transform: rotate(-45deg) scale(0);
        z-index: 2;
        pointer-events: none;
        transition: 0.5s;
        transition-timing-function: ease-in-out;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: calc(50% - 1px);
        left: 0;
        background: linear-gradient(90deg,
            #fbaa2b 0%,
            #f4b759 17%,
            #edc689 37%,
            #e7d1b0 56%,
            #e3dacb 73%,
            #e0dfdc 88%,
            #e0e1e2 100%);
        transform: scaleX(0);
        transition: 0.5s;
        transition-delay: 0.5s;
        transition-timing-function: ease-in-out;
        z-index: 1;
        pointer-events: none;
      }

      span {
        font: 700 20px / 1 $font-pollen;
        color: $red;
        position: relative;
        top: 2.5px;
        right: 1px;
        display: inline-block;
        transform: translateY(2px);
        transition: 0.5s;
        transition-delay: 0.6s;
        transition-timing-function: ease-in-out;
        opacity: 0;
        z-index: 3;
      }

      &.aos-animate {
        &::before {
          transform: rotate(-45deg) scale(1);
        }

        &::after {
          transform: scaleX(1);
        }

        span {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    &__title {
      margin-bottom: 24px;

      &-badge {
        width: 112px;
        height: 125px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        color: $yellow;
        font-family: $font-shs;

        &-main {
          font-size: 20px;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: 0.2px;
        }

        &-sub {
          font-size: 14px;
          font-weight: 400;
          line-height: 10px;
          letter-spacing: 0.42px;
        }
      }
    }

    .button-next,
    .button-prev {
      position: absolute;
      top: 50%;
      width: 12px;
      height: 27px;
      background-color: black;
      z-index: 3;
      cursor: pointer;
      background: url("https://www.owndays.com/images/specials/products/harry-potter/arrow-right.svg") no-repeat center/contain;
      font-size: 0;
      color: transparent;
    }

    .button-next {
      right: 0;
      transform: translateY(calc(-50% - 15px));
    }

    .button-prev {
      left: 0;
      transform: translateY(calc(-50% - 15px)) scaleX(-1);
    }

    .tab-content {
      >.tab-pane {
        display: block !important;
        height: 0;
        overflow: hidden;
        pointer-events: none;
      }

      >.active {
        height: unset;
        overflow: unset;
        pointer-events: unset;
      }
    }

    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      line-height: 0;
    }

    @include mq-down() {
      &__products {
        &-middle {
          .swiper {
            &-slide {
              width: calc(80% - 2px) !important;

              &:not(.swiper-slide-active) {
                p {
                  transition: 0.2s;
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      &__title {
        padding-top: 34px;

        &-badge {
          position: absolute;
          top: -77px;
          right: 0;
        }
      }
    }

    @include mq-up() {
      background: linear-gradient(180deg,
        #250000 3.24%,
        #72101a 7.19%,
        #72101a 88.54%,
        #250000 100%);
    }
  }

  .merchandise {
    position: relative;
    padding-bottom: 100px;
    &__main {
      position: relative;
      max-width: 2000px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;

      &-slider {
        &-pagination {
          display: flex;
          justify-content: start;
          position: absolute;
          left: 20px !important;
          right: auto;
          bottom: 25px !important;
          gap: 10px;
          margin: 0 auto;
          z-index: 2;

          .swiper-pagination-bullet {
            width: 20px;
            height: 5px;
            border-radius: 0;
            opacity: 0.5;
            background-color: $black;
            margin: 0;

            &-active {
              background-color: $black;
              opacity: 1;
            }
          }
        }
      }
    }
    &__other {
      max-width: 2000px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      grid-gap: 40px;
      max-width: 960px;
      margin: 100px auto 0;
    }
    &__notes{
      margin-top: 30px;
      ul{
        display: flex;
        flex-direction: column;
        font-size: 13px;
        font-weight: 500;
        line-height: 220%;
        li{
          position: relative;
          padding-left: 1em;
          &::before{
            content: '・';
            position: absolute;
            top: 0;
            left: -8px;
            font-size: 22px;
          }
        }
      }
    }
    .l-hp__title{
      &--pc{
        display: none;
      }
    }
    @include mq-down() {
      &__main {
        &-slider {
          padding-right: var(--pd);
          margin-bottom: 30px;
        }
      }
      &__buttons {
        .l-hp__btn {
          &-inner {
            font-size: 15px;
            letter-spacing: 1.5px;
            padding: 0 6px;
          }
        }
      }
    }
  }

  .shop {
    padding-bottom: 100px;
    ul {
      margin-top: -15px;
      > li {
        font: 500 13px/29px $font-shs;
        display: flex;

        &::before {
          content: '・';
          font-size: 1em;
          margin-right: 2px;
        }
      }
    }
  }

  .share {
    background-color: #250101;
    position: relative;
    z-index: 2;
    padding-bottom: 160px;

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 16px;
      margin-bottom: 84px;

      >li {
        display: inline-flex;

        a {
          font-size: 0;
          color: transparent;
        }
      }

      &-link {
        display: inline-block;
        width: 56px;
        height: 56px;
        border-radius: 10rem;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &--x {
          background-image: url("https://www.owndays.com/images/specials/products/harry-potter/twitter.svg");
        }

        &--facebook {
          background-image: url("https://www.owndays.com/images/specials/products/harry-potter/Facebook.svg");
        }

        &--line {
          background-image: url("https://www.owndays.com/images/specials/products/harry-potter/line.svg");
        }
      }
    }

    &__copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 13px;
      font-family: sans-serif;
      font-size: 8px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: normal;
      margin: 0 auto;
      color: $yellow;

      img {
        max-width: 117px;
      }
    }

    @include mq-down(lg) {
      &__copyright {
        flex-direction: column;
        text-align: center;
      }
    }
  }

  [data-aos="main-bg"] {
    opacity: 1;
    transition-property: transform, opacity;
    transform: scale(1.3);
  
    &.aos-animate {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  [data-aos="show-letter"] {
    &.aos-animate {
      .letter {
        animation: hp_fadeIn 1s ease forwards;
      }
    }
  }
}

.hp-particle {
  position: fixed;
  z-index: 10002;
  width: 2px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);
  animation: hp_move .8s ease-out forwards, hp_fade 1s ease-out forwards;
}

@keyframes hp_btnJump {
  100% {
    transform: translateY(-7px);
  }
}

@keyframes hp_fadeIn {
  from {
    opacity: 0;
    pointer-events: none;
  }

  to {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes hp_move {
  to {
    transform: translateY(-100px) translateX(50px);
  }
}

@keyframes hp_fade {
  to {
    opacity: 0;
  }
}
