$font-founders: 'Founders Grotesk', sans-serif;

$bg: #f4f4f4;
$black: #000;
$black-66: #666;

.header-lens-replacement {
  position: relative;
  letter-spacing: 1px;

  &__bg {
    max-width: 3840px;

    picture {
      img {
        width: 100%;
        height: auto;
        display: block;
        aspect-ratio: 192/125;

        @media (min-width: 768px) {
          aspect-ratio: 16/5;
        }
      }
    }
  }

  &__title {
    position: relative;
    transform: translateY(0);
    padding: 34px 5%;
    background-color: $bg;

    &-default, &-description {
      font-family: $font-founders;
      text-align: left;
    }

    &-default {
      font-size: 20px;
      font-weight: 600 !important;
      color: $black;
    }

    &-description {
      margin: 14px auto 0;
      color: $black-66;
      font-size: 15px;
      font-weight: normal !important;
      line-height: 1.6;
    }
    
    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      text-align: center;
      z-index: 2;
      margin: 0 auto;
      background-color: transparent;

      &-default, &-description {
        text-align: center;
      }

      &-default {
        font-size: 40px;
        font-weight: 300 !important;
        line-height: 1;
      }

      &-description {
        max-width: 600px;
        margin: 17px auto 0
      }
    }
    
  }
}
