.footer-gift-ticket__condition {
  padding: 60px 0 85px;
}

.footer-gift-ticket__condition__container {
  width: 100%;
  max-width: 924px;
  height: auto;
  padding: 0 5%;
  margin: 0 auto;
}

.footer-gift-ticket__condition__title {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 1;
  margin-bottom: 15px;
  text-align: left;
}

.footer-gift-ticket__condition__list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-gift-ticket__condition__item {
  padding-left: 1em;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  font-size: 15px;
  text-align: left;
  color: #000;
  letter-spacing: 1px;
  line-height: 2;
  position: relative;
}

.footer-gift-ticket__condition__item::before {
  content: "*";
  position: absolute;
  left: 0;
}

@media (min-width: 768px) {
  .footer-gift-ticket__condition {
    padding: 80px 0 100px;
  }
  .footer-gift-ticket__condition__title {
    font-size: 16px;
  }
}
