.services__lens-filter {
  display: flex;
  flex-direction: column;
  &-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 30px;
    li {
      display: inline-flex;
      font-size: 15px;
      line-height: 2;
      color: #666;
      padding: 0 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      &.active {
        color: #007eaa;
      }
      &:hover {
        color: #007eaa;
      }
    }
  }
  &-content {
    ul {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      li {
        display: grid;
        grid-template-columns: 55px 1fr;
        border: 1px solid #f4f4f4;
        padding: 20px 0;
        position: relative;
        cursor: pointer;
        &::before{
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 25px;
          background: #f4f4f4 url('https://static.lenskart.com/media/owndays/img/services-lens/arrow-b-black.png') no-repeat center center/4px auto;
        }
      }
    }
  }
  &-icon {
    display: block;
    max-width: 30px;
    margin: 0 auto;
  }
  &-details {
    display: flex;
    flex-direction: column;
    padding-right: 45px;
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 17px;
      font-size: 11px;
      line-height: 1;
      text-align: center;
      color: #fff;
      margin-bottom: 3px;
      &.standard {
        background-color: #0094c8;
      }
      &.option {
        background-color: #c7002e;
      }
    }
  }
  &-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    h4,
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    h4 {
      display: flex;
      align-items: center;
      column-gap: 8px;
      font-size: 14px;
      font-weight: 600 !important;
      color: #000;
      img {
        width: auto;
        height: 27px;
        object-fit: contain;
      }
    }
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      color: #c7002e;
      display: flex;
      flex-direction: column;
      column-gap: 4px;
      row-gap: 4px;
      small {
        font-weight: 400;
      }
    }
  }
  @media (min-width: 768px) {
    .services__lens-filter {
      &-content {
        ul {
          li {
            grid-template-columns: 68px 1fr;
            &::before{
              width: 50px;
            }
          }
        }
      }
      &-icon{
        max-width: 36px;
      }
      &-details{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 20px;
        align-items: center;
        padding-right: calc(50px + 20px);
        span{
          margin-bottom: 0;
          height: 30px;
        }
      }
      &-title{
        display: grid;
        grid-template-columns: 3fr 2fr;
        h4,p{
          font-size: 16px;
        }
      }
    }
  }
}
