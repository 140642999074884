$font-founders: 'Founders Grotesk', sans-serif;

$gray-66: #666;
$gray-e3: #e3e3e3;
$gray-99: #999;

$base-img-url: 'https://static.lenskart.com/owndays/img/contact-us';

.body-contact-us {
  &__container {
    width: 100%;
    height: auto;
    padding: 0 5%;
    max-width: 764px;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 80%;
      padding: 0;
    }
  }

  &__about {
    padding: 20px 0 40px 0;
    border-top: 1px solid $gray-e3;
    border-bottom: 1px solid $gray-e3;

    h2 {
      font-family: $font-founders;
      font-size: 18px;
      font-weight: 400 !important;
      line-height: 1.5;
      letter-spacing: 1px;
      text-align: center;
      color: black;
      margin: 0;

      span {
        width: 140px;
        height: auto;
        display: block;
        margin: 0 auto;

        img {
          width: 100%;
          display: block;
        }
      }
    }

    ul {
      margin: 25px 0 0 0;
      list-style-type: none;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 20px;

      li {
        a {
          display: block;
          transition: 0.5s;
          color: black;
          font-family: $font-founders;
          font-size: 15px;
          font-weight: normal !important;
          line-height: 2;
          letter-spacing: 1px;
          padding: 17px 58px 17px 16px;
          border: 1px solid $gray-e3;
          position: relative;

          &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
          }

          &::after {
            content: '';
            width: 28px;
            height: 7px;
            background-image: url("#{$base-img-url}/arrow-r-black.png");
            background-size: contain;
            position: absolute;
            right: 16px;
            top: calc(50% - 3.5px);
          }
        }
      }
    }

    @media (min-width: 768px) {
      padding: 40px 0 90px 0;

      h2 {
        span {
          width: 170px;
        }
      }
  
      ul {
        margin: 60px 0 0 0;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
  
        li {
          a {
            padding: 24px 73px 24px 30px;

            &::after {
              right: 24px;
            }
          }
        }
      }
    }

    @media (min-width: 1024px) {
      ul li a:hover {
        color: $gray-99;
      }
    }
  }

  &__recruit {
    padding: 20px 0 40px 0;
    border-bottom: 1px solid $gray-e3;

    h2 {
      font-family: $font-founders;
      font-size: 18px;
      font-weight: 400 !important;
      line-height: 1.5;
      letter-spacing: 1px;
      text-align: center;
      color: black;
      margin: 0;

      span {
        width: 60px;
        height: auto;
        display: block;
        margin: 0 auto;

        img {
          width: 100%;
          display: block;
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 40px 0 0 0;
      max-width: 510px;
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;

      li {
        display: grid;
        grid-template-columns: 130px 1fr;
        grid-template-rows: 30px;
        align-items: baseline;

        &:nth-child(2) {
          grid-template-rows: 60px;
        }
        
        h4, p {
          font-family: $font-founders;
          font-weight: normal !important;
          font-size: 15px;
          line-height: 2;
          letter-spacing: 1px;
          color: black;
          margin: 0;
        }
      }
    }

    &__link {
      margin-top: 20px;

      a {
        display: block;
        transition: 0.5s;
        color: black;
        font-family: $font-founders;
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
        letter-spacing: 1px;
        padding: 17px 58px 17px 16px;
        border: 1px solid $gray-e3;
        position: relative;

        &:hover, &:active, &:visited, &:focus {
          text-decoration: none;
        }

        &::after {
          content: '';
          width: 28px;
          height: 7px;
          background-image: url("#{$base-img-url}/arrow-r-black.png");
          background-size: contain;
          position: absolute;
          right: 16px;
          top: calc(50% - 3.5px);
        }
      }
    }

    @media (min-width: 768px) {
      padding: 40px 0 70px 0;

      h2 {
        span {
          width: 80px;
        }
      }

      ul {
        margin: 40px auto 0;
        max-width: 510px;
  
        li {
          &:nth-child(2) {
            grid-template-rows: 30px;
          }
        }
      }

      &__link {
        margin-top: 30px;
        display: flex;
        justify-content: center;
  
        a {
          width: 48.5%;
          padding: 24px 73px 24px 30px;

          &::after {
            right: 24px;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      &__link a:hover {
        color: $gray-99;
      }
    }
  }
}
