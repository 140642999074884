.theme-button {
    background-color: #00b9c6;
    color: #fff;
}
.flex-direction-column {
    flex-direction: column;
}
.lk-exchange-modal .modal{
    height: auto;
    margin: 0;
}