.body-ortho-k-lens__key-benefits {
  padding: 70px 0;
  position: relative;
}

.body-ortho-k-lens__key-benefits::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0;
  background-color: #2e5aa8;
  z-index: -1;
}

.body-ortho-k-lens__key-benefits .base-ortho-k-lens__title {
  color: #fff;
}

.body-ortho-k-lens__key-benefits__list {
  display: grid;
  gap: 23px;
  margin: 50px 0 0 0;
  list-style-type: none;
  padding-left: 0;
}

.body-ortho-k-lens__key-benefits__list > li {
  padding: 35px 20px 45px;
  background-color: #fff;
  border: 1px solid #707070;
}

.body-ortho-k-lens__key-benefits__list-top {
  padding-bottom: 25px;
  margin-bottom: 20px;
  position: relative;
}

.body-ortho-k-lens__key-benefits__list-top::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: url("https://static.lenskart.com/media/owndays/img/ortho-k-lens/dot-blue.svg") left/contain repeat-x;
}

.body-ortho-k-lens__key-benefits__list-img {
  width: 150px;
  margin: 0 auto 15px;
  display: block;
}

.body-ortho-k-lens__key-benefits__list-title {
  font: 700 22px/1.5 din-2014, sans-serif;
  font-weight: 700 !important;
  color: #2e5aa8;
  text-align: center;
  margin: 0;
}

.body-ortho-k-lens__key-benefits__list p {
  font-size: 14px;
  margin: 0;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__key-benefits__list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .body-ortho-k-lens__key-benefits {
    padding: 120px 0 130px;
  }
  .body-ortho-k-lens__key-benefits__list {
    margin: 64px 0 0 0;
    gap: 45px;
  }
  .body-ortho-k-lens__key-benefits__list > li {
    padding: 50px 53px 53px;
  }
  .body-ortho-k-lens__key-benefits__list-top {
    display: grid;
    grid-template-columns: 150px 1fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 24px;
  }
  .body-ortho-k-lens__key-benefits__list-title {
    text-align: left;
  }
}

.body-ortho-k-lens__recommended {
  margin-top: 70px;
}

.body-ortho-k-lens__recommended .base-ortho-k-lens__title {
  white-space: nowrap;
}

.body-ortho-k-lens__recommended__list {
  margin: 38px 0 48px;
  padding-left: 0;
  list-style-type: none;
}

.body-ortho-k-lens__recommended__list > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
}

.body-ortho-k-lens__recommended__list > li::before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f7f9fc url("https://static.lenskart.com/media/owndays/img/ortho-k-lens/icon-check.svg") center/15px auto no-repeat;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin: 7px 18px 0 0;
}

.body-ortho-k-lens__recommended__list > li + li {
  margin-top: 25px;
}

.body-ortho-k-lens__recommended__left-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  text-align: left;
}

.body-ortho-k-lens__recommended__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  padding-top: 15px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #0094c8 !important;
  text-decoration: underline;
  position: relative;
}

.body-ortho-k-lens__recommended__link::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 21px;
  background-image: url("https://static1.lenskart.com/owndays/img/icons/pdf.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.body-ortho-k-lens__recommended__link:hover::after {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__recommended__content {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 40px;
  }
  .body-ortho-k-lens__recommended__list {
    margin: 0 0 91px;
  }
  .body-ortho-k-lens__recommended__list > li {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .body-ortho-k-lens__recommended {
    margin-top: 120px;
  }
  .body-ortho-k-lens__recommended__content {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  .body-ortho-k-lens__recommended__list > li::before {
    content: "";
    width: 38px;
    height: 38px;
  }
}

.body-ortho-k-lens__treatment-process {
  margin-top: 70px;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__treatment-process {
    margin-top: 130px;
  }
}

@media (min-width: 1024px) {
  .body-ortho-k-lens__treatment-process .base-ortho-k-lens__container--sub {
    margin-left: 0;
    max-width: 100%;
  }
}

.body-ortho-k-lens__treatment-process__list {
  margin: 30px 0 0 0;
  counter-reset: step-counter;
  padding-right: 0;
  list-style-type: none;
}

@media (max-width: 767px) {
  .body-ortho-k-lens__treatment-process__list {
    margin-left: -15px;
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .body-ortho-k-lens__treatment-process__list {
    margin-top: 64px;
  }
}

.body-ortho-k-lens__treatment-process__list > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  counter-increment: step-counter;
  cursor: pointer;
}

.body-ortho-k-lens__treatment-process__list > li:last-child .body-ortho-k-lens__treatment-process__list-step::after {
  content: none;
}

.body-ortho-k-lens__treatment-process__list-content {
  width: 100%;
  margin-top: -2px;
}

.body-ortho-k-lens__treatment-process__list--question {
  position: relative;
  padding: 40px 20px;
  border-bottom: 2px solid #413d3d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font: 700 16px/1.5 din-2014, sans-serif;
  letter-spacing: 0.02em;
}

@media (max-width: 767px) {
  .body-ortho-k-lens__treatment-process__list--question {
    padding: 25px 10px;
  }
}

.body-ortho-k-lens__treatment-process__list--question:first-child {
  border-top: 2px solid #413d3d;
}

.body-ortho-k-lens__treatment-process__list--question > span:first-child {
  max-width: 94%;
}

@media (max-width: 767px) {
  .body-ortho-k-lens__treatment-process__list--question > span:first-child {
    max-width: 88%;
  }
}

.body-ortho-k-lens__treatment-process__list--question__plus {
  position: relative;
  width: 20px;
  height: 20px;
}

.body-ortho-k-lens__treatment-process__list--question__plus::before, .body-ortho-k-lens__treatment-process__list--question__plus::after {
  content: '';
  position: absolute;
  background-color: #999;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.body-ortho-k-lens__treatment-process__list--question__plus::before {
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
}

.body-ortho-k-lens__treatment-process__list--question__plus::after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: -1px;
}

.body-ortho-k-lens__treatment-process__list--question--opened .body-ortho-k-lens__treatment-process__list--question__plus::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.body-ortho-k-lens__treatment-process__list--question--opened .body-ortho-k-lens__treatment-process__list--question__plus::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  height: 0;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__treatment-process__list--question {
    font-size: 22px;
  }
}

.body-ortho-k-lens__treatment-process__list--answer {
  background-color: #f7f9fc;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}

.body-ortho-k-lens__treatment-process__list--answer > div {
  position: relative;
  padding: 30px 30px 30px 46px;
}

@media (max-width: 767px) {
  .body-ortho-k-lens__treatment-process__list--answer > div {
    padding: 38px 15px 38px 30px;
  }
}

.body-ortho-k-lens__treatment-process__list--answer > div sup {
  font-size: 8px;
}

.body-ortho-k-lens__treatment-process__list--answer a {
  display: inline;
}

.body-ortho-k-lens__treatment-process__list--answer p {
  font-size: 13px;
  line-height: 1.9;
  margin: 0;
}

.body-ortho-k-lens__treatment-process__list--answer .base-ortho-k-lens__note {
  margin-top: 13px;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__treatment-process__list--answer p {
    font-size: 14px;
  }
}

.body-ortho-k-lens__treatment-process__list-step {
  position: relative;
  margin-right: 18px;
}

.body-ortho-k-lens__treatment-process__list-step::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 9px;
  bottom: -9px;
  border-left: 2px solid #dedede;
  z-index: -1;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.body-ortho-k-lens__treatment-process__list-step-inner {
  font-size: 13px;
  font-weight: 700 !important;
  line-height: 1.2;
  text-align: center;
  width: 52px;
  height: 52px;
  background-color: #dedede;
  color: #fff;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 3px;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin-top: 9px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.body-ortho-k-lens__treatment-process__list-step-inner::before {
  content: "STEP";
  font-size: 11px;
}

.body-ortho-k-lens__treatment-process__list-step-inner::after {
  content: counter(step-counter);
}

.body-ortho-k-lens__treatment-process__list-step__active::after {
  border-left-color: #2e5aa8;
}

.body-ortho-k-lens__treatment-process__list-step__active .body-ortho-k-lens__treatment-process__list-step-inner {
  background-color: #2e5aa8;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__treatment-process__list-step {
    margin-right: 38px;
  }
  .body-ortho-k-lens__treatment-process__list-step::after {
    top: 27px;
    bottom: -27px;
    border-left-width: 3px;
  }
  .body-ortho-k-lens__treatment-process__list-step-inner {
    font-size: 17px;
    width: 60px;
    height: 60px;
    margin-top: 27px;
  }
  .body-ortho-k-lens__treatment-process__list-step-inner::before {
    font-size: 14px;
  }
}

.body-ortho-k-lens__price {
  margin-top: 70px;
}

.body-ortho-k-lens__price .base-ortho-k-lens__title {
  margin-bottom: 19px;
}

.body-ortho-k-lens__price .base-ortho-k-lens__title + p {
  font-size: 13px;
  padding-right: 20px;
  margin: 0;
}

.body-ortho-k-lens__price__content {
  margin-top: 20px;
}

.body-ortho-k-lens__price__content p {
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  margin: 0;
}

.body-ortho-k-lens__price__content ul {
  list-style-type: none;
  padding-left: 0;
}

.body-ortho-k-lens__price__content ul li {
  position: relative;
  padding-left: 15px;
  text-align: left;
  font-size: 15px;
}

.body-ortho-k-lens__price__content ul li::before {
  content: "*";
  position: absolute;
  left: 0;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__price .base-ortho-k-lens__title + p {
    font-size: 15px;
  }
  .body-ortho-k-lens__price__content ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .body-ortho-k-lens__price {
    display: grid;
    grid-template-columns: auto 676px;
    margin-top: 130px;
    padding-bottom: 138px;
  }
  .body-ortho-k-lens__price .base-ortho-k-lens__title + p {
    max-width: 310px;
  }
}

@media (min-width: 1296px) {
  .body-ortho-k-lens__price {
    grid-template-columns: auto 766px;
  }
}

.body-ortho-k-lens__stores {
  margin-top: 70px;
  padding-bottom: 70px;
}

.body-ortho-k-lens__stores .base-ortho-k-lens__title {
  margin-bottom: 19px;
}

.body-ortho-k-lens__stores .base-ortho-k-lens__title + p {
  font-size: 13px;
  padding-right: 20px;
  margin: 0;
}

.body-ortho-k-lens__stores__list {
  list-style-type: none;
  padding-left: 0;
}

@media (max-width: 1023px) {
  .body-ortho-k-lens__stores__list > li {
    margin-top: 41px;
  }
}

.body-ortho-k-lens__stores__title {
  font: 700 15px/1.6 "Noto Sans", sans-serif;
  font-weight: 700 !important;
  letter-spacing: 0.02em;
  margin: 20px 0 28px;
}

.body-ortho-k-lens__stores .base-ortho-k-lens__note {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .body-ortho-k-lens__stores .base-ortho-k-lens__title + p {
    font-size: 15px;
  }
  .body-ortho-k-lens__stores__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .body-ortho-k-lens__stores__list > li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .body-ortho-k-lens__stores__list .base-ortho-k-lens__button {
    margin-top: auto;
  }
}

@media (min-width: 1024px) {
  .body-ortho-k-lens__stores {
    margin-top: 0;
    display: grid;
    grid-template-columns: auto 676px;
    padding-bottom: 138px;
  }
  .body-ortho-k-lens__stores .base-ortho-k-lens__title + p {
    max-width: 310px;
  }
  .body-ortho-k-lens__stores__list {
    gap: 36px;
  }
}

@media (min-width: 1296px) {
  .body-ortho-k-lens__stores {
    grid-template-columns: auto 766px;
  }
}

.body-ortho-k-lens__guide {
  position: relative;
  padding: 80px 0;
}

.body-ortho-k-lens__guide::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0;
  background-color: #2e5aa8;
  z-index: -1;
}

.body-ortho-k-lens__guide__title .base-ortho-k-lens__title {
  color: #fff;
  margin-bottom: 35px;
}

.body-ortho-k-lens__guide__title h3 {
  font-size: 13px;
  font-weight: bold !important;
  line-height: 21px;
  color: #fff;
  text-align: center;
  margin: 0 0 40px;
}

.body-ortho-k-lens__guide__frame__slide {
  margin-bottom: 50px;
}

.body-ortho-k-lens__guide__row {
  position: relative;
  margin-top: 50px;
}

.body-ortho-k-lens__guide__row:not(:first-child) {
  padding: 50px 0 0;
}

.body-ortho-k-lens__guide__row:not(:first-child)::after {
  content: "";
  background: url("https://static.lenskart.com/media/owndays/img/ortho-k-lens/dot-white.svg") repeat-x left center/contain;
  height: 5px;
  position: absolute;
  left: -27px;
  right: -27px;
  top: 0;
}

.body-ortho-k-lens__guide__row__title {
  margin-bottom: 30px;
}

.body-ortho-k-lens__guide__row__title__main-title {
  font-size: 18px;
  font-weight: bold !important;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #fff;
  padding: 10px 25px;
  border: 1px solid #fff;
  border-radius: 50px;
  margin: 0 auto;
}

.body-ortho-k-lens__guide__row__title__main-title h4 {
  margin: 0;
}

.body-ortho-k-lens__guide__row__title__sub-title {
  text-align: center;
}

.body-ortho-k-lens__guide__row__title__sub-title p {
  color: #fff;
  font-size: 14px;
  font-weight: 200 !important;
  margin: 15px 0 0 0;
}

.body-ortho-k-lens__guide__slide {
  margin-left: -27px;
  padding-left: 27px;
  position: relative;
  overflow: hidden;
}

.body-ortho-k-lens__guide__slide > div {
  overflow: visible;
}

@media (max-width: 767px) {
  .body-ortho-k-lens__guide__slide {
    margin-right: -27px;
  }
}

.body-ortho-k-lens__guide__slide__item {
  width: 250px !important;
  margin-right: 15px;
  list-style-type: none;
}

@media (max-width: 1023px) {
  .body-ortho-k-lens__guide__slide__item {
    width: 220px !important;
  }
}

.body-ortho-k-lens__guide__slide__img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 30px;
  background-color: #fff;
  width: 250px;
  height: 250px;
  position: relative;
}

@media (max-width: 1023px) {
  .body-ortho-k-lens__guide__slide__img-box {
    width: 220px;
    height: 220px;
  }
}

.body-ortho-k-lens__guide__slide__order {
  position: absolute;
  top: 15px;
  left: 20px;
}

.body-ortho-k-lens__guide__slide__order span {
  color: #2e5aa8;
  font-weight: bold !important;
  font: 600 25px/1 din-2014, sans-serif !important;
}

.body-ortho-k-lens__guide__slide__detail {
  margin-top: 20px;
}

.body-ortho-k-lens__guide__slide__detail p {
  color: #fff;
  font-size: 14px;
  line-height: 23px;
  margin: 0;
}

.body-ortho-k-lens__guide__clean__list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.body-ortho-k-lens__guide__clean__item {
  background-color: #fff;
  border-radius: 30px;
  padding: 40px 20px;
}

.body-ortho-k-lens__guide__clean__item__main-title-card {
  font-size: 25px;
  font-weight: bold !important;
  color: #2e5aa8;
  text-align: center;
  margin: 0;
}

.body-ortho-k-lens__guide__clean__item__img-clean {
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
}

.body-ortho-k-lens__guide__clean__item__img-clean img {
  display: block;
  width: 100%;
  max-height: 136px;
  margin: 0 auto;
}

.body-ortho-k-lens__guide__clean__item__img-clean .weekly-img {
  width: 100%;
  max-height: 128px;
  margin: 0 auto;
}

.body-ortho-k-lens__guide__clean__item__detail {
  margin-top: 25px;
}

.body-ortho-k-lens__guide__clean__item__detail p {
  color: #413d3d !important;
  font-size: 14px !important;
  line-height: 26px !important;
  margin: 0;
}

@media (max-width: 768px) {
  .body-ortho-k-lens__guide__clean__item__detail {
    font-size: 13px !important;
  }
}

@media (max-width: 767px) {
  .body-ortho-k-lens__guide__clean li + li {
    margin-top: 24px;
  }
}

@media (min-width: 768px) {
  .body-ortho-k-lens__guide {
    padding: 130px 0;
  }
  .body-ortho-k-lens__guide__frame__slide {
    margin-top: 60px;
  }
  .body-ortho-k-lens__guide__row {
    display: grid;
    grid-template-columns: 200px auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 0;
  }
  .body-ortho-k-lens__guide__row:first-child .body-ortho-k-lens__guide__row__slide {
    margin-top: 0;
    padding-top: 0;
  }
  .body-ortho-k-lens__guide__row:first-child .body-ortho-k-lens__guide__row__slide::after {
    content: none;
  }
  .body-ortho-k-lens__guide__row:not(:first-child) {
    padding: 0;
  }
  .body-ortho-k-lens__guide__row:not(:first-child)::after {
    content: none;
  }
  .body-ortho-k-lens__guide__row__title {
    margin-bottom: 0;
  }
  .body-ortho-k-lens__guide__row__title h4 {
    margin-right: inherit;
    margin-left: inherit;
  }
  .body-ortho-k-lens__guide__row__title__group {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .body-ortho-k-lens__guide__row__sub-title p {
    margin-top: 10px;
  }
  .body-ortho-k-lens__guide__title h3 {
    font-size: 19px;
    margin: 60px 0 30px;
  }
  .body-ortho-k-lens__guide__clean__row {
    max-width: 924px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .body-ortho-k-lens__guide__clean__row ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 30px;
  }
  .body-ortho-k-lens__guide__clean__row ul li {
    width: 47.402%;
  }
  .body-ortho-k-lens__guide__clean__row ul li__detail p {
    font-size: 14px;
    line-height: 26px;
  }
  .body-ortho-k-lens__guide__clean__row ul li + li {
    margin-top: 0;
  }
}

@media (min-width: 1296px) {
  .body-ortho-k-lens__guide__row {
    grid-template-columns: 250px auto;
  }
}
