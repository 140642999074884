.search-bar-nascent {
  max-width: 420px;
  height: 35px;
  border: 1px solid transparent;
  position: relative;
  will-change: border-color, padding-left, padding-right;
  -webkit-transition: border-color 0.3s ease-in-out, padding-left 0.3s ease-in-out, padding-right 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, padding-left 0.3s ease-in-out, padding-right 0.3s ease-in-out;
}

.search-bar-nascent--active {
  padding-left: 25px;
  padding-right: 25px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  border-color: #555;
  background-color: #FFFFFF;
}

.search-bar-nascent__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}

.search-bar-nascent__layout > div:first-child {
  width: 100%;
  height: 100%;
}

.search-bar-nascent__layout [src$=".svg"] {
  width: auto;
}

.search-bar-nascent__input {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: #555;
}

.search-bar-nascent__input::-webkit-input-placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: rgba(85, 85, 85, .3);
}

.search-bar-nascent__input::-moz-placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: rgba(85, 85, 85, .3);
}

.search-bar-nascent__input::-ms-input-placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: rgba(85, 85, 85, .3);
}

.search-bar-nascent__input::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  color: rgba(85, 85, 85, .3);
}

.search-bar-nascent__icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
  will-change: opacity;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.search-bar-nascent__icon:hover {
  opacity: 0.5;
}

.search-bar-nascent__icon--secondary {
  width: 15px;
  height: 15px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}

@media (min-width: 1440px) {
  .search-bar-nascent__icon--secondary {
    width: 24px;
    height: 24px;
  }
}
