.services {
  font-family: 'Founders Grotesk', sans-serif;
  letter-spacing: 1px;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    width: 100%;
  }
  ul,ol{
    list-style: none;
    padding: 0;
  }
  &__container {
    width: 100%;
    padding: 0 5% 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1152px;
  }
  &__subtitle{
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: 1px;
    margin: 50px 0 30px;
    color: #000;
  }
  &__lens-replacement {
    display: flex;
    flex-direction: column;
    background-color: #fbfbfb;
    padding: 30px calc(5% + 10px);
    position: relative;
    color: #000;
    font-size: 15px;
    line-height: 2;
    margin-top: 40px;
    &:hover {
      color: inherit;
    }
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      border: 2px solid #fff;
      pointer-events: none;
    }
    &-title {
      font-size: 26px;
      line-height: 1.6;
      margin: 0 0 10px;
    }
    &-subtitle,
    &-des,
    &-more,
    &-price {
      margin-bottom: 0;
    }
    &-des,
    &-more {
      color: #999;
    }
    &-price {
      color: #c7002e;
      font-size: 24px;
    }
  }
  @media (min-width: 768px) {
    &__container {
      width: 90%;
      padding-bottom: 120px;
    }
    &__subtitle{
      font-size: 26px;
      margin-top: 80px;
    }
    &__lens-replacement {
      display: grid;
      grid-template-columns: 1fr 60%;
      align-items: center;
      padding: 20px 10px;
      &-details {
        max-width: 80%;
      }
    }
  }
}
