.header-kuromi-collection__checkered-pattern {
  position: relative;
  overflow: hidden;
}

.header-kuromi-collection__checkered-pattern::before {
  content: '';
  position: absolute;
  top: -50vw;
  bottom: -50vw;
  left: -50vw;
  right: -50vw;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/checkered-pattern.svg") center/43px;
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
  z-index: 1;
  pointer-events: none;
}

.header-kuromi-collection__main {
  padding-top: 67px;
  padding-bottom: 30%;
  position: relative;
}

.header-kuromi-collection__main::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(308deg, #a69cca, #b8d5e7, #f99bb8);
  background-size: 600% 600%;
  -webkit-animation: kuromiBackgroundAnimation 60s ease infinite;
          animation: kuromiBackgroundAnimation 60s ease infinite;
  z-index: -1;
}

@media (min-width: 768px) {
  .header-kuromi-collection__main::before {
    background-image: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/main-bg-lg.webp");
    width: 100vw;
  }
}

@media (min-width: 1224px) {
  .header-kuromi-collection__main::before {
    left: calc(612px - 50vw);
  }
}

.header-kuromi-collection__main h1 {
  font-size: 40px;
  line-height: 1;
  grid-area: h1;
  margin: 0;
}

.header-kuromi-collection__main h1 img {
  width: 66.3%;
  max-width: 280px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .header-kuromi-collection__main h1 {
    -ms-flex-item-align: end;
        align-self: end;
  }
  .header-kuromi-collection__main h1 img {
    width: 100%;
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .header-kuromi-collection__main h1 img {
    width: 346px;
  }
}

.header-kuromi-collection__main figure {
  grid-area: img;
}

.header-kuromi-collection__main figure img {
  width: 87.5%;
  height: auto;
  display: block;
  max-width: 450px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .header-kuromi-collection__main figure img {
    width: 100%;
    max-width: none;
  }
}

.header-kuromi-collection__main__content {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 55px;
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .header-kuromi-collection__main__content {
    display: grid;
    gap: 0 60px;
    grid-template-columns: 32% auto;
    grid-template-areas: "h1 img" "date img";
  }
}

@media (min-width: 1024px) {
  .header-kuromi-collection__main__content {
    grid-template-columns: auto 559px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 0 60px;
  }
}

.header-kuromi-collection__main__release-date {
  margin: 0 auto;
  grid-area: date;
  text-align: center;
}

.header-kuromi-collection__main__release-date::before, .header-kuromi-collection__main__release-date::after {
  content: '';
  width: 18px;
  height: 24px;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/star.svg") center/contain no-repeat;
}

.header-kuromi-collection__main__release-date h3 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
  font: 600 29px/1 "Corporate Logo Rounded";
  margin: 0;
}

.header-kuromi-collection__main__release-date p {
  font-size: 14px;
  line-height: 2.2em;
  letter-spacing: 0.07em;
  margin: 0;
}

@media (min-width: 768px) {
  .header-kuromi-collection__main__release-date {
    margin-top: 30px;
  }
}

@media (min-width: 1024px) {
  .header-kuromi-collection__main__release-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: start;
        align-self: start;
    -webkit-column-gap: 3px;
       -moz-column-gap: 3px;
            column-gap: 3px;
    margin-top: 39px;
  }
  .header-kuromi-collection__main__release-date::before, .header-kuromi-collection__main__release-date::after {
    width: 13px;
    height: 18px;
  }
  .header-kuromi-collection__main__release-date h3 {
    font-size: 16px;
    line-height: 1;
  }
  .header-kuromi-collection__main__release-date p {
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.04em;
  }
}

.header-kuromi-collection__main__hashtag {
  position: absolute;
  top: 50%;
  right: -13px;
  min-width: 14px;
  width: 4.3%;
  height: auto;
  max-width: 25px;
  -webkit-transform: translateY(calc(-50% + 13px));
          transform: translateY(calc(-50% + 13px));
}

@media (min-width: 768px) {
  .header-kuromi-collection__main__hashtag {
    width: 25px;
    max-width: none;
  }
}

@media (min-width: 1296px) {
  .header-kuromi-collection__main__hashtag {
    right: -110px;
  }
}

.header-kuromi-collection__main__scroll-layout {
  text-align: center;
}

.header-kuromi-collection__main__scroll-btn {
  font-size: 14px;
  padding: 8px 30px 8px 13px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #bcadd3;
  color: white;
  border-radius: 3em;
  margin-top: 55px;
  position: relative;
  z-index: 2;
  text-decoration: none;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: pointer;
}

.header-kuromi-collection__main__scroll-btn:hover {
  color: white;
}

.header-kuromi-collection__main__scroll-btn::before {
  content: '';
  width: 29px;
  height: 29px;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/arrow-down.svg") center/contain no-repeat;
  margin-right: 11px;
}

@media (min-width: 768px) {
  .header-kuromi-collection__main__scroll-btn:hover, .header-kuromi-collection__main__scroll-btn:active, .header-kuromi-collection__main__scroll-btn:visited, .header-kuromi-collection__main__scroll-btn:focus {
    text-decoration: none;
  }
}

@media (min-width: 1024px) {
  .header-kuromi-collection__main__scroll-btn {
    font-size: 17px;
    padding: 10px 21px 9px 14px;
    margin-top: 41px;
  }
}

@media (min-width: 768px) {
  .header-kuromi-collection__main {
    padding-top: 120px;
    padding-bottom: 75px;
  }
}

.header-kuromi-collection__intro {
  background-color: #bcadd3;
  padding-top: 60px;
  padding-bottom: 300px;
  position: relative;
  color: black;
}

.header-kuromi-collection__intro::before {
  content: '';
  position: absolute;
  bottom: calc(100% - 2px);
  left: 0;
  right: 0;
  height: 22.6vw;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/intro-top-shape.svg") top center/cover no-repeat;
  pointer-events: none;
}

.header-kuromi-collection__intro__content-1 {
  position: relative;
  padding: 0 0 10px 7px;
  z-index: 4;
}

.header-kuromi-collection__intro__content-1::before {
  content: '';
  position: absolute;
  top: 10px;
  bottom: 3px;
  left: 0;
  right: 7px;
  background-color: #fcbbc2;
  border: 3px solid black;
  border-radius: 3px;
}

.header-kuromi-collection__intro__content-1-inner {
  background-color: white;
  padding: 64px 30px 45px 22px;
  border: 3px solid #2b2045;
  border-radius: 3px;
  position: relative;
  z-index: 2;
}

.header-kuromi-collection__intro__content-1-top {
  position: absolute;
  top: 0;
  left: 50%;
  width: 90%;
  max-width: 770px;
  -webkit-transform: translate(-50%, -52%);
          transform: translate(-50%, -52%);
  pointer-events: none;
}

.header-kuromi-collection__intro__content-1-title {
  display: block;
  width: 100%;
  margin: 0 auto 34px;
}

.header-kuromi-collection__intro__content-1 p {
  font-size: 15px;
  line-height: 2.5;
  letter-spacing: 2;
  margin: 0;
}

.header-kuromi-collection__intro__content-1-highlight-text {
  display: inline-block;
  width: auto;
  height: 24px;
  vertical-align: middle;
  margin-top: -5px;
}

.header-kuromi-collection__intro__content-1-character {
  display: block;
  width: 190px;
  margin: 10px auto 0;
}

@media (min-width: 768px) {
  .header-kuromi-collection__intro__content-1 {
    max-width: 883px;
    margin: 0 auto;
    padding: 0 0 20px 16px;
  }
  .header-kuromi-collection__intro__content-1::before {
    top: 25px;
    right: 18px;
    border-width: 6px;
  }
  .header-kuromi-collection__intro__content-1-inner {
    padding: 112px 92px 50px 92px;
    border-width: 6px;
  }
  .header-kuromi-collection__intro__content-1-character {
    position: absolute;
  }
}

@media (min-width: 1024px) {
  .header-kuromi-collection__intro__content-1-inner {
    padding-bottom: 90px;
  }
  .header-kuromi-collection__intro__content-1-title {
    width: 500px;
    margin-bottom: 57px;
  }
  .header-kuromi-collection__intro__content-1 p {
    font-size: 22px;
  }
  .header-kuromi-collection__intro__content-1-highlight-text {
    height: 36px;
  }
  .header-kuromi-collection__intro__content-1-character {
    bottom: -7px;
  }
}

@media (min-width: 1296px) {
  .header-kuromi-collection__intro__content-1-character {
    width: 318px;
    right: -198px;
  }
}

.header-kuromi-collection__intro__content-2 {
  margin-top: 105px;
  position: relative;
  z-index: 4;
}

.header-kuromi-collection__intro__content-2-inner {
  background-color: white;
  padding: 50px 30px 1px;
  -webkit-box-shadow: 10px 10px 0 #2b2045;
          box-shadow: 10px 10px 0 #2b2045;
  position: relative;
}

.header-kuromi-collection__intro__content-2-img {
  display: block;
  width: 95px;
  margin: 0 auto 45px;
}

.header-kuromi-collection__intro__content-2-title {
  font: 700 23px/1 "Corporate Logo Rounded";
  padding-bottom: 18px;
  text-align: center;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/intro-2-dot-sp.svg") center bottom no-repeat;
  display: inline-table;
  line-height: 1.3;
  background-image: none;
  margin: 0 0 10px 0;
}

@media (min-width: 768px) {
  .header-kuromi-collection__intro__content-2-title {
    letter-spacing: 0.06em;
    background-repeat: repeat-x;
  }
}

.header-kuromi-collection__intro__content-2 p {
  font: 800 13px/2.5 "heisei-maru-gothic-std", sans-serif;
  margin: 0;
  letter-spacing: 0;
  line-height: 2.1;
}

.header-kuromi-collection__intro__content-2-character {
  display: block;
  width: 190px;
  margin: 15px auto -36px;
}

@media (min-width: 1024px) {
  .header-kuromi-collection__intro__content-2 {
    margin-top: 200px;
  }
  .header-kuromi-collection__intro__content-2-inner {
    padding: 53px 16px 53px 89px;
    -webkit-box-shadow: 14px 14px 0 #2b2045;
            box-shadow: 14px 14px 0 #2b2045;
    display: grid;
    grid-template-columns: 108px auto;
    -webkit-column-gap: 70px;
       -moz-column-gap: 70px;
            column-gap: 70px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header-kuromi-collection__intro__content-2-img {
    width: 100%;
    margin: 0;
  }
  .header-kuromi-collection__intro__content-2-detail {
    position: relative;
  }
  .header-kuromi-collection__intro__content-2-title {
    font-size: 24px;
  }
  .header-kuromi-collection__intro__content-2 p {
    font-size: 14px;
    max-width: 545px;
  }
  .header-kuromi-collection__intro__content-2-character {
    width: 200px;
    margin: 0;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@media (min-width: 1296px) {
  .header-kuromi-collection__intro__content-2 p {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .header-kuromi-collection__intro {
    padding-top: 310px;
    padding-bottom: 370px;
  }
  .header-kuromi-collection__intro .base-kuromi-collection__container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header-kuromi-collection__intro::before {
    height: 54px;
    background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/intro-top-shape-pc.webp") center bottom/100% 100% no-repeat;
  }
  .header-kuromi-collection__intro::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/intro-bg-pc.webp") top 100px center/140% auto no-repeat;
    z-index: 2;
    pointer-events: none;
  }
}

@media (min-width: 1024px) {
  .header-kuromi-collection__intro {
    padding-bottom: 400px;
  }
  .header-kuromi-collection__intro::after {
    background-size: 1478px auto;
  }
}

@media (min-width: 1400px) {
  .header-kuromi-collection__intro {
    padding-bottom: 500px;
  }
}

@-webkit-keyframes kuromiBackgroundAnimation {
  0% {
    background-position: 0% 9%;
  }
  50% {
    background-position: 100% 92%;
  }
  100% {
    background-position: 0% 9%;
  }
}

@keyframes kuromiBackgroundAnimation {
  0% {
    background-position: 0% 9%;
  }
  50% {
    background-position: 100% 92%;
  }
  100% {
    background-position: 0% 9%;
  }
}
