.corporate-information {
  padding: 60px 200px 160px;
}

.corporate-information__title {
  margin: 0 0 90px;
  text-align: center;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0em;
  color: #000000;
}

.corporate-information__subtitle {
  margin: 0 0 50px;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  color: #000000;
}

.corporate-information__divider {
  border-top: 1px solid rgba(0, 0, 0, .1);
  margin: 50px 0;
}
