.footer-huawei-2-titanium__spec {
  padding-top: 57px;
}

@media (min-width: 768px) {
  .footer-huawei-2-titanium__spec {
    padding-top: 120px;
  }
}

.footer-huawei-2-titanium__spec__title {
  text-align: center;
  font-family: "BIZ UDPGothic", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 51.2px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  color: black;
  padding-bottom: 45px;
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__title {
    font-size: 66px;
    line-height: 105.6px;
    padding-bottom: 60px;
  }
}

.footer-huawei-2-titanium__spec__list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.footer-huawei-2-titanium__spec__list--sp {
  display: block;
}

.footer-huawei-2-titanium__spec__list--pc {
  display: none;
}

.footer-huawei-2-titanium__spec__list > li:not(:first-child) {
  margin-top: 40px;
}

.footer-huawei-2-titanium__spec__list h3, .footer-huawei-2-titanium__spec__list p {
  margin-bottom: 0;
}

.footer-huawei-2-titanium__spec__list h3 {
  font-family: "BIZ UDPGothic", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 30.8px;
  letter-spacing: 0.14px;
  color: black;
  margin-bottom: 16.667px;
  display: grid;
  grid-template-columns: 23.589px 1fr;
  gap: 12.78px;
  position: relative;
}

.footer-huawei-2-titanium__spec__list h3::after {
  position: absolute;
  left: 0;
  bottom: -8px;
  content: "";
  display: block;
  width: 100%;
  height: auto;
  border-bottom: 0.667px solid black;
}

.footer-huawei-2-titanium__spec__list h3 > span:nth-child(1) {
  margin: auto;
}

.footer-huawei-2-titanium__spec__list p {
  font-family: "BIZ UDPGothic", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 30.8px;
  letter-spacing: 0.14px;
  color: black;
}

@media (min-width: 768px) {
  .footer-huawei-2-titanium__spec__list--sp {
    display: none;
  }
  .footer-huawei-2-titanium__spec__list--pc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 45px 6.18%;
  }
  .footer-huawei-2-titanium__spec__list--pc > ul:first-child > li:not(:first-child), .footer-huawei-2-titanium__spec__list--pc > ul:nth-child(2) > li:not(:first-child) {
    margin-top: 45px;
  }
  .footer-huawei-2-titanium__spec__list--pc > ul:last-child {
    grid-column: span 2;
  }
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__list h3 {
    font-size: 16px;
    line-height: 35.2px;
    letter-spacing: 0.16px;
    margin-bottom: 30.667px;
    display: grid;
    grid-template-columns: 30.12px 1fr;
    gap: 18px;
  }
  .footer-huawei-2-titanium__spec__list h3::after {
    bottom: -15px;
  }
  .footer-huawei-2-titanium__spec__list p {
    font-size: 13px;
    font-weight: 700 !important;
    line-height: 30px;
    letter-spacing: 0.16px;
    color: black;
  }
}

.footer-huawei-2-titanium__spec__item__remarks {
  list-style-type: none;
  padding-left: 11px;
  margin-bottom: 0;
  color: #535353;
  font-family: "BIZ UDPGothic";
  font-size: 11px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20.9px;
  margin-top: 10px;
  position: relative;
}

.footer-huawei-2-titanium__spec__item__remarks li::before {
  content: '*';
  position: absolute;
  left: 0;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item__remarks {
    font-size: 13px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 24.7px;
    letter-spacing: 0.13px;
    padding-left: 20.15px;
  }
}

.footer-huawei-2-titanium__spec__item__accessories {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 17px 19px;
  margin-top: 13px;
}

.footer-huawei-2-titanium__spec__item__accessories li > p {
  color: black;
  font-family: "BIZ UDPGothic", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.11px;
}

@media (min-width: 768px) {
  .footer-huawei-2-titanium__spec__item__accessories {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .footer-huawei-2-titanium__spec__item__accessories__accessories-pc {
    display: grid;
    grid-template-columns: calc(50% - 32px) 1fr;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
    margin: 20px 0;
  }
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item__accessories li > p {
    font-size: 13px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 1.6;
    letter-spacing: 0.13px;
  }
}

.footer-huawei-2-titanium__spec__item-speakers h3 > span > img {
  width: 23.589px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-speakers h3 > span > img {
    width: 30.12px;
  }
}

.footer-huawei-2-titanium__spec__item-microphones h3 > span > img {
  width: 13.76px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-microphones h3 > span > img {
    width: 19px;
  }
}

.footer-huawei-2-titanium__spec__item-battery h3 > span > img {
  width: 13.027px;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="1 0" /><feFuncG type="table" tableValues="1 0" /><feFuncB type="table" tableValues="1 0" /></feComponentTransfer></filter></svg>#filter');
  -webkit-filter: invert(1);
          filter: invert(1);
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-battery h3 > span > img {
    width: 18px;
  }
}

.footer-huawei-2-titanium__spec__item-product-weight h3 > span > img {
  width: 18.674px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-product-weight h3 > span > img {
    width: 24px;
  }
}

.footer-huawei-2-titanium__spec__item-product-weight h3 > span:last-child {
  position: relative;
}

.footer-huawei-2-titanium__spec__item-product-weight h3 > span:last-child::before {
  content: '* excluding lenses';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 5%;
  font-family: "BIZ UDPGothic", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 220%;
  letter-spacing: 0.11px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-product-weight h3 > span:last-child::before {
    right: unset;
    left: 206px;
  }
}

.footer-huawei-2-titanium__spec__item-others h3 > span > img {
  width: 21.623px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-others h3 > span > img {
    width: 30px;
  }
}

.footer-huawei-2-titanium__spec__item-others > p:last-child {
  margin-top: 10px;
}

.footer-huawei-2-titanium__spec__item-in-box h3 > span > img {
  width: 24px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-in-box h3 > span > img {
    width: 36.182px;
  }
}

.footer-huawei-2-titanium__spec__item-in-box__manual a {
  color: #0094C8;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.footer-huawei-2-titanium__spec__item-in-box__manual a u {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.footer-huawei-2-titanium__spec__item-in-box__manual a img {
  width: 20.101px;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__spec__item-in-box__manual a {
    cursor: pointer;
  }
  .footer-huawei-2-titanium__spec__item-in-box__manual a:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0.6;
  }
}

.footer-huawei-2-titanium__spec__item-in-box__box-arrow {
  margin-top: 35px;
  background-color: #F7F7F7;
  padding: 20px 15px;
  position: relative;
  color: #535353;
}

.footer-huawei-2-titanium__spec__item-in-box__box-arrow::after {
  content: "";
  position: absolute;
  top: -18px;
  left: 70px;
  width: 23px;
  height: 22px;
  background-image: url("https://static.lenskart.com/owndays/img/huawei2-titanium/polygon-icon.svg");
  background-repeat: no-repeat;
  background-position: 0;
  background-size: auto 23px;
}

.footer-huawei-2-titanium__spec__item-in-box__box-arrow a {
  color: #0094C8;
  text-decoration: underline;
  text-underline-offset: 2px;
}

@media (min-width: 768px) {
  .footer-huawei-2-titanium__spec__item-in-box__box-arrow {
    margin-top: 0;
    padding: 20px;
  }
  .footer-huawei-2-titanium__spec__item-in-box__box-arrow::after {
    top: 28px;
    left: -18px;
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
}

.footer-huawei-2-titanium__attention {
  width: 100%;
  height: auto;
  background-color: #F7F7F7;
  padding-block: 70px;
  margin: 70px 0;
}

.footer-huawei-2-titanium__attention__title {
  text-align: center;
  font-family: "BIZ UDPGothic", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 51.2px;
  letter-spacing: 0.32px;
  margin: 0;
  color: black;
}

.footer-huawei-2-titanium__attention__question {
  width: 100%;
  height: auto;
  max-width: 900px;
  border-radius: 10px;
  border: 1px solid #535353;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 45px auto 0 auto;
}

.footer-huawei-2-titanium__attention__question__layout--q {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.footer-huawei-2-titanium__attention__question__layout--q::before, .footer-huawei-2-titanium__attention__question__layout--q::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 23px;
  height: 1px;
  background-color: #535353;
}

.footer-huawei-2-titanium__attention__question__layout--q::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
  opacity: 1;
}

.footer-huawei-2-titanium__attention__question__layout--q__open::after {
  -webkit-transform: translateY(-50%) rotate(0deg) !important;
          transform: translateY(-50%) rotate(0deg) !important;
  opacity: 0;
}

.footer-huawei-2-titanium__attention__question__layout--a {
  padding: 0 20px 0 20px;
  -webkit-transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.footer-huawei-2-titanium__attention__question__q {
  font-family: "BIZ UDPGothic", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 30.8px;
  letter-spacing: 0.14px;
  padding-left: 20px;
  color: #535353;
}

.footer-huawei-2-titanium__attention__question h3, .footer-huawei-2-titanium__attention__question h4 {
  font-weight: 700 !important;
}

.footer-huawei-2-titanium__attention__question h3, .footer-huawei-2-titanium__attention__question h4, .footer-huawei-2-titanium__attention__question ul, .footer-huawei-2-titanium__attention__question p {
  font-family: "BIZ UDPGothic", serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.6px;
  margin: 0;
  color: #535353;
}

.footer-huawei-2-titanium__attention__question h4 {
  margin-top: 24px;
}

.footer-huawei-2-titanium__attention__question ul, .footer-huawei-2-titanium__attention__question p {
  font-weight: 400 !important;
}

.footer-huawei-2-titanium__attention__question ul {
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0 0 0;
}

.footer-huawei-2-titanium__attention__question ul:last-child {
  padding-bottom: 20px;
}

.footer-huawei-2-titanium__attention__question ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-huawei-2-titanium__attention__question ul li span:nth-child(1) {
  margin-right: 7px;
}

.footer-huawei-2-titanium__attention__question__note {
  position: relative;
  padding-left: 12px;
}

.footer-huawei-2-titanium__attention__question__note::before {
  content: '*';
  position: absolute;
  left: 0;
}

.footer-huawei-2-titanium__attention__remarks {
  width: 100%;
  height: auto;
  max-width: 900px;
  list-style-type: none;
  padding-left: 0;
  margin: 37px auto 0 auto;
}

.footer-huawei-2-titanium__attention__remarks li {
  position: relative;
  padding-left: 12px;
  font-family: "BIZ UDPGothic", serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.6px;
  letter-spacing: 0.13px;
  color: #535353;
}

.footer-huawei-2-titanium__attention__remarks li::before {
  content: '*';
  position: absolute;
  left: 0;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__attention {
    padding-block: 120px;
    margin-top: 120px;
  }
  .footer-huawei-2-titanium__attention__title {
    font-size: 66px;
    line-height: 105.6px;
    letter-spacing: 0.66px;
  }
  .footer-huawei-2-titanium__attention__question {
    max-width: 900px;
    margin: 60px auto 0 auto;
    cursor: pointer;
  }
  .footer-huawei-2-titanium__attention__question__layout--q {
    height: 122px;
  }
  .footer-huawei-2-titanium__attention__question__layout--q::before, .footer-huawei-2-titanium__attention__question__layout--q::after {
    width: 40px;
    height: 1px;
    background-color: #535353;
    right: 42px;
  }
  .footer-huawei-2-titanium__attention__question__layout--a {
    padding: 0 42px;
  }
  .footer-huawei-2-titanium__attention__question__q {
    font-size: 18px;
    line-height: 39.6px;
    padding-left: 42px;
  }
  .footer-huawei-2-titanium__attention__question h3, .footer-huawei-2-titanium__attention__question h4, .footer-huawei-2-titanium__attention__question ul, .footer-huawei-2-titanium__attention__question p {
    font-size: 16px;
    line-height: 35.2px;
    letter-spacing: normal;
  }
  .footer-huawei-2-titanium__attention__question ul:last-child {
    padding-bottom: 56px;
  }
  .footer-huawei-2-titanium__attention__question__note {
    padding-left: 19px;
  }
  .footer-huawei-2-titanium__attention__remarks {
    margin: 60px auto 0 auto;
  }
  .footer-huawei-2-titanium__attention__remarks li {
    padding-left: 19px;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 35.2px;
  }
}

.footer-huawei-2-titanium__share {
  width: 100%;
  height: auto;
  background-color: #414141;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 34px;
  padding: 43px 0 58px 0;
}

.footer-huawei-2-titanium__share h2 {
  margin: 0;
  color: white;
  text-align: center;
  font-family: "BIZ UDPGothic", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 33px;
  letter-spacing: 3px;
}

.footer-huawei-2-titanium__share__list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 40px;
}

.footer-huawei-2-titanium__share__list li a {
  font-size: 35px;
  color: white;
  display: block;
  width: 35px;
  height: 35px;
}

.footer-huawei-2-titanium__share__list li a i {
  background: transparent;
}

.footer-huawei-2-titanium__share__list li:nth-child(1), .footer-huawei-2-titanium__share__list li:nth-child(2) {
  margin-top: -5px;
}

.footer-huawei-2-titanium__share__list li:nth-child(3) {
  width: 35px;
  height: 35px;
  position: relative;
}

.footer-huawei-2-titanium__share__list li:nth-child(3) a {
  color: #414141;
  position: relative;
}

.footer-huawei-2-titanium__share__list li:nth-child(3) a i {
  font-size: 22.23px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.footer-huawei-2-titanium__share__list li:nth-child(3) a::before {
  position: absolute;
  content: '';
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 0;
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__share__list {
    margin: 0;
    gap: 36px;
  }
  .footer-huawei-2-titanium__share__list li a {
    cursor: pointer;
    -webkit-transition: .5s;
    transition: .5s;
    max-height: none;
  }
  .footer-huawei-2-titanium__share__list li:not(:nth-child(3)) {
    margin-top: -13px;
  }
  .footer-huawei-2-titanium__share__list li:not(:nth-child(3)) a:hover {
    color: #b3b3b3;
  }
  .footer-huawei-2-titanium__share__list li:nth-child(3) a:hover::before {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #b3b3b3;
  }
}

@media (min-width: 1024px) {
  .footer-huawei-2-titanium__share {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 40px;
  }
  .footer-huawei-2-titanium__share h2 {
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 4px;
  }
}
