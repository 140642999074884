.bottom-sheet-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 200;
}

.bottom-sheet {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 201;
    pointer-events: none;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition-duration: .4s;
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(.25, .8, .25, 1);
    transition-duration: .4s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.25, .8, .25, 1);
    &.bottom-sheet-in {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        pointer-events: auto;
        z-index: 101;
    }
}
.top-border {
    border-radius: 12px 12px 0px 0px;
}
.scroll{
    max-height: 400px;
    overflow-y: scroll;
}

.six-over-six-height{
    height: 100vh;
}
.border-radius16{
    border-radius: 16px 16px 0 0;
}

.height-expand {
    height: 100%;
}