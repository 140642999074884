
@keyframes fadeIn {
	0% {
	  opacity: 0;
	  top: -10px;
	}
	100% {
	  opacity: 1;
	  top: 0px;
	}
  }
  
  @keyframes fadeOut {
	0% {
	  opacity: 1;
	  top: 0px;
	}
	100% {
	  opacity: 0;
	  top: -10px;
	}
  }