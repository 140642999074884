.app-footer {
  background-color: #ffffff;
  letter-spacing: 1px;
  font-family: 'Founders Grotesk', sans-serif;
}

.app-footer-top {
  padding: 60px 0;
  background-color: #fafafa;
}

.app-footer-top__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.app-footer-nav__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.app-footer-nav__title {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  font-weight: 400;
  font-family: 'Founders Grotesk', sans-serif;
  text-transform: uppercase;
}

.app-footer-nav__link {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  color: #999;
  cursor: pointer;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  font-weight: 400;
}

.app-footer-nav__link:hover {
  color: #666;
  text-decoration: none;
}

.app-footer-nav__link--new-users {
  margin-top: 20px;
}

.app-footer-nav__link:last-child {
  margin-bottom: 0;
}

.app-footer-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.app-footer-contact__title {
  margin-bottom: 18px;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  color: #000000;
}

.app-footer-contact__call-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 37px;
}

.app-footer-contact__phone {
  margin-bottom: 0;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.app-footer-contact__hours {
  margin-bottom: 0;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}

.app-footer-info {
  padding: 40px 0;
}

.app-footer-info__copyright {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0em;
  color: #000000;
  opacity: 0.7;
}

.app-footer-info__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 25px;
}

.app-footer-info__link {
  text-decoration: underline;
}

.app-footer-info__link, .app-footer-info__divider {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0em;
  color: #000000;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.app-footer-info__link:hover, .app-footer-info__divider:hover {
  color: #000000;
  opacity: 0.3;
}

.app-footer-info__country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  cursor: pointer;
}

.app-footer-info__country-img {
  width: 25px;
}

.app-footer-info__country__toggle {
  width: 8px;
  height: 5px;
}

.app-footer-info__country span {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0em;
  color: #000000;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.app-footer-info__country span:hover {
  color: #000000;
  opacity: 0.3;
}
