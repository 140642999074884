.theme-button {
  background-color: #00b9c6;
  color: #fff;
}

.flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.lk-exchange-modal .modal {
  height: auto;
  margin: 0;
}
