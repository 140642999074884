.header-freaks-store__main {
  position: relative;
}

.header-freaks-store__main__bg img {
  aspect-ratio: 9 / 13;
}

.header-freaks-store__main__logo-container {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  padding: 0;
}

.header-freaks-store__main__logo {
  margin: 0;
  background-color: rgba(255, 255, 255, .84);
  padding: 31px 0;
}

.header-freaks-store__main__logo img {
  width: 47%;
  height: auto;
  display: block;
  margin: 0 auto;
  aspect-ratio: 8 / 3;
}

@media (min-width: 768px) {
  .header-freaks-store__main__bg img {
    aspect-ratio: 144 / 65;
  }
  .header-freaks-store__main__logo-container {
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-right: 12vw;
  }
  .header-freaks-store__main__logo {
    background: none;
    padding: 0;
  }
  .header-freaks-store__main__logo img {
    width: 22vw;
  }
}

.header-freaks-store__concept {
  background-color: #555E2B;
  padding: 74px 0 0;
}

.header-freaks-store__concept__animate-typing {
  -webkit-transition: 0s !important;
  transition: 0s !important;
}

.header-freaks-store__concept__animate-typing__text-split {
  font: inherit;
  display: inline;
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s;
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
  -webkit-transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
          transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  position: relative;
  top: 0;
}

.header-freaks-store__concept__animate-typing__text-split--empty {
  width: 0.5em;
}

.header-freaks-store__concept__animate-typing:not(.aos-animate) {
  opacity: 0;
}

.header-freaks-store__concept__animate-typing:not(.aos-animate) .header-freaks-store__concept__animate-typing__text-split {
  -webkit-transition-duration: 0s !important;
          transition-duration: 0s !important;
  -webkit-transition-delay: 0s !important;
          transition-delay: 0s !important;
  top: 20px;
  opacity: 0;
}

.header-freaks-store__concept__title {
  font: 900 24px/37px "Noto Sans JP", sans-serif;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 1.5px;
  color: white;
}

.header-freaks-store__concept__title span {
  font-weight: inherit !important;
}

.header-freaks-store__concept__title p {
  font: 400 14px/26px "Noto Sans JP", sans-serif;
}

.header-freaks-store__concept__vdo {
  display: block;
  position: relative;
  min-width: 100%;
  -webkit-transition: none;
  transition: none;
  margin-top: 81px;
}

.header-freaks-store__concept__vdo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 11.1vw;
  max-width: 110px;
  background: url("https://static.lenskart.com/media/owndays/img/freaks-store/play-icon.svg") center/contain no-repeat;
  z-index: 2;
}

.header-freaks-store__concept__vdo picture {
  display: block;
  opacity: 1;
  position: relative;
  z-index: 1;
}

.header-freaks-store__concept__vdo picture::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  pointer-events: none;
  z-index: -1;
}

.header-freaks-store__concept p {
  font: 400 14px/26px "Noto Sans JP", sans-serif;
  margin-bottom: 0;
  color: white;
}

@media (min-width: 768px) {
  .header-freaks-store__concept .base-freaks-store__btn {
    margin-top: 39px;
    max-width: 380px;
  }
  .header-freaks-store__concept .base-freaks-store__container {
    display: grid;
    grid-template-columns: 49% auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
    max-width: 1056px;
  }
  .header-freaks-store__concept__title {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 1.5px;
    white-space: nowrap;
    margin: 0;
  }
  .header-freaks-store__concept__vdo {
    width: 5.6vw;
  }
}

@media (min-width: 1024px) {
  .header-freaks-store__concept {
    padding: 80px 0 0;
  }
  .header-freaks-store__concept .base-freaks-store__container {
    grid-template-columns: auto 527px;
  }
  .header-freaks-store__concept p {
    font-size: 16px;
  }
}

.header-freaks-store__menu {
  padding: 37px 0 50px;
  background-color: #F7F7F7;
}

.header-freaks-store__menu .base-freaks-store__container {
  padding-top: 23px;
}

.header-freaks-store__menu ul {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.header-freaks-store__menu ul li {
  position: relative;
}

.header-freaks-store__menu__item--lineup .header-freaks-store__menu__icon img {
  width: 30px;
  top: 18px;
}

.header-freaks-store__menu__item--original .header-freaks-store__menu__icon img {
  width: 22px;
  top: 10px;
}

.header-freaks-store__menu__icon {
  display: block;
  width: 60px;
  height: 60px;
  background: linear-gradient(0deg, rgba(85, 94, 43, 0) 36px, #555E2B 0);
  border-radius: 50%;
  position: absolute;
  top: -23px;
  left: 0;
  right: 0;
  margin: auto;
  isolation: isolate;
  z-index: 2;
}

.header-freaks-store__menu__icon img {
  display: block;
  width: 30px;
  height: auto;
  top: 18px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  z-index: 1;
}

.header-freaks-store__menu__icon::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: #F5F4EF;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 24px, 0 24px);
          clip-path: polygon(0 0, 100% 0, 100% 24px, 0 24px);
  border-radius: 50%;
  isolation: isolate;
}

.header-freaks-store__menu__icon-hover {
  display: none !important;
}

.header-freaks-store__menu__content {
  display: block;
  text-align: center;
  padding: 38px 10px 12px;
  background-color: #F5F4EF;
  border: 2px solid #555E2B;
  position: relative;
  height: 100%;
}

.header-freaks-store__menu__content-inner {
  color: #555E2B;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 4;
}

.header-freaks-store__menu__content-inner::after {
  content: "";
  display: block;
  margin: auto auto 0;
  width: 22px;
  height: 11px;
  background-color: currentColor;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
          clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.header-freaks-store__menu__content-title {
  font: 700 24px/1 din-condensed, sans-serif;
  margin: 0;
}

.header-freaks-store__menu__content-desc {
  display: block;
  font: 700 12px/1 "Noto Sans JP", sans-serif;
  margin: 11px 0 15px;
  color: black;
}

@media (min-width: 768px) {
  .header-freaks-store__menu {
    padding: 119px 0 130px;
  }
  .header-freaks-store__menu .base-freaks-store__container {
    padding-top: 51px;
  }
  .header-freaks-store__menu ul {
    gap: 20px;
  }
  .header-freaks-store__menu__item--lineup .header-freaks-store__menu__icon img {
    width: 60px;
    top: 38px;
  }
  .header-freaks-store__menu__item--goods .header-freaks-store__menu__icon img {
    width: 47px;
    top: 18px;
  }
  .header-freaks-store__menu__item a {
    color: transparent;
  }
  .header-freaks-store__menu__item a:hover, .header-freaks-store__menu__item a:active, .header-freaks-store__menu__item a:visited, .header-freaks-store__menu__item a:focus {
    text-decoration: none !important;
  }
  .header-freaks-store__menu__icon {
    width: 140px;
    height: 140px;
    background: linear-gradient(0deg, rgba(85, 94, 43, 0) 87px, #555E2B 0);
    top: -51px;
  }
  .header-freaks-store__menu__icon img {
    display: block;
    width: 30px;
    top: 18px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
    z-index: 1;
  }
  .header-freaks-store__menu__icon::after {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 52px, 0 52px);
            clip-path: polygon(0 0, 100% 0, 100% 52px, 0 52px);
  }
  .header-freaks-store__menu__content {
    padding: 45px 10px 15px;
  }
  .header-freaks-store__menu__content-inner::after {
    width: 38px;
    height: 20px;
  }
  .header-freaks-store__menu__content-title {
    font-size: 42px;
  }
  .header-freaks-store__menu__content-desc {
    font-size: 14px;
    margin: 6px 0 20px;
  }
}

@media (min-width: 1024px) {
  .header-freaks-store__menu__icon img.header-freaks-store__menu__icon-hover {
    display: block;
    opacity: 0 !important;
  }
  .header-freaks-store__menu__icon-hover {
    display: block !important;
  }
  .header-freaks-store__menu__item {
    cursor: pointer;
  }
  .header-freaks-store__menu__item:hover .header-freaks-store__menu__icon img:not(.header-freaks-store__menu__icon-hover) {
    opacity: 0 !important;
  }
  .header-freaks-store__menu__item:hover .header-freaks-store__menu__icon img.header-freaks-store__menu__icon-hover {
    opacity: 1 !important;
  }
  .header-freaks-store__menu__item:hover .header-freaks-store__menu__content-inner, .header-freaks-store__menu__item:hover .header-freaks-store__menu__content-desc {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: white;
  }
  .header-freaks-store__menu__item:hover .header-freaks-store__menu__content {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 2px solid #555E2B;
  }
  .header-freaks-store__menu__item:hover .header-freaks-store__menu__icon::after, .header-freaks-store__menu__item:hover .header-freaks-store__menu__content {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #555E2B;
  }
}
