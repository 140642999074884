.bundle-sale {
  width: 100%;
  max-width: 1020px;
  padding: 35px 20px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 1px;
}

.bundle-sale__title {
  font-size: 18px;
  font-weight: 700 !important;
  line-height: 1.6;
  letter-spacing: 2px;
  padding: 15px 0;
  margin: 0 0 10px;
}

.bundle-sale__thumbnail {
  width: 100%;
  margin-bottom: 20px;
  aspect-ratio: 5/2;
}

.bundle-sale__container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.bundle-sale__text {
  margin-bottom: 25px;
}

.bundle-sale__text strong {
  font-size: 17px;
  font-weight: 600;
}

.bundle-sale__subtitle {
  font-size: 18px;
  font-weight: 700 !important;
  letter-spacing: 2px;
  line-height: 1.6;
  margin: 70px 0 35px;
  position: relative;
}

.bundle-sale__subtitle::before {
  content: '';
  width: 5px;
  height: 100%;
  position: absolute;
  top: -2px;
  left: -20px;
  background-color: #000;
}

.bundle-sale__highlights {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
  padding: 0 24px;
}

.bundle-sale__highlights-li {
  border: 1px solid #dee2e6;
  padding: 24px;
  display: grid;
  grid-gap: 16px;
}

.bundle-sale__highlights-img {
  aspect-ratio: 1/1;
  width: 100%;
}

.bundle-sale__highlights-details h3 {
  font-size: 15px;
  font-weight: 700 !important;
  line-height: 1.5;
  margin: 0 0 24px;
}

.bundle-sale__highlights-details h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  margin: 0;
}

.bundle-sale__highlights-details ul {
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px;
}

.bundle-sale__highlights-details ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: .5em;
     -moz-column-gap: .5em;
          column-gap: .5em;
}

.bundle-sale__highlights-details ul li strong {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 600;
}

.bundle-sale__highlights-details ul li a {
  color: #2e5aa8;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.bundle-sale__highlights-details ul li a:hover {
  color: #999;
  text-decoration: none;
}

.bundle-sale__highlights-terms {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-weight: 600;
  line-height: 1;
  color: #2e5aa8;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: .25em;
     -moz-column-gap: .25em;
          column-gap: .25em;
  position: relative;
}

.bundle-sale__highlights-terms::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  border-bottom: 1px solid currentColor;
}

.bundle-sale__highlights-terms img {
  width: 15px;
  height: 15px;
  -o-object-fit: contain;
     object-fit: contain;
}

.bundle-sale__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1000;
  padding: 20px;
}

.bundle-sale__modal-content {
  background: #fff;
  color: #000;
  padding: 57px 27px;
  width: 100%;
  max-width: 960px;
  max-height: 90vh;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  overflow-y: auto;
}

.bundle-sale__modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.bundle-sale__modal-title {
  text-align: center;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 1;
  letter-spacing: .02em;
  margin: 0 0 27px;
}

.bundle-sale__modal-subtitle {
  font-weight: 700;
  text-decoration: underline;
  margin: 0;
}

.bundle-sale__modal-terms {
  list-style: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  line-height: 2;
  margin-bottom: 0;
}

.bundle-sale__modal-terms li {
  position: relative;
  padding-left: 20px;
}

.bundle-sale__modal-terms li::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 4px;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 100%;
}

@media (max-width: 767px) {
  .bundle-sale__modal-content {
    padding-bottom: 200px;
  }
}

@media (min-width: 768px) {
  .bundle-sale__highlights {
    padding: 0;
  }
  .bundle-sale__highlights-li {
    grid-template-columns: 1fr 50%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (min-width: 1024px) {
  .bundle-sale {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .bundle-sale__title {
    font-size: 34px;
    font-weight: 400 !important;
    padding: 10px 0 30px;
    margin-bottom: 30px;
  }
  .bundle-sale__subtitle {
    font-size: 26px;
    line-height: 1.6;
  }
  .bundle-sale__subtitle::before {
    width: 6px;
  }
  .bundle-sale__highlights {
    row-gap: 48px;
  }
  .bundle-sale__highlights-li {
    padding: 48px;
    grid-gap: 0 48px;
  }
  .bundle-sale__modal-close {
    width: 38px;
    height: 38px;
    top: 35px;
    right: 35px;
  }
  .bundle-sale__modal-close img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .bundle-sale__modal-content {
    padding: 79px 102px 111px;
    font-size: 15px;
  }
  .bundle-sale__modal-title {
    font-size: 22px;
    margin-bottom: 60px;
  }
}
