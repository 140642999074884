.od-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #F5F2EA;
  margin: 60px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-circle__p {
  position: absolute;
  top: 61px;
  left: 63px;
}

.od-circle__smile-first {
  position: absolute;
  top: 61px;
  left: 49px;
}

.od-circle__smile-second {
  position: absolute;
  top: 61px;
  left: 84px;
}

.od-circle__stairs-border {
  position: absolute;
  top: 48px;
  left: 48px;
}

.od-circle__vector {
  position: absolute;
  top: 45px;
  left: 45px;
}
