.navigation-drawer-sidenav {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1001;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: fadeIn 1.2s ease-in-out;
}
#navigation-drawer-backdrop {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-animation: fadeIn 1.2s ease-in-out;
    animation: fadeIn 1.2s ease-in-out;
}
.closeicon {
    margin: 20px;
    cursor: pointer;
    position: fixed;
    right: 0;
    z-index: 1234;
}
@media screen and (max-height: 700px) {
    .navigation-drawer-sidenav {
        overflow-y: scroll;
    }
  }
