.od-upload-prescription-d {
  display: flex;
  font-style: normal;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 30px;
  align-items: center;

  &__left {
    margin-right: 100px;
    padding-top: 50px;
    position: relative;
    &__thumbnail {
      max-width: 30vw;
      height: auto;
    }
  }

  &__right {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
  }

  .od-upload-prescription-card {
    background: #fafafa;
    padding: 45px 30px;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.3px;
      color: #000000;
      font-family: 'Founders Grotesk', sans-serif;
      margin-bottom: 40px;
    }
    &__para {
      font-family: 'NeuzeitGroReg', sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #808080;
      margin-bottom: 35px;
    }
    &__upload {
      color: #808080;
      display: flex;
      justify-content: space-between;
      padding: 0px 34px;
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        text-decoration: none;
        font-size: 20px;
        font-family: 'Founders Grotesk', sans-serif;
        color: #000000;
        img {
          max-width: 20px;
          margin-right: 10px;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .od-upload-prescription-line {
    margin-bottom: 30px;
  }

  &__footer {
    margin-top: 100px;
    &__acceptTerms {
      font-size: 16px;
      font-family: 'NeuzeitGroReg', sans-serif;
      opacity: 0.5;
      margin-bottom: 20px;
    }
    .od-upload-prescription-d__continueBtn {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Founders Grotesk', sans-serif;
      justify-content: center;
      font-size: 16px;
    }
  }
}
