.od-coupon-items-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.od-coupon-items {
  display: flex;
  flex-direction: column;
  gap: 50px;


  &-multiple {
    color: #00000080;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    li {
      list-style-type: disc;
    }
  }
}

.od-coupon-items-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  &__title {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #000000;
  }

  &__icon {
    cursor: pointer;
  }
}

.od-coupon-items-add {
  display: flex;
  gap: 10px;

  &__input {
    display: block;
    width: 100%;
    height: 56px;
    padding: 16px 14px;
    border: 1px solid #e2e2ee;
    background-color: #ffffff;
    font-family: Raleway;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #000000;
  }

  &__error {
    font-family: Raleway;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #a94442;
  }
}

.od-coupon-tems-item {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  background-color: #ffffff;
  overflow: hidden;

  &__title {
    font-family: Raleway;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  &__description {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    text-decoration: none;
  }

  &__terms-conditions {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    text-decoration: none;
  }

  &__button {
    padding: 0;
    border: none;
    align-self: flex-start;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    text-transform: capitalize;
    text-decoration-line: underline;
    background-color: transparent;
  }

  &__divider {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
  }
}
