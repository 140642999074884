@charset "UTF-8";
.od-privacy {
  font-family: "Founders Grotesk", sans-serif;
  width: 100%;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 60px;
}

.od-privacy__title-container {
  border-bottom: 1px solid #e3e3e3;
  max-width: 768px;
  margin: 0 auto;
}

@media (min-width: 426px) and (max-width: 768px) {
  .od-privacy__title-container {
    margin: 0 35px;
  }
}

@media (max-width: 425px) {
  .od-privacy__title-container {
    width: 100%;
    padding: 0 20px;
  }
}

.od-privacy__title-container div {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
}

@media (min-width: 425px) {
  .od-privacy__title-container div {
    text-align: justify;
  }
}

@media (max-width: 425px) {
  .od-privacy__title-container div {
    text-align: left;
    margin: 0;
  }
}

.od-privacy__title-container div p {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 !important;
}

.od-privacy__title {
  margin: 60px 0 !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
}

@media (max-width: 425px) {
  .od-privacy__title {
    font-size: 18px;
    margin: 0;
  }
}

.od-privacy__content {
  font-family: "Founders Grotesk", sans-serif;
  max-width: 768px;
  margin: 60px auto 0;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .od-privacy__content {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.od-privacy__content div {
  width: 100%;
  font-size: 20px;
  line-height: 40px;
}

@media (min-width: 425px) {
  .od-privacy__content div {
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .od-privacy__content div {
    text-align: left;
    margin: 0;
  }
}

.od-privacy__content div h3 {
  font-family: "Founders Grotesk", sans-serif;
  margin: 20px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.od-privacy__content div h4 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  line-height: 30px;
}

.od-privacy__content div p {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  padding-left: 20px;
}

.od-privacy__content div a {
  color: #0094c8;
  font-family: "Founders Grotesk", sans-serif;
  text-decoration: none;
}

.od-privacy__content div a:hover {
  color: #007eaa !important;
}

.od-privacy__content div ul {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
}

.od-privacy__content div ul li {
  width: 100%;
  list-style: none;
  padding-left: 30px;
  text-indent: -15px;
  line-height: 30px;
  margin-bottom: 10px;
  font-size: 15px;
}

.od-privacy__content div ul li::marker {
  content: '';
}

.od-privacy__content div ul li::before {
  content: '・';
  font-size: 15px;
}

.od-privacy__updated {
  text-align: right;
}
