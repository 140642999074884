.l-care{
  color: #000;
  &__main{
    &-logo{
      padding: 30px 0;
      img{
        display: block;
        width: 100%;
        max-width: 160px;
        margin: 0 auto;
      }
    }
    &-content{
      background-color: #F0F0F0;
      &-heading{
        padding: 44px 10% 0;
        font-size: 21px;
        font-weight: 700 !important;
        line-height: 1.4;
        margin: 0;
      }
      &-img{
        display: block;
        max-width: 380px;
        margin-left: auto;
        margin-right: 0;
      }
    }
    &-footer{
      background-color: #06082B;
      color: #fff;
      font-weight: 700;
      font-size: 15px;
      line-height: 1.3;
      text-align: center;
      padding: 10px 20px;
      margin: 0;
    }
  }
  &__features{
    margin: 46px 0;
    padding: 0 5%;
    text-align: center;
    &-title{
      font-size: 29px;
      font-weight: 700 !important;
      line-height: 1.5;
      letter-spacing: 3px;
      text-align: center;
      display: inline-block;
      border-bottom: 4px solid #000;
      margin: 0 auto 30px;
    }
    &-list{
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 16px;
    }
    &-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      &-title{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F0F0F0;
        width: 155px;
        height: 155px;
        border-radius: 100%;
        margin: 0;
        span{
          font-size: 15px;
          font-weight: 400;
          line-height: 2;
          letter-spacing: 1px;
          img{
            display: inline-block;
            margin: 3px auto;
            width: 100%;
            max-width: 90px;
          }
        }
      }
      &-text{
        margin: 0;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 2;
      }
    }
  }
  &__terms{
    margin: 46px 0;
    padding: 0 5%;
    &-inner{
      border: 1px solid #000;
      padding: 0 7%;
      max-width: 860px;
      margin: 0 auto;
    }
    &-title{
      font-size: 18px;
      font-weight: 700 !important;
      line-height: 1.5;
      text-align: center;
      margin: calc(-27px/2) auto 13px;
      background-color: #fff;
      width: 280px;
    }
    &-text{
      font-size: 13px;
      line-height: 2;
      letter-spacing: 1px;
      margin-bottom: 2em;
    }
  }
  @media screen and (min-width: 768px) {
    &__main{
      &-logo{
        padding: 50px 0;
        img{
          max-width: 240px;
        }
      }
      &-content{
        position: relative;
        &-heading{
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          width: 80%;
          max-width: 1152px;
          margin: 0 auto;
          padding: 0;
        }
      }
      &-footer{
        font-size: 20px;
      }
    }
    &__terms{
      &-inner{
        padding: 0 3%;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    &__main{
      &-content{
        &-heading{
          font-size: 29px;
        }
        &-img{
          max-width: 560px;
        }
      }
    }
    &__features{
      margin: 70px 0;
      &-title{
        font-size: 37px;
        letter-spacing: 3px;
        margin-bottom: 70px;
      }
      &-list{
        gap: 75px;
      }
      &-item{
        &-title{
          width: 220px;
          height: 220px;
          padding: 10px;
          span{
            display: inline-block;
            font-size: 20px;
            line-height: 1.6;
            strong{
              font-family: inherit;
              font-weight: 700;
            }
            img{
              max-width: 120px;
            }
          }
        }
        &-text{
          font-size: 15px;
        }
      }
    }
    &__terms{
      margin: 90px 0 120px;
      &-title{
        font-size: 28px;
        line-height: 1.6;
        margin-top: calc(-42px/2);
      }
    }
  }
}
