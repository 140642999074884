@charset "UTF-8";
.article-details {
  padding-block: 35px 60px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.8;
  letter-spacing: 1px;
}
.article-details a[class^='link'] {
  color: #0094c8;
  text-decoration: none;
}
.article-details a[class^='link']:hover, .article-details a[class^='link']:focus {
  text-decoration: none;
  outline: none;
}
.article-details__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 840px;
}
.article-details__header p {
  font-size: 11px;
  line-height: 1;
  color: #666;
  margin-block: 0 10px;
}
.article-details__header h1 {
  font-size: 18px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.4;
  margin-block: 0 25px;
}
.article-details__header img {
  display: block;
  width: 100%;
  max-width: 1020px;
  aspect-ratio: 7/4;
  margin: 0 auto 32px;
}
.article-details__content h2 {
  font: 700 18px/1.6 "Founders Grotesk", sans-serif !important;
  letter-spacing: 2px;
  margin-block: 50px 35px;
  position: relative;
}
.article-details__content h2::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -20px;
  width: 5px;
  height: 100%;
  background-color: #000;
}
.article-details__content p {
  line-height: 1.8;
  margin-block: 0 25px;
}
.article-details__content p a {
  color: currentColor;
}
.article-details__content p.p-img-left, .article-details__content p.p-img-right {
  display: grid;
  gap: 25px;
}
.article-details__content p.p-img-small img {
  max-width: 66%;
}
.article-details__content p.p-align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.article-details__content img {
  display: block;
  width: 100%;
  margin-inline: auto;
}
.article-details__content ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.article-details__content ol li h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font: 700 16px/1.5 "Founders Grotesk", sans-serif;
  margin-block: 45px 16px;
}
.article-details__content ol li h3 span {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #b47e00;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #7d7d7d;
}
.article-details__content a:hover, .article-details__content a:focus {
  text-decoration: none;
  outline: none;
}
.article-details__content a.link-arrow-left, .article-details__content a.link-arrow-right {
  position: relative;
}
.article-details__content a.link-arrow-left::before, .article-details__content a.link-arrow-right::before {
  content: '';
  position: absolute;
  top: 55%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-style: solid;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #0094c8;
  border-right: 0;
}
.article-details__content a.link-arrow-left {
  padding-left: 15px;
}
.article-details__content a.link-arrow-left::before {
  left: 0;
}
.article-details__content a.link-arrow-right {
  padding-right: 15px;
}
.article-details__content a.link-arrow-right::before {
  right: 0;
}
.article-details__intro {
  padding: 70px 24px 24px;
  border: 1px solid #000;
  position: relative;
  margin-top: 48px;
}
.article-details__intro-grid {
  display: grid;
  gap: 24px;
}
.article-details__intro h3,
.article-details__intro h4 {
  text-align: center;
  font: 700 16px/1 "Founders Grotesk", sans-serif;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0;
  background-color: #fff;
  padding-inline: 16px;
  margin-block: 0;
}
.article-details__related {
  position: relative;
  border-top: 1px solid #e3e3e3;
  margin-block: 60px 48px;
  padding: 24px 20px 0;
}
.article-details__related-grid {
  display: grid;
  gap: 24px;
}
.article-details__related h3 {
  text-align: center;
  font: 700 16px/1 "Founders Grotesk", sans-serif;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0;
  background-color: #fff;
  padding-inline: 16px;
  white-space: nowrap;
}
.article-details__sns {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 50px;
  padding-block: 50px 40px;
}
.article-details__sns li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.article-details__sns li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.article-details__sns li a svg {
  width: 22px;
  height: 22px;
}
.article-details__sns li a svg path {
  fill: #999;
}
.article-details__footer {
  border-top: 1px solid #e3e3e3;
  padding: 20px 20px 0;
  max-width: 1060px;
  margin-inline: auto;
}
.article-details__footer a {
  color: #000;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  font-size: 15px;
  outline: none;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.article-details__footer a svg {
  width: 18px;
  height: 18px;
}
.article-details__footer a svg path {
  fill: #999;
}
.article-details__footer a:hover, .article-details__footer a:focus {
  color: #000;
  text-decoration: none;
  opacity: 0.6;
  outline: none;
}
.article-details__pins {
  background-color: #f7f7f7;
  list-style: none;
  padding: 24px 20px;
  margin-block: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}
.article-details__pins li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-left: 2em;
}
.article-details__pins li::before {
  content: '📌';
  position: absolute;
  top: 2px;
  left: 0;
  font-size: smaller;
}
.article-details__card {
  border: 1px solid #e3e3e3;
  padding: 24px;
  display: grid;
  grid-template-columns: 4fr 7fr auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
  color: #000;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.article-details__card p {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
}
.article-details__card p small {
  font-weight: normal;
  font-size: smaller;
}
.article-details__card svg {
  width: 15px;
  height: 28px;
}
.article-details__card:is(a):hover {
  color: #000;
  opacity: 0.6;
}
.article-details__offer {
  background-color: #f7f7f7;
  padding: 24px 24px 48px;
}
.article-details__offer h4 {
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 1.8;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  margin-block: 0 24px;
  padding-bottom: 8px;
}
@media (max-width: 767px) {
  .article-details__content p.p-img-single {
    margin-inline: -20px;
  }
  .article-details__intro img, .article-details__related img {
    max-width: 50%;
    margin-inline: auto;
  }
  .article-details__related {
    margin-inline: -20px;
  }
  .article-details__pins {
    margin-inline: -20px;
  }
}
@media (min-width: 768px) {
  .article-details__content p.p-img-left {
    grid-template-columns: 4fr 6fr;
  }
  .article-details__content p.p-img-right {
    grid-template-columns: 6fr 4fr;
  }
  .article-details__content .button-group {
    max-width: 460px;
    margin-inline: auto;
  }
  .article-details__content .button-group--2 {
    max-width: 676px;
  }
  .article-details__intro {
    padding: 70px 48px 48px;
    margin-top: 48px;
  }
  .article-details__intro-grid {
    grid-template-columns: 4fr 6fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 48px;
  }
  .article-details__related {
    padding: 70px 48px 48px;
    margin-top: 96px;
  }
  .article-details__related-grid {
    grid-template-columns: 4fr 6fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 48px;
  }
  .article-details__card {
    padding: 40px;
  }
}
@media (min-width: 1024px) {
  .article-details {
    padding-block: 50px 120px;
    font-size: 16px;
    line-height: 2;
  }
  .article-details__header p {
    font-size: 14px;
  }
  .article-details__header h1 {
    font-size: 26px;
    margin-bottom: 40px;
  }
  .article-details__content h2 {
    font-size: 26px !important;
    margin-block: 70px 35px;
  }
  .article-details__content p.p-img-small img {
    max-width: 42%;
  }
  .article-details__content ol li h3 {
    font-size: 20px;
  }
  .article-details__intro h3,
  .article-details__intro h4 {
    font-size: 20px;
  }
  .article-details__related h3 {
    font-size: 20px;
  }
  .article-details__sns {
    padding-block: 80px 50px;
  }
  .article-details__pins {
    padding: 32px 40px;
  }
  .article-details__card {
    padding: 48px;
  }
  .article-details__offer h4 {
    font-size: 15px;
  }
}
@media (min-width: 1440px) {
  .article-details__footer {
    padding-inline: 0;
  }
}
