.od-cart-bill-details {
  width: 387px;
  margin-bottom: 24px;
}

.od-cart-bill-details__title {
  color: #444;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.48px;
  margin-bottom: 24px;
}

.od-cart-bill-details__rows {
  display: grid;
  padding: 16px 16px 0 16px;
  grid-template-columns: auto -webkit-min-content;
  grid-template-columns: auto min-content;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 8px;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
  white-space: nowrap;
  background: #fff;
}

.od-cart-bill-details__item {
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.od-cart-bill-details__item-price {
  color: #444;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.od-cart-bill-details__item-price--free {
  color: #4D9855;
}

.od-cart-bill-details__total-wrapper {
  padding-bottom: 16px;
}

.od-cart-bill-details__total {
  color: #444;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
}

.od-cart-bill-details__total-price {
  text-align: right;
  color: #444;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
}

.od-cart-bill-details__button-proceed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid #546073;
  border-radius: 50px;
  margin-top: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 21px;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  background-color: #546073;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.od-cart-bill-details__unlocked-discount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  padding: 8px 16px;
  margin-top: 17px;
  color: #4D9855;
  font-size: 16px;
}

.od-cart-bill-details__unlocked-discount img {
  margin-top: -4px;
}

.od-cart-bill-details__unlocked-discount--exclude {
  color: #000;
  margin-top: 0px;
}

.od-cart-bill-details__button-icon {
  font-size: 24px;
}

.od-cart-bill-details__coupon-gift-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 17px;
  gap: 16px;
}

.od-cart-bill-details__features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  margin-top: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  gap: 26px;
}

.od-cart-bill-details__delivery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 24px 0;
}

.od-cart-bill-details__delivery__title {
  color: #444;
  font-size: 24px;
  letter-spacing: -0.48px;
}

.od-cart-bill-details__delivery__change {
  color: #546073;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.32px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.od-cart-bill-details__feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-cart-bill-details__image {
  max-width: 24px;
  max-height: 24px;
}

.od-cart-bill-details__feature-title {
  margin-top: 8px;
  text-align: center;
  color: #444;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
}

.od-cart-bill-details-membership {
  padding: 16px;
  background-color: #fff;
}

.od-cart-bill-details-membership__checkbox-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  cursor: pointer;
}

.od-cart-bill-details-membership__checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-cart-bill-details-membership__checkbox::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #546073;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}

.od-cart-bill-details-membership__checkbox--checked {
  background: url(/assets/svg/od-checkbox-grey-icon.svg);
}

.od-cart-bill-details-membership__checkbox--checked::after {
  display: none;
}

.od-cart-bill-details-membership__checkbox-label {
  color: #546073;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.od-cart-bill-details-membership__discount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
}
