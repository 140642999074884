$font-base: yu-mincho-pr6n, sans-serif;

.header-senichisaku {
  &__main {
    text-align: center;

    picture {
      img {
        display: block;
        width: 100%;
      }
    }

    .base-senichisaku__container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: 0;
      line-height: 1;
      margin-bottom: 25px;

      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 167px;
        margin: 0 auto 7px
      }
    }

    &__text {
      font-size: 17px;
      line-height: 1.65;
      margin: 0;
    }

    @media (min-width: 1024px) {
      &__title{
        margin-bottom: 50px;
        
        img{
          max-width: 340px;
          margin-bottom: 6px;
        }
      }

      &__text{
        font-size: 28px;
      }
    }
  }
}
