/*
 *  Define scss variables here.
*/

$base-font-size: 12px;
$base-line-height: 1.2857;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-roboto-slab: 'Roboto slab', sans-serif;
$base-font-family: $font-family-roboto, 'Arial', 'Helvetica', sans-serif;
$heavy-font-family: $font-family-roboto-slab, 'Arial', 'Helvetica', sans-serif;
$base-text-interval: ($base-font-size * $base-line-height);

$colorpallete: (
  orange: #fd6b0b,
  dull_orange: #f18132,
  topaz: #18cfa8,
  topaz_light: #97ddce,
  dark_topaz: #358d84,
  apple_green: #7ed321,
  dark_grey_blue: #27394e,
  ice: #d6fff6,
  cool_grey: #99a0a9,
  pale_grey: #eaeff4,
  bluey_grey: #8e9bad,
  light_tan: #f8edb6,
  aqua: #0ee4b5,
  color_white: #ffffff,
  color_off_white: #fafbfc,
  color_black: #000000,
  /* Newly Introduced */ color_blue: #3b5998,
  cool_dark_grey: #757575,
  color_green: #61ab44,
  color_red: #ff0000,
  color_dark_green: #349f95,
  color_link_green: #0ecda4,
  color_link_blue: #00bac6,
  color_dark_grey: #424242,
  color_light_grey: #616161,
  color_brick_red: #d54c44,
  color_light_pink: #ef4e74,
  color_dark_pink: #ed0972,
  color_grey_black: #333333,
  color_dull_black: #666666,
  color_pale_black: #999999,
  color_cc: #ccc,
  color_ee: #eeeeee,
  color_dd: #dddddd,
  color_grey: #676767,
  color_white_smoke: #f5f5f5,
  color_white_light: #fafafa,
  color_snow: #fbfbfb,
  color_light_orange: #ffedd0,
  color_bright_yellow: #faa61a,
  color_light_yellow: #ffda6e,
  color_dark_yellow: #ffb400,
  color_lighter_yellow: #fff8e6,
  color_3c: #3c3c3c,
  color_light_3c: #96938a,
  color_dark_blue: #000042,
  color_blue_magenta: #737397,
  color_deep_koamaru: #333368,
  color_grass_green: #489b1c,
  color_amethyst: #66668e,
);

$maxGridCols: 5;
$maxGridRows: 5;
$maxSpacingVar: 12;
$oddSpacingVar: 4;

$app-nav-background: map-get($colorpallete, dark_grey_blue);
$badge-background: #546073;
$md-radio-checked-color: map-get($colorpallete, topaz);
$md-radio-border-color: map-get($colorpallete, color_pale_black);
$md-radio-size: 12px;
$md-radio-checked-size: 10px;
