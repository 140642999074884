.od-gender-menu {

  .menu-line-section {
    position: relative;
  }

  .nav-head {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    display: block;
    margin-bottom: 40px;
  }

  .nav-head-category {
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    display: block;
    margin: -40px 0 40px 0;
    padding-left: 20px;
  }

  .nav-head-category-submenu {
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    display: block;
    margin-bottom: 40px;
  }

  .od-gender {

    &__info,
    &__category-menu_list {
      display: flex;
      align-items: center;
      padding: 10px 25px 10px 20px;
      cursor: pointer;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      max-width: 250px;

      &:hover {
        background: #FAFAFA;
      }

      span {
        width: 50%;
        margin-left: 20px;
        display: inline-block;
      }
      .fa-angle-right {
        padding-left: 30px;
        color: #000042;
      }

      &__img {
        width: 50px;
        height: 50px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:hover {
      .od-gender__category {
        display: block;
        position: absolute;
        left: 100%;
        top: -20px;
        width: 100%;
      }
    }
  }

  .od-gender-menu-section {
    > .od-gender {
      &.active {
        .od-gender__info {
          background-color: #FAFAFA;
          + .od-gender__category {
            position: absolute;
            left: 100%;
            width: 250px;
            display: block;
            background-color: #fff;
            top: -20px;

            &.submenu {
              width: auto;
              min-height: 425px;
            }

            .category-menu {
              .category-menu_data {

                &.active {
                  background-color: #FAFAFA;
                  border-radius: 5px;
                  .select-submenu {
                    display: block;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    min-height: 400px;
                    .nav-subcategory-level {
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .category-condition {
    background-color: #FAFAFA;
  }
}
