.od-apply-coupon {
	position: relative;
	overflow: hidden;

	&__button {
		display: flex;
		width: 100%;
		padding: 12px 20px;
		border-radius: 60px;
		justify-content: center;
		align-items: center;
		gap: 16px;
		color: #546073;
		font-size: 16px;
		line-height: 16px;
		letter-spacing: -0.32px;
		white-space: nowrap;
		background: #E9EEF6;
		cursor: pointer;

		&--unauthorized {
			background-color: #F0F0F0;
			opacity: 0.7;
		}
	}

	&__backdrop {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #000;
		opacity: 0.5;
		-webkit-transition: opacity 0.15s linear;
		-o-transition: opacity 0.15s linear;
		transition: opacity 0.15s linear;
		z-index: 999;
	}

	&__sidebar--m {
		display: block;
		width: 496px;
		max-width: 100vw;
		height: 100vh;
		padding: 50px;
		margin: 0 auto;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1200 !important;
		overflow-x: hidden;
		background-color: #FFF;
		animation: animateleft 0.5s;
	}

	&__sidebar--d {
		display: block;
		width: 534px;
		max-width: 100vw;
		height: 100vh;
		padding: 50px 100px;
		margin: 0 auto;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1200 !important;
		overflow-x: hidden;
		background-color: #FFF;
		animation: animateleft 0.5s;
	}
}

@keyframes animateleft {
	from {
		transform: translate3d(30%, 0, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}
