.body-senichisaku__bs {
  padding-top: 13px;
  padding-bottom: 130px;
}

.body-senichisaku__bs__title {
  font-size: 24px;
  letter-spacing: 0.15em;
  margin-bottom: 50px;
  line-height: 1.6;
}

.body-senichisaku__bs__title small {
  color: white;
  display: block;
  font-size: 11px;
  letter-spacing: 0.08em;
}

.body-senichisaku__bs__img--glasses {
  margin-bottom: 70px;
}

.body-senichisaku__bs__img--town {
  position: relative;
  margin-bottom: 60px;
}

.body-senichisaku__bs__img--frame {
  max-width: 1050px;
  margin-top: 130px;
  margin-left: auto;
  margin-right: -5.5%;
}

.body-senichisaku__bs__subtitle {
  font-size: 24px;
  margin-bottom: 50px;
  line-height: 1.6;
}

.body-senichisaku__bs__text {
  margin-bottom: 70px;
}

.body-senichisaku__bs__slider {
  margin-bottom: 70px;
}

.body-senichisaku__bs__slider-item {
  padding-left: 1px;
  padding-right: 1px;
}

.body-senichisaku__bs__slider-item img {
  width: 100%;
}

.body-senichisaku__bs .base-senichisaku__btn-group {
  margin-bottom: 130px;
}

@media (max-width: 767px) {
  .body-senichisaku__bs__img--town {
    margin-left: -5.5%;
    margin-right: -5.5%;
  }
}

@media (min-width: 768px) {
  .body-senichisaku__bs__title, .body-senichisaku__bs__subtitle {
    line-height: 1.9;
  }
}

@media (min-width: 1024px) {
  .body-senichisaku__bs {
    padding-top: 80px;
    padding-bottom: 200px;
  }
  .body-senichisaku__bs__title {
    font-style: 32px;
    margin-bottom: 80px;
  }
  .body-senichisaku__bs__title small {
    font-size: 13px;
  }
  .body-senichisaku__bs__subtitle {
    font-size: 32px;
    margin-bottom: 100px;
  }
  .body-senichisaku__bs__text {
    margin-bottom: 130px;
  }
  .body-senichisaku__bs__img--glasses {
    margin-bottom: 130px;
  }
  .body-senichisaku__bs__img--town {
    margin-bottom: 100px;
  }
  .body-senichisaku__bs__img--frame {
    margin-top: 200px;
  }
  .body-senichisaku__bs__slider {
    margin-bottom: 130px;
  }
  .body-senichisaku__bs__slider-item {
    padding-left: 4px;
    padding-right: 4px;
  }
  .body-senichisaku__bs .base-senichisaku__btn-group {
    margin-bottom: 200px;
  }
}

.body-senichisaku__craftsmen {
  padding-bottom: 80px;
}

.body-senichisaku__craftsmen__top {
  position: relative;
}

.body-senichisaku__craftsmen__title {
  font-size: 24px;
  margin-bottom: 50px;
  line-height: 1.6;
}

.body-senichisaku__craftsmen__text {
  margin-bottom: 70px;
}

.body-senichisaku__craftsmen__block {
  margin-bottom: 50px;
}

.body-senichisaku__craftsmen__block-title {
  font-size: 18px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 54px;
  line-height: 1.6;
}

.body-senichisaku__craftsmen__block-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid white;
  width: 40px;
}

.body-senichisaku__craftsmen__block--odd .body-senichisaku__craftsmen__block-img {
  margin-left: -5.5%;
}

.body-senichisaku__craftsmen__block--even .body-senichisaku__craftsmen__block-img {
  margin-right: -5.5%;
}

@media (max-width: 1023px) {
  .body-senichisaku__craftsmen__top > .base-senichisaku__container {
    margin-top: -200px;
  }
  .body-senichisaku__craftsmen__block-img {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .body-senichisaku__craftsmen__title, .body-senichisaku__craftsmen__block-title {
    line-height: 1.9;
  }
}

@media (min-width: 1024px) {
  .body-senichisaku__craftsmen {
    padding-bottom: 100px;
  }
  .body-senichisaku__craftsmen__top > .base-senichisaku__container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .body-senichisaku__craftsmen__title {
    font-size: 32px;
    margin-bottom: 100px;
  }
  .body-senichisaku__craftsmen__text {
    margin-bottom: 140px;
  }
  .body-senichisaku__craftsmen__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1173px;
    margin-bottom: 100px;
  }
  .body-senichisaku__craftsmen__block-details {
    padding-top: 90px;
  }
  .body-senichisaku__craftsmen__block-img {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 50%;
    max-width: 633px;
  }
  .body-senichisaku__craftsmen__block-title {
    font-size: 28px;
    margin-bottom: 40px;
  }
  .body-senichisaku__craftsmen__block-text {
    font-size: 16px;
  }
  .body-senichisaku__craftsmen__block--odd {
    margin-right: auto;
  }
  .body-senichisaku__craftsmen__block--odd .body-senichisaku__craftsmen__block-details {
    padding-left: 80px;
  }
  .body-senichisaku__craftsmen__block--even {
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .body-senichisaku__craftsmen__block--even .body-senichisaku__craftsmen__block-details {
    padding-right: 80px;
    padding-left: 12px;
  }
}

.body-senichisaku__process {
  padding-bottom: 80px;
}

.body-senichisaku__process picture {
  display: block;
}

.body-senichisaku__process picture img {
  width: 100%;
  height: auto;
}

.body-senichisaku__process__cover {
  margin-bottom: 70px;
}

.body-senichisaku__process__title {
  font-size: 24px;
  margin-bottom: 50px;
  line-height: 1.6;
}

.body-senichisaku__process__block {
  margin-bottom: 50px;
}

.body-senichisaku__process__block-title {
  font: 300 13px "HiraginoMinchoPro", sans-serif;
  letter-spacing: 0.015em;
  margin-bottom: 10px;
}

.body-senichisaku__process__block-text {
  font: 300 11px "HiraginoMinchoPro", sans-serif;
  line-height: 1.9;
  margin-bottom: 20px;
}

.body-senichisaku__process__block-media {
  pointer-events: none;
}

@media (max-width: 1023px) {
  .body-senichisaku__process__block-media {
    margin-bottom: 25px;
  }
  .body-senichisaku__process__block--sm, .body-senichisaku__process__block--sm2 {
    padding-left: 28px;
    padding-right: 28px;
  }
  .body-senichisaku__process__block--lg .body-senichisaku__process__block-media {
    margin-left: -5.5%;
    margin-right: -5.5%;
  }
}

@media (min-width: 1024px) {
  .body-senichisaku__process {
    padding-bottom: 140px;
  }
  .body-senichisaku__process__cover {
    margin-bottom: 130px;
  }
  .body-senichisaku__process__title {
    font-size: 32px;
    margin-bottom: 100px;
  }
  .body-senichisaku__process__block {
    margin-bottom: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .body-senichisaku__process__block-title {
    font-size: 20px;
  }
  .body-senichisaku__process__block-text {
    font-size: 13px;
    line-height: 2.2;
  }
  .body-senichisaku__process__block--odd .body-senichisaku__process__block-details {
    padding-left: 67px;
    padding-right: 80px;
    max-width: 69%;
  }
  .body-senichisaku__process__block--even {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .body-senichisaku__process__block--even .body-senichisaku__process__block-details {
    padding-right: 67px;
  }
  .body-senichisaku__process__block--sm .body-senichisaku__process__block-media {
    max-width: 817px;
  }
  .body-senichisaku__process__block--sm2 .body-senichisaku__process__block-media {
    max-width: 364px;
  }
  .body-senichisaku__process__block--sm2 .body-senichisaku__process__block-details {
    max-width: 53%;
  }
  .body-senichisaku__process__block--md .body-senichisaku__process__block-media {
    max-width: 492px;
  }
  .body-senichisaku__process__block--md .body-senichisaku__process__block-details {
    padding-left: 45px;
    max-width: 57.3%;
  }
}
