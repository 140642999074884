.odslv-modal-pt {
  &__heading {
    font-family: 'Founders Grotesk', sans-serif;
    color: #444;
    font-size: 24px;
    letter-spacing: -0.48px;
    margin: 8px 0 40px 0;

    @media (max-width: 768px) {
      margin: 8px 0 32px 0;
    }
  }

  &__status-prescription {
    color: #444;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    opacity: 0.5;
    margin-bottom: 24px;
    margin-top: 32px;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &__radio {
    background-color: #fff;
    &--content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: #FAFAFA;
      padding: 24px 16px;
    }
    img {
      height: 18px;
    }
    span {
      font-family: 'NeuzeitGroReg', sans-serif;
      color: #444;
      font-size: 16px;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__radio-selected {
    background-color: #eee;
    border-radius: 10px;
  }
}