$font-fredoka: "Fredoka", sans-serif;

$yellow-fff: #fff9b1;
$brown-76: #76332e;

.footer-pompompurin {
  &__pompompurin {
    padding-bottom: 220px;
    background-color: white;

    &__buttons {
      padding: 76px 0 90px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .base-pompompurin__btn {
        max-width: 380px;
      }
    }

    &__title {
      margin-top: 0;
      margin-bottom: 21px;
      text-align: center;

      img {
        aspect-ratio: 313/33;
        max-width: 230px;
      }
    }

    &__img {
      aspect-ratio: 121/118;
    }

    &__details {
      p {
        font-size: 14px;
        font-weight: 800;
        line-height: 30px;
        letter-spacing: 0.28px;
        margin-bottom: 40px;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr;
    }

    @media (max-width: 767px) {
      padding-inline: 20px;

      &__title {
        img {
          margin-inline: auto;
        }
      }

      &__img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 28px;
      }
    }

    @media (min-width: 768px) {
      &__title {
        text-align: left;
      }
      
      &__buttons {
        padding: 90px 0 140px 0;
  
        .base-pompompurin__btn {
          margin: 0;
          width: 310px;

          &:nth-child(2) {
            width: 380px;
          }
        }
      }

      &__grid {
        display: grid;
        grid-template-columns: calc(363px / 1.5) 1fr;
        column-gap: calc(75px / 2);
      }

      &__img {
        grid-row: 1 / span 2;
        grid-column: 1;
      }

      &__details {
        .base-pompompurin__btn {
          margin-left: 0;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-bottom: 308px;

      &__buttons {
        flex-direction: row;
        justify-content: center;
        gap: 50px;

        .base-pompompurin__btn {
          margin: 0;
          
          &:nth-child(1) {
            width: 300px;
          }

          &:nth-child(2) {
            width: 380px;
          }
        }
      }

      &__grid {
        grid-template-columns: 363px 1fr;
        column-gap: 75px;
      }

      &__title {
        margin-bottom: 24px;
        img {
          max-width: 313px;
        }
      }

      &__details {
        p {
          font-size: 16px;
          line-height: 35px;
          letter-spacing: 0.32px;
        }
      }
    }

    @media (min-width: 1445px) {
      margin-bottom: -20px;
    }
  }

  &__sns {
    background-color: $brown-76;
    color: white;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 25px;
      background-color: $yellow-fff;
    }

    .base-pompompurin__container {
      position: relative;
      padding-bottom: 155px;
    }

    &__fg {
      position: absolute;

      &--cloud {
        width: 100%;
        aspect-ratio: 1513/250;
        top: -150px;
        height: 186px;
        object-fit: cover;
        object-position: 63% top;
      }

      &--char {
        aspect-ratio: 32/17;
        max-width: 213px;
        right: 11px;
        bottom: 17px;
      }
    }

    &__title {
      text-align: center;
      font-family: $font-fredoka !important;
      font-weight: 700 !important;
      font-size: 32px;
      line-height: 1.25;
      letter-spacing: 2.56px;
      margin-bottom: 25px;
    }

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin-bottom: 45px;
      list-style-type: none;
      padding: 0;

      li {
        a {
          font-size: 38px;
          color: white;

          i {
            background: transparent;
          }
        }
      }
    }

    &__copyright {
      font-size: 11px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      margin: 0;
    }

    @media (min-width: 1024px) {
      &::before {
        height: 54px;
      }

      .base-pompompurin__container {
        padding-bottom: 74px;
      }

      &__fg {
        &--cloud {
          top: -223px;
          height: 245px;
          object-position: center top;
        }
        &--char {
          max-width: 320px;
          right: -96px;
          bottom: 44px;
        }
      }

      &__title {
        font-size: 60px;
        letter-spacing: 3px;
        margin-bottom: 47px;
      }

      &__list {
        gap: 50px;
        margin-bottom: 75px;
        li {
          a {
            font-size: 48px;
          }
        }
      }

      &__copyright {
        font-size: 12px;
      }
    }
  }
}
