.slider-owndays-pc .slick-list {
  padding: 0 50% !important;
}

.slider-owndays-pc .slick-slide {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0 5px;
}

.slider-owndays-pc .slick-slide div li {
  max-width: 580px;
  margin: 0 15px;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.slider-owndays-pc .slick-slide div li img {
  display: block;
  width: 100%;
  height: auto;
}

.slider-owndays-pc__btn-prev, .slider-owndays-pc__btn-next {
  display: none;
}

@media (min-width: 1024px) {
  .slider-owndays-pc {
    position: relative;
    padding: 0 12%;
  }
  .slider-owndays-pc__btn-prev, .slider-owndays-pc__btn-next {
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .slider-owndays-pc__btn-prev {
    left: 4%;
  }
  .slider-owndays-pc__btn-next {
    right: 4%;
  }
}

@media (min-width: 1296px) {
  .slider-owndays-pc .slick-list {
    padding: 0 !important;
  }
}
