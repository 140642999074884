.power-status-message {
  background-color: #eee;
  width: 95%;
}

.power-status-message .status-icon {
  width: 30px;
  height: 30px;
}

.power-status-message .status-icon .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.power-status-message.desktop {
  width: calc(100% - 30px);
}

.power-status-message__view-details {
  color: #444444;
}

.width_100 {
  width: 100%;
}

.padding_0_8 {
  padding: 0px 8px;
}

.new-style-details {
  color: #66668E;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-family: 'LenskartSans-Regular';
  text-transform: capitalize;
}

.cartView-msg-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.cartView-msg-container .cartView-primary-msg {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000042;
}

.cartView-msg-container .cartView-primary-msg .cartView-basicMsg {
  font-family: 'LenskartSans-Regular';
  font-weight: 400;
}

.cartView-msg-container .cartView-primary-msg .cartView-moreInfo {
  font-family: 'LenskartSans-Bold';
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}

.cartView-msg-container .cartView-secondary-msg {
  font-family: 'LenskartSans-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #333368;
}
