.od-product-preview {
  width: 50%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    &__img {
      transform: scale(1.2);
  }
  &__description {
    color: #000;
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    opacity: 0.5;
    margin-top: -20px;

    hr {
      display: none;
    }
  }
  }
}
