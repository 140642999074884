$font-founders: 'Founders Grotesk', sans-serif;

.body-news {
  margin-top: 84px;
  width: 100%;
  height: auto;
  font-family: $font-founders;

  @media (max-width: 767px) {
    margin-top: 30px;
  }

  a {
    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }
  }

  &__news {
    margin: 0 auto;
    opacity: 0;
    transform: translate(0, 16px);
    transition: opacity .9s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform .8s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: auto;
    max-width: 1152px;
    padding: 0 60px;

    @media (max-width: 767px) {
      width: 100%;
    }

    figure {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      overflow: hidden;
      margin: 0;

      @media (max-width: 767px) {
        display: block;
        overflow: visible;
      }

      figcaption {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 460px);
        padding: 20px 5%;
        text-align: center;

        @media (max-width: 1023px) {
          width: calc(100% - 320px);
          padding: 45px 5%;
        }

        @media (max-width: 767px) {
          display: block;
          position: relative;
          z-index: 2;
          width: calc(100% - 32px);
          margin: -48px auto 0;
          padding: 42px 5%;
          background-color: #fff;
          border: 0;
          box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
        }
      }
    }

    &-thumbnail {
      width: 460px;
      font-size: 0;
      line-height: 0;
      letter-spacing: 0;

      @media (max-width: 1023px) {
        width: 320px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__card {
      width: 100%;
      height: 100%;
      transition: box-shadow .5s cubic-bezier(0.645, 0.045, 0.355, 1),
      transform .4s cubic-bezier(0.645, 0.045, 0.355, 1);

      @media (min-width: 768px) {
        &:hover {
          color: #000;
          opacity: 1;
          box-shadow: 0 0 30px 5px rgba(0, 0, 0, .1);
          transform: scale(1.01);

          .body-news__news-thumbnail {
            opacity: 0.6;
          }
        }
      }
    }

    &--active {
      opacity: 1;
      transform: translate(0, 0);
    }

    &-details{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-text{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-latest {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 162px;
      font-size: 0;
      line-height: 0;
      letter-spacing: 0;

      @media (max-width: 1023px) {
        top: 5%;
        left: 5%;
        width: 81px;
      }

      @media (max-width: 767px) {
        top: 10%;
        left: 8%;
      }

      img {
        width: 100%;
      }
    }

    &-category {
      margin: 0 0 16px;
      font-size: 14px;
      letter-spacing: 3px;
      text-indent: 3px;
      color: #000;
    }

    &-title {
      margin: 0 0 16px;
      font-size: 26px;
      letter-spacing: 1px;
      color: #000;

      @media (max-width: 1023px) {
        font-size: 20px;
      }
    }

    &-description {
      line-height: 1.5;
      margin-bottom: 16px;
      color: #000;
      letter-spacing: 1px;

      @media (max-width: 767px) {
        margin: 0 0 16px;

        br {
          display: none;
        }
      }
    }

    &-link {
      width: 100%;
      margin-top: auto;
      color: #0094c8;
      letter-spacing: 1px;

      @media (max-width: 767px) {
        position: static;
      }
    }
  }

  &__l-content {
    margin: 78px auto 120px;
    display: block;
    overflow: hidden;
    position: relative;
  
    @media (max-width: 1023px) {
      margin: 0 auto 60px;
    }
  
    &--inner {
      @media (max-width: 767px) {
        &--full-sp {
          padding: 0;
        }
      }
    }
  }  
}
