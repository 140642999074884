.body-huawei-2-titanium__lineup {
  padding: 70px 0 91.75px 0;
  background-color: #F7F7F7;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup {
    padding: 120px 0;
  }
}

.body-huawei-2-titanium__lineup__title {
  text-align: center;
  font-family: "BIZ UDPGothic", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 51.2px;
  letter-spacing: 0.32px;
  color: black;
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__title {
    font-size: 66px;
    line-height: 105.6px;
    letter-spacing: 0.66px;
  }
}

.body-huawei-2-titanium__lineup__title-img, .body-huawei-2-titanium__lineup__title-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  z-index: 1;
}

.body-huawei-2-titanium__lineup__title-img::before, .body-huawei-2-titanium__lineup__title-text::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  z-index: -1;
}

.body-huawei-2-titanium__lineup__title-img span, .body-huawei-2-titanium__lineup__title-text span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-inline: 25px;
  background-color: #F7F7F7;
}

.body-huawei-2-titanium__lineup__title-img {
  font: 0/0 a;
  margin: 45px 0 0 0;
}

.body-huawei-2-titanium__lineup__title-img span img {
  width: 132px;
  height: auto;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__title-img {
    margin: 60px 0 0 0;
  }
  .body-huawei-2-titanium__lineup__title-img span img {
    width: 164.408px;
  }
}

.body-huawei-2-titanium__lineup__title-text {
  color: black;
  text-align: center;
  font-family: "BIZ UDPGothic", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 19.2px;
  letter-spacing: 0.12px;
  margin: 60px 0 0 0;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__title-text {
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 22.4px;
    letter-spacing: 0.14px;
    margin: 100px 0 0 0;
  }
}

.body-huawei-2-titanium__lineup__section {
  margin-top: 60px;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__section {
    margin-top: 100px;
  }
}

.body-huawei-2-titanium__lineup__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 15px;
}

.body-huawei-2-titanium__lineup__desc h3 {
  color: #F7F7F7;
  text-shadow: 1px 0px 0px black, -1px 0px 0px black, 0px 1px 0 black, 0px -1px 0 black;
  font-family: "Advent Pro", sans-serif;
  font-size: 48px;
  font-weight: 700 !important;
  line-height: 67.2px;
  margin-bottom: 0;
}

.body-huawei-2-titanium__lineup__desc p {
  font-family: "BIZ UDPGothic", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 28px;
  letter-spacing: 0.14px;
  margin-bottom: 0;
  color: #535353;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__desc {
    width: 100%;
    height: auto;
    max-width: 900px;
    margin: 0 auto;
  }
  .body-huawei-2-titanium__lineup__desc h3 {
    font-size: 80px;
    line-height: 112px;
  }
  .body-huawei-2-titanium__lineup__desc p {
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 32px;
    letter-spacing: 0.16px;
  }
}

.body-huawei-2-titanium__lineup__product-container {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-container {
    display: grid;
    grid-template-columns: calc(100% - 199px) 199px;
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__product-container {
    grid-template-columns: calc(100% - 258px) 258px;
    margin-top: 40px;
    -webkit-column-gap: 30px;
       -moz-column-gap: 30px;
            column-gap: 30px;
  }
}

@media (min-width: 1296px) {
  .body-huawei-2-titanium__lineup__product-container {
    grid-template-columns: calc(100% - 280.28px) 280.28px;
    -webkit-column-gap: 50px;
       -moz-column-gap: 50px;
            column-gap: 50px;
  }
}

@media (min-width: 1440px) {
  .body-huawei-2-titanium__lineup__product-container {
    grid-template-columns: calc(100% - 310px) 310px;
  }
}

.body-huawei-2-titanium__lineup__product-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 18.32px;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-layout {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 18px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.body-huawei-2-titanium__lineup__product-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
}

.body-huawei-2-titanium__lineup__product-main p {
  margin-bottom: 0;
  color: black;
  font-style: normal;
  font-weight: 400 !important;
}

.body-huawei-2-titanium__lineup__product-main p:nth-child(1) {
  font-family: "BIZ UDPGothic", serif;
  font-size: 12px;
  line-height: 21.6px;
}

.body-huawei-2-titanium__lineup__product-main p:nth-child(2) {
  font-family: "SFCompactDisplay", sans-serif;
  font-size: 13px;
  line-height: 23.4px;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 15px;
  }
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__product-main p {
    font-weight: 700 !important;
  }
  .body-huawei-2-titanium__lineup__product-main p:nth-child(1) {
    font-size: 14px;
    line-height: 25.2px;
    letter-spacing: 0.14px;
  }
  .body-huawei-2-titanium__lineup__product-main p:nth-child(2) {
    font-family: "BIZ UDPGothic", serif;
    font-size: 16px;
    line-height: 28.8px;
    letter-spacing: 0.16px;
  }
}

.body-huawei-2-titanium__lineup__product-tag {
  width: 57px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  color: #b47e00;
  font-size: 14px;
  line-height: 22.4px;
  font-family: "BIZ UDPGothic", serif;
  font-weight: 700 !important;
  letter-spacing: 0.28px;
  border: 1px solid #b47e00;
  margin-bottom: 0;
}

.body-huawei-2-titanium__lineup__product-number {
  color: #7C7C7C;
  font-family: "SFCompactDisplay", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 36px;
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__product-number {
    font-size: 26px;
    font-weight: 700 !important;
    line-height: 46.8px;
    letter-spacing: 0.26px;
  }
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-color-and-arrows {
    width: 100%;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 10px;
  }
}

.body-huawei-2-titanium__lineup__product-colors {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.body-huawei-2-titanium__lineup__product-colors li {
  width: 38px;
  height: 38px;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__product-colors li {
    width: 44px;
    height: 44px;
  }
}

.body-huawei-2-titanium__lineup__product-colors li button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-clip: content-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  -o-border-image: none;
     border-image: none;
  -o-border-image: initial;
     border-image: initial;
  outline: none;
  border-radius: 10rem;
  padding: 6px;
}

.body-huawei-2-titanium__lineup__product-colors li button[data-color="dark-gun"], .body-huawei-2-titanium__lineup__product-colors li button[data-color="black"] {
  background-color: black;
}

.body-huawei-2-titanium__lineup__product-colors li button[data-color="silver"], .body-huawei-2-titanium__lineup__product-colors li button[data-color="clear-grey"] {
  background-color: #999999;
}

.body-huawei-2-titanium__lineup__product-colors li button[data-color="clear-brown"] {
  background-color: #8B6554;
}

.body-huawei-2-titanium__lineup__product-colors li button[data-color-selected="true"] {
  border-color: #999999;
}

.body-huawei-2-titanium__lineup__product-btn {
  margin-top: 25.5px;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-btn {
    margin-top: 40px;
  }
}

.body-huawei-2-titanium__lineup__product-snap__layout {
  width: calc(100% + 40px);
  margin: 60px -23px 0 -23px;
  height: auto;
  background-color: #F2F2F2;
  padding: 0 23px;
  position: relative;
}

.body-huawei-2-titanium__lineup__product-snap__layout::before {
  position: absolute;
  content: '';
  width: 73px;
  height: 73px;
  top: -36.5px;
  right: 37px;
  background: url("https://static.lenskart.com/owndays/img/huawei2-titanium/plus-icon.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.body-huawei-2-titanium__lineup__product-snap__layout--sp {
  display: block;
}

.body-huawei-2-titanium__lineup__product-snap__layout--pc {
  display: none;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-snap__layout--sp {
    display: none;
  }
  .body-huawei-2-titanium__lineup__product-snap__layout--pc {
    display: block;
    grid-column: span 2;
    width: 100%;
    max-width: 800px;
    height: auto;
    margin: 56px auto 0 auto;
    padding: 0 14px 0 40px;
  }
}

.body-huawei-2-titanium__lineup__product-snap__content {
  display: grid;
  grid-template-columns: 130px 1fr;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 36px 0 49px 20px;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-snap__content {
    padding: 35px 0 20px 0;
    grid-template-columns: 213px 1fr;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
}

.body-huawei-2-titanium__lineup__product-snap__main p {
  margin-bottom: 0;
  white-space: nowrap;
}

.body-huawei-2-titanium__lineup__product-snap__main-lens {
  color: #AD0824;
  font-family: "BIZ UDPGothic", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 19.8px;
}

.body-huawei-2-titanium__lineup__product-snap__main-name {
  color: #7C7C7C;
  font-family: "SFCompactDisplay", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 25.2px;
}

.body-huawei-2-titanium__lineup__product-snap__main-price {
  color: black;
  font-family: "SFCompactDisplay", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 25.2px;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-snap__main p:nth-child(1) {
    white-space: unset;
  }
  .body-huawei-2-titanium__lineup__product-snap__main-lens {
    font-size: 14px;
    line-height: 25.2px;
    letter-spacing: 0.14px;
    margin-top: 12px;
  }
  .body-huawei-2-titanium__lineup__product-snap__main-name, .body-huawei-2-titanium__lineup__product-snap__main-price {
    font-size: 16px;
    line-height: 28.8px;
  }
}

.body-huawei-2-titanium__lineup__product-snap__desc {
  margin-bottom: 0;
}

.body-huawei-2-titanium__lineup__product-snap__desc--sp {
  display: block;
  grid-column: span 2;
  margin-top: 14px;
  color: #535353;
  font-family: "BIZ UDPGothic", serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 23.4px;
}

.body-huawei-2-titanium__lineup__product-snap__desc--pc {
  display: none;
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__product-snap__desc--sp {
    display: none;
  }
  .body-huawei-2-titanium__lineup__product-snap__desc--pc {
    display: block;
    color: #535353;
    font-family: #535353;
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 28.8px;
    letter-spacing: 0.16px;
  }
}

.body-huawei-2-titanium__lineup__slider {
  width: calc(100% + 46px);
  margin: 0 -23px;
  height: auto;
}

.body-huawei-2-titanium__lineup__slider > .slick-list {
  margin: 0 -6.5px;
  max-height: 190px;
}

.body-huawei-2-titanium__lineup__slider > .slick-list .slick-slide {
  padding: 0 6.5px;
  max-width: 303.874px;
  aspect-ratio: 303.874/190;
}

@media (min-width: 430px) {
  .body-huawei-2-titanium__lineup__slider > .slick-list {
    max-height: none;
  }
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__slider > .slick-list {
    margin: 0;
    padding: 0px 0 0 40% !important;
  }
  .body-huawei-2-titanium__lineup__slider > .slick-list .slick-slide {
    padding: 0 8.5px;
  }
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__slider > .slick-list {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 0px 0 0 37% !important;
  }
  .body-huawei-2-titanium__lineup__slider > .slick-list .slick-slide {
    width: 424px;
    max-width: 559.768px;
    aspect-ratio: 559.768/350;
  }
}

@media (min-width: 1296px) {
  .body-huawei-2-titanium__lineup__slider > .slick-list {
    padding: 0px 0 0 33.7% !important;
  }
  .body-huawei-2-titanium__lineup__slider > .slick-list .slick-slide {
    width: 577px;
    max-width: none;
    max-width: 560/350;
  }
}

@media (min-width: 768px) {
  .body-huawei-2-titanium__lineup__slider {
    width: calc(100% + 500px);
    margin: 0 0 0 -500px;
  }
}

@media (min-width: 1140px) {
  .body-huawei-2-titanium__lineup__slider {
    width: calc(100% + 500px);
    margin: 0 0 0 -500px;
  }
}

.body-huawei-2-titanium__lineup__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
}

@media (max-width: 767px) {
  .body-huawei-2-titanium__lineup__arrows {
    display: none;
  }
}

.body-huawei-2-titanium__lineup__arrows-prev, .body-huawei-2-titanium__lineup__arrows-next {
  width: 54px;
  height: 50px;
  background-color: #7C7C7C;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 21px 23px;
  border: none;
  outline: none;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
}

@media (min-width: 1024px) {
  .body-huawei-2-titanium__lineup__arrows-prev, .body-huawei-2-titanium__lineup__arrows-next {
    width: 68px;
    cursor: pointer;
  }
  .body-huawei-2-titanium__lineup__arrows-prev:hover, .body-huawei-2-titanium__lineup__arrows-next:hover {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: black;
  }
}

.body-huawei-2-titanium__lineup__arrows-prev {
  background-image: url("https://static.lenskart.com/owndays/img/huawei2-titanium/arrow-left-icon.svg");
  border-radius: 10px 0 0 10px;
}

.body-huawei-2-titanium__lineup__arrows-next {
  background-image: url("https://static.lenskart.com/owndays/img/huawei2-titanium/arrow-right-icon.svg");
  border-radius: 0 10px 10px 0;
}
