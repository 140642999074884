.body-contact-us__container {
  width: 100%;
  height: auto;
  padding: 0 5%;
  max-width: 764px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .body-contact-us__container {
    width: 80%;
    padding: 0;
  }
}

.body-contact-us__about {
  padding: 20px 0 40px 0;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.body-contact-us__about h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: black;
  margin: 0;
}

.body-contact-us__about h2 span {
  width: 140px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.body-contact-us__about h2 span img {
  width: 100%;
  display: block;
}

.body-contact-us__about ul {
  margin: 25px 0 0 0;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.body-contact-us__about ul li a {
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 1px;
  padding: 17px 58px 17px 16px;
  border: 1px solid #e3e3e3;
  position: relative;
}

.body-contact-us__about ul li a:hover, .body-contact-us__about ul li a:active, .body-contact-us__about ul li a:visited, .body-contact-us__about ul li a:focus {
  text-decoration: none;
}

.body-contact-us__about ul li a::after {
  content: '';
  width: 28px;
  height: 7px;
  background-image: url("https://static.lenskart.com/owndays/img/contact-us/arrow-r-black.png");
  background-size: contain;
  position: absolute;
  right: 16px;
  top: calc(50% - 3.5px);
}

@media (min-width: 768px) {
  .body-contact-us__about {
    padding: 40px 0 90px 0;
  }
  .body-contact-us__about h2 span {
    width: 170px;
  }
  .body-contact-us__about ul {
    margin: 60px 0 0 0;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .body-contact-us__about ul li a {
    padding: 24px 73px 24px 30px;
  }
  .body-contact-us__about ul li a::after {
    right: 24px;
  }
}

@media (min-width: 1024px) {
  .body-contact-us__about ul li a:hover {
    color: #999;
  }
}

.body-contact-us__recruit {
  padding: 20px 0 40px 0;
  border-bottom: 1px solid #e3e3e3;
}

.body-contact-us__recruit h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: black;
  margin: 0;
}

.body-contact-us__recruit h2 span {
  width: 60px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.body-contact-us__recruit h2 span img {
  width: 100%;
  display: block;
}

.body-contact-us__recruit ul {
  list-style-type: none;
  margin: 40px 0 0 0;
  max-width: 510px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
}

.body-contact-us__recruit ul li {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: 30px;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.body-contact-us__recruit ul li:nth-child(2) {
  grid-template-rows: 60px;
}

.body-contact-us__recruit ul li h4, .body-contact-us__recruit ul li p {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  font-size: 15px;
  line-height: 2;
  letter-spacing: 1px;
  color: black;
  margin: 0;
}

.body-contact-us__recruit__link {
  margin-top: 20px;
}

.body-contact-us__recruit__link a {
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 1px;
  padding: 17px 58px 17px 16px;
  border: 1px solid #e3e3e3;
  position: relative;
}

.body-contact-us__recruit__link a:hover, .body-contact-us__recruit__link a:active, .body-contact-us__recruit__link a:visited, .body-contact-us__recruit__link a:focus {
  text-decoration: none;
}

.body-contact-us__recruit__link a::after {
  content: '';
  width: 28px;
  height: 7px;
  background-image: url("https://static.lenskart.com/owndays/img/contact-us/arrow-r-black.png");
  background-size: contain;
  position: absolute;
  right: 16px;
  top: calc(50% - 3.5px);
}

@media (min-width: 768px) {
  .body-contact-us__recruit {
    padding: 40px 0 70px 0;
  }
  .body-contact-us__recruit h2 span {
    width: 80px;
  }
  .body-contact-us__recruit ul {
    margin: 40px auto 0;
    max-width: 510px;
  }
  .body-contact-us__recruit ul li:nth-child(2) {
    grid-template-rows: 30px;
  }
  .body-contact-us__recruit__link {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .body-contact-us__recruit__link a {
    width: 48.5%;
    padding: 24px 73px 24px 30px;
  }
  .body-contact-us__recruit__link a::after {
    right: 24px;
  }
}

@media (min-width: 1024px) {
  .body-contact-us__recruit__link a:hover {
    color: #999;
  }
}
