.footer-sun__gap {
  width: 100%;
  height: 80px;
}

.footer-sun__navs-sticky {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 55px;
  z-index: 2;
}

.footer-sun__navs-sticky .base-sun__container {
  padding-inline: 0;
}

.footer-sun__navs-sticky .base-sun__btn {
  width: 160px !important;
  height: 55px;
  border-radius: 0;
  white-space: nowrap;
  text-align: center;
}

.footer-sun__navs-sticky .base-sun__btn--primary {
  border-top-left-radius: 15px !important;
}

.footer-sun__navs-sticky__inner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
}

.footer-sun__navs-sticky__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  max-width: 823px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .footer-sun__navs-sticky__gap {
    height: 100px;
  }
  .footer-sun__navs-sticky .base-sun__btn {
    width: 260px !important;
  }
  .footer-sun__navs-sticky .base-sun__btn--primary {
    border-top-left-radius: 20px !important;
  }
  .footer-sun__navs-sticky .base-sun__btn--secondary {
    border-top-right-radius: 20px !important;
  }
  .footer-sun__navs-sticky__actions {
    padding-right: 20px;
    max-width: none;
  }
  .footer-sun__navs-sticky__inner {
    background-color: transparent;
    padding: 0;
  }
}
