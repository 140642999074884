.prescription-page {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 37px 107px 50px 107px;
  width: 100%;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  &-title {
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
  }

  &-text {
    font-size: 30px;
    color: #000;
  }

  &-content {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 50px;
  }

  &-date {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-self: stretch;
    opacity: 0.5;
  }

  &-table {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    &-row {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      background-color: #FFF;
    }

    &-title {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      padding: 13px 77px;
      border: 1px solid #D0D0D0;
      text-align: center;
    }

    &-cell {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      padding: 13px 77px;
      border: 1px solid #D0D0D0;
      text-align: center;
    }
  }
}
