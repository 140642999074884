.od-payment-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 90px 115px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 170px;
  background-color: #f7f7f7;
  min-height: calc(100vh - 83px);
}

.od-payment-page__content {
  display: grid;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  grid-template-columns: 3.5fr 1fr;
  gap: 40px;
}

.od-payment-page__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.od-payment-page__add-items {
  width: 100%;
  padding-top: 28px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.od-payment-page__add-items span {
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.od-payment-page__success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  height: calc(100vh - 83px);
}

.od-payment-page__success .od-payment-page-d-success-image {
  margin-top: -100px;
}
