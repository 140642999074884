.logo {
    display: inline-flex;
    margin-right: 10px;
    >a{
        display: inline-flex;
    }

    &__image {
        width: calc(190px/2);
    }
    @media (min-width: 992px) {
        &__image{
            width: calc(190px/1.5);
        }
    }
    @media (min-width: 1200px) {
        &__image{
            width: calc(190px/1.25);
        }
    }
    @media (min-width: 1440px) {
        &__image{
            width: calc(190px/1);
        }
    }
}

.blank-logo {
    height: 40px;
    width: 100%;
    overflow: hidden;
    .blankwidgets {
        grid-template-columns: repeat(1, 1fr);
        padding: 0px;
        .blankwidgets_box {
            border: none;
            .blankwidgets_box_headerWrap{
                width: 100%;
                .blankwidgets_box_animatedBackground_header {
                    height: 40px;
                }
            }
            .blankwidgets_box_imageWrap,
            .blankwidgets_box_footerWrap {
                display: none;
            }
        }
    }
}