.od-body-owndays-progressive-cp {
  width: 100%;
  height: auto;
}

.od-body-owndays-progressive-cp__container__meaning-box {
  width: 100%;
  height: auto;
  position: relative;
}

.od-body-owndays-progressive-cp__container__meaning {
  width: 100%;
  max-width: 760px;
  height: auto;
  padding: 144px 0 150px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.od-body-owndays-progressive-cp__container__meaning--text {
  padding: 144px 0 0 0;
}

.od-body-owndays-progressive-cp__container__meaning--vdo {
  padding: 0 0 140px 0;
}

@media (max-width: 885px) {
  .od-body-owndays-progressive-cp__container__meaning {
    padding: 144px 40px 150px;
  }
}

@media (max-width: 430px) {
  .od-body-owndays-progressive-cp__container__meaning {
    padding: 100px 40px 100px;
  }
  .od-body-owndays-progressive-cp__container__meaning--text {
    padding: 100px 40px 0 40px;
  }
  .od-body-owndays-progressive-cp__container__meaning--vdo {
    padding: 0 0 100px 0;
    background-color: #E5E8ED;
  }
}

.od-body-owndays-progressive-cp__container__meaning-presbyopia {
  width: calc(100% - 120px);
  max-width: 1665px;
  height: 632px;
  position: relative;
  margin: 0 auto;
  zoom: 160%;
}

@media (max-width: 2431px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    zoom: 135%;
  }
}

@media (max-width: 2378px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    zoom: 138%;
  }
}

@media (max-width: 2111px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    zoom: 120%;
  }
}

@media (max-width: 1794px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    width: 100%;
    max-width: 1440px;
    zoom: unset;
  }
}

@media (max-width: 1176px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 850px;
  }
}

@media (max-width: 671px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 797px;
  }
}

@media (max-width: 609px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 823px;
  }
}

@media (max-width: 448px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 844px;
    background-color: #E5E8ED;
  }
}

@media (max-width: 427px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 859px;
  }
}

@media (max-width: 406px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 877px;
  }
}

@media (max-width: 390px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 880px;
  }
}

@media (max-width: 390px) {
  .od-body-owndays-progressive-cp__container__meaning-presbyopia {
    height: 880px;
  }
}

.od-body-owndays-progressive-cp__container__book-online-btn {
  padding: 150px 0;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__book-online-btn {
    padding: 100px 20px;
  }
}

.od-body-owndays-progressive-cp__container__benefits-box {
  width: 100%;
  height: auto;
  position: relative;
}

.od-body-owndays-progressive-cp__container__benefits {
  width: calc(100% - 120px);
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 50px;
  margin: 0 auto;
  padding: 150px 0;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__benefits {
    width: calc(100% - 40px);
    padding: 0 0 126px 0;
  }
}

.od-body-owndays-progressive-cp__container__benefits-list {
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  -webkit-column-gap: 53px;
     -moz-column-gap: 53px;
          column-gap: 53px;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp__container__benefits-list {
    grid-template-columns: 260px 260px;
    row-gap: 53px;
  }
}

@media (max-width: 700px) {
  .od-body-owndays-progressive-cp__container__benefits-list {
    grid-template-columns: 260px 260px;
    row-gap: 10px;
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
  }
}

@media (max-width: 574px) {
  .od-body-owndays-progressive-cp__container__benefits-list {
    grid-template-columns: 230px 230px;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__benefits-list {
    grid-template-columns: 200px 200px;
  }
}

@media (max-width: 475px) {
  .od-body-owndays-progressive-cp__container__benefits-list {
    grid-template-columns: 170px 170px;
  }
}

.od-body-owndays-progressive-cp__container__persons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
  padding-bottom: 101px;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp__container__persons {
    padding-top: 74px;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__persons {
    gap: 34px;
    padding-bottom: 101px;
    background-color: #283750;
  }
}

.od-body-owndays-progressive-cp__container__persons-list {
  width: calc(100% - 340px);
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp__container__persons-list {
    width: calc(100% - 120px);
  }
}

@media (max-width: 860px) {
  .od-body-owndays-progressive-cp__container__persons-list {
    width: calc(100% - 60px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__persons-list {
    width: calc(100% - 40px);
    gap: 30px;
  }
}

.od-body-owndays-progressive-cp__container__types {
  width: calc(100% - 340px);
  height: auto;
  padding-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 50px;
  margin: 0 auto;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp__container__types {
    width: calc(100% - 120px);
    padding-top: 100px;
  }
}

@media (max-width: 704px) {
  .od-body-owndays-progressive-cp__container__types {
    width: calc(100% - 60px);
  }
}

@media (max-width: 650px) {
  .od-body-owndays-progressive-cp__container__types {
    width: calc(100% - 40px);
    gap: 30px;
    padding-bottom: 50px;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__types {
    position: relative;
  }
  .od-body-owndays-progressive-cp__container__types::before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    background-color: #E5E8ED;
    z-index: -1;
  }
}

.od-body-owndays-progressive-cp__container__comparison-box {
  width: 100%;
  height: auto;
  position: relative;
}

.od-body-owndays-progressive-cp__container__comparison {
  width: calc(100% - 120px);
  max-width: 1440px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .od-body-owndays-progressive-cp__container__comparison {
    width: calc(100% - 40px);
  }
}

.od-body-owndays-progressive-cp__container__lens-features {
  width: calc(100% - 120px);
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  gap: 50px;
  padding: 150px 0 0 0;
}

@media (max-width: 768px) {
  .od-body-owndays-progressive-cp__container__lens-features {
    width: calc(100% - 40px);
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp__container__lens-features {
    width: calc(100% - 20px);
    gap: 30px;
    padding: 100px 0 0 0;
  }
}

.od-body-owndays-progressive-cp .meaning__title, .od-body-owndays-progressive-cp .meaning__desc {
  font-family: "Founders Grotesk", sans-serif;
}

.od-body-owndays-progressive-cp .meaning__title {
  color: #283750;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
  margin: 0;
}

.od-body-owndays-progressive-cp .meaning__title br {
  display: none;
}

.od-body-owndays-progressive-cp .meaning__desc {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.9px;
  margin: 54px 0 50px 0;
}

.od-body-owndays-progressive-cp .meaning__vdo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -o-object-fit: cover;
     object-fit: cover;
}

.od-body-owndays-progressive-cp .meaning__vdo-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  background-color: rgba(0, 0, 0, .3);
}

.od-body-owndays-progressive-cp .meaning__vdo-container {
  width: 100%;
  height: auto;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 48.85%;
}

.od-body-owndays-progressive-cp .meaning__vdo-container:hover .meaning__vdo-cover {
  background-color: transparent;
}

.od-body-owndays-progressive-cp .meaning__vdo-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background: url("https://static.lenskart.com/media/owndays/img/progressive-cp/play-vdo-icon.svg") no-repeat center center;
  /* Use your play button image */
  background-size: contain;
  cursor: pointer;
  z-index: 2;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

@media (max-width: 430px) {
  .od-body-owndays-progressive-cp .meaning__vdo-container::before {
    width: 50px;
    height: 50px;
  }
}

.od-body-owndays-progressive-cp .meaning__playing {
  width: 100%;
  height: auto;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 48.85%;
}

.od-body-owndays-progressive-cp .meaning__playing .meaning__vdo-container::before {
  display: none;
}

@media (max-width: 669px) {
  .od-body-owndays-progressive-cp .meaning__title br {
    display: block;
  }
}

@media (max-width: 430px) {
  .od-body-owndays-progressive-cp .meaning__title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
  .od-body-owndays-progressive-cp .meaning__desc {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.8px;
    margin: 30px 0 50px 0;
  }
  .od-body-owndays-progressive-cp .meaning__vdo {
    width: 100%;
    margin-left: 0;
  }
}

.od-body-owndays-progressive-cp .meaning-presbyopia__desc-box {
  width: calc(100% - 154px);
  max-width: 900px;
  height: auto;
  background-color: #283750;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px 0;
  border-radius: 0px 50px 0px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}

@media (max-width: 1383px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-box {
    width: 60%;
  }
}

@media (max-width: 1200px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-box {
    width: 55%;
  }
}

@media (max-width: 1176px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-box {
    width: 100%;
    z-index: 1;
    padding: 130px 0 100px 0;
  }
}

@media (max-width: 671px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-box {
    gap: 30px;
  }
}

.od-body-owndays-progressive-cp .meaning-presbyopia__desc-title, .od-body-owndays-progressive-cp .meaning-presbyopia__desc-detail {
  font-family: "Founders Grotesk", sans-serif;
  color: #fff;
}

.od-body-owndays-progressive-cp .meaning-presbyopia__desc-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
  margin: 0;
  text-align: center;
}

.od-body-owndays-progressive-cp .meaning-presbyopia__desc-title br {
  display: none;
}

@media (max-width: 671px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-title br {
    display: block;
  }
}

.od-body-owndays-progressive-cp .meaning-presbyopia__desc-detail {
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.9px;
  padding: 0 120px;
}

@media (max-width: 1383px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-detail {
    padding: 0 60px;
  }
}

@media (max-width: 858px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-detail {
    padding: 0 40px;
  }
}

@media (max-width: 671px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__desc-detail {
    font-size: 16px;
    line-height: 28px;
  }
}

.od-body-owndays-progressive-cp .meaning-presbyopia__image {
  width: 720px;
  height: 400px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@media (max-width: 1176px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__image {
    z-index: 2;
  }
}

@media (max-width: 858px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__image {
    width: 680px;
    height: 360px;
  }
}

@media (max-width: 671px) {
  .od-body-owndays-progressive-cp .meaning-presbyopia__image {
    width: calc(100% - 40px);
    height: auto;
  }
}

.od-body-owndays-progressive-cp .book-online-btn {
  width: 500px;
  height: 80px;
  border-radius: 40px;
  background-color: #283750;
  padding: 29px 20px 28px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  cursor: pointer;
  border: 3px solid #283750;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.od-body-owndays-progressive-cp .book-online-btn:hover, .od-body-owndays-progressive-cp .book-online-btn:active, .od-body-owndays-progressive-cp .book-online-btn:visited, .od-body-owndays-progressive-cp .book-online-btn:focus {
  text-decoration: none;
}

@media (min-width: 768px) {
  .od-body-owndays-progressive-cp .book-online-btn:hover {
    text-decoration: none;
    background-color: #fff;
    border: 3px solid #283750;
  }
  .od-body-owndays-progressive-cp .book-online-btn:hover span {
    color: #283750;
  }
  .od-body-owndays-progressive-cp .book-online-btn:hover img {
    content: url("https://static.lenskart.com/media/owndays/img/progressive-cp/arrow-right-circle-blue-icon.svg");
  }
}

.od-body-owndays-progressive-cp .book-online-btn span {
  color: #fff;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  letter-spacing: 2px;
}

.od-body-owndays-progressive-cp .book-online-btn img {
  width: 50px;
  height: 50px;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .book-online-btn {
    width: 350px;
    height: 60px;
    padding: 25px 10px 25px 40px;
  }
  .od-body-owndays-progressive-cp .book-online-btn span {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
  }
  .od-body-owndays-progressive-cp .book-online-btn img {
    width: 40px;
    height: 40px;
  }
}

.od-body-owndays-progressive-cp .bg-light-blue {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 175px);
  height: calc(100% - 670px);
  border-radius: 0px 50px 0px 0px;
  background-color: #E5E8ED;
  z-index: -1;
}

@media (max-width: 1176px) {
  .od-body-owndays-progressive-cp .bg-light-blue {
    display: none;
  }
}

.od-body-owndays-progressive-cp .bg-dark-blue {
  position: absolute;
  top: 454px;
  left: 0;
  width: 100%;
  height: 652px;
  background-color: #283750;
  z-index: -1;
}

.od-body-owndays-progressive-cp .bg-dark-blue::before {
  position: absolute;
  top: 652px;
  content: '';
  width: 100%;
  height: calc(100% + 680px);
  background-color: #EEF0F2;
  z-index: -1;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp .bg-dark-blue {
    top: 49%;
    height: 83%;
  }
  .od-body-owndays-progressive-cp .bg-dark-blue::before {
    display: none;
  }
}

@media (max-width: 860px) {
  .od-body-owndays-progressive-cp .bg-dark-blue {
    top: 100%;
    height: 83%;
  }
}

@media (max-width: 430px) {
  .od-body-owndays-progressive-cp .bg-dark-blue {
    display: none;
  }
}

.od-body-owndays-progressive-cp .bg-dark-blue-comparison {
  position: absolute;
  top: 410px;
  left: 0;
  width: 100%;
  height: 93%;
  background-color: #283750;
  z-index: -1;
}

.od-body-owndays-progressive-cp .bg-dark-blue-comparison::before {
  position: absolute;
  top: 100%;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #EEF0F2;
  z-index: -1;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp .bg-dark-blue-comparison {
    top: 37%;
    height: 86%;
  }
}

@media (max-width: 1024px) {
  .od-body-owndays-progressive-cp .bg-dark-blue-comparison {
    top: 37%;
    height: 87%;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .bg-dark-blue-comparison {
    top: 78%;
    height: 50%;
  }
  .od-body-owndays-progressive-cp .bg-dark-blue-comparison::after {
    position: absolute;
    top: -156%;
    content: "";
    width: 100%;
    height: 156%;
    background-color: #E5E8ED;
    z-index: -1;
  }
}

.od-body-owndays-progressive-cp .benefits__title {
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
  text-align: center;
}

.od-body-owndays-progressive-cp .benefits__title br {
  display: none;
}

.od-body-owndays-progressive-cp .benefits__item {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: baseline;
      -ms-flex-pack: baseline;
          justify-content: baseline;
  gap: 30px;
  padding: 26px 30px 50px 30px;
  border-radius: 10px;
  background-color: #EEF0F2;
}

.od-body-owndays-progressive-cp .benefits__item h5, .od-body-owndays-progressive-cp .benefits__item span {
  font-family: "Founders Grotesk", sans-serif;
  color: #000;
  text-align: center;
}

.od-body-owndays-progressive-cp .benefits__item img {
  width: auto;
  max-width: 104px;
  height: auto;
  max-height: 60px;
}

.od-body-owndays-progressive-cp .benefits__item h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  letter-spacing: 2px;
  margin: 0;
}

.od-body-owndays-progressive-cp .benefits__item span {
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.4px;
}

.od-body-owndays-progressive-cp .benefits__item:nth-child(1) img {
  margin-top: 19px;
}

.od-body-owndays-progressive-cp .benefits__item:nth-child(4) h5 {
  white-space: nowrap;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .benefits__title {
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 28px;
  }
  .od-body-owndays-progressive-cp .benefits__title br {
    display: block;
  }
  .od-body-owndays-progressive-cp .benefits__item {
    gap: 20px;
    padding: 25px 20px 30px 20px;
  }
  .od-body-owndays-progressive-cp .benefits__item img {
    width: auto;
    max-width: 91px;
    height: auto;
    max-height: 56px;
  }
  .od-body-owndays-progressive-cp .benefits__item h5 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
    margin: 0;
  }
  .od-body-owndays-progressive-cp .benefits__item span {
    margin: 0;
    line-height: 22px;
    letter-spacing: 0.8px;
    text-align: left;
  }
  .od-body-owndays-progressive-cp .benefits__item:nth-child(1) img {
    margin-top: 10px;
  }
}

.od-body-owndays-progressive-cp .persons__title {
  margin: 0;
  color: #FFF;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
  text-align: center;
}

.od-body-owndays-progressive-cp .persons__title br {
  display: none;
}

@media (max-width: 860px) {
  .od-body-owndays-progressive-cp .persons__title br {
    display: block;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .persons__title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

.od-body-owndays-progressive-cp .persons__item {
  width: 360px;
  height: auto;
  padding: 30px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, .1);
          box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, .1);
}

.od-body-owndays-progressive-cp .persons__item img {
  width: 250px;
  height: auto;
}

.od-body-owndays-progressive-cp .persons__item span {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.9px;
}

@media (max-width: 1240px) {
  .od-body-owndays-progressive-cp .persons__item {
    width: 100%;
  }
}

@media (max-width: 860px) {
  .od-body-owndays-progressive-cp .persons__item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .persons__item {
    gap: 10px;
    padding: 20px;
  }
  .od-body-owndays-progressive-cp .persons__item img {
    width: 150px;
  }
  .od-body-owndays-progressive-cp .persons__item span {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
    text-align: left;
  }
}

.od-body-owndays-progressive-cp .types h2, .od-body-owndays-progressive-cp .types span {
  font-family: "Founders Grotesk", sans-serif;
}

.od-body-owndays-progressive-cp .types h2 {
  color: #283750;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
  margin: 0;
  text-align: center;
}

.od-body-owndays-progressive-cp .types span {
  width: 100%;
  max-width: 760px;
  height: auto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.9px;
  color: #000;
  margin: 0 auto;
}

@media (max-width: 650px) {
  .od-body-owndays-progressive-cp .types h2 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
  .od-body-owndays-progressive-cp .types span {
    width: calc(100% - 20px);
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.8px;
  }
}

.od-body-owndays-progressive-cp .comparison__title {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  letter-spacing: 2px;
  color: #283750;
  text-align: center;
  margin: 0 0 50px 0;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
    margin: 0 0 30px 0;
  }
}

.od-body-owndays-progressive-cp .comparison__nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}

@media (max-width: 1265px) {
  .od-body-owndays-progressive-cp .comparison__nav {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 4px;
  }
}

.od-body-owndays-progressive-cp .comparison__nav-item {
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #939598;
  cursor: pointer;
}

.od-body-owndays-progressive-cp .comparison__nav-item--active:nth-child(1) {
  background-color: #BF0000;
}

.od-body-owndays-progressive-cp .comparison__nav-item--active:nth-child(2) {
  background-color: #283750;
}

.od-body-owndays-progressive-cp .comparison__nav-item--active:nth-child(3) {
  background-color: #B47E00;
}

.od-body-owndays-progressive-cp .comparison__nav-item--active:nth-child(4) {
  background-color: #000;
}

.od-body-owndays-progressive-cp .comparison__nav-item strong {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: 1.8px;
  color: #fff;
  text-align: center;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__nav-item strong {
    font-size: 16px;
    letter-spacing: 1.6px;
  }
}

@media (max-width: 414px) {
  .od-body-owndays-progressive-cp .comparison__nav-item strong {
    padding: 0 10px;
  }
}

.od-body-owndays-progressive-cp .comparison__desc {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 67.08% 32.92%;
  grid-template-rows: 680px;
}

.od-body-owndays-progressive-cp .comparison__desc__header-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.od-body-owndays-progressive-cp .comparison__desc__detail {
  width: 100%;
  height: auto;
  padding: 50px;
  background-color: #fff;
  position: relative;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__rank {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__rank:nth-child(2) {
  margin: 30px 0;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__rank span {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 1px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__rank .circle-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__rank .circle {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  border: 1px solid #000;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__rank .circle--selected {
  background-color: #000;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__rank:nth-child(2) {
    margin: 20px 0;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__rank span {
    font-size: 16px;
    letter-spacing: 0.8px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__rank .circle-box {
    gap: 3.85px;
    margin-right: 25%;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__rank .circle {
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 385px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__rank .circle-box {
    margin-right: 0;
  }
}

.od-body-owndays-progressive-cp .comparison__desc__detail__key-differences {
  padding: 50px 0;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__key-differences h3 {
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__key-differences ul {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
  list-style-type: none;
  padding-left: 0;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__key-differences ul li {
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.9px;
  position: relative;
  padding-left: 1em;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__key-differences ul li::before {
  content: '';
  position: absolute;
  top: 13px;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__key-differences {
    padding: 40px 0;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__key-differences h3 {
    font-size: 14px;
    text-underline-offset: 0.7px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__key-differences ul {
    margin-top: 25px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__key-differences ul li {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.8px;
    position: relative;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__key-differences ul li::before {
    top: 12px;
    width: 5px;
    height: 5px;
  }
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price h3, .od-body-owndays-progressive-cp .comparison__desc__detail__price__value, .od-body-owndays-progressive-cp .comparison__desc__detail__price__plus {
  font-family: "Founders Grotesk", sans-serif;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price h3 {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value-box {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value-box--zero {
  font-family: "Founders Grotesk", sans-serif;
  margin-top: 50px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value-box--600 {
  margin-bottom: 80px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value {
  color: #BF0000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: 1.5px;
  margin-left: 23px;
  position: relative;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value--zero {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: 5px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value::before {
  content: '$';
  position: absolute;
  left: -23px;
  top: 0;
  font-size: 30px;
  letter-spacing: 1.5px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__value--suffix {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
  letter-spacing: 0.8px;
  margin-left: 5px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__price__plus {
  color: #BF0000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
  letter-spacing: 1.5px;
  margin: 2px 10px 2px 0;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__price h3 {
    font-size: 14px;
    letter-spacing: 0.7px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__value-box {
    margin-top: 25px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__value-box--zero {
    font-family: "Founders Grotesk", sans-serif;
    margin-top: 29px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__value {
    font-size: 20px;
    letter-spacing: 1px;
    margin-left: 16px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__value--zero {
    font-family: "Founders Grotesk", sans-serif;
    font-size: 70px;
    letter-spacing: 3.5px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__value::before {
    font-size: 20px;
    letter-spacing: 1px;
    left: -16px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__value--suffix {
    font-size: 14px;
    letter-spacing: 0.7px;
    margin-left: 8px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__price__plus {
    font-size: 20px;
    letter-spacing: 1px;
    margin: 0 4px 4px 0;
  }
}

@media (max-width: 430px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__price {
    height: 148px;
  }
}

.od-body-owndays-progressive-cp .comparison__desc__detail__remark {
  position: absolute;
  right: 50px;
  bottom: 5%;
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.7px;
}

.od-body-owndays-progressive-cp .comparison__desc__detail__remark--600 {
  width: 75%;
}

@media (max-width: 1265px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__remark--600 {
    width: auto;
    left: 40px;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail__remark {
    right: 20px;
    font-size: 12px;
    letter-spacing: 0.6px;
    bottom: 20px;
  }
  .od-body-owndays-progressive-cp .comparison__desc__detail__remark--600 {
    width: 89%;
    left: 20px;
  }
}

@media (max-width: 1292px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail {
    padding: 40px;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__desc__detail {
    padding: 20px;
  }
}

.od-body-owndays-progressive-cp .comparison__desc__image-text {
  color: #fff;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.7px;
  margin-top: 8px;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .comparison__desc__image-text {
    font-size: 12px;
    letter-spacing: 0.6px;
  }
}

@media (max-width: 1265px) {
  .od-body-owndays-progressive-cp .comparison__desc {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.od-body-owndays-progressive-cp .lens-features h2 {
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .lens-features h2 {
    font-size: 20px;
  }
}

.od-body-owndays-progressive-cp .lens-features__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
}

@media (max-width: 1024px) {
  .od-body-owndays-progressive-cp .lens-features__list {
    gap: 25px;
  }
}

@media (max-width: 550px) {
  .od-body-owndays-progressive-cp .lens-features__list {
    gap: 10px;
  }
}

.od-body-owndays-progressive-cp .lens-features__item {
  width: 260px;
  height: 187px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, .1);
          box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, .1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 22px 0;
}

.od-body-owndays-progressive-cp .lens-features__item-img {
  width: auto;
  max-width: 100px;
  height: auto;
}

.od-body-owndays-progressive-cp .lens-features__item-text {
  color: #000;
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  letter-spacing: 2px;
}

.od-body-owndays-progressive-cp .lens-features__item:nth-child(1) {
  gap: 30px;
}

.od-body-owndays-progressive-cp .lens-features__item:nth-child(1) .lens-features__item-img {
  margin-top: 1px;
}

.od-body-owndays-progressive-cp .lens-features__item:nth-child(3) .lens-features__item-img {
  margin-top: 13px;
}

.od-body-owndays-progressive-cp .lens-features__item:nth-child(3) .lens-features__item-text br {
  display: none;
}

@media (max-width: 950px) {
  .od-body-owndays-progressive-cp .lens-features__item {
    width: 240px;
  }
}

@media (max-width: 890px) {
  .od-body-owndays-progressive-cp .lens-features__item {
    width: 200px;
    height: 220px;
  }
}

@media (max-width: 768px) {
  .od-body-owndays-progressive-cp .lens-features__item {
    width: 130px;
    height: 184px;
    padding: 20px 0;
    gap: 20px;
  }
  .od-body-owndays-progressive-cp .lens-features__item-img {
    max-width: 62px;
  }
  .od-body-owndays-progressive-cp .lens-features__item-text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
  }
  .od-body-owndays-progressive-cp .lens-features__item:nth-child(1) {
    gap: 30px;
  }
  .od-body-owndays-progressive-cp .lens-features__item:nth-child(1) .lens-features__item-img, .od-body-owndays-progressive-cp .lens-features__item:nth-child(3) .lens-features__item-img {
    margin-top: 0;
  }
  .od-body-owndays-progressive-cp .lens-features__item:nth-child(3) .lens-features__item-img {
    height: 48px;
  }
  .od-body-owndays-progressive-cp .lens-features__item:nth-child(3) .lens-features__item-text br {
    display: block;
  }
}

@media (max-width: 430px) {
  .od-body-owndays-progressive-cp .lens-features__item {
    width: 110px;
    height: 165px;
  }
}
