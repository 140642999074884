$font-space-mono: 'Space Mono', monospace;
$font-neuzeitgro-reg: 'NeuzeitGroReg';

$light: #f9f9f9;

.body-owndays-pc {
  &__about {
    &__top,
    &__middle {
      padding: 60px 0;
    }

    &__top {
      padding-top: 50px;
    }

    &__middle {
      background-color: $light;
      margin-bottom: 60px;

      ul {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
        max-width: 820px;
        margin: 0 auto;
        padding-left: 0;
        list-style-type: none;

        li {
          h5 {
            font-family: $font-neuzeitgro-reg;
            font-weight: 400 !important;
            font-size: 12px;
            letter-spacing: 0.1em;
            line-height: 1.3;
            margin: 0;
          }

          p {
            font-family: $font-space-mono;
            font-weight: 400 !important;
            font-size: 18px;
            line-height: 1.4;
            min-height: 25.19px;
            margin: 0;
          }
        }
      }
    }

    @media (min-width: 768px) {
      &__middle {
        ul {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    @media (min-width: 1024px) {
      &__top {
        padding-top: 90px;
        padding-bottom: 100px;
        
        .base-owndays-pc__btn {
          margin-left: 0;
        }
      }

      &__middle {
        margin-bottom: 100px;

        ul {
          li {
            h5 {
              font-size: 14px;
            }

            p {
              font-size: 20px;
              min-height: 28px;
            }

            &:nth-child(1) {
              p {
                min-width: 294px;
              }
            }

            &:nth-child(2) {
              p {
                min-width: 111px;
              }
            }
          }
        }
      }
    }
  }

  &__points {
    padding: 100px 0;

    &__list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 40px;
      max-width: 240px;
      margin: 0 auto 60px;
      list-style-type: none;
      padding-left: 0;

      li {
        img {
          margin-bottom: 20px;
        }

        h5 {
          font-family: $font-space-mono;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          margin-bottom: 15px;
        }

        p {
          font-family: $font-neuzeitgro-reg;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: 0.5px;
          margin: 0;
        }
      }
    }

    @media (min-width: 768px) {
      &__list {
        grid-template-columns: repeat(3, 1fr);
        max-width: 1050px;
        margin-bottom: 100px;
      }
    }
    
    @media (min-width: 1024px) {
      padding-top: 180px;

      &__list {
        grid-column-gap: 120px;

        li {
          h5, p {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__what {
    &__top {
      padding: 0 0 20px 0;
    }
    
    &__blue-light {
      margin: 0 auto;
      max-width: 800px;
    }

    &__slider {
      margin: 60px 0 100px;

      .slick-list {
        padding: 0 50% !important;
      }
    }

    @media (min-width: 1024px) {
      &__top {
        padding: 80px 0 60px 0;
      }

      &__slider {
        margin: 100px 0 186px 0;
  
        .slick-list {
          padding: 0 !important;
        }
      }
    }
  }
}
