.footer-ortho-k-lens__faq {
  margin-top: 70px;
}

@media (max-width: 1023px) {
  .footer-ortho-k-lens__faq {
    margin-bottom: 70px;
  }
}

@media (max-width: 1023px) {
  .footer-ortho-k-lens__faq .base-ortho-k-lens__title {
    text-align: center;
  }
}

.footer-ortho-k-lens__faq__q-a__accordion {
  margin-top: 40px;
  padding-left: 0;
  list-style-type: none;
}

.footer-ortho-k-lens__faq__q-a__accordion > li {
  border-bottom: 3px solid #000;
}

.footer-ortho-k-lens__faq__q-a__accordion > li:first-child {
  border-top: 3px solid #000;
}

.footer-ortho-k-lens__faq__q-a__accordion__title {
  padding: 23px 14px;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.footer-ortho-k-lens__faq__q-a__accordion__content {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.footer-ortho-k-lens__faq__q-a__q-title {
  font-size: 13px;
  letter-spacing: 0.06em;
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
  font-weight: 700 !important;
  font-family: "Noto Sans JP", sans-serif;
  margin: 0;
}

@media (max-width: 767px) {
  .footer-ortho-k-lens__faq__q-a__q-title {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.footer-ortho-k-lens__faq__q-a__q-title::before {
  content: 'Q';
  font: 700 15px/1 "Outfit", sans-serif;
  min-width: 28px;
  height: 30px;
  background-color: #000;
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: .06em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer-ortho-k-lens__faq__q-a__a-inner {
  font-size: 15px;
  font-weight: 500 !important;
  letter-spacing: 0.06em;
  line-height: 2.15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
  padding: 23px 14px;
  background-color: #f8f8f8;
}

.footer-ortho-k-lens__faq__q-a__a-inner::before {
  content: 'A';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font: 700 15px/1 "Outfit", sans-serif;
  min-width: 28px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #000;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: .06em;
}

.footer-ortho-k-lens__faq__q-a__a-inner p {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0;
  font-weight: normal !important;
  line-height: 2;
}

@media (min-width: 768px) {
  .footer-ortho-k-lens__faq__q-a__accordion__title {
    padding: 35px 21px;
  }
  .footer-ortho-k-lens__faq__q-a__q-title {
    font-size: 15px;
    -webkit-column-gap: 21px;
       -moz-column-gap: 21px;
            column-gap: 21px;
  }
  .footer-ortho-k-lens__faq__q-a__a-inner {
    font-size: 13px;
    -webkit-column-gap: 21px;
       -moz-column-gap: 21px;
            column-gap: 21px;
    padding: 31px 21px;
  }
}

.footer-ortho-k-lens__faq__link-color {
  color: #2e5aa8;
  font-weight: bold !important;
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .footer-ortho-k-lens__faq {
    display: grid;
    margin-top: 130px;
    padding-bottom: 138px;
    grid-template-columns: auto 843px;
  }
  .footer-ortho-k-lens__faq__title {
    min-width: 277px;
    font-size: 51px !important;
    text-align: left;
  }
  .footer-ortho-k-lens__faq__q-a__accordion {
    margin-top: 0px;
  }
  .footer-ortho-k-lens__faq__link-color:hover, .footer-ortho-k-lens__faq__link-color:active, .footer-ortho-k-lens__faq__link-color:visited, .footer-ortho-k-lens__faq__link-color:focus {
    color: #2e5aa8;
  }
}

.footer-ortho-k-lens__contact-lenses {
  position: relative;
  padding: 70px 0;
  margin-bottom: 120px;
}

.footer-ortho-k-lens__contact-lenses:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0;
  background-color: #f7f9fc;
  z-index: -1;
}

.footer-ortho-k-lens__contact-lenses__title h3 {
  font: 700 28px/1.3 din-2014, sans-serif !important;
  letter-spacing: .06em !important;
  line-height: 34px !important;
  text-align: center;
  margin: 0;
}

.footer-ortho-k-lens__contact-lenses__list {
  margin-top: 40px;
}

.footer-ortho-k-lens__contact-lenses__list ul {
  display: grid;
  gap: 10px;
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}

.footer-ortho-k-lens__contact-lenses__list ul li:hover a img {
  opacity: 0.6;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (min-width: 500px) {
  .footer-ortho-k-lens__contact-lenses__list ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .footer-ortho-k-lens__contact-lenses {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .footer-ortho-k-lens__contact-lenses__title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .footer-ortho-k-lens__contact-lenses__title h3 {
    letter-spacing: .045em !important;
    line-height: 1.3 !important;
    text-align: left;
    font-size: clamp(35.2px, 3.34vw, 53.44px) !important;
  }
  .footer-ortho-k-lens__contact-lenses__list {
    width: 66%;
    margin-top: 0;
  }
}
