.odslv-p__addons {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.odslv-p__addons .odslv-p__addon {
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 250px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 10px;
  margin: 4px 0;
  background: #FAFAFA;
}

.odslv-p__addons .odslv-p__addon__selected {
  border: 1px solid #546073;
}

.odslv-p__addons .odslv-p__addon__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}

.odslv-p__addons .odslv-p__addon-img {
  margin-right: 10px;
}

.odslv-p__addons .odslv-p__addon-img img {
  height: 40px;
}

.odslv-p__addons .odslv-p__addon-des-t {
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 14px;
  color: #546073;
}

.odslv-p__addons .odslv-p__addon-des-p strong {
  color: #546073;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 16px;
}
