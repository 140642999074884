$font-founders: "Founders Grotesk", sans-serif;

.base-eye-camp {
  &__container {
    &--inner {
      padding-top: 20px;
      padding-bottom: 40px;

      @media (min-width: 768px) {
        width: 80%;
        height: auto;
        max-width: 1152px;
        margin: 0 auto;
        padding-top: 90px;
        padding-bottom: 100px;
      }
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px;
  }

  &__col {
    flex-basis: auto;
    flex-grow: 1;

    @media (min-width: 768px) {
      flex-basis: 0;
      padding-right: 7.5px;
      padding-left: 7.5px;
    }
  }

  &__hide {
    &--pc {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }

    &--sp {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  &__bg {
    max-width: 3840px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-color: rgba(0, 0, 0, .4);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    picture {
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
        display: block;
        aspect-ratio: unset;
      }
    }
  }

  &__title {
    width: 100%;
    max-width: 762px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: transparent;
    padding: 0 10%;
    text-align: center;
    color: white;
    z-index: 2;

    h1 {
      font-family: $font-founders;
      font-size: 40px;
      font-weight: 400 !important;
      line-height: 1;
      margin: 0;

      span {
        display: block;
        font-size: 13px;
        margin-top: 5px;
        letter-spacing: 1px;
      }
    }

    @media (min-width: 768px) {
      width: 80%;
      max-width: 1152px;
      margin: 0 auto;

      h1 {
        span {
          margin-top: 20px;
        }
      }
    }
  }
}
