@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

@font-face {
  font-family: "Corporate Logo Rounded ver2";
  src: url("https://static.lenskart.com/media/owndays/desktop/fonts/Corporate-Logo/Corporate-Logo-Rounded.woff2") format("woff2"),
    url("https://static.lenskart.com/media/owndays/desktop/fonts/Corporate-Logo/Corporate-Logo-Rounded.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$font-heisei: "heisei-maru-gothic-std", sans-serif;
$font-corporate: "Corporate Logo Rounded ver2", sans-serif;
$font-fredoka: "Fredoka", sans-serif;

$base-img-url: 'https://static.lenskart.com/media/owndays/img/pompompurin';

$yellow-ffc: #ffcb14;
$yellow-ffe: #ffe57a;
$yellow-fff: #fff9b1;
$brown-76: #76332e;
$brown-ee: #eedabe;

@keyframes loading-pompompurin {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

body {
  animation: loading-pompompurin 1s;
}

.base-pompompurin {
  font-family: $font-heisei;
  font-size: 13px;
  font-weight: 400;
  line-height: calc(25 / 13);
  letter-spacing: 0.26px;
  color: $brown-76;
  background: $yellow-fff url("#{$base-img-url}/bg-dots.svg") repeat center top/auto;
  background-attachment: fixed;

  section {
    position: relative;
  }

  :is(h1, h2, h3, h4, h5, h6) {
    font-family: inherit;
  }

  &__hidden-scroll {
    overflow: hidden;
  }

  &__hide {
    &--pc {
      display: block;

      @media (min-width: 1024px) {
        display: none;
      }
    }

    &--sp {
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }
    }
  }

  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1150px + 40px);
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: $yellow-ffc;
    width: 100%;
    max-width: 310px;
    margin-inline: auto;
    height: 71px;
    border: 3px solid $brown-76;
    border-radius: 10rem;
    padding-inline: 35px 13px;
    font-size: 14px;
    font-weight: 800 !important;
    line-height: 1.5;
    color: $brown-76;
    position: relative;
    overflow: hidden;
    z-index: 2;

    i {
      font-style: normal;
      background: transparent;
    }
    

    &:hover, &:active, &:visited, &:focus {
      color: inherit;
      text-decoration: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $brown-76;
      transform: scaleX(0);
      transform-origin: right;
      transition: all 0.25s ease;
      transition-property: transform;
      z-index: -1;
    }

    &-arrow {
      display: grid;
      place-items: center;
      width: 47px;
      height: 47px;
      background-color: $brown-76;
      border-radius: 10rem;
    }

    &[disabled] {
      pointer-events: none;
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(black, 0.44);
      }
    }

    &--white {
      background-color: white;
      .base-pompompurin__btn-arrow svg path {
        fill: white;
        stroke: white;
      }
    }

    &--nav{
      padding-inline: 20px;
      min-width: 166px;
      
      span{
        display: inline-flex;
        margin-top: -4px;
      }
    }

    @media (max-width: 767px) {
      &--nav{
        min-width: 0;
        width: 110px;
        height: 42px;
        border: none;
        font-size: 11px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    
    @media (min-width: 768px) {
      &--nav{
        padding-inline: 35px;
      }
    }

    @media (min-width: 1024px) {
      font-size: 16px;
      width: 300px;

      span {
        svg {
          width: 16px;
          height: 27px;
        }
      }

      &:hover {
        color: $yellow-ffc;

        &::before {
          transform: scaleX(1);
          transform-origin: left;
        }

        .base-pompompurin__btn-arrow {
          background-color: $yellow-ffc;
          svg path {
            fill: $brown-76;
            stroke: $brown-76;
          }
        }
      }
    }
  }

  &__nav{
    position: sticky;
    bottom: -1px;
    z-index: 20;
    background-color: rgba(black,.7);
    padding-block: 20px;
    color: white;

    &-grid{
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 7px;
      align-items: center;
    }

    &-duration{
      display: flex;
      justify-content: center;
      font: 700 22px/30px $font-corporate;
      small{
        font: 800 11px/20px $font-heisei;
      }
    }
    
    &-discount{
      display: flex;
      font-size: 9px;
      font-weight: 800 !important;
      line-height: 15px;

      a{
        display: inline-flex;
        text-decoration: underline;
        color: currentColor;
      }
    }
    
    @media (max-width: 1296px) {
      padding-inline: 20px;
      text-align: right;

      &-grid{
        display: inline-grid;
        column-gap: 10px;
      }

      &-duration{
        flex-direction: column;
      }

      &-discount{
        grid-column: span 2;
      }

      .base-pompompurin__container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (min-width: 1296px) {
      padding-block: 37px 24px;

      .base-pompompurin__container {
        text-align: center;
      }

      &-grid{
        display: inline-grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 20px;
      }

      &-duration{
        font-size: 31px;
        line-height: 50px;
        flex-direction: column;
        align-items: flex-start;
        align-items: flex-end;

        small{
          font-size: 14px;
          line-height: 28px;
          margin-left: 0;
        }
      }
    }
  }
}
