$base-img-url: 'https://static.lenskart.com/owndays/img/tomandjerry';

$pink-ec: #ec7497;
$green-00: #00ac87;
$gray-7b: #7b868c;
$yellow-fa: #fae445;
$yellow-fd: #fde360;
$blue-15: #15a9d8;
$blue-of: #0f99c4;
$red-e1: #e1002a;
$black-2b: #2b2926;

$font-karu: "Karu";
$font-genei: "GenEi POPle Pw";

.header-tom-and-jerry {
  &__main {
    position: relative;
    padding-block: 124px 199px;
    background: $yellow-fd url("#{$base-img-url}/main-bg_sp.svg") no-repeat left 18px top 21px / 100% auto;

    &::after {
      content: "";
      position: absolute;
      top: calc(100% - 77px);
      left: 50%;
      transform: translateX(-50%);
      width: 180vw;
      height: 700px;
      background-color: $blue-15;
      border-radius: 100%;
    }

    &__top {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__logo {
      font: 0/0 a;
      img {
        display: block;
        aspect-ratio: 305/187;
        max-width: 179px;
        margin-inline: auto;
      }
    }

    &__content {
      position: relative;
      width: 100%;
      height: 310px;
    }

    &__img {
      position: absolute;
      z-index: 1;

      &--tom {
        top: 0;
        left: calc(50% - 55px);

        img {
          aspect-ratio: 438/253;
          width: 386px;
          height: 223px;
          max-width: none;
        }
      }

      &--jn {
        bottom: 0;
        right: calc(50% - 30px);
        img {
          aspect-ratio: 227/245;
          width: 206px;
          height: 154px;
        }
      }

      &--85y {
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

        img {
          aspect-ratio: 185/186;
          max-width: 146px;
        }
      }
    }

    @media (max-width: 1023px) {
      &__logo {
        margin-bottom: 40px;
      }
    }

    @media (min-width: 1024px) {
      padding-block: 305px 653px;
      background: $yellow-fd url("#{$base-img-url}/main-bg_pc.svg") no-repeat right 82px top 91px/100% auto;

      &::after {
        width: 115vw;
        height: 1196px;
        top: calc(100% - 304px);
      }

      &__logo {
        img {
          max-width: 305px;
        }
      }

      &__content {
        width: 1735px;
        height: 503px;
        position: absolute;
        top: 313px;
        left: calc(50% - 665px);
      }

      &__img {
        &--tom {
          left: auto;
          right: 0;
          img {
            width: 871px;
            height: 503px;
          }
        }

        &--jn {
          top: 108px;
          left: 0;
          right: auto;
          bottom: auto;
          img {
            width: 465px;
            height: 348px;
          }
        }

        &--85y {
          top: 25px;
          right: 25px;
          left: auto;
          bottom: auto;
          transform: none;

          img {
            max-width: 184px;
          }
        }
      }
    }
  }

  &__about {
    position: relative;
    background-color: $blue-15;
    padding-block: 45px 70px;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 50px;

      p {
        font-family: $font-karu;
        color: white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 900 !important;
        line-height: 41px;
        letter-spacing: 1px;
        margin: 0 auto 0 auto;

        em {
          background-color: $red-e1;
          font-style: normal;
          padding: 0 4px;
        }
      }

      img {
        display: block;
        aspect-ratio: 32/35;
        max-width: 288px;
        margin-inline: auto;
      }
    }

    @media (min-width: 768px) {
      &__content {
        flex-direction: row-reverse;
        align-items: center;
        gap: 0;

        p {
          width: 50%;
          text-align: left;
          margin: 0;
          padding-right: 38px;

          br {
            display: none;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      padding-block: 0 114px;
      margin-top: -90px;
      background-color: transparent;

      &::before {
        content: "";
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blue-15;
      }

      &__content {
        position: relative;

        p {
          font-size: 20px;
          line-height: 49px;
        }

        img {
          max-width: 439px;
        }
      }
    }
  }

  &__lineup-menu {
    position: relative;
    background-color: $blue-of;

    &__layout {
      width: 100%;
      height: auto;
      padding: 70px 0;

      @media (min-width: 768px) {
        padding: 160px 0 106px;
      }

      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 70px;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      max-height: 89px;
      aspect-ratio: 218/89;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        margin-bottom: 0;
      }

      &-logo {
        width: 162px;
        height: auto;
      }

      p {
        color: $black-2b;
        font-family: $font-karu;
        font-size: 20px;
        font-style: normal;
        font-weight: 900 !important;
        line-height: 28px;
        letter-spacing: 1px;
        margin-bottom: 0;
        white-space: nowrap;
      }

      @media (min-width: 1024px) {
        width: fit-content;
        height: auto;
        margin: 0 0 0 20px;
        max-height: none;
        align-items: baseline;
        gap: 33px;

        &-logo {
          width: 276px;
        }

        p {
          font-size: 26px;
          letter-spacing: 1.3px;
        }
      }

      @media (min-width: 1200px) {
        margin: 0 0 0 60px;
      }
    }

    &__title {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 3.19px;

      h3 {
        color: black;
        font-family: $font-genei;
        font-size: 16px;
        font-style: normal;
        font-weight: 900 !important;
        line-height: 19.19px;
        margin: 0;
      }

      img {
        display: block;
        width: 63px;
        height: auto;
        aspect-ratio: 3.94/1;
      }

      @media (min-width: 1296px) {
        gap: 5.89px;

        h3 {
          font-size: 20px;
          line-height: 20px;
        }

        img {
          width: 79px;
        }
      }
    }

    &__character {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__arrow {
      &::before {
        content: '';
        position: absolute;
        width: 29.639px;
        height: 29.639px;
        background-image: url("#{$base-img-url}/lineup_arrow-icon-menu.svg");
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
        bottom: 7.54px;
        right: 7.54px;

        @media (min-width: 1296px) {
          width: 44.638px;
          height: 44.638px;
        }
      }
    }

    ul {
      list-style-type: none !important;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      gap: 8.41px;
      padding: 0;
      margin: 0;

      @media (min-width: 768px) {
        gap: 12.644px;
      }

      @media (min-width: 1200px) {
        margin: 0 60px 0 0;
      }

      li {
        width: 100%;
        max-height: 278px;
        height: auto;
        border: 2px solid black;
        position: relative;

        @media (max-width: 390px) {
          width: 168.651px;
          height: 184.587px;
          aspect-ratio: 168.651 / 184.587;
        }

        @media (min-width: 1024px) {
          cursor: pointer;
          border: 2.678px solid black;
          width: 254px;
          height: 278px;

          &::after,
          &::before,
          & span::after,
          & span::before {
            content: "";
            position: absolute;
            background-color: white;
            transition: all 0.3s ease;
          }

          /* Top-left (horizontal line) */
          &::after {
            width: 0;
            height: 2px;
            top: 0;
            left: 0;
          }

          /* Bottom-right (horizontal line) */
          &::before {
            width: 0;
            height: 2px;
            bottom: 0;
            right: 0;
          }

          /* Bottom-left (vertical line) */
          & span::after {
            width: 2px;
            height: 0;
            bottom: 0;
            left: 0;
          }

          /* Top-right (vertical line) */
          & span::before {
            width: 2px;
            height: 0;
            top: 0;
            right: 0;
          }

          /* Hover effect */
          &:hover {
            border-color: transparent;
          }

          &:hover::after,
          &:hover::before {
            width: 100%;
          }

          &:hover span::after,
          &:hover span::before {
            height: 100%;
          }
        }

        &:nth-child(1) {
          background-color: $pink-ec;

          .header-tom-and-jerry__lineup-menu__title {
            top: 12.28px;
            right: 12.3px;

            h3 {
              margin-left: auto;
            }

            @media (min-width: 1296px) {
              top: 20px;
              right: 20px;
            }
          }

          .header-tom-and-jerry__lineup-menu__character {
            aspect-ratio: 21/20;
            padding: 21.25px 11.53px 22.85px 9.3px;

            @media (min-width: 768px) {
              padding: 32px 17.36px 34.42px 14px;
            }
          }
        }

        &:nth-child(2) {
          background-color: $green-00;

          .header-tom-and-jerry__lineup-menu__title {
            top: 12.28px;
            left: 50%;
            transform: translateX(-50%);

            h3 {
              margin-left: auto;
              margin-right: auto;
              white-space: nowrap;
            }

            img {
              margin: 0 auto;
            }

            @media (min-width: 1296px) {
              top: 20px;
            }
          }

          .header-tom-and-jerry__lineup-menu__character {
            aspect-ratio: 29/20;
            padding: 55.15px 11.38px 27.63px 12.2px;

            @media (min-width: 768px) {
              padding: 83.06px 17.52px 41.61px 18px;
            }
          }
        }

        &:nth-child(3) {
          background-color: $gray-7b;

          .header-tom-and-jerry__lineup-menu__title {
            top: 12.28px;
            left: 50%;
            transform: translateX(-50%);

            h3 {
              margin-left: auto;
              margin-right: auto;
              white-space: nowrap;
            }

            img {
              margin: 0 auto;
            }

            @media (min-width: 1296px) {
              top: 20px;
            }
          }

          .header-tom-and-jerry__lineup-menu__character {
            aspect-ratio: 91/100;
            padding: 58.77px 12.79px 23.86px 13.6px;

            @media (min-width: 768px) {
              padding: 84px 19.74px 40.45px 20px;
            }
          }
        }

        &:nth-child(4) {
          background-color: $yellow-fa;

          .header-tom-and-jerry__lineup-menu__title {
            top: 12.28px;
            left: 50%;
            transform: translateX(-50%);

            h3 {
              margin-left: auto;
              margin-right: auto;
              white-space: nowrap;
            }

            img {
              margin: 0 auto;
            }

            @media (min-width: 1296px) {
              top: 20px;
            }
          }

          .header-tom-and-jerry__lineup-menu__character {
            aspect-ratio: 31/20;
            padding: 61.46px 13.74px 30.84px 12.94px;

            @media (min-width: 768px) {
              padding: 91px 25.2px 41.6px 25px;
            }
          }
        }

        &:last-child {
          max-width: none;
          background-color: $blue-15;
          grid-column: span 2;
          aspect-ratio: 19/11;
          margin: 0 auto;
          display: none; // Remove accessories content

          .header-tom-and-jerry__lineup-menu__title {
            left: 12.46px;
            bottom: 12.2px;

            img {
              width: 124px;
              aspect-ratio: 31/4;
            }

            @media (min-width: 1296px) {
              left: 19.79px;
              bottom: 19.46px;

              img {
                width: 154px;
              }
            }
          }

          .header-tom-and-jerry__lineup-menu__character {
            aspect-ratio: 63/16;
            padding: 17.92px 49.2px 53.46px 50px;

            @media (min-width: 768px) {
              padding: 35px 74.41px 72.5px 93px;
            }
          }
        }
      }
    }


  }
}
