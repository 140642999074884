@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap");
@import url("https://use.typekit.net/ikm7woc.css");
@font-face {
  font-family: "Karu";
  src: url("https://static.lenskart.com/owndays/fonts/Karu/karu-black.woff2") format("woff2"), url("https://static.lenskart.com/owndays/fonts/Karu/karu-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GenEi POPle Pw";
  src: url("https://static.lenskart.com/owndays/fonts/GenEiPOPlePw/GenEiPOPlePw-Bk.woff2") format("woff2"), url("https://static.lenskart.com/owndays/fonts/GenEiPOPlePw/GenEiPOPlePw-Bk.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
.base-tom-and-jerry {
  overflow: hidden;
}
.base-tom-and-jerry__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1192px;
}
.base-tom-and-jerry__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 65px;
  background: black url("https://static.lenskart.com/owndays/img/tomandjerry/btn-arrow.svg") no-repeat right 26px center/8px auto;
  color: white;
  border-radius: 10rem;
  padding-inline: 36px;
  font-size: 15px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.45px;
  -webkit-transition: 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  transition: 0.3s cubic-bezier(0.5, 1, 0.89, 1);
}
.base-tom-and-jerry__btn:hover, .base-tom-and-jerry__btn:focus {
  background-color: black;
  color: white;
}
.base-tom-and-jerry__btn[disabled] {
  background-color: #bdbdbd;
  pointer-events: none;
}
@media (min-width: 768px) {
  .base-tom-and-jerry__btn {
    font-size: 16px;
    letter-spacing: 0.48px;
  }
}
@media (min-width: 1024px) {
  .base-tom-and-jerry__btn:hover, .base-tom-and-jerry__btn:active, .base-tom-and-jerry__btn:visited, .base-tom-and-jerry__btn:focus {
    text-decoration: none;
  }
  .base-tom-and-jerry__btn:hover {
    font-size: 18px;
    letter-spacing: 0.54px;
    background-color: #e1002a;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
