#external-pages {

  h2 {

    .l-gundam__project-number {
      font-size: 200px;

      @media only screen and (max-width: 767px) {
        font-size: 88px;
      }
    }
  }
}