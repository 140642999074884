.search-bar-results {
  display: flex;
  width: 100%;
  padding: 20px 25px;
  border: 1px solid #555555;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
  background-color: #FFFFFF;

  &__trending {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #555555;
    opacity: 0.5;
  }

  &__view-all {
    margin: 20px auto 0;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #555555;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.3;
    }
  }

  &__result {

    &,
    &:visited {
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #555555;
      text-decoration: none;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      color: #555555;
      text-decoration: none;
      opacity: 0.3;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
