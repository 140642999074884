.servicers__nav {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
}

.servicers__nav-link {
  font-family: 'Founders Grotesk', sans-serif;
  line-height: 1;
}

.servicers__nav--mobile {
  margin-top: 20px;
}

.servicers__nav--mobile .servicers__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 0 10px;
  margin: 0;
  border: 1px solid #e3e3e3;
  list-style-type: none;
}

.servicers__nav--mobile .servicers__nav-item:first-child {
  margin-bottom: 10px;
}

.servicers__nav--mobile .servicers__nav-item:first-child .servicers__nav-link {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px dashed #e3e3e3;
}

.servicers__nav--mobile .servicers__nav-item:not(:first-child) .servicers__nav-link::before {
  content: '>';
  margin-right: 5px;
  color: #000;
}

.servicers__nav--mobile .servicers__nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 20px;
  position: relative;
  font-size: 15px;
  font-weight: 400;
  color: #0094c8;
}

.servicers__nav--desktop {
  background-color: #333;
}

.servicers__nav--desktop .servicers__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.servicers__nav--desktop .servicers__nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  border-left: 1px solid #444;
}

.servicers__nav--desktop .servicers__nav-item:last-child {
  border-right: 1px solid #444;
}

.servicers__nav--desktop .servicers__nav-link {
  font-size: 15px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #fff;
  height: 40px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.servicers__nav--desktop .servicers__nav-link.active {
  background-color: #777;
}

.servicers__nav--desktop .servicers__nav-link:hover {
  background-color: #777;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  .servicers__nav--desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .servicers__nav--mobile {
    display: none;
  }
}
