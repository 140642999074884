
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@charset "UTF-8";
.description-policy__container {
  padding: 0 24px;
  margin: 0 auto;
}
@media (min-width: 430px) {
  .description-policy__container {
    padding: 0 40px;
  }
}
@media (min-width: 768px) {
  .description-policy__container {
    padding: 0 100px;
  }
}
@media (min-width: 1024px) {
  .description-policy__container {
    padding: 0 140px;
  }
}
@media (min-width: 1296px) {
  .description-policy__container {
    padding: 0 200px;
  }
}
.description-policy__header {
  padding: 40px 0 24px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
}
.description-policy__header__title, .description-policy__header__subtitle {
  font-style: normal;
  text-align: center;
  margin: 0;
}
.description-policy__header__title {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 28px;
  font-weight: 500 !important;
  line-height: 30.8px;
  color: #000;
}
.description-policy__header__subtitle {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 21.6px;
  color: #666;
  position: relative;
  padding-left: 18px;
}
.description-policy__header__subtitle::before {
  content: '※';
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  color: #666;
  position: absolute;
  left: 0;
  top: -3px;
}
@media (min-width: 768px) {
  .description-policy__header {
    padding: 80px 0 48px 0;
  }
  .description-policy__header__title {
    font-size: 48px;
    line-height: normal;
  }
  .description-policy__header__subtitle {
    font-size: 24px;
    line-height: 32px;
    padding-left: 24px;
  }
  .description-policy__header__subtitle::before {
    font-size: 29px;
  }
}
.description-policy__title, .description-policy__message {
  font-family: "Founders Grotesk", sans-serif;
  font-style: normal;
  color: #000;
}
.description-policy__title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700 !important;
  padding: 16px 0;
  margin: 0;
}
@media (min-width: 768px) {
  .description-policy__title {
    font-size: 24px;
    font-weight: 500 !important;
    padding: 40px 0 16px 0;
  }
}
.description-policy__message {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 32px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .description-policy__message {
    font-size: 20px;
    line-height: 40px;
  }
}
.description-policy__link {
  cursor: pointer;
  color: #0094c8;
}
