.od-add-prescription {
  padding: 32px 216px 48px;

  &__title {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;
  }

  &__subtitle {
    margin: 25px 0 56px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  &__label {
    margin: 40px 0 16px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  &__required-mark {
    color: #D80027;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  &__input {
    width: 100%;
    padding: 16px 25px;
    border: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    background-color: #FAFAFA;

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__usage-options {
    display: flex;
    gap: 16px;
  }

  &__usage-option {
    display: flex;
    padding: 16px 25px;
    flex: 1 0 50%;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    background-color: #FAFAFA;
    cursor: pointer;

    &--active {
      border: 1px solid #000;
    }
  }

  &__upload {
    display: flex;
    height: 159px;
    padding: 32px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    background-color: #FAFAFA;
    cursor: pointer;
  }

  &__upload-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__hints {
    padding-left: 22px;
    margin: 16px 0 0;
    list-style-type: disc;
  }

  &__hint {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.28px;
    list-style-type: disc;
  }

  &__table {
    display: grid;
    border-top: 1px solid #D0D0D0;
    border-left: 1px solid #D0D0D0;
    margin-top: 16px;
    grid-template-columns: repeat(5, auto);
  }

  &__cell {
    padding: 13px;
    border-right: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    text-align: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;

    &--title {
      color: rgba(68, 68, 68, 0.5);
      text-transform: capitalize;
    }
  }

  &__cell-input {
    border: none;
    border-bottom: 1px solid rgba(68, 68, 68, 0.2);
    text-align: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  &__actions {
    display: flex;
    margin-top: 64px;
    justify-content: center;
    gap: 16px;
  }

  &__submit {
    width: 400px;
    padding: 16px 24px;
    text-align: center;
    color: #F7F7F7;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    background-color: #546073;
    backdrop-filter: blur(7px);

    &:hover {
      color: #F7F7F7;
      background-color: #546073;
    }
  }

  &__cancel {
    width: 400px;
    padding: 16px 24px;
    text-align: center;
    color: #444;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    background-color: #FAFAFA;
    backdrop-filter: blur(7px);

    &:hover {
      color: #444;
      background-color: #FAFAFA;
    }
  }
}
