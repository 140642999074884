$base-img-url: 'https://static.lenskart.com/media/owndays/img/kimetsu';

$font-se-jp: 'Noto Serif JP', serif;
$font-sa-jp: 'Noto Sans JP', sans-serif;
$font-founders: 'Founders Grotesk', sans-serif;
$font-neuzeit-bold: 'NeuzeitGroBold';

$gray-d6: #D6D6D6;
$gray-64: #646464;
$green-28: #2B7C62;
$pink-a0: #A0334A;
$orange-ce: #CE5623;
$sky-55: #5585A4;
$blue-28: #283D86;
$purple-45: #452D60;
$red-c7: #C72838;

$_color-green-gd: linear-gradient(#000000, #1C5240);
$_color-pink-gd: linear-gradient(#000000, #561A2B);
$_color-orange-gd: linear-gradient(#000000, #813515);
$_color-sky-gd: linear-gradient(#000000, #013C6F);
$_color-blue-gd: linear-gradient(#000000, #22336F);
$_color-purple-gd: linear-gradient(#000000, #452D60);
$_color-red-gd: linear-gradient(#000000, #711422);

.body-kimetsu {
  &__sec {
    position: relative;
    padding-top: 23px;
    padding-bottom: 45px;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 358px;
    }

    >.base-kimetsu__container {
      >*:not(.body-kimetsu__sec-img):not(.body-kimetsu__sec-subtitle) {
        z-index: 2;
      }
    }

    &-img {
      position: absolute;
      top: -40px;
      max-width: 764px;
      z-index: 1;

      @media (max-width: 767px) {
        max-width: 360px;
      }
    }

    &-number {
      font-family: $font-se-jp;
      font-size: 131px;
      font-weight: 300 !important;
      line-height: 188px;
    }

    &-title {
      display: flex;
      align-items: center;
      position: relative;

      img {
        width: auto;
        height: 82.5px;

        @media (max-width: 1023px) {
          height: 70px;
        }

        @media (max-width: 767px) {
          height: 55px;
        }
      }
    }

    &-subtitle {
      position: relative;
      font-family: $font-se-jp;
      font-size: 50px;
      font-weight: 500;
      color: $gray-d6;
      letter-spacing: 2.5px;
      line-height: 56px;
      margin-bottom: 32px;
    }

    &-badge {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 148px;
      height: 148px;
      text-align: center;
      line-height: 1;
      color: white !important;
      background: url('#{$base-img-url}/bg-owndays.svg') no-repeat center center/contain;

      &-header {
        font-family: $font-sa-jp;
        font-size: 11px;
        font-weight: 800 !important;
        margin-top: 12px;
        margin-bottom: 4px;
      }

      &-body {
        font-family: $font-sa-jp;
        font-size: 8px;
        font-weight: 700 !important;
        letter-spacing: 0.8px;
        margin-bottom: 4px;

        span {
          display: block;
          font-family: $font-sa-jp;
          font-size: 10px;
          font-weight: 700 !important;

          strong {
            font-family: $font-neuzeit-bold;
            font-size: 20px;
            font-weight: normal !important;
            color: white !important;
          }

          small {
            font-family: $font-sa-jp;
            font-size: 60%;
            line-height: 1;
          }
        }
      }

      &-footer {
        font-size: 6px;
      }

      @media (min-width: 1024px) {
        width: 206px;
        height: 206px;

        &-header, &-body {
          line-height: 1.1;
        }

        &-header {
          font-size: 16px;
          margin-top: 10px;
          margin-bottom: 2px;
        }

        &-body {
          font-size: 14px;
          margin-bottom: 0;

          span {
            font-size: 12px;
            margin-top: 5px;

            strong {
              font-size: 18px;
            }

            small {
              font-size: 8px;

              @media (max-width: 1023px) {
                display: none;
              }
            }
          }
        }

        &-footer {
          font-size: 8px;
        }
      }
    }

    &-slider {
      margin-bottom: calc(24px + 27px + 80px) !important;
      padding-left: 15px;
      padding-right: 15px;

      > .slick {
        &-arrow {
          position: absolute;
          top: auto;
          bottom: calc(-27px + -24px);
          width: 14px;
          height: 27px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          transform: translate(0, 0);

          &::before {
            display: none;
          }
        }

        &-prev, &-next {
          background-color: transparent;
          color: transparent;
          border: 0;
        }

        &-prev {
          left: 0;
          background-image: url('#{$base-img-url}/arrow-left-black.svg');
        }

        &-next {
          right: 0;
          background-image: url('#{$base-img-url}/arrow-right-black.svg');
        }

        &-dots {
          position: absolute;
          left: calc(15px + 18px);
          right: calc(15px + 18px);
          bottom: calc((-27px / 2 - 2px) + -24px);
          width: auto;
          display: flex !important;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            flex-basis: 0;
            flex-grow: 1;
            margin: 0;
            height: 4px;

            &.slick-active {
              button {
                opacity: 1;
              }
            }

            button {
              padding: 0;
              width: 100%;
              height: 100%;
              background-color: black;
              opacity: 0.1;
              color: transparent;
              border: 0;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }

    &-des {
      margin-bottom: 126px;
      letter-spacing: 1px;
      line-height: 2;
      color: black;

      @media (max-width: 1023px) {
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        font-family: $font-sa-jp;
        font-size: 13px;
        margin-bottom: 50px;
      }
    }

    &-details {
      position: relative;
      z-index: 2;

      &-price {
        margin-bottom: 15px;
        color: black;

        h5, p {
          margin: 0;
        }

        h5 {
          font-family: $font-se-jp;
          font-size: 24px;
          font-weight: 700 !important;

          small {
            font-size: 14px;
          }
        }

        p {
          font-size: 10px;
        }
      }

      &-btn {
        font-family: $font-se-jp;
        font-size: 13px;
        font-weight: 700 !important;
        padding: 16px;
        display: block;
        text-align: center;
        color: white;
        margin-bottom: 28px;
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
        }

        &::before {
          top: 3px;
          left: 3px;
          right: 3px;
          bottom: 3px;
          border: 1px solid rgba(white, .5);
        }

        &::after {
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          width: 8px;
          height: 14px;
          background: url('#{$base-img-url}/arrow-right.svg') no-repeat center center/contain;
        }

        &-wrap {
          &[disabled] {
            pointer-events: none;
            position: relative;

            &::before {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 2;
              background-color: rgba(128, 128, 128, 0.9);
            }
          }
        }
      }

      dl {
        display: flex;
        font-size: 13px;
        color: $gray-64;
        letter-spacing: 1px;
        margin: 0;

        dt, dd {
          line-height: 2 !important;
        }

        dt {
          min-width: 85px;
          font-weight: 400 !important;
        }

        dd {
          margin: 0;
        }

        @media (max-width: 767px) {
          dt, dd {
            line-height: 1.7 !important;
          }
        }
      }

      @media (min-width: 1024px) {
        &-btn {
          margin-bottom: 0 !important;
        }

        &-right {
          padding-left: 15px;
        }
      }
    }

    @media (min-width: 1024px) {
      &-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &-left {
          width: 540px;
        }
      }

      &-slider,
      &-slider-nav,
      &-des {
        max-width: 540px;
        padding-left: 0;
        padding-right: 0;
      }

      &-subtitle {
        white-space: nowrap;
        position: absolute;
        top: 11.7%;
      }
    }

    @media (min-width: 1024px) {
      &-img {
        top: auto;
        bottom: 50px;
      }
    }

    @media (min-width: 768px) {
      padding-top: 80px;
      padding-bottom: 55px;

      &::after {
        height: 272px;
      }

      &-number {
        font-size: 225px;
        line-height: 280px;
      }

      &-img {
        top: -300px;
      }

      &-slider {
        margin-bottom: calc(40px + 50px + 92px) !important;
        padding-left: 15px;
        padding-right: 15px;

        .slick {
          &-arrow {
            bottom: calc(-50px + -40px);
            width: 26px;
            height: 50px;
          }

          &-dots {
            left: calc(32px + 26px);
            right: calc(32px + 26px);
            bottom: calc((-50px / 2 - 3px) + -40px);

            li {
              button {
                height: 6px;
              }
            }
          }
        }
      }

      &-subtitle {
        font-size: 103px;
        line-height: 1.1;
        letter-spacing: 12px;
      }

      &-des {
        font-size: 14px;
        min-height: 145px;
      }

      &-details {
        &-price {
          h5 {
            font-size: 31px;
            line-height: 1.2;

            small {
              font-size: 12px;
            }
          }
        }

        &-btn {
          margin-bottom: 0;
          font-size: 16px;
          padding: 26px 16px;
          margin-bottom: 28px;

          &::before {
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
          }

          &:after {
            width: 15px;
            height: 28px;
            right: 20px;
          }
        }
      }

      &2,
      &4,
      &6 {

        &-slider,
        &-slider-nav,
        &-des {
          margin-left: auto;
        }

        .body-kimetsu__sec-details-left {
          text-align: right;
          order: 2;
        }

        .body-kimetsu__sec-details-right {
          order: 1;
        }
      }
    }

    @media (max-width: 767px) {
      &-subtitle {
        margin-top: -19px;
      }
    }
  }

  &__sec1 {
    &::before {
      top: 20%;
      background: url('#{$base-img-url}/bg-green-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55.svg') no-repeat center top/cover;
    }

    &-img {
      right: -120px;

      @media (max-width: 1023px) {
        right: -180px;
      }

      @media (max-width: 767px) {
        right: -60px;
      }
    }

    &-title {
      margin-left: -25px;

      @media (max-width: 1023px) {
        margin-left: -60px;
      }

      @media (max-width: 767px) {
        margin-left: -20px;
      }
    }

    &-number {
      color: $green-28;
      margin-right: 22px;

      @media (max-width: 1023px) {
        margin-right: 10px;
      }
    }

    &-subtitle {
      left: 9%;

      @media (max-width: 1023px) {
        left: -6%;
      }

      @media (max-width: 767px) {
        margin-left: 4%;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-green-gd;
      }
    }

    @media (min-width: 1024px) {
      z-index: 3;

      &::before {
        top: 28%;
        background: url('#{$base-img-url}/bg-green-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55@2x.svg') no-repeat center top/cover;
      }
    }
  }

  &__sec2 {
    &::before {
      top: 20%;
      background: url('#{$base-img-url}/bg-pink-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55-2.svg') no-repeat center top/cover;
    }

    &-img {
      left: -120px;

      @media (max-width: 1023px) {
        left: -180px;
      }

      @media (max-width: 767px) {
        left: -60px;
      }
    }

    &-title {
      justify-content: flex-end;
      margin-right: -25px;

      @media (max-width: 1023px) {
        margin-right: -60px;
      }

      @media (max-width: 767px) {
        margin-right: -20px;
      }
    }

    &-subtitle {
      right: 9%;

      @media (max-width: 1023px) {
        right: -6%;
        text-align: right;
      }

      @media (max-width: 767px) {
        margin-right: 4%;
      }
    }

    &-number {
      color: $pink-a0;
      margin-left: 22px;

      @media (max-width: 1023px) {
        margin-left: 10px;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-pink-gd;
      }
    }

    @media (min-width: 768px) {
      z-index: 2;

      &::before {
        top: 26%;
        background: url('#{$base-img-url}/bg-pink-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55-2@2x.svg') no-repeat center top/cover;
      }
    }
  }

  &__sec3 {
    &::before {
      top: 21%;
      background: url('#{$base-img-url}/bg-orange-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55.svg') no-repeat center top/cover;
    }

    &-img {
      right: -120px;

      @media (max-width: 1023px) {
        right: -180px;
      }

      @media (max-width: 767px) {
        right: -60px;
      }
    }

    &-title {
      margin-left: -25px;

      @media (max-width: 1023px) {
        margin-left: -60px;
      }

      @media (max-width: 767px) {
        margin-left: -20px;
      }
    }

    &-subtitle {
      left: 11.5%;

      @media (max-width: 1023px) {
        text-align: left;
        left: -6%;
      }

      @media (max-width: 767px) {
        margin-left: 4%;
      }
    }

    &-number {
      color: $orange-ce;
      margin-right: 22px;

      @media (max-width: 1023px) {
        margin-right: 10px;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-orange-gd;
      }
    }

    @media (min-width: 768px) {
      z-index: 1;

      &::before {
        top: 27%;
        background: url('#{$base-img-url}/bg-orange-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55@2x.svg') no-repeat center top/cover;
      }
    }
  }

  &__sec4 {
    &::before {
      top: 20.5%;
      background: url('#{$base-img-url}/bg-sky-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55-2.svg') no-repeat center top/cover;
    }

    &-img {
      left: -120px;

      @media (max-width: 767px) {
        left: -8%;
      }
    }

    &-title {
      justify-content: flex-end;
      margin-right: -25px;

      @media (max-width: 1023px) {
        margin-right: -60px;
      }

      @media (max-width: 767px) {
        margin-right: -20px;
      }
    }

    &-subtitle {
      right: 9%;

      @media (max-width: 1023px) {
        text-align: right;
        right: -6%;
      }

      @media (max-width: 767px) {
        margin-right: 4%;
      }
    }

    &-number {
      color: $sky-55;
      margin-left: 22px;

      @media (max-width: 1023px) {
        margin-left: 10px;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-sky-gd;
      }
    }

    @media (min-width: 768px) {
      z-index: 0;

      &::before {
        top: 27%;
        background: url('#{$base-img-url}/bg-sky-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55-2@2x.svg') no-repeat center top/cover;
      }
    }
  }

  &__sec5 {
    &::before {
      top: 21%;
      background: url('#{$base-img-url}/bg-blue-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55.svg') no-repeat center top/cover;
    }

    &-img {
      right: -120px;
      bottom: 40px;
      top: -160px;

      @media (max-width: 1023px) {
        top: -170px;
      }

      @media (max-width: 767px) {
        max-width: 350px;
        top: 37px;
        right: -60px;
      }
    }

    &-title {
      margin-left: -25px;

      @media (max-width: 1023px) {
        margin-left: -60px;
      }

      @media (max-width: 767px) {
        margin-left: -20px;
      }
    }

    &-subtitle {
      left: 9%;

      @media (max-width: 1023px) {
        text-align: left;
        left: -6%;
      }

      @media (max-width: 767px) {
        margin-left: 4%;
      }
    }

    &-number {
      color: $blue-28;
      margin-right: 22px;

      @media (max-width: 1023px) {
        margin-right: 10px;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-blue-gd;
      }
    }

    @media (min-width: 768px) {
      &::before {
        top: 28%;
        background: url('#{$base-img-url}/bg-blue-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55@2x.svg') no-repeat center top/cover;
      }
    }
  }

  &__sec6 {
    &::before {
      top: 20.5%;
      background: url('#{$base-img-url}/bg-purple-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55-2.svg') no-repeat center top/cover;
    }

    &-img {
      left: -120px;

      @media (max-width: 1023px) {
        left: -180px;
      }

      @media (max-width: 767px) {
        left: -16%;
      }
    }

    &-title {
      justify-content: flex-end;
      margin-right: -25px;

      @media (max-width: 1023px) {
        margin-right: -60px;
      }

      @media (max-width: 767px) {
        margin-right: -20px;
      }
    }

    &-subtitle {
      right: 9%;

      @media (max-width: 1023px) {
        text-align: right;
        right: -6%;
      }

      @media (max-width: 767px) {
        margin-right: 4%;
      }
    }

    &-number {
      color: $purple-45;
      margin-left: 22px;

      @media (max-width: 1023px) {
        margin-left: 10px;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-purple-gd;
      }
    }

    @media (min-width: 768px) {
      &::before {
        top: 27%;
        background: url('#{$base-img-url}/bg-purple-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55-2@2x.svg') no-repeat center top/cover;
      }
    }
  }

  &__sec7 {
    &::before {
      top: 21%;
      background: url('#{$base-img-url}/bg-red-50.svg') no-repeat center top/100% auto;
    }

    &::after {
      background: url('#{$base-img-url}/bg-white-55.svg') no-repeat center top/cover;
    }

    &-img {
      right: -30%;
      top: -18%;

      @media (max-width: 767px) {
        max-width: 350px;
        top: 0px;
        right: -90px;
      }

      @media (min-width: 1024px) {
        top: -22rem;
        right: -10rem;
      }
    }

    &-title {
      margin-left: -25px;

      @media (max-width: 1023px) {
        margin-left: -60px;
      }

      @media (max-width: 767px) {
        margin-left: -20px;
      }
    }

    &-subtitle {
      left: 9%;
      margin-bottom: 75px;

      @media (max-width: 1023px) {
        text-align: left;
        left: -6%;
      }

      @media (max-width: 767px) {
        margin-left: 4%;
      }
    }

    &-badge {
      top: 16%;
      left: 5%;

      @media (min-width: 1024px) {
        top: -5rem;
        left: 30rem;
      }

      @media (min-width: 1296px) {
        top: 1rem;
        left: 41rem;
      }
    }

    &-number {
      color: $red-c7;
      margin-right: 22px;

      @media (max-width: 1023px) {
        margin-right: 10px;
      }
    }

    &-details {
      &-btn {
        background-image: $_color-red-gd;
      }
    }

    @media (min-width: 768px) {
      &::before {
        top: 28%;
        background: url('#{$base-img-url}/bg-red-50@2x.svg') no-repeat center top/100% auto;
      }

      &::after {
        background: url('#{$base-img-url}/bg-white-55@2x.svg') no-repeat center top/cover;
      }
    }
  }
}
