$base-img-url: 'https://static.lenskart.com/owndays/img/sun';

$font-founders: 'Founders Grotesk', sans-serif;

$white-d9: #d9d9d9;
$white-f7: #f7f6f2;
$gray-99: #999;

.header-sun {
  &__main {
    position: relative;

    .base-sun__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__bg {
      img {
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: 425 / 654;
      }
    }

    &__text {
      position: absolute;
      inset: 0;
      padding-block: 50px 70px;
      font-family: $font-founders;

      h1, h2 {
        margin: 0;
      }

      h1 {
        font: 0 / 0 a;

        img {
          display: block;
          max-width: 120px;
          margin: 0 auto;
        }
      }

      h2 {
        color: white;
        font-size: 36px;
        font-weight: 600 !important;
        line-height: 61.2px;
        letter-spacing: 1.08px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translate(-50%, 50%);
      transform: translate(-50%, 50%);
      width: 1px;
      height: 60px;
      background-color: $white-d9;
      z-index: 2;
    }

    @media (min-width: 768px) {
      &__bg {
        img {
          aspect-ratio: 2 / 1;
        }
      }

      &__text {
        h2 {
          line-height: 1.2;
        }
      }
    }

    @media (min-width: 1024px) {
      &__bg {
        img {
          min-height: 720px;
          object-fit: cover;
        }
      }

      &__text {
        padding-block: 76px 120px;

        img {
          max-width: 192px;
        }

        h2 {
          font-size: 40px;
          letter-spacing: 1.2px;
          padding-left: 20px;
        }
      }

      &::after {
        height: 80px;
      }
    }

    @media (min-width: 1124px) {
      &__text {
        h2 {
          br {
            display: none;
          }
        }

        h1 {
          img {
            max-width: 170px;
          }
        }
      }
    }
  }

  &__about {
    position: relative;

    &__bg {
      position: relative;
      display: flex;
      flex-direction: column;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: radial-gradient(352.03% 121.7% at 50% 7.5%, rgba(0, 0, 0, .4) 0, hsla(0, 0%, 85%, .3) 100%);
      }

      video {
        display: inline-block;
        width: 100%;
        height: 524px;
        object-fit: cover;
      }
    }

    &__text {
      position: absolute;
      inset: auto 0 32px 0;
      color: white;
      
      p {
        font-family: $font-founders;
        font-size: 14px;
        font-weight: 500 !important;
        margin: 0;
      }
    }

    &__play {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      font: 0 / 0 a;
      background: url("#{$base-img-url}/icon_play.svg") no-repeat 50% / 80px auto;
      transition: all .3s ease;
    }

    @media (min-width: 1024px) {
      &__bg {
        video {
          height: 580px;
        }
      }

      &__text {
        inset: 0;
        display: flex;
        align-items: center;
        padding-left: 20px;

        p {
          font-size: 16px;
          letter-spacing: 0.48px;
          line-height: 32px;
          max-width: calc(50% - 147px);
        }
      }
    }
  }

  &__check {
    &__ribbon {
      display: table;
      margin: 0 auto;
      position: absolute;
      top: -9px;
      left: 0;
      right: 0;

      @media (max-width: 768px) {
        width: 98px;
      }

      &-text {
        position: absolute;
        top: 0;
        left: -5px;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-founders;
        font-weight: 700 !important;
        font-size: 14px;
        letter-spacing: 1.54px;
        padding-bottom: 6px;
        color: black;

        span {
          letter-spacing: 2.8px;
        }
      }
    }

    &__inner {
      border-radius: 20px;
      border: 2px solid $white-f7;
      padding: 50px 20px 19px;
      margin-top: 60px;
      position: relative;
    }

    &__title {
      font-family: $font-founders;
      font-size: 18px;
      font-weight: 600 !important;
      line-height: 28px;
      letter-spacing: 0.72px;
      text-align: center;
      color: black;
    }

    &-bg-svg {
      background: transparent !important;
    }

    p {
      font-family: $font-founders;
      font-size: 14px;
      font-weight: 500 !important;
      line-height: 28px;
      margin: 15px 0 0 0;
      letter-spacing: 0.42px;
      color: black;
    }

    @media (min-width: 768px) {
      &__ribbon {
        left: auto;
        right: 60px;
      }
      &__title {
        text-align: left;

        br {
          display: none;
        }
      }
    }

    @media (min-width: 1024px) {
      &__ribbon {
        svg {
          width: 113px;
          height: 45px;
        }
        &-text {
          font-size: 16px;
          letter-spacing: 1.76px;
        }
      }

      &__inner {
        padding: 44px 50px;
        margin-top: 95px;
        width: 100%;
        max-width: 1080px;
        margin-inline: auto;
      }

      &__title {
        font-size: 22px;
        letter-spacing: 1.32px;
      }

      p {
        margin-top: 18px;
        letter-spacing: 0.42px;
      }
    }
  }

  &__navs {
    position: relative;
    padding-block: 64px 24px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $font-founders;
        font-size: 16px;
        font-weight: 600 !important;
        letter-spacing: 0.96px;
        line-height: normal;
        color: black;
        transition: all .3s ease;
        background-color: $white-f7;
        height: 52px;
        padding-inline:20px;
        border-radius: 5px;
        z-index: 1;
        
        &::after {
          content: "";
          width: 28px;
          height: 28px;
          border-radius: 10rem;
          border: 1px solid black;
          background: black url("#{$base-img-url}/icon-arrow-down.svg") no-repeat 50% / 8px auto;
          transition: all .3s ease;
        }
      }
    }

    @media (min-width: 768px) {
      ul {
        flex-direction: row;
        background-color: $white-f7;
        height: 76px;
        border-radius: 10px;

        li {
          flex: 0 1 100%;
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;

          &:not(:last-child):before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 48px;
            background-color: rgba(0, 0, 0, .2);
            transform: translateY(-50%);
          }

          a {
            padding: 20px;
            gap: 28px;

            &:hover {
              color: $gray-99;
              text-decoration: none;

              &::after {
                background-color: white;
                background-image: url("#{$base-img-url}/icon-arrow-black.svg");
              }
            }

          }
        }
      }
    }

    @media (min-width: 1024px) {
      padding-block: 90px 100px;

      ul a {
        font-size: 16px;
        letter-spacing: .96px;
      }
    }
  }
}
