$font-founders: 'Founders Grotesk', sans-serif;

$red: #BF0000;
$black: #000;
$black28: #283750;
$white: #fff;
$whited7: #D7DCE2;
$whiteee: #EEF0F2;

.od-footer-owndays-progressive-cp {
  $baseImgUrl: 'https://static.lenskart.com/media/owndays/img/progressive-cp';
  
  &__container {
    &__free-enhanced-header {
      width: 100%;
      height: 70px;
      background-color: $red;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 150px;

      @media (max-width: 1196px) {
        height: auto;
        padding: 0 120px;
      }

      @media (max-width: 802px) {
        padding: 0 60px;
      }

      @media (max-width: 687px) {
        padding: 0 40px;
      }

      @media (max-width: 641px) {
        height: 83px;
        margin-top: 100px;
      }

      @media (max-width: 455px) {
        padding: 0 20px;
      }

      @media (max-width: 414px) {
        padding: 0;
      }
      
    }

    &__free-enhanced-body-box {
      width: 100%;
      height: auto;
      background-color: $whited7;
    }

    &__free-enhanced-body {
      width: 100%;
      max-width: 1440px;
      height: auto;
      display: flex;
      margin: 0 auto;
      position: relative;
    }

    &__book-online-btn {
      background-color: $whiteee;
      padding: 150px 0;

      @media (max-width: 550px) {
        padding: 100px 20px;
      }
    }

    &__term-and-con-modal-box {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      z-index: 9999;
      background-color: rgba(0, 0, 0, 0.30);
    }

    &__term-and-con-modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 900px;
      height: auto;
      min-height: 629px;
      background-color: $white;

      @media (max-width: 1024px) {
        width: calc(100% - 120px);
        height: calc(100% - 120px);
      }

      @media (max-width: 768px) {
        width: calc(100% - 60px);
        height:  calc(100% - 120px);
      }

      @media (max-width: 550px) {
        width: calc(100% - 40px);
        height: calc(100% - 40px)
      }
    }
  }

  .free-enhanced-header {
    color: $white;
    font-family: $font-founders;
    font-size: 30px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 46px;
    letter-spacing: 3px;
    text-align: center;
    margin: 0;

    br {
      display: none;
    }

    @media (max-width: 1201px) {
      br {
        display: block;
      }
    }

    @media (max-width: 641px) {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 2px;
    }
  }

  .free-enhanced-body {
    &__desc-box {
      display: flex;
      flex-direction: column;
      width: 552px;
      height: auto;
      margin-left: 120px;
      padding: 50px 0 45px 0;

      p {
        color: $black;
        font-family: $font-founders;
        font-size: 18px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 32px;
        letter-spacing: 0.9px;
        margin-bottom: 0;

        &:nth-child(2) {
          margin-top: 20px;
        }

        @media (max-width: 430px) {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.8px;
        }
      }

      @media (max-width: 1399px) {
        padding: 62% 0 50px 0;
        width: calc(100% - 120px);
        margin: 0 auto;
      }

      @media (max-width: 430px) {
        padding: 67% 0 35px 0;
        width: calc(100% - 80px);
      }
    }

    &__remark-box {
      margin-top: 48px;
      display: flex;
      flex-direction: column;

      &__text {
        color: $black;
        font-family: $font-founders;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 24px;
        letter-spacing: 0.7px;
      }

      &__link-open-modal {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 12px;
        position: relative;
        cursor: pointer;
        
        &::before {
          position: absolute;
          bottom: 2px;
          content: '';
          width: 97px;
          border-bottom: 1px solid $black;
        }
        
        img {
          width: 10px;
          height: 10px;
        }
      }

      @media (max-width: 430px) {
        margin-top: 28px;

        &__text {
          font-size: 12px;
          letter-spacing: 0.6px;
        }

        &__link-open-modal {
          gap: 4px;
          margin-top: 0;

          &::before {
            width: 83px;
          }
        }
      }
    }


    &__img {
      width: 730px;
      height: auto;
      position: absolute;
      right: 0;
      top: 33px;

      @media (max-width: 1399px) {
        width: calc(100% - 22px);
        top: 30px;
      }

      @media (max-width: 768px) {
        width: calc(100% - 40px);
      }

      @media (max-width: 430px) {
        width: calc(100% - 30px);
      }
    }
  }

  .book-online-btn {
    width: 500px;
    height: 80px;
    border-radius: 40px;
    background-color: $black28;
    padding: 29px 20px 28px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    border: 3px solid $black28;
    transition: 0.5s;

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    @media (min-width: 768px) {
      &:hover {
        text-decoration: none;
        background-color: $white;
        border: 3px solid $black28;

        span {
          color: $black28;
        }

        img {
          content: url("#{$baseImgUrl}/arrow-right-circle-blue-icon.svg");
        }
      }
    }

    span {
      color: $white;
      font-family: $font-founders;
      font-size: 20px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 32px;
      letter-spacing: 2px;
    }

    img {
      width: 50px;
      height: 50px;
    }

    @media (max-width: 550px) {
      width: 350px;
      height: 60px;
      padding: 25px 10px 25px 40px;

      span {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.8px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .term-and-con-modal {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__close-icon {
      position: absolute;
      top: 32px;
      right: 25px;
      width: 39px;
      height: 39px;
      cursor: pointer;

      @media (max-width: 550px) {
        top: 10px;
        right: 10px;
        width: 29px;
        height: 29px;
      }
    }

    h2 {
      margin: 0;
      color: $black;
      text-align: center;
      font-family: $font-founders;
      font-size: 20px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 32px;
      letter-spacing: 2px;
      padding: 50px 0 32px 0px;

      @media (max-width: 550px) {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 2px;
      }
    }

    ul {
      width: 700px;
      height: auto;
      padding-bottom: 36px;
      list-style-type: none;

      li {
        font-family: $font-founders;
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 36px;
        letter-spacing: 0.1px;
        margin-left: -21px;
        position: relative;

        &::before {
          position: absolute;
          top: 18px;
          left: -18px;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $black;
        }
      }

      @media (max-width: 1024px) {
        width: calc(100% - 120px);
        height: 80%;
        overflow-y: scroll;
      }

      @media (max-width: 550px) {
        width: calc(100% - 60px);
        height: 100%;

        li {
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 0.8px;

          &::before {
            top: 15px;
          }
        }
      }
    }

    @media (max-width: 550px) {
      height: 100%;
    }
  }
}
