@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.contact-category--clear-contactlens h2::before {
  background-color: #59b2e7;
}

.contact-category--clear-contactlens .contact-type__header-img span,
.contact-category--clear-contactlens .contact-type__features ul li h3 strong {
  color: #59b2e7;
}

.contact-category--color-contactlens h2::before {
  background-color: #71475e;
}

.contact-category--color-contactlens .contact-type__header {
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-category--color-contactlens .contact-type__header svg {
  display: none;
}

.contact-category--color-contactlens .contact-type__header-img span,
.contact-category--color-contactlens .contact-type__features ul li h3 strong {
  color: #71475e;
}

.contact-category--color-contactlens .contact-type__header-img img {
  max-width: 440px;
  max-height: 335px;
}

@media (max-width: 767px) {
  .contact-category--color-contactlens .contact-type__header-logo h1 img {
    max-width: 150px;
  }
  .contact-category--color-contactlens .contact-type__header-img {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .contact-category--color-contactlens .contact-type__header-img img {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .contact-category--clear-contactlens .contact-type__header-grid {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  .contact-category--clear-contactlens .contact-type__header-logo, .contact-category--clear-contactlens .contact-type__header-img {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
            flex: 1 0 0;
  }
}

@media (min-width: 1024px) {
  .contact-category--color-contactlens .contact-type__header {
    padding-block: 224px;
  }
}

.contact-type {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.8px;
  width: 100%;
  overflow-x: hidden;
}

.contact-type img {
  display: block;
  width: 100%;
}

.contact-type h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
  letter-spacing: 0.01rem;
  position: relative;
  margin: 0 0 60px;
  padding-bottom: 60px;
}

.contact-type h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 60px;
  height: 4px;
}

.contact-type__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1192px;
}

.contact-type__container--lg {
  max-width: 1340px;
}

.contact-type__container--sm {
  max-width: 940px;
}

.contact-type__header {
  position: relative;
  padding-block: 64px;
  background: url("https://static1.lenskart.com/media/owndays/img/contacts/bg-header.webp") repeat center/auto;
}

.contact-type__header svg {
  scale: -1;
  min-height: 50px;
  position: absolute;
  bottom: 0;
}

.contact-type__header-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}

.contact-type__header-logo {
  text-align: center;
}

.contact-type__header-logo h1 {
  font: 0/0 a;
  margin: 0;
}

.contact-type__header-logo h1 img {
  max-width: 220px;
  margin-inline: auto;
}

.contact-type__header-logo h3 {
  font-size: inherit;
  line-height: inherit;
  margin: 15px 0 0;
}

.contact-type__header-logo p {
  margin: 20px auto 0;
  background-color: #dadcd9;
  font-size: 13px;
  line-height: 1;
  padding: 12px 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.contact-type__header-logo p::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 100%;
  height: 100%;
  border: 1px solid;
}

.contact-type__header-img {
  position: relative;
}

.contact-type__header-img img {
  margin-inline: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.contact-type__header-img > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 48px;
  right: 48px;
  width: 74px;
  height: 74px;
  background-color: white;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  z-index: 1;
  -webkit-animation: pulse 4s infinite both;
          animation: pulse 4s infinite both;
}

.contact-type__header-img > span::before, .contact-type__header-img > span::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 74px;
  height: 74px;
  background-color: white;
  border-radius: 4px;
  z-index: -1;
}

.contact-type__header-img > span::before {
  rotate: 65deg;
}

.contact-type__header-img > span::after {
  rotate: -65deg;
}

.contact-type__header-img > span > span::before, .contact-type__header-img > span > span::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 74px;
  height: 74px;
  background-color: white;
  border-radius: 4px;
  z-index: -1;
}

.contact-type__header-img > span > span::before {
  rotate: 25deg;
}

.contact-type__header-img > span > span::after {
  rotate: 45deg;
}

.contact-type__features {
  margin-block: 80px;
}

.contact-type__features ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 24px 48px;
}

.contact-type__features ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 332px;
}

.contact-type__features ul li img {
  max-width: 240px;
  margin-inline: auto;
  opacity: 0.3;
}

.contact-type__features ul li h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 0 0 8px;
}

.contact-type__features ul li h3 strong {
  opacity: 0.5;
  font-family: inherit;
  font-size: 72px;
  font-weight: 600;
}

.contact-type__lineup {
  margin-block: 80px;
}

.contact-type__lineup-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 30px 15px;
}

.contact-type__lineup-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 160px;
}

.contact-type__lineup-list li .lens {
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="3" /><feOffset dx="3" dy="3" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.3)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  -webkit-filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .3));
          filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .3));
}

.contact-type__lineup-list li .name {
  line-height: 1.5;
  margin: 10px 0 20px;
}

.contact-type__lineup-list li .image {
  margin-top: auto;
  position: relative;
}

.contact-type__lineup-list li .image span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 10rem;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: -8px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
}

.contact-type__spec {
  background-color: #f4f4f4;
  padding-block: 80px;
}

.contact-type__spec-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
}

.contact-type__spec-list li {
  display: grid;
  padding-block: 20px;
  border-bottom: 1px solid black;
}

.contact-type__spec-list li:first-child {
  border-top: 1px solid black;
}

.contact-type__spec-list li h3,
.contact-type__spec-list li p {
  margin: 0;
}

.contact-type__spec-list li h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: inherit;
}

.contact-type__spec-remark {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  color: #999;
  font-size: 11px;
}

.contact-type__shops {
  margin-block: 80px;
}

.contact-type__shops-list {
  list-style: none;
  padding: 30px 0;
  margin: 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: grid;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.contact-type__shops-list li {
  position: relative;
  padding-left: 1.5em;
}

.contact-type__shops-list li::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 8px;
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 16px;
  border-radius: 1rem;
}

.contact-type__shops-list li a {
  color: black;
  text-decoration: none;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.contact-type__shops-list li a:hover {
  opacity: 0.5;
}

.contact-type--daily-blue h2::before {
  background-color: #26558d;
}

.contact-type--daily-blue .contact-type__header-img span li h3 strong,
.contact-type--daily-blue .contact-type__features ul li h3 strong {
  color: #26558d;
}

.contact-type--daily-blue .contact-type__features ul {
  max-width: 1000px;
  margin-inline: auto;
}

.contact-type--natural h2::before, .contact-type--natural .contact-type__lineup-list li .image span {
  background-color: #b28247;
}

.contact-type--natural .contact-type__header-img span li h3 strong,
.contact-type--natural .contact-type__features ul li h3 strong {
  color: #b28247;
}

.contact-type--natural .contact-type__header-logo p::before {
  border-color: #b28247;
}

.contact-type--horoscope h2::before, .contact-type--horoscope .contact-type__lineup-list li .image span {
  background-color: #324675;
}

.contact-type--horoscope .contact-type__header-img span li h3 strong,
.contact-type--horoscope .contact-type__features ul li h3 strong {
  color: #324675;
}

.contact-type--horoscope .contact-type__header-logo h3 {
  color: white;
}

.contact-type--horoscope .contact-type__header-logo p::before {
  border-color: #999;
}

.contact-type--petal h2::before, .contact-type--petal .contact-type__lineup-list li .image span {
  background-color: #9aced6;
}

.contact-type--petal .contact-type__header-img span li h3 strong,
.contact-type--petal .contact-type__features ul li h3 strong {
  color: #9aced6;
}

.contact-type--petal .contact-type__header-logo p::before {
  border-color: #9aced6;
}

.contact-type--velvet h2::before, .contact-type--velvet .contact-type__lineup-list li .image span {
  background-color: #7198cf;
}

.contact-type--velvet .contact-type__header-img span li h3 strong,
.contact-type--velvet .contact-type__features ul li h3 strong {
  color: #7198cf;
}

.contact-type--velvet .contact-type__header-logo p::before {
  border-color: #7198cf;
}

@media (min-width: 768px) {
  .contact-type__header-grid {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 80px;
  }
  .contact-type__header-img > span {
    top: 25%;
    left: -24px;
    right: auto;
  }
  .contact-type__lineup-list li {
    max-width: 200px;
  }
  .contact-type__spec-list li {
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    padding-inline: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .contact-type__shops-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .contact-type h2 {
    font-size: 50px;
  }
  .contact-type__header-img > span {
    left: -48px;
    width: 90px;
    height: 90px;
  }
  .contact-type__header-img > span::before, .contact-type__header-img > span::after {
    width: 90px;
    height: 90px;
  }
  .contact-type__header-img > span > span::before, .contact-type__header-img > span > span::after {
    width: 90px;
    height: 90px;
  }
  .contact-type__features, .contact-type__lineup, .contact-type__shops {
    margin-block: 120px;
  }
  .contact-type__lineup-list {
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
  }
  .contact-type__lineup-list li {
    max-width: 276px;
  }
  .contact-type__lineup-list li .name {
    font-size: 24px;
  }
  .contact-type__lineup-list li .image span {
    width: 70px;
    height: 70px;
    font-size: 15px;
  }
  .contact-type__spec {
    padding-block: 120px;
  }
  .contact-type__spec-list li {
    grid-template-columns: 270px 300px;
  }
}
