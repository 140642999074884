.information {
  font-family: 'Founders Grotesk', sans-serif;
  line-height: 1.5;
  letter-spacing: 1px;
  padding-block: 50px;

  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1020px + (20px * 2));
  }
  &__title {
    text-align: center;
    margin: 0 0 40px;
    font-size: 20px;
    font-weight: 400;
    color: black;
  }
  &__genre {
    list-style: none;
    padding: 0;
    margin: 0 0 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    &-btn {
      background: none;
      border: none;
      outline: none;
      padding: 5px;
      line-height: 1;
      letter-spacing: 1px;
      position: relative;
      color: black;
      &::before {
        content: '';
        position: absolute;
        left: auto;
        bottom: 0;
        right: 0;
        width: 0;
        height: 1px;
        background-color: black;
        transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &:hover {
        &::before {
          left: 0;
          width: 100%;
          right: auto;
        }
      }
      &.active {
        &::before {
          width: 100%;
        }
      }
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 30px;
  }
  &__card {
    display: grid;
    color: black;
    &-cover {
      aspect-ratio: 7/4;
      transition: opacity 0.3s ease;
    }
    &-date {
      font-size: 12px;
      margin: 0 0 6px;
      color: #666;
    }
    &-title {
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      transition: color 0.3s ease;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit;
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 5px;
    &-btn {
      height: 32px;
      min-width: 32px;
      padding-inline: 5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: black;
      border-radius: 3px;
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 14px;
      transition: all 0.3s ease;
      &:hover,
      &:focus {
        color: black;
        background-color: transparent;
      }
      &.active {
        background-color: black;
        color: white;
      }
    }
  }
  @media (max-width: 767px) {
    &__card {
      grid-template-columns: 3fr 7fr;
      gap: 15px;
    }
  }
  @media (min-width: 768px) {
    padding-block: 90px;
    &__title {
      font-size: 24px;
      margin-bottom: 80px;
    }
    &__genre {
      margin-bottom: 70px;
      gap: 25px;
      &-btn {
        font-size: 14px;
      }
    }
    &__list {
      grid-template-columns: repeat(3, 1fr);
      gap: 50px 20px;
    }
    &__card {
      gap: 15px;
      &-date {
        margin-bottom: 3px;
      }
      &-title {
        font-size: 14px;
        line-height: 1.7;
      }
      &:hover {
        .information__card {
          &-cover {
            opacity: 0.6;
          }
          &-title {
            color: #999;
          }
        }
      }
    }
    &__pagination {
      &-btn {
        &:not(.active):hover {
          background-color: #e9ecef;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &__title {
      font-size: 30px;
    }
  }
}
