.od-add-prescription {
  padding: 32px 216px 48px;
}

.od-add-prescription__title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;
}

.od-add-prescription__subtitle {
  margin: 25px 0 56px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.od-add-prescription__label {
  margin: 40px 0 16px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.od-add-prescription__required-mark {
  color: #D80027;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.od-add-prescription__input {
  width: 100%;
  padding: 16px 25px;
  border: none;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  background-color: #FAFAFA;
}

.od-add-prescription__input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, .3);
}

.od-add-prescription__input::-moz-placeholder {
  color: rgba(0, 0, 0, .3);
}

.od-add-prescription__input::-ms-input-placeholder {
  color: rgba(0, 0, 0, .3);
}

.od-add-prescription__input::placeholder {
  color: rgba(0, 0, 0, .3);
}

.od-add-prescription__usage-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}

.od-add-prescription__usage-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 25px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 50%;
          flex: 1 0 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  background-color: #FAFAFA;
  cursor: pointer;
}

.od-add-prescription__usage-option--active {
  border: 1px solid #000;
}

.od-add-prescription__upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 159px;
  padding: 32px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  background-color: #FAFAFA;
  cursor: pointer;
}

.od-add-prescription__upload-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 16px;
}

.od-add-prescription__hints {
  padding-left: 22px;
  margin: 16px 0 0;
  list-style-type: disc;
}

.od-add-prescription__hint {
  color: rgba(0, 0, 0, .5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  list-style-type: disc;
}

.od-add-prescription__table {
  display: grid;
  border-top: 1px solid #D0D0D0;
  border-left: 1px solid #D0D0D0;
  margin-top: 16px;
  grid-template-columns: repeat(5, auto);
}

.od-add-prescription__cell {
  padding: 13px;
  border-right: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
}

.od-add-prescription__cell--title {
  color: rgba(68, 68, 68, .5);
  text-transform: capitalize;
}

.od-add-prescription__cell-input {
  border: none;
  border-bottom: 1px solid rgba(68, 68, 68, .2);
  text-align: center;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
}

.od-add-prescription__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 64px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 16px;
}

.od-add-prescription__submit {
  width: 400px;
  padding: 16px 24px;
  text-align: center;
  color: #F7F7F7;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  background-color: #546073;
  backdrop-filter: blur(7px);
}

.od-add-prescription__submit:hover {
  color: #F7F7F7;
  background-color: #546073;
}

.od-add-prescription__cancel {
  width: 400px;
  padding: 16px 24px;
  text-align: center;
  color: #444;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  background-color: #FAFAFA;
  backdrop-filter: blur(7px);
}

.od-add-prescription__cancel:hover {
  color: #444;
  background-color: #FAFAFA;
}
