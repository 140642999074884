.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb.breadcrumb--2 {
  background-color: #fff;
  border-top: 1px solid rgba(84, 96, 115, .1);
  border-bottom: 1px solid rgba(84, 96, 115, .1);
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.02em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
}

.breadcrumb.breadcrumb--2 > li + li::before {
  content: '>';
  padding: 0 15px;
}

.breadcrumb-list {
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0.3;
  font-family: "Founders Grotesk", sans-serif;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000;
}

.breadcrumb-item a:hover {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #000;
  opacity: 1;
}

@media (min-width: 1024px) {
  .breadcrumb.breadcrumb--2 {
    padding: 10px 50px;
  }
}

.breadcrumb.breadcrumb--2 > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
