$black: #000000;
$white: #ffffff;
$light: #f9f9f9;

$font-neuzeitgro-reg: 'NeuzeitGroReg';

.header-owndays-pc {
  &__container {
    &__banner {
      background-color: $light;
      padding: 100px 0 255px;
      position: relative;
    }

    &__nav {
      text-align: center;
      padding: 100px 0;
    }
  }

  &__logo {
    margin: 0 0 50px 0;

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 617px;
    }
  }

  &__heading {
    font-family: $font-neuzeitgro-reg;
    font-size: 18px;
    font-weight: normal !important;
    letter-spacing: 0.1em;
    line-height: 1.6;
    margin: 0 0 44px 0;
  }

  &__tag {
    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 140px;
    }
  }

  &__product {
    position: absolute;
    left: calc(50% - 169px);
    right: -161px;
    bottom: 30px;
    pointer-events: none;

    img {
      display: block;
    }
  }

  &__nav-title {
    font-family: $font-neuzeitgro-reg;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 1.375;
    
    &--long {
      margin: 0 0 20px 0;
    }

    &--short {
      margin: 0 0 40px 0;
    }
  }

  &___nav-list {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 60px 120px;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    .slick-current {
      z-index: 1;
    }
  }

  &__nav-item {
    img {
      display: block;
      width: 100%;
      max-width: 500px;
      height: auto;
      margin-bottom: 72px;
    }
  }

  @media (min-width: 768px) {
    &__container {
      &__banner {
        padding: 267px 0 155px;
      }
  
      &__nav {
        padding-bottom: 180px;
      }
    }

    &__logo {
      max-width: 50%;
    }

    &__heading {
      br {
        display: none;
      }
    }

    &__tag {
      img {
        max-width: 160px;
      }
    }

    &__product {
      top: 45%;
      left: 50%;
      right: -255px;
      bottom: auto;
      transform: translateY(-50%);

      img {
        width: 100%;
      }
    }

    &__nav-title {
      font-size: 20px;
      letter-spacing: 1px;

      &__hide-new-line {
        display: none;
      }
      
      &--long, &--short {
        margin: 0 0 30px 0;
      }
    }

    &___nav-list {
      grid-template-columns: repeat(2, calc(50% - 60px));
    }

    &__nav-item {
      img {
        margin-bottom: 55px;
      }
    }
  }
}

.product-od-pc {
  &__container {
    width: max-content;
    height: auto;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 94px;

    @media (min-width: 768px) {
      bottom: 92px;
    }
  }

  &__palette {
    display: flex;
    gap: 14px;

    @media (min-width: 768px) {
      gap: 12px;
    }
  }

  &__color {
    padding: 0;
    width: 28px;
    height: 28px;
    background: transparent;
    background-size: contain;
    border: 3px solid $white;
    font-size: 0;
    color: transparent;
    cursor: pointer;

    @media (min-width: 860px) {
      width: 22px;
      height: 22px;
    }

    &--selected {
      box-shadow: 0 0 0 1px $black;
    }
  }
}
