$font-founders: 'Founders Grotesk', sans-serif;

$blue-00: #0094c8;

.base-gift-cards-products {
  &__container {
    width: 100%;
    max-width: 1152px;
    height: auto;
    margin: 0 auto;
    padding: 0 5%;

    @media (min-width: 768px) {
      width: 80%;
      padding: 0;
    }
  }

  &__main {
    grid-area: left;
  }

  &__link {
    font-family: $font-founders;
    font-size: 15px;
    font-weight: normal !important;
    line-height: 2;
    transition: 0.5s;
    color: $blue-00;
    text-decoration: none;
    cursor: pointer;
    letter-spacing: 1px;

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    small {
      font-family: inherit;
      font-size: 60%;
      font-weight: 400 !important;
    }
  }

  @media (min-width: 1024px) {
    &__layout {
      display: grid;
      grid-template-areas:
          "left right"
          "detail right";
      width: 80%;
      height: auto;
      margin: 0 auto;
      max-width: 1152px;
    }

    &__main {
      max-width: 650px;

      .base-gift-cards-products__container {
        width: 100%;
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1295px) {
    &__main {
      padding-right: 3%;
      max-width: 570px;
    }
  }
}
