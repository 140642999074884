.base-sun__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1280px;
  height: auto;
}

.base-sun__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid transparent;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0.12px;
  height: 40px;
  border-radius: 10px;
  padding-inline: 40px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.base-sun__btn--primary {
  background-color: #292929;
  border-color: #292929;
  color: white;
}

.base-sun__btn--primary:hover, .base-sun__btn--primary:focus {
  color: white;
}

.base-sun__btn--secondary {
  background-color: white;
  border-color: #535353;
  color: #535353;
}

.base-sun__btn--secondary:hover, .base-sun__btn--secondary:focus {
  color: #535353;
}

@media (min-width: 1024px) {
  .base-sun__btn {
    height: 54px;
    font-size: 16px;
    letter-spacing: 0.16px;
  }
  .base-sun__btn:hover, .base-sun__btn:active, .base-sun__btn:visited, .base-sun__btn:focus {
    text-decoration: none;
  }
  .base-sun__btn--primary:hover {
    background-color: #535353;
    border-color: #535353;
    color: white;
  }
  .base-sun__btn--secondary:hover {
    background-color: #535353;
    color: white;
  }
}

.base-sun__hide--sp {
  display: none;
}

.base-sun__hide--pc {
  display: block;
}

@media (min-width: 768px) {
  .base-sun__hide--sp {
    display: block;
  }
  .base-sun__hide--pc {
    display: none;
  }
}

.base-sun__scroll-hidden {
  overflow: hidden;
}
