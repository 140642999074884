.base-owndays-pc__hidden-overflow-x {
  overflow-x: hidden;
}

.base-owndays-pc__container {
  width: 100%;
  max-width: calc(1200px + 10%);
  height: auto;
  padding: 0 5%;
}

@media (min-width: 768px) {
  .base-owndays-pc__container {
    max-width: calc(1200px + 20%);
    padding: 0 10%;
    margin: 0 auto;
  }
}

.base-owndays-pc__container-bg-grid {
  width: 100%;
  max-width: calc(1200px + 20%);
  padding: 0 10%;
}

@media (min-width: 768px) {
  .base-owndays-pc__container-bg-grid {
    max-width: calc(1200px + 10%);
    padding: 0 5%;
    margin: 0 auto;
  }
}

.base-owndays-pc__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #4e7098;
  color: #ffffff;
  border-radius: 10rem;
  height: 60px;
  font: 700 14px/20px "NeuzeitGroBold";
  font-weight: 700 !important;
  letter-spacing: 0.1em;
  padding: 9px 60px 8px 30px;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
}

.base-owndays-pc__btn::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 30px;
  width: 16px;
  height: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-repeat: repeat;
}

.base-owndays-pc__btn:hover, .base-owndays-pc__btn:active, .base-owndays-pc__btn:visited, .base-owndays-pc__btn:focus {
  text-decoration: none;
}

.base-owndays-pc__btn--down::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/owndays-pc/arrow-alt-circle-down.png");
  background-position: center 0px;
  background-size: 16px 16px;
}

.base-owndays-pc__btn--left::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/owndays-pc/arrow-alt-circle-left.png");
  background-position: 0px center;
  background-size: 16px 16px;
}

.base-owndays-pc__btn--right::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/owndays-pc/arrow-alt-circle-right.png");
  background-position: 0px center;
  background-size: 16px 16px;
}

.base-owndays-pc__btn--black {
  background-color: #000000;
  letter-spacing: 0.05em;
}

.base-owndays-pc__btn--black:hover, .base-owndays-pc__btn--black:focus, .base-owndays-pc__btn--black:visited {
  background-color: #000000;
  color: #ffffff;
}

.base-owndays-pc__btn--circle {
  width: 54px;
  height: 54px;
  padding: 0;
  border: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.base-owndays-pc__btn--circle::before {
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .base-owndays-pc__btn {
    max-width: 220px;
  }
}

@media (min-width: 1024px) {
  .base-owndays-pc__btn {
    font-size: 16px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .base-owndays-pc__btn:hover, .base-owndays-pc__btn:focus, .base-owndays-pc__btn:focus:hover, .base-owndays-pc__btn:visited:hover {
    background-color: #000000;
    color: #ffffff;
  }
  .base-owndays-pc__btn--down:hover::before {
    -webkit-animation: owndays-pc-btn-hover-down 0.3s ease forwards;
            animation: owndays-pc-btn-hover-down 0.3s ease forwards;
  }
  .base-owndays-pc__btn--left:hover::before {
    -webkit-animation: owndays-pc-btn-hover-left 0.3s ease forwards;
            animation: owndays-pc-btn-hover-left 0.3s ease forwards;
  }
  .base-owndays-pc__btn--right:hover::before {
    -webkit-animation: owndays-pc-btn-hover-right 0.3s ease forwards;
            animation: owndays-pc-btn-hover-right 0.3s ease forwards;
  }
  .base-owndays-pc__btn--black:hover, .base-owndays-pc__btn--black:focus, .base-owndays-pc__btn--black:visited:hover {
    background-color: #4e7098;
    color: #ffffff;
  }
  .base-owndays-pc__btn--black:visited {
    background-color: #000000;
    color: #ffffff;
  }
  .base-owndays-pc__btn--circle:focus {
    background-color: #4e7098;
    color: #ffffff;
  }
}

.base-owndays-pc__bg {
  background-image: url("https://static.lenskart.com/media/owndays/img/owndays-pc/line-bg-sp.png");
  background-repeat: repeat;
  background-position: left top;
  background-size: auto;
}

@media (min-width: 1024px) {
  .base-owndays-pc__bg {
    background-image: url("https://static.lenskart.com/media/owndays/img/owndays-pc/line-bg-pc.svg");
  }
}

.base-owndays-pc__title {
  font-family: "Space Mono", monospace;
  font-weight: 300 !important;
  line-height: 1.5;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 40px;
}

.base-owndays-pc__title-name {
  font-size: 50px;
}

.base-owndays-pc__title-no {
  font-size: 10px;
  position: relative;
  letter-spacing: 2px;
}

.base-owndays-pc__title-no::before {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(2em + 5px);
  width: 119px;
  border-top: 1px solid #000000;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.base-owndays-pc__title-content p {
  font-family: "NeuzeitGroReg";
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
  letter-spacing: 0;
}

.base-owndays-pc__title-content p:nth-child(1) {
  margin-bottom: 20px;
}

.base-owndays-pc__title-content p:nth-child(2) {
  margin-bottom: 40px;
}

@media (min-width: 1024px) {
  .base-owndays-pc__title {
    margin-bottom: 100px;
  }
  .base-owndays-pc__title-name {
    font-size: 90px;
  }
  .base-owndays-pc__title-no::before {
    width: 200px;
  }
  .base-owndays-pc__title-grid {
    display: grid;
    grid-template-columns: 1fr calc(50% + 120px);
  }
  .base-owndays-pc__title-content {
    margin-top: 50px;
    line-height: 1.625;
    letter-spacing: 0.5px;
  }
  .base-owndays-pc__title-content p {
    font-size: 15px;
  }
  .base-owndays-pc__title-content div .base-owndays-pc__btn {
    max-width: 275px;
  }
}

@-webkit-keyframes owndays-pc-btn-hover-down {
  0% {
    background-position: center 0px;
  }
  100% {
    background-position: center 16px;
  }
}

@keyframes owndays-pc-btn-hover-down {
  0% {
    background-position: center 0px;
  }
  100% {
    background-position: center 16px;
  }
}

@-webkit-keyframes owndays-pc-btn-hover-left {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: -16px center;
  }
}

@keyframes owndays-pc-btn-hover-left {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: -16px center;
  }
}

@-webkit-keyframes owndays-pc-btn-hover-right {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: 16px center;
  }
}

@keyframes owndays-pc-btn-hover-right {
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: 16px center;
  }
}
