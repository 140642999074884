.discount-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #000;
  padding: 13px 0;
  width: 100%;
  top: 0;
}

@media (max-width: 767px) {
  .discount-banner {
    padding: 13px 30px;
  }
}

.discount-banner__title {
  margin: 0;
  color: #fff;
  font-size: 20px;
}

@media (max-width: 767px) {
  .discount-banner__title {
    font-size: 14px;
    text-align: center;
  }
}
