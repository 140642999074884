.footer-contact-us {
  padding: 20px 0 40px 0;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 60px;
}

.footer-contact-us__container {
  width: 100%;
  height: auto;
  padding: 0 5%;
  max-width: 764px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .footer-contact-us__container {
    width: 80%;
    padding: 0;
  }
}

.footer-contact-us h2 {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: black;
  margin: 0;
}

.footer-contact-us h2 span {
  width: 60px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.footer-contact-us h2 span img {
  width: 100%;
  display: block;
}

.footer-contact-us ul {
  list-style-type: none;
  margin: 40px 0 0 0;
  max-width: 510px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
}

.footer-contact-us ul li {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: 30px;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.footer-contact-us ul li:nth-child(2) {
  grid-template-rows: 60px;
}

.footer-contact-us ul li h4, .footer-contact-us ul li p {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: normal !important;
  font-size: 15px;
  line-height: 2;
  letter-spacing: 1px;
  color: black;
  margin: 0;
}

@media (min-width: 768px) {
  .footer-contact-us {
    padding: 40px 0 70px 0;
  }
  .footer-contact-us h2 span {
    width: 80px;
  }
  .footer-contact-us ul {
    margin: 40px auto 0;
    max-width: 510px;
  }
  .footer-contact-us ul li:nth-child(2) {
    grid-template-rows: 30px;
  }
}

@media (min-width: 1024px) {
  .footer-contact-us {
    margin-bottom: 120px;
  }
}
