@import url("https://use.typekit.net/mxa1grw.css");

$base-img-url: 'https://static.lenskart.com/media/owndays/img/freaks-store';

$font-noto: "Noto Sans JP", sans-serif;
$font-din: din-condensed, sans-serif;

$green-55: #555E2B;
$gray-cb: #CBCEC7;

html{
  scroll-behavior: smooth;
}

.base-freaks-store {
  &__hide {
    display: none !important;
  }

  &__container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(1080px + (15px * 2));
    font: 400 14px / 26px $font-noto;
    letter-spacing: normal;
    color: black;
    overflow: hidden;
  }

  &__btn {
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 66px;
    border-radius: 10rem;
    background: white url("#{$base-img-url}/arrow-right--green.svg") no-repeat right 20px center / 8px auto;
    color: $green-55;
    font: 700 14px / 20px $font-noto;
    padding: 0 40px 0 20px;

    &--black {
      background: black url("#{$base-img-url}/arrow-right--white.svg") no-repeat right 20px center / 8px auto;
      color: white;
    }

    @media (min-width: 1024px) {
      height: 70px;
      padding: 0 50px 0 30px;
      background-position: right 30px center;
      
      &:hover {
        background-color: $gray-cb;
        color: white;
      }

      &:hover, &:active, &:visited, &:focus {
        text-decoration: none;
      }

      &--black {
        &:hover {
          background-color: $green-55;
        }
      }
    }
  }

  &__title {
    font: 700 12px / 10px $font-noto;
    letter-spacing: normal;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 12px;
    margin: 0;

    strong {
      font: 700 42px / 32px $font-din;
      letter-spacing: normal;
      color: $green-55;
      letter-spacing: calc(1em*(3 / 100));
    }

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;

      strong {
        font-size: 70px;
        line-height: 60px;
      }
    }
  }
}
