@-webkit-keyframes pompomFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pompomFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes pompomLoading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pompomLoading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.body-pompompurin__models__item {
  padding-block: 69px 217px;
  background-color: #ffe57a;
  position: relative;
}

.body-pompompurin__models__item::before {
  content: "";
  position: absolute;
  top: -114px;
  left: 0;
  right: 0;
  height: 185px;
  background: url("https://static.lenskart.com/media/owndays/img/pompompurin/wave-1.svg") no-repeat center top/cover;
  z-index: 1;
}

.body-pompompurin__models__item .base-pompompurin__container {
  position: relative;
  z-index: 2;
  max-width: 1196px;
}

.body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots {
  margin-top: 14px;
  padding: 0;
  list-style-type: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots li > button, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots li > button {
  border: 0;
  color: transparent;
}

.body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots li, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots li {
  width: 24px;
  height: 19px;
  margin: 0 6px;
}

.body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots li button, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
  background: url("https://static.lenskart.com/media/owndays/img/pompompurin/footprint.svg") no-repeat center/contain;
  opacity: 0.25;
}

.body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots li button::before, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots li button::before {
  display: none;
}

.body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots li.slick-active button, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots li.slick-active button {
  opacity: 1;
}

@media (min-width: 768px) {
  .body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots {
    margin-top: 32px;
  }
  .body-pompompurin__models__item .body-pompompurin__models__feature-list > .slick-dots li, .body-pompompurin__models__item .body-pompompurin__models__products-slider > .slick-dots li {
    width: 37px;
    height: 30px;
    margin: 0 9px;
  }
}

.body-pompompurin__models__item:nth-child(even) {
  background-color: #fff9b1;
}

.body-pompompurin__models__item:nth-child(even)::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/pompompurin/wave-2.svg");
}

.body-pompompurin__models__item:nth-child(5) {
  padding-bottom: 0;
}

.body-pompompurin__models__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 6px;
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 30px;
  line-height: 1.25;
  letter-spacing: 3px;
  color: white;
  -webkit-text-stroke-width: 6px;
  -webkit-text-stroke-color: #76332e;
  paint-order: stroke;
  margin-bottom: 30px;
}

.body-pompompurin__models__title span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #76332e;
  color: white;
  padding: 3px 10px 5px;
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 29px;
  line-height: 1;
  letter-spacing: 0.66px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .body-pompompurin__models__title span {
    padding: 9px 10px;
  }
}

.body-pompompurin__models__char--1, .body-pompompurin__models__char--2, .body-pompompurin__models__char--3, .body-pompompurin__models__char--4, .body-pompompurin__models__char--5 {
  display: block;
  width: 100%;
}

.body-pompompurin__models__char--1 img {
  aspect-ratio: 196/197;
}

.body-pompompurin__models__char--2 img {
  aspect-ratio: 124/113;
}

.body-pompompurin__models__char--3 img {
  aspect-ratio: 616/613;
}

.body-pompompurin__models__char--4 img {
  aspect-ratio: 604/581;
}

.body-pompompurin__models__char--5 img {
  aspect-ratio: 620/633;
}

.body-pompompurin__models__content {
  display: grid;
}

.body-pompompurin__models__content-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 23px;
}

.body-pompompurin__models__content-header h3, .body-pompompurin__models__content-header h4 {
  font-family: "Corporate Logo Rounded ver2", sans-serif !important;
  margin-bottom: 0;
}

.body-pompompurin__models__content-header h3 {
  font: 700 20px/22px "Corporate Logo Rounded ver2", sans-serif;
  padding-bottom: 7px;
  border-bottom: 3px solid currentColor;
}

.body-pompompurin__models__content-header h4 {
  font: 700 20px/33px "Corporate Logo Rounded ver2", sans-serif;
  letter-spacing: 0.2px;
}

.body-pompompurin__models__content-header h4 small {
  font-family: "heisei-maru-gothic-std", sans-serif !important;
  font: 800 12px/1 "heisei-maru-gothic-std", sans-serif;
  letter-spacing: 0.72px;
  margin-left: 5px;
}

.body-pompompurin__models__content-product {
  display: block;
}

.body-pompompurin__models__content-product img {
  aspect-ratio: 221/160;
}

.body-pompompurin__models__content-text {
  font-size: 13px;
  font-weight: 800 !important;
  letter-spacing: 0px;
  margin-bottom: 30px;
}

.body-pompompurin__models__feature {
  margin-block: 70px 80px;
}

.body-pompompurin__models__feature-item {
  padding-inline: 5px;
}

.body-pompompurin__models__feature-title {
  text-align: center;
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 26px;
  line-height: 1.25;
  letter-spacing: 2.08px;
  margin-bottom: 35px;
}

.body-pompompurin__models__feature-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #76332e;
  color: white;
  position: relative;
  border-radius: 100rem;
  aspect-ratio: 250/333;
}

.body-pompompurin__models__feature-content span {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 67px;
  height: 47px;
  font: 700 20px/1 "Fredoka", sans-serif;
  letter-spacing: 0.4px;
  color: #76332e;
  padding-left: 5px;
  background: url("https://static.lenskart.com/media/owndays/img/pompompurin/pudding.svg") no-repeat center/contain;
}

.body-pompompurin__models__feature-content img {
  aspect-ratio: 31/27;
}

.body-pompompurin__models__feature-content p {
  display: grid;
  width: 171px;
  font-size: 11px;
  font-weight: 800 !important;
  line-height: 20px;
  text-align: center;
  -webkit-box-flex: 0.75;
      -ms-flex-positive: 0.75;
          flex-grow: 0.75;
  -ms-flex-line-pack: center;
      align-content: center;
  margin: 0 auto;
  letter-spacing: 0;
}

.body-pompompurin__models__products-colors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 11px;
  margin-bottom: 4px;
  margin-inline: 4%;
}

@media (max-width: 767px) {
  .body-pompompurin__models__products-colors {
    margin-inline: 10%;
  }
}

@media (min-width: 768px) {
  .body-pompompurin__models__products-colors {
    margin-inline: 0;
  }
}

@media (min-width: 1024px) {
  .body-pompompurin__models__products-colors {
    margin-inline: 4%;
  }
}

.body-pompompurin__models__products-colors p {
  font: 700 16px/1.25 "Fredoka", sans-serif;
  letter-spacing: 1.28px;
  margin-bottom: 0;
}

.body-pompompurin__models__products-colors ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.body-pompompurin__models__products-colors ul li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 10rem;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid transparent;
}

.body-pompompurin__models__products-colors ul li span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 14px;
  height: 14px;
  border-radius: 10rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.body-pompompurin__models__products-colors ul li.active {
  border-color: black;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR1007B-4A-C1"] span {
  background-color: #865928;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR1007B-4A-C2"] span {
  background-color: #fc9ac0;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR1008B-4A-C1"] span {
  background-color: #865928;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR1008B-4A-C2"] span {
  background-color: #9e7a53;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR2003J-4A-C1"] span {
  background-color: #faf5d6;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR2003J-4A-C2"] span {
  background-color: #ecd5c1;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR2004N-4A-C1"] span {
  background-color: #865928;
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR2004N-4A-C2"] span {
  background-image: url("https://static.lenskart.com/media/owndays/img/pompompurin/color-1.webp");
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR2005N-4A-C1"] span {
  background-image: url("https://static.lenskart.com/media/owndays/img/pompompurin/color-2.webp");
}

.body-pompompurin__models__products-colors ul li[data-product="#product-SR2005N-4A-C2"] span {
  background-color: #ceb39c;
}

.body-pompompurin__models__products-slider {
  width: 100%;
}

.body-pompompurin__models__products-slider > .slick-list {
  overflow: visible;
}

.body-pompompurin__models__products-slider > .slick-list .slick-slide {
  padding-inline: 4px;
  padding-bottom: 8px;
}

.body-pompompurin__models__products-slider > .slick-list .slick-slide.slick-current picture {
  -webkit-transform: translateY(8px);
          transform: translateY(8px);
}

.body-pompompurin__models__products-slider > .slick-arrow {
  position: absolute;
  top: 34%;
  width: 40px;
  height: 40px;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.body-pompompurin__models__products-slider > .slick-arrow::before {
  display: none;
}

@media (min-width: 768px) {
  .body-pompompurin__models__products-slider > .slick-arrow {
    top: 38%;
  }
}

@media (min-width: 1025px) {
  .body-pompompurin__models__products-slider > .slick-arrow {
    display: none !important;
  }
}

.body-pompompurin__models__products-slider > .slick-prev, .body-pompompurin__models__products-slider > .slick-next {
  color: transparent;
  background-color: transparent;
  border: 0;
}

.body-pompompurin__models__products-slider > .slick-prev {
  left: 20px;
  background-image: url("https://static.lenskart.com/media/owndays/img/pompompurin/slick-prev.svg");
}

.body-pompompurin__models__products-slider > .slick-next {
  right: 20px;
  background-image: url("https://static.lenskart.com/media/owndays/img/pompompurin/slick-next.svg");
}

.body-pompompurin__models__products-slider picture {
  background-color: white;
  border-radius: 20px;
  border: 3px solid #76332e;
  padding: 14px 12px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.body-pompompurin__models__products-slider picture img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 591/373;
}

.body-pompompurin__models__products .base-pompompurin__btn {
  margin-top: 45px;
}

.body-pompompurin__models__bottom {
  background-color: #ffe57a;
  padding: 88px 20px 25px;
}

@media (max-width: 767px) {
  .body-pompompurin__models__bottom {
    padding: 38px 20px 18px;
  }
}

.body-pompompurin__models__note {
  text-align: center;
  font-size: 10px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0.2px;
  font-family: "heisei-maru-gothic-std", sans-serif;
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .body-pompompurin__models__top, .body-pompompurin__models__content {
    max-width: 425px;
    margin-inline: auto;
  }
  .body-pompompurin__models__title {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-text-stroke-width: 1.5px;
  }
  .body-pompompurin__models__char {
    padding-inline: 20px;
    margin-bottom: 40px;
    margin-top: -8px;
  }
  .body-pompompurin__models__feature {
    margin-inline: -20px;
  }
}

@media (min-width: 768px) {
  .body-pompompurin__models__products {
    position: relative;
  }
  .body-pompompurin__models__products-slider picture img {
    max-width: 577px;
  }
  .body-pompompurin__models__products-colors {
    gap: 24px;
    position: absolute;
    top: 490px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
  }
  .body-pompompurin__models__products-colors p {
    font-size: 36px;
    letter-spacing: 2.88px;
  }
  .body-pompompurin__models__products-colors ul {
    gap: 9px;
  }
  .body-pompompurin__models__products-colors ul li {
    width: 43px;
    height: 43px;
  }
  .body-pompompurin__models__products-colors ul li span {
    width: 31px;
    height: 31px;
  }
  .body-pompompurin__models__products .base-pompompurin__btn {
    margin-top: 90px;
  }
}

@media (min-width: 1024px) {
  .body-pompompurin__models__item {
    padding-bottom: 509px;
  }
  .body-pompompurin__models__item::before {
    top: -504px;
    left: -175px;
    right: -175px;
    height: 642px;
  }
  .body-pompompurin__models__item .base-pompompurin__container {
    position: relative;
  }
  .body-pompompurin__models__item:nth-of-type(odd) .body-pompompurin__models__content-header, .body-pompompurin__models__item:nth-of-type(odd) .body-pompompurin__models__content-text {
    margin-left: auto;
  }
  .body-pompompurin__models__item:nth-of-type(odd) .body-pompompurin__models__content-header {
    position: relative;
  }
  .body-pompompurin__models__item:nth-of-type(odd) .body-pompompurin__models__content-header::before {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 175px;
    height: auto;
    aspect-ratio: 57/85;
    background: url("https://static.lenskart.com/media/owndays/img/pompompurin/line-dot.svg") no-repeat center right/contain;
    z-index: 1;
    left: 95%;
    top: -167px;
    left: -102px;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__char {
    left: 20px;
    right: auto;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__content-product {
    margin-left: auto;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__content-header {
    position: relative;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__content-header::before {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 175px;
    height: auto;
    aspect-ratio: 57/85;
    background: url("https://static.lenskart.com/media/owndays/img/pompompurin/line-dot-2.svg") no-repeat center left/contain;
    z-index: 1;
    top: -167px;
    right: -102px;
  }
  .body-pompompurin__models__item:nth-child(2) .body-pompompurin__models__title {
    margin-left: calc(100% - 541px);
  }
  .body-pompompurin__models__item:nth-child(4) .body-pompompurin__models__title {
    margin-left: calc(100% - 465px);
  }
}

@media (min-width: 1024px) and (max-width: 1125px) {
  .body-pompompurin__models__item .body-pompompurin__models__content-product::before {
    display: none;
  }
}

@media (min-width: 1024px) {
  .body-pompompurin__models__top {
    position: relative;
  }
  .body-pompompurin__models__title {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 16px;
    font-size: 67px;
    letter-spacing: 6.695px;
    margin-bottom: 150px;
  }
  .body-pompompurin__models__title span {
    font-size: 58px;
    letter-spacing: 1.32px;
    padding: 11px 20px;
  }
  .body-pompompurin__models__char {
    position: absolute;
    top: 0;
    right: 20px;
  }
  .body-pompompurin__models__char img {
    width: 100%;
  }
  .body-pompompurin__models__char--1 {
    max-width: 481px;
    top: -107px;
  }
  .body-pompompurin__models__char--2 {
    max-width: 429px;
    top: -127px;
  }
  .body-pompompurin__models__char--3 {
    max-width: 502px;
    top: -210px;
  }
  .body-pompompurin__models__char--4 {
    max-width: 467px;
    top: -140px;
  }
  .body-pompompurin__models__char--5 {
    max-width: 528px;
    top: -138px;
  }
  .body-pompompurin__models__content {
    display: block;
    margin-bottom: 154px;
    position: relative;
  }
  .body-pompompurin__models__content-product {
    max-width: calc(45% + 35px);
  }
  .body-pompompurin__models__content-header, .body-pompompurin__models__content-text {
    max-width: calc(50% - 25px);
  }
  .body-pompompurin__models__content-header h3 {
    font-size: 26px;
    line-height: 32.8px;
    letter-spacing: 0.26px;
    padding-bottom: 9px;
    border-bottom-width: 5px;
  }
  .body-pompompurin__models__content-header h4 {
    font-size: 25px;
    letter-spacing: 0.25px;
  }
  .body-pompompurin__models__content-text {
    font-size: 18px;
    line-height: 33px;
    letter-spacing: 0.16px;
    margin-bottom: 0;
  }
  .body-pompompurin__models__content .base-pompompurin__container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .body-pompompurin__models__feature {
    margin-bottom: 120px;
  }
  .body-pompompurin__models__feature-title {
    font-size: 36px;
  }
  .body-pompompurin__models__feature-list {
    margin-inline: -33px;
  }
  .body-pompompurin__models__feature-item {
    padding-inline: 33px;
  }
  .body-pompompurin__models__feature-content p {
    width: 277px;
    font-size: 15px;
    line-height: 25px;
  }
  .body-pompompurin__models__products {
    max-width: 1440px;
    margin: 0 auto;
  }
  .body-pompompurin__models__products-colors {
    left: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .body-pompompurin__models__products-slider > .slick-list .slick-slide {
    padding-inline: 8px;
    padding-bottom: 18px;
  }
  .body-pompompurin__models__products-slider > .slick-list .slick-slide.slick-current picture {
    -webkit-transform: translateY(18px);
            transform: translateY(18px);
  }
  .body-pompompurin__models__products-slider picture {
    padding: 28px 25px;
    border-width: 5px;
    border-radius: 30px;
  }
  .body-pompompurin__models__products-slider picture img {
    max-width: 591px;
  }
}

@media (min-width: 1296px) {
  .body-pompompurin__models__char {
    right: 0;
  }
  .body-pompompurin__models__char img {
    width: 100%;
  }
  .body-pompompurin__models__char--1 {
    max-width: 581px;
    top: -107px;
  }
  .body-pompompurin__models__char--2 {
    max-width: 597px;
    top: -127px;
  }
  .body-pompompurin__models__char--3 {
    max-width: 602px;
    top: -210px;
  }
  .body-pompompurin__models__char--4 {
    max-width: 567px;
    top: -140px;
  }
  .body-pompompurin__models__char--5 {
    max-width: 628px;
    top: -138px;
  }
  .body-pompompurin__models__item {
    padding-bottom: 509px;
  }
  .body-pompompurin__models__item::before {
    top: -504px;
    left: -175px;
    right: -175px;
    height: 642px;
  }
  .body-pompompurin__models__item:nth-of-type(odd) .body-pompompurin__models__content-header, .body-pompompurin__models__item:nth-of-type(odd) .body-pompompurin__models__content-text {
    margin-left: auto;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
  }
  .body-pompompurin__models__item:nth-of-type(even) .body-pompompurin__models__char {
    left: 20px;
    right: auto;
  }
  .body-pompompurin__models__item:nth-child(2) .body-pompompurin__models__title {
    margin-left: calc(100% - 506px);
  }
  .body-pompompurin__models__item:nth-child(3) .body-pompompurin__models__content-product::before {
    top: 44%;
  }
  .body-pompompurin__models__item:nth-child(4) .body-pompompurin__models__title {
    margin-left: calc(100% - 465px);
  }
}

.body-pompompurin__case {
  padding-block: 112px 164px;
  background-color: #76332e;
}

.body-pompompurin__case__content {
  background-color: white;
  border-radius: 20px;
  padding: 70px 20px 50px;
  position: relative;
}

.body-pompompurin__case__fg {
  aspect-ratio: 76/51;
  max-width: 159px;
  position: absolute;
  top: -72px;
  left: calc(50% - 63px);
}

.body-pompompurin__case__heading {
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: 1.84px;
  margin: 0;
}

.body-pompompurin__case__img {
  aspect-ratio: 253/189;
}

.body-pompompurin__case__text {
  width: 100%;
  max-width: 495px;
  height: auto;
  font-size: 14px;
  font-weight: 800 !important;
  line-height: 31px;
  letter-spacing: 0.28px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .body-pompompurin__case__heading {
    text-align: center;
  }
  .body-pompompurin__case__img {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .body-pompompurin__case__content {
    display: grid;
    grid-template-columns: 1fr 50%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px 47px;
  }
  .body-pompompurin__case__fg {
    top: auto;
    left: -57px;
    bottom: -59px;
  }
  .body-pompompurin__case__heading {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .body-pompompurin__case__img {
    grid-row: 1 / span 2;
    grid-column: 2;
  }
  .body-pompompurin__case__text {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }
}

@media (min-width: 1024px) {
  .body-pompompurin__case {
    padding-block: 120px 288px;
  }
  .body-pompompurin__case__content {
    padding: 25px 60px 60px;
  }
  .body-pompompurin__case__fg {
    max-width: 228px;
  }
  .body-pompompurin__case__heading {
    font-size: 29.6px;
    line-height: normal;
    letter-spacing: 2.3792px;
  }
}

@media (min-width: 1296px) {
  .body-pompompurin__case__heading {
    font-size: 37px;
    letter-spacing: 2.974px;
  }
  .body-pompompurin__case__text {
    width: 495px;
  }
}

.body-pompompurin__accessories {
  margin-top: -1px;
  padding-bottom: 18px;
  z-index: 1;
  background-color: #76332e;
}

.body-pompompurin__accessories::before {
  content: "";
  position: absolute;
  inset: 291px 0 0;
  background-color: #fff9b1;
  z-index: -1;
}

.body-pompompurin__accessories::after {
  content: "";
  position: absolute;
  top: -89px;
  left: -115px;
  right: -115px;
  height: 671px;
  background-color: #fff9b1;
  border-radius: 100%;
  z-index: -1;
}

.body-pompompurin__accessories .base-pompompurin__container {
  position: relative;
}

.body-pompompurin__accessories__fg {
  width: 100%;
  aspect-ratio: 175/198;
  max-width: 140px;
  position: absolute;
  left: 50%;
  bottom: -180px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}

.body-pompompurin__accessories__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 2.56px;
  margin-top: 0;
  margin-bottom: 40px;
}

.body-pompompurin__accessories__title span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffcb14;
  color: #76332e;
  height: 23px;
  padding-inline: 7px;
  border-radius: 3px;
  font: 700 13px/1 "heisei-maru-gothic-std", sans-serif;
  letter-spacing: 0.26px;
}

.body-pompompurin__accessories__list {
  display: grid;
  gap: 60px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 38px;
  list-style-type: none;
  padding: 0;
}

.body-pompompurin__accessories__item {
  width: 100%;
  overflow: hidden;
  display: grid;
  gap: 35px;
}

.body-pompompurin__accessories__slider {
  width: 100%;
  overflow: hidden;
  opacity: 0;
}

.body-pompompurin__accessories__slider img {
  aspect-ratio: 139/91;
}

.body-pompompurin__accessories__slider.loaded {
  -webkit-animation: pompomFadeIn 1s ease 1s forwards;
          animation: pompomFadeIn 1s ease 1s forwards;
}

.body-pompompurin__accessories__slider > .slick-list {
  aspect-ratio: 139/91;
}

.body-pompompurin__accessories__slider > .slick-dots {
  margin-top: 20px;
  padding: 0;
  list-style-type: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.body-pompompurin__accessories__slider > .slick-dots li > button {
  border: 0;
  color: transparent;
}

.body-pompompurin__accessories__slider > .slick-dots li {
  width: 24px;
  height: 19px;
  margin: 0 6px;
}

.body-pompompurin__accessories__slider > .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
  background: url("https://static.lenskart.com/media/owndays/img/pompompurin/footprint.svg") no-repeat center/contain;
  opacity: 0.25;
}

.body-pompompurin__accessories__slider > .slick-dots li button::before {
  display: none;
}

.body-pompompurin__accessories__slider > .slick-dots li.slick-active button {
  opacity: 1;
}

@media (min-width: 768px) {
  .body-pompompurin__accessories__slider > .slick-dots {
    margin-top: 32px;
  }
  .body-pompompurin__accessories__slider > .slick-dots li {
    width: 37px;
    height: 30px;
    margin: 0 9px;
  }
}

.body-pompompurin__accessories__content p {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.28px;
  margin-bottom: 30px;
}

.body-pompompurin__accessories__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 23px;
}

.body-pompompurin__accessories__header h3, .body-pompompurin__accessories__header h4 {
  margin: 0;
}

.body-pompompurin__accessories__header h3 {
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 22px;
  padding-bottom: 7px;
  border-bottom: 3px solid currentColor;
}

.body-pompompurin__accessories__header h4 {
  font-family: "Corporate Logo Rounded ver2", sans-serif !important;
  font: 700 20px/33px "Corporate Logo Rounded ver2", sans-serif;
  letter-spacing: 0.2px;
}

.body-pompompurin__accessories__header h4 small {
  font-family: "heisei-maru-gothic-std", sans-serif !important;
  font: 800 12px/1 "heisei-maru-gothic-std", sans-serif;
  letter-spacing: 0.72px;
  margin-left: 5px;
}

.body-pompompurin__accessories__note {
  text-align: center;
  font-size: 10px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0.2px;
  font-family: "heisei-maru-gothic-std", sans-serif;
  margin: 0 0 140px 0;
}

@media (min-width: 768px) {
  .body-pompompurin__accessories__item {
    grid-template-columns: repeat(2, 1fr);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .body-pompompurin__accessories__item:nth-child(even) .body-pompompurin__accessories__slider {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .body-pompompurin__accessories__content .base-pompompurin__btn {
    margin-left: 0;
  }
  .body-pompompurin__accessories__note {
    margin-bottom: 150px;
  }
}

@media (min-width: 1024px) {
  .body-pompompurin__accessories {
    padding-bottom: 25px;
  }
  .body-pompompurin__accessories__fg {
    left: 90%;
    bottom: -48px;
  }
  .body-pompompurin__accessories::before {
    top: 233.2px;
  }
  .body-pompompurin__accessories::after {
    top: -178px;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 1507px;
    height: 1166px;
  }
  .body-pompompurin__accessories__title {
    font-size: 60px;
    line-height: 73px;
    letter-spacing: 3px;
    gap: 10px;
    margin-bottom: 70px;
  }
  .body-pompompurin__accessories__title span {
    font-size: 21px;
    letter-spacing: 0.42px;
    height: 37px;
    padding-inline: 18px;
  }
  .body-pompompurin__accessories__list {
    gap: 67px;
    margin-bottom: 98px;
  }
  .body-pompompurin__accessories__item {
    gap: 60px;
  }
  .body-pompompurin__accessories__header {
    margin-bottom: 27px;
  }
  .body-pompompurin__accessories__header h3 {
    font-size: 34px;
    line-height: 50px;
    padding-bottom: 9px;
    border-bottom-width: 5px;
  }
  .body-pompompurin__accessories__header h4 {
    font-size: 25px;
    line-height: 33px;
    letter-spacing: 0.25px;
  }
  .body-pompompurin__accessories__header h4 small {
    font-size: 14px;
    letter-spacing: 0.84px;
  }
  .body-pompompurin__accessories__content p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.32px;
    margin-bottom: 26px;
  }
  .body-pompompurin__accessories__note {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .body-pompompurin__accessories::before {
    top: 405px;
  }
  .body-pompompurin__accessories::after {
    left: -34px;
    right: -34px;
    -webkit-transform: none;
            transform: none;
    width: auto;
  }
}
