.footer-kuromi-collection__accessory {
  padding-top: 139px;
  background-color: #bcadd3;
}

.footer-kuromi-collection__accessory .base-kuromi-collection__container {
  padding-top: 0;
}

.footer-kuromi-collection__accessory__inner {
  background-color: white;
  padding: 1px 30px 65px;
  border: 5px solid #2b2045;
  border-radius: 20px;
  position: relative;
}

.footer-kuromi-collection__accessory__character {
  display: block;
  width: 190px;
  margin: -80px auto 33px;
  aspect-ratio: 65 / 63;
}

.footer-kuromi-collection__accessory__title {
  font: 400 20px/1.7 "Eveleth Clean";
  letter-spacing: 0.06em;
  text-align: center;
  margin: 0;
}

.footer-kuromi-collection__accessory__img {
  display: block;
  margin: 33px auto 45px;
  max-width: 450px;
  aspect-ratio: 315 / 302;
}

.footer-kuromi-collection__accessory__detail {
  font: 700 12px/2.5 heisei-maru-gothic-std, sans-serif;
  letter-spacing: 0.04em;
  position: relative;
  padding-top: 35px;
  margin: 0;
}

.footer-kuromi-collection__accessory__detail::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  height: 8px;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/dot.svg") top center repeat-x;
}

@media (min-width: 768px) {
  .footer-kuromi-collection__accessory .base-kuromi-collection__item-corner-top::before, .footer-kuromi-collection__accessory .base-kuromi-collection__item-corner-top::after {
    top: 24px;
  }
  .footer-kuromi-collection__accessory .base-kuromi-collection__item-corner-bottom::before, .footer-kuromi-collection__accessory .base-kuromi-collection__item-corner-bottom::after {
    bottom: 24px;
  }
  .footer-kuromi-collection__accessory__inner {
    padding: 20px 30px 10px 80px;
    display: grid;
    grid-template-areas: "title img" "detail img";
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
  }
  .footer-kuromi-collection__accessory__character {
    width: 140px;
    position: absolute;
    left: -80px;
    bottom: 0px;
  }
  .footer-kuromi-collection__accessory__title {
    text-align: left;
    grid-area: title;
    -ms-flex-item-align: end;
        align-self: end;
  }
  .footer-kuromi-collection__accessory__character {
    width: 210px;
    left: -130px;
  }
  .footer-kuromi-collection__accessory__img {
    grid-area: img;
    margin: 0;
    aspect-ratio: 280 / 201;
  }
  .footer-kuromi-collection__accessory__detail {
    padding-top: 20px;
    grid-area: detail;
  }
  .footer-kuromi-collection__accessory__detail::before {
    content: none;
  }
}

@media (min-width: 1024px) {
  .footer-kuromi-collection__accessory__inner {
    padding: 20px 15px 10px 100px;
    border-radius: 30px;
  }
  .footer-kuromi-collection__accessory__title {
    font-size: 28px;
  }
  .footer-kuromi-collection__accessory__img {
    max-width: none;
  }
  .footer-kuromi-collection__accessory__detail {
    width: 360px;
    font-size: 14px;
    padding-top: 30px;
  }
}

@media (min-width: 1296px) {
  .footer-kuromi-collection__accessory__inner {
    padding-right: 5px;
    grid-template-columns: auto 560px;
  }
}

.footer-kuromi-collection__novelty {
  background-color: #bcadd3;
  padding-top: 73px;
}

.footer-kuromi-collection__novelty .base-kuromi-collection__button {
  width: 330px;
}

.footer-kuromi-collection__novelty__inner {
  background-color: white;
  padding: 50px 30px 65px;
  margin-top: 25px;
  border: 5px solid #2b2045;
  border-radius: 20px;
  position: relative;
}

.footer-kuromi-collection__novelty__inner > small {
  font: 700 12px/1 heisei-maru-gothic-std, sans-serif;
  padding: 10px 12px 7px;
  background-color: #2b2045;
  color: white;
  display: table;
  margin: 0 auto;
}

.footer-kuromi-collection__novelty__title {
  font: 700 28px/1 "Corporate Logo Rounded";
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1.5;
  margin-top: 23px;
  position: relative;
  z-index: 2;
  margin: 23px 0 0 0;
}

.footer-kuromi-collection__novelty__title small {
  display: block;
  font-size: 14px;
}

.footer-kuromi-collection__novelty__img {
  display: block;
  width: 100%;
  height: auto;
  margin: -17px auto 0;
  max-width: 450px;
  aspect-ratio: 322 / 263;
}

.footer-kuromi-collection__novelty__detail {
  font: 800 13px/2 heisei-maru-gothic-std, sans-serif;
  color: black;
  margin: 0;
}

.footer-kuromi-collection__novelty__note {
  font-size: 11px;
  font-weight: 400;
  display: block;
  margin-top: 18px;
  padding-left: 10px;
  position: relative;
}

.footer-kuromi-collection__novelty__note::before {
  position: absolute;
  top: 0;
  left: 0;
  font-style: normal;
  background: none;
  content: '*';
}

.footer-kuromi-collection__novelty__character {
  display: block;
  width: 76px;
  position: absolute;
  right: -20px;
  bottom: -23px;
  z-index: 2;
}

@media (min-width: 768px) {
  .footer-kuromi-collection__novelty .base-kuromi-collection__container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .footer-kuromi-collection__novelty .base-kuromi-collection__button {
    max-width: none;
  }
  .footer-kuromi-collection__novelty__inner {
    display: grid;
    grid-template-areas: "img label" "img title" "img detail";
    grid-template-rows: 0fr auto 0fr;
    grid-template-columns: 55% auto;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    padding: 50px 30px 50px 16px;
  }
  .footer-kuromi-collection__novelty__inner > small {
    margin-left: 0;
    grid-area: label;
    -ms-flex-item-align: end;
        align-self: end;
  }
  .footer-kuromi-collection__novelty__title {
    grid-area: title;
    text-align: left;
    margin-bottom: 25px;
  }
  .footer-kuromi-collection__novelty__img {
    grid-area: img;
    -ms-flex-item-align: center;
        align-self: center;
  }
  .footer-kuromi-collection__novelty__detail {
    grid-area: detail;
  }
  .footer-kuromi-collection__novelty__goforit {
    position: absolute;
    top: 58px;
    left: -73px;
    width: 140px;
  }
}

@media (min-width: 1024px) {
  .footer-kuromi-collection__novelty {
    padding-top: 110px;
  }
  .footer-kuromi-collection__novelty__inner {
    margin-top: 35px;
    padding: 80px 78px 80px 16px;
  }
  .footer-kuromi-collection__novelty__inner > small {
    font-size: 13px;
    letter-spacing: 0.12em;
    padding: 11px 12px 7px;
  }
  .footer-kuromi-collection__novelty__title {
    font-size: 34px;
  }
  .footer-kuromi-collection__novelty__title small {
    font-size: 16px;
  }
  .footer-kuromi-collection__novelty__character {
    width: 101px;
    right: -51px;
    bottom: -18px;
  }
}

@media (min-width: 1296px) {
  .footer-kuromi-collection__novelty__inner {
    grid-template-columns: 492px auto;
  }
}

.footer-kuromi-collection__about {
  background-color: #bcadd3;
  padding: 30vw 0 32vw;
}

.footer-kuromi-collection__about__inner {
  position: relative;
  margin-bottom: 50px;
  padding: 20px 30px 30px;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/about-bg-body-sp.webp") top left/contain repeat-y;
}

.footer-kuromi-collection__about__inner::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 13vw;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/about-bg-top-sp.webp") bottom left/contain no-repeat;
}

.footer-kuromi-collection__about__inner::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 8vw;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/about-bg-bottom-sp.webp") top left/contain no-repeat;
}

.footer-kuromi-collection__about__character {
  display: block;
  width: 189px;
  height: auto;
  margin: 0 auto;
  grid-area: img;
  aspect-ratio: 193 / 220;
}

.footer-kuromi-collection__about__title {
  margin: 30px 0 18px;
  font: 700 21px/1.7 "Corporate Logo Rounded";
}

.footer-kuromi-collection__about__detail {
  font: 800 13px/2.3 heisei-maru-gothic-std, sans-serif;
  margin-bottom: 20px;
}

.footer-kuromi-collection__about__el {
  position: absolute;
  z-index: 3;
}

.footer-kuromi-collection__about__el--1 {
  top: -18vw;
  right: -9vw;
  width: 33vw;
}

.footer-kuromi-collection__about__el--2 {
  top: -14vw;
  left: -7vw;
  width: 22vw;
}

.footer-kuromi-collection__about__el--3 {
  bottom: -11vw;
  right: -7vw;
  width: 22vw;
}

.footer-kuromi-collection__about__el--4 {
  bottom: -16vw;
  left: -10vw;
  width: 31vw;
  max-width: 150px;
}

@media (min-width: 768px) {
  .footer-kuromi-collection__about {
    padding-top: 135px;
    padding-bottom: 110px;
  }
  .footer-kuromi-collection__about .base-kuromi-collection__container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .footer-kuromi-collection__about .base-kuromi-collection__button {
    margin-left: 0;
  }
  .footer-kuromi-collection__about__inner {
    margin-left: 45px;
    background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/about-bg-body-pc.webp") top left/contain repeat-x;
    display: grid;
    grid-template-areas: "title img" "detail img" "button img";
    grid-template-columns: 55% auto;
    -webkit-column-gap: 25px;
    -moz-column-gap: 25px;
         column-gap: 25px;
    padding: 55px 30px;
  }
  .footer-kuromi-collection__about__inner::before {
    left: -31px;
    top: 0;
    bottom: 0;
    right: 0;
    height: unset;
    background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/about-bg-left-pc.webp") top left/contain no-repeat;
    pointer-events: none;
  }
  .footer-kuromi-collection__about__inner::after {
    left: 0;
    top: 0;
    bottom: 0;
    right: -7px;
    height: unset;
    background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/about-bg-right-pc.webp") top right/contain no-repeat;
    pointer-events: none;
  }
  .footer-kuromi-collection__about__character {
    width: 100%;
  }
  .footer-kuromi-collection__about__title {
    text-align: left;
  }
  .footer-kuromi-collection__about__el--1 {
    top: -81px;
    right: -4vw;
    width: 178px;
  }
  .footer-kuromi-collection__about__el--2 {
    top: -25px;
    left: -7vw;
    width: 108px;
  }
  .footer-kuromi-collection__about__el--3 {
    bottom: -11px;
    right: -3vw;
    width: 108px;
  }
  .footer-kuromi-collection__about__el--4 {
    bottom: -40px;
    left: -10vw;
    width: 140px;
  }
}

@media (min-width: 1024) {
  .footer-kuromi-collection__about {
    padding-bottom: 120px;
  }
}

@media (min-width: 1296px) {
  .footer-kuromi-collection__about__inner {
    grid-template-columns: auto 313px;
    -webkit-column-gap: 50px;
       -moz-column-gap: 50px;
            column-gap: 50px;
    padding: 100px 50px;
  }
  .footer-kuromi-collection__about__inner::before {
    left: -37px;
  }
  .footer-kuromi-collection__about__el--1 {
    top: -49px;
    right: 37px;
  }
  .footer-kuromi-collection__about__el--2 {
    width: 130px;
    top: -25px;
    left: -69px;
  }
  .footer-kuromi-collection__about__el--3 {
    bottom: -14px;
    right: -37px;
    width: 130px;
  }
  .footer-kuromi-collection__about__el--4 {
    bottom: 27px;
    left: -98px;
  }
}

.footer-kuromi-collection__share {
  color: white;
  margin-top: -88px;
  padding: 0 0 55px;
  position: relative;
  z-index: 2;
}

.footer-kuromi-collection__share::before {
  content: '';
  position: absolute;
  top: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2b2045;
  -webkit-clip-path: polygon(0 0, 100% 12%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 12%, 100% 100%, 0% 100%);
  z-index: -1;
}

.footer-kuromi-collection__share__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 56px;
  margin-bottom: 56px;
  padding-left: 0;
}

.footer-kuromi-collection__share__social-item {
  background-color: white;
  width: 66px;
  height: 66px;
  margin: 0 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.footer-kuromi-collection__share__social-item::before {
  content: '';
  width: 25px;
  height: 25px;
  background: center/contain no-repeat;
}

.footer-kuromi-collection__share__social-item a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: transparent !important;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer-kuromi-collection__share__social-item--fb::before {
  width: 19px;
  background-image: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/icon-fb.svg");
}

.footer-kuromi-collection__share__social-item--tw::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/icon-tw.svg");
}

.footer-kuromi-collection__share__social-item--line::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/icon-line.svg");
}

.footer-kuromi-collection__share__copyright {
  font: 300 11px / 2 "Noto Sans", sans-serif;
  text-align: center;
  margin: 0;
}

@media (min-width: 768px) {
  .footer-kuromi-collection__share {
    padding: 0 0 30px;
  }
  .footer-kuromi-collection__share::before {
    top: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 17%, 100% 100%, 0% 100%);
  }
  .footer-kuromi-collection__share__social {
    margin-top: 60px;
    margin-bottom: 83px;
  }
  .footer-kuromi-collection__share__social-item {
    width: 73px;
    height: 73px;
    margin: 0 15px;
  }
  .footer-kuromi-collection__share__social-item::before {
    width: 28px;
    height: 28px;
  }
  .footer-kuromi-collection__share__social-item:hover {
    background-color: black;
    opacity: 1;
  }
  .footer-kuromi-collection__share__social-item:hover::before {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="1 0" /><feFuncG type="table" tableValues="1 0" /><feFuncB type="table" tableValues="1 0" /></feComponentTransfer><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="2" /><feFuncG type="linear" slope="2" /><feFuncB type="linear" slope="2" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: invert(1) brightness(2);
            filter: invert(1) brightness(2);
  }
  .footer-kuromi-collection__share__social-item--fb::before {
    width: 21px;
  }
  .footer-kuromi-collection__share__copyright {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .footer-kuromi-collection__share {
    margin-top: -113px;
  }
  .footer-kuromi-collection__share::before {
    top: -9px;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
  }
}
