.header,.header--fixed{
  width: 100%;
  padding: 16px 20px;
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  top: 0;
  @media (min-width: 1440px) {
    padding-inline: 24px;
  }
  @media (min-width: 1800px) {
    padding-inline: 50px;
  }
}
