@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&family=Zen+Old+Mincho&display=swap");
@import url("https://fonts.cdnfonts.com/css/post-no-bills-colombo");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/webfonts/fa-brands-400.woff2) format("woff2"),
  url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/webfonts/fa-brands-400.ttf) format("truetype")
}

// Fonts
$font-zen-old: "Zen Old Mincho", serif;
$font-zen-new: "Zen Kaku Gothic New", sans-serif;
$font-heading: "Post No Bills Colombo", sans-serif;
$font-inter: "Inter", sans-serif;

// Colors
$black: #000;
$white: #fff;
$gold: #c18b35;
$gold-light: #ffd086;
$red: #b31111;
$red-light: #ff8383;
$brown: #66430b;
$dark: #150707;
$dark-2: #191313;
$grey: #999;
$grey-2: #666;


$breakpoints: (
  xs: 390px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1296px,
  xxl: 1440px
);

@mixin mq-up($size: md) {
  @if type-of($size) == number {
    @media (min-width: #{$size}px) { @content; }
  } @else {
    $breakpoint: map-get($breakpoints, $size);
    @if $breakpoint {
      @media (min-width: $breakpoint) { @content; }
    } @else {
      @media (min-width: map-get($breakpoints, md)) { @content; }
    }
  }
}

@mixin mq-down($size: md) {
  @if type-of($size) == number {
    @media (max-width: #{$size - 1}px) { @content; }
  } @else {
    $breakpoint: map-get($breakpoints, $size);
    @if $breakpoint {
      @media (max-width: ($breakpoint - 1)) { @content; }
    } @else {
      @media (max-width: (map-get($breakpoints, md) - 1)) { @content; }
    }
  }
}

.l-zeon {
  color: $white;
  background-color: $black;
  font: 500 14px/220% $font-zen-new;
  letter-spacing: normal;
  min-height: 100vh;
  overflow: hidden;

  // Reset
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    color: inherit;
    background: none;
  }

  ul,ol {
    list-style: none;
  }
  button {
    cursor: pointer;
    background-color: unset;
  }
  button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
  }
  a {
    cursor: pointer;
    text-decoration: none !important;
    outline: none;
    display: block;
  }

  @include mq-up(lg) {
    font-size: 16px;
    line-height: 250%;
  }

  // Container
  &__container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: calc(1280px + 40px);
  }

  // Heading
  &__heading {
    font: 400 36px/28px $font-heading;
    letter-spacing: 3.6px;
    display: flex;
    span {
      font: inherit;
      letter-spacing: inherit;
      position: relative;
    }
    &--border {
      span {
        padding-bottom: calc(19px + 13px);
        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 17px;
          border-top: 1px solid $white;
        }
        &::before {
          bottom: 13px;
          width: 84px;
        }
        &::after {
          bottom: 0;
          width: 46px;
        }
      }
    }
    &--center {
      justify-content: center;
      span {
        &::before,
        &::after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &--bold {
      font-weight: 700 !important;
    }
    @include mq-up() {
      &--pc {
        &-center {
          justify-content: center;
          span {
            &::before,
            &::after {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    @include mq-up(lg) {
      font-size: 48px;
      line-height: 28px;
      letter-spacing: 4.8px;
      &--border {
        span {
          padding-bottom: 46px;
          &::before {
            bottom: 16px;
            width: 225px;
          }
          &::after {
            width: 123px;
          }
        }
      }
    }
  }

  // Button
  &__btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-radius: 10rem;
    background: $gold url("https://www.owndays.com/images/specials/products/gundam5-zeon/arrow-right.svg") no-repeat right 28px center/8px auto;
    color: $white;
    font: 600 16px/1 $font-zen-new;
    &:hover {
      color: $white;
    }
    &:focus,
    &:active,
    &:visited {
      color: $white;
    }
    &--red {
      background-color: $red;
      border-color: $red;
      border-radius: 8px;
    }
    &--pill {
      border-radius: 10rem;
    }
    &--reservation {
      background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/arrow-up-down.svg");
      background-position: right 24px center;
      background-size: 19px auto;
    }
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
    @include mq-up(lg) {
      height: 85px;
      font-size: 18px;
      letter-spacing: 1px;
      background-position: right 40px center;
      background-size: 9px auto;
      &:hover {
        color: $white;
        animation: shine 2s;
      }
      &--red {
        height: 80px;
        &:hover {
          animation: shine-red 2s;
        }
      }
      &--reservation {
        background-position: right 37px center;
        background-size: 25px auto;
      }
    }
  }

  // Main
  .main {
    background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/main_bg-sp.webp") no-repeat center top/cover;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        0deg,
        $black 0%,
        rgba($black, 0.5) 40%,
        rgba($black, 0) 95%
      );
      z-index: 3;
    }
    .l-zeon__container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 150px;
      z-index: 4;
    }
    &__fg {
      position: absolute;
      left: 0;
      right: 0;
      img{
        width: 100%;
      }
      &--1 {
        top: 0;
        z-index: 0;
      }
      &--2 {
        bottom: -60px;
        z-index: 2;
      }
    }
    &__zeon-logo {
      position: absolute;
      top: 26px;
      right: 15px;
      width: 44px;
    }
    &__char {
      position: relative;
      z-index: 1;
    }
    &__heading {
      display: flex;
      margin-bottom: 75px;
      h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 17px;
        img {
          max-width: 281px;
          margin: 0 auto;
        }
        span {
          display: inline-flex;
          justify-content: center;
          color: $gold;
          border-width: 1px 0 1px 0;
          border-style: solid;
          border-color: $gold;
          font: 900 13px/16px $font-zen-old;
          letter-spacing: 2.08px;
          padding: 5px 28px 7px;
        }
      }
    }
    &__scroll-down {
      display: flex;
      justify-content: center;
      a {
        display: inline-flex;
        width: 100px;
        height: 100px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/scroll-down.svg") no-repeat center/contain;
          animation: rotation 10s infinite linear;
        }
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 32px;
          height: 48px;
          background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/arrow-down.svg") no-repeat center/contain;
          font: 0/0 a;
          transition: all 0.2s ease-in;
        }
      }
    }
    &__period {
      margin-bottom: 2px;
      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 94px;
        height: 94px;
        border-radius: 100%;
        background-color: $gold;
        margin-left: auto;
        span {
          font-family: $font-zen-new;
          font-weight: 700;
          &.y {
            font-size: 10px;
            line-height: 17px;
            letter-spacing: 1px;
            margin-top: -6px;
          }
          &.m-d {
            font-size: 20px;
            line-height: 17px;
            letter-spacing: 1.2px;
            margin-top: -2px;
            margin-bottom: 3px;
            small {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background-color: #7e530d;
              padding: 2px 1px 3px 2px;
              font-size: 8px;
              font-weight: 700;
              line-height: 5px;
              letter-spacing: 0.8px;
              margin-left: 2px;
            }
          }
          &.t {
            font-size: 9px;
            line-height: 6px;
            letter-spacing: 0.18px;
            margin-bottom: 1px;
          }
          &.text {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 1.2px;
          }
        }
      }
    }
    @include mq-up() {
      background-repeat: repeat-x;
      background-size: auto 100%;
      background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/main_bg-pc.webp");
      &__char {
        margin-left: calc(50% - 507px);
      }
      &__fg {
        img {
          object-fit: contain;
        }
        &--1 {
          img {
            max-height: 422px;
            object-position: right top;
          }
        }
        &--2 {
          img {
            max-height: 685px;
            object-position: right top;
          }
        }
      }
      &__period {
        position: absolute;
        top: calc(50% + 10rem);
        left: 0;
        right: 0;
        padding: 0 20px;
        margin-bottom: 0;
      }
    }
    @include mq-up(lg) {
      .l-zeon__container {
        justify-content: space-between;
        padding-top: 313px;
        padding-bottom: 124px;
      }
      &__zeon-logo {
        width: 76px;
      }
      &__fg {
        &--2 {
          bottom: -35px;
        }
      }
      &__heading {
        h1 {
          row-gap: 28px;
          img {
            max-width: 525px;
          }
          span {
            font-size: 23px;
            line-height: 16px;
            letter-spacing: 3.68px;
            padding: 25px 0 27px;
          }
        }
      }
      &__scroll-down {
        a {
          width: 150px;
          height: 150px;
          i {
            width: 48px;
            height: 73px;
          }
          &:hover {
            i {
              animation: move 1s infinite linear;
            }
          }
        }
      }
      &__period {
        top: calc(50% + 4rem);
        &-wrap {
          width: 168px;
          height: 168px;
          span {
            &.y {
              font-size: 15px;
              line-height: 26px;
              letter-spacing: 1.5px;
              margin-top: -15px;
            }
            &.m-d {
              font-size: 34px;
              letter-spacing: 3.4px;
              margin-top: 5px;
              margin-bottom: 7px;
              small {
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 1.4px;
                padding: 0 2px 1px 3px;
                margin-left: 4px;
                transform: translateY(-5px);
              }
            }
            &.t {
              font-size: 15px;
              letter-spacing: 0.3px;
              margin-bottom: 10px;
            }
            &.text {
              font-size: 21px;
              letter-spacing: 2.1px;
            }
          }
        }
      }
    }
    @include mq-up(xl) {
      &__zeon-logo {
        top: 70px;
        right: 60px;
      }
      &__heading {
        padding-left: 60px;
      }
      &__period {
        padding: 0 70px;
      }
    }
  }

  // Concept
  .concept {
    position: relative;
    z-index: 3;
    margin-top: -1px;
    .l-zeon__heading {
      margin-bottom: 50px;
    }
    &__text {
      padding: 0 30px;
      max-width: 800px + 60px;
      margin: 0 auto 90px;
    }
    &__movie {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
      &-wrap {
        max-width: 800px + 60px;
        margin: 0 auto;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 64px;
        height: 64px;
        background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/btn_play.svg") no-repeat center/contain;
      }
      img {
        transition: all 0.3s ease;
      }
      iframe {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.3s ease;
      }
    }
    @include mq-up(lg) {
      .l-zeon__heading {
        margin-bottom: 60px;
      }
      &__text {
        margin-bottom: 100px;
      }
      &__movie {
        &::after {
          width: 87px;
          height: 87px;
        }
        &:hover {
          picture,
          img {
            opacity: 1;
          }
          img,iframe {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  // Lineup
  .lineup {
    &__item {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        background-repeat: repeat-x;
        background-position: center;
        background-size: auto 100%;
      }
      &--glasses {
        padding: 115px 0 0;
        &::before {
          top: -199px;
          bottom: -189px;
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/lineup_bg-1-sp.webp");
        }
        .lineup__char {
          top: 289px;
          left: calc(50% - 333px);
        }
      }
      &--sunglasses {
        padding: 120px 0 94px;
        &::before {
          top: -121px;
          bottom: -168px;
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/lineup_bg-2-sp.webp");
        }
        .lineup__char {
          top: 248px;
          left: calc(50% - 274px);
        }
      }
    }
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    &__char {
      width: 750px;
      height: 1080px;
      position: absolute;
      z-index: 1;
    }
    &__content {
      position: relative;
      z-index: 2;
    }
    &__heading {
      display: flex;
      row-gap: 25px;
      padding: 0 20px;
      margin-bottom: 497px;
      &-logo {
        max-width: 31px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        h2 {
          color: $gold;
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          font: 500 26px/160% $font-zen-new !important;
          letter-spacing: 1.3px;

          small {
            font: 900 14px/16px $font-zen-old;
            letter-spacing: 2.24px;
          }
        }
        p {
          font: 500 14px/165% $font-zen-new;
          strong {
            font-family: inherit !important;
            font-weight: 600 !important;
            font-size: 20px;
            margin: 0 0.25em;
          }
          small {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    &__product {
      position: relative;
      display: flex;
      flex-direction: column;
      &-slider {
        img {
          width: 337px;
          padding: 10px 0;
          background-color: $white;
        }
        .slick {
          display: none;
          &.active {
            display: block;
          }
          &-slide > div {
            display: flex;
            margin: 0 6px;
          }
        }
      }
      &-details {
        padding: 25px 20px 63px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -125px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba($black, 0.8);
          background-blend-mode: multiply;
          z-index: -1;
        }
      }
      &-actions {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
      }
      &-colors {
        display: flex;
        column-gap: 15px;
        li {
          display: flex;
          button {
            display: flex;
            outline: none;
            padding: 0;
            font: 0/0 a;
            width: 57px;
            height: 26px;
            border: 2px solid transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            cursor: pointer;
            &.active {
              border-color: $gold;
            }
          }
        }
      }
      &-color {
        margin-left: 20px;
        font: 500 14px/165% $font-zen-new;
      }
      &-nav {
        margin-left: auto;
        display: flex;
        align-items: center;
        column-gap: 15px;
        &-btn {
          outline: none;
          font: 0/0 a;
          padding: 0;
          background-color: $white;
          background-repeat: no-repeat;
          background-size: 16px auto;
          border: 1px solid $grey-2;
          border-radius: 100%;
          width: 80px;
          height: 80px;
          transition: transform 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
          &--prev {
            background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/chevron-left.svg");
            background-position: 45% center;
          }
          &--next {
            background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/chevron-right.svg");
            background-position: 55% center;
          }
        }
      }
      &-des {
        margin-bottom: 38px;
      }
    }
    @include mq-down(lg) {
      &__heading {
        flex-direction: column;
        &-logo {
          margin: 0 auto;
        }
        &-text {
          text-align: center;
        }
      }
      &__product {
        &-actions {
          padding: 0 7px;
        }
        &-nav {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
        &-des {
          text-align: justify;
          padding: 0 30px;
        }
        &-link {
          padding: 0 10px;
        }
      }
    }
    @include mq-up() {
      &__item {
        &--glasses {
          &::before {
            background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/lineup_bg-1-pc.webp");
          }
        }
        &--sunglasses {
          &::before {
            background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/lineup_bg-2-pc.webp");
          }
        }
      }
    }
    @include mq-up(lg) {
      &__item {
        &--glasses {
          padding: 263px 0 242px;
          .lineup {
            &__char {
              top: 218px;
              left: auto;
              right: calc(50% - 109px);
            }
            &__content {
              margin-left: calc(50% - 190px);
            }
            &__heading {
              padding-left: 83px;
            }
            &__product {
              &-slider {
                margin-left: 70px;
                .slick-slide > div {
                  margin: 0 30px 0 0;
                }
              }
            }
          }
        }
        &--sunglasses {
          padding: 137px 0 371px;
          .lineup {
            &__char {
              top: 202px;
              left: calc(50% + 25px);
            }
            &__content {
              margin-left: auto;
              margin-right: calc(50% - 190px);
            }
            &__heading {
              padding-left: 100px;
            }
            &__product {
              &-slider {
                align-self: flex-end;
                margin-right: 70px;
                img {
                  width: 100%;
                }
                .slick-slide > div {
                  margin: 0 0 0 30px;
                }
              }
              &-details {
                margin-left: auto;
              }
            }
          }
        }
      }
      &__char {
        width: 972px;
        height: 1374px;
      }
      &__heading {
        column-gap: 28px;
        margin-bottom: 58px;
        &-logo {
          max-width: 72px;
        }
        &-text {
          row-gap: 29px;
          h2 {
            font-size: 32px !important;
            letter-spacing: 5.12px;
            row-gap: 12px;
            small {
              font-size: 20px;
              letter-spacing: 3.2px;
            }
          }
          p {
            font-size: 16px;
            strong {
              font-size: 24px;
            }
            small {
              font-size: 16px;
            }
          }
        }
      }
      &__product {
        &-slider {
          width: calc((530px * 2) + 60px);
          img {
            width: 100%;
            padding: 18px 0;
          }
        }
        &-details {
          padding: 30px 70px 60px;
          max-width: 870px;
        }
        &-actions {
          margin-bottom: 35px;
        }
        &-colors {
          li {
            button {
              width: 100px;
              height: 45px;
            }
          }
        }
        &-color {
          margin-left: 25px;
          font-size: 18px;
        }
        &-des {
          margin-bottom: 35px;
        }
      }
    }
    @include mq-up(xl) {
      &__product {
        &-slider {
          width: calc((630px * 2) + 60px);
        }
      }
    }
  }

  // Accessories
  .accessories {
    position: relative;
    .l-zeon__heading {
      margin-bottom: 50px;
    }
    &__inner {
      background-color: $dark-2;
      padding: 100px 30px;
    }
    &__grid {
      display: grid;
      grid-gap: 40px;
    }
    @include mq-up() {
      &__grid {
        grid-template-columns: calc(50% + 27px) 1fr;
        align-items: center;
      }
    }
    @include mq-up(lg) {
      .l-zeon__heading {
        margin-bottom: 70px;
      }
      &__inner {
        padding: 110px 60px;
      }
      &__grid {
        grid-gap: 50px;
      }
    }
  }

  // Stores
  .stores {
    padding: 100px 0;
    .l-zeon {
      &__container {
        max-width: calc(875px + 40px);
      }
      &__heading {
        margin-bottom: 40px;
      }
      &__btn {
        max-width: 700px;
        margin: 0 auto;
      }
    }
    &__text {
      text-align: center;
      font: 500 20px/170% $font-zen-new;
      letter-spacing: 1px;
      small {
        font-weight: inherit;
        font-size: 16px;
        letter-spacing: 0.8px;
      }
      i {
        font-weight: inherit;
        font-size: 9px;
        letter-spacing: 0.45px;
      }
    }
    &__action {
      padding: 0 10px 50px;
    }
    &__notes {
      margin-top: 15px;
      font: 500 12px/220% $font-zen-new;
      display: flex;
      flex-direction: column;
      color: $grey;
      font: 400 13px/220% $font-inter;

      li {
        position: relative;
        padding-left: 1.5em;
        &::before {
          content: "*";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    @include mq-up(lg) {
      padding: 195px 0 150px;
      .l-zeon {
        &__heading {
          margin-bottom: 50px;
        }
      }
      &__text {
        font-size: 30px;
        letter-spacing: 1.5px;
        small {
          font-size: 21px;
          letter-spacing: 1.05px;
        }
        i {
          font-size: 14px;
          letter-spacing: 0.7px;
        }
      }
      &__note {
        margin-top: 20px;
        font-size: 14px;
        letter-spacing: 0.7px;
      }
      &__action {
        padding-bottom: 70px;
      }
    }
  }

  // Case
  .case {
    position: relative;
    padding: 60px 10px 153px;
    background: url("https://www.owndays.com/images/specials/products/gundam5-zeon/case_bg-sp.webp") no-repeat center top/cover;
    .l-zeon__container {
      position: relative;
      z-index: 1;
    }
    &__img {
      position: absolute;
      top: 26px;
      left: 50%;
      transform: translateX(-50%);
      width: 295px;
      z-index: 0;
    }
    &__badge {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px 12px 8px;
      background-color: $brown;
      color: $white;
      font: 500 13px/16px $font-zen-new;
      letter-spacing: 3.9px;
    }
    &__heading {
      margin-top: 226px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      font: 900 36px/44px $font-zen-old;
      letter-spacing: 1.44px;
      row-gap: 3px;
      margin-bottom: 12px;
      small {
        font-size: 13px;
        font-weight: inherit;
        line-height: normal;
        letter-spacing: 1.17px;
      }
    }
    &__zeon-owndays {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      text-align: center;
      margin-bottom: 46px;
      h3 {
        max-width: 187px;
        margin: 0 auto;
      }
      p {
        display: inline-flex;
        justify-content: center;
        align-items: baseline;
        font: 500 12px/28px $font-zen-new;
        letter-spacing: 3px;
        padding: 0 44px 4px;
        border-width: 1px 0 1px 0;
        border-style: solid;
        border-color: $white;
        strong {
          font-weight: 900;
          font-size: 16px;
          line-height: inherit;
          letter-spacing: 2.08px;
        }
      }
    }
    &__action {
      max-width: 400px;
      margin: 0 auto;
    }
    @include mq-up() {
      padding: 94px 0;
      background-repeat: repeat-x;
      background-size: auto 100%;
      background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/case_bg-pc.webp");
      &__img {
        top: calc(-57px / 2);
        left: auto;
        right: calc(50% + 40px);
        transform: translateX(0);
      }
      &__content {
        max-width: 440px;
        margin-left: 50%;
      }
      &__badge {
        display: flex;
        width: fit-content;
        margin: 0 auto;
      }
      &__heading {
        margin-top: 20px;
      }
    }
    @include mq-up(lg) {
      &__img {
        top: -57px;
        right: calc(50% + 55px);
        width: 440px;
      }
      &__heading {
        row-gap: 10px;
        font-size: 45px;
        line-height: 48px;
        letter-spacing: 1.8px;
        margin-bottom: 23px;
        small {
          font-size: 16px;
          line-height: normal;
          letter-spacing: 1.44px;
        }
      }
      &__zeon-owndays {
        row-gap: 30px;
        h3 {
          max-width: 267px;
        }
        p {
          padding: 4px 56px 8px;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 3.75px;
          strong {
            font-size: 19px;
            line-height: inherit;
            letter-spacing: 2.47px;
          }
        }
      }
    }
  }

  // Share
  .share {
    padding: 60px 0 100px;

    &__inner {
      background-color: $dark;
      padding: 43px 0 62px;
    }
    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 40px;
      li {
        a {
          display: inline-flex;
          color: $white;
          font-size: 35px;
        }
      }
    }
    @include mq-up() {
      .l-zeon__container {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: calc(57px / 2);
      }
    }
    @include mq-up(lg) {
      padding-bottom: 110px;
      .l-zeon {
        &__container {
          column-gap: 57px;
        }
        &__heading {
          font-size: 40px;
          line-height: 44px;
          letter-spacing: 4px;
        }
      }
      &__inner {
        padding-top: 30px;
        padding-bottom: 26px;
      }
      &__list {
        column-gap: 36px;
      }
    }
  }
}

.fancybox-container {
  .fancybox {
    &-infobar {
      top: calc(50% + 62vw);
      left: 50%;
      transform: translateX(-50%);
      color: $white;
    }
    &-button {
      &--zoom,
      &--play {
        display: none !important;
      }
      &--close {
        background-color: $black;
      }
    }
    &-navigation {
      position: absolute;
      top: calc(50% + 50vw);
      left: 0;
      right: 0;
      z-index: 99997;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;
      .fancybox-button {
        position: static;
        padding: 0;
        width: 60px;
        height: 60px;
        background-color: $white;
        border-radius: 100%;
        border: 1px solid #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px auto;
        svg {
          display: none !important;
        }
        &--arrow_left {
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/nav-prev.svg");
        }
        &--arrow_right {
          background-image: url("https://www.owndays.com/images/specials/products/gundam5-zeon/nav-next.svg");
        }
        &[disabled] {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
    }
    &-caption {
      top: calc(50% + 38vw);
      left: 0;
      bottom: auto;
      text-align: left;
      padding: 0 20px;
      max-width: 780px;
      margin: 0 auto;
      background: none;
    }
  }
}

.fa-brands,.fab {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-weight: 400;
}

.fa-facebook:before {
  content: "\f09a"
}
.fa-x-twitter:before {
  content: "\e61b"
}
.fa-line:before {
  content: "\f3c0"
}

#zendesk-chat-widget {
  z-index: 9;
}

@include mq-down(1153) {
  .breadcrumbContainer_wrapper {
    transform: translate(20px, 4px) !important;
  }
}

// Animations
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes move {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-50%, -35%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  75% {
    transform: translate(-50%, -65%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes shine {
  0% {
    background-color: #c18b35;
  }
  8% {
    background-color: #ffd086;
  }
  100% {
    background-color: #c18b35;
  }
}
@keyframes shine-red {
  0% {
    background-color: #b31111;
  }
  8% {
    background-color: #ff8383;
  }
  100% {
    background-color: #b31111;
  }
}