.logo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
}

.logo > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.logo__image {
  width: 95px;
}

@media (min-width: 992px) {
  .logo__image {
    width: 126.66667px;
  }
}

@media (min-width: 1200px) {
  .logo__image {
    width: 152px;
  }
}

@media (min-width: 1440px) {
  .logo__image {
    width: 190px;
  }
}

.blank-logo {
  height: 40px;
  width: 100%;
  overflow: hidden;
}

.blank-logo .blankwidgets {
  grid-template-columns: repeat(1, 1fr);
  padding: 0px;
}

.blank-logo .blankwidgets .blankwidgets_box {
  border: none;
}

.blank-logo .blankwidgets .blankwidgets_box .blankwidgets_box_headerWrap {
  width: 100%;
}

.blank-logo .blankwidgets .blankwidgets_box .blankwidgets_box_headerWrap .blankwidgets_box_animatedBackground_header {
  height: 40px;
}

.blank-logo .blankwidgets .blankwidgets_box .blankwidgets_box_imageWrap,
.blank-logo .blankwidgets .blankwidgets_box .blankwidgets_box_footerWrap {
  display: none;
}
