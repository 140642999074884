.corporate-information-table {
  display: grid;
  grid-template-columns: 4fr 7.3fr;
  -webkit-column-gap: 67px;
     -moz-column-gap: 67px;
          column-gap: 67px;
  row-gap: 50px;
}

.corporate-information-table__cell--title {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0em;
  color: #000000;
}

.corporate-information-table__cell--info {
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  color: #000000;
}

.corporate-information-table__list {
  padding-left: 10px;
  list-style-type: unset !important;
}

.corporate-information-table__list-item {
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  list-style-type: unset !important;
}

.corporate-information-table__list-item--link {
  color: #7AA4F4;
}

.corporate-information-table__link {
  color: #7AA4F4;
  text-decoration: underline;
}

.corporate-information-table__link:hover {
  color: #7AA4F4;
}
