.corporate-information-table {
    display: grid;
    grid-template-columns: 4fr 7.3fr;
    column-gap: 67px;
    row-gap: 50px;

    &__cell {
        &--title {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0em;
            color: #000000;
        }

        &--info {
            font-size: 20px;
            line-height: 23px;
            letter-spacing: 0em;
            color: #000000;
        }
    }

    &__list {
        padding-left: 10px;
        list-style-type: unset!important;
    }

    &__list-item {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0em;
        list-style-type: unset!important;

        &--link {
            color: #7AA4F4;
        }
    }

    &__link {
        color: #7AA4F4;
        text-decoration: underline;

        &:hover {
            color: #7AA4F4;
        }
    }
}