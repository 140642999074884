$font-founders: 'Founders Grotesk', sans-serif;

.header-gift-ticket {
  @media (max-width: 430px) {
    &__breadcrumbs {
      nav ol li:last-child {
        &::before {
          padding-left: 0;
        }
      }
    }
  }

  &__banner {
    position: relative;

    picture {
      img {
        display: block;
        width: 100%;
        height: 510px;
        object-fit: cover;

        @media (min-width: 768px) {
          height: auto;
        }
      }
    }

    &__message {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      max-width: 66%;
      padding: 50px 5% 0 5%;

      &__container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
      }

      &__logo {
        width: 147px;
        height: auto;
        margin: 0 auto 15px auto;
      }

      &__desc {
        margin-bottom: 0;
        font-family: $font-founders;
        font-weight: normal !important;
        font-size: 12px;
        text-align: center;
        line-height: 2;
        color: white;
      }

      @media (min-width: 768px) {
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 80%;
        padding: 50px 0;
        margin: 0 auto;

        &__logo {
          width: 200px;
          margin: 0 0 30px 0;
        }

        &__desc {
          font-size: 14px;
          text-align: left;
        }
      }

      @media (min-width: 1024px) {
        &__logo {
          width: 226px;
        }

        &__desc {
          font-size: 20px;
        }
      }
    } 
  }
}
