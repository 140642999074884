$clear-contactlens: #59b2e7;
$color-contactlens: #71475e;

.contact-category {
  font-size: 14px;
  line-height: 2;
  letter-spacing: .8px;
  width: 100%;
  overflow-x: hidden;
  img {
    display: block;
    width: 100%;
  }
  h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    line-height: 1;
    letter-spacing: 0.01rem;
    position: relative;
    margin: 0 0 60px;
    padding-bottom: 60px;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
    }
  }
  &__btn {
    text-decoration: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    padding: 1rem;
    overflow: hidden;
    text-transform: uppercase;
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 275px;
    height: 64px;
    margin-inline: auto;
    &::before {
      content: '';
      position: absolute;
      left: -10%;
      bottom: 0;
      width: 0;
      height: 120%;
      transform: skewX(15deg);
      z-index: -1;
      transition: all 0.3s ease;
    }
    &:hover {
      text-decoration: none;
      outline: none;
      &::before {
        width: 120%;
      }
    }
    &:focus{
      text-decoration: none;
      outline: none;
    }
    &--primary,&--primary:focus {
      background-color: black;
      border-color: black;
      color: white;
      &::before {
        background-color: #f4f4f4;
      }
      &:hover {
        color: black;
      }
    }
    &--secondary,&--secondary:focus {
      background-color: #f4f4f4;
      border-color: black;
      color: black;
      &::before {
        background-color: black;
      }
      &:hover {
        color: white;
      }
    }
  }
  &__header {
    padding: 96px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    gap: 30px;
    position: relative;
    h1 {
      font: 0/0 a;
      margin: 0;
      img {
        max-width: 230px;
        margin-inline: auto;
      }
    }
    p {
      width: fit-content;
      text-align: center;
      margin: 0 auto;
      background-color: black;
      color: white;
      padding: 7px 10px;
      font-size: 20px;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 1px;
    }
    svg {
      scale: -1;
      min-height: 50px;
      position: absolute;
      bottom: 0;
    }
  }
  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: calc(1152px + 40px);
    &--lg {
      max-width: calc(1300px + 40px);
    }
  }
  &__banner {
    margin-block: 50px;
    a {
      img {
        transition: opacity 0.3s ease;
      }
    }
    a:hover {
      img {
        opacity: 0.5;
      }
    }
  }
  &__lineup {
    margin-block: 80px;
    &-list {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 70px;
      > li {
        text-align: center;
        width: 100%;
        max-width: 300px;
        img {
          max-width: 250px;
          object-fit: contain;
          filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
          margin: 0 auto 32px;
          &.vertical{
            max-height: 235px;
          }
          &.horizontal{
            max-height: 92px;
          }
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 5px;
          margin-bottom: 10px;
          li {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            font-weight: 600;
            letter-spacing: 0.08em;
            line-height: 1.6em;
            padding: 3px 8px;
            border-radius: 10rem;
          }
        }
        h4 {
          font-weight: 800;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 1px;
          margin: 0 0 5px;
        }
        p {
          color: black;
          font-size: 16px;
          margin: 0 0 16px;
        }
      }
    }
  }
  &__htu {
    background-color: #f4f4f4;
    padding-block: 96px;
    img{
      max-width: 200px;
      margin: 64px auto;
    }
    p{
      margin-bottom: 32px;
    }
  }
  &__note{
    margin-block: 80px;
    p{
      margin-bottom: 24px;
      ~ h2{
        margin-top: 80px;
      }
    }
    ol{
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      li{
        h3,p{
          margin: 0;
        }
        h3{
          font-size: inherit;
          font-weight: 600;
          line-height: 2;
        }
      }
    }
  }
  &--clear-contactlens {
    h2::before {
      background-color: $clear-contactlens;
    }
    .contact-category__lineup-list > li {
      color: $clear-contactlens;
      ul li {
        background-color: rgba($clear-contactlens, 0.15);
      }
    }
  }
  &--color-contactlens {
    h2::before {
      background-color: $color-contactlens;
    }
    .contact-category__lineup-list {
      max-width: 1000px;
      > li {
        color: $color-contactlens;
        ul li {
          background-color: rgba($color-contactlens, 0.15);
        }
      }
    }
  }
  @media (min-width: 768px) {
    &__htu{
      p{
        text-align: center;
      }
    }
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    h2{
      font-size: 50px;
    }
    &__banner,&__lineup,&__note{
      margin-block: 120px;
    }
    &__htu{
      padding-block: 120px;
    }
    &__header {
      padding-block: 192px;
      h1 {
        img {
          max-width: 300px;
        }
      }
    }
  }
}
