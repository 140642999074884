.slider-owndays-pc {
  .slick-list {
    padding: 0 50% !important;
  }

  .slick-slide {
    transition: all 0.3s ease;
    padding: 0 5px;
    
    div {
      li {
        max-width: 580px;
        margin: 0 15px;
        transition: width 0.3s ease;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__btn-prev, &__btn-next {
    display: none;
  }

  @media (min-width: 1024px) {
    position: relative;
    padding: 0 12%;

    &__btn-prev, &__btn-next {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &__btn-prev {
      left: 4%;
    }

    &__btn-next {
      right: 4%;
    }
  }

  @media (min-width: 1296px) {
    .slick-list {
      padding: 0 !important;
    }
  }
}
