.od-footer-owndays-progressive-cp__container__free-enhanced-header {
  width: 100%;
  height: 70px;
  background-color: #BF0000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-top: 150px;
}

@media (max-width: 1196px) {
  .od-footer-owndays-progressive-cp__container__free-enhanced-header {
    height: auto;
    padding: 0 120px;
  }
}

@media (max-width: 802px) {
  .od-footer-owndays-progressive-cp__container__free-enhanced-header {
    padding: 0 60px;
  }
}

@media (max-width: 687px) {
  .od-footer-owndays-progressive-cp__container__free-enhanced-header {
    padding: 0 40px;
  }
}

@media (max-width: 641px) {
  .od-footer-owndays-progressive-cp__container__free-enhanced-header {
    height: 83px;
    margin-top: 100px;
  }
}

@media (max-width: 455px) {
  .od-footer-owndays-progressive-cp__container__free-enhanced-header {
    padding: 0 20px;
  }
}

@media (max-width: 414px) {
  .od-footer-owndays-progressive-cp__container__free-enhanced-header {
    padding: 0;
  }
}

.od-footer-owndays-progressive-cp__container__free-enhanced-body-box {
  width: 100%;
  height: auto;
  background-color: #D7DCE2;
}

.od-footer-owndays-progressive-cp__container__free-enhanced-body {
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  position: relative;
}

.od-footer-owndays-progressive-cp__container__book-online-btn {
  background-color: #EEF0F2;
  padding: 150px 0;
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp__container__book-online-btn {
    padding: 100px 20px;
  }
}

.od-footer-owndays-progressive-cp__container__term-and-con-modal-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .3);
}

.od-footer-owndays-progressive-cp__container__term-and-con-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 900px;
  height: auto;
  min-height: 629px;
  background-color: #fff;
}

@media (max-width: 1024px) {
  .od-footer-owndays-progressive-cp__container__term-and-con-modal {
    width: calc(100% - 120px);
    height: calc(100% - 120px);
  }
}

@media (max-width: 768px) {
  .od-footer-owndays-progressive-cp__container__term-and-con-modal {
    width: calc(100% - 60px);
    height: calc(100% - 120px);
  }
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp__container__term-and-con-modal {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}

.od-footer-owndays-progressive-cp .free-enhanced-header {
  color: #fff;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
  letter-spacing: 3px;
  text-align: center;
  margin: 0;
}

.od-footer-owndays-progressive-cp .free-enhanced-header br {
  display: none;
}

@media (max-width: 1201px) {
  .od-footer-owndays-progressive-cp .free-enhanced-header br {
    display: block;
  }
}

@media (max-width: 641px) {
  .od-footer-owndays-progressive-cp .free-enhanced-header {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

.od-footer-owndays-progressive-cp .free-enhanced-body__desc-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 552px;
  height: auto;
  margin-left: 120px;
  padding: 50px 0 45px 0;
}

.od-footer-owndays-progressive-cp .free-enhanced-body__desc-box p {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.9px;
  margin-bottom: 0;
}

.od-footer-owndays-progressive-cp .free-enhanced-body__desc-box p:nth-child(2) {
  margin-top: 20px;
}

@media (max-width: 430px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__desc-box p {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.8px;
  }
}

@media (max-width: 1399px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__desc-box {
    padding: 62% 0 50px 0;
    width: calc(100% - 120px);
    margin: 0 auto;
  }
}

@media (max-width: 430px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__desc-box {
    padding: 67% 0 35px 0;
    width: calc(100% - 80px);
  }
}

.od-footer-owndays-progressive-cp .free-enhanced-body__remark-box {
  margin-top: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__text {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.7px;
}

.od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__link-open-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  margin-top: 12px;
  position: relative;
  cursor: pointer;
}

.od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__link-open-modal::before {
  position: absolute;
  bottom: 2px;
  content: '';
  width: 97px;
  border-bottom: 1px solid #000;
}

.od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__link-open-modal img {
  width: 10px;
  height: 10px;
}

@media (max-width: 430px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__remark-box {
    margin-top: 28px;
  }
  .od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__text {
    font-size: 12px;
    letter-spacing: 0.6px;
  }
  .od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__link-open-modal {
    gap: 4px;
    margin-top: 0;
  }
  .od-footer-owndays-progressive-cp .free-enhanced-body__remark-box__link-open-modal::before {
    width: 83px;
  }
}

.od-footer-owndays-progressive-cp .free-enhanced-body__img {
  width: 730px;
  height: auto;
  position: absolute;
  right: 0;
  top: 33px;
}

@media (max-width: 1399px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__img {
    width: calc(100% - 22px);
    top: 30px;
  }
}

@media (max-width: 768px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__img {
    width: calc(100% - 40px);
  }
}

@media (max-width: 430px) {
  .od-footer-owndays-progressive-cp .free-enhanced-body__img {
    width: calc(100% - 30px);
  }
}

.od-footer-owndays-progressive-cp .book-online-btn {
  width: 500px;
  height: 80px;
  border-radius: 40px;
  background-color: #283750;
  padding: 29px 20px 28px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  cursor: pointer;
  border: 3px solid #283750;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.od-footer-owndays-progressive-cp .book-online-btn:hover, .od-footer-owndays-progressive-cp .book-online-btn:active, .od-footer-owndays-progressive-cp .book-online-btn:visited, .od-footer-owndays-progressive-cp .book-online-btn:focus {
  text-decoration: none;
}

@media (min-width: 768px) {
  .od-footer-owndays-progressive-cp .book-online-btn:hover {
    text-decoration: none;
    background-color: #fff;
    border: 3px solid #283750;
  }
  .od-footer-owndays-progressive-cp .book-online-btn:hover span {
    color: #283750;
  }
  .od-footer-owndays-progressive-cp .book-online-btn:hover img {
    content: url("https://static.lenskart.com/media/owndays/img/progressive-cp/arrow-right-circle-blue-icon.svg");
  }
}

.od-footer-owndays-progressive-cp .book-online-btn span {
  color: #fff;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  letter-spacing: 2px;
}

.od-footer-owndays-progressive-cp .book-online-btn img {
  width: 50px;
  height: 50px;
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp .book-online-btn {
    width: 350px;
    height: 60px;
    padding: 25px 10px 25px 40px;
  }
  .od-footer-owndays-progressive-cp .book-online-btn span {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
  }
  .od-footer-owndays-progressive-cp .book-online-btn img {
    width: 40px;
    height: 40px;
  }
}

.od-footer-owndays-progressive-cp .term-and-con-modal {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.od-footer-owndays-progressive-cp .term-and-con-modal__close-icon {
  position: absolute;
  top: 32px;
  right: 25px;
  width: 39px;
  height: 39px;
  cursor: pointer;
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp .term-and-con-modal__close-icon {
    top: 10px;
    right: 10px;
    width: 29px;
    height: 29px;
  }
}

.od-footer-owndays-progressive-cp .term-and-con-modal h2 {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  letter-spacing: 2px;
  padding: 50px 0 32px 0px;
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp .term-and-con-modal h2 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

.od-footer-owndays-progressive-cp .term-and-con-modal ul {
  width: 700px;
  height: auto;
  padding-bottom: 36px;
  list-style-type: none;
}

.od-footer-owndays-progressive-cp .term-and-con-modal ul li {
  font-family: "Founders Grotesk", sans-serif;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 36px;
  letter-spacing: 0.1px;
  margin-left: -21px;
  position: relative;
}

.od-footer-owndays-progressive-cp .term-and-con-modal ul li::before {
  position: absolute;
  top: 18px;
  left: -18px;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
}

@media (max-width: 1024px) {
  .od-footer-owndays-progressive-cp .term-and-con-modal ul {
    width: calc(100% - 120px);
    height: 80%;
    overflow-y: scroll;
  }
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp .term-and-con-modal ul {
    width: calc(100% - 60px);
    height: 100%;
  }
  .od-footer-owndays-progressive-cp .term-and-con-modal ul li {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.8px;
  }
  .od-footer-owndays-progressive-cp .term-and-con-modal ul li::before {
    top: 15px;
  }
}

@media (max-width: 550px) {
  .od-footer-owndays-progressive-cp .term-and-con-modal {
    height: 100%;
  }
}
