.power-status-message {
  background-color: #eee;
  width: 95%;
  .status-icon {
      width: 30px;
      height: 30px;
      .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
  }
  &.desktop {
    width: calc(100% - 30px);
  }

  &__view-details {
    color: #444444;
  }
}
.width_100 {
  width: 100%;
}
.padding_0_8 {
  padding: 0px 8px;
}
.new-style-details{
  color: #66668E;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-family: 'LenskartSans-Regular';
  text-transform: capitalize;
}

.cartView-msg-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex: 1;
  .cartView-primary-msg{
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000042;
    .cartView-basicMsg{
      font-family: 'LenskartSans-Regular';
      font-weight: 400;
    }
    .cartView-moreInfo{
      font-family: 'LenskartSans-Bold';
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .cartView-secondary-msg{
    font-family: 'LenskartSans-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #333368;
  }
}