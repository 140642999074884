.footer-senichisaku {
  padding-bottom: 200px;

  &__img {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 50px;
    line-height: 1.6;
  }

  &__text {
    margin-bottom: 70px;
  }

  picture {
    display: block;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: 1024px) {
    padding-bottom: 270px;

    &__img {
      margin-bottom: 70px;
    }

    &__title {
      font-size: 32px;
      margin-bottom: 100px;
      line-height: 1.9;
    }

    &__text {
      margin-bottom: 130px;
    }
  }
}
