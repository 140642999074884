.lang-popup-wrapper {
  max-width: 790px;
}

.lang-popup-wrapper .lang-popup {
  padding: 0 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 790px;
  margin: 0 auto;
}

.lang-popup-wrapper .lang-popup__cross {
  top: 20px;
  right: 20px;
  margin: 0;
  opacity: 0.5;
}

.lang-popup-wrapper .lang-popup__title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-family: 'Founders Grotesk', sans-serif;
  color: #000;
  padding: 40px 0 30px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: .04em;
}

.lang-popup-wrapper .lang-popup__columns {
  height: 100%;
  padding-bottom: 40px;
  overflow-y: auto;
}

.lang-popup-wrapper .lang-popup .country-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px 16px 20px 0;
  background-color: #fff;
  border-width: 1px 0;
  border-style: solid;
  border-color: #e3e3e3;
  cursor: pointer;
  width: 100%;
  margin-top: -1px;
}

.lang-popup-wrapper .lang-popup .country-item__content__btn {
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Founders Grotesk', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 1.25;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 13px;
  padding: 0;
}

.lang-popup-wrapper .lang-popup .country-item__content__btn img {
  width: 25px;
  height: 16px;
  border: 1px solid #e3e3e3;
  aspect-ratio: 25/12;
  -o-object-fit: cover;
     object-fit: cover;
}

.lang-popup-wrapper .lang-popup .country-item__content__toggle-img {
  opacity: .25;
  width: 18px;
  height: 18px;
  -o-object-fit: contain;
     object-fit: contain;
}

.lang-popup-wrapper .lang-popup .country-item__content.active .country-item__content__btn {
  color: #0094c8;
}

.lang-popup-wrapper .lang-popup .country-item__language-list {
  padding: 8px 0;
  background-color: #f8f8f8;
}

.lang-popup-wrapper .lang-popup .country-item__language-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lang-popup-wrapper .lang-popup .country-item__language-list ul li {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 15px;
  line-height: 1.5;
  color: #666;
}

.lang-popup-wrapper .lang-popup .country-item__language-list ul li.active {
  color: #0094c8;
}

@media screen and (min-width: 768px) {
  .lang-popup-wrapper .lang-popup {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: auto;
    border: 1px solid rgba(0, 0, 0, .2);
    background-clip: padding-box;
    padding: 0 40px 40px;
  }
  .lang-popup-wrapper .lang-popup__title {
    padding: 50px 0 35px;
  }
  .lang-popup-wrapper .lang-popup__columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 30px;
    padding-bottom: 0;
  }
  .lang-popup-wrapper .lang-popup__column {
    margin-top: 1px;
  }
}

@-webkit-keyframes slideIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}

@keyframes slideIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}

@-webkit-keyframes slideOut {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
  }
}

@keyframes slideOut {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
  }
}

.country-item__language-list.open {
  -webkit-animation: slideIn 0.3s ease-in-out forwards;
          animation: slideIn 0.3s ease-in-out forwards;
}

.country-item__language-list.lang-close {
  -webkit-animation: slideOut 0.3s ease-in-out forwards;
          animation: slideOut 0.3s ease-in-out forwards;
}
