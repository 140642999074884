.footer-lens-replacement {
  margin-bottom: 60px !important;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .footer-lens-replacement {
    margin-bottom: 200px !important;
  }
}

.footer-lens-replacement__qa {
  margin-bottom: 80px;
}

.footer-lens-replacement__qa__list {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.footer-lens-replacement__qa__item:last-child-a {
  padding-bottom: 40px;
}

.footer-lens-replacement__qa__item-q {
  padding: 20px 0;
  cursor: pointer;
  position: relative;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  border-top: 1px solid #e3e3e3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer-lens-replacement__qa__item-q--border-top-none {
  border-top: none;
}

.footer-lens-replacement__qa__item-q h3 {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 1.9;
  text-align: left;
  padding: 0 24px 0 48px;
  position: relative;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 1px;
}

.footer-lens-replacement__qa__item-q h3::before {
  content: "Q";
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-weight: 600 !important;
  color: #0094c8;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .footer-lens-replacement__qa__item-q h3::before {
    left: 24px;
  }
}

.footer-lens-replacement__qa__item-navigate {
  padding-right: 24px;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  color: #000;
  letter-spacing: 1px;
}

.footer-lens-replacement__qa__item-a {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.4s ease;
  transition: max-height 0.4s ease;
}

.footer-lens-replacement__qa__item-a p {
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: normal !important;
  letter-spacing: 1px;
  line-height: 2;
  padding: 0 15px 20px 48px;
  margin-bottom: 0;
  position: relative;
}

.footer-lens-replacement__qa__item-a p::before {
  content: "A";
  position: absolute;
  left: 15px;
  top: 0;
  font-weight: 600 !important;
  color: #c7002e;
}

@media (min-width: 768px) {
  .footer-lens-replacement__qa__item-a p {
    padding: 0 24px 0 48px;
  }
  .footer-lens-replacement__qa__item-a p::before {
    left: 24px;
  }
}

.footer-lens-replacement__qa__item__open {
  border-top: 1px dashed #e3e3e3;
  overflow: visible;
  padding: 20px 0;
}
