.modal{
	position: fixed;
	display: block;
	margin: 0 auto;
	top: 0;
	left: 0;
	padding-top: 10px;
	z-index: 1200 !important;
	overflow-x: hidden;
	overflow-y: hidden;
	height: 100vh;
}

.animate-top{
	animation: animatetop .5s;
}
.animate-left{
	animation: animateleft 0.5s;
}

.modal-backdrop {
    z-index: 1040;
    background-color: #000;
	opacity: 1;
	
}
.fade{
	opacity:0;
	-webkit-transition:opacity .15s linear;-o-transition:opacity .15s linear;transition:opacity .15s linear
}
.sgBookAppointment-height {
	height: 87vh;
	overflow: auto;
}
.thank-you-height {
	height: 52vh;
	overflow: auto;
}
.modal-body{
	position: relative;
	padding: 15px;
	height: 100%;
}
@media (min-width: 992px){
.modal-lg {
    width: 900px;
	}
}
.modal-header{
		min-height: 55px;
	.close{
		color: #939393;
		position: absolute;
		right: 20px;
		opacity: .9;
		display: flex;
		justify-content: center;
		&:hover{
			color: #333
		}
	}
	h1,h2,h3,h4{
		font-size: 18px;
		margin-top: 0;
		margin-bottom: 0;
	}
}

button.close{
	padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.title{
	font-size: 14px;
    font-family: Roboto, sans-serif;
    line-height: 143%;
    letter-spacing: .15px;
    cursor: pointer;
}

@keyframes animatetop {
from {
	transform: translate3d(0, -30%, 0);
	}
to {
	transform: translate3d(0, 0, 0); 
	}
}
@keyframes animateleft {
from {
	transform: translate3d(30%, 0, 0);
	}
to {
	transform: translate3d(0, 0, 0);
	}
}
