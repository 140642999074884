$base-img-url: 'https://static.lenskart.com/media/owndays/img/kuromi-collection';

$font-co: "Corporate Logo Rounded";
$font-ka: "Katakanaboy";
$font-ev: "Eveleth Clean";
$font-do: 'Dosis', sans-serif;
$font-he: heisei-maru-gothic-std, sans-serif;

$pink-ed: #edbad8;
$pink-e5: #e5a3d5;
$black-2b: #2b2045;
$purple-a6: #a68fbd;
$purple-84: #846f98;

.body-kuromi-collection {
  &__lineup {
    margin-top: -170px;
    position: relative;
    z-index: 4;

    .base-kuromi-collection__container {
      padding-top: 0;
      padding-bottom: 0;
    }
    
    .base-kuromi-collection__title {
      margin-bottom: 65px;
    }

    &__content {
      padding: 20px 0 100px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: calc(100% - 2px);
        left: 0;
        right: 0;
        height: 122px;
        background: top center / 100% 100% no-repeat;
        pointer-events: none;
      }

      @media (min-width: 1024px) {
        padding: 1px 0 100px;

        &::before {
          height: 19vw;
        }
      }

      @media (min-width: 1400px) {
        &::before {
          height: 437px;
        }
      }
    }

    &--pink {
      background-color: $pink-ed;
      padding-bottom: 200px;

      .body-kuromi-collection__lineup__slider-product-title h4 {
        background-color: $pink-e5;
      }

      &::before {
        background-image: url("#{$base-img-url}/lineup-top-shape-pink.webp");
      }

      @media (min-width: 1024px) {
        padding-bottom: 300px;
      }

      @media (min-width: 1400px) {
        padding-bottom: 370px;
      }
    }

    &--purple {
      background-color: $purple-a6;

      .body-kuromi-collection__lineup__slider-product-title h4 {
        background-color: $purple-84;
      }

      &::before {
        background-image: url("#{$base-img-url}/lineup-top-shape-purple.webp");
      }
    }

    &__top {
      position: relative;
      z-index: 4;

      &-name {
        text-align: center;
        margin: 0 0 10px 0;

        span {
          font: 900 38px / 1 $font-ka;
          letter-spacing: 0;
          color: white;
          display: inline-block;
          padding: 10px 15px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $black-2b;
            z-index: -1;
          }

          img {
            display: block;
          }
        }

        small {
          font: 700 28px / 1 $font-co;
          letter-spacing: 0.01em;
          display: block;
          color: $black-2b;
          margin-top: 10px;
        }
      }

      &-character {
        max-width: 500px;
        margin: -37px auto 0 auto;
        grid-area: img;

        img {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 727 / 794;
        }
      }

      &-detail {
        font-size: 15px;
        margin: 30px 0 0 0;
        line-height: 2.5;
      }

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 50% auto;
        grid-template-areas: "name img" "detail img";

        &-name {
          text-align: left;
          align-self: end;

          span {
            vertical-align: text-bottom;
            padding: 13px 23px;
          }

          small {
            display: inline-block;
            margin-left: 12px;
          }
        }

        &-character {
          max-width: none;

          img {
            width: auto;
            max-width: 100%;
            aspect-ratio: 538 / 457;
          }
        }

        &-detail {
          align-self: start;
        }
      }

      @media (min-width: 1296px) {
        grid-template-columns: 538px auto;

        &-name {
          span {
            font-size: 81px;
          }

          small {
            font-size: 54px;
            margin-left: 22px;
          }
        }

        &-detail {
          font-size: 19px;
          margin-top: 47px;
        }
      }

      @media (min-width: 1400px) {
        margin-top: -110px;
      }
    }

    &__slider {
      margin-top: 80px;

      &-header {
        padding-bottom: 20px !important;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: auto;
          bottom: 0;
          left: 17px;
          right: 17px;
          height: 6px;
          background: url("#{$base-img-url}/dot.svg") bottom center repeat-X;
        }
      }

      &-product-no {
        h3 {
          font: 400 20px / 1 $font-ev;
          letter-spacing: 0.12em;
          text-align: center;
          margin-bottom: 26px;
        }
      }

      &-product-title {
        h4 {
          font: 400 13px / 1 $font-ev;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          padding: 9px 15px;
          margin-bottom: 13px;
          color: white;
          display: inline-block;
        }
      }

      &-product-code {
        font: 700 20px / 1 $font-do;
        display: inline-block;
        vertical-align: middle;
        margin: 0;
      }

      &-product-list {
        margin-top: 45px;

        .slick-track {
          padding-bottom: 15px;
          display: flex;
          gap: 14px;
        }

        .slick-slide {
          width: 81%;
          max-width: 648px;
          box-sizing: border-box; 
        }

        .slick-active {
          .body-kuromi-collection__lineup__slider-product-inner {
            transform: translateY(15px);
          }
        }

      }

      &-product-inner {
        border: 3px solid $black-2b;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        transition: 0.4s;

        div > img {
          display: block;
          aspect-ratio: 601 / 358;
        }
      }

      @media (min-width: 768px) {
        .base-kuromi-collection__item-corner-top, .base-kuromi-collection__item-corner-bottom {
          &::before, &::after {
            width: 12px;
            height: 12px;
          }

          &::before {
            left: 24px;
          }
      
          &::after {
            right: 24px;
          }
        }

        .base-kuromi-collection__item-corner-top {
          &::before, &::after {
            top: 24px;
          }
        }

        .base-kuromi-collection__item-corner-bottom {
          &::before, &::after {
            bottom: 24px;
          }
        }

        &-header {
          padding-bottom: 0 !important;
          display: flex;
          justify-content: space-between;
          align-items: end;

          &::after {
            content: none;
          }
        }

        &-product-no {
          h3 {
            font-size: 30px;
            text-align: left;
            display: inline-block;
            position: relative;
            margin-bottom: 37px;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: -10px;
              left: 0;
              right: 0;
              border-bottom: 3px solid currentColor;
            }
          }
        }

        &-product-title {
          display: inline-block;

          h4 {
            margin-bottom: 0;
          }
        }

        &-product-code {
          font-size: 25px;
          margin-left: 15px;
        }

        &-product-list {
          margin-top: 30px;

          .slick-track {
            padding-bottom: 30px;
          }
  
          .slick-slide {
            width: 65%;
          }
  
          .slick-active {
            .body-kuromi-collection__lineup__slider-product-inner {
              transform: translateY(30px);
            }
          }

          .slick-dots {
            width: 100%;
            height: auto;
            display: inline-flex !important;
            justify-content: center;
            column-gap: 10px;
            padding-left: 0;
            margin-top: 30px;
            list-style-type: none;

            li > button {
              width: 10px;
              height: 10px;
              display: block;
              color: transparent;
              position: relative;
              background: none;
              border: 0;

              &::before {
                content: '';
                position: absolute;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .slick-active > button {
              &::before {
                background-color: $black-2b;
              }
            }
          }

          &--pink {
            .slick-dots {
              li > button {
                &::before {
                  background-color: $pink-e5;
                }
              }
            }
          }

          &--purple {
            .slick-dots {
              li > button {
                &::before {
                  background-color: $purple-84;
                }
              }
            }
          }
        }

        &-product-inner {
          border-width: 5px;
          border-radius: 30px;
        }
      }

      @media (min-width: 1400px) {
        &-product-list {
          .slick-track {
            gap: 29px;
          }
        }
      }
    }

    &__point {
      margin: 60px auto 65px;
      display: grid;
      row-gap: 82px;
      list-style-type: none;

      &-img-wrap {
        position: relative;
        margin-bottom: 28px;
        padding: 0 30px;

        img {
          display: block;
          max-width: 450px;
          max-height: 280px;
          margin: 0 auto;
          aspect-ratio: 334 / 313;
        }
      }

      &-title {
        font: 700 45px / 1 $font-do;
        letter-spacing: 0.05em;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: auto;
        left: 0;
        right: 0;
        margin: 0;

        small {
          font-size: 28px;
          display: inline-block;
          vertical-align: top;
          margin-top: 0.15em;
          position: relative;
          color: $black-2b;
          font-weight: 700 !important;

          &::after {
            content: '';
            position: absolute;
            top: auto;
            bottom: -10px;
            left: 0;
            right: 0;
            border-bottom: 4px solid $black-2b;
            border-radius: 4px;
          }
        }
      }

      p {
        font: 800 13px / 1 $font-he;
        line-height: 2.5;
        text-align: center;
        margin: 0;
      }

      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        -webkit-column-gap: 35px;
        column-gap: 35px;
        margin-top: 85px;
        margin-bottom: 100px;

        &-img-wrap {
          padding: 0;
  
          img {
            max-width: none;
          }
        }

        &-title {
          position: static;
          margin-bottom: 34px;
        }

        p {
          text-align: left;
          line-height: 2;
        }
      }

      @media (min-width: 1024px) {
        column-gap: 52px;

        &-img-wrap {
          margin-bottom: 53px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    @media (min-width: 1024px) {
      margin-top: -193px;
    }

    @media (min-width: 1400px) {
      .base-kuromi-collection__title {
        top: -135px;
      }
    }
  }
}
