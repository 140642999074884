.services__header {
  position: relative;
}

.services__header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 34px 5%;
}

.services__header-content h1,
.services__header-content p {
  letter-spacing: 1px;
  line-height: 2;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  color: inherit;
  margin: 0;
}

.services__header-content h1 {
  font-size: 20px;
  margin-bottom: 14px;
  line-height: 1;
  font-weight: 300;
}

.services__header-content p {
  font-size: 15px;
  line-height: 1.6;
}

.services__header-content--left {
  width: 100%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: start;
}

.services__header-content--left h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}

.services__header-content--left p {
  color: #fff;
  max-width: 450px;
}

.services__header-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 767px) {
  .services__header-content {
    background-color: #333;
    color: #fff;
  }
  .services__header-content p {
    line-height: 1.6;
  }
  .services__header-content--light {
    background-color: #f4f4f4;
  }
  .services__header-content--light h1 {
    color: #000;
    font-weight: 600;
  }
  .services__header-content--light p {
    color: #000;
  }
  .services__header-content--dark h1 {
    color: #fff;
    font-weight: 600;
  }
  .services__header-content--dark p {
    color: #fff;
  }
  .services__header-content--sp-bg-light {
    background-color: #f4f4f4;
  }
  .services__header-content--sp-bg-light h1 {
    color: #000;
  }
  .services__header-content--sp-bg-light p {
    color: #666;
  }
}

@media (min-width: 768px) {
  .services__header-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .services__header-content h1 {
    font-size: 40px;
    margin-bottom: 17px;
  }
  .services__header-content p {
    max-width: 600px;
    line-height: 1.6;
  }
  .services__header-content--center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .services__header-content--dark {
    text-align: center;
  }
  .services__header-content--dark h1 {
    color: #000;
  }
  .services__header-content--dark p {
    color: #666;
  }
  .services__header-content--light h1,
  .services__header-content--light p {
    color: #fff;
  }
  .services__header-content--sp-bg-light {
    text-align: center;
  }
  .services__header-content--sp-bg-light h1 {
    color: #000;
  }
  .services__header-content--sp-bg-light p {
    color: #666;
  }
  .services__header-content--left {
    width: 80%;
    position: absolute;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: start;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 1152px;
    padding: 0;
    margin: 0 auto;
  }
  .services__header-content--left h1 {
    font-size: 40px;
    line-height: 1;
    color: #fff;
    font-weight: 300;
  }
  .services__header-content--left p {
    color: #fff;
    max-width: 450px;
    line-height: 2;
  }
}
