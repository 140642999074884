.wishlist-page-item {
  padding: 6px 16px;
  background-color: #ffffff;

  &__layout {
    display: grid;
    height: 100%;
    grid-template-columns: 365fr 205fr;
    gap: 16px;
  }

  &__details {
    padding: 26px 0;
  }

  &__image {
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }

  &__title {
    margin: 0 0 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    cursor: pointer;
  }

  &__model-name {
    margin-top: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__divider {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 24px 0 20px;
  }

  &__actions {
    display: flex;
    gap: 16px;
  }

  &__action {
    padding: 0;
    border: none;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    text-transform: capitalize;
    text-decoration: underline;
    background-color: transparent;
  }

  &__actions-divider {
    border-right: 1px solid #E2E2EE;
  }
}