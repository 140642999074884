$font-founders: 'Founders Grotesk', sans-serif;

$gray-e3: #e3e3e3;
$gray-f4: #f4f4f4;
$blue-51: #516075;

.footer-gift-cards-products {
  &__preview {
    grid-area: right;
    display: flex;
    flex-direction: column;

    &__title {
      font-family: $font-founders;
      font-weight: bold !important;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 1px;
      margin-bottom: 15px;
      text-align: left;
      color: black;
    }

    &__card {
      border: 1px solid $gray-e3;
      padding: 20px;
      margin-bottom: 50px;

      img {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        object-fit: cover;
        aspect-ratio: 31 / 23;
      }

      pre, p {
        font-family: $font-founders;
        font-weight: normal !important;
        letter-spacing: 1px;
        color: black;
        margin: 0;
      }

      pre {
        font-size: 14px;
        line-height: 25px;
        word-wrap: break-word;
        white-space: normal;
        border: 0;
        background-color: transparent;
        padding: 0;
        word-break: normal;
      }

      p {
        font-size: 15px;
        line-height: 2;
        margin-left: auto;
        text-align: right;
      }
    }

    &__summary {
      &__price {
        font-family: $font-founders;
        line-height: 1;
        margin-bottom: 20px;
        font-weight: 400 !important;
        font-size: 28px;
        text-align: left;
        color: black;
      }

      &__exp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $gray-f4;
        padding: 10px 15px;
        margin-bottom: 20px;

        p {
          font-family: $font-founders;
          font-size: 15px;
          font-weight: normal !important;
          line-height: 2;
          margin-bottom: 0;
          color: black;
        }
      }

      &__add-cart-btn {
        width: 100%;
        height: auto;
        background-color: $blue-51;
        color: white;
        border: none;
        transition-duration: 0.5s;
        padding: 18px 0;
        font-family: $font-founders;
        font-size: 14px;
        font-weight: 500 !important;
        margin-bottom: 45px;
        cursor: pointer;

        &:hover {
          background-color: rgb(107, 120, 138);
        }
      }
    }

    @media (min-width: 768px) {
      &__title {
        font-size: 16px;
      }
    }

    @media (min-width: 1024px) {
      padding: 80px 15px 0 15px;
      width: 335px;
      margin-left: auto;

      .base-gift-cards-products__container {
        width: 100%;
      }

      > div {
        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(2) {
          order: 1;
        }
      }
    }
  }
  
  &__detail {
    padding: 50px 0 40px 0;
    border-top: 1px solid $gray-e3;
    grid-area: detail;

    &__how-to-use {
      list-style-type: none;
      padding: 0;
      margin-bottom: 40px;

      &__title, &__sub-title {
        font-family: $font-founders;
        font-weight: bold !important;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1;
        color: black;
      }

      &__title {
        margin-bottom: 15px;
      }

      &__sub-title {
        position: relative;
        margin-bottom: 10px;
        padding-left: 13px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 3px;
          height: 18px;
          transform: translateY(-50%);
          background-color: black;
        }
      }

      &__desc {
        font-family: $font-founders;
        font-size: 15px;
        font-weight: normal;
        line-height: 2;
        margin-bottom: 0;
        color: black;
        letter-spacing: 1px;
      }

      &__item {
        &:nth-child(1) {
          p {
            margin-bottom: 20px;
          }
        }

        &:nth-child(2) {
          p {
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: 768px) {
        &__title {
          font-size: 16px;
        }
      }
    }

    &__terms {
      &__title {
        font-family: $font-founders;
        font-weight: bold !important;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1;
        margin-bottom: 15px;
        color: black;
      }

      &__list {
        list-style-type: none;
        padding: 0;

        li {
          color: black;
          font-family: $font-founders;
          font-size: 15px;
          font-weight: normal;
          line-height: 2;
          padding-left: 1em;
          letter-spacing: 1px;
          position: relative;

          &::before {
            content: '*';
            position: absolute;
            left: 0;
          }
        }
      }

      @media (min-width: 768px) {
        &__title {
          font-size: 16px;
        }
      }
    }

    @media (min-width: 1024px) {
      max-width: 650px;

      .base-gift-cards-products__container {
        width: 100%;
      }
    }

    @media (min-width: 1024px) and (max-width: 1295px) {
      padding-right: 3%;
      max-width: 570px;
    }

    @media (min-width: 1296px) {
      padding-bottom: 110px;
    }
  }
}
