.cart-item {
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    width: 721px;
    float: left;
    &_container {
        padding: 6px 0 0px 0px;
        width: 148px;
        float: left;
        &_thumb {
            float: left;
            margin: 15px 8px 0px 2px;
            margin-top: 0px;
            width: 75px;
        }
        &_qty-block {
            float: left;
            width: 52px;
            font-size: 16px;
            color: #777;
            &_title {
                float: left;
                width: 30px;
                margin-top: 25px;
            }
            .blck-qty {
                float: left;
                width: 22px;
                i {
                    float: left;
                    width: 16px;
                    height: 16px;
                    background-position: -1048px -168px;
                    margin: 3px 5px;
                    cursor: pointer;
                    border: 1px solid #ccc;
                    border-radius: 47%;
                    padding: 4px;
                }
                .plus {
                    background-position: -1098px -168px;
                }
                .qty-value {
                    width: 27px;
                    margin: 0px;
                    text-align: center;
                }
            }
        }
    }
    &_amount {
        width: 566px;
        padding: 26px 0 15px;
        border-left: 1px solid #f0f0f0;
        min-height: 95px;
        color: #777;
        float: left;
        text-align: center;
        .old-price {
            text-decoration: line-through;
            overflow: hidden;
            display: block;
        }
        .off-price {
            font-size: 14px;
            color: #777;
            overflow: hidden;
            display: block;
            line-height: 22px;
        }
        .crt-frm-prce {
            float: left;
            width: 155px;
            >div {
                padding-left: 10px;
            }
        }
        .crt-lns-prce {
            .toggle-arrow {
                cursor: pointer;
                color: #329c92;
                font-weight: bold;
                &.toggle-arrow-view:after {
                    content: "-";
                    font-size: 20px;
                }
                &:after {
                    content: "+";
                    font-size: 16px;
                    line-height: 0px;
                    width: 18px;
                    display: inline-block;
                }
            }
            .view-power-lens {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    display: flex;
                    justify-content: space-between;
                    &>span {
                        color: #445961;
                        font-weight: 700;
                    }
                }
            }
        }
        .pls-crt-sign {
            font-size: 24px;
            margin: 25px 0 0 0;
            line-height: 20px;
            width: 14px;
            display: inline-block;
        }
        &_status {
            overflow: hidden;
            display: block;
            span {
                display: block;
                color: #999;
                font-style: italic;
            }
        }
    }
    table.lns-detail-tab {
        margin-top: 2px;
        td {
            padding: 0px 3px 0 0;
            font-size: 13px;
            border: none;
        }
        td:first-child {
            text-align: left;
        }
    }
    &_remove-icon {
        position: absolute;
        top: 0;
        right: -15px;
        float: right;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-position: -1000px -150px;
        margin: 10px 20px 0 0;
    }
    &_message {
        background-color: #ffffe6;
        border: 0.25px solid #cccccc;
        color: #777777;
        clear: both;
        width: 100%;
        text-align: center;
        font-family: -webkit-body;
        letter-spacing: 1px;
        .oos-text {
            position: relative;
            top: -4px;
        }
        .fa-bullhorn {
            position: relative;
            left: -8px;
            color: #31b19e;
        }
    }
}

.row-fluid {
    width: 100%;
}

.row-fluid [class*="span"]:first-child {
    margin-left: 0;
    text-align: right;
}

.row-fluid .span6 {
    width: 49.33875890132248%;
}

.row-fluid [class*="span"] {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 1.3224821973550356%;
}

.row-fluid .span2 {
    width: 15.564598168870804%;
}

.row-fluid .span3 {
    width: 24.008138351983725%;
}

.content {
    border: none !important;
}

.color-yellow {
    color: #e8792a;
}

.packges-block {
    display: block;
}

.chkout-cart-region {
    .power-details-cart {
        clear: both;
    }
    .power-table-block {
        width: 100%;
        padding: 0;
        margin: auto;
        &.shadow {
            box-shadow: none;
        }
        .power-item {
            padding-bottom: 0;
        }
        .display-grid {
            border-top: 1px solid #f0f0f0;
            width: 100%;
            padding-right: 30%;
            padding-left: 20px;
            margin-top: -5px;
        }
        .bg-yellowish-brown {
            margin: 0;
            border-bottom: 0;
        }
        .name-strip {
            margin-left: 0;
            margin-right: 0;
            border-bottom: none;
            padding-left: 0px;
        }
    }
    .power-status-message {
        width: 100%;
        justify-content: center;
        padding: 10px 0;
        position: relative;
        margin: auto;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 149px;
            height: 100%;
        }
    }
}




