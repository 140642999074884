.lkModal.modal-title {
  font-weight: 700;
}

.lkModal.modal-dialog {
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}
