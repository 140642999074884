.odslv-modal-pt__heading {
  font-family: 'Founders Grotesk', sans-serif;
  color: #444;
  font-size: 24px;
  letter-spacing: -0.48px;
  margin: 8px 0 40px 0;
}

@media (max-width: 768px) {
  .odslv-modal-pt__heading {
    margin: 8px 0 32px 0;
  }
}

.odslv-modal-pt__status-prescription {
  color: #444;
  -webkit-font-feature-settings: 'clig' off, 'liga' off;
          font-feature-settings: 'clig' off, 'liga' off;
  font-size: 20px;
  opacity: 0.5;
  margin-bottom: 24px;
  margin-top: 32px;
}

@media (max-width: 768px) {
  .odslv-modal-pt__status-prescription {
    margin-bottom: 16px;
  }
}

.odslv-modal-pt__radio {
  background-color: #fff;
}

.odslv-modal-pt__radio--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 10px;
  background: #FAFAFA;
  padding: 24px 16px;
}

.odslv-modal-pt__radio img {
  height: 18px;
}

.odslv-modal-pt__radio span {
  font-family: 'NeuzeitGroReg', sans-serif;
  color: #444;
  font-size: 16px;
}

.odslv-modal-pt__radio:not(:last-child) {
  margin-bottom: 16px;
}

.odslv-modal-pt__radio-selected {
  background-color: #eee;
  border-radius: 10px;
}
