.custom-width {
  min-width: 200px;
  max-width: 220px;
}

@media (max-width: 1380px) {
  .custom-width {
    min-width: 100px !important;
    max-width: 183px !important;
  }
}

.nav-subcategory-level {
  padding: 0 30px;
  width: 100%;
  margin-top: 0;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

@media (max-width: 1050px) {
  .nav-subcategory-level {
    padding: 0 20px;
  }
}

.nav-subcategory-level .nav-level-3 {
  margin-right: 50px;
  min-width: 200px;
  max-width: 230px;
}

.nav-subcategory-level .nav-level-3.flex-img-grow {
  max-width: none;
  min-width: none;
  margin-right: 0;
}

.nav-subcategory-level .nav-level-3 .level-5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0px 40px;
  background-color: #fbfbfb;
  height: 280px;
  width: 240px;
  border-radius: 20px;
}

.nav-subcategory-level .nav-level-3 .two .image {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}

.nav-subcategory-level .nav-level-3 .three .image {
  -ms-flex-preferred-size: 33%;
      flex-basis: 33%;
}

.nav-subcategory-level .nav-level-3 .four .image {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
}

.nav-subcategory-level .nav-level-3 .one .image {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}

@media (min-width: 768px) and (max-width: 992px) {
  .nav-subcategory-level .nav-level-3 {
    min-width: 100px;
    max-width: 150px;
    margin-right: 10px;
  }
}

.nav-subcategory-level .nav-level-4 {
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .nav-subcategory-level .nav-level-4 {
    font-size: 13px;
  }
}

.nav-subcategory-level .nav-level-4-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: rgba(0, 0, 0, 0.69804);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}

.nav-subcategory-level .nav-level-4-item span {
  margin-right: 5px;
  display: block;
  color: rgba(0, 0, 0, 0.69804);
  height: auto;
  font-size: 14px;
  line-height: 20px;
}

.nav-subcategory-level .nav-level-4-item span:hover {
  color: #000000;
}

.nav-subcategory-level .nav-level-4-item:hover {
  color: #000000;
  text-decoration: none;
}

.nav-subcategory-level .nav-level-4-item:hover .fa-angle-right {
  display: block !important;
}
