$font-founders: "Founders Grotesk", sans-serif;

.popup {
  font-family: $font-founders;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }

  &__content {
    background: #ffffff;
    width: 100%;
    max-width: 570px;
    padding: 60px 60px 20px 60px;
    position: relative;
    text-align: left;

    @media screen and (max-width: 768px) {
      padding: 60px 20px 20px 20px;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 30px;
      border: 1px solid black;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__message-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__message {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 1;
    margin: 0;
    color: black;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  &__button {
    background: white;
    color: black;
    padding: 10px 0;
    border-radius: 30px;
    cursor: pointer;
    border: 1px solid black;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    line-height: 2;
    letter-spacing: 2;
    width: 100%;
    max-width: 300px;

    &:hover {
      background: black;
      color: white;
    }
  }
}
