.odslv-p__addons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;

  .odslv-p__addon {
    padding: 16px;
    display: flex;
    min-width: 250px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin: 4px 0;
    &__selected {
      border: 1px solid #546073;
    }
    background: #FAFAFA;
    &__left {
      display: flex;
      gap: 16px;
    }
    &-img {
      margin-right: 10px;
      img {
        height: 40px;
      }
    }
    &-des {
      &-t {
        font-family: 'NeuzeitGroReg', sans-serif;
        font-size: 14px;
        color: #546073;
      }
      &-p {
        strong {
          color: #546073;
          font-family: 'Founders Grotesk', sans-serif;
          font-size: 16px;
        }
      }
    }
  }
}