.footer-pompompurin__pompompurin {
  padding-bottom: 220px;
  background-color: white;
}

.footer-pompompurin__pompompurin__buttons {
  padding: 76px 0 90px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.footer-pompompurin__pompompurin__buttons .base-pompompurin__btn {
  max-width: 380px;
}

.footer-pompompurin__pompompurin__title {
  margin-top: 0;
  margin-bottom: 21px;
  text-align: center;
}

.footer-pompompurin__pompompurin__title img {
  aspect-ratio: 313/33;
  max-width: 230px;
}

.footer-pompompurin__pompompurin__img {
  aspect-ratio: 121/118;
}

.footer-pompompurin__pompompurin__details p {
  font-size: 14px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0.28px;
  margin-bottom: 40px;
}

.footer-pompompurin__pompompurin__grid {
  display: grid;
  grid-template-columns: 1fr;
}

@media (max-width: 767px) {
  .footer-pompompurin__pompompurin {
    padding-inline: 20px;
  }
  .footer-pompompurin__pompompurin__title img {
    margin-inline: auto;
  }
  .footer-pompompurin__pompompurin__img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 28px;
  }
}

@media (min-width: 768px) {
  .footer-pompompurin__pompompurin__title {
    text-align: left;
  }
  .footer-pompompurin__pompompurin__buttons {
    padding: 90px 0 140px 0;
  }
  .footer-pompompurin__pompompurin__buttons .base-pompompurin__btn {
    margin: 0;
    width: 310px;
  }
  .footer-pompompurin__pompompurin__buttons .base-pompompurin__btn:nth-child(2) {
    width: 380px;
  }
  .footer-pompompurin__pompompurin__grid {
    display: grid;
    grid-template-columns: 242px 1fr;
    -webkit-column-gap: 37.5px;
       -moz-column-gap: 37.5px;
            column-gap: 37.5px;
  }
  .footer-pompompurin__pompompurin__img {
    grid-row: 1 / span 2;
    grid-column: 1;
  }
  .footer-pompompurin__pompompurin__details .base-pompompurin__btn {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .footer-pompompurin__pompompurin {
    padding-bottom: 308px;
  }
  .footer-pompompurin__pompompurin__buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 50px;
  }
  .footer-pompompurin__pompompurin__buttons .base-pompompurin__btn {
    margin: 0;
  }
  .footer-pompompurin__pompompurin__buttons .base-pompompurin__btn:nth-child(1) {
    width: 300px;
  }
  .footer-pompompurin__pompompurin__buttons .base-pompompurin__btn:nth-child(2) {
    width: 380px;
  }
  .footer-pompompurin__pompompurin__grid {
    grid-template-columns: 363px 1fr;
    -webkit-column-gap: 75px;
       -moz-column-gap: 75px;
            column-gap: 75px;
  }
  .footer-pompompurin__pompompurin__title {
    margin-bottom: 24px;
  }
  .footer-pompompurin__pompompurin__title img {
    max-width: 313px;
  }
  .footer-pompompurin__pompompurin__details p {
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.32px;
  }
}

@media (min-width: 1445px) {
  .footer-pompompurin__pompompurin {
    margin-bottom: -20px;
  }
}

.footer-pompompurin__sns {
  background-color: #76332e;
  color: white;
}

.footer-pompompurin__sns::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 25px;
  background-color: #fff9b1;
}

.footer-pompompurin__sns .base-pompompurin__container {
  position: relative;
  padding-bottom: 155px;
}

.footer-pompompurin__sns__fg {
  position: absolute;
}

.footer-pompompurin__sns__fg--cloud {
  width: 100%;
  aspect-ratio: 1513/250;
  top: -150px;
  height: 186px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 63% top;
     object-position: 63% top;
}

.footer-pompompurin__sns__fg--char {
  aspect-ratio: 32/17;
  max-width: 213px;
  right: 11px;
  bottom: 17px;
}

.footer-pompompurin__sns__title {
  text-align: center;
  font-family: "Fredoka", sans-serif !important;
  font-weight: 700 !important;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 2.56px;
  margin-bottom: 25px;
}

.footer-pompompurin__sns__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
  margin-bottom: 45px;
  list-style-type: none;
  padding: 0;
}

.footer-pompompurin__sns__list li a {
  font-size: 38px;
  color: white;
}

.footer-pompompurin__sns__list li a i {
  background: transparent;
}

.footer-pompompurin__sns__copyright {
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin: 0;
}

@media (min-width: 1024px) {
  .footer-pompompurin__sns::before {
    height: 54px;
  }
  .footer-pompompurin__sns .base-pompompurin__container {
    padding-bottom: 74px;
  }
  .footer-pompompurin__sns__fg--cloud {
    top: -223px;
    height: 245px;
    -o-object-position: center top;
       object-position: center top;
  }
  .footer-pompompurin__sns__fg--char {
    max-width: 320px;
    right: -96px;
    bottom: 44px;
  }
  .footer-pompompurin__sns__title {
    font-size: 60px;
    letter-spacing: 3px;
    margin-bottom: 47px;
  }
  .footer-pompompurin__sns__list {
    gap: 50px;
    margin-bottom: 75px;
  }
  .footer-pompompurin__sns__list li a {
    font-size: 48px;
  }
  .footer-pompompurin__sns__copyright {
    font-size: 12px;
  }
}
