.l-care {
  color: #000;
}

.l-care__main-logo {
  padding: 30px 0;
}

.l-care__main-logo img {
  display: block;
  width: 100%;
  max-width: 160px;
  margin: 0 auto;
}

.l-care__main-content {
  background-color: #F0F0F0;
}

.l-care__main-content-heading {
  padding: 44px 10% 0;
  font-size: 21px;
  font-weight: 700 !important;
  line-height: 1.4;
  margin: 0;
}

.l-care__main-content-img {
  display: block;
  max-width: 380px;
  margin-left: auto;
  margin-right: 0;
}

.l-care__main-footer {
  background-color: #06082B;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
  padding: 10px 20px;
  margin: 0;
}

.l-care__features {
  margin: 46px 0;
  padding: 0 5%;
  text-align: center;
}

.l-care__features-title {
  font-size: 29px;
  font-weight: 700 !important;
  line-height: 1.5;
  letter-spacing: 3px;
  text-align: center;
  display: inline-block;
  border-bottom: 4px solid #000;
  margin: 0 auto 30px;
}

.l-care__features-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 16px;
}

.l-care__features-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.l-care__features-item-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #F0F0F0;
  width: 155px;
  height: 155px;
  border-radius: 100%;
  margin: 0;
}

.l-care__features-item-title span {
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 1px;
}

.l-care__features-item-title span img {
  display: inline-block;
  margin: 3px auto;
  width: 100%;
  max-width: 90px;
}

.l-care__features-item-text {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 2;
}

.l-care__terms {
  margin: 46px 0;
  padding: 0 5%;
}

.l-care__terms-inner {
  border: 1px solid #000;
  padding: 0 7%;
  max-width: 860px;
  margin: 0 auto;
}

.l-care__terms-title {
  font-size: 18px;
  font-weight: 700 !important;
  line-height: 1.5;
  text-align: center;
  margin: -13.5px auto 13px;
  background-color: #fff;
  width: 280px;
}

.l-care__terms-text {
  font-size: 13px;
  line-height: 2;
  letter-spacing: 1px;
  margin-bottom: 2em;
}

@media screen and (min-width: 768px) {
  .l-care__main-logo {
    padding: 50px 0;
  }
  .l-care__main-logo img {
    max-width: 240px;
  }
  .l-care__main-content {
    position: relative;
  }
  .l-care__main-content-heading {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 80%;
    max-width: 1152px;
    margin: 0 auto;
    padding: 0;
  }
  .l-care__main-footer {
    font-size: 20px;
  }
  .l-care__terms-inner {
    padding: 0 3%;
  }
}

@media screen and (min-width: 1024px) {
  .l-care__main-content-heading {
    font-size: 29px;
  }
  .l-care__main-content-img {
    max-width: 560px;
  }
  .l-care__features {
    margin: 70px 0;
  }
  .l-care__features-title {
    font-size: 37px;
    letter-spacing: 3px;
    margin-bottom: 70px;
  }
  .l-care__features-list {
    gap: 75px;
  }
  .l-care__features-item-title {
    width: 220px;
    height: 220px;
    padding: 10px;
  }
  .l-care__features-item-title span {
    display: inline-block;
    font-size: 20px;
    line-height: 1.6;
  }
  .l-care__features-item-title span strong {
    font-family: inherit;
    font-weight: 700;
  }
  .l-care__features-item-title span img {
    max-width: 120px;
  }
  .l-care__features-item-text {
    font-size: 15px;
  }
  .l-care__terms {
    margin: 90px 0 120px;
  }
  .l-care__terms-title {
    font-size: 28px;
    line-height: 1.6;
    margin-top: -21px;
  }
}
