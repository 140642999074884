.corporate-information {
    padding: 60px 200px 160px;

    &__title {
        margin: 0 0 90px;
        text-align: center;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: 0em;
        color: #000000;
    }

    &__subtitle {
        margin: 0 0 50px;
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0em;
        color: #000000;
    }

    &__divider {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin: 50px 0;
    }
}