$font-founders: 'Founders Grotesk';

.product-price {
	display: inline-flex;
	margin: 0;
	align-items: center;
	font-family: $font-founders;

	&--mobile {
		margin: 4px 0;
	}

	&__cost {
		display: flex;
		align-items: baseline;
		gap: 4px;
		color: #000000;

		&--mobile {
			color: #000000;
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
		}
	}

	&__tax {
		margin-left: 13px;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: #9999B3;
		
		&--mobile {
			color: rgba(0, 0, 0, 0.5);
			font-family: Raleway;
			font-weight: 400;
		}
	}
}
