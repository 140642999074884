.od-dkp {
  width: 100%;

  &-banner {
    width: 100%;
    color: #000000;
    text-align: center;
    margin-bottom: 60px;

    &__img {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 133px;
    }

    &__title {
      width: 100%;
      padding: 0 107px;
      font-size: 48px;
      line-height: 60px;
      margin-top: 80px;
    }

    &__description {
      width: 100%;
      padding: 0 283px;
      font-size: 20px;
      line-height: 40px;
      margin-top: 40px;
      opacity: 0.7;
    }
  }

  &-separator {
    width: 100%;
    border: 1px solid #000000;
    opacity: 0.2;
  }

  &-btns {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 76px;
  }

  .od-dkp-options {
    width: 100%;
    margin: 40px auto;
    color: #000000;
    max-width: 1209px;

    .od-dkp__option-one {
      background-color: #FAFAFA;
      margin: 0 auto;
      padding-bottom: 51px;

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 53px;
        padding-left: 100px;

        &__left {
          max-width: 611px;
          
          &__title {
            font-size: 16px;
            line-height: 19px;
            opacity: 0.5;
          }

          &__subTitle {
            font-size: 32px;
            line-height: 38px;
            margin-top: 30px;
          }

          &__description {
            max-width: 564px;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 30px;
            font-size: 16px;
            line-height: 19px;
          }
        }

        &__right {
          width: 300px;
          height: 470px;
          margin: 50px 145px 0 0;
        }

        &__right-new-customer {
          margin: 100px 120px 100px 0;
        }
      }

      &__register-btn {
        margin-top: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &__description {
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
      }
    }

    .option-two-customer {
      padding: 50px 100px;
    }

    .option-two-new-customer {
      padding: 50px 130px 108px 100px;
    }

    .od-dkp__option-two {
      background-color: #FAFAFA;
      margin: 40px auto;
      color: #000000;
      display: flex;
      gap: 39px;

      .od-dkp__option-two__info {
        width: 100%;
      }

      &__title {
        font-size: 16px;
        line-height: 19px;
        opacity: 0.5;
      }

      &__subTitle {
        margin-top: 30px;
        font-size: 32px;
        line-height: 37px;
      }

      &__description {
        margin-top: 30px;
        font-size: 16px;
        line-height: 25px;
      }

      &__description-list {
        margin-top: 30px;
        padding: 0;
        font-size: 16px;
        line-height: 19px;

        li {
          list-style: inside;
        }
      }

      &__btns {
        width: 100%;
        margin-top: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        color: #000000;
      }
    }

      .od-dkp__option-two-img {
        width: 284px;
        height: 202px;
      }
  }

  .od-contact-us-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__separator {
      width: 20px;
      transform: rotate(90deg);
      border: 1px solid #000000;
      opacity: 0.2;
    }
  }
}