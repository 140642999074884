.body-kuromi-collection__lineup {
  margin-top: -170px;
  position: relative;
  z-index: 4;
}

.body-kuromi-collection__lineup .base-kuromi-collection__container {
  padding-top: 0;
  padding-bottom: 0;
}

.body-kuromi-collection__lineup .base-kuromi-collection__title {
  margin-bottom: 65px;
}

.body-kuromi-collection__lineup__content {
  padding: 20px 0 100px;
  position: relative;
}

.body-kuromi-collection__lineup__content::before {
  content: '';
  position: absolute;
  bottom: calc(100% - 2px);
  left: 0;
  right: 0;
  height: 122px;
  background: top center / 100% 100% no-repeat;
  pointer-events: none;
}

@media (min-width: 1024px) {
  .body-kuromi-collection__lineup__content {
    padding: 1px 0 100px;
  }
  .body-kuromi-collection__lineup__content::before {
    height: 19vw;
  }
}

@media (min-width: 1400px) {
  .body-kuromi-collection__lineup__content::before {
    height: 437px;
  }
}

.body-kuromi-collection__lineup--pink {
  background-color: #edbad8;
  padding-bottom: 200px;
}

.body-kuromi-collection__lineup--pink .body-kuromi-collection__lineup__slider-product-title h4 {
  background-color: #e5a3d5;
}

.body-kuromi-collection__lineup--pink::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/lineup-top-shape-pink.webp");
}

@media (min-width: 1024px) {
  .body-kuromi-collection__lineup--pink {
    padding-bottom: 300px;
  }
}

@media (min-width: 1400px) {
  .body-kuromi-collection__lineup--pink {
    padding-bottom: 370px;
  }
}

.body-kuromi-collection__lineup--purple {
  background-color: #a68fbd;
}

.body-kuromi-collection__lineup--purple .body-kuromi-collection__lineup__slider-product-title h4 {
  background-color: #846f98;
}

.body-kuromi-collection__lineup--purple::before {
  background-image: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/lineup-top-shape-purple.webp");
}

.body-kuromi-collection__lineup__top {
  position: relative;
  z-index: 4;
}

.body-kuromi-collection__lineup__top-name {
  text-align: center;
  margin: 0 0 10px 0;
}

.body-kuromi-collection__lineup__top-name span {
  font: 900 38px/1 "Katakanaboy";
  letter-spacing: 0;
  color: white;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
}

.body-kuromi-collection__lineup__top-name span::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2b2045;
  z-index: -1;
}

.body-kuromi-collection__lineup__top-name span img {
  display: block;
}

.body-kuromi-collection__lineup__top-name small {
  font: 700 28px/1 "Corporate Logo Rounded";
  letter-spacing: 0.01em;
  display: block;
  color: #2b2045;
  margin-top: 10px;
}

.body-kuromi-collection__lineup__top-character {
  max-width: 500px;
  margin: -37px auto 0 auto;
  grid-area: img;
}

.body-kuromi-collection__lineup__top-character img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 727 / 794;
}

.body-kuromi-collection__lineup__top-detail {
  font-size: 15px;
  margin: 30px 0 0 0;
  line-height: 2.5;
}

@media (min-width: 768px) {
  .body-kuromi-collection__lineup__top {
    display: grid;
    grid-template-columns: 50% auto;
    grid-template-areas: "name img" "detail img";
  }
  .body-kuromi-collection__lineup__top-name {
    text-align: left;
    -ms-flex-item-align: end;
        align-self: end;
  }
  .body-kuromi-collection__lineup__top-name span {
    vertical-align: text-bottom;
    padding: 13px 23px;
  }
  .body-kuromi-collection__lineup__top-name small {
    display: inline-block;
    margin-left: 12px;
  }
  .body-kuromi-collection__lineup__top-character {
    max-width: none;
  }
  .body-kuromi-collection__lineup__top-character img {
    width: auto;
    max-width: 100%;
    aspect-ratio: 538 / 457;
  }
  .body-kuromi-collection__lineup__top-detail {
    -ms-flex-item-align: start;
        align-self: start;
  }
}

@media (min-width: 1296px) {
  .body-kuromi-collection__lineup__top {
    grid-template-columns: 538px auto;
  }
  .body-kuromi-collection__lineup__top-name span {
    font-size: 81px;
  }
  .body-kuromi-collection__lineup__top-name small {
    font-size: 54px;
    margin-left: 22px;
  }
  .body-kuromi-collection__lineup__top-detail {
    font-size: 19px;
    margin-top: 47px;
  }
}

@media (min-width: 1400px) {
  .body-kuromi-collection__lineup__top {
    margin-top: -110px;
  }
}

.body-kuromi-collection__lineup__slider {
  margin-top: 80px;
}

.body-kuromi-collection__lineup__slider-header {
  padding-bottom: 20px !important;
  position: relative;
}

.body-kuromi-collection__lineup__slider-header::after {
  content: '';
  position: absolute;
  top: auto;
  bottom: 0;
  left: 17px;
  right: 17px;
  height: 6px;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/dot.svg") bottom center repeat-X;
}

.body-kuromi-collection__lineup__slider-product-no h3 {
  font: 400 20px/1 "Eveleth Clean";
  letter-spacing: 0.12em;
  text-align: center;
  margin-bottom: 26px;
}

.body-kuromi-collection__lineup__slider-product-title h4 {
  font: 400 13px/1 "Eveleth Clean";
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 9px 15px;
  margin-bottom: 13px;
  color: white;
  display: inline-block;
}

.body-kuromi-collection__lineup__slider-product-code {
  font: 700 20px/1 "Dosis", sans-serif;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.body-kuromi-collection__lineup__slider-product-list {
  margin-top: 45px;
}

.body-kuromi-collection__lineup__slider-product-list .slick-track {
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 14px;
}

.body-kuromi-collection__lineup__slider-product-list .slick-slide {
  width: 81%;
  max-width: 648px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.body-kuromi-collection__lineup__slider-product-list .slick-active .body-kuromi-collection__lineup__slider-product-inner {
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
}

.body-kuromi-collection__lineup__slider-product-inner {
  border: 3px solid #2b2045;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.body-kuromi-collection__lineup__slider-product-inner div > img {
  display: block;
  aspect-ratio: 601 / 358;
}

@media (min-width: 768px) {
  .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-top::before, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-top::after, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-bottom::before, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-bottom::after {
    width: 12px;
    height: 12px;
  }
  .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-top::before, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-bottom::before {
    left: 24px;
  }
  .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-top::after, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-bottom::after {
    right: 24px;
  }
  .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-top::before, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-top::after {
    top: 24px;
  }
  .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-bottom::before, .body-kuromi-collection__lineup__slider .base-kuromi-collection__item-corner-bottom::after {
    bottom: 24px;
  }
  .body-kuromi-collection__lineup__slider-header {
    padding-bottom: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
  }
  .body-kuromi-collection__lineup__slider-header::after {
    content: none;
  }
  .body-kuromi-collection__lineup__slider-product-no h3 {
    font-size: 30px;
    text-align: left;
    display: inline-block;
    position: relative;
    margin-bottom: 37px;
  }
  .body-kuromi-collection__lineup__slider-product-no h3::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 0;
    right: 0;
    border-bottom: 3px solid currentColor;
  }
  .body-kuromi-collection__lineup__slider-product-title {
    display: inline-block;
  }
  .body-kuromi-collection__lineup__slider-product-title h4 {
    margin-bottom: 0;
  }
  .body-kuromi-collection__lineup__slider-product-code {
    font-size: 25px;
    margin-left: 15px;
  }
  .body-kuromi-collection__lineup__slider-product-list {
    margin-top: 30px;
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-track {
    padding-bottom: 30px;
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-slide {
    width: 65%;
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-active .body-kuromi-collection__lineup__slider-product-inner {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-dots {
    width: 100%;
    height: auto;
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
    padding-left: 0;
    margin-top: 30px;
    list-style-type: none;
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-dots li > button {
    width: 10px;
    height: 10px;
    display: block;
    color: transparent;
    position: relative;
    background: none;
    border: 0;
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-dots li > button::before {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .body-kuromi-collection__lineup__slider-product-list .slick-dots .slick-active > button::before {
    background-color: #2b2045;
  }
  .body-kuromi-collection__lineup__slider-product-list--pink .slick-dots li > button::before {
    background-color: #e5a3d5;
  }
  .body-kuromi-collection__lineup__slider-product-list--purple .slick-dots li > button::before {
    background-color: #846f98;
  }
  .body-kuromi-collection__lineup__slider-product-inner {
    border-width: 5px;
    border-radius: 30px;
  }
}

@media (min-width: 1400px) {
  .body-kuromi-collection__lineup__slider-product-list .slick-track {
    gap: 29px;
  }
}

.body-kuromi-collection__lineup__point {
  margin: 60px auto 65px;
  display: grid;
  row-gap: 82px;
  list-style-type: none;
}

.body-kuromi-collection__lineup__point-img-wrap {
  position: relative;
  margin-bottom: 28px;
  padding: 0 30px;
}

.body-kuromi-collection__lineup__point-img-wrap img {
  display: block;
  max-width: 450px;
  max-height: 280px;
  margin: 0 auto;
  aspect-ratio: 334 / 313;
}

.body-kuromi-collection__lineup__point-title {
  font: 700 45px/1 "Dosis", sans-serif;
  letter-spacing: 0.05em;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  margin: 0;
}

.body-kuromi-collection__lineup__point-title small {
  font-size: 28px;
  display: inline-block;
  vertical-align: top;
  margin-top: 0.15em;
  position: relative;
  color: #2b2045;
  font-weight: 700 !important;
}

.body-kuromi-collection__lineup__point-title small::after {
  content: '';
  position: absolute;
  top: auto;
  bottom: -10px;
  left: 0;
  right: 0;
  border-bottom: 4px solid #2b2045;
  border-radius: 4px;
}

.body-kuromi-collection__lineup__point p {
  font: 800 13px/1 heisei-maru-gothic-std, sans-serif;
  line-height: 2.5;
  text-align: center;
  margin: 0;
}

@media (min-width: 768px) {
  .body-kuromi-collection__lineup__point {
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 35px;
    -moz-column-gap: 35px;
         column-gap: 35px;
    margin-top: 85px;
    margin-bottom: 100px;
  }
  .body-kuromi-collection__lineup__point-img-wrap {
    padding: 0;
  }
  .body-kuromi-collection__lineup__point-img-wrap img {
    max-width: none;
  }
  .body-kuromi-collection__lineup__point-title {
    position: static;
    margin-bottom: 34px;
  }
  .body-kuromi-collection__lineup__point p {
    text-align: left;
    line-height: 2;
  }
}

@media (min-width: 1024px) {
  .body-kuromi-collection__lineup__point {
    -webkit-column-gap: 52px;
       -moz-column-gap: 52px;
            column-gap: 52px;
  }
  .body-kuromi-collection__lineup__point-img-wrap {
    margin-bottom: 53px;
  }
  .body-kuromi-collection__lineup__point p {
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  .body-kuromi-collection__lineup {
    margin-top: -193px;
  }
}

@media (min-width: 1400px) {
  .body-kuromi-collection__lineup .base-kuromi-collection__title {
    top: -135px;
  }
}
