.header-lens-replacement {
  position: relative;
  letter-spacing: 1px;
}

.header-lens-replacement__bg {
  max-width: 3840px;
}

.header-lens-replacement__bg picture img {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: 192/125;
}

@media (min-width: 768px) {
  .header-lens-replacement__bg picture img {
    aspect-ratio: 16/5;
  }
}

.header-lens-replacement__title {
  position: relative;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  padding: 34px 5%;
  background-color: #f4f4f4;
}

.header-lens-replacement__title-default, .header-lens-replacement__title-description {
  font-family: "Founders Grotesk", sans-serif;
  text-align: left;
}

.header-lens-replacement__title-default {
  font-size: 20px;
  font-weight: 600 !important;
  color: #000;
}

.header-lens-replacement__title-description {
  margin: 14px auto 0;
  color: #666;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .header-lens-replacement__title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    z-index: 2;
    margin: 0 auto;
    background-color: transparent;
  }
  .header-lens-replacement__title-default, .header-lens-replacement__title-description {
    text-align: center;
  }
  .header-lens-replacement__title-default {
    font-size: 40px;
    font-weight: 300 !important;
    line-height: 1;
  }
  .header-lens-replacement__title-description {
    max-width: 600px;
    margin: 17px auto 0;
  }
}
