@charset "UTF-8";
.footer-kimetsu__lens {
  padding: 50px 0 36px 0;
}
.footer-kimetsu__lens .header-kimetsu__sub-title-text {
  color: black;
}
.footer-kimetsu__lens__shops {
  list-style-type: none;
  padding: 0;
  width: 80%;
  height: auto;
  max-width: 1152px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footer-kimetsu__lens__shops li {
  position: relative;
  padding-left: 20px;
  width: 33%;
}
.footer-kimetsu__lens__shops li::before {
  content: "・";
  position: absolute;
  left: 0px;
  top: 11px;
  font-size: 12px;
}
.footer-kimetsu__lens__shops li a {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400 !important;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 2;
  outline: none;
  text-decoration: none;
  display: block;
  color: black;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: default;
}
@media (max-width: 767px) {
  .footer-kimetsu__lens__shops {
    margin-bottom: 30px;
  }
  .footer-kimetsu__lens__shops li {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .footer-kimetsu__lens {
    padding: 113px 0 130px 0;
  }
}
.footer-kimetsu__share {
  background-color: black;
  color: white;
  text-align: center;
  padding: 37px 0;
}
.footer-kimetsu__share ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 30px;
  margin-bottom: 45px;
  gap: 60px;
}
.footer-kimetsu__share ul a {
  color: white;
  font-size: 30px;
}
.footer-kimetsu__share ul a i:before {
  background-color: black;
}
.footer-kimetsu__share ul a .fa-facebook:before {
  content: "\f39e" !important;
}
.footer-kimetsu__share ul a .fa-twitter:before {
  content: "\1D54F" !important;
}
@media (min-width: 768px) {
  .footer-kimetsu__share {
    padding: 60px;
  }
  .footer-kimetsu__share ul {
    gap: 90px;
    margin-bottom: 56px;
  }
  .footer-kimetsu__share ul a {
    font-size: 40px;
  }
}
.footer-kimetsu__copyright {
  padding: 0;
  margin: 0;
  font-size: 11px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600 !important;
  color: white;
}
