.odslv-modal-pt__item {
  padding: 16px 24px;
  cursor: pointer;
  margin-bottom: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid #CDCDCD;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Founders Grotesk", sans-serif;
}

@media (max-width: 768px) {
  .odslv-modal-pt__item {
    gap: 8px;
    padding: 16px;
  }
}

.odslv-modal-pt__item--active {
  border: 2px solid #546073;
}

.odslv-modal-pt__item__info {
  width: 100%;
}

.odslv-modal-pt__item__img {
  width: 40px;
  height: 40px;
}

.odslv-modal-pt__item__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 8px;
  margin-bottom: 0px;
}

.odslv-modal-pt__item__name {
  color: #444;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 768px) {
  .odslv-modal-pt__item__name {
    font-size: 16px;
  }
}

.odslv-modal-pt__item__description {
  color: #444;
  font-size: 16px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .odslv-modal-pt__item__description {
    font-size: 14px;
  }
}
