.main-nav {
  max-width: 1920px;
  margin-inline: auto;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--left {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      .main-nav__nav {
        margin-left: 10px;
        @media (max-width: 1023px) {
          margin-left: 0;
        }

        .main-nav__nav-link {
          padding-bottom: 0px;
          font-size: 10px;
          line-height: 19px;
          color: #000;
          cursor: pointer;
        }
      }
    }

    &--right {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      justify-content: end;
      @media (max-width: 1023px) {
        justify-content: none;
      }
      &--info {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        gap: 10px;
        margin-left: 10px;
        @media (min-width: 1200px) {
          gap: 15px;
          margin-left: 15px;
        }
      }
    }
  }

  &__book-eye-test {
    padding: 10px;
    height: 34px;
    flex-shrink: 0;
    background-color: #0094C8;
    border-radius: 65px;
    border: 1px solid #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    margin-right: 10px;
    margin-top: 3px;

    img {
      width: 20px !important;
      height: 20px !important;
      object-fit: contain;
    }

    &__text {
      color: #FFFFFF;
      font-size: 12px;
      line-height: 24px;
    }
    @media (min-width: 1440px) {
      padding: 15px 20px;
      height: 44px;
      margin: 0 20px;
      gap: 13px;
      &__text{
        font-size: 16px;
      }
    }
  }

  &__countable {
    position: relative;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    img{
      width: 15px;
      height: 15px;
      object-fit: contain;
    }

    &:hover {
      opacity: 30%;
    }
    @media (min-width: 1440px) {
      img{
        width: 24px;
        height: 24px;
      }
    }
  }

  &__counter {
    display: flex;
    height: 15px;
    min-width: 15px;
    padding: 2px;
    border-radius: 15px;
    position: absolute;
    top: -6px;
    right: -9px;
    justify-content: center;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: 0em;
    color: #ffffff;
    background-color: #546073;
  }

  &__login {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 30%;
    }
  }

  &__nav {
    flex-grow: 1;
  }

  &__nav-links {
    display: flex;
    padding: 0 2px;
    margin-bottom: 0;
    column-gap: 10px;
  }

  &__nav-link-gap {
    min-width: 20px;
    max-width: 50px;
    flex-grow: 1;
  }

  &__nav-link {
    display: inline-block;
    padding-bottom: 9px;
    position: relative;
    font-size: 12px;
    line-height: 14px;
    color: #000000;

    &:hover {
      color: #000000;
      text-decoration: none;
      cursor: pointer;

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      margin-top: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #000000;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s ease-in-out;
    }
  }
  @media (max-width: 991px) {
    &__book-eye-test{
      display: none;
    }
  }
  @media (min-width: 1200px){
    &__top--left .main-nav__nav .main-nav__nav-link{
      font-size: 13px;
    }
  }
  @media (min-width: 1440px){
    &__nav-links{
      column-gap: 20px;
    }
    &__top--left .main-nav__nav .main-nav__nav-link{
      font-size: 15px;
    }
  }
}

.od-submenu-d-wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 20px 50px 0 50px;
  margin: 0 auto;
  height: fit-content;

  .od-submenu-d {
    margin: 0 auto;
    max-width: 1509px;
    padding: 23px 30px 23px 30px;
    background-color: #fff;
  
    .od-nav-level-2 {
      width: 250px;
    }
  }
  
  .contact-lens-cards {
    display: flex;
    flex-wrap: nowrap;
    gap: 33px;
    overflow-x: auto;
  }
}

.secondary-nav {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 23px;

    &--left {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      .secondary-nav__nav {
        margin-left: 10px;

        .secondary-nav__nav-link {
          padding-bottom: 0px;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          cursor: pointer;

          @media (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
    }

    &--right {
      display: flex;
      justify-content: end;
      align-items: center;
      flex: 1 0 auto;

      &--info {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        gap: 50px;
        margin-left: 50px;

        @media (max-width: 1400px) {
          margin-left: 20px;
          gap: 20px;
        }

        @media (max-width: 1260px) {
          margin-left: 1px;
          gap: 3px;
        }
      }
    }
  }

  &__book-eye-test {
    padding: 15px 20px;
    height: 44px;
    flex-shrink: 0;
    background: #0094C8;
    backdrop-filter: blur(5px);
    margin-right: 53px;
    border-radius: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    cursor: pointer;

    @media (max-width: 1024px) {
      display: none;
    }

    &__text {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 1400px) {
      margin-right: 20px;
    }

    @media (max-width: 1260px) {
      margin-right: 5px;
    }
  }

  &__countable {
    position: relative;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 30%;
    }
  }

  &__counter {
    display: flex;
    height: 15px;
    min-width: 15px;
    padding: 2px;
    border-radius: 15px;
    position: absolute;
    top: -6px;
    right: -9px;
    justify-content: center;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: 0em;
    color: #ffffff;
    background-color: #546073;
  }

  &__login {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 19px;
    color: #ffffff;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 30%;
    }
    @media (min-width: 1024px) {
      font-size: 12px;
    }
    @media (min-width: 1440px) {
      font-size: 16px;
    }
  }

  &__nav {
    flex-grow: 1;
  }

  &__nav-links {
    display: flex;
    padding: 0 2px;
    margin-bottom: 0;
  }

  &__nav-link-gap {
    min-width: 20px;
    max-width: 50px;
    flex-grow: 1;
  }

  &__nav-link {
    display: inline-block;
    padding-bottom: 9px;
    position: relative;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: #000000;
      text-decoration: none;

      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      margin-top: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #000000;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s ease-in-out;
    }
  }
}

.od-auth-handler {
  color: #fff;
  position: relative;
  display: inline-block;
  &-dropdown {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 200px;
    padding: 20px;
    right: 0;
    top: 30px;
    &::after {
      display: block;
      content: '';
      height: 30px;
    }
  }
}
.od-auth-handler:hover {
  .od-auth-handler-dropdown {
    display: block !important;
  }
}
.header--fixed {
  .secondary-nav__login {
    color: #000 !important;
  }
}
.header {
  .secondary-nav__login {
    color: #000 !important;
  }
}

@supports (-webkit-hyphens: none) {
  .main-nav__nav-link{
    padding-top: 12px;
  }
}
