.prescription-page {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
  padding: 37px 107px 50px 107px;
  width: 100%;
}

.prescription-page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 40px;
}

.prescription-page-title {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}

.prescription-page-text {
  font-size: 30px;
  color: #000;
}

.prescription-page-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 30px;
  margin-bottom: 50px;
}

.prescription-page-date {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  opacity: 0.5;
}

.prescription-page-table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
}

.prescription-page-table-row {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  background-color: #FFF;
}

.prescription-page-table-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: 13px 77px;
  border: 1px solid #D0D0D0;
  text-align: center;
}

.prescription-page-table-cell {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  padding: 13px 77px;
  border: 1px solid #D0D0D0;
  text-align: center;
}
