.main-nav {
  max-width: 1920px;
  margin-inline: auto;
}

.main-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-nav__top--left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.main-nav__top--left .main-nav__nav {
  margin-left: 10px;
}

@media (max-width: 1023px) {
  .main-nav__top--left .main-nav__nav {
    margin-left: 0;
  }
}

.main-nav__top--left .main-nav__nav .main-nav__nav-link {
  padding-bottom: 0px;
  font-size: 10px;
  line-height: 19px;
  color: #000;
  cursor: pointer;
}

.main-nav__top--right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

@media (max-width: 1023px) {
  .main-nav__top--right {
    -webkit-box-pack: none;
        -ms-flex-pack: none;
            justify-content: none;
  }
}

.main-nav__top--right--info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  margin-left: 10px;
}

@media (min-width: 1200px) {
  .main-nav__top--right--info {
    gap: 15px;
    margin-left: 15px;
  }
}

.main-nav__book-eye-test {
  padding: 10px;
  height: 34px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background-color: #0094C8;
  border-radius: 65px;
  border: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  gap: 8px;
  margin-right: 10px;
  margin-top: 3px;
}

.main-nav__book-eye-test img {
  width: 20px !important;
  height: 20px !important;
  -o-object-fit: contain;
     object-fit: contain;
}

.main-nav__book-eye-test__text {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 24px;
}

@media (min-width: 1440px) {
  .main-nav__book-eye-test {
    padding: 15px 20px;
    height: 44px;
    margin: 0 20px;
    gap: 13px;
  }
  .main-nav__book-eye-test__text {
    font-size: 16px;
  }
}

.main-nav__countable {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.main-nav__countable img {
  width: 15px;
  height: 15px;
  -o-object-fit: contain;
     object-fit: contain;
}

.main-nav__countable:hover {
  opacity: 30%;
}

@media (min-width: 1440px) {
  .main-nav__countable img {
    width: 24px;
    height: 24px;
  }
}

.main-nav__counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 2px;
  border-radius: 15px;
  position: absolute;
  top: -6px;
  right: -9px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0em;
  color: #ffffff;
  background-color: #546073;
}

.main-nav__login {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.main-nav__login:hover {
  opacity: 30%;
}

.main-nav__nav {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.main-nav__nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 2px;
  margin-bottom: 0;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}

.main-nav__nav-link-gap {
  min-width: 20px;
  max-width: 50px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.main-nav__nav-link {
  display: inline-block;
  padding-bottom: 9px;
  position: relative;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.main-nav__nav-link:hover {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

.main-nav__nav-link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.main-nav__nav-link::after {
  content: '';
  width: 100%;
  height: 1px;
  margin-top: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000000;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .main-nav__book-eye-test {
    display: none;
  }
}

@media (min-width: 1200px) {
  .main-nav__top--left .main-nav__nav .main-nav__nav-link {
    font-size: 13px;
  }
}

@media (min-width: 1440px) {
  .main-nav__nav-links {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  .main-nav__top--left .main-nav__nav .main-nav__nav-link {
    font-size: 15px;
  }
}

.od-submenu-d-wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
  padding: 20px 50px 0 50px;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.od-submenu-d-wrapper .od-submenu-d {
  margin: 0 auto;
  max-width: 1509px;
  padding: 23px 30px 23px 30px;
  background-color: #fff;
}

.od-submenu-d-wrapper .od-submenu-d .od-nav-level-2 {
  width: 250px;
}

.od-submenu-d-wrapper .contact-lens-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 33px;
  overflow-x: auto;
}

.secondary-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 23px;
}

.secondary-nav__top--left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.secondary-nav__top--left .secondary-nav__nav {
  margin-left: 10px;
}

.secondary-nav__top--left .secondary-nav__nav .secondary-nav__nav-link {
  padding-bottom: 0px;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .secondary-nav__top--left .secondary-nav__nav .secondary-nav__nav-link {
    font-size: 12px;
  }
}

.secondary-nav__top--right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.secondary-nav__top--right--info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
  margin-left: 50px;
}

@media (max-width: 1400px) {
  .secondary-nav__top--right--info {
    margin-left: 20px;
    gap: 20px;
  }
}

@media (max-width: 1260px) {
  .secondary-nav__top--right--info {
    margin-left: 1px;
    gap: 3px;
  }
}

.secondary-nav__book-eye-test {
  padding: 15px 20px;
  height: 44px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: #0094C8;
  backdrop-filter: blur(5px);
  margin-right: 53px;
  border-radius: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 13px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .secondary-nav__book-eye-test {
    display: none;
  }
}

.secondary-nav__book-eye-test__text {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 1400px) {
  .secondary-nav__book-eye-test {
    margin-right: 20px;
  }
}

@media (max-width: 1260px) {
  .secondary-nav__book-eye-test {
    margin-right: 5px;
  }
}

.secondary-nav__countable {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.secondary-nav__countable:hover {
  opacity: 30%;
}

.secondary-nav__counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 2px;
  border-radius: 15px;
  position: absolute;
  top: -6px;
  right: -9px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 9px;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0em;
  color: #ffffff;
  background-color: #546073;
}

.secondary-nav__login {
  margin-bottom: 0;
  font-size: 10px;
  line-height: 19px;
  color: #ffffff;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.secondary-nav__login:hover {
  opacity: 30%;
}

@media (min-width: 1024px) {
  .secondary-nav__login {
    font-size: 12px;
  }
}

@media (min-width: 1440px) {
  .secondary-nav__login {
    font-size: 16px;
  }
}

.secondary-nav__nav {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.secondary-nav__nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 2px;
  margin-bottom: 0;
}

.secondary-nav__nav-link-gap {
  min-width: 20px;
  max-width: 50px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.secondary-nav__nav-link {
  display: inline-block;
  padding-bottom: 9px;
  position: relative;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  white-space: nowrap;
  cursor: pointer;
}

.secondary-nav__nav-link:hover {
  color: #000000;
  text-decoration: none;
}

.secondary-nav__nav-link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.secondary-nav__nav-link::after {
  content: '';
  width: 100%;
  height: 1px;
  margin-top: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000000;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.od-auth-handler {
  color: #fff;
  position: relative;
  display: inline-block;
}

.od-auth-handler-dropdown {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 200px;
  padding: 20px;
  right: 0;
  top: 30px;
}

.od-auth-handler-dropdown::after {
  display: block;
  content: '';
  height: 30px;
}

.od-auth-handler:hover .od-auth-handler-dropdown {
  display: block !important;
}

.header--fixed .secondary-nav__login {
  color: #000 !important;
}

.header .secondary-nav__login {
  color: #000 !important;
}

@supports (-webkit-hyphens: none) {
  .main-nav__nav-link {
    padding-top: 12px;
  }
}
