.header-gift-cards-products {
  padding: 40px 0 50px 0;
}

.header-gift-cards-products__title, .header-gift-cards-products__desc {
  font-family: "Founders Grotesk", sans-serif;
  color: black;
  letter-spacing: 1px;
}

.header-gift-cards-products__title {
  font-size: 20px;
  font-weight: normal !important;
  line-height: 1.5;
}

.header-gift-cards-products__desc {
  margin: 0;
  font-size: 15px;
  font-weight: normal !important;
  line-height: 2;
}

@media (min-width: 768px) {
  .header-gift-cards-products__title {
    font-size: 38px;
  }
}

@media (min-width: 1024px) {
  .header-gift-cards-products {
    padding-top: 80px;
  }
}
