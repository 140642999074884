$red: #c7002e;
$blue: #0094c8;
$light: #f4f4f4;

.services__lens-details {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin: 0;
  &-item {
    display: flex;
    flex-direction: column;
    border: 1px solid $light;
  }
  &-header {
    display: grid;
    grid-template-columns: 55px 1fr;
    border-bottom: 1px solid $light;
    padding: 20px 0;
    &-icon {
      max-width: 30px;
      margin: 0 auto;
    }
    &-details {
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      i {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 17px;
        text-align: center;
        font-size: 11px;
        line-height: 1;
        color: #fff;
        margin-bottom: 3px;
        font-style: normal;
        &.standard {
          background-color: $blue;
        }
        &.option {
          background-color: $red;
        }
      }
      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 8px;
        row-gap: 4px;
        h3 {
          font-size: 14px;
          font-weight: 600 !important;
          color: #000;
          margin: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          column-gap: 8px;
          span {
            display: inline-flex;
            align-items: center;
            column-gap: 8px;
            img {
              width: auto;
              height: 27px;
            }
          }
          small {
            color: $red;
            font-weight: 400;
            font-size: 100%;
          }
        }
        p {
          color: $red;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
          margin: 0;
          small {
            font-weight: 400;
          }
        }
      }
    }
  }
  &-body {
    padding: 20px;
    font-size: 16px;
    h4 {
      font-size: 14px;
      font-weight: 600 !important;
      margin: 0 0 10px;
      color: #000;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      strong {
        font-weight: inherit;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 4px;
        small {
          color: $red;
          font-size: inherit;
          font-weight: 400 !important;
        }
      }
      span {
        color: $red;
        margin-left: 8px;
      }
    }
    h5{
      color: #000;
    }
    p {
      font-size: 15px;
      line-height: 2;
      color: #666;
      margin: 0 0 20px;
    }
    ul{
      &.list-disc{
        >li{
          position: relative;
          padding-left: 1.5em;
          &::before{
            content: '\00B7';
            position: absolute;
            top: 0;
            left: 1em;
          }
        }
      }
      &.list-notes{
        font-size: 11px;
        color: #000;
      }
    }
    &-features {
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;
      tr {
        th,
        td {
          border-style: solid;
          border-color: $light;
        }
        th {
          font-weight: 400;
          border-width: 1px 1px 1px 0;
          padding: 10px 14px 10px 0;
          color: #000;
        }
        td {
          color: #666;
          border-width: 1px 0 1px 1px;
          padding: 10px 0 10px 14px;
          ul {
            li {
              position: relative;
              padding-left: 30px;
              &::before {
                content: '';
                position: absolute;
                top: 4px;
                left: 0;
                width: 15px;
                height: 15px;
                background: url('https://static.lenskart.com/media/owndays/img/services-lens/check.svg')
                  no-repeat center/contain;
              }
            }
          }
        }
      }
    }
    &-lineup {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      > li {
        border-top: 1px solid $light;
        padding-top: 20px;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    &-examples {
      font-size: 11px;
      padding: 20px;
      border: 1px solid $light;
      margin-top: 20px;
      ul {
        margin-left: auto;
        margin-right: auto;
        li {
          h6 {
            font-size: 12px;
            font-weight: 600 !important;
            line-height: 1.6;
            margin: 0 0 10px;
            color: #000;
          }
          div {
            p {
              font-size: inherit;
              margin-bottom: 10px;
            }
            figure {
              figcaption {
                text-align: center;
                margin-top: 5px;
                &.figcaption{
                  &-pclens{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0;
                    span{
                      display: inline-flex;
                      max-width: 100px;
                    }
                  }
                  &-left{
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
      figure{
        margin: 0;
      }
      .cols {
        display: grid;
        grid-gap: 10px;
        &-2 {
          grid-template-columns: 1fr 1fr;
        }
        &-3 {
          grid-template-columns: repeat(3, 1fr);
        }
        &-lens {
          &-transition {
            display: flex;
            justify-content: center;
            li {
              max-width: calc(100% / 3);
            }
          }
          &-color{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li{
              max-width: 70px;
            }
          }
        }
      }
    }
    &-notes {
      margin-top: 10px;
      font-size: 11px;
    }
  }
  @media (min-width: 768px) {
    &-header {
      grid-template-columns: 68px 1fr;
      &-icon {
        max-width: 36px;
      }
      &-details {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 20px;
        align-items: center;
        i {
          height: 30px;
          margin-bottom: 0;
        }
        div {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-column-gap: 20px;
          h3 {
            font-size: 16px;
          }
        }
      }
    }
    &-body {
      padding: 30px 25px;
      &-features {
        tr {
          td {
            ul {
              columns: 2;
            }
          }
        }
      }
      &-examples {
        text-align: center;
        ul {
          li {
            h6 {
              font-size: 14px;
            }
            div {
              figure {
                figcaption {
                  margin-top: 10px;
                }
              }
            }
          }
        }
        .cols {
          &-pc {
            &-2 {
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 40px;
            }
            &-bd{
              >li:not(:last-child){
                position: relative;
                &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: calc(100% + 20px);
                  bottom: 0;
                  border-right: 1px solid $light;
                }
              }
            }
            &-aspheric{
              grid-template-columns: 2fr 3fr;
            }
          }
          &-lens {
            &-transition {
              flex-wrap: wrap;
              li {
                max-width: 124px;
              }
            }
          }
        }
      }
    }
  }
}
