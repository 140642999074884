$black: #000;
$white: #fff;
$gray: #EBEDF3;
$button-color: #546073;

$font-founders: 'Founders Grotesk', sans-serif;

.form-policy {
  &__container {
    padding: 16px 24px 40px 24px;
    margin: 0 auto;

    @media (min-width: 430px) {
      padding: 16px 40px 40px 40px;
    }

    @media (min-width: 768px) {
      padding: 16px 100px 40px 100px;
    }

    @media (min-width: 1024px) {
      padding: 16px 140px 40px 140px;
    }

    @media (min-width: 1296px) {
      padding: 40px 200px 80px 200px;
    }
  }

  &__box {
    border-radius: 6px;
    border: 1px solid $gray;
    padding: 16px 24px;

    &:first-child {
      margin-bottom: 24px;
    }

    @media (min-width: 1076px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__question, &__name {
    font-family: $font-founders;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 32px;
    color: $black;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 40px;
    }
  }

  &__agree-layout {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__list {
    list-style-type: none;
    margin: 14px 0 0 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 26px;
    align-items: center;

    @media (min-width: 1076px) {
      margin-top: 0;
      display: flex;
      gap: 22px;
    }
  }

  &__item {
    list-style-type: none;
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    column-gap: 16px;
  }

  &__radio, &__checkbox {
    width: 24px;
    height: 24px;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer;

    &:checked {
      accent-color: $black;
    }
  }

  &__confirm-button {
    width: 100%;
    max-width: 377px;
    height: 50px;
    margin: 24px auto 0 auto;
    border-radius: 30px;
    text-align: center;
    font-family: $font-founders;
    font-size: 15px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 30px;
    padding: 0 24px;
    background-color: $button-color;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    
    &--inactive {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &--active {
      cursor: pointer;
    }

    @media (min-width: 430px) {
      max-width: 320px;
    }

    @media (min-width: 768px) {
      margin: 48px auto 0 auto;
    }
  }
}
