.receive-news {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 16px;

  &__list {
    list-style-type: none;
    margin: 16px 2px;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      & + label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        display: inline-block;
        line-height: 25px;
      }

      & + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 20px;
        height: 20px;
        border: 2px solid #546073;
        border-radius: 50%;
        background: #fff;
      }

      &:checked + label:before {
        background: #3885ff;
        border-color: #3885ff;
      }

      &:checked + label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 7px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    &__name {
      margin: 0;
      font-weight: 400;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
