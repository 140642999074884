.od-shimmer-bg {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #77777735 8%, #f0f0f0 18%, #77777735 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}