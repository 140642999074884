.header-news {
  opacity: 0;
  -webkit-transform: translate(0, 16px);
          transform: translate(0, 16px);
  -webkit-transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
}

.header-news__title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.header-news--active {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.header-news--active .header-news__title-text {
  opacity: 1;
}

.header-news--active .header-news__title-text:before {
  width: 100%;
}

.header-news--active .header-news__title-text span {
  opacity: 1;
}

.header-news__title-text {
  display: inline-block;
  position: relative;
  padding: 3px 26px;
  line-height: 1;
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity 1s cubic-bezier(1, 0, 0, 1) 0.25s;
  transition: opacity 1s cubic-bezier(1, 0, 0, 1) 0.25s;
  margin: 0;
  background-color: #000;
  letter-spacing: 1px;
  font-size: 40px;
  font-weight: 300 !important;
}

@media (max-width: 767px) {
  .header-news__title-text {
    padding: 3px 13px;
  }
}

.header-news__title-text--active {
  -webkit-animation: slide-in-left-to-right 0.75s ease-out forwards;
          animation: slide-in-left-to-right 0.75s ease-out forwards;
}

.header-news__line-curve {
  display: block;
  position: absolute;
  top: 10px;
  right: -105px;
  width: 274px;
  height: 140px;
  background: 0 0 no-repeat;
  background-image: url("https://static.lenskart.com/media/owndays/img/news/line-curve.png");
  background-size: 274px 140px;
}

@media (max-width: 767px) {
  .header-news__line-curve {
    width: 116px;
    height: 70px;
    top: 5px;
    right: auto;
    left: 60%;
    background-size: 137px 70px;
  }
}

@-webkit-keyframes slide-in-left-to-right {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0);
  }
  100% {
    -webkit-clip-path: inset(0);
            clip-path: inset(0);
  }
}

@keyframes slide-in-left-to-right {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
            clip-path: inset(0 100% 0 0);
  }
  100% {
    -webkit-clip-path: inset(0);
            clip-path: inset(0);
  }
}
