$font-noto: "Noto Sans JP", sans-serif;
$font-din: din-condensed, sans-serif;

$green-55: #555E2B;
$brown-9d: #9D674F;
$gray-cc: #ccc;

.main-lineup-freak-store {
  &__main-slide {
    max-width: 650px;
    margin: 0 auto;
    margin-top: 30px;
    padding-bottom: 53px;
    position: relative;

    > .slick-dots {
      padding-left: 0;
      list-style-type: none;
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin: 12px 0 0 0;

      > li, > li > button {
        width: 40px;
        height: 5px;
      }

      > li > button {
        border: 0;
        border-radius: 0;
        opacity: 1;
        margin: 0;
        background-color: $gray-cc;
        color: transparent;
      }
      
      .slick-active > button {
        background-color: black;
      }
    }
  }

  &__content {
    &-slider {
      &__title {
        margin: 0 calc(15px * -1);
        padding-top: 28px;
        padding-bottom: 28px;
        border-top: 1px solid $green-55;
        border-bottom: 1px solid $green-55;

        h2, p {
          font-family: $font-noto;
          text-align: center;
          margin: 0;
          font-weight: normal;
        }

        p {
          font-size: 15px;
          line-height: 2;

          span {
            font-family: $font-noto;
            font-weight: bold !important;
            font-size: 14px;
          }
        }

        h2 {
          font-size: 32px;
          font-weight: bold !important;
          line-height: 38px;
        }

        &--highlight {
          font-family: $font-noto;
          font-weight: bold !important;
          color: $brown-9d;
          letter-spacing: 0.87px;
        }
      }

      &__sub-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin: 20px calc(15px * -1);
        font-size: 27px;
        font-family: $font-din;
        letter-spacing: 0.81px;
        font-weight: bold !important;
        line-height: 1.5;
      }

      &__message {
        font-size: 15px;
        font-weight: normal !important;
        line-height: 2;
        margin: 0;
      }
    }
  }

  @media (min-width: 768px) {
    &__main-slide {
      margin-top: 0px;
      padding-bottom: 0;

      > .slick-dots {
        justify-content: flex-start;
        gap: 10px;
        margin: 12px 0 0 0;

        > li, > li > button {
          width: 50px;
          height: 5px;
        }
      }
    }

    &__detail-2way {
      display: flex;
      justify-content: center;
      align-items: center;

      &--row-reverse {
        flex-direction: row-reverse;
      }
    }

    &-slider {
      width: 50%;
    }

    &__content {
      &-slider {
        width: 50%;
        margin-top: -45px;

        &__title {
          margin: auto;
          margin-right: calc(15px * -1);
          padding: 28px;

          h2, p {
            text-align: left;
          }

          p {
            span {
              font-size: 18px;
            }
          }

          &--reverse {
            margin-right: inherit !important;
            margin-left: -100vw !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            h2, p {
              max-width: 540px !important;
              margin-left: auto !important;

              @media (max-width: 1123px) {
                max-width: calc(100% - 67.4%) !important;
              }
            }
          }
        }

        &__detail {
          padding: 0 30px;

          &--reverse {
            padding: 0 73px 0 0px !important;
          }
        }

        &__sub-title {
          display: flex;
          justify-content: flex-start;
          margin: 40px 0 15px 0;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    padding-bottom: 120px;

    &__frame-section {
      margin-top: 80px;
    }

    &__main-slide {
      margin-top: 0px;
    }

    &__content {
      &-slider {
        &__title {
          margin-right: -100vw;
          padding: 35px 73px 30px 73px;

          h2 {
            span {
              font-size: 32px;
              line-height: 60px;
            }
          }
        }

        &__detail {
          padding: 0 0px 0 73px;
        }

        &__sub-title {
          font-size: 32px;
        }

        &__message {
          font-size: 16px;
        }
      }
    }
  }
}
