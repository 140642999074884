.od-membership-heading__subtitle {
    width: 100%;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-top: 120px;
    text-align: center;
    opacity: 0.5;
}

.od-membership-heading__title {
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  display: flex;
  margin-bottom: 41px;
  margin-top: 20px;

  &:before,
  &:after {
    content: ' ';
    flex: 1 1;
    border-bottom: 1px solid #000000;
    opacity: 0.2;
  }

  &:before {
    margin: auto 67px auto 0;
  }

  &:after {
    margin: auto 0 auto 67px;
  }
}

.od-membership-heading__description {
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  opacity: 0.5;
  padding: 0 283px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .od-membership-heading__subtitle {
    margin-top: 50px;
  }

  .od-membership-heading__title {
    font-size: 32px;
  }

  .od-membership-heading__description {
    padding: 0 20px;
  }
}
