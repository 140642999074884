@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap");
@font-face {
  font-family: "Corporate Logo Rounded ver2";
  src: url("https://static.lenskart.com/media/owndays/desktop/fonts/Corporate-Logo/Corporate-Logo-Rounded.woff2") format("woff2"), url("https://static.lenskart.com/media/owndays/desktop/fonts/Corporate-Logo/Corporate-Logo-Rounded.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@-webkit-keyframes loading-pompompurin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes loading-pompompurin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body {
  -webkit-animation: loading-pompompurin 1s;
          animation: loading-pompompurin 1s;
}
.base-pompompurin {
  font-family: "heisei-maru-gothic-std", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.92308;
  letter-spacing: 0.26px;
  color: #76332e;
  background: #fff9b1 url("https://static.lenskart.com/media/owndays/img/pompompurin/bg-dots.svg") repeat center top/auto;
  background-attachment: fixed;
}
.base-pompompurin section {
  position: relative;
}
.base-pompompurin :is(h1, h2, h3, h4, h5, h6) {
  font-family: inherit;
}
.base-pompompurin__hidden-scroll {
  overflow: hidden;
}
.base-pompompurin__hide--pc {
  display: block;
}
@media (min-width: 1024px) {
  .base-pompompurin__hide--pc {
    display: none;
  }
}
.base-pompompurin__hide--sp {
  display: none;
}
@media (min-width: 1024px) {
  .base-pompompurin__hide--sp {
    display: block;
  }
}
.base-pompompurin__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1190px;
}
.base-pompompurin__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  background-color: #ffcb14;
  width: 100%;
  max-width: 310px;
  margin-inline: auto;
  height: 71px;
  border: 3px solid #76332e;
  border-radius: 10rem;
  padding-inline: 35px 13px;
  font-size: 14px;
  font-weight: 800 !important;
  line-height: 1.5;
  color: #76332e;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.base-pompompurin__btn i {
  font-style: normal;
  background: transparent;
}
.base-pompompurin__btn:hover, .base-pompompurin__btn:active, .base-pompompurin__btn:visited, .base-pompompurin__btn:focus {
  color: inherit;
  text-decoration: none;
}
.base-pompompurin__btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #76332e;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  z-index: -1;
}
.base-pompompurin__btn-arrow {
  display: grid;
  place-items: center;
  width: 47px;
  height: 47px;
  background-color: #76332e;
  border-radius: 10rem;
}
.base-pompompurin__btn[disabled] {
  pointer-events: none;
}
.base-pompompurin__btn[disabled]::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, .44);
}
.base-pompompurin__btn--white {
  background-color: white;
}
.base-pompompurin__btn--white .base-pompompurin__btn-arrow svg path {
  fill: white;
  stroke: white;
}
.base-pompompurin__btn--nav {
  padding-inline: 20px;
  min-width: 166px;
}
.base-pompompurin__btn--nav span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: -4px;
}
@media (max-width: 767px) {
  .base-pompompurin__btn--nav {
    min-width: 0;
    width: 110px;
    height: 42px;
    border: none;
    font-size: 11px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 768px) {
  .base-pompompurin__btn--nav {
    padding-inline: 35px;
  }
}
@media (min-width: 1024px) {
  .base-pompompurin__btn {
    font-size: 16px;
    width: 300px;
  }
  .base-pompompurin__btn span svg {
    width: 16px;
    height: 27px;
  }
  .base-pompompurin__btn:hover {
    color: #ffcb14;
  }
  .base-pompompurin__btn:hover::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .base-pompompurin__btn:hover .base-pompompurin__btn-arrow {
    background-color: #ffcb14;
  }
  .base-pompompurin__btn:hover .base-pompompurin__btn-arrow svg path {
    fill: #76332e;
    stroke: #76332e;
  }
}
.base-pompompurin__nav {
  position: sticky;
  bottom: -1px;
  z-index: 20;
  background-color: rgba(0, 0, 0, .7);
  padding-block: 20px;
  color: white;
}
.base-pompompurin__nav-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 7px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.base-pompompurin__nav-duration {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font: 700 22px/30px "Corporate Logo Rounded ver2", sans-serif;
}
.base-pompompurin__nav-duration small {
  font: 800 11px/20px "heisei-maru-gothic-std", sans-serif;
}
.base-pompompurin__nav-discount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 9px;
  font-weight: 800 !important;
  line-height: 15px;
}
.base-pompompurin__nav-discount a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: underline;
  color: currentColor;
}
@media (max-width: 1296px) {
  .base-pompompurin__nav {
    padding-inline: 20px;
    text-align: right;
  }
  .base-pompompurin__nav-grid {
    display: inline-grid;
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
  }
  .base-pompompurin__nav-duration {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .base-pompompurin__nav-discount {
    grid-column: span 2;
  }
  .base-pompompurin__nav .base-pompompurin__container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1296px) {
  .base-pompompurin__nav {
    padding-block: 37px 24px;
  }
  .base-pompompurin__nav .base-pompompurin__container {
    text-align: center;
  }
  .base-pompompurin__nav-grid {
    display: inline-grid;
    grid-template-columns: auto auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
  }
  .base-pompompurin__nav-duration {
    font-size: 31px;
    line-height: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .base-pompompurin__nav-duration small {
    font-size: 14px;
    line-height: 28px;
    margin-left: 0;
  }
}
