.header-ortho-k-lens__top__img {
  position: relative;
  margin-left: -27px;
}

.header-ortho-k-lens__top__title {
  position: absolute;
  bottom: 69px;
  left: 0;
  right: 0;
  width: 74%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .header-ortho-k-lens__top__title {
    top: 50%;
    bottom: auto;
    left: auto;
    right: 40px;
    width: 352px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@media (min-width: 1024px) {
  .header-ortho-k-lens__top__img {
    margin-left: calc(560px - 50vw);
  }
  .header-ortho-k-lens__top__title {
    right: 160px;
    width: 462px;
  }
}

.header-ortho-k-lens__intro {
  margin-top: 79px;
}

.header-ortho-k-lens__intro .base-ortho-k-lens__title {
  margin-bottom: 29px;
  grid-area: title;
}

.header-ortho-k-lens__intro p {
  font-size: 14px;
  font-weight: 600 !important;
  grid-area: text;
  margin: 0;
}

.header-ortho-k-lens__intro__img {
  display: block;
  margin: 48px auto 0;
  width: 89.6%;
  max-width: 470px;
  grid-area: img;
}

@media (min-width: 768px) {
  .header-ortho-k-lens__intro {
    display: grid;
    grid-template-columns: 1fr auto;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-template-areas: "title img" "text img";
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  .header-ortho-k-lens__intro__img {
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .header-ortho-k-lens__intro {
    margin-top: 168px;
    grid-template-columns: 1fr 449px;
  }
  .header-ortho-k-lens__intro p {
    font-size: 16px;
  }
  .header-ortho-k-lens__intro__img {
    margin-top: 0;
    width: 100%;
    max-width: none;
  }
}

@media (min-width: 1296px) {
  .header-ortho-k-lens__intro {
    -webkit-column-gap: 112px;
       -moz-column-gap: 112px;
            column-gap: 112px;
  }
}

.header-ortho-k-lens__video {
  margin: 61px auto 0;
}

@media (max-width: 430px) {
  .header-ortho-k-lens__video .base-ortho-k-lens__container--sub {
    height: 214.45px;
  }
}

@media (max-width: 390px) {
  .header-ortho-k-lens__video .base-ortho-k-lens__container--sub {
    height: 191.63px;
  }
}

.header-ortho-k-lens__video a {
  position: relative;
}

@media (min-width: 1024px) {
  .header-ortho-k-lens__video a:hover img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0.6;
  }
}

.header-ortho-k-lens__video__iframe-container {
  width: 100%;
  width: 100%;
  height: 100%;
  display: inline-block;
  aspect-ratio: 16/9;
}

@media (min-width: 768px) {
  .header-ortho-k-lens__video {
    margin-top: 82px;
  }
  .header-ortho-k-lens__video a {
    position: relative;
  }
  .header-ortho-k-lens__video a::before {
    background-size: 86px auto;
  }
}

.header-ortho-k-lens__how-works {
  margin-top: 70px;
  padding: 70px 0;
  position: relative;
}

.header-ortho-k-lens__how-works::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0;
  background-color: #f7f9fc;
  padding-top: 70px;
  z-index: -1;
}

.header-ortho-k-lens__how-works .base-ortho-k-lens__container--sub {
  max-width: 1022px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .header-ortho-k-lens__how-works .base-ortho-k-lens__container--sub {
    margin-left: 0;
  }
}

.header-ortho-k-lens__how-works__list {
  margin: 67px -27px 8px;
  padding: 37px 27px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  counter-reset: how-works-counter;
  list-style-type: none;
}

@media (max-width: 1023px) {
  .header-ortho-k-lens__how-works__list {
    overflow: overlay;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0 calc(100% - 30px));
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0 calc(100% - 30px));
  }
}

.header-ortho-k-lens__how-works__list > li {
  width: 80%;
  max-width: 213px;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  background-color: #fff;
  border-radius: 28px;
  padding: 44px 10px 30px;
  position: relative;
  counter-increment: how-works-counter;
  list-style-type: none;
}

.header-ortho-k-lens__how-works__list > li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -20px;
  width: 15px;
  height: 15px;
  -webkit-transform: translateY(calc(-50% - 10px));
          transform: translateY(calc(-50% - 10px));
  background: url("https://static.lenskart.com/media/owndays/img/ortho-k-lens/icon-caret-right.svg") center/contain no-repeat;
}

.header-ortho-k-lens__how-works__list > li:first-child::before {
  content: none;
}

.header-ortho-k-lens__how-works__list > li + li {
  margin-left: 26px;
}

.header-ortho-k-lens__how-works__list-number {
  font: 700 27px/1 din-2014, sans-serif;
  font-weight: 700 !important;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: -80px auto 10px;
}

.header-ortho-k-lens__how-works__list-number::before {
  content: counter(how-works-counter);
}

.header-ortho-k-lens__how-works__list-img {
  display: block;
  margin: 0 auto;
}

.header-ortho-k-lens__how-works__list-title {
  font: 700 19px/1.6 "Noto Sans", sans-serif;
  font-weight: 700 !important;
  text-align: center;
  margin: 20px 0 0 0;
}

.header-ortho-k-lens__how-works__list-title small {
  font-size: 15px;
  font-weight: 300 !important;
  letter-spacing: 0.01em;
  line-height: 1.6;
  display: block;
}

.header-ortho-k-lens__how-works__des {
  font-size: 13px;
  font-weight: normal !important;
  margin: 0;
}

@media (min-width: 1024px) {
  .header-ortho-k-lens__how-works {
    margin-top: 134px;
    padding: 108px 0 120px;
  }
  .header-ortho-k-lens__how-works__list {
    margin: 55px 0 66px 0;
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .header-ortho-k-lens__how-works__des {
    font-size: 15px;
  }
}

@media (min-width: 1296px) {
  .header-ortho-k-lens__how-works__list {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header-ortho-k-lens__how-works__list > li::before {
    left: -33px;
  }
  .header-ortho-k-lens__how-works__list > li + li {
    margin-left: 57px;
  }
}
