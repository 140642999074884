$font-founders: 'Founders Grotesk', sans-serif;

$gray-70: #707070;
$gray-66: #666;
$gray-51: #516075;
$gray-ad: #adadad;
$gray-d9: #d9d9d9;
$white-f4: #f4f4f4;
$white-ec: #ecf2fa;
$blue-00: #0094c8;
$black-2d: #2d4156;

$base-img-url: 'https://static.lenskart.com/owndays/img/cookies-modal';

.cookies-modal {
  &__button {
    width: auto;
    font-family: $font-founders;
    font-weight: 700 !important;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: .195px;
    margin: 0;
    border-radius: 0;
    padding: 8px;
    flex: 1 1 auto;
    border: 1px solid $gray-66;

    &--white {
      color: $gray-66;
      background-color: white;
      border: 1px solid $gray-66;
    }

    &--gray {
      background-color: $gray-51;
      border-color: $gray-51;
      color: white;
    }

    @media (min-width: 1024px) {
      padding: 16px;
      letter-spacing: .195px;

      &--white:hover {
        transition: 0.5s;
        background-color: $gray-66;
        color: white;
      }

      &--gray:hover {
        transition: 0.5s;
        opacity: .8;
      }
    }
  }

  &__popup {
    position: fixed;
    left: 8px;
    right: 8px;
    bottom: 12px;
    z-index: 9999999;

    &__container {
      width: 100%;
      max-width: 467px;
      height: auto;
      background-color: hsla(0, 0%, 100%, .9);
      padding: 12px 16px;
      margin: 0 auto;
      box-shadow: 0 .625em 1.875em rgba(2, 2, 3, .28);
      position: relative;
    }

    &__close {
      position: absolute;
      top: 12px;
      right: 16px;
      width: 16px;
      height: 16px;
      border: none;
      background-color: transparent;

      &::before {
        content: '';
        background-image: url("#{$base-img-url}/close-icon.svg");
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        top: 0;
        left: 0;
      }
    }

    &__title {
      font-family: $font-founders;
      font-weight: 600 !important;
      font-size: 11.5px;
      line-height: 17px;
      letter-spacing: .173px;
      color: black;
      margin-bottom: 2px;
    }

    &__desc {
      font-family: $font-founders;
      font-weight: 300 !important;
      font-size: 11px;
      line-height: normal;
      color: black;
      margin-bottom: 0;

      a {
        font-family: $font-founders;
        font-weight: 600 !important;
        color: $blue-00;
        border-color: $blue-00;
        text-decoration: underline;
        text-underline-offset: 2px;

        &:hover {
          color: $blue-00;
          border-color: $blue-00;
        }

        @media (min-width: 1024px) {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 3px;
      gap: 12px;
    }

    @media (min-width: 520px) {  
      &__title {
        text-align: center;
      }
    }

    @media (min-width: 768px) {  
      &__title {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }

    @media (min-width: 1024px) {
      &__container {
        max-width: 890px;
        padding-left: 19px;
        padding-right: 48px;
      }
      
      &__row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }

      &__close {
        top: 50%;
        transform: translateY(-50%);
      }

      &__title {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 3px;
      }

      &__desc {
        text-align: left;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: .195px;
      }

      &__buttons {
        margin-top: 0;
      }
    }
  }

  &__settings {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;

    &__layout {
      width: 100%;
      height: 100%;
    }
    
    &__content {
      width: 100%;
      max-width: 600px;
      height: 100%;
      background-color: white;
    }

    &__header {
      width: 100%;
      height: 60px;
      padding: 0 1.3em 0 30px;
      position: relative;
    }

    &__title {
      font-family: $font-founders;
      font-weight: 600 !important;
      font-size: 16px;
      letter-spacing: 0;
      color: black;
      margin: 0;
      padding-top: 40px;
    }

    &__close {
      position: absolute;
      width: 21px;
      height: 21px;
      top: 50%;
      transform: translateY(-50%);
      right: 1.3em;
      border: none;
      background: transparent;

      &::before {
        content: '';
        background-image: url("#{$base-img-url}/close-icon.svg");
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &__body {
      width: 100%;
      height: calc(100% - 147px);
      padding: 22px 30px 50px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &__desc {
      p {
        color: black;
        line-height: 20px;
        font-family: $font-founders;
        font-weight: lighter !important;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 0;

        &:nth-child(2) {
          margin-top: 10px;
          padding-bottom: 20px;
        }
      }

      a {
        color: black;
        text-decoration: underline;

        &:hover {
          color: black;
        }

        @media (min-width: 1024px) {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    &__item {
      &__header, &__desc {
        background-color: $white-f4;
        border-radius: .35em;
      }

      &__header {
        padding: 0 10px 0 0;
        letter-spacing: 0;
        position: relative;
      }

      &__name {
        position: relative;
        width: 88%;
        height: auto;
        padding: 20px 0 20px 35px;

        @media (min-width: 1024px) {
          cursor: pointer;
        }

        &::before {
          content: '';
          position: absolute;
          border: solid $black-2d;
          border-width: 0 2px 2px 0;
          top: 48%;
          left: 17px;
          transform: translateY(-50%) rotate(45deg);
          margin-right: 15px;
          padding: .2em;
        }

        h3 {
          width: fit-content;
          height: auto;
          margin: 0;
          font-family: $font-founders;
          font-size: 13.3px;
          letter-spacing: 0;
          color: black;
        }
      }

      &__desc {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        p {
          font-family: $font-founders;
          font-size: 12px;
          line-height: 20px;
          margin-top: 0;
          padding: .2em 15px 27px;
          letter-spacing: 0;
          color: black;
          margin-bottom: 0;
        }
      }

      &__toggle {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%) scale(1.1);
        width: 52px;
        height: 24px;
        border-radius: 4em;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          @media (min-width: 1024px) {
            cursor: pointer;
          }
        }

        &--slider {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transition: 0.4s;
          border-radius: 34px;
          background-color: $gray-ad;

          @media (min-width: 1024px) {
            cursor: pointer;
          }

          &::before {
            content: "";
            position: absolute;
            height: 18px;
            width: 18px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
            z-index: 1;
          }
        }

        input:checked + .cookies-modal__settings__item__toggle--slider {
          background-color: $gray-51;
        }
        
        input:checked + .cookies-modal__settings__item__toggle--slider:before {
          transform: translateX(26px);
        }

        input:disabled + .cookies-modal__settings__item__toggle--slider {
          cursor: not-allowed;
          background-color: $gray-d9;
        }

        &--on {
          position: relative;

          &::before {
            content: "";
            margin: 0 auto;
            position: absolute;
            transform: rotate(45deg);
            border: solid $white-ec;
            border-width: 0 2px 2px 0;
            display: inline-block;
            left: .75em;
            padding: .1em .1em .45em;
            top: 6px;
            font-size: 16px;
          }
        }

        &--off {
          position: relative;
          right: -90%;

          @supports (-webkit-backdrop-filter: blur(0)) {
            right: -45%;
          }

          &::before, &::after {
            background: $white-ec;
            content: " ";
            display: block;
            margin: 0 auto;
            position: absolute;
            right: .7em;
            top: .42em;
            transform-origin: center;
            width: 1.4px;
            height: 12px;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &__footer {
      width: 100%;
      max-width: 758px;
      height: 87px;
      padding: 20px 35px;
      display: block;
      margin: 0 auto;

      > .cookies-modal__button {
        width: 100%;
        height: 100%;
      }
    }

    @media (min-width: 512px) {
      background-color: rgba(0, 0, 0, 0.5);

      &__content {
        height: calc(100% - 2.5em);
        max-height: 530px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &__footer {
        max-width: 300px;
        margin: 0;
        padding: 20px 30px;
      }
    }
  }

  &__remove-border-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__expand {
    .cookies-modal__settings__item__name::before {
      top: 54%;
      transform: translateY(-50%) rotate(224deg);
      left: 17px;
    }
  }
}
