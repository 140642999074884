/*
 *  Define scss variables here.
*/
.power-table-block {
  width: 95%;
  overflow: hidden;
  max-height: 100vh;
  -webkit-transition: max-height 300ms ease-in-out;
  transition: max-height 300ms ease-in-out;
  border: 1px solid #eee;
}
.power-table-block .power-item > div {
  line-height: 25px;
  letter-spacing: 0.5px;
}
.power-table-block .power-item_msite > div {
  line-height: 20px;
  letter-spacing: 0.1px;
}
.power-table-block .display-grid {
  grid-template-columns: 100%;
}
.power-table-block .name-strip {
  background: #eee;
  margin-left: -15px;
  margin-right: -15px;
}
.power-table-block.shadow:not(:empty) {
  -webkit-box-shadow: 0px 1px 2px 1px #efefef;
          box-shadow: 0px 1px 2px 1px #efefef;
}
.power-table-block.desktop {
  border: 1px solid #ddd;
  border-top: none;
  width: calc(100% - 30px);
}
.power-table-block .accordion-group-header .power-accordion-title:before {
  content: "Show Power";
  color: #00bac6;
  margin-right: 5px;
  font-size: 12px;
}
.power-table-block .accordion-group-header .power-accordion-title .icon {
  line-height: 1;
}
.power-table-block .accordion-group-header .power-accordion-title .icon:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.power-table-block .accordion-group-header.expanded .power-accordion-title:before {
  content: "Hide Power";
}
.power-table-block .accordion-group-header.expanded .power-accordion-title .icon:before {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.padding_0_15 {
  padding: 0 15px;
}
.padding_0_7 {
  padding: 0 7px;
}
.mr-l15 {
  margin-left: 15px;
}
.border-top_grey {
  border-top: 1px solid lightgrey;
}
.new-style {
  font-family: "LenskartSans-Regular";
  font-weight: 400;
  color: #000042;
  margin-left: 0 !important;
}
.table-new-style-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.table-new-style-container .table-new-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  padding: 5px 10px;
}
.table-new-style-container .col0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 15px;
}
.table-new-style-container .col0 .left-text,
.table-new-style-container .col0 .right-text {
  width: 10% !important;
}
.table-new-style-container .new-col1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: visible;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  gap: 5px;
}
.table-new-style-container .new-col1 .col1-sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.new-header-container {
  margin: 10px 0;
}
.new-header-container .new-header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000042;
  font-style: normal;
}
.new-header-container .new-header-content .header-text-primary {
  font-family: "LenskartSans-Regular";
  font-weight: 400;
}
.new-header-container .new-header-content .header-text-secondary {
  font-family: "LenskartSans-Bold";
  font-weight: 700;
  text-decoration: underline;
}
.new-header-container .new-header-content .header-text-fix {
  font-family: "LenskartSans-Regular";
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #333368;
}
.new-header-container .new-header-content .icon {
  border: solid #000042;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}
.new-header-container .new-header-content .arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.new-header-container .new-header-content .arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.cartView-body-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e2e2ee;
  border-radius: 8px;
  font-family: "LenskartSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000042;
}
.cartView-body-container .cart-view-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  background: #f5f5ff;
  padding: 12px;
  text-transform: uppercase;
}
.cartView-body-container .cart-view-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 12px;
}
.cartView-body-container .cart-view-table .table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cartView-body-container .cart-view-table .table-row .table-row-item {
  min-width: 30px;
  text-align: center;
}
