.yt-modal{
  &__overlay{
    position: fixed;
    inset: 0;
    background-color: rgba(#1e1e1e, 0.9);
    padding: 5%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__content{
    position: relative;
    aspect-ratio: 16/9;
  }
  &__close{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(#1e1e1e,.6);
    color: #ccc;
    border: none;
    outline: none;
    box-shadow: none;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
  &__iframe{
    width: 100%;
    height: 100%;
    border: none;
  }
}
