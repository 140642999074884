$font-founders: 'Founders Grotesk', sans-serif;

.header-gift-cards-products {
  padding: 40px 0 50px 0;

  &__title, &__desc {
    font-family: $font-founders;
    color: black;
    letter-spacing: 1px;
  }

  &__title {
    font-size: 20px;
    font-weight: normal !important;
    line-height: 1.5;
  }

  &__desc {
    margin: 0;
    font-size: 15px;
    font-weight: normal !important;
    line-height: 2;
  }

  @media (min-width: 768px) {
    &__title {
      font-size: 38px;
    }
  }

  @media (min-width: 1024px) {
    padding-top: 80px;
  }
}
