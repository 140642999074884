.odslv-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
}

.odslv-modal .select-lens-page-title {
  color: #444;
  font-size: 24px;
  letter-spacing: -0.48px;
  font-family: 'Founders Grotesk', sans-serif;
  margin: 8px 0 40px 0;
}

.odslv-modal__right {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  top: 0;
}

.odslv-modal__right__main {
  height: 100%;
  position: relative;
  padding-bottom: 120px;
  overflow-y: auto;
}

.odslv-modal__right__main__content {
  padding: 61px 100px 0;
}

@media (max-width: 768px) {
  .odslv-modal__right__main__content {
    padding: 24px 16px;
  }
}

.odslv-modal__right__footer {
  width: 100%;
  bottom: 0;
  position: fixed;
}

.odslv-modal__right__footer--content {
  font-family: 'NeuzeitGroLig', sans-serif;
  color: #444444;
  opacity: 0.5;
  padding: 0px 30px;
  font-size: 18px;
  margin-bottom: 20px;
}

.odslv-modal__right__footer--action {
  border-radius: 10px 10px 0 0;
  background: #FFF;
  -webkit-box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, .01), 0px -12px 7px 0px rgba(0, 0, 0, .02), 0px -5px 5px 0px rgba(0, 0, 0, .03), 0px -1px 3px 0px rgba(0, 0, 0, .04);
          box-shadow: 0px -32px 9px 0px rgba(0, 0, 0, 0), 0px -21px 8px 0px rgba(0, 0, 0, .01), 0px -12px 7px 0px rgba(0, 0, 0, .02), 0px -5px 5px 0px rgba(0, 0, 0, .03), 0px -1px 3px 0px rgba(0, 0, 0, .04);
  padding: 24px 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 768px) {
  .odslv-modal__right__footer--action {
    padding: 16px;
  }
}

.odslv-modal__right__footer--action-info {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
          flex: 1 0 0;
}

.odslv-modal__right__footer--action-info div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 4px;
}

.odslv-modal__right__footer--action-info__price {
  color: #444;
  font-size: 24px;
  letter-spacing: -0.48px;
}

@media (max-width: 768px) {
  .odslv-modal__right__footer--action-info__price {
    -webkit-font-feature-settings: 'clig' off, 'liga' off;
            font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
  }
}

.odslv-modal__right__footer--action-info__description {
  color: #444;
  -webkit-font-feature-settings: 'clig' off, 'liga' off;
          font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .odslv-modal__right__footer--action-info__description {
    font-size: 12px;
  }
}

.odslv-modal__right__footer--action button {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
          flex: 1 0 0;
}

.od-fram-only-description {
  font-family: 'NeuzeitGroReg', sans-serif;
  font-size: 16px;
  margin-top: 8px;
  padding: 16px;
}

.od-fram-only-description h1 {
  color: #000;
  margin-bottom: 32px;
  font-size: 26px;
  margin-top: 0px;
  font-family: 'Founders Grotesk', sans-serif;
}

.od-fram-only-description ul {
  color: #444;
  opacity: 0.5;
  padding-left: 16px;
}

.od-fram-only-description ul li {
  list-style: disc;
}

.od-fram-only-description ul li:not(:last-child) {
  margin-bottom: 8px;
}
