@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap");
@font-face {
  font-family: "Corporate Logo Rounded";
  src: url("https://static.lenskart.com/media/owndays/fonts/corporate-logo-rounded/Corporate-Logo-Rounded.ttf") format("truetype"), url("https://static.lenskart.com/media/owndays/fonts/corporate-logo-rounded/Corporate-Logo-Rounded.woff2") format("woff2"), url("https://static.lenskart.com/media/owndays/fonts/corporate-logo-rounded/Corporate-Logo-Rounded.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Katakanaboy";
  src: url("https://static.lenskart.com/media/owndays/fonts/katakanaboy/Katakanaboy-R.ttf") format("truetype"), url("https://static.lenskart.com/media/owndays/fonts/katakanaboy/Katakanaboy-R.woff2") format("woff2"), url("https://static.lenskart.com/media/owndays/fonts/katakanaboy/Katakanaboy-R.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Eveleth Clean";
  src: url("https://static.lenskart.com/media/owndays/fonts/eveleth-clean/EvelethCleanRegular.woff2") format("woff2"), url("https://static.lenskart.com/media/owndays/fonts/eveleth-clean/EvelethCleanRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.base-kuromi-collection__container {
  font: 700 15px/1.7 "Corporate Logo Rounded";
  max-width: 1264px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #2b2045;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .base-kuromi-collection__container {
    padding-top: 120px;
    padding-bottom: 75px;
  }
}
@media (min-width: 1024px) {
  .base-kuromi-collection__container {
    max-width: 1224px;
    padding-left: 73px;
    padding-right: 73px;
  }
}
.base-kuromi-collection__hide-pc {
  display: block !important;
}
@media (min-width: 768px) {
  .base-kuromi-collection__hide-pc {
    display: none !important;
  }
}
.base-kuromi-collection__hide-sp {
  display: none !important;
}
@media (min-width: 768px) {
  .base-kuromi-collection__hide-sp {
    display: block !important;
  }
}
.base-kuromi-collection__item-corner-top::before, .base-kuromi-collection__item-corner-top::after, .base-kuromi-collection__item-corner-bottom::before, .base-kuromi-collection__item-corner-bottom::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background-color: #2b2045;
  width: 10px;
  height: 10px;
}
.base-kuromi-collection__item-corner-top::before, .base-kuromi-collection__item-corner-bottom::before {
  left: 14px;
}
.base-kuromi-collection__item-corner-top::after, .base-kuromi-collection__item-corner-bottom::after {
  right: 14px;
}
.base-kuromi-collection__item-corner-top::before, .base-kuromi-collection__item-corner-top::after {
  top: 14px;
}
.base-kuromi-collection__item-corner-bottom::before, .base-kuromi-collection__item-corner-bottom::after {
  bottom: 14px;
}
.base-kuromi-collection__title {
  font: 400 42px/1 "Eveleth Clean";
  letter-spacing: 0.03em;
  text-align: center;
  color: white;
  -webkit-text-stroke: 2px #2b2045;
  padding-bottom: 0.6em;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/title-bg.svg") bottom center/58px auto no-repeat;
  position: relative;
  z-index: 9;
}
@media (min-width: 768px) {
  .base-kuromi-collection__title {
    -webkit-text-stroke-width: 3px;
  }
}
@media (min-width: 1024px) {
  .base-kuromi-collection__title {
    font-size: 80px;
    background-size: 92px auto;
  }
}
.base-kuromi-collection__center {
  text-align: center;
}
.base-kuromi-collection__color {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 20px 0 0 0;
  list-style-type: none;
  padding: 0;
}
.base-kuromi-collection__color__item--black, .base-kuromi-collection__color__item--pink-e8, .base-kuromi-collection__color__item--purple-cd, .base-kuromi-collection__color__item--white-e3 {
  width: 29px;
  height: 29px;
  background-color: white;
  position: relative;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin: 0 4px;
  cursor: pointer;
}
.base-kuromi-collection__color__item--black::before, .base-kuromi-collection__color__item--pink-e8::before, .base-kuromi-collection__color__item--purple-cd::before, .base-kuromi-collection__color__item--white-e3::before {
  content: '';
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}
.base-kuromi-collection__color__item--black::before {
  background-color: black;
}
.base-kuromi-collection__color__item--pink-e8::before {
  background-color: #e8c4e4;
}
.base-kuromi-collection__color__item--purple-cd::before {
  background-color: #cdb4d8;
}
.base-kuromi-collection__color__item--white-e3::before {
  background-color: #e3e3e3;
}
@media (min-width: 768px) {
  .base-kuromi-collection__color {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: auto;
  }
  .base-kuromi-collection__color::before {
    content: attr(data-title);
    letter-spacing: 0.06em;
    font: 700 22px/1 "Dosis", sans-serif;
    margin-right: 28px;
  }
  .base-kuromi-collection__color__item--black, .base-kuromi-collection__color__item--pink-e8, .base-kuromi-collection__color__item--purple-cd, .base-kuromi-collection__color__item--white-e3 {
    width: 42px;
    height: 42px;
  }
  .base-kuromi-collection__color__item--black::before, .base-kuromi-collection__color__item--pink-e8::before, .base-kuromi-collection__color__item--purple-cd::before, .base-kuromi-collection__color__item--white-e3::before {
    width: 24px;
    height: 24px;
  }
}
.base-kuromi-collection__button {
  font: 700 15px/1 "Corporate Logo Rounded";
  color: #2b2045;
  position: relative;
  padding: 0 14px 0 30px;
  margin: 0 auto 8px;
  height: 66px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 3;
}
.base-kuromi-collection__button[disabled] {
  pointer-events: none;
}
.base-kuromi-collection__button[disabled]::before, .base-kuromi-collection__button[disabled]::after {
  border-color: #e3e3e3;
}
.base-kuromi-collection__button[disabled] span {
  color: #e3e3e3;
  background: none;
}
.base-kuromi-collection__button::before, .base-kuromi-collection__button::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid currentColor;
  border-radius: 5em;
  background-color: white;
}
.base-kuromi-collection__button::before {
  z-index: -1;
}
.base-kuromi-collection__button::after {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: -2;
  -webkit-transform: translate(8px, 8px);
          transform: translate(8px, 8px);
}
.base-kuromi-collection__button span {
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  position: relative;
  background: url("https://static.lenskart.com/media/owndays/img/kuromi-collection/arrow-right.svg") center right/29px 29px no-repeat;
}
@media (min-width: 768px) {
  .base-kuromi-collection__button {
    height: 62px;
    max-width: 281px;
  }
  .base-kuromi-collection__button:hover {
    opacity: 1;
    color: currentColor;
    -webkit-transform: translate(3px, 3px);
            transform: translate(3px, 3px);
  }
  .base-kuromi-collection__button:hover::after {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .base-kuromi-collection__button:hover, .base-kuromi-collection__button:active, .base-kuromi-collection__button:visited, .base-kuromi-collection__button:focus {
    color: currentColor;
    text-decoration: none;
  }
}
.base-kuromi-collection__button-group {
  margin-top: 70px;
  display: grid;
  row-gap: 15px;
}
@media (min-width: 768px) {
  .base-kuromi-collection__button-group {
    max-width: 747px;
    margin-left: auto;
    margin-right: auto;
  }
}
.base-kuromi-collection__scroll-x-hidden {
  overflow-x: hidden;
}
