.owndays-btn {
  display: block;
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  font-size: 14px;
  line-height: 120%;
}

.owndays-btn:focus, .owndays-btn:hover {
  text-decoration: none;
}

.owndays-btn--white {
  background: #FFFFFF;
  border-color: #555555;
  color: #555555;
}

.owndays-btn--white:active, .owndays-btn--white:hover {
  color: #ffffff;
  background-color: #555555;
}

.owndays-btn--white-no-hover:hover {
  background-color: #FFFFFF;
  color: #555555;
}

.owndays-btn--black {
  background: #000;
  backdrop-filter: blur(7px);
  color: #FFF;
}

.owndays-btn--black:active, .owndays-btn--black:hover {
  color: #000;
  background-color: #FFF;
}

.owndays-btn--black-no-hover:hover {
  background-color: #000;
  color: #FFF;
}

.owndays-btn--white-secondary {
  background: #FFFFFF;
  color: #000000;
  border: none;
  opacity: 0.7;
}

.owndays-btn--white-secondary:active, .owndays-btn--white-secondary:hover {
  color: #ffffff;
  background-color: #555555;
}

.owndays-btn--white-secondary-no-hover:hover {
  background-color: #FFFFFF;
  color: #000000;
}

.owndays-btn--white-ternary {
  background: #FFFFFF;
  border: 1px solid #555555;
}

.owndays-btn--white-ternary:active, .owndays-btn--white-ternary:hover {
  color: #ffffff;
  background-color: #555555;
}

.owndays-btn--white-ternary-no-hover:hover {
  background-color: #FFFFFF;
  color: #000000;
}

.owndays-btn--white-4 {
  background: #FFFFFF;
  color: #000000;
  border: 1px solid #000;
  -webkit-box-shadow: 0px 4px 9px 0px #000;
          box-shadow: 0px 4px 9px 0px #000;
}

.owndays-btn--white-4:active, .owndays-btn--white-4:hover {
  color: #ffffff;
  background-color: #555555;
}

.owndays-btn--white-4-no-hover:hover {
  background-color: #FFFFFF;
  color: #000000;
}

.owndays-btn--white-5 {
  background: #FFFFFF;
  color: #000000;
  border: 1px solid #000;
}

.owndays-btn--white-5:active, .owndays-btn--white-5:hover {
  color: #ffffff;
  background-color: #000;
}

.owndays-btn--white-5-no-hover:hover {
  background-color: #FFFFFF;
  color: #000000;
}

.owndays-btn--white-6 {
  background: #FFFFFF;
  color: #444;
  border: 1px solid #546073;
}

.owndays-btn--white-6:active, .owndays-btn--white-6:hover {
  color: #ffffff;
  background-color: #546073;
}

.owndays-btn--white-6-no-hover:hover {
  background-color: #FFFFFF;
  color: #444;
}

.owndays-btn--white-7 {
  background-color: #FFFFFF;
  color: #444;
  border: 1px solid #444;
}

.owndays-btn--white-8 {
  background-color: #FFFFFF;
  color: #546073;
  border: none;
}

.owndays-btn--transparent-light {
  background: transparent;
  border-color: #555555;
  color: #555555;
}

.owndays-btn--transparent-light:active, .owndays-btn--transparent-light:hover {
  color: #ffffff;
  background-color: #555555;
}

.owndays-btn--transparent-light-no-hover:hover {
  background-color: transparent;
  color: #555555;
}

.owndays-btn--grey {
  background: #546073;
  border-color: #555555;
  color: #ffffff;
}

.owndays-btn--grey:active, .owndays-btn--grey:hover {
  color: #fff;
  background-color: #546073;
}

.owndays-btn--borderless {
  border-width: 0;
}

.owndays-btn--grey-no-hover:hover {
  background-color: #546073;
  color: #ffffff;
}

.owndays-btn--grey-secondary {
  background: #FAFAFA;
  color: #546073;
}

.owndays-btn--grey-secondary:active, .owndays-btn--grey-secondary:hover {
  color: #FAFAFA;
  background-color: #546073;
}

.owndays-btn--grey-secondary-no-hover:hover {
  background-color: #FFFFFF;
  color: #555555;
}

.owndays-btn--grey-tertiary {
  background: rgba(84, 96, 115, 0.10196);
  color: #546073;
}

.owndays-btn--grey-tertiary:active, .owndays-btn--grey-tertiary:hover {
  color: #fff;
  background-color: #546073;
}

.owndays-btn--grey-tertiary-no-hover:hover {
  background-color: #FFFFFF;
  color: #555555;
}

.owndays-btn--oval-white {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: solid #fff 1px;
  border-radius: 9999px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  color: #000;
  background-color: #fff;
  -webkit-transition: border-color 0.15s ease-out, background-color 0.15s ease-out;
  transition: border-color 0.15s ease-out, background-color 0.15s ease-out;
}

.owndays-btn--oval-white:hover {
  border-color: rgba(255, 255, 255, .75);
  background-color: rgba(255, 255, 255, .75);
}

.owndays-btn--oval-black, .owndays-btn--oval-transparent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: solid #fff 1px;
  border-radius: 9999px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  background-color: #000;
  -webkit-transition: border-color 0.15s ease-out, background-color 0.15s ease-out;
  transition: border-color 0.15s ease-out, background-color 0.15s ease-out;
}

.owndays-btn--oval-black:hover, .owndays-btn--oval-transparent:hover {
  border-color: rgba(255, 255, 255, .75);
  background-color: rgba(255, 255, 255, .75);
}

.owndays-btn--oval-transparent {
  border: solid #fff 2px;
  color: #fff;
  background-color: transparent;
  -webkit-transition: border-color 0.15s ease-out, color 0.15s ease-out;
  transition: border-color 0.15s ease-out, color 0.15s ease-out;
}

.owndays-btn--oval-transparent:hover {
  border-color: #fff;
  color: #fff;
}

.owndays-btn--oval-grey {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: solid #546073 1px;
  border-radius: 9999px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  color: #fff;
  background-color: #546073;
  -webkit-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
}

.owndays-btn--oval-grey:hover {
  border-color: rgba(84, 96, 115, .75);
  background-color: rgba(84, 96, 115, .75);
}

.owndays-btn--secondary {
  font-family: 'Founders Grotesk';
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  padding: 16px 40px 17px;
  border-radius: 10rem;
  border-color: #546073;
  color: #546073;
  background-color: #ffffff;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.owndays-btn--secondary:hover {
  background-color: #546073;
  color: #fff;
}

.owndays-btn:disabled {
  background-color: #C5C5C5;
  border: none;
}

.owndays-btn:disabled:hover {
  background: #546073;
  color: #fff;
  cursor: not-allowed;
}

.owndays-btn-font--mobile {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
