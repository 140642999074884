.htu {
  color: black;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.8px;
  width: 100%;
  overflow-x: hidden;
}

.htu img {
  display: block;
  width: 100%;
}

.htu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.htu a,
.htu a:hover {
  color: inherit;
  text-decoration: none;
}

.htu__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1192px;
}

.htu__container--lg {
  max-width: 1340px;
}

.htu__container--sm {
  max-width: 940px;
}

.htu__header {
  background-color: #f4f4f4;
  padding-block: 80px;
}

.htu__header h1 {
  margin: 0 0 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.6px;
  padding-bottom: 16px;
  position: relative;
}

.htu__header h1::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 60px;
  height: 4px;
}

.htu__header p {
  margin: 0 0 48px;
}

.htu__header ul {
  display: grid;
  gap: 8px;
}

.htu__header ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: white;
  padding: 16px;
  position: relative;
  cursor: pointer;
}

.htu__header ul li h3 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 8px;
}

.htu__header ul li h3 strong {
  font-family: inherit;
  font-size: 20px;
}

.htu__header ul li img {
  max-width: 150px;
  margin: 16px auto;
}

.htu__header ul li svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.htu__steps {
  padding-bottom: 136px;
}

.htu__steps-sec {
  padding-top: 136px;
}

.htu__steps-sec h2 {
  margin: 0 0 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}

.htu__steps-sec h2 strong {
  font-family: inherit;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}

.htu__steps-sec ul {
  display: grid;
  gap: 16px;
}

.htu__steps-sec ul li {
  border: 1px solid #e3e3e3;
}

.htu__steps-sec ul li img {
  max-width: 150px;
  margin-inline: auto;
}

.htu__steps-sec ul li div {
  padding: 16px 32px;
}

.htu__steps-sec ul li div h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 8px;
}

.htu__steps-sec ul li div h3 strong {
  font-family: inherit;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

.htu--clear-contactlens .htu__header h1::before {
  background-color: #59b2e7;
}

.htu--clear-contactlens .htu__header ul li svg {
  fill: #59b2e7;
}

.htu--clear-contactlens .htu__steps-sec h2 strong, .htu--clear-contactlens .htu__steps-sec ul li div h3 strong {
  color: #59b2e7;
}

.htu--color-contactlens .htu__header h1::before {
  background-color: #71475e;
}

.htu--color-contactlens .htu__header ul li svg {
  fill: #71475e;
}

.htu--color-contactlens .htu__steps-sec h2 strong, .htu--color-contactlens .htu__steps-sec ul li div h3 strong {
  color: #71475e;
}

@media (max-width: 767px) {
  .htu__header ul li a img {
    display: none;
  }
  .htu__steps-sec ul li img {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .htu__header p {
    text-align: center;
  }
  .htu__header ul {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  .htu__header ul li {
    padding-bottom: 46px;
  }
  .htu__header ul li h3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 24px;
    border-bottom: 1px dashed #e3e3e3;
  }
  .htu__header ul li svg {
    top: auto;
    left: 50%;
    right: auto;
    bottom: 16px;
    -webkit-transform: translate(-50%, 0%) rotate(90deg);
            transform: translate(-50%, 0%) rotate(90deg);
  }
  .htu__steps-sec ul li {
    display: grid;
    grid-template-columns: 150px 1fr;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .htu__steps-sec ul li img {
    padding: 16px 32px;
    margin-block: 16px;
    border-right: 1px dashed #e3e3e3;
  }
}

@media (min-width: 1024px) {
  .htu__header ul {
    grid-template-columns: repeat(4, 1fr);
  }
}
