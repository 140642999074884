.cart-popup {
    position: fixed;
    right: 0px;
    top: 60px;
    .modal-body {
        position: relative;
        padding: 0px !important;
    }
    .modal-content {
        border-radius: 0px !important;
        border: none;
    }
    &_slide {
        background: #fff;
        display: block;
        width: 778px;
        z-index: 11;
        box-shadow: -3px 1px 8px rgba(0, 0, 0, 0.15);
        border-left: 6px solid #4b4c51;
        border-bottom: 6px solid #4b4c51;
        position: fixed;
        left: 100%;
        margin-left: -775px;
        min-height: 300px;
        .icon-cart-close {
            display: block;
        }
        .loader {
            position: absolute;
        }
        &_empty {
            text-align: center;
            &_message {
                font-size: 16px;
                text-transform: uppercase;
                color: rgb(240, 67, 67);
                margin-bottom: 0px;
                margin-top: 80px;
                display: block;
            }
            &_links {
                margin-top: 35px;
                width: 100%;
                [class*="span"]:first-child {
                    margin-left: 0;
                    text-align: right;
                }
                .span6 {
                    width: 49.33875890132248%;
                }
                [class*="span"] {
                    display: block;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    float: left;
                    margin-left: 1.3224821973550356%;
                }
                .span2 {
                    width: 15.564598168870804%;
                    font-family: "SourceSansProSemibold", sans-serif;
                    font-size: 18px;
                }
                .span3 {
                    width: 24.008138351983725%;
                    font-size: 18px;
                }
                .color-yellow {
                    border-right: 1px solid #e8792a;
                    margin-bottom: 40px;
                    a {
                        color: #e8792a;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
                .color-yellow:last-child {
                    border-right: 0px solid #e8792a;
                }
            }
            &_footer {
                font-size: 14px;
                line-height: 20px;
                color: #fff;
                text-align: center;
                overflow: hidden;
                display: block;
                .icon-chkout-arw {
                    width: 20px;
                    height: 20px;
                    background-position: -1150px -100px;
                    vertical-align: -4px;
                    margin-left: 3px;
                }
                > .title {
                    display: block;
                    padding: 8px 0;
                    color: #fff;
                    text-decoration: none;
                    margin: 13px auto;
                    background: #e9782a;
                    width: 258px;
                    font-size: 18px;
                }
            }
        }
        &_header {
            background: #4b4c51;
            color: #fff;
            padding: 6px 10px;
            overflow: hidden;
            display: block;
            .icon-cart-close {
                float: right;
                width: 14px;
                height: 14px;
                background-position: -950px -150px;
                margin: 9px 24px 0 20px;
                cursor: pointer;
            }
            > .title {
                margin: 11px 0 5px 0 !important;
                font-size: 16px;
                padding-left: 0;
                letter-spacing: 1.3px;
                text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.modal.fade:not(.in) .cart-popup {
    -webkit-transform: translate3d(25%, 0, 0) !important;
    transform: translate3d(25%, 0, 0) !important;
}

i {
    background: url(//static.lenskart.com/skin/frontend/base/default/img/sprite-v2.png) no-repeat 0 0;
    display: inline-block;
    vertical-align: baseline;
}

.cart-content {
    overflow: hidden;
    display: block;
}

.cart-content.crt-prd-block {
    padding-right: 3px;
}

.chkout-cart-region {
    max-height: 274px;
}

.row-fluid {
    width: 100%;
}

.row-fluid [class*="span"]:first-child {
    margin-left: 0;
    text-align: right;
}

.row-fluid .span6 {
    width: 49.33875890132248%;
}

.row-fluid [class*="span"] {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 1.3224821973550356%;
}

.row-fluid .span2 {
    width: 15.564598168870804%;
}

.row-fluid .span3 {
    width: 24.008138351983725%;
}

.lns-detail-tab {
    margin: 0 auto 10px;
    width: 227px;
}

.crt-lns-prce {
    float: left;
    width: 246px;
}

.product-detl .qty .qty-value-txt {
    overflow: hidden;
    display: block;
}

.discount-cart {
    position: relative;
}

#discount-cart {
    position: relative;
}

#discount-cart:hover .discount-cont {
    display: block;
    background: #000;
    padding: 10px;
    width: 200px;
    height: auto;
    bottom: 26px;
    position: absolute;
    margin-left: -90px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.7px;
    font-style: normal;
}

.pric-hedng span .mob-icon-question-mark {
    display: inline-block;
    width: 22px;
    height: 19px;
    margin: 0px;
}

.mob-icon-question-mark {
    width: 22px;
    background-position: -187px -90px;
    height: 18px;
    margin-left: 1px;
    vertical-align: -5px;
}

.discount-cont {
    display: none;
}

.discount-arrow {
    background: url("//www.lenskart.com/skin/frontend/base/default/img/tipsy.gif") no-repeat;
    width: 9px;
    height: 9px;
    position: absolute;
    bottom: -5px;
    left: 50%;
}

.subtotal-value .pls-crt-sign {
    margin: 10px 0 0 0;
}

.loyalty-offer {
    background: #777;
    color: #fff;
    text-align: center;
    padding: 1px 10px;
    font-size: 11px;
}

ul.bogo-offers li.bogo-url a {
    text-decoration: none;
    display: block;
    color: white;
}

ul.bogo-offers li.bogo-url span.bogo-add {
    font-size: 65px;
    line-height: normal;
    margin: 0;
    font-weight: bold;
}

ul.bogo-offers li.bogo-url span {
    font-size: 20px;
    letter-spacing: 1px;
    margin: 3px 0 0;
    text-transform: uppercase;
    display: block;
}

ul.bogo-offers {
    background: #fdfdfd;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #c3c3c3;
    position: relative;
}

ul.bogo-offers li.bogo-fname {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #343434;
}

ul.bogo-offers li {
    display: block;
    padding: 5px 0;
}

ul.bogo-offers li.bogo-fcart {
    font-size: 16px;
    color: #ee801c;
    letter-spacing: 1px;
}

ul.bogo-offers li.bogo-url {
    display: none;
}

ul.bogo-offers li.loyalty-bogo-url {
    display: none;
}

ul.bogo-offers:hover li.bogo-url {
    color: #fff;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    background: #57ba90;
}

ul.bogo-offers:hover li.loyalty-bogo-url {
    display: block;
    position: absolute;
    top: 0;
    padding: 0px;
    height: 100%;
    cursor: pointer;
}

ul.bogo-offers.loyalty-enabled {
    float: left;
    width: 624px;
    min-height: 141px;
}

li.loyalty-bogo-url .loyalty-bogo-hover {
    color: #fff;
    background: #57ba90;
    height: 100%;
    display: block;
    &_info {
        font-size: 20px;
        padding: 42px 0;
        text-decoration: none;
        color: #fff;
    }
    &_arrow {
        font-size: 30px;
        padding: 48px 0;
    }
}

.bogo-url span.bogo-add {
    font-size: 70px;
    line-height: 30px;
    margin: 0;
    font-weight: bold;
}

.loyalty-know-more {
    padding: 0px;
    cursor: pointer;
    float: left;
}

.loyalty-know-more-img {
    height: 141px;
    width: 143px;
}

.loyalty-content:not(.closed) {
    border-bottom: 1px solid #ccc;
}

.animate-slide-down {
    overflow-y: hidden;
    max-height: auto;
    transition-property: all;
    transition-duration: 0.4s;
    transition-delay: 0.06s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.animate-slide-down.closed {
    max-height: 0px;
}

.close-loyalty {
    position: absolute;
    right: 0px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.btn-holders {
    padding: 10px 0;
}

.add-membership .loyalty-btn {
    padding: 8px 0px;
    background: black;
    color: rgb(222, 203, 55);
    display: inline-block;
    overflow: hidden;
    width: 262px;
    border-radius: 2px;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
    font-size: 18px;
    float: none;
    margin: 0 0 0 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    font-family: FuturaStd-Heavy, sans-serif;
}

.skip-membership {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
}

.upy-block {
    font-size: 23px;
    > .span6 {
        padding: 5.5% 0 15px;
    }
}

.finl-cart-conti {
    float: left;
    border-left: 1px dashed #ccc;
    padding: 12px 0 4px;
    min-height: 69px;
}

.pyment-crt-blck {
    border-bottom: 1px solid #ccc;
}

.payment-crt-tb td {
    padding: 5px 3px;
    text-align: center;
    vertical-align: top;
    line-height: 26px;
    color: #777;
}

.payment-crt-tb td i.sgnminus {
    background-position: -1047px -150px;
}

.payment-crt-tb td i.sgnplus {
    background-position: -1100px -150px;
}
