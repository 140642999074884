.od-shimmer-bg {
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
          animation-name: shimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #ddd;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(119, 119, 119, 0.20784)), color-stop(18%, #f0f0f0), color-stop(33%, rgba(119, 119, 119, 0.20784)));
  background: linear-gradient(to right, rgba(119, 119, 119, 0.20784) 8%, #f0f0f0 18%, rgba(119, 119, 119, 0.20784) 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
