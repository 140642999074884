@import "../../theme/mobile/variables";

.power-table-block {
    width: 95%;
    overflow: hidden;
    max-height: 100vh;
    transition: max-height 300ms ease-in-out;
    border: 1px solid #eee;
    .power-item {
        & > div {
            line-height: 25px;
            letter-spacing: 0.5px;
        }
    }
    .power-item_msite {
        & > div {
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    }
    .display-grid {
        grid-template-columns: 100%;
    }
    .name-strip {
        background: #eee;
        margin-left: -15px;
        margin-right: -15px;
    }
    &.shadow:not(:empty) {
        box-shadow: 0px 1px 2px 1px #efefef;
    }
    &.desktop {
        border: 1px solid #ddd;
        border-top: none;
        width: calc(100% - 30px);
    }
    .accordion-group-header {
        .power-accordion-title {
            &:before {
                content: "Show Power";
                color: map-get($colorpallete, color_link_blue);
                margin-right: 5px;
                font-size: 12px;
            }
            .icon {
                line-height: 1;
                &:before {
                    transition: all 0.3s ease-in-out;
                }
            }
        }
        &.expanded {
            .power-accordion-title {
                &:before {
                    content: "Hide Power";
                }
                .icon:before {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
.padding_0_15 {
    padding: 0 15px;
}
.padding_0_7 {
    padding: 0 7px;
}
.mr-l15 {
    margin-left: 15px;
}
.border-top_grey {
    border-top: 1px solid lightgrey;
}
.new-style {
    font-family: "LenskartSans-Regular";
    font-weight: 400;
    color: #000042;
    margin-left: 0 !important;
}
.table-new-style-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .table-new-style {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        padding: 5px 10px;
    }
    .col0 {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: flex-end;
        margin-top: 15px;
        .left-text,
        .right-text {
            width: 10% !important;
        }
    }
    .new-col1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: visible;
        flex: 2;
        gap: 5px;
        .col1-sub {
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;
        }
    }
}

.new-header-container {
    margin: 10px 0;
    .new-header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: #000042;
        font-style: normal;
        .header-text-primary {
            font-family: "LenskartSans-Regular";
            font-weight: 400;
        }
        .header-text-secondary {
            font-family: "LenskartSans-Bold";
            font-weight: 700;
            text-decoration: underline;
        }
        .header-text-fix {
            font-family: "LenskartSans-Regular";
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #333368;
        }
        .icon {
            border: solid #000042;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            margin-left: 5px;
        }
        .arrow-down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
        .arrow-up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }
    }
}
.cartView-body-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #e2e2ee;
    border-radius: 8px;

    font-family: "LenskartSans-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000042;
    
    .cart-view-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: #f5f5ff;
        padding: 12px;
        text-transform: uppercase;
    }
    .cart-view-table {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        padding: 12px;
        .table-row {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            .table-row-item {
                min-width: 30px;
                text-align: center;
            }
        }
    }
}
