.od-membership-function-container {
  margin-top: 40px;
  padding: 0 200px;
}

.od-membership-function-container .od-membership-function {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 35px;
}

.od-membership-function-container .od-membership-function__item {
  width: 380px;
  min-height: 628px;
  background-color: #FAFAFA;
  margin-bottom: 5px;
  padding: 40px;
  color: #000000;
  text-align: center;
}

.od-membership-function-container .od-membership-function__item__title {
  margin-top: 41px;
  font-size: 22px;
  line-height: 26px;
}

.od-membership-function-container .od-membership-function__item__description {
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .od-membership-function-container {
    padding: 0 20px;
  }
  .od-membership-function-container .od-membership-function {
    gap: 20px;
  }
  .od-membership-function-container .od-membership-function__item {
    width: 100%;
    padding: 20px 20px 30px 20px;
    min-height: auto;
  }
  .od-membership-function-container .od-membership-function__item__title {
    margin-top: 20px;
    font-size: 22px;
    line-height: 26px;
  }
}
