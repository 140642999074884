@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;900&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP:wght@100;200;300;400;500;600;700&family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');
@import url("https://use.typekit.net/pcp6abb.css"); // din-2014
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
    font-family: 'HiraginoMinchoPro';
    src: url('https://static.lenskart.com/media/owndays/desktop/fonts/Hiragino-Mincho-ProN-W3.otf') format('otf');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'NeuzeitGroBold';
    src: url('https://static.lenskart.com/media/owndays/desktop/fonts/NeuzeitGro-Bol.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'NeuzeitGroLig';
    src: url('https://static.lenskart.com/media/owndays/desktop/fonts/NeuzeitGro-Lig/NeuzeitGro-Lig.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'NeuzeitGroReg';
    src: url('https://static.lenskart.com/media/owndays/desktop/fonts/NeuzeitGro-Reg/NeuzeitGro-Reg.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'SourceSansProRegular';
    src: url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Regular-webfont.eot');
    src: url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Regular-webfont.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Regular-webfont.ttf') format('truetype'), url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Regular-webfont.svg#SourceSansProRegular') format('svg');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansProSemibold';
    src: url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Semibold-webfont.eot');
    src: url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Semibold-webfont.eot?#iefix') format('embedded-opentype'), url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Semibold-webfont.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Semibold-webfont.ttf') format('truetype'), url('https://static.lenskart.com/fonts/fonts/SourceSansPro-Semibold-webfont.svg#SourceSansProSemibold') format('svg');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('https://static.lenskart.com/fonts/fonts/OpenSans-Regular-webfont.eot');
    src: url('https://static.lenskart.com/fonts/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('https://static.lenskart.com/fonts/fonts/OpenSans-Regular-webfont.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/OpenSans-Regular-webfont.ttf') format('truetype'), url('https://static.lenskart.com/fonts/fonts/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Heavy';
    src: url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Heavy.woff') format('woff'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Heavy.otf') format('otf'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Heavy.eot') format('eot'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Heavy.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Medium';
    src: url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Medium.woff') format('woff'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Medium.otf') format('otf'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Medium.eot') format('eot'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Medium.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Book';
    src: url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Book.woff') format('woff'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Book.otf') format('otf'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Book.eot') format('eot'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Book.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Conv_FuturaStd-Light';
    src: url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Light.eot');
    src: local('â˜º'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Light.woff') format('woff'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Light.ttf') format('truetype'), url('https://static.lenskart.com/media/wysiwyg/futura-fonts/FuturaStd-Light.svg') format('svg');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}


@font-face {
    font-family: 'Rajdhani-Medium';
    src: url('https://static.lenskart.com/media/wysiwyg/futura-fonts/Rajdhani-Medium.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}
@font-face {
    font-family: 'Rajdhani-Regular';
    src: url('https://static.lenskart.com/media/wysiwyg/futura-fonts/Rajdhani-Regular.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'LenskartSans-Black';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Black.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Black.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Black.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Black.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSans-Bold';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Bold.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Bold.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Bold.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Bold.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSans-Extrablack';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Extrablack.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Extrablack.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Extrablack.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Extrablack.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSans-Hairline';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Hairline.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Hairline.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Hairline.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Hairline.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSans-Medium';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Medium.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Medium.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Medium.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Medium.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSans-Regular';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Regular.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Regular.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Regular.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lksans/LenskartSans-Regular.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lenskart-Serif-Book';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lenskart-Serif-Normal';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lenskart-Serif-UltraLight';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSerif-Book';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Book.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSerif-Normal';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-Normal.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LenskartSerif-UltraLight';
    src: url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.woff') format('woff'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.otf') format('otf'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.eot') format('eot'), url('https://static.lenskart.com/fonts/fonts/ds/lkserif/Lenskart-Serif-UltraLight.ttf') format('ttf');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Light.woff2') format('woff2'),
        url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Regular.woff2') format('woff2'),
        url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Medium.woff2') format('woff2'),
        url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Semibold.woff2') format('woff2'),
        url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Founders Grotesk';
    src: url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Bold.woff2') format('woff2'),
        url('https://static1.lenskart.com/media/owndays/fonts/FoundersGrotesk/FoundersGrotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AXIS Std';
    src: url('https://static.lenskart.com/media/owndays/fonts/AxisStd/AxisStd-Light.woff2') format('woff2'),
        url('https://static.lenskart.com/media/owndays/fonts/AxisStd/AxisStd-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'AXIS Std';
    src: url('https://static.lenskart.com/media/owndays/fonts/AxisStd/AxisStd-Regular.woff2') format('woff2'),
        url('https://static.lenskart.com/media/owndays/fonts/AxisStd/AxisStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AXIS Std';
    src: url('https://static.lenskart.com/media/owndays/fonts/AxisStd/AxisStd-Medium.woff2') format('woff2'),
        url('https://static.lenskart.com/media/owndays/fonts/AxisStd/AxisStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
