.header-huawei-2-titanium__main {
  width: 100%;
  height: 100%;
  background-color: #414141;
  aspect-ratio: 390 / 620;
  position: relative;
}

.header-huawei-2-titanium__main__logo {
  width: 221px;
  height: auto;
  position: absolute;
  top: 63px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font: 0 / 0 a;
  margin: 0;
}

.header-huawei-2-titanium__main__glasses {
  width: 100%;
  height: auto;
  position: absolute;
  top: -23px;
}

.header-huawei-2-titanium__main__new-arrivals-layout {
  position: absolute;
  left: 38px;
  bottom: 73px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header-huawei-2-titanium__main__new-arrivals-title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  border: 1px solid #b47e00;
  padding: 3px 8px;
  line-height: 0;
  margin-bottom: 11px;
}

.header-huawei-2-titanium__main__new-arrivals-title span {
  color: #b47e00;
  text-align: center;
  font-family: "BIZ UDPGothic", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 19.2px;
  letter-spacing: 0.12px;
}

.header-huawei-2-titanium__main__new-arrivals-titanium {
  width: 179.698px;
  height: auto;
}

.header-huawei-2-titanium__main__new-arrivals-date {
  color: white;
  font-family: "BIZ UDPGothic", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 15.4px;
  letter-spacing: 0.11px;
  margin: 24.22px 0 0 0;
  position: relative;
}

.header-huawei-2-titanium__main__new-arrivals-date::before {
  position: absolute;
  content: url("https://static.lenskart.com/owndays/img/huawei2-titanium/main-text-line-sp.svg");
  bottom: 117px;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 259/98;
}

.header-huawei-2-titanium__main__new-arrivals-date strong {
  color: white;
  font-family: "BIZ UDPGothic", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 140%;
  letter-spacing: 0.14px;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__main {
    width: 100%;
    aspect-ratio: 1440/835;
    margin: 0;
  }
  .header-huawei-2-titanium__main__logo {
    left: unset;
    right: 10%;
    -webkit-transform: none;
            transform: none;
    z-index: 2;
  }
  .header-huawei-2-titanium__main__glasses {
    width: 100%;
    top: 0;
    left: 0;
  }
  .header-huawei-2-titanium__main__new-arrivals-layout {
    left: 10%;
    bottom: 5%;
  }
}

@media (min-width: 1024px) {
  .header-huawei-2-titanium__main__logo {
    width: 28%;
    right: 12%;
  }
  .header-huawei-2-titanium__main__glasses {
    width: 100%;
    top: 0;
    left: 0;
  }
  .header-huawei-2-titanium__main__new-arrivals-layout {
    left: 10%;
    bottom: 5%;
  }
  .header-huawei-2-titanium__main__new-arrivals-title {
    padding: 6px 10px;
    margin-bottom: 18px;
  }
  .header-huawei-2-titanium__main__new-arrivals-title span {
    font-size: 14px;
    line-height: 22.4px;
    letter-spacing: 0.14px;
  }
  .header-huawei-2-titanium__main__new-arrivals-titanium {
    width: 252px;
    height: auto;
  }
  .header-huawei-2-titanium__main__new-arrivals-date {
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0.14px;
    margin: 28px 0 0 0;
  }
  .header-huawei-2-titanium__main__new-arrivals-date::before {
    content: url("https://static.lenskart.com/owndays/img/huawei2-titanium/main-text-line-pc.svg");
    bottom: 125px;
    left: 0;
    aspect-ratio: 492/114.5;
  }
  .header-huawei-2-titanium__main__new-arrivals-date strong {
    font-size: 18px;
    letter-spacing: 0.18px;
  }
}

@media (min-width: 1296px) {
  .header-huawei-2-titanium__main {
    padding-top: 60px;
    padding-right: 80px;
  }
  .header-huawei-2-titanium__main__logo {
    width: 100%;
    max-width: 405px;
    top: 17%;
    right: 12%;
  }
  .header-huawei-2-titanium__main__new-arrivals-layout {
    left: 12%;
    bottom: 12%;
  }
}

@media (min-width: 1500px) {
  .header-huawei-2-titanium__main__logo {
    right: 20.2%;
  }
  .header-huawei-2-titanium__main__new-arrivals-layout {
    left: 20%;
  }
}

.header-huawei-2-titanium__concept {
  width: 100%;
  height: auto;
  padding: 70px 0 51px;
  position: relative;
}

.header-huawei-2-titanium__concept picture {
  width: 100%;
  height: auto;
  display: block;
}

.header-huawei-2-titanium__concept__title {
  color: black;
  font-family: "BIZ UDPGothic", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 160%;
  letter-spacing: 0.28px;
  margin: 0;
}

.header-huawei-2-titanium__concept__details {
  margin-bottom: 6px;
}

.header-huawei-2-titanium__concept__details-text {
  color: #535353;
  font-family: "BIZ UDPGothic", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 32px;
  letter-spacing: 0.16px;
  margin: 24px 0 30px 0;
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__concept__details-text {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.14px;
    font-weight: 400 !important;
  }
}

.header-huawei-2-titanium__concept__glasses {
  max-width: none;
  width: 679px;
  height: auto;
  margin-left: -66px;
}

.header-huawei-2-titanium__concept__options {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 25px;
  padding: 0 40px;
  margin-bottom: 0;
}

.header-huawei-2-titanium__concept__options > li img {
  height: auto;
}

.header-huawei-2-titanium__concept__options > li:nth-child(1) img {
  width: 43.493px;
}

.header-huawei-2-titanium__concept__options > li:nth-child(2) img {
  width: 52px;
}

.header-huawei-2-titanium__concept__options > li:nth-child(3) img {
  width: 51.999px;
}

.header-huawei-2-titanium__concept__options > li:nth-child(4) img {
  width: 52px;
}

.header-huawei-2-titanium__concept__options > li h3 {
  color: black;
  font-family: "BIZ UDPGothic", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 32px;
  letter-spacing: 0.18px;
  margin: 20px 0 8px 0;
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__concept__options > li h3 {
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: 0.16px;
  }
}

.header-huawei-2-titanium__concept__options > li p {
  color: #6A6965;
  font-family: "BIZ UDPGothic", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 32px;
  letter-spacing: 0.16px;
  margin: 0;
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__concept__options > li p {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.14px;
    font-weight: 400 !important;
  }
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__concept {
    padding-bottom: 132px;
  }
  .header-huawei-2-titanium__concept__title {
    width: 50%;
    height: auto;
  }
  .header-huawei-2-titanium__concept__details {
    width: 50%;
    height: auto;
    margin-bottom: 100px;
  }
  .header-huawei-2-titanium__concept__glasses {
    position: absolute;
    top: 26px;
    left: calc(50% - 30.5px);
    width: 577px;
    height: 324.5px;
    margin-left: 0;
  }
  .header-huawei-2-titanium__concept__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 44px;
       -moz-column-gap: 44px;
            column-gap: 44px;
    row-gap: 50px;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .header-huawei-2-titanium__concept__title {
    font-size: 50px;
    line-height: 83.2px;
    letter-spacing: 0.52px;
  }
  .header-huawei-2-titanium__concept__details {
    max-width: 720px;
    margin-bottom: 47px;
  }
  .header-huawei-2-titanium__concept__glasses {
    top: 0;
    left: calc(50% - 61px);
    right: -373px;
    width: 80%;
    height: auto;
    aspect-ratio: 1154/649;
  }
  .header-huawei-2-titanium__concept__glasses.is-fixed {
    position: fixed;
  }
  .header-huawei-2-titanium__concept__options {
    width: 50%;
  }
  .header-huawei-2-titanium__concept__options > li:nth-child(1) img {
    width: 52.493px;
  }
  .header-huawei-2-titanium__concept__options > li:nth-child(2) img {
    width: 56px;
  }
  .header-huawei-2-titanium__concept__options > li:nth-child(3) img {
    width: 61.393px;
  }
  .header-huawei-2-titanium__concept__options > li:nth-child(4) img {
    width: 58px;
  }
  .header-huawei-2-titanium__concept__options > li h3 {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.18px;
    margin: 30px 0 12px 0;
  }
  .header-huawei-2-titanium__concept__options > li p {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.16px;
  }
  .header-huawei-2-titanium__concept__frame-top, .header-huawei-2-titanium__concept__frame-bottom {
    width: 50%;
    position: absolute;
    right: 0;
    z-index: 1;
    background-color: white;
  }
  .header-huawei-2-titanium__concept__frame-top {
    height: 140px;
    top: 0;
  }
  .header-huawei-2-titanium__concept__frame-bottom {
    height: 132px;
    bottom: 0;
  }
}

.header-huawei-2-titanium__feature {
  width: 100%;
  height: auto;
  background-color: white;
}

.header-huawei-2-titanium__feature__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 100px;
  padding: 45px 35px;
  background-color: #f7f7f7;
  border-radius: 20px;
  list-style-type: none;
  width: 100%;
  height: auto;
  margin: 0;
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__feature__list {
    gap: 30px;
  }
}

.header-huawei-2-titanium__feature__list > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 22px;
}

.header-huawei-2-titanium__feature__list > li img {
  height: auto;
}

.header-huawei-2-titanium__feature__list > li:nth-child(1) img {
  width: 48px;
}

.header-huawei-2-titanium__feature__list > li:nth-child(2) img {
  width: 49.119px;
}

.header-huawei-2-titanium__feature__list > li:nth-child(3) img {
  width: 52px;
}

.header-huawei-2-titanium__feature__list > li > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
}

.header-huawei-2-titanium__feature__list > li > div h4 {
  color: black;
  font-family: "BIZ UDPGothic", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 25.6px;
  letter-spacing: 0.16px;
  text-align: center;
  margin: 0;
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__feature__list > li > div h4 {
    font-size: 16px;
    text-align: left;
  }
}

.header-huawei-2-titanium__feature__list > li > div h4 br {
  display: none;
}

.header-huawei-2-titanium__feature__list > li > div p {
  margin-bottom: 0;
  color: #6a6965;
  font-family: "BIZ UDPGothic", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
  letter-spacing: 0.14px;
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__feature__list > li > div p {
    font-size: 14px;
    line-height: 28px;
  }
}

.header-huawei-2-titanium__feature__remark {
  margin-top: 15px;
  padding-bottom: 50px;
  color: #535353;
  font-family: "BIZ UDPGothic", serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.9px;
  letter-spacing: 0.11px;
  padding-left: 15px;
  margin-bottom: 0;
  position: relative;
}

.header-huawei-2-titanium__feature__remark::before {
  content: "*";
  position: absolute;
  left: 0;
  top: 0;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__feature__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .header-huawei-2-titanium__feature__list > li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 30px;
    width: 100%;
  }
  .header-huawei-2-titanium__feature__list > li img {
    min-height: 65px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .header-huawei-2-titanium__feature__list > li:nth-child(1) img {
    width: 58px;
  }
  .header-huawei-2-titanium__feature__list > li:nth-child(2) img {
    width: 61.119px;
  }
  .header-huawei-2-titanium__feature__list > li:nth-child(3) img {
    width: 67.603px;
    padding: 10px 0;
  }
  .header-huawei-2-titanium__feature__list > li > div {
    gap: 12px;
  }
  .header-huawei-2-titanium__feature__list > li > div h4,
  .header-huawei-2-titanium__feature__list > li > div p {
    margin: 0;
  }
  .header-huawei-2-titanium__feature__remark {
    margin-top: 20px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1024px) {
  .header-huawei-2-titanium__feature__list {
    padding: 70px 80px;
  }
  .header-huawei-2-titanium__feature__list > li h4 {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.18px;
  }
  .header-huawei-2-titanium__feature__list > li p {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.16px;
  }
  .header-huawei-2-titanium__feature__remark {
    font-size: 13px;
    padding-left: 20px;
  }
}

.header-huawei-2-titanium__scene__tag {
  width: 57px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  color: #b47e00;
  font-size: 14px;
  line-height: 22.4px;
  font-family: "BIZ UDPGothic", serif;
  font-weight: 700 !important;
  letter-spacing: 0.28px;
  border: 1px solid #b47e00;
}

.header-huawei-2-titanium__scene__title, .header-huawei-2-titanium__scene__desc {
  font-family: "BIZ UDPGothic", serif;
  margin-bottom: 0;
}

.header-huawei-2-titanium__scene__title {
  padding: 15px 0;
  font-size: 26px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 41.6px;
  letter-spacing: 0.26px;
  color: black;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__scene__title {
    font-size: 38px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 60.8px;
    letter-spacing: 0.38px;
  }
}

.header-huawei-2-titanium__scene__desc {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 28px;
  letter-spacing: 0.14px;
  color: #535353;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__scene__desc {
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 32px;
    letter-spacing: 0.16px;
  }
}

.header-huawei-2-titanium__scene__video {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__scene__video {
    height: 690px;
  }
}

@media (max-width: 767px) {
  .header-huawei-2-titanium__scene__video {
    aspect-ratio: 390/281.619;
  }
}

.header-huawei-2-titanium__scene__video::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: url("https://static.lenskart.com/owndays/img/huawei2-titanium/btn_play.svg");
  background-size: 100%;
  z-index: 2;
  pointer-events: none;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__scene__video::after {
    width: 64px;
    height: 64px;
  }
}

.header-huawei-2-titanium__scene__video a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-huawei-2-titanium__scene__video a > iframe {
  pointer-events: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.header-huawei-2-titanium__scene__header__layout {
  display: grid;
  grid-template-columns: 1fr;
}

.header-huawei-2-titanium__scene__header__img img {
  width: 100%;
  height: 270px;
  -o-object-fit: cover;
     object-fit: cover;
}

.header-huawei-2-titanium__scene__header__content {
  width: 100%;
  height: auto;
  padding: 50px 23px;
}

.header-huawei-2-titanium__scene__header__content > .base-huawei-2-titanium__btn {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__scene__header__layout {
    grid-template-columns: 50% 50%;
  }
  .header-huawei-2-titanium__scene__header__content {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .header-huawei-2-titanium__scene__header__img {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .header-huawei-2-titanium__scene__header__img img {
    width: 100%;
    height: 100%;
    -o-object-position: unset;
       object-position: unset;
    overflow: hidden;
  }
}

@media (min-width: 1296px) {
  .header-huawei-2-titanium__scene__header__content {
    padding: 104px 110px;
  }
}

.header-huawei-2-titanium__scene__body__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header-huawei-2-titanium__scene__body__layout > .base-huawei-2-titanium__container {
  padding-block: 50px;
}

.header-huawei-2-titanium__scene__body__layout > .base-huawei-2-titanium__container > .header-huawei-2-titanium__scene__title {
  padding-top: 0;
}

.header-huawei-2-titanium__scene__body__layout > .base-huawei-2-titanium__container > .header-huawei-2-titanium__feature__remark {
  padding-bottom: 0;
}

.header-huawei-2-titanium__scene__body__layout > .header-huawei-2-titanium__scene__header__img {
  -o-object-position: unset;
     object-position: unset;
}

@media (min-width: 768px) {
  .header-huawei-2-titanium__scene__body__layout {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    aspect-ratio: 768/618.41;
  }
  .header-huawei-2-titanium__scene__body__layout > div {
    width: 50%;
  }
  .header-huawei-2-titanium__scene__body__layout > .base-huawei-2-titanium__container {
    margin: auto 0;
  }
  .header-huawei-2-titanium__scene__body__layout--reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}

@media (min-width: 1024px) {
  .header-huawei-2-titanium__scene__body__layout {
    max-height: 500px;
    aspect-ratio: 1440/500;
  }
}

@media (min-width: 1240px) {
  .header-huawei-2-titanium__scene__body__layout > .base-huawei-2-titanium__container {
    padding: 0 110px;
  }
}

@media (min-width: 1240px) {
  .header-huawei-2-titanium__scene__body__layout {
    max-height: none;
  }
}
