.full-widget-width {
    width: calc(100% - 255px);
}

.blankwidgets {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 15px;
    &_box {
        border: 1px solid #ddd;
        &_animatedBackground {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f7f8;
            background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
            background-size: 800px 104px;
            position: relative;
            &_header {
                height: 20px;
            }
            &_image {
                height: 200px;
            }
        }
        &_headerWrap {
            width: 80%;
            margin: 3px;
        }
        &_imageWrap {
            margin: 3px 3px 10px 3px;
        }
        &_footerWrap {
            margin: 3px;
            &_left {
                width: 70%;
                display: inline-block;
            }
            &_right {
                float: right;
                width: 30%;
            }
            &_right div,
            &_left div{
                margin-bottom: 6px;
                height: 15px;
            }
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}