$font-founders: 'Founders Grotesk', sans-serif;

$grey-29: #292929;
$grey-53: #535353;
$blue-00: #0094c8;

.base-air {
  &__container {
    padding-inline: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: 1280px;
    height: auto;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    font-family: $font-founders;
    font-size: 14px;
    font-weight: 600 !important;
    letter-spacing: 0.12px;
    height: 40px;
    border-radius: 10px;
    padding-inline: 40px;
    transition: 0.5s;

    &--primary {
      background-color: $grey-29;
      border-color: $grey-29;
      color: white;
      &:hover,
      &:focus {
        color: white;
      }
    }

    &--secondary {
      background-color: white;
      border-color: $grey-53;
      color: $grey-53;
      &:hover,
      &:focus {
        color: $grey-53;
      }
    }

    @media (min-width: 1024px) {
      height: 54px;
      font-size: 19.2px;
      letter-spacing: 0.8px;

      &:hover, &:active, &:visited, &:focus {
        text-decoration: none;
      }

      &--primary {
        &:hover {
          background-color: $grey-53;
          border-color: $grey-53;
          color: white;
        }
      }
      &--secondary {
        &:hover {
          background-color: $grey-53;
          color: white;
        }
      }
    }
  }

  &__hide {
    &--sp {
      display: none;
    }

    &--pc {
      display: block;
    }

    @media (min-width: 768px) {
      &--sp {
        display: block;
      }
  
      &--pc {
        display: none;
      }
    }
  }

  &__scroll-hidden {
    overflow: hidden;
  }
}
