.wishlist-page {
    padding: 30px 175px;
    background-color: #F7F7F7;

    &__layout {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__title {
        margin: 0;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.02em;  
		color: #000000;
    }

    &__empty {
        margin: 16px 0 0;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.5);
    }

    &__empty-image {
        margin-top: 118px;
    }
}