.cookies-modal__button {
  width: auto;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 700 !important;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: .195px;
  margin: 0;
  border-radius: 0;
  padding: 8px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  border: 1px solid #666;
}

.cookies-modal__button--white {
  color: #666;
  background-color: white;
  border: 1px solid #666;
}

.cookies-modal__button--gray {
  background-color: #516075;
  border-color: #516075;
  color: white;
}

@media (min-width: 1024px) {
  .cookies-modal__button {
    padding: 16px;
    letter-spacing: .195px;
  }
  .cookies-modal__button--white:hover {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #666;
    color: white;
  }
  .cookies-modal__button--gray:hover {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: .8;
  }
}

.cookies-modal__popup {
  position: fixed;
  left: 8px;
  right: 8px;
  bottom: 12px;
  z-index: 9999999;
}

.cookies-modal__popup__container {
  width: 100%;
  max-width: 467px;
  height: auto;
  background-color: rgba(255, 255, 255, .9);
  padding: 12px 16px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, .28);
          box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, .28);
  position: relative;
}

.cookies-modal__popup__close {
  position: absolute;
  top: 12px;
  right: 16px;
  width: 16px;
  height: 16px;
  border: none;
  background-color: transparent;
}

.cookies-modal__popup__close::before {
  content: '';
  background-image: url("https://static.lenskart.com/owndays/img/cookies-modal/close-icon.svg");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  top: 0;
  left: 0;
}

.cookies-modal__popup__title {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 600 !important;
  font-size: 11.5px;
  line-height: 17px;
  letter-spacing: .173px;
  color: black;
  margin-bottom: 2px;
}

.cookies-modal__popup__desc {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 300 !important;
  font-size: 11px;
  line-height: normal;
  color: black;
  margin-bottom: 0;
}

.cookies-modal__popup__desc a {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 600 !important;
  color: #0094c8;
  border-color: #0094c8;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.cookies-modal__popup__desc a:hover {
  color: #0094c8;
  border-color: #0094c8;
}

@media (min-width: 1024px) {
  .cookies-modal__popup__desc a:hover {
    cursor: pointer;
  }
}

.cookies-modal__popup__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 3px;
  gap: 12px;
}

@media (min-width: 520px) {
  .cookies-modal__popup__title {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .cookies-modal__popup__title {
    font-size: 12px;
    margin-bottom: 4px;
  }
}

@media (min-width: 1024px) {
  .cookies-modal__popup__container {
    max-width: 890px;
    padding-left: 19px;
    padding-right: 48px;
  }
  .cookies-modal__popup__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 20px;
  }
  .cookies-modal__popup__close {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .cookies-modal__popup__title {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 3px;
  }
  .cookies-modal__popup__desc {
    text-align: left;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: .195px;
  }
  .cookies-modal__popup__buttons {
    margin-top: 0;
  }
}

.cookies-modal__settings {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
}

.cookies-modal__settings__layout {
  width: 100%;
  height: 100%;
}

.cookies-modal__settings__content {
  width: 100%;
  max-width: 600px;
  height: 100%;
  background-color: white;
}

.cookies-modal__settings__header {
  width: 100%;
  height: 60px;
  padding: 0 1.3em 0 30px;
  position: relative;
}

.cookies-modal__settings__title {
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 600 !important;
  font-size: 16px;
  letter-spacing: 0;
  color: black;
  margin: 0;
  padding-top: 40px;
}

.cookies-modal__settings__close {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 1.3em;
  border: none;
  background: transparent;
}

.cookies-modal__settings__close::before {
  content: '';
  background-image: url("https://static.lenskart.com/owndays/img/cookies-modal/close-icon.svg");
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cookies-modal__settings__body {
  width: 100%;
  height: calc(100% - 147px);
  padding: 22px 30px 50px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.cookies-modal__settings__desc p {
  color: black;
  line-height: 20px;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: lighter !important;
  letter-spacing: 0;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 0;
}

.cookies-modal__settings__desc p:nth-child(2) {
  margin-top: 10px;
  padding-bottom: 20px;
}

.cookies-modal__settings__desc a {
  color: black;
  text-decoration: underline;
}

.cookies-modal__settings__desc a:hover {
  color: black;
}

@media (min-width: 1024px) {
  .cookies-modal__settings__desc a:hover {
    cursor: pointer;
  }
}

.cookies-modal__settings__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 25px;
}

.cookies-modal__settings__item__header, .cookies-modal__settings__item__desc {
  background-color: #f4f4f4;
  border-radius: .35em;
}

.cookies-modal__settings__item__header {
  padding: 0 10px 0 0;
  letter-spacing: 0;
  position: relative;
}

.cookies-modal__settings__item__name {
  position: relative;
  width: 88%;
  height: auto;
  padding: 20px 0 20px 35px;
}

@media (min-width: 1024px) {
  .cookies-modal__settings__item__name {
    cursor: pointer;
  }
}

.cookies-modal__settings__item__name::before {
  content: '';
  position: absolute;
  border: solid #2d4156;
  border-width: 0 2px 2px 0;
  top: 48%;
  left: 17px;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  margin-right: 15px;
  padding: .2em;
}

.cookies-modal__settings__item__name h3 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 13.3px;
  letter-spacing: 0;
  color: black;
}

.cookies-modal__settings__item__desc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cookies-modal__settings__item__desc p {
  font-family: "Founders Grotesk", sans-serif;
  font-size: 12px;
  line-height: 20px;
  margin-top: 0;
  padding: .2em 15px 27px;
  letter-spacing: 0;
  color: black;
  margin-bottom: 0;
}

.cookies-modal__settings__item__toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%) scale(1.1);
          transform: translateY(-50%) scale(1.1);
  width: 52px;
  height: 24px;
  border-radius: 4em;
}

.cookies-modal__settings__item__toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

@media (min-width: 1024px) {
  .cookies-modal__settings__item__toggle input {
    cursor: pointer;
  }
}

.cookies-modal__settings__item__toggle--slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  background-color: #adadad;
}

@media (min-width: 1024px) {
  .cookies-modal__settings__item__toggle--slider {
    cursor: pointer;
  }
}

.cookies-modal__settings__item__toggle--slider::before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  z-index: 1;
}

.cookies-modal__settings__item__toggle input:checked + .cookies-modal__settings__item__toggle--slider {
  background-color: #516075;
}

.cookies-modal__settings__item__toggle input:checked + .cookies-modal__settings__item__toggle--slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

.cookies-modal__settings__item__toggle input:disabled + .cookies-modal__settings__item__toggle--slider {
  cursor: not-allowed;
  background-color: #d9d9d9;
}

.cookies-modal__settings__item__toggle--on {
  position: relative;
}

.cookies-modal__settings__item__toggle--on::before {
  content: "";
  margin: 0 auto;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border: solid #ecf2fa;
  border-width: 0 2px 2px 0;
  display: inline-block;
  left: .75em;
  padding: .1em .1em .45em;
  top: 6px;
  font-size: 16px;
}

.cookies-modal__settings__item__toggle--off {
  position: relative;
  right: -90%;
}

@supports (-webkit-backdrop-filter: blur(0)) {
  .cookies-modal__settings__item__toggle--off {
    right: -45%;
  }
}

.cookies-modal__settings__item__toggle--off::before, .cookies-modal__settings__item__toggle--off::after {
  background: #ecf2fa;
  content: " ";
  display: block;
  margin: 0 auto;
  position: absolute;
  right: .7em;
  top: .42em;
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 1.4px;
  height: 12px;
}

.cookies-modal__settings__item__toggle--off::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.cookies-modal__settings__item__toggle--off::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.cookies-modal__settings__footer {
  width: 100%;
  max-width: 758px;
  height: 87px;
  padding: 20px 35px;
  display: block;
  margin: 0 auto;
}

.cookies-modal__settings__footer > .cookies-modal__button {
  width: 100%;
  height: 100%;
}

@media (min-width: 512px) {
  .cookies-modal__settings {
    background-color: rgba(0, 0, 0, .5);
  }
  .cookies-modal__settings__content {
    height: calc(100% - 2.5em);
    max-height: 530px;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .cookies-modal__settings__footer {
    max-width: 300px;
    margin: 0;
    padding: 20px 30px;
  }
}

.cookies-modal__remove-border-bottom-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cookies-modal__expand .cookies-modal__settings__item__name::before {
  top: 54%;
  -webkit-transform: translateY(-50%) rotate(224deg);
          transform: translateY(-50%) rotate(224deg);
  left: 17px;
}
