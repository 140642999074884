.search-bar-nascent {
  max-width: 420px;
  height: 35px;
  border: 1px solid transparent;
  position: relative;
  will-change: border-color, padding-left, padding-right;
  transition: border-color 0.3s ease-in-out, padding-left 0.3s ease-in-out, padding-right 0.3s ease-in-out;

  &--active {
    padding-left: 25px;
    padding-right: 25px;
    flex-grow: 1;
    border-color: #555;
    background-color: #FFFFFF;
  }

  &__layout {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    & > div:first-child {
      width: 100%;
      height: 100%;
    }

    [src$=".svg"] {
      width: auto;
    }
  }

  &__input {
    flex: 1 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    color: #555;

    &::placeholder {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      color: rgba(85, 85, 85, 0.3);
    }
  }

  &__icon {
    flex-shrink: 0;
    cursor: pointer;
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;

    &:hover {
        opacity: 0.5;
    }

    &--secondary {
      width: 15px;
      height: 15px;
      object-fit: contain;
      cursor: pointer;
      @media (min-width: 1440px) {
        width: 24px;
        height: 24px;
      }
    }
  }
}
