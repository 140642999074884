.footer-owndays-pc__lineup {
  padding-bottom: 100px;
}

.footer-owndays-pc__lineup__mb-0 {
  margin-bottom: 0 !important;
}

.footer-owndays-pc__lineup__product {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px 12px;
  margin-top: 60px;
  list-style-type: none;
  padding-left: 0;
}

@media (min-width: 768px) {
  .footer-owndays-pc__lineup__product {
    margin-top: 0;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .footer-owndays-pc__lineup__product {
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
  .footer-owndays-pc__lineup__product--first {
    margin-top: 0;
  }
}

.footer-owndays-pc__lineup__product a img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.footer-owndays-pc__lineup__product a:hover, .footer-owndays-pc__lineup__product a:active, .footer-owndays-pc__lineup__product a:visited, .footer-owndays-pc__lineup__product a:focus {
  text-decoration: none;
}

.footer-owndays-pc__lineup__product a:hover {
  color: #000000;
}

.footer-owndays-pc__lineup__product a:hover img {
  opacity: 1;
}

.footer-owndays-pc__lineup__product figure {
  position: relative;
  margin: 0;
}

@media (min-width: 768px) {
  .footer-owndays-pc__lineup__product figure:hover img {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
}

.footer-owndays-pc__lineup__product-color {
  font-family: "Space Mono", monospace;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  color: #000000;
  margin: 0;
}

.footer-owndays-pc__lineup__product-sku {
  font-family: "NeuzeitGroReg";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #000000;
  margin: 0;
}

.footer-owndays-pc__lineup__more {
  margin-top: 40px;
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .footer-owndays-pc__lineup__more {
    margin-top: 60px;
    margin-bottom: 90px;
  }
}

.footer-owndays-pc__lineup__footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px 0;
  margin-top: 100px;
}

@media (min-width: 768px) {
  .footer-owndays-pc__lineup__footer {
    grid-template-columns: 1fr 1fr;
    gap: 40px 20px;
  }
}

@media (min-width: 1024px) {
  .footer-owndays-pc__lineup__footer {
    gap: 90px 180px;
  }
}

.footer-owndays-pc__lineup__footer .base-owndays-pc__btn {
  width: 100%;
  max-width: 450px;
}

@media (min-width: 768px) {
  .footer-owndays-pc__lineup__footer .base-owndays-pc__btn:first-child {
    margin-left: auto;
    margin-right: 0;
  }
  .footer-owndays-pc__lineup__footer .base-owndays-pc__btn:last-child {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .footer-owndays-pc__lineup {
    padding-bottom: 180px;
  }
}
