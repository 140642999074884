.breadcrumbContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid rgba(84, 96, 115, .1);
  border-bottom: 1px solid rgba(84, 96, 115, .1);
}

.breadcrumbContainer_wrapper {
  font-size: 11px;
  letter-spacing: 1px;
  border-radius: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 5px 5px 0;
}

.breadcrumbContainer_wrapper_breadcrumb {
  padding: 0;
  margin: 0;
}

.breadcrumbContainer_wrapper_breadcrumb li {
  font-size: 16px;
  line-height: 30px;
  opacity: 0.3;
  display: inline-block;
}

.breadcrumbContainer_wrapper_breadcrumb li:after {
  content: '>';
  padding: 0 15px;
  font-family: 'NeuzeitGroReg', sans-serif;
}

.breadcrumbContainer_wrapper_breadcrumb li:last-child:after {
  display: none;
}

.breadcrumbContainer_wrapper_breadcrumb li:last-child {
  opacity: 1;
}

.breadcrumbContainer_wrapper_breadcrumb span {
  color: #000000;
}

.breadcrumbContainer_wrapper_breadcrumb a {
  color: #000000;
}

@media only screen and (min-width: 1025px) {
  .breadcrumbContainer_wrapper {
    margin-top: 5px;
    padding-left: 50px;
  }
  .breadcrumbContainer_wrapper_breadcrumb {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }
}

@media screen and (max-width: 1153px) {
  .breadcrumbContainer_wrapper {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animatedBackground {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
