.od-payment-methods {
  &__title {
    padding-top: 28px;
    color: #444;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.48px;
    display: flex;
    align-items: center;
    &-backBTN {
			cursor: pointer;
			margin-bottom: -11px;
      margin-right: 15px;
		}
  }
}

.od-payment-methods-active {
  display: grid;
  padding: 30px 16px;
  margin-top: 16px;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  background-color: #fff;
}

.od-payment-methods-options {
  height: 100%;
  border-right: 1px dashed rgba(84, 96, 115, 0.10);
  
  &__option {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
  }
  
  &__option-label {
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.28px;
  }

  .atom-payment {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      overflow: hidden;
      color: #444;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.28px;
    }

    &__description {
      overflow: hidden;
      color: #444444B2;
      text-overflow: ellipsis;
      font-size: 12px;
      letter-spacing: -0.24px;
    }
  }

  &__others {
    display: flex;
    padding: 0 16px 0 52px;
    margin-top: 12px;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }
}


.od-payment-methods-checkbox {
  position: relative;

  &__check {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;

      &--active,
      &:hover {
          opacity: 1;
      }
  }
}

.od-payment-methods-more {
  display: flex;
  padding: 16px;
  border-top: 1px dashed rgba(84, 96, 115, 0.10);
  align-items: center;
  gap: 12px;
  background-color: #fff;

  &__method {
    display: flex;
    width: 46px;
    height: 32px;
    border: 1px solid rgba(84, 96, 115, 0.10);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
}

.od-payment-methods-options__radio__check {
  height: 13px;
  width: 13px;
}