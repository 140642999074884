.od-membership-heading__subtitle {
  width: 100%;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-top: 120px;
  text-align: center;
  opacity: 0.5;
}

.od-membership-heading__title {
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 41px;
  margin-top: 20px;
}

.od-membership-heading__title:before, .od-membership-heading__title:after {
  content: ' ';
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  border-bottom: 1px solid #000000;
  opacity: 0.2;
}

.od-membership-heading__title:before {
  margin: auto 67px auto 0;
}

.od-membership-heading__title:after {
  margin: auto 0 auto 67px;
}

.od-membership-heading__description {
  font-size: 20px;
  line-height: 40px;
  color: #000000;
  opacity: 0.5;
  padding: 0 283px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .od-membership-heading__subtitle {
    margin-top: 50px;
  }
  .od-membership-heading__title {
    font-size: 32px;
  }
  .od-membership-heading__description {
    padding: 0 20px;
  }
}
