$font-founders: "Founders Grotesk", sans-serif;

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  &.breadcrumb--2 {
    background-color: #fff;
    border-top: 1px solid rgba(84, 96, 115, 0.1);
    border-bottom: 1px solid rgba(84, 96, 115, 0.1);
    padding: 10px 20px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    >li+li::before{
      content: '>';
      padding: 0 15px;
    }
  }
  

  &-list {
    margin: 0;
    padding: 0;
  }
  &-item {
    display: flex;
    align-items: center;
    opacity: 0.3;
    font-family: $font-founders;
    
    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }
    &.active {
      color: #000;
      opacity: 1;
    }
  }
}
@media (min-width: 1024px) {
  .breadcrumb {
  
    &.breadcrumb--2 {
      padding: 10px 50px;
    }
  }
}

.breadcrumb.breadcrumb--2 > li {
  display: flex;
}
