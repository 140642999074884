.header-kimetsu__main {
  background-color: #22140b;
  position: relative;
  padding-bottom: 24px;
}

.header-kimetsu__main--ban-dai {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 47px;
  height: 47px;
  -o-object-fit: contain;
     object-fit: contain;
}

.header-kimetsu__main--kimetsu {
  display: block;
  width: 235px;
  height: 222px;
  margin: 0px auto 50px;
}

@media (max-width: 767px) {
  .header-kimetsu__main--kimetsu {
    width: 123px;
    height: 101px;
    margin: 0;
  }
}

.header-kimetsu__main--owndays {
  position: absolute;
  width: 174px;
  height: 174px;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/bg-owndays.svg") no-repeat center center/contain;
  color: white;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header-kimetsu__main--owndays h5,
.header-kimetsu__main--owndays h6 {
  font-family: "Noto Sans JP", sans-serif;
}

.header-kimetsu__main--owndays h5 {
  font-size: 11px;
  font-weight: 700 !important;
  margin-bottom: 5px;
}

.header-kimetsu__main--owndays h6 {
  font-size: 9px;
  font-weight: 500 !important;
  margin: 0;
}

.header-kimetsu__main--owndays h6 small {
  font-size: 8px;
  font-weight: 500;
}

.header-kimetsu__main--owndays img {
  height: 25px;
  width: auto;
}

.header-kimetsu__main__heading {
  color: white;
  text-align: center;
  position: relative;
}

.header-kimetsu__main__heading-bg {
  display: block;
  position: absolute;
  top: -42px;
  left: 135px;
  width: 346px;
  height: auto;
  max-width: none;
}

@media (min-width: 768px) {
  .header-kimetsu__main__heading-bg {
    top: -60px;
    left: 270px;
    width: 700px;
  }
}

.header-kimetsu__main__heading h1 {
  position: relative;
  font-family: "Noto Serif JP", serif;
  font-weight: 900 !important;
  font-size: 16px;
  line-height: 1.25;
  margin: -11px 0 0 0;
  letter-spacing: 0.05em;
  line-height: 1.4;
}

.header-kimetsu__main__heading h1 span {
  display: block;
  font-weight: 700 !important;
  font-size: 11px;
  margin-bottom: 4px;
}

@media (min-width: 1024px) {
  .header-kimetsu__main-inner {
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
  }
  .header-kimetsu__main-inner .header-kimetsu__main--kimetsu {
    width: 235px;
    height: 222px;
    margin: 0px auto 50px;
  }
  .header-kimetsu__main__heading-bg {
    top: -10%;
    left: 5%;
    width: 493px;
  }
  .header-kimetsu__main__heading-bg h1 {
    font-size: 56px;
  }
  .header-kimetsu__main__heading-bg h1 span {
    font-size: 20px;
  }
}

.header-kimetsu__main--img {
  display: block;
  max-width: 670px;
  margin-left: auto;
  margin-right: -36px;
}

@media (max-width: 1023px) {
  .header-kimetsu__main--img {
    max-width: 97%;
  }
}

@media (max-width: 767px) {
  .header-kimetsu__main--img {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .header-kimetsu__main--ban-dai {
    width: 90px;
    height: 90px;
    top: 42px;
    right: -33px;
  }
}

@media (min-width: 768px) {
  .header-kimetsu__main--owndays {
    bottom: -43px;
    right: 0;
    width: 297px;
    height: 297px;
  }
  .header-kimetsu__main--owndays h5 {
    font-size: 18px;
    line-height: 25px;
    margin: 0 0 8px 0;
  }
  .header-kimetsu__main--owndays h6 {
    font-size: 15px;
  }
  .header-kimetsu__main--owndays h6 small {
    font-size: 14px;
  }
  .header-kimetsu__main--owndays img {
    width: 109px;
    height: 43px;
  }
  .header-kimetsu__main__heading {
    margin-bottom: 40px;
  }
  .header-kimetsu__main__heading h1 {
    font-size: 36px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .header-kimetsu__main__heading h1 {
    margin-right: 10%;
  }
}

@media (min-width: 768px) and (max-width: 767px) {
  .header-kimetsu__main__heading h1 {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .header-kimetsu__main__heading h1 span {
    font-size: 16px;
    letter-spacing: 0.1em;
    margin-bottom: 8px;
  }
}

@media (max-width: 1023px) {
  .header-kimetsu__main {
    padding-bottom: 24px;
  }
  .header-kimetsu__main > .base-kimetsu__container {
    padding-left: 0;
    padding-right: 0;
  }
  .header-kimetsu__main--owndays {
    top: 60%;
    left: 3%;
  }
  .header-kimetsu__main--kimetsu {
    position: absolute;
    top: 3%;
    left: 3%;
  }
  .header-kimetsu__main__heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: -40px 16px 12px 0;
  }
}

@media (max-width: 767px) {
  .header-kimetsu__main--owndays {
    left: -3%;
  }
  .header-kimetsu__main__heading {
    margin: 15px 16px 12px 0;
  }
}

.header-kimetsu__sub {
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/sub-bg.png") no-repeat center bottom/cover;
  color: white;
  padding: 54px 0 80px;
}

.header-kimetsu__sub-title {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 38px;
}

.header-kimetsu__sub-title-line {
  display: block;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  height: 16px;
  width: 100%;
  position: relative;
}

.header-kimetsu__sub-title-line::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  width: 100%;
  background-color: white;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header-kimetsu__sub-title-line--dark {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.header-kimetsu__sub-title-line--dark::before {
  background-color: black;
}

.header-kimetsu__sub-title-text {
  font-family: "Noto Serif JP", serif;
  font-size: 21px;
  font-weight: 700 !important;
  white-space: nowrap;
  padding: 0 23px;
}

@media (min-width: 768px) {
  .header-kimetsu__sub-title-line {
    height: 20px;
  }
  .header-kimetsu__sub-title-text {
    font-size: 38px;
    line-height: 55px;
  }
}

.header-kimetsu__sub-text {
  font-weight: 400 !important;
  font-size: 13px;
  letter-spacing: .52px;
  line-height: 2;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .header-kimetsu__sub-text {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .header-kimetsu__sub-text {
    letter-spacing: .26px;
  }
}

.header-kimetsu__sub__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -7px;
  padding: 0;
}

.header-kimetsu__sub__nav-item {
  list-style-type: none;
  width: 33.33333%;
  padding: 0 7px;
}

.header-kimetsu__sub__nav-link {
  background-color: #434343;
  border: 1.5px solid #86846A;
  position: relative;
  display: inline-block;
  margin-bottom: 58px;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: pointer;
}

.header-kimetsu__sub__nav-link::before {
  content: '';
  position: absolute;
  top: calc(100% + 13px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 40px;
  height: 19px;
  background: url("https://static.lenskart.com/media/owndays/img/kimetsu/arrow-down.svg") no-repeat center center/contain;
}

.header-kimetsu__sub__nav-link:hover {
  color: #999;
  text-decoration: none;
}

.header-kimetsu__sub__nav-link:hover img {
  opacity: .6;
  -webkit-transition: .5s;
  transition: .5s;
}

.header-kimetsu__sub__nav-img {
  position: relative;
}

.header-kimetsu__sub__nav-img::before, .header-kimetsu__sub__nav-img::after {
  content: '';
  position: absolute;
  top: 1px;
  border-bottom: 13px solid transparent;
  z-index: 1;
}

.header-kimetsu__sub__nav-img::before {
  left: 1px;
  border-left: 13px solid #86846A;
}

.header-kimetsu__sub__nav-img::after {
  right: 1px;
  border-right: 13px solid #86846A;
}

.header-kimetsu__sub__nav-title {
  color: white;
  font-family: "Noto Serif JP", serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 4px 0 5px;
  margin: 0;
  letter-spacing: .01em;
}

@media (min-width: 768px) {
  .header-kimetsu__sub__nav-title {
    font-size: 15px;
    line-height: 2;
  }
}

@media (min-width: 768px) {
  .header-kimetsu__sub {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 150px;
  }
  .header-kimetsu__sub-title-text {
    padding-left: 62px;
    padding-right: 62px;
    letter-spacing: .2em;
  }
  .header-kimetsu__sub__nav-item {
    width: 25%;
  }
  .header-kimetsu__sub__nav-link {
    border-width: 2.5px;
    margin-bottom: 65px;
  }
  .header-kimetsu__sub__nav-link::before {
    top: calc(100% + 14px);
    width: 50px;
    height: 24px;
  }
}
