$base-img-url: 'https://static.lenskart.com/owndays/img/tomandjerry';

$blue-of: #0f99c4;
$pink-ec: #ec7497;
$green-00: #00ac87;
$gray-7b: #7b868c;
$yellow-fa: #fae445;
$yellow-fd: #fde360;
$brown-68: #682f25;
$red-e1: #e1002a;

$font-karu: "Karu";
$font-genei: "GenEi POPle Pw";
$font-zen: "Zen Kaku Gothic New", serif;

.body-tom-and-jerry {
  &__lineup {

    &__main {
      &__bg {
        &-header {
          width: 100%;
          height: auto;
          position: relative;

          &::before, &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            z-index: -4;
          }

          img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: -1;
          }

          &::before {
            top: -1px;
            height: 95%;
            background-color: $blue-of;
          }

          &::after {
            bottom: -1px;
            height: 5%;
            background-color: $pink-ec;
          }

          &[data-key="chase"] {
            &::before {
              background-color: $pink-ec;
            }

            &::after {
              background-color: $green-00;
              z-index: 0;
            }

            img {
              z-index: 0;
            }
          }

          &[data-key="friendly-face"] {
            &::before {
              z-index: 0;
              background-color: transparent;
            }

            &::after {
              background-color: $gray-7b;
              z-index: 0;
            }

            img {
              z-index: 0;
            }
          }

          &[data-key="cheese"] {
            &::before {
              z-index: 0;
              background-color: transparent;
            }

            &::after {
              background-color: $yellow-fa;
              z-index: 0;
            }

            img {
              z-index: 0;
            }
          }
        }

        &-body {
          width: 100%;
          margin: 0 auto;
          height: auto;
          padding: 50px 20px 93px 20px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background-color: $pink-ec;
          }

          &[data-key="chase"] {
            &::before {
              z-index: 0;
              background-color: $green-00 !important;
            }
          }

          &[data-key="friendly-face"] {
            &::before {
              z-index: 0;
              background-color: $gray-7b !important;
            }
          }

          &[data-key="cheese"] {
            &::before {
              z-index: 0;
              background-color: $yellow-fa !important;
            }
          }
        }

        &-footer {
          width: 100%;
          height: auto;
          background-color: $pink-ec;
          padding-bottom: calc(335px + 5px);

          &[data-key="chase"] {
            background-color: $green-00;
          }

          &[data-key="friendly-face"] {
            background-color: $gray-7b;
          }

          &[data-key="cheese"] {
            background-color: $yellow-fa;
          }
        }
      }

      &__figure {
        &-1 {
          width: 192.67px;
          height: auto;
          position: absolute;
          top: -125px;
          right: -41px;
          z-index: 2;

          &-addon {
            position: absolute;
            height: auto;
            z-index: 1;

            &[data-key="friendly-face"] {
              width: 83.359px;
              top: -95px;
              left: 11%;
              transform: translateX(-50%);
            }
          }

          &[data-key="chase"] {
            width: 134px;
            height: 127.916px;
            top: -92px;
            right: unset;
            left: -20px;
          }

          &[data-key="friendly-face"] {
            width: 303.43px;
            height: auto;
            top: -105px;
            left: calc(50% - 24px);
            right: unset;
            transform: translateX(-50%) !important;
          }

          &[data-key="cheese"] {
            width: 125.238px;
            height: auto;
            top: -49px;
            right: unset;
            left: -20px;
            transform: rotate(-11.122deg);
            transform-origin: center bottom;
            animation: cartoonSwinging 2s linear infinite;
          }
        }

        &-2 {
          width: 99px;
          height: auto;
          position: absolute;
          bottom: -53px;
          left: -11px;
          z-index: 2;

          &[data-key="chase"] {
            width: 132.437px;
            height: 74px;
            bottom: unset;
            left: unset;
            top: 2px;
            right: 2px;
          }

          &[data-key="friendly-face"] {
            width: 113.55px;
            height: auto;
            transform: rotate(-56.879deg) !important;
            left: unset;
            right: -75px;
            bottom: -101px;
          }

          &[data-key="cheese"] {
            width: 92.981px;
            height: auto;
            bottom: -22px;
            left: unset;
            right: -9px;
          }
        }

        &-3 {
          position: absolute;
          z-index: 2;

          &[data-key="chase"] {
            width: 146px;
            height: auto;
            bottom: -66px;
            right: -8px;
          }
        }
      }

      &__layout {
        &-model,
        &-point {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          border: 2px solid black;
          background: white;
        }

        &-model {
          border-radius: 30px 30px 0 0;
          padding: 32px 20px 40.5px 20px;
        }

        &-point {
          justify-content: center;
          padding: 40.5px 20px 31.2px 20px;
          border-top-width: 0;
          border-bottom-width: 8.79px;
          border-radius: 0 0 30px 30px;
        }

        &-product {
          display: flex;
          flex-direction: column;
        }

        &-pc {
          position: relative;
          z-index: 4;
        }
      }

      &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-sp {
          display: flex;
        }

        &-pc {
          display: none;
        }

        &-title {
          color: black;
          text-align: center;
          font-family: $font-genei;
          font-size: 50px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          letter-spacing: 1.28px;
          display: flex;
          margin-bottom: 0;

          @media (max-width: 767px) {
            flex-direction: column;
            font-size: 32px;
          }
        }

        &-model {
          width: 198px;
          height: auto;
          margin-left: 13px;

          @media (max-width: 767px) {
            width: 127px;
            margin: 0 auto;
          }
        }

        &-desc {
          img {
            width: 100%;
            height: auto;
          }

          p {
            color: $brown-68;
            font-family: $font-zen;
            font-size: 14px;
            font-style: normal;
            font-weight: 900;
            line-height: 24px;
            letter-spacing: normal;
            margin-bottom: 0;
          }
        }

        &__order-funny-1,
        &__order-funny-2,
        &__order-funny-3,
        &__order-chase-1,
        &__order-chase-2,
        &__order-chase-3,
        &__order-friendly-face-1,
        &__order-friendly-face-2,
        &__order-friendly-face-3,
        &__order-cheese-1,
        &__order-cheese-2,
        &__order-cheese-3 {
          display: none;
        }
      }

      &__slider {
        &__points {
          &__desc {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-bottom: 29px;

            p {
              margin-bottom: 0;
            }

            p:nth-child(1) {
              padding: 0 11px;
              background-color: $red-e1;
              line-height: 0;

              span {
                color: white;
                font-family: $font-karu;
                font-size: 20px;
                font-style: normal;
                font-weight: 900;
                line-height: 33px;
                letter-spacing: 0.6px;
                text-transform: uppercase;
              }
            }

            p:not(:first-child) {
              color: $brown-68;
              text-align: center;
              font-family: $font-zen;
              font-size: 14px;
              font-style: normal;
              font-weight: 900;
              line-height: 29px;
              min-height: 87px;
              width: fit-content;
              height: auto;
            }
          }

          &__img {
            width: calc(100% - 16px);
            height: auto;
            border: 2px solid black;
            margin: 0 auto;
          }
        }

        &__products {
          > .slick-list {
            margin: 0 -5px;

            .slick-slide {
              padding: 0 5px;
              max-width: 503px;
              aspect-ratio: 603/385;
            }
          }
        }
      }

      &__product {
        &__layout {
          &-row {
            display: flex;
            justify-content: space-between;
            padding: 20px;
          }
        }

        &__sku {
          color: white;
          -webkit-text-stroke-width: 4px;
          -webkit-text-stroke-color: black;
          paint-order: stroke;
          font-family: $font-karu;
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: 32.8px;
          letter-spacing: 2.2px;
          margin-bottom: 0;

          @media (max-width: 767px) {
            -webkit-text-stroke-width: 3px;
          }
        }

        &__color {
          &-layout {
            display: flex;
            gap: 5px;
            z-index: 2;
          }

          &-name {
            color: white;
            font-family: $font-zen;
            font-size: 13px;
            font-style: normal;
            font-weight: 900;
            line-height: 2;
            margin-bottom: 0;

            &__cheese {
              color: black;
            }
          }

          &-chip {
            &__funny,
            &__chase,
            &__friendly-face,
            &__cheese {
              width: 34px;
              height: 34px;
            }

            &__funny--selected,
            &__chase--selected,
            &__friendly-face--selected,
            &__cheese--selected {
              border: 3px solid white;
            }
          }

          &-c1,
          &-c2 {
            display: block;
            width: 100%;
            height: 100%;
          }

          &-c1 {
            background: url("#{$base-img-url}/products/funny/color-1.webp") center/100% no-repeat;

            &[data-key="chase"] {
              background: url("#{$base-img-url}/products/chase/color-1.webp") center/100% no-repeat;
            }

            &[data-key="friendly-face"] {
              background: url("#{$base-img-url}/products/friendly-face/color-1.webp") center/100% no-repeat;
            }

            &[data-key="cheese"] {
              background: url("#{$base-img-url}/products/cheese/color-1.webp") center/100% no-repeat;
            }
          }

          &-c2 {
            background: url("#{$base-img-url}/products/funny/color-2.webp") center/100% no-repeat;

            &[data-key="chase"] {
              background: url("#{$base-img-url}/products/chase/color-2.webp") center/100% no-repeat;
            }

            &[data-key="friendly-face"] {
              background: url("#{$base-img-url}/products/friendly-face/color-2.webp") center/100% no-repeat;
            }

            &[data-key="cheese"] {
              background: url("#{$base-img-url}/products/cheese/color-2.webp") center/100% no-repeat;
            }
          }
        }

        &__price {
          color: white;
          font-family: $font-zen;
          font-size: 18px;
          font-style: normal;
          font-weight: 900;
          line-height: 32.8px;
          margin-top: -4px;
          margin-bottom: 0;

          &__cheese {
            color: black;
          }
        }

        &__button {
          max-width: 300px;
          padding: 0 0 65px 0;
          margin: 0 auto;
        }
      }

      @media (min-width: 961px) {
        &__bg {
          &-header[data-key] {
            background-color: transparent;
            margin-top: -2px;
          }
          &-body {
            padding: 32px 20px 82px 20px;
            margin-top: 0;
          }
          &-footer {
            padding-bottom: calc(335px + 110px);
          }
        }

        &__figure {
          &-1 {
            top: -125px;
            right: -49px;

            &-addon {
              &[data-key="friendly-face"] {
                top: -96px;
                left: 35%;

                @media (min-width: 1200px) {
                  left: 37.5%;
                }
              }
            }

            &[data-key="chase"] {
              top: 49px;
              left: 3px;
            }

            &[data-key="friendly-face"] {
              width: 331.43px;
            }

            &[data-key="cheese"] {
              width: 201px;
              top: -15px;
              left: -62px;
            }
          }

          &-2 {
            bottom: -29px;
            left: -43px;

            &[data-key="chase"] {
              top: 2px;
              right: 10px;
            }

            &[data-key="friendly-face"] {
              width: 134.521px;
              transform: unset !important;
              bottom: -91px;

              @media (min-width: 1200px) {
                right: -67px;
                bottom: -49px;
              }
            }

            &[data-key="cheese"] {
              width: 156.628px;
              bottom: -154px;
              right: -58px;

              @media (min-width: 1200px) {
                bottom: -74px;
              }
            }
          }

          &-3 {
            &[data-key="chase"] {
              width: 220px;
              bottom: -120px;
              right: -50px;
            }
          }
        }

        &__layout {
          &-pc {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            max-width: 1152px;
            height: auto;
            margin: 0 auto;
            position: relative;
            z-index: 4;
          }

          &-pc--inner {
            display: grid;
            grid-template-columns: 60% 40%;
            aspect-ratio: 1154/571;
            max-width: 100%;
          }

          &-model,
          &-point {
            border: 3px solid black;
            border-bottom-width: 11px;
            border-top-width: 0;
          }

          &-model {
            border-radius: 0 0 0 40px;
            padding: 0 13px 40.5px 13px;
          }

          &-point {
            padding: 0;
            border-left-width: 0;
            border-right-width: 10px;
            border-radius: 0 0 40px 0;
          }

          &-product {
            display: grid;
            grid-template-columns: 40% 60%;
            padding-top: 75px;

            .body-tom-and-jerry__lineup__main__slider__products {
              order: 2;

              li {
                img {
                  border-radius: 40px;
                  border: 3px solid #000;
                  width: 99.3%;

                  @media (max-width: 767px) {
                    width: 100%;
                    border-radius: unset;
                    border: unset;
                  }
                }
              }
            }

            div:nth-child(2) {
              order: 1;
            }
          }
        }

        &__header {
          &-sp {
            display: none;
          }

          &-pc {
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding: 20px;
            background-color: white;
            border-radius: 40px 40px 0 0;
            border: 3px solid black;
            border-right-width: 10px;
          }

          &-desc {
            position: relative;

            p {
              font-size: 16px;
              line-height: 36px;
              margin-top: -43px;
              padding: 0 49px;
            }
          }

          &__order-funny {
            &-1 {
              display: block;
              position: absolute;
              top: 20%;
              left: 32%;
            }

            &-2 {
              display: block;
              position: absolute;
              top: 28%;
              left: 62%;
            }

            &-3 {
              display: block;
              position: absolute;
              top: 16%;
              left: 51%;
            }
          }

          &__order-chase {
            &-1 {
              display: block;
              position: absolute;
              top: 33%;
              left: 59%;
            }

            &-2 {
              display: block;
              position: absolute;
              top: 14%;
              left: 8%;
            }

            &-3 {
              display: block;
              position: absolute;
              top: 33%;
              left: 40%;
            }
          }

          &__order-friendly-face {
            &-1 {
              display: block;
              position: absolute;
              top: 23%;
              left: 1%;
            }

            &-2 {
              display: block;
              position: absolute;
              top: 28%;
              left: 36%;
            }

            &-3 {
              display: block;
              position: absolute;
              top: 35%;
              right: 0;
            }
          }

          &__order-cheese {
            &-1 {
              display: block;
              position: absolute;
              top: 14%;
              left: 42%;
            }

            &-2 {
              display: block;
              position: absolute;
              top: 37%;
              left: 39%;
            }

            &-3 {
              display: block;
              position: absolute;
              top: 32%;
              left: 61%;
            }
          }

          &-option {
            width: 79px;
            height: 72.121px;
            position: relative;

            @media (min-width: 1024px) {
              cursor: pointer;
            }

            &::before {
              position: absolute;
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background: url("#{$base-img-url}/products/lineup_inactive-point.svg") center/100% no-repeat;
              transition: .5s;
              cursor: pointer;
            }

            &__funny,
            &__chase,
            &__friendly-face,
            &__cheese {

              &--active,
              &:hover {
                &::before {
                  background: url("#{$base-img-url}/products/lineup_active-point.svg") center/100% no-repeat !important;
                }
              }
            }

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              -webkit-text-stroke-width: 4px;
              -webkit-text-stroke-color: black;
              paint-order: stroke;
              font-family: $font-karu;
              font-size: 26px;
              font-style: normal;
              font-weight: 900;
              line-height: 33px;
              letter-spacing: 3.12px;

              @media (max-width: 767px) {
                -webkit-text-stroke-width: 3px;
              }
            }
          }
        }

        &__slider {
          &__points {
            margin-bottom: 0 !important;

            &__desc {
              gap: 25px;
              margin: 73px 0;

              p:nth-child(1) {
                padding: 4px 10px 2px;

                span {
                  font-size: 30px;
                  letter-spacing: 2px;
                }
              }

              p:not(:first-child) {
                font-size: 18px;
                line-height: 36px;
                min-height: 108px;
              }
            }

            &__img {
              width: 100%;
              border: 3px solid black;
              border-left-width: 0;
              border-right-width: 0;
              border-bottom-width: 0;
              border-radius: 0 0 40px 0;
            }
          }

          &__products {
            > .slick {
              &-list {
                margin: 0 -8px;

                .slick {
                  &-slide {
                    padding: 0 8px;
                  }
                }
              }

              &-arrow {
                position: absolute;
                width: 50px;
                height: 50px;
                top: -75px;
                z-index: 1;
                background: transparent;
                color: transparent;
                border: none;

                @media (min-width: 1024px) {
                  cursor: pointer;
                }

                &::before,
                &::after {
                  position: absolute;
                  content: "";
                  display: block;
                  width: 50px;
                  height: 50px;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background-size: 50px 50px;
                  background-repeat: no-repeat;
                  transition: opacity 0.5s ease;
                }

                &::before {
                  background-image: url("#{$base-img-url}/lineup_arrow-icon-menu.svg");
                  opacity: 1;
                }

                &::after {
                  background-image: url("#{$base-img-url}/lineup_arrow-icon-menu-hover.svg");
                  opacity: 0;
                }

                &:hover::before {
                  opacity: 0;
                }

                &:hover::after {
                  opacity: 1;
                }

              }

              &-prev {
                left: calc(100% - 174px);
                transform: rotate(90deg);
              }

              &-next {
                right: 64px;
                transform: rotate(270deg);
              }
            }
          }
        }

        &__product {
          &__layout {
            &-row {
              flex-direction: column;
              gap: 20px;
              padding: 40px 20px 20px 20px;
            }
          }

          &__sku {
            font-size: 33px;
            letter-spacing: 3.3px;
          }

          &__color-name {
            font-size: 13px;
            margin: 8px 0;
          }

          &__price {
            font-size: 24px;
          }

          &__button {
            padding-bottom: 0;
            margin: 0 0 0 20px;
            position: relative;
            z-index: 2;
          }
        }
        &__layout {
          &-product {
            margin-left: auto;
            grid-template-columns: 32% 68%;
            position: relative;
            z-index: 4;
            max-width: calc(100% - 20px);
            gap: 40px;

            @media (min-width: 1024px) {
              gap: 0;
              padding-top: 157px;
            }

            @media (min-width: 1440px) {
              max-width: calc(100% - 156px);
            }

            @media (min-width: 1600px) {
              max-width: calc(100% - 230px);
            }
          }
        }

        &__header {
          &__order-funny {
            &-1 {
              top: 24%;
              left: 36%;
            }

            &-2 {
              top: 31%;
              left: 65%;
            }

            &-3 {
              top: 19%;
              left: 50%;
            }
          }
        }

        &__figure {
          &-1 {
            width: 319.47px;
            top: -209px;
            right: -89px;

            &[data-key="chase"] {
            }
          }

          &-2 {
            width: 155px;
            bottom: -79px;
            left: -67px;
          }
        }

        &__slider {
          &__products {
            > .slick-list > .slick-track > .slick {
              &-slide {
                max-width: 603px;
              }
            }
          }
        }

        &__product {
          &__layout {
            padding: 55px 20px 20px 20px;

            &-row {
              padding-left: 0;
            }
          }

          &__button {
            margin-left: 0;
          }
        }
      }
    }

    &__funny,
    &__chase,
    &__friendly-face,
    &__cheese {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -43px;
        left: -733px;
        right: -733px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;

        @media (max-width: 960px) {
          content: none;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 300px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &__chase,
    &__friendly-face,
    &__cheese {
      margin-top: -335px;
    }

    &__funny{
      &::before{
        z-index: 0;
        background-image: url("#{$base-img-url}/products/funny/bg-funny-text.webp");
      }
      &::after {
        background-color: $pink-ec;
      }
    }

    &__chase{
      &::before{
        z-index: 1;
        background-image: url("#{$base-img-url}/products/chase/bg-chase-text.webp");
      }
      &::after {
        background-color: $green-00;
      }
    }

    &__friendly-face{
      z-index: 1;

      &::before{
        z-index: 2;
        background-image: url("#{$base-img-url}/products/friendly-face/bg-friendly-face-text.webp");
      }
      &::after {
        background-color: $gray-7b;
      }
    }

    &__cheese{
      z-index: 2;
      &::before{
        z-index: 3;
        background-image: url("#{$base-img-url}/products/cheese/bg-cheese-text.webp");
      }
      &::after {
        background-color: $yellow-fa;
      }
    }
  }
}


.body-tom-and-jerry__lineup__main__layout-point > .slick-slider {
  position: relative;
  > .slick {
    &-dots {
      list-style-type: none;
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      margin: 8px 0 0 0;

      > li,
      > li > button,
      > li > button::before {
        width: 11px;
        height: 11px;
      }

      > li {
        margin: 0 6px;

        > button {
          color: transparent;
        }
      }

      > li > button {
        content: "";
        background-color: $yellow-fd;
        border-radius: 50%;
        opacity: 1;
        border: none;
      }

      > li.slick-active > button {
        background-color: #F0A04A;
        opacity: 1;
      }
    }

    &-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 38px;
      height: 38px;
      top: 64.5%;
      z-index: 1;
      background: transparent;
      color: transparent;
      border: none;

      @media (min-width: 961px) {
        display: none !important;
      }


      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        display: block;
        width: 38px;
        height: 38px;
        opacity: 1;
        background: url("#{$base-img-url}/lineup_arrow-icon-menu.svg") center / 100% no-repeat;
      }
    }

    &-prev {
      left: -5px;
      transform: rotate(90deg);
    }

    &-next {
      right: -5px;
      transform: rotate(270deg);
    }
  }
}

@keyframes cartoonSwinging {
  0%,
  100% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
}
