$blue-15: #15A9D8;
$blue-0f: #0F99C4;
$brown-68: #682F25;
$yellow-fa: #fae445;
$yellow-fd: #FDE360;
$red-e1: #e1002a;

$font-zen: "Zen Kaku Gothic New", serif;
$font-karu: "Karu";

$base-img-url: 'https://static.lenskart.com/owndays/img/tomandjerry';

.footer-tom-and-jerry {
  &__case {
    position: relative;
    margin-top: -341px;
    z-index: 2;

    &__bg {
      &__header {
        background-color: transparent;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &__body {
        background-color: $blue-15;
        position: relative;
        z-index: 0;

        &::before, &::after {
          content: '';
          width: 100%;
          height: 40px;
          background-color: $blue-15;
          position: absolute;
          z-index: -1;
        }

        &::before {
          top: -20px;
        }

        &::after {
          bottom: -20px;
        }

        @media (min-width: 768px) {
          margin-top: -10px;
          margin-bottom: -10px;

          &::before {
            top: -30px;
          }

          &::after {
            bottom: -30px;
          }
        }
      }

      &__footer {
        background-color: $blue-0f;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &__card {
      padding: 60px 0 92.36px 0;
      border: 2px solid black;
      background: white;
      border-top-width: 2px;
      border-bottom-width: 8.79px;
      border-radius: 30px;

      @media (min-width: 768px) {
        border-radius: 40px;
        border: 3px solid black;
        border-bottom-width: 8px;
        display: grid;
        max-width: 100%;
        grid-template-columns: 50% 50%;
        padding: 79px 24px 62px 24px;
        margin: 30px 0;
        column-gap: 24px;
      }
    }

    &__header {
      img {
        display: block;
        width: 292.569px;
        height: auto;
        margin: 0 auto;
      }

      p {
        padding: 0 16px;
        color: $brown-68;
        font-family: $font-zen;
        font-size: 14px;
        font-style: normal;
        font-weight: 900 !important;
        line-height: 32px;
        margin: 30.65px auto 89px auto;
        letter-spacing: 0px;
      }

      @media (min-width: 768px) {
        padding-left: 28px;

        img {
          margin-left: 0;
        }

        p {
          padding: 0;
          margin-top: 30.45px;
          margin-bottom: 0;
        }
      }

      @media (min-width: 1024px) {
        padding-top: 55px;

        img {
          width: 500.554px;
        }

        p {
          line-height: 30px;
        }
      }
    }

    &__footer {
      position: relative;

      &__main-img {
        display: block;
        width: 100%;
        height: auto;
      }

      &__figure {
        &-1, &-2 {
          position: absolute;
          height: auto;
        }

        &-1 {
          width: 153.321px;
          top: -66px;
          right: 5.34px;
        }

        &-2 {
          width: 153.321px;
          left: 7px;
          bottom: -44.42px;
        }

        @media (min-width: 768px) {
          &-1 {
            width: 216.31px;
            top: -138px;
            right: -58px;
          }

          &-2 {
            width: 216.032px;
            left: -67px;
            bottom: -114.42px;
          }
        }

        @media (min-width: 1024px) {
          &-1 {
            width: 316.31px;
            top: -159px;
            right: -80px;
          }

          &-2 {
            width: 316.31px;
            left: -76px;
            bottom: -146.42px;
          }
        }
      }
    }
  }

  &__accessories {
    background-color: $blue-0f;
    position: relative;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        top: -2px;
        background-color: $blue-0f;
      }

      img {
        display: block;
        height: auto;

        &:nth-child(1) {
          width: 199px;
        }

        &:nth-child(2) {
          width: 304px;
        }

        @media (min-width: 1024px) {
          &:nth-child(1) {
            width: 228px;
          }

          &:nth-child(2) {
            width: 529px;
          }
        }
      }
    }

    &__body {
      margin-top: 89px;
      position: relative;

      &__figure {
        position: absolute;
        top: -64px;
        left: -24px;
        z-index: 1;
        width: 181.313px;
        height: 113.206px;

        @media (min-width: 1024px) {
          width: 316.31px;
          height: 197.494px;
          top: -78px;
          left: -77px;
        }
      }

      >.slick-slider {
        >.slick {
          &-list {
            margin: 0 -3px;
            height: 245px;

            @media (min-width: 768px) {
              height: auto;
            }

            > .slick-track {
              height: auto !important;
            }

            > .slick-track > .slick {
              &-slide {
                height: auto !important;
                display: flex !important;
                align-items: center;
                justify-content: center;
                padding: 0 3px;

                &:focus {
                  outline: none;
                }


                > div {
                  height: auto;
                }

                > div > li {
                  height: auto;
                  display: flex;
                }

                > div > li > img {
                  display: block;
                  width: 311px;
                  height: auto;
                  object-fit: contain;

                  @media (min-width: 768px) {
                    width: 100%;
                  }

                  @media (min-width: 1024px) {
                    width: 560px;
                  }
                }
              }
            }
          }

          &-dots {
            list-style-type: none;
            display: flex !important;
            align-items: center;
            justify-content: center;
            padding-left: 0;
            margin: 8px 0 0 0;

            @media (min-width: 1024px) {
              margin-top: 20px;

              > li,
              > li > button,
              > li > button::before {
                width: 11px;
                height: 11px;
              }

              > li {
                margin: 0 6px;

                > button {
                  color: transparent;
                }
              }

              > li > button {
                content: "";
                background-color: $yellow-fd;
                border-radius: 50%;
                opacity: 1;
                border: none;
              }

              > li.slick-active {
                width: 51px;
                height: 34.423px;
                margin-right: 4px;
                margin-left: 4px;
              }

              > li.slick-active > button {
                width: 51px;
                height: 34.423px;
                position: relative;
                background-color: transparent;

                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 51px;
                  height: 34.423px;
                  background: url("#{$base-img-url}/accessories/accessories-cheese-icon.svg") no-repeat 50% / contain;
                }
              }
            }
          }

          &-arrow {
            position: absolute;
            width: 38px;
            height: 38px;
            top: 38%;
            transform: translateY(-50%);
            z-index: 1;
            background: transparent;
            color: transparent;
            border: none;

            &::before {
              position: absolute;
              content: "";
              display: block;
              width: 38px;
              height: 38px;
              opacity: 1;
              background: url("#{$base-img-url}/lineup_arrow-icon-menu.svg") center / 100% no-repeat;
              transition: .5s;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            @media (min-width: 768px) {
              width: 58px;
              height: 58px;

              &::before {
                width: 58px;
                height: 58px;
              }
            }

            @media (min-width: 1024px) {
              display: none !important;
            }
          }

          &-prev {
            right: calc(100% - 57px);
            transform: rotate(90deg);
          }

          &-next {
            left: calc(100% - 57px);
            transform: rotate(270deg);
          }

          @media (min-width: 768px) {
            &-prev {
              right: calc(100% - 126px);
            }

            &-next {
              left: calc(100% - 126px);
            }
          }

          @media (min-width: 1024px) {
            display: none !important;
          }

        }

        @media (min-width: 1024px) {
          height: 389px;
        }
      }

      &__message {
        margin-top: 20px;

        &__header {
          display: flex;
          flex-direction: column;

          p {
            margin-bottom: 0;

            &:nth-child(1) {
              color: white;
              -webkit-text-stroke-width: 4px;
              -webkit-text-stroke-color: black;
              paint-order: stroke;
              font-family: $font-karu;
              font-size: 22px;
              font-style: normal;
              font-weight: 900;
              line-height: 32.8px;
              letter-spacing: 2.2px;
              margin-bottom: 0;

              @media (max-width: 767px) {
                -webkit-text-stroke-width: 3px;
              }

              @media (min-width: 961px) {
                font-size: 33px;
                letter-spacing: 3.3px;
              }
            }

            &:nth-child(2) {
              color: white;
              font-family: $font-zen;
              font-size: 18px;
              font-style: normal;
              font-weight: 900;
              line-height: 32.8px;
              margin-top: -4px;
              margin-bottom: 0;

              @media (min-width: 961px) {
                font-size: 24px;
              }
            }
          }

          @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
        }

        &__body {
          p {
            margin-bottom: 0;
            font-family: $font-zen;
            font-size: 14px;
            font-style: normal;
            font-weight: 900 !important;
            line-height: 32px;
            letter-spacing: 0px;
            color: white;
            margin-top: 12px;
          }
        }

        &__footer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 50px;
          padding: 30px 0 100px 0;

          a {
            width: 316px;
          }

          @media (min-width: 768px) {
            a {
              width: 300px;
            }
          }

          @media (min-width: 1024px) {
            padding: 25px 0 0 50px;
            align-items: baseline;
          }
        }

        @media (min-width: 1024px) {
          margin-top: 0;
          height: 389px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .base-tom-and-jerry__container {
            padding: 0 0 0 50px;
          }
        }
      }

      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 560px 1fr;
        padding: 0 20px;
        max-width: 1192px;
        margin: 90.3px auto 0 auto;
        padding-bottom: 215.7px;
      }
    }
  }

  &__share {
    position: relative;
    background-color: black;
    padding: 50px 0 51.6px 0;

    @media (min-width: 1024px) {
      padding: 61px 0 30.6px 0;

      > .base-tom-and-jerry__container > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 65px;
      }
    }

    &__header {
      text-align: center;
      margin: 0;

      img {
        width: 142px;
        height: auto;
        margin: 0 auto;
      }

      @media (min-width: 1024px) {
        img {
          width: 176px;
        }
      }
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      gap: 24px;

      li {
        a {
          font-size: 37.856px;
          color: white;
          display: block;
          width: 100%;
          height: 100%;

          i {
            background: transparent;
          }
        }

        &:nth-child(3) {
          width: 37.856px;
          height: 37.856px;
          position: relative;

          a {
            color: black;
            position: relative;

            i {
              font-size: 26.669px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::before {
              position: absolute;
              content: '';
              width: 37.856px;
              height: 37.856px;
              background-color: white;
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 0;
            }
          }
        }
      }

      @media (min-width: 1024px) {
        margin: 0;
        gap: 49.37px;

        li {
          a {
            font-size: 48px;
            cursor: pointer;
            transition: .5s;
          }

          &:not(:nth-child(3)) {
            a {
              &:hover {
                color: $red-e1;
              }
            }
          }

          &:nth-child(3) {
           a {
              &::before {
                width: 48px;
                height: 48px;
              }

              &:hover {
                &::before {
                  transition: 0.5s;
                  background-color: $red-e1;
                }
              }
            }
          }
        }
      }
    }

    &__tom-and-jerry {
      width: 263px;
      height: auto;
      display: flex;
      margin: 0 auto;
      padding: 30.14px 0 19.14px 0;

      @media (min-width: 1024px) {
        display: none;
      }
    }

    &__copyright {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 11.09px;

      img {
        width: 44.7px;
        height: auto;
      }

      p {
        margin-bottom: 0;
        font-family: $font-zen;
        font-size: 11px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        color: white;
      }

      @media (min-width: 1024px) {
        margin-top: 36.91px;

        p {
          font-size: 12px;
        }
      }
    }
  }

  &__navs-sticky {
    position: absolute;
    width: 100%;
    height: 50px;
    right: 0;
    bottom: 0;
    z-index: 8;

    &__inner {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: auto;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      max-width: 823px;
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      height: 70.062px;

      &__gap {
        height: 100px;
      }

      &__actions {
        padding-right: 20px;
        max-width: none;
      }

      &__inner {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 55px;
    border-radius: 0;
    white-space: nowrap;
    text-align: center;
    font-family: $font-zen;
    font-size: 12px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;

    &--primary {
      width: 124px;
      border-radius: 20px 0px 0px 0px;
      border-top: 2px solid $red-e1;
      border-left: 2px solid $red-e1;
      background: $red-e1;
      color: white;

      &:hover {
        color: white;
      }
    }

    &--secondary {
      width: 150px;
      border-radius: 0px 0px 0px 0px;
      border-top: 2px solid $red-e1;
      border-right: 2px solid $red-e1;
      border-left: 2px solid $red-e1;
      background: white;
      color: $red-e1;

      &:hover {
        color: $red-e1;
      }
    }

    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    @media (min-width: 768px) {
      width: 260px;
      height: 70.062px;
      font-size: 16px;

      &--primary {
        border-top-left-radius: 20px !important;
      }

      &--secondary {
        border-radius: 0px 20px 0px 0px;
        border-top-right-radius: 20px !important;
      }
    }

    @media (min-width: 1024px) {
      cursor: pointer;

      &--primary, &--secondary {
        &:hover {
          background-color: black;
          color: white;
          border-color: black;
          transition: 0.5s;
        }
      }
    }
  }

  &__shops {
    position: relative;
    background-color: $yellow-fd;

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1114.638px;
          height: auto;
          margin: auto;
        }
      }
    }

    &__title, &__list-sp, &__list-pc, &__footer {
      position: relative;
      z-index: 1;
    }

    &__title {
      width: 100%;
      height: auto;
      margin: 0;
      display: flex;
      justify-content: center;
      padding: 75px 0 49px 0;

      img {
        display: block;
        width: 250px;
        height: auto;
      }

      @media (min-width: 768px) {
        img {
          width: 403px;
        }
      }

      @media (min-width: 1024px) {
        padding: 160px 0 94px 0;

        img {
          width: 699px;
        }
      }
    }

    &__list-sp {
      display: block;

      @media (min-width: 768px) {
        display: none;
      }
    }

    &__list-sp {
      padding: 0 20px;
      width: fit-content;
      height: auto;
      margin: 0 auto;
      list-style-type: disc;
      display: block;

      @media (min-width: 768px) {
        display: none;
      }

      li {
        color: $brown-68;
        font-family: $font-zen;
        font-size: 14px;
        font-style: normal;
        font-weight: 900 !important;
        line-height: 32px;
        letter-spacing: 0.28px;
        position: relative;
        margin-left: 28px;
        list-style-type: inherit;
      }
    }

    &__list-pc {
      display: none;

      @media (min-width: 768px) {
        display: flex;
        justify-content: center;

        ul {
          padding: 0 20px;
          margin: 0;
          list-style-type: disc;

          li {
            color: $brown-68;
            font-family: $font-zen;
            font-size: 16px;
            font-style: normal;
            font-weight: 900 !important;
            line-height: 40px;
            letter-spacing: 0.32px;
            position: relative;
            margin-left: 28px;
            list-style-type: inherit;
          }
        }
      }

      @media (min-width: 1024px) {
        ul {
          &:nth-child(2) {
            padding-left: 80px;
          }
        }
      }
    }

    &__footer {
      padding: 45px 20px 100px 20px;
      display: flex;
      justify-content: center;

      a {
        max-width: 372px;
      }

      @media (min-width: 1024px) {
        padding-top: 90px;
        padding-bottom: 116px;
      }
    }

    &__figure-footer {
      display: none;

      @media (min-width: 1024px) {
        display: block;
        position: absolute;
        bottom: 0;
        right: 26px;
        width: 263px;
        height: 114px;
      }

      @media (min-width: 1200px) {
        width: 395px;
        height: 171px;
      }
    }
  }
}
