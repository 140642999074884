.wishlist-page {
  padding: 30px 175px;
  background-color: #F7F7F7;
}

.wishlist-page__layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}

.wishlist-page__title {
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
}

.wishlist-page__empty {
  margin: 16px 0 0;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, .5);
}

.wishlist-page__empty-image {
  margin-top: 118px;
}
