@import './desktop-variables.scss';

@mixin mQ-max($size) {
    @media screen and (max-width: $size) {
        @content;
    }
}

@mixin mQ-min($size){
    @media screen and (min-width: $size){
        @content;
    }
}

@mixin mQ-minmax($minsize, $maxsize){
    @media screen and (min-width: $minsize) and (max-width: $maxsize){
        @content;
    }
}

@mixin bigScreenWrapper() {
    @include mQ-min($bigScreenWidth){
        max-width: $bigScreenWidth;
    }
}