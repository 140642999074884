.service_l {
  margin: 0 auto 120px;
  display: block;
  overflow: hidden;
  position: relative;
  letter-spacing: 1px;
}

@media (max-width: 1023px) {
  .service_l {
    margin: 0 auto 60px;
  }
}

.service_l__section {
  position: relative;
  overflow: hidden;
  display: block;
}

.service_l__container {
  width: 90%;
  max-width: 1296px;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container {
    width: 100%;
    padding: 0 5%;
  }
}

.service_l__container-retail {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .service_l__container-retail {
    width: 100%;
    padding: 0;
  }
}

.service_l__container-warranty {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1296px;
}

@media (max-width: 767px) {
  .service_l__container-warranty {
    width: 100%;
    padding: 0 5%;
  }
}

.l-services-warranty {
  width: 100%;
}

.l-services-warranty--l-content--inner {
  width: 80%;
  max-width: 1152px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .l-services-warranty--l-content--inner {
    width: 100%;
  }
}

.l-services-warranty--l-warranty__content-block {
  display: block;
  border: 1px solid #e3e3e3;
  margin: 30px 0 20px;
  position: relative;
  min-height: 150px;
  width: 100%;
}

.l-services-warranty--l-warranty__content-block:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.l-services-warranty--l-warranty__content-block:before {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  left: 110px;
  border-left: 1px dashed #e3e3e3;
}

.l-services-warranty--l-warranty__content-block--l-warranty-no {
  width: 90px;
  float: left;
}

.l-services-warranty--l-warranty__content-block--l-warranty-no p {
  position: absolute;
  transform: rotate(-90deg);
  top: 50%;
  left: 10px;
  margin-top: -12px;
  color: #000;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 15px;
}

.l-services-warranty--l-warranty__content-block--l-warranty-service {
  padding: 20px 20px 20px 130px;
  display: table;
  height: 100%;
  min-height: 150px;
  width: 100%;
}

.l-services-warranty--l-warranty__content-block--l-warranty-service-inner {
  vertical-align: middle;
  display: table-cell;
  line-height: 2;
}

.l-services-warranty--l-warranty__content-block--l-warranty-service-inner ul {
  padding-top: 5px;
  align-items: start;
}

.l-services-warranty--l-warranty__content-block--l-warranty-service-inner h3 {
  font-size: 16px;
  color: #000;
}

.l-services-warranty--title {
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  color: #000;
  line-height: 2;
  font-weight: 400 !important;
  text-align: center;
}

.l-services-warranty--c-attention-title {
  margin: 0;
  font-family: "Founders Grotesk", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 18px;
  color: #000;
  font-weight: 300;
  line-height: 2;
}

.l-services-warranty--p-main__title-default {
  position: relative;
}

.l-services-warranty--p-main__title-description {
  text-align: center;
  max-width: 600px;
  margin: 17px auto 0;
}

.l-services-warranty--l-warranty__content {
  margin-top: 80px;
  position: relative;
}

@media (max-width: 767px) {
  .l-services-warranty--l-warranty__content {
    margin-top: 25px;
  }
}

.l-services-warranty--l-warranty__content h2 {
  font-size: 18px;
  text-align: center;
}

.l-services-warranty--p-attention-list {
  padding: 0;
  list-style-position: inside;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 0;
}

.l-services-warranty--p-attention-list li {
  position: relative;
  padding-left: 13px;
  list-style: none;
}

.l-services-warranty--p-attention-list li p {
  font-size: 11px;
  color: #999;
  -webkit-font-smoothing: subpixel-antialiased;
  padding-top: 0;
  margin: 0;
}

.l-services-warranty--p-attention-list li:before {
  content: '*';
  position: absolute;
  color: #999;
  left: 0;
  top: 4px;
  line-height: 1;
  font-size: 14px;
}

.l-services-warranty--c-term {
  margin: 0;
  font-size: 16px;
  color: #c7002e;
  font-weight: 600;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 2;
}

.l-services-warranty--c-attention {
  margin: 0;
  font-size: 11px;
  color: #999;
  padding-top: 5px;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 2;
}

@media (max-width: 1023px) {
  .l-services-warranty--c-attention {
    letter-spacing: 0.4px;
  }
}

@media (max-width: 767px) {
  .l-services-warranty--c-attention {
    letter-spacing: 0.4px;
  }
}

.l-services-warranty--c-attention i {
  background: none !important;
}

.l-services-warranty--c-attention span {
  color: #999;
  font-size: 11px;
  margin-top: 5px;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 2;
}

.l-services-warranty--c-attention span:before {
  content: '*';
  color: #999;
  font-size: 14px;
  line-height: 1;
  margin-right: 0.3rem;
}

.u-text-comment {
  color: #999;
  font-size: 11px;
  line-height: 1.7;
  margin-top: 5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.u-text-comment:before {
  content: '*';
  position: relative;
  color: #999;
  left: 0;
  line-height: 1;
  padding-right: 0.3rem;
}

@media (max-width: 767px) {
  .l-services-warranty--p-main__title-description {
    margin: 14px 0 0;
  }
  .l-services-warranty--l-warranty__content-block:before, .l-services-warranty--l-warranty__content-block:after {
    display: none;
  }
  .l-services-warranty--l-warranty__content-block--l-warranty-no {
    float: none;
    width: 100%;
  }
  .l-services-warranty--l-warranty__content-block--l-warranty-no p {
    position: relative;
    transform: rotate(0);
    top: auto;
    left: auto;
    margin: 0;
    text-align: center;
    border-bottom: 1px dashed #e3e3e3;
    padding: 15px 0;
  }
  .l-services-warranty--l-warranty__content-block--l-warranty-service {
    padding: 15px;
    min-height: auto;
  }
}
