.contact-category {
  font-size: 14px;
  line-height: 2;
  letter-spacing: .8px;
  width: 100%;
  overflow-x: hidden;
}

.contact-category img {
  display: block;
  width: 100%;
}

.contact-category h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 35px;
  line-height: 1;
  letter-spacing: 0.01rem;
  position: relative;
  margin: 0 0 60px;
  padding-bottom: 60px;
}

.contact-category h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 60px;
  height: 4px;
}

.contact-category__btn {
  text-decoration: none;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid transparent;
  padding: 1rem;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 275px;
  height: 64px;
  margin-inline: auto;
}

.contact-category__btn::before {
  content: '';
  position: absolute;
  left: -10%;
  bottom: 0;
  width: 0;
  height: 120%;
  -webkit-transform: skewX(15deg);
          transform: skewX(15deg);
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.contact-category__btn:hover {
  text-decoration: none;
  outline: none;
}

.contact-category__btn:hover::before {
  width: 120%;
}

.contact-category__btn:focus {
  text-decoration: none;
  outline: none;
}

.contact-category__btn--primary, .contact-category__btn--primary:focus {
  background-color: black;
  border-color: black;
  color: white;
}

.contact-category__btn--primary::before, .contact-category__btn--primary:focus::before {
  background-color: #f4f4f4;
}

.contact-category__btn--primary:hover, .contact-category__btn--primary:focus:hover {
  color: black;
}

.contact-category__btn--secondary, .contact-category__btn--secondary:focus {
  background-color: #f4f4f4;
  border-color: black;
  color: black;
}

.contact-category__btn--secondary::before, .contact-category__btn--secondary:focus::before {
  background-color: black;
}

.contact-category__btn--secondary:hover, .contact-category__btn--secondary:focus:hover {
  color: white;
}

.contact-category__header {
  padding: 96px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  gap: 30px;
  position: relative;
}

.contact-category__header h1 {
  font: 0/0 a;
  margin: 0;
}

.contact-category__header h1 img {
  max-width: 230px;
  margin-inline: auto;
}

.contact-category__header p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  background-color: black;
  color: white;
  padding: 7px 10px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 1px;
}

.contact-category__header svg {
  scale: -1;
  min-height: 50px;
  position: absolute;
  bottom: 0;
}

.contact-category__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 1192px;
}

.contact-category__container--lg {
  max-width: 1340px;
}

.contact-category__banner {
  margin-block: 50px;
}

.contact-category__banner a img {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.contact-category__banner a:hover img {
  opacity: 0.5;
}

.contact-category__lineup {
  margin-block: 80px;
}

.contact-category__lineup-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 70px;
}

.contact-category__lineup-list > li {
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.contact-category__lineup-list > li img {
  max-width: 250px;
  -o-object-fit: contain;
     object-fit: contain;
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="5" /><feOffset dx="1" dy="3" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.15)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, .15));
          filter: drop-shadow(0 2px 5px rgba(0, 0, 0, .15));
  margin: 0 auto 32px;
}

.contact-category__lineup-list > li img.vertical {
  max-height: 235px;
}

.contact-category__lineup-list > li img.horizontal {
  max-height: 92px;
}

.contact-category__lineup-list > li ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
  margin-bottom: 10px;
}

.contact-category__lineup-list > li ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 1.6em;
  padding: 3px 8px;
  border-radius: 10rem;
}

.contact-category__lineup-list > li h4 {
  font-weight: 800;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin: 0 0 5px;
}

.contact-category__lineup-list > li p {
  color: black;
  font-size: 16px;
  margin: 0 0 16px;
}

.contact-category__htu {
  background-color: #f4f4f4;
  padding-block: 96px;
}

.contact-category__htu img {
  max-width: 200px;
  margin: 64px auto;
}

.contact-category__htu p {
  margin-bottom: 32px;
}

.contact-category__note {
  margin-block: 80px;
}

.contact-category__note p {
  margin-bottom: 24px;
}

.contact-category__note p ~ h2 {
  margin-top: 80px;
}

.contact-category__note ol {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}

.contact-category__note ol li h3, .contact-category__note ol li p {
  margin: 0;
}

.contact-category__note ol li h3 {
  font-size: inherit;
  font-weight: 600;
  line-height: 2;
}

.contact-category--clear-contactlens h2::before {
  background-color: #59b2e7;
}

.contact-category--clear-contactlens .contact-category__lineup-list > li {
  color: #59b2e7;
}

.contact-category--clear-contactlens .contact-category__lineup-list > li ul li {
  background-color: rgba(89, 178, 231, .15);
}

.contact-category--color-contactlens h2::before {
  background-color: #71475e;
}

.contact-category--color-contactlens .contact-category__lineup-list {
  max-width: 1000px;
}

.contact-category--color-contactlens .contact-category__lineup-list > li {
  color: #71475e;
}

.contact-category--color-contactlens .contact-category__lineup-list > li ul li {
  background-color: rgba(113, 71, 94, .15);
}

@media (min-width: 768px) {
  .contact-category__htu p {
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .contact-category {
    font-size: 16px;
  }
  .contact-category h2 {
    font-size: 50px;
  }
  .contact-category__banner, .contact-category__lineup, .contact-category__note {
    margin-block: 120px;
  }
  .contact-category__htu {
    padding-block: 120px;
  }
  .contact-category__header {
    padding-block: 192px;
  }
  .contact-category__header h1 img {
    max-width: 300px;
  }
}
