$base-img-url: 'https://static.lenskart.com/owndays/img/huawei2-titanium';

$yellow-b4: #b47e00;
$gray-53: #535353;
$gray-6a: #6A6965;
$gray-6e: #6a6965;
$white-f7: #f7f7f7;

$font-biz: "BIZ UDPGothic", serif;

.header-huawei-2-titanium {
  &__main {
    width: 100%;
    height: 100%;
    background-color: #414141;
    aspect-ratio: 390 / 620;
    position: relative;

    &__logo {
      width: 221px;
      height: auto;
      position: absolute;
      top: 63px;
      left: 50%;
      transform: translateX(-50%);
      font: 0 / 0 a;
      margin: 0;
    }

    &__glasses {
      width: 100%;
      height: auto;
      position: absolute;
      top: -23px;
    }

    &__new-arrivals {
      &-layout {
        position: absolute;
        left: 38px;
        bottom: 73px;
        display: flex;
        flex-direction: column;
      }

      &-title {
        width: fit-content;
        height: auto;
        border: 1px solid $yellow-b4;
        padding: 3px 8px;
        line-height: 0;
        margin-bottom: 11px;

        span {
          color: $yellow-b4;
          text-align: center;
          font-family: $font-biz;
          font-size: 12px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 19.2px;
          letter-spacing: 0.12px;
        }
      }

      &-titanium {
        width: 179.698px;
        height: auto;
      }

      &-date {
        color: white;
        font-family: $font-biz;
        font-size: 11px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 15.4px;
        letter-spacing: 0.11px;
        margin: 24.22px 0 0 0;
        position: relative;

        &::before {
          position: absolute;
          content: url("#{$base-img-url}/main-text-line-sp.svg");
          bottom: 117px;
          left: 0;
          width: 100%;
          height: 100%;
          aspect-ratio: 259/98;
        }

        strong {
          color: white;
          font-family: $font-biz;
          font-size: 14px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 140%;
          letter-spacing: 0.14px;
        }
      }
    }

    @media (min-width: 768px) {
      width: 100%;
      aspect-ratio: 1440/835;
      margin: 0;

      &__logo {
        left: unset;
        right: 10%;
        transform: none;
        z-index: 2;
      }

      &__glasses {
        width: 100%;
        top: 0;
        left: 0;
      }

      &__new-arrivals {
        &-layout {
          left: 10%;
          bottom: 5%;
        }
      }
    }

    @media (min-width: 1024px) {
      &__logo {
        width: 28%;
        right: 12%;
      }

      &__glasses {
        width: 100%;
        top: 0;
        left: 0;
      }

      &__new-arrivals {
        &-layout {
          left: 10%;
          bottom: 5%;
        }

        &-title {
          padding: 6px 10px;
          margin-bottom: 18px;

          span {
            font-size: 14px;
            line-height: 22.4px;
            letter-spacing: 0.14px;
          }
        }

        &-titanium {
          width: 252px;
          height: auto;
        }

        &-date {
          font-size: 14px;
          line-height: 19.6px;
          letter-spacing: 0.14px;
          margin: 28px 0 0 0;

          &::before {
            content: url("#{$base-img-url}/main-text-line-pc.svg");
            bottom: 125px;
            left: 0;
            aspect-ratio: 492/114.5;
          }

          strong {
            font-size: 18px;
            letter-spacing: 0.18px;
          }
        }
      }
    }

    @media (min-width: 1296px) {
      padding-top: 60px;
      padding-right: 80px;

      &__logo {
        width: 100%;
        max-width: 405px;
        top: 17%;
        right: 12%;
      }

      &__new-arrivals {
        &-layout {
          left: 12%;
          bottom: 12%;
        }
      }
    }

    @media (min-width: 1500px) {
      &__logo {
        right: 20.2%;
      }

      &__new-arrivals {
        &-layout {
          left: 20%;
        }
      }
    }
  }

  &__concept {
    width: 100%;
    height: auto;
    padding: 70px 0 51px;
    position: relative;

    picture {
      width: 100%;
      height: auto;
      display: block;
    }

    &__title {
      color: black;
      font-family: $font-biz;
      font-size: 28px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 160%;
      letter-spacing: 0.28px;
      margin: 0;
    }

    &__details {
      margin-bottom: 6px;

      &-text {
        color: $gray-53;
        font-family: $font-biz;
        font-size: 16px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin: 24px 0 30px 0;

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.14px;
          font-weight: 400 !important;
        }
      }
    }

    &__glasses {
      max-width: none;
      width: 679px;
      height: auto;
      margin-left: -66px;
    }

    &__options {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding: 0 40px;
      margin-bottom: 0;

      > li {
        img {
          height: auto;
        }

        &:nth-child(1) {
          img {
            width: 43.493px;
          }
        }

        &:nth-child(2) {
          img {
            width: 52px;
          }
        }

        &:nth-child(3) {
          img {
            width: 51.999px;
          }
        }

        &:nth-child(4) {
          img {
            width: 52px;
          }
        }

        h3 {
          color: black;
          font-family: $font-biz;
          font-size: 18px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 32px;
          letter-spacing: 0.18px;
          margin: 20px 0 8px 0;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 25.6px;
            letter-spacing: 0.16px;
          }
        }

        p {
          color: $gray-6a;
          font-family: $font-biz;
          font-size: 16px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 32px;
          letter-spacing: 0.16px;
          margin: 0;

          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 0.14px;
            font-weight: 400 !important;
          }
        }
      }
    }

    @media (min-width: 768px) {
      padding-bottom: 132px;

      &__title {
        width: 50%;
        height: auto;
      }

      &__details {
        width: 50%;
        height: auto;
        margin-bottom: 100px;
      }

      &__glasses {
        position: absolute;
        top: 26px;
        left: calc(50% - (61px/2));
        width: calc(1154px/2);
        height: calc(649px/2);
        margin-left: 0;
      }

      &__options {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 44px;
        row-gap: 50px;
        padding: 0;
      }
    }

    @media (min-width: 1024px) {
      &__title {
        font-size: 50px;
        line-height: 83.2px;
        letter-spacing: 0.52px;
      }

      &__details {
        max-width: 720px;
        margin-bottom: 47px;
      }

      &__glasses {
        top: 0;
        left: calc(50% - 61px);
        right: -373px;
        width: 80%;
        height: auto;
        aspect-ratio: 1154/649;
        &.is-fixed{
          position: fixed;
        }
      }

      &__options {
        width: 50%;

        > li {
          &:nth-child(1) {
            img {
              width: 52.493px;
            }
          }

          &:nth-child(2) {
            img {
              width: 56px;
            }
          }

          &:nth-child(3) {
            img {
              width: 61.393px;
            }
          }

          &:nth-child(4) {
            img {
              width: 58px;
            }
          }

          h3 {
            font-size: 18px;
            line-height: 32px;
            letter-spacing: 0.18px;
            margin: 30px 0 12px 0;
          }

          p {
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.16px;
          }
        }
      }

      &__frame {    
        &-top, &-bottom {
          width: 50%;
          position: absolute;
          right: 0;
          z-index: 1;
          background-color: white;
        }

        &-top {
          height: 140px;
          top: 0;
        }

        &-bottom {
          height: 132px;
          bottom: 0;
        }
      }
    }
  }

  &__feature {
    width: 100%;
    height: auto;
    background-color: white;

    &__list {
      display: flex;
      flex-direction: column;
      gap: 100px;
      padding: 45px 35px;
      background-color: $white-f7;
      border-radius: 20px;
      list-style-type: none;
      width: 100%;
      height: auto;
      margin: 0;

      @media (max-width: 767px) {
        gap: 30px;
      }

      > li {
        display: flex;
        align-items: flex-start;
        gap: 22px;

        img {
          height: auto;
        }

        &:nth-child(1) img {
          width: 48px;
        }

        &:nth-child(2) img {
          width: 49.119px;
        }

        &:nth-child(3) img {
          width: 52px;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h4 {
            color: black;
            font-family: $font-biz;
            font-size: 18px;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 25.6px;
            letter-spacing: 0.16px;
            text-align: center;
            margin: 0;

            @media (max-width: 767px) {
              font-size: 16px;
              text-align: left;
            }

            br {
              display: none;
            }
          }

          p {
            margin-bottom: 0;
            color: $gray-6e;
            font-family: $font-biz;
            font-size: 16px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 32px;
            letter-spacing: 0.14px;

            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 28px;
            }
          }
        }
      }
    }

    &__remark {
      margin-top: 15px;
      padding-bottom: 50px;
      color: $gray-53;
      font-family: $font-biz;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.9px;
      letter-spacing: 0.11px;
      padding-left: 15px;
      margin-bottom: 0;
      position: relative;

      &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    @media (min-width: 768px) {
      &__list {
        flex-direction: row;

        > li {
          flex-direction: column;
          align-items: center;
          gap: 30px;
          width: 100%;

          img {
            min-height: 65px;
            object-fit: contain;
          }

          &:nth-child(1) img {
            width: 58px;
          }

          &:nth-child(2) img {
            width: 61.119px;
          }

          &:nth-child(3) img {
            width: 67.603px;
            padding: 10px 0;
          }

          > div {
            gap: 12px;

            h4,
            p {
              margin: 0;
            }
          }
        }
      }

      &__remark {
        margin-top: 20px;
        padding-bottom: 120px;
      }
    }

    @media (min-width: 1024px) {
      &__list {
        padding: 70px 80px;

        > li {
          h4 {
            font-size: 18px;
            line-height: 32px;
            letter-spacing: 0.18px;
          }

          p {
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.16px;
          }
        }
      }

      &__remark {
        font-size: 13px;
        padding-left: 20px;
      }
    }
  }

  &__scene {
    &__tag {
      width: 57px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      color: $yellow-b4;
      font-size: 14px;
      line-height: 22.4px;
      font-family: $font-biz;
      font-weight: 700 !important;
      letter-spacing: 0.28px;
      border: 1px solid $yellow-b4;
    }

    &__title, &__desc {
      font-family: $font-biz;
      margin-bottom: 0;
    }

    &__title {
      padding: 15px 0;
      font-size: 26px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 41.6px;
      letter-spacing: 0.26px;
      color: black;

      @media (min-width: 768px) {
        font-size: 38px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 60.8px;
        letter-spacing: 0.38px;
      }
    }

    &__desc {
      font-size: 14px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 28px;
      letter-spacing: 0.14px;
      color: $gray-53;

      @media (min-width: 768px) {
        font-size: 16px;
        font-weight: 700 !important;
        line-height: 32px;
        letter-spacing: 0.16px;
      }
    }

    &__video {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      @media (min-width: 768px) {
        height: 690px;
      }

      @media (max-width: 767px) {
        aspect-ratio: 390/281.619;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        transform: translate(-50%, -50%);
        background: url("#{$base-img-url}/btn_play.svg");
        background-size: 100%;
        z-index: 2;
        pointer-events: none;

        @media (min-width: 768px) {
          width: 64px;
          height: 64px;
        }
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        > iframe {
          pointer-events: none;
          border: none;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
        }
      }
    }

    &__header {
      &__layout {
        display: grid;
        grid-template-columns: 1fr;
      }

      &__img {
        img {
          width: 100%;
          height: 270px;
          object-fit: cover;
        }
      }

      &__content {
        width: 100%;
        height: auto;
        padding: 50px 23px;

        > .base-huawei-2-titanium__btn {
          margin-top: 20px;
        }
      }

      @media (min-width: 768px) {
        &__layout {
          grid-template-columns: 50% 50%;
        }

        &__content {
          order: 1;
        }

        &__img {
          order: 2;
          
          img {
            width: 100%;
            height: 100%;
            object-position: unset;
            overflow: hidden;
          }
        }
      }

      @media (min-width: 1296px) {
        &__content {
          padding: 104px 110px;
        }
      }
    }

    &__body {
      &__layout {
        display: flex;
        flex-direction: column;

        > .base-huawei-2-titanium__container {
          padding-block: 50px;

          > .header-huawei-2-titanium__scene__title {
            padding-top: 0;
          }

          > .header-huawei-2-titanium__feature__remark {
            padding-bottom: 0;
          }
        }

        > .header-huawei-2-titanium__scene__header__img {
          object-position: unset;
        }

        @media (min-width: 768px) {
          flex-direction: row;
          aspect-ratio: 768/618.41;

          > div {
            width: 50%;
          }

          > .base-huawei-2-titanium__container {
            margin: auto 0;
          }

          &--reverse {
            flex-direction: row-reverse;
          }
        }

        @media (min-width: 1024px) {
          max-height: 500px;
          aspect-ratio: 1440/500;
        }

        @media (min-width: 1240px) {
          > .base-huawei-2-titanium__container {
            padding: 0 110px;
          }
        }

        @media (min-width: 1240px) {
          max-height: none;
        }
      }
    }
  }
}
